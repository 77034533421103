import { useCallback } from 'react';
import {
  CheckCircleIcon,
  MinusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { InspectionStatus } from '@prisma/client';

interface InspectionStatusButtonProps {
  onPress: () => void;
  colorScheme: 'green' | 'red' | 'blue' | 'yellow';
  text: string;
  isSelected: boolean;
  /* eslint-disable-next-line */
  Icon: any;
}

function InspectionStatusButton({
  onPress,
  colorScheme,
  text,
  isSelected,
  Icon,
}: InspectionStatusButtonProps) {
  const borderColor = isSelected ? `${colorScheme}-600` : 'gray-200';
  const backgroundColor = isSelected ? `${colorScheme}-100` : 'white';

  const iconColor = isSelected ? `${colorScheme}-600` : 'gray-500';
  const textColor = isSelected ? `${colorScheme}-800` : 'gray-500';
  return (
    <div
      onClick={onPress}
      className={`mb-2 px-3 h-11 rounded-[10px] flex flex-row items-center border border-${borderColor} bg-${backgroundColor} cursor-pointer`}
    >
      <Icon
        className={`h-4 w-4 mr-2 items-center justify-center rounded-full text-${iconColor}`}
      />
      <span className={`text-${textColor} text-xs font-medium`}>{text}</span>
    </div>
  );
}

export interface InspectionStatusSelectorProps {
  status?: InspectionStatus | null;
  name: string;
  onChange: (name: string, status: InspectionStatus) => void;
}

export function InspectionStatusSelector({
  onChange,
  name,
  status,
}: InspectionStatusSelectorProps) {
  const handleSelect = useCallback(
    (status: keyof typeof InspectionStatus) => {
      onChange(name, InspectionStatus[status]);
    },
    [name, onChange]
  );

  return (
    <div className="flex flex-col">
      <InspectionStatusButton
        isSelected={status === InspectionStatus.PASSED}
        text="Safe to Use"
        onPress={() => handleSelect(InspectionStatus.PASSED)}
        colorScheme="green"
        Icon={CheckCircleIcon}
      />
      <InspectionStatusButton
        isSelected={status === InspectionStatus.FAILED}
        text="Failed"
        onPress={() => handleSelect(InspectionStatus.FAILED)}
        colorScheme="red"
        Icon={XCircleIcon}
      />
      <InspectionStatusButton
        isSelected={status === InspectionStatus.QUARANTINED}
        text="Quarantined"
        onPress={() => handleSelect(InspectionStatus.QUARANTINED)}
        colorScheme="blue"
        Icon={MinusCircleIcon}
      />
      <InspectionStatusButton
        isSelected={status === InspectionStatus.MONITORING}
        text="Monitoring"
        onPress={() => handleSelect(InspectionStatus.MONITORING)}
        colorScheme="yellow"
        Icon={MinusCircleIcon}
      />
    </div>
  );
}

export default InspectionStatusSelector;
