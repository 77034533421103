import { Role as PrismaRole } from '@prisma/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Invites, Role } from '@scannable/common';
import {
  userRoleOptions,
  useSendInvitesMutation,
} from '@scannable/frontend/common';

import { ModalForm } from '../../forms';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldArray } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

const emailRegex = /\S+@\S+\.\S+/;
const validationSchema = yup.object({
  invites: yup
    .array()
    .test('validations', 'At least one valid email is needed', (value) => {
      if (!value) {
        return false;
      }
      const filteredEmails = value
        .map((v) => v.email)
        .filter(Boolean)
        .filter((e) => emailRegex.test(e));

      return filteredEmails.length > 0;
    }),
});

export interface SendInviteValues {
  invites: {
    email: string;
    role: PrismaRole;
  }[];
}

export interface InviteModalProps {
  title?: string;
}

export function InviteModal({ title }: InviteModalProps) {
  const { hideModal } = useModal();
  const { t } = useTranslation();
  const [sendInvites] = useSendInvitesMutation({
    refetchQueries: [Invites.GetInvitesByOrganisation],
  });

  const formik = useFormik({
    initialValues: {
      invites: [
        {
          email: '',
          role: Role.Admin,
        },
      ],
    },
    validationSchema,
    onSubmit: async (
      values: SendInviteValues,
      actions: FormikHelpers<SendInviteValues>
    ) => {
      const result = await resolveMutation(
        sendInvites({
          variables: {
            data: {
              invites: values.invites.filter((i) => i.email !== ''),
            },
          },
        }),
        { successMessage: 'Invites sent' }
      );
      if (result.ok) {
        hideModal();
      }
      actions.setSubmitting(false);
    },
  });

  return (
    <Modal title={title} overflowHidden={false} icon="team">
      <ModalForm
        className="space-y-0"
        formik={formik}
        buttons={[
          {
            label: t('team.send_invites'),
            type: 'submit',
            onClick: () => {
              formik.setFieldValue(
                'invites',
                formik.values.invites.map((i) => {
                  return {
                    email: i.email,
                    role: i.role ?? Role.Admin,
                  };
                })
              );
              formik.submitForm();
            },
            color: 'primary',
            'data-cy': 'send-invites',
          },
        ]}
      >
        <InputFieldArray
          formik={formik}
          name="invites"
          options={[
            {
              type: 'text',
              name: 'email',
              label: t('email'),
              placeholder: t('email'),
            },
            {
              type: 'select',
              name: 'role',
              label: t('role'),
              placeholder: t('role'),
              options: userRoleOptions,
              defaultValue: Role.Admin,
            },
          ]}
        />
      </ModalForm>
    </Modal>
  );
}

export default InviteModal;
