import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { cn } from '@scannable/common';

export function SortableItem({
  id,
  children,
  className,
  draggedClassName,
  disabled,
}: {
  id: string;
  children: React.ReactNode;
  className?: string;
  draggedClassName?: string;
  disabled?: boolean;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const resolvedClassName = cn(
    className ?? '',
    isDragging ? draggedClassName ?? '' : ''
  );

  return (
    <div
      ref={setNodeRef}
      className={resolvedClassName}
      style={style}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
}
