import { useEffect, useState } from 'react';
import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

export interface SearchBoxProps {
  searchTerm: string | null;
  searchFields: string[];
  clearSearch: () => void;
  handleSearch: (e: React.SyntheticEvent<HTMLFormElement>) => void;
}

export function SearchBox({
  searchTerm,
  searchFields,
  handleSearch,
  clearSearch,
}: SearchBoxProps) {
  const [search, setSearch] = useState<string>('');
  useEffect(() => {
    setSearch(searchTerm || '');
  }, [searchTerm]);

  return (
    <form onSubmit={handleSearch}>
      <label
        htmlFor="TableSearch"
        className="block text-sm font-medium leading-6 text-gray-900 sr-only"
      >
        Search
      </label>
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          {search && (
            <div className="absolute inset-y-0 right-2 flex items-center">
              <XMarkIcon
                className="h-5 w-5 cursor-pointer"
                onClick={() => {
                  setSearch('');
                  clearSearch();
                }}
              />
            </div>
          )}
          <input
            className="block w-full rounded-none rounded-l-md border-0 py-2 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            type="text"
            name="search"
            id="TableSearch"
            placeholder={`Search by ${searchFields?.join(', ')}`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <ArrowRightIcon className="w-4 h-4 mx-2" />
        </button>
      </div>
    </form>
  );
}

export default SearchBox;
