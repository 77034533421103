import { Button } from '../../atoms';
import { TableAction, TableActionContext } from '../../types/table.types';

export interface TableActionsProps {
  tableActions?: TableAction[];
  context: TableActionContext; // table state being passed to the action, just tracking sort for now
}

export function TableActions({ tableActions, context }: TableActionsProps) {
  return (
    <div className="flex flex-row-reverse pt-2 items-center">
      {tableActions
        ?.filter((a) => a.display === undefined || a.display === true)
        .map((action, i) =>
          action.render ? (
            <div key={`table_action_${i}`} className="ml-2">
              {action.render(context)}
            </div>
          ) : (
            <Button
              key={`table_action_${i}`}
              className="ml-2 h-10 border border-gray-300"
              data-cy={action.dataCy}
              color={action.color || 'info'}
              onClick={() => action.callback && action.callback(context)}
            >
              {action.label}
            </Button>
          )
        )}
    </div>
  );
}

export default TableActions;
