import { ParsedUrlQuery } from 'querystring';
import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { classNames, ucFirst } from '@scannable/common';

type TextGenerator = (param: string, query: ParsedUrlQuery) => Promise<string>;
export interface DynamicBreadcrumbsProps {
  getTextGenerator?: TextGenerator;
  getDefaultTextGenerator?: (path: string, href: string) => string;
  hiddenCrumbs?: string[];
  labelMappings?: Record<string, string>;
}

const _defaultGetDefaultTextGenerator = (path: string, href: string) => path;

// Pulled out the path part breakdown because its
// going to be used by both `asPath` and `pathname`
const generatePathParts = (pathStr: string) => {
  const pathWithoutQuery = pathStr.split('?')[0];
  return pathWithoutQuery.split('/').filter((v) => v.length > 0);
};

function Crumb({
  text: defaultText,
  textGenerator,
  param,
  query,
  href,
  labelMappings,
  last = false,
}: {
  text: string;
  textGenerator?: TextGenerator;
  href: string;
  param: string;
  query: ParsedUrlQuery;
  labelMappings: Record<string, string>;
  last?: boolean;
}) {
  const [text, setText] = useState(defaultText);

  // if we want to do async look up in the future
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function runTextGenerator() {
    if (labelMappings) {
      const mappedText = labelMappings[param];
      if (mappedText) {
        setText(mappedText);
      }
    }
    if (textGenerator) {
      const finalText = await textGenerator(param, query);
      setText(finalText || ucFirst(param));
    }
  }
  useEffect(() => {
    if (labelMappings && param in labelMappings) {
      const mappedText = labelMappings[param];
      setText(mappedText || '');
      return;
    }
    setText(ucFirst(param));
  }, [labelMappings, param]);

  if (last) {
    return (
      <span className="text-sm font-medium text-gray-400 cursor-default">
        {text}
      </span>
    );
  }

  return (
    <Link
      href={href}
      className="text-sm font-medium text-gray-400 hover:text-gray-200"
    >
      {text}
    </Link>
  );
}

export function DynamicBreadcrumbs({
  getTextGenerator,
  getDefaultTextGenerator = _defaultGetDefaultTextGenerator,
  labelMappings = {},
  hiddenCrumbs,
}: DynamicBreadcrumbsProps) {
  const router = useRouter();

  const breadcrumbs = useMemo(
    function generateBreadcrumbs() {
      const asPathNestedRoutes = generatePathParts(router.asPath);
      const pathnameNestedRoutes = generatePathParts(router.pathname);

      const crumblist = asPathNestedRoutes
        .map((subpath, idx) => {
          const param = pathnameNestedRoutes[idx]
            .replace('[', '')
            .replace(']', '');
          const href = `/${asPathNestedRoutes.slice(0, idx + 1).join('/')}`;
          return {
            href,
            param,
            query: router.query,
            textGenerator: getTextGenerator,
            labelMappings,
            text: getDefaultTextGenerator(subpath, href),
          };
        })
        .filter((crumb) => {
          if (hiddenCrumbs) {
            return !hiddenCrumbs.includes(crumb.param);
          }
          return true;
        });
      return [...crumblist];
    },
    [
      router.asPath,
      router.pathname,
      router.query,
      hiddenCrumbs,
      getTextGenerator,
      getDefaultTextGenerator,
      labelMappings,
    ]
  );

  return (
    <nav className="hidden sm:flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1">
        {breadcrumbs.map((crumb, idx) => (
          <li key={idx}>
            <div className={classNames('flex', idx > 0 ? 'items-center' : '')}>
              {idx > 0 && (
                <span className="text-sm font-medium text-gray-400 cursor-default mr-2">
                  /
                </span>
              )}
              <Crumb
                {...crumb}
                key={idx}
                last={idx === breadcrumbs.length - 1}
              />
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default DynamicBreadcrumbs;
