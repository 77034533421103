import { cn } from '@scannable/common';

interface UsagePercentageBarProps {
  percent: number;
}
export function UsagePercentageBar({ percent }: UsagePercentageBarProps) {
  let color = 'bg-brand-primary';
  if (percent >= 80) {
    color = 'bg-yellow-500';
  }
  if (percent >= 100) {
    color = 'bg-red-600';
  }
  const maxPercent = Math.min(percent, 100);
  return (
    <div className="w-full bg-gray-400 rounded-full h-2.5 mb-4">
      <div
        className={cn('h-2.5 rounded-full', color)}
        style={{
          width: `${maxPercent}%`,
        }}
      />
    </div>
  );
}

export default UsagePercentageBar;
