import { useMutation } from '@apollo/client';

import {
  ADD_TO_INVENTORY,
  ASSIGN_ITEM_TO_ME,
  ASSIGN_ITEM_TO_USER,
  ASSIGN_TO_USER_BY_GROUP,
  CHANGE_ITEM_SKU,
  CREATE_AND_ADD_TO_INVENTORY,
  REMOVE_INVENTORY_ITEM,
  REMOVE_ITEM_FROM_INVENTORY,
  UNASSIGN_ITEM,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useUnAssignItemMutation(
  options?: UseMutationOptions<typeof UNASSIGN_ITEM>
) {
  return useMutation(UNASSIGN_ITEM, options);
}

export function useAssignItemToMeMutation(
  options?: UseMutationOptions<typeof ASSIGN_ITEM_TO_ME>
) {
  return useMutation(ASSIGN_ITEM_TO_ME, options);
}

export function useAssignItemToUserMutation(
  options?: UseMutationOptions<typeof ASSIGN_ITEM_TO_USER>
) {
  return useMutation(ASSIGN_ITEM_TO_USER, options);
}

export function useAssignToUserByGroupMutation(
  options?: UseMutationOptions<typeof ASSIGN_TO_USER_BY_GROUP>
) {
  return useMutation(ASSIGN_TO_USER_BY_GROUP, options);
}

export function useCreateAndAddToInventoryMutation(
  options?: UseMutationOptions<typeof CREATE_AND_ADD_TO_INVENTORY>
) {
  return useMutation(CREATE_AND_ADD_TO_INVENTORY, options);
}

export function useAddToInventoryMutation(
  options?: UseMutationOptions<typeof ADD_TO_INVENTORY>
) {
  return useMutation(ADD_TO_INVENTORY, options);
}

export function useRemoveItemFromInventoryMutation(
  options?: UseMutationOptions<typeof REMOVE_ITEM_FROM_INVENTORY>
) {
  return useMutation(REMOVE_ITEM_FROM_INVENTORY, options);
}

export function useRemoveInventoryItemMutation(
  options?: UseMutationOptions<typeof REMOVE_INVENTORY_ITEM>
) {
  return useMutation(REMOVE_INVENTORY_ITEM, options);
}

export function useChangeItemSkuMutation(
  options?: UseMutationOptions<typeof CHANGE_ITEM_SKU>
) {
  return useMutation(CHANGE_ITEM_SKU, options);
}
