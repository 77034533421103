import { Inventory, ItemHistory, SerialisedProduct } from '@scannable/common';
import {
  useAssignItemToMeMutation,
  useUnAssignItemMutation,
} from '@scannable/frontend/common';

import { Button } from '../../atoms';
import { resolveMutation } from '../../lib/lib';

interface AssignToMeButtonProps {
  currentUserId: number | null;
  teamMemberId: number | null;
  serialisedProductId: number;
  onChange?: () => void;
}
export function AssignToMeButton({
  currentUserId,
  teamMemberId,
  serialisedProductId,
  onChange,
}: AssignToMeButtonProps) {
  const [assignItemToMe, { loading }] = useAssignItemToMeMutation({
    refetchQueries: [
      SerialisedProduct.SerialisedProductForUniqueUrl,
      Inventory.PaginatedWebInventory,
      ItemHistory.PaginatedItemHistory,
    ],
  });
  const [unAssignItem, { loading: unAssignedLoading }] =
    useUnAssignItemMutation({
      refetchQueries: [
        SerialisedProduct.SerialisedProductForUniqueUrl,
        Inventory.PaginatedWebInventory,
        ItemHistory.PaginatedItemHistory,
      ],
    });
  const isAssignedToMe = currentUserId === teamMemberId;
  const label = isAssignedToMe ? 'Unassign from Me' : 'Assign to Me';
  const handleChange = async () => {
    if (!isAssignedToMe) {
      await resolveMutation(
        assignItemToMe({
          variables: {
            serialisedProductId,
          },
        }),
        {
          successMessage: 'Assigned successfully',
          failureMessage: 'Failed to assign',
        }
      );
    }
    if (isAssignedToMe) {
      await resolveMutation(
        unAssignItem({
          variables: {
            serialisedProductId,
          },
        }),
        {
          successMessage: 'Unassigned successfully',
          failureMessage: 'Failed to unassign',
        }
      );
    }
    if (onChange) {
      onChange();
    }
  };
  return (
    <Button
      color="info"
      className="w-full"
      loading={loading || unAssignedLoading}
      type="button"
      onClick={handleChange}
    >
      {label}
    </Button>
  );
}

export default AssignToMeButton;
