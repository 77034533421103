import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { SIGN_IN_ROUTE } from '@scannable/common';
import { RESET_PASSWORD } from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { resolveMutation } from '../../lib/lib';
import { CustomForm } from '../../molecules/CustomForm/CustomForm';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';

export const resetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('A valid email address is required'),
  password: Yup.string()
    .min(8)
    .required('A password of at least 8 characters is required'),
  confirmPassword: Yup.string().test(
    'passwords-match',
    'Passwords must match',
    function (value) {
      return this.parent.password === value;
    }
  ),
});

export interface ResetPasswordFormValues {
  email: string;
  password: string;
  confirmPassword: string;
  token: string;
}

/* eslint-disable-next-line */
export interface ResetPasswordFormProps {
  token: string;
}

export function ResetPasswordForm({ token }: ResetPasswordFormProps) {
  const router = useRouter();
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      token: '',
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: async (
      values: ResetPasswordFormValues,
      actions: FormikHelpers<ResetPasswordFormValues>
    ) => {
      const { confirmPassword, ...returnValues } = values;

      returnValues.token = token;
      const result = await resolveMutation(
        resetPassword({
          variables: {
            data: {
              email: returnValues.email,
              password: returnValues.password,
              token: returnValues.token,
            },
          },
        }),
        {
          successMessage: 'Password successfully reset, please log in!',
        }
      );

      if (result.ok) {
        actions.setSubmitting(false);
        actions.resetForm();
        router.push(SIGN_IN_ROUTE);
      }
    },
  });
  return (
    <CustomForm className="mt-8 space-y-6" formik={formik}>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <InputFieldGroup
            {...{
              type: 'email',
              name: 'email',
              placeholder: 'Email address',
            }}
            formik={formik}
          />
          <InputFieldGroup
            {...{
              type: 'password',
              name: 'password',
              placeholder: 'Password',
            }}
            formik={formik}
          />
          <InputFieldGroup
            {...{
              type: 'password',
              name: 'confirmPassword',
              placeholder: 'Confirm password',
            }}
            formik={formik}
          />
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <Button
            label="Reset your password"
            type="submit"
            loading={formik.isSubmitting}
          />
        </div>
      </div>
    </CustomForm>
  );
}

export default ResetPasswordForm;
