import { useEffect, useRef } from 'react';

interface UseInfiniteScrollProps {
  loadMore: () => void;
  hasMore: boolean | undefined;
}

const useInfiniteScroll = ({ loadMore, hasMore }: UseInfiniteScrollProps) => {
  const loader = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      },
      { threshold: 1.0 }
    );

    const currentLoader = loader.current;

    if (currentLoader) {
      observer.observe(currentLoader);
    }

    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [loadMore, hasMore]);

  return loader;
};

export default useInfiniteScroll;
