import { SubscriptionStatus, SubscriptionType } from '@prisma/client';

import { SETTINGS_ROUTE, UPGRADE_ROUTE } from '@scannable/common';
import { daysLeft } from '@scannable/frontend/common';

import { Icon, Link } from '../../atoms';
import UpgradePlanButton from '../UpgradePlanButton/UpgradePlanButton';

export interface SubscriptionBadgeProps {
  subscriptionStatus: SubscriptionStatus | null;
  subscriptionType: SubscriptionType | null;
  isOnTrial: boolean;
  trialEnds: string | null;
}

export function SubscriptionBadge({
  subscriptionStatus,
  subscriptionType,
  isOnTrial,
  trialEnds,
}: SubscriptionBadgeProps) {
  if (subscriptionType === SubscriptionType.ACCESS) {
    return (
      <div className="pt-4 px-2">
        <UpgradePlanButton />
      </div>
    );
  }
  if (
    subscriptionType === SubscriptionType.ENTERPRISE ||
    subscriptionStatus === SubscriptionStatus.NONE ||
    !subscriptionStatus ||
    !subscriptionType
  ) {
    return null;
  }
  let status: string = subscriptionStatus;
  let link = SETTINGS_ROUTE;
  if (subscriptionStatus === SubscriptionStatus.TRIAL) {
    status = daysLeft(trialEnds);
    link = UPGRADE_ROUTE;
  }
  if (
    subscriptionStatus === SubscriptionStatus.CANCELLED ||
    subscriptionStatus === SubscriptionStatus.CANCEL_AT_PERIOD_END
  ) {
    status = 'Cancelled';
  }
  if (subscriptionStatus === SubscriptionStatus.ACTIVE) {
    status = 'Active';
  }

  return (
    <Link href={link}>
      <div className="pt-4 pb-2 px-4 flex">
        {/* <span className="bg-scannable-lime font-medium py-1 px-3 rounded-lg">
          {subscriptionType}
        </span> */}
        <Icon name="pro" width={50} className="text-black" />
        <div>
          <span className="ml-2 text-sm leading-4 text-gray-800 font-medium block">
            {status}
          </span>
          <span className="ml-2 text-xs leading-4 text-gray-500 block ">
            {isOnTrial ? 'Upgrade now' : 'View plan'}
          </span>
        </div>
      </div>
    </Link>
  );
}

export default SubscriptionBadge;
