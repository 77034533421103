import { useState } from 'react';

import { useGetUserNotificationPreferencesQuery } from '@scannable/frontend/common';

import { useModal } from '../../modals/ModalContext/ModalContext';
import NotificationPreferencesModal from '../../modals/NotificationPreferencesModal/NotificationPreferencesModal';
import { useFeatureFlag } from '../useFeatureFlag/useFeatureFlag';

export function useNotifications() {
  const { showModal, hideModal } = useModal();
  const [shown, setShown] = useState(false);
  const isNotificationsEnabled = useFeatureFlag('notifications');

  useGetUserNotificationPreferencesQuery({
    onCompleted: (result) => {
      if (result.preferences?.length === 0 && !shown) {
        setShown(true);
        showModal(
          <NotificationPreferencesModal
            onDismiss={hideModal}
            preferences={result.preferences}
          />
        );
      }
    },
    skip: !isNotificationsEnabled,
  });
}

export default useNotifications;
