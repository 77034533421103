import { useMutation, useQuery } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { ProductCategories } from '@scannable/common';
import {
  CREATE_PRODUCT_GROUP,
  PRODUCT_CATEGORIES,
  UPDATE_PRODUCT_GROUP,
} from '@scannable/graphql-queries';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export type ProductCategoryCreateValues = {
  name: string;
  description: string;
  productGroupCategoryId: number | null;
  hidden: boolean;
};
type ProductCategoryModalProps = {
  id?: number;
} & Partial<ProductCategoryCreateValues>;

export function ProductCategoryModal({
  id,
  name,
  description,
  productGroupCategoryId,
  hidden,
}: ProductCategoryModalProps) {
  const { data } = useQuery(PRODUCT_CATEGORIES);

  const options = data?.productGroupCategories
    ? data.productGroupCategories.map((pgc) => ({
        label: pgc.name,
        value: pgc.id,
      }))
    : [];

  const [createProductCategory] = useMutation(CREATE_PRODUCT_GROUP, {
    refetchQueries: [ProductCategories.PaginatedProductCategories],
  });
  const [updateProductCategory] = useMutation(UPDATE_PRODUCT_GROUP, {
    refetchQueries: [ProductCategories.PaginatedProductCategories],
  });
  const { hideModal } = useModal();

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      description: description ?? '',
      hidden: hidden ?? false,
      productGroupCategoryId: productGroupCategoryId ?? null,
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
      productGroupCategoryId: yup
        .number()
        .nullable()
        .required('Please select a category'),
    }),
    onSubmit: async (
      values: ProductCategoryCreateValues,
      actions: FormikHelpers<ProductCategoryCreateValues>
    ) => {
      const data = {
        name: values.name,
        description: values.description,
        productGroupCategoryId: Number(values.productGroupCategoryId),
        hidden: values.hidden,
      };
      const result = await resolveMutation(
        id
          ? updateProductCategory({
              variables: {
                data: { ...data, id },
              },
            })
          : createProductCategory({
              variables: {
                data,
              },
            }),
        {
          successMessage: id
            ? 'Update product category'
            : 'Created product category',
        }
      );
      if (result.ok) {
        hideModal();
      }
      actions.setSubmitting(false);
    },
  });

  return (
    <Modal
      overflowHidden={false}
      title={id ? 'Update Product Category' : 'Create Product Category'}
    >
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: id ? 'Update' : 'Create',
            type: 'submit',
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup type="text" name="name" label="Name" formik={formik} />
        <InputFieldGroup
          type="textarea"
          name="description"
          label="Description"
          formik={formik}
        />
        <InputFieldGroup
          type="select"
          name="productGroupCategoryId"
          label="Product Group Category"
          formik={formik}
          options={options}
        />
        <InputFieldGroup
          type="radio"
          name="hidden"
          label="Product Group Access"
          options={[
            {
              label: 'Product group is active',
              value: false,
            },
            {
              label: 'Product group is hidden on the public facing app',
              value: true,
            },
          ]}
          formik={formik}
        />
      </ModalForm>
    </Modal>
  );
}

export default ProductCategoryModal;
