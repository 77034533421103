import toast from 'react-hot-toast';

import { ItemAction, itemUidUrl, Role } from '@scannable/common';
import {
  ActionType,
  h,
  UseItemScreenData,
  useRemoveInventoryItemMutation,
} from '@scannable/frontend/common';
import { reload } from '@scannable/frontend/store';

import { useIsAccess, useRoleGuard } from '../../auth/AuthContext/AuthContext';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import {
  ConfirmationModal,
  EditItemInspectionFrequencyModal,
  useModal,
} from '../../modals';
import {
  AssignGroupsSidePanel,
  CreateInspectionSidePanel,
  EditItemSidePanel,
  useSidePanel,
} from '../../side-panels';
import { OptionsMenu } from '../OptionsMenu/OptionsMenu';

export interface ItemOptionsMenuProps {
  item?: UseItemScreenData;
}

export function ItemOptionsMenu({ item }: ItemOptionsMenuProps) {
  const { t } = useTranslation();
  const isAccess = useIsAccess();
  const roleGuard = useRoleGuard();
  const { showModal, hideModal } = useModal();
  const { showSidePanel, showItemSidePanel, hideSidePanel } = useSidePanel();

  const [removeItemFromInventory] = useRemoveInventoryItemMutation({
    onCompleted: () => {
      reload();
      hideModal();
    },
  });

  const onBack = (id: number) => {
    showItemSidePanel({
      serialisedProductId: id,
      showOptionsButton: true,
      showBanner: false,
      onBack: () => {
        hideSidePanel();
      },
    });
  };

  if (!item) {
    return null;
  }
  const previewInfo = {
    uri: item.data?.images?.[0]?.uri,
    serialNumber: item?.serialNumber,
    code: item?.info?.code,
    name: item?.info?.name,
    productName: item?.info?.productName,
    manufacturerName: item?.info?.manufacturerName,
    hasNfc: Boolean(item?.info?.nfcTag),
  };
  return (
    <OptionsMenu
      actions={[
        {
          label: h(t('share')),
          icon: 'share',
          item,
          callback: (item) => {
            if (item?.uid) {
              if (!navigator.canShare || !navigator.canShare()) {
                navigator.clipboard.writeText(itemUidUrl(item.uid));
                toast.success('Link copied to clipboard');
              } else {
                navigator.share({
                  title: item.info?.name || '',
                  text: item.info?.name || '',
                  url: itemUidUrl(item.uid),
                });
              }
            }
          },
        },
        {
          label: h(t('inspect')),
          icon: 'inspect',
          key: ItemAction.inspect,
          callback: (item) => {
            showSidePanel(
              <CreateInspectionSidePanel
                id={item.id}
                actionType={ActionType.SingleItem}
                isMultiScanInspection={false}
                {...previewInfo}
              />,
              {
                title: t('inspections.title'),
                onBack: () => onBack(item.id),
              }
            );
          },
          item,
        },
        {
          label: h(t('update_information')),
          icon: 'updateInformation',
          item,
          display: roleGuard([Role.Admin, Role.Member]),
          callback: (item) =>
            showSidePanel(
              <EditItemSidePanel id={item?.id} {...previewInfo} />,
              {
                title: t('update_information'),
                onBack: () => onBack(item.id),
              }
            ),
        },
        {
          label: h(t('actions.assign_update_groups')),
          icon: 'addToGroup',
          item,
          display: !isAccess && roleGuard([Role.Admin, Role.Member]),
          callback: (item) => {
            showSidePanel(
              <AssignGroupsSidePanel
                id={item?.id}
                assignedUserId={item?.info?.assignedUserId}
                {...previewInfo}
              />,
              {
                title: h(t('actions.assign_update_groups')),
                onBack: () => onBack(item.id),
              }
            );
          },
        },
        {
          label: h(t('actions.set_inspection_frequency')),
          icon: 'notifications',
          display: !isAccess && roleGuard([Role.Admin, Role.Member]),
          callback: (item) =>
            showModal(
              <EditItemInspectionFrequencyModal
                title={t('actions.set_inspection_frequency')}
                serialisedProductId={item.id}
              />
            ),
          item,
        },
        {
          label: h(t('actions.remove_item_from_inventory')),
          icon: 'removeFromInventory',
          type: 'danger',
          key: ItemAction.removeItemFromInventory,
          display: roleGuard([Role.Admin]),
          callback: (item) =>
            showModal(
              <ConfirmationModal
                title="Are you sure you want to remove this item from inventory?"
                buttonLabel="Remove"
                onConfirm={async () => {
                  await resolveMutation(
                    removeItemFromInventory({
                      variables: {
                        id: item.id,
                      },
                    }),
                    {
                      successMessage: 'Item removed from inventory',
                    }
                  );
                }}
              />
            ),
          item,
        },
      ]}
    />
  );
}

export default ItemOptionsMenu;
