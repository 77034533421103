export interface ContentWrapperProps {
  children: React.ReactNode;
  testId?: string;
}

export function ContentWrapper({ children, testId }: ContentWrapperProps) {
  return (
    <div className="mt-9" data-cy={testId}>
      {children}
    </div>
  );
}

export default ContentWrapper;
