import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { SortOrder } from '@scannable/frontend/common';
import {
  GET_GROUPS_WITH_CATEGORIES,
  MOVE_ALL_PRODUCTS_TO_GROUP,
} from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { resolveMutation } from '../../lib/lib';
import { CustomForm } from '../../molecules/CustomForm/CustomForm';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import SimpleModal from '../SimpleModal/SimpleModal';

interface MoveProductsToGroupsModalProps {
  currentGroupId: number;
  onCompleted?: () => void;
}

export function MoveProductsToGroupsModal({
  onCompleted,
  currentGroupId,
}: MoveProductsToGroupsModalProps) {
  const [moveProductsToGroup] = useMutation(MOVE_ALL_PRODUCTS_TO_GROUP, {
    onCompleted,
  });
  const { data } = useQuery(GET_GROUPS_WITH_CATEGORIES, {
    variables: {
      orderBy: [{ name: SortOrder.asc }],
    },
    fetchPolicy: 'cache-and-network',
  });

  const formik = useFormik({
    initialValues: {
      productGroupId: 0,
    },
    validationSchema: Yup.object({
      productGroupId: Yup.number().required('Please select a product group'),
    }),

    async onSubmit({ productGroupId }: { productGroupId: number }) {
      // find the product group
      const productGroupData = data?.productGroups?.find(
        (pg) => pg.id === Number(productGroupId)
      );
      const productCategoryId = productGroupData?.productGroupCategory?.id;

      if (productGroupId && productCategoryId) {
        await resolveMutation(
          moveProductsToGroup({
            variables: {
              data: {
                currentGroupId,
                productCategoryId,
                productGroupId,
              },
            },
          }),
          {
            successMessage: 'Products moved successfully',
          }
        );
      }
    },
  });

  return (
    <SimpleModal
      title="Move Products to new group"
      size="lg"
      overflowHidden={false}
    >
      <CustomForm formik={formik} className="w-96 mt-10">
        <InputFieldGroup
          id="productGroupId"
          name="productGroupId"
          type="react-select"
          formik={formik}
          label="Product group"
          className="w-full"
          options={data?.productGroups?.map((productGroup) => ({
            value: productGroup?.id,
            label: `${productGroup?.name} (${productGroup?.productGroupCategory?.name})`,
          }))}
        />
        <Button type="submit" size="xl" color="primary" className="mt-10">
          Move Products
        </Button>
      </CustomForm>
    </SimpleModal>
  );
}

export default MoveProductsToGroupsModal;
