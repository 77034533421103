import { GroupType } from '@prisma/client';
import { ResultOf } from 'gql.tada';

import {
  GET_ALL_GROUPS,
  GET_GROUPS_BY_SERIALISED_PRODUCT_ID,
} from '@scannable/graphql-queries';

export type InventoryGroupType =
  | 'KIT_BAG'
  | 'LOCATION'
  | 'VEHICLE'
  | 'CLIENT'
  | 'STAFF'
  | 'EQUIPMENT_TYPE';

export const inventoryGroups: InventoryGroupType[] = [
  GroupType.KIT_BAG,
  GroupType.LOCATION,
  GroupType.VEHICLE,
  GroupType.CLIENT,
  GroupType.STAFF,
  GroupType.EQUIPMENT_TYPE,
];

export type ItemGroups = NonNullable<
  ResultOf<
    typeof GET_GROUPS_BY_SERIALISED_PRODUCT_ID
  >['groupsBySerialisedProductId']
>;

export type AllGroups = NonNullable<ResultOf<typeof GET_ALL_GROUPS>['groups']>;
