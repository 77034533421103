import { useState } from 'react';
import { ServerError } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';

import {
  Inventory,
  ItemHistory,
  Manufacturer,
  ProductCategories,
} from '@scannable/common';
import {
  h,
  useAddToInventoryMutation,
  useCreateAndAddToInventoryMutation,
} from '@scannable/frontend/common';
import { Search } from '@scannable/frontend/types';

import Alert from '../../atoms/Alert/Alert';
import { InputText } from '../../atoms/InputText/InputText';
import { SidePanelForm } from '../../forms';
import { useTranslation, useValidateSerial } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup, SearchResultItem } from '../../molecules';
import SearchInput from '../../organisms/SearchInput/SearchInput';
import Tabs, { Tab, TabProvider } from '../../organisms/Tabs/Tabs';
import { useSidePanel } from '../SidePanelContext/SidePanelContext';
import { withSidePanelLoading } from '../withSidePanelLoading/withSidePanelLoading';
import {
  InventoryItemSidePanelProps,
  InventoryItemValues,
  validationSchema,
} from './InventoryItemSidePanel.types';

function SidePanel({ productVariation }: InventoryItemSidePanelProps) {
  const { t } = useTranslation();
  const { hideSidePanel, showItemSidePanel } = useSidePanel();
  const refetchQueries = [
    Inventory.PaginatedWebInventory,
    ProductCategories.FindUserInventoryProductGroups,
    ProductCategories.FindInventoryProductGroups,
    Manufacturer.InventoryManufacturers,
    ItemHistory.PaginatedItemHistory,
  ];
  const [createAndAddToInventory] = useCreateAndAddToInventoryMutation({
    refetchQueries,
  });
  const [addToInventory] = useAddToInventoryMutation({
    refetchQueries,
  });
  const [result, setResult] = useState<Search | null>(productVariation ?? null);

  const createSerial = async (values: InventoryItemValues) => {
    return resolveMutation(
      createAndAddToInventory({
        variables: {
          data: {
            serialNumber: values.serialNumber,
            batchNumber: values.batchNumber,
            dom: values.dom,
            dofu: values.dofu,
            productVariationId: values.productVariationId as number,
            confirmDuplicateSerial: values.confirmDuplicateSerial,
            externalId: values.externalId,
          },
        },
      }),
      {
        successMessage: 'Item added to inventory',
      }
    );
  };

  const {
    confirmEmptySerial,
    setIsEmptySerial,
    setIsDuplicateSerial,
    getMessageAndAction,
  } = useValidateSerial();

  const formik = useFormik({
    initialValues: {
      serialNumber: '',
      batchNumber: '',
      productVariationId: productVariation?.id ?? null,
      dom: undefined,
      dofu: undefined,
      externalId: '',
    } as InventoryItemValues,
    validationSchema,
    onSubmit: async (
      values: InventoryItemValues,
      actions: FormikHelpers<InventoryItemValues>
    ) => {
      if (
        !confirmEmptySerial &&
        !values.serialNumber &&
        result?.info?.productType !== 'ASSEMBLY'
      ) {
        setIsEmptySerial(true);
        return;
      }

      const serialResult = await createSerial(values);
      actions.setSubmitting(false);

      if (serialResult && !serialResult.ok) {
        const isDuplicateSerial =
          serialResult.error?.message === 'Serial number already exists' ||
          (serialResult.error?.networkError as ServerError)?.statusCode === 409;

        if (isDuplicateSerial) {
          setIsDuplicateSerial(true);
        }
      }
      if (serialResult.ok) {
        if (serialResult?.data?.createAndAddToInventory?.id) {
          showItemSidePanel({
            serialisedProductId: serialResult.data.createAndAddToInventory.id,
            showBanner: false,
            showOptionsButton: true,
          });
          return;
        }
        hideSidePanel();
      }
    },
  });

  const setProductVariation = (productVariation: Search) => {
    setResult(productVariation);
    formik.setFieldValue('productVariationId', productVariation.id);
  };

  const { message, primaryButtonLabel, primaryButtonAction } =
    getMessageAndAction(
      () => {
        formik.setFieldValue('confirmDuplicateSerial', true);
      },
      () => formik.submitForm()
    );

  return (
    <SidePanelForm
      formik={formik}
      buttons={[
        {
          label: primaryButtonLabel,
          type: 'submit',
          loading: formik.isSubmitting,
          onClick: primaryButtonAction,
          color: 'primary',
          'data-cy': 'save-item',
        },
      ]}
    >
      <TabProvider defaultTab="sku">
        <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
          <Tabs />
        </div>

        <Tab name="sku" label="A product (SKU)">
          {result && <SearchResultItem result={result} />}
          <div className="space-y-3 mt-3">
            {!productVariation && (
              <SearchInput
                name="productVariationId"
                showScannerInput={false}
                addToCallback={setProductVariation}
                resetAfterCallback
              />
            )}
            {formik.errors['productVariationId'] && (
              <InputText
                name="productVariationId"
                type="error"
                message={formik.errors['productVariationId']}
              />
            )}

            <InputFieldGroup
              id="SerialNumber"
              name="serialNumber"
              type="camera"
              formik={formik}
              label="Serial number"
              onChange={(e) => {
                formik.handleChange(e);
                setIsDuplicateSerial(false);
                setIsEmptySerial(false);
              }}
            />

            <InputFieldGroup
              id="BatchNumber"
              name="batchNumber"
              type="camera"
              formik={formik}
              label="Batch number"
            />
            <InputFieldGroup
              id="DateOfManufacture"
              name="dom"
              type="date"
              formik={formik}
              label="Date of manufacture"
              config={{
                showMonthDropdown: true,
                showYearDropdown: true,
              }}
            />
            <InputFieldGroup
              id="DateOfFirstUse"
              name="dofu"
              type="date"
              formik={formik}
              label="Date of first use"
              config={{
                showMonthDropdown: true,
                showYearDropdown: true,
              }}
            />
            <InputFieldGroup
              id="externalId"
              name="externalId"
              type="camera"
              formik={formik}
              label={h(t('external_id'))}
            />
          </div>
          {message && (
            <div
              className="pt-4 text-sm"
              data-cy="confirm-duplicate-serial-prompt"
            >
              <Alert type="warning" message={message} />
            </div>
          )}
        </Tab>
        <Tab
          name="serial"
          label="A serial number or NFC"
          dataCy="add-existing-item"
        >
          <div className="mt-5 space-y-2 p-5">
            <SearchInput
              name="serialisedProductId"
              data-cy="serial-number"
              type="GlobalItemsOnly"
              buttonLabel="Add"
              showScannerInput={false}
              addToCallback={async (item) => {
                await resolveMutation(
                  addToInventory({
                    variables: {
                      data: {
                        serialisedProductId: item.serialisedProductId as number,
                      },
                    },
                  }),
                  {
                    successMessage: 'Item added to inventory',
                  }
                );
              }}
              resetAfterCallback
            />
          </div>
        </Tab>
      </TabProvider>
    </SidePanelForm>
  );
}

const InventoryItemSidePanel = withSidePanelLoading(SidePanel);

export function CreateInventoryItemSidePanel(
  props: InventoryItemSidePanelProps
) {
  return <InventoryItemSidePanel {...props} />;
}
