import { cn } from '@scannable/common';

import { ReactComponent as IconAddNfc } from './icons/add-nfc.svg';
import { ReactComponent as IconAddToGroup } from './icons/add-to-group.svg';
import { ReactComponent as IconAddToInventory } from './icons/add-to-inventory.svg';
import { ReactComponent as IconAssign } from './icons/assign.svg';
import { ReactComponent as IconBookOpen } from './icons/book-open.svg';
import { ReactComponent as IconClients } from './icons/clients.svg';
import { ReactComponent as IconComponents } from './icons/components.svg';
import { ReactComponent as IconCreateAssemblies } from './icons/create-assemblies.svg';
import { ReactComponent as IconCreateSerials } from './icons/create-serials.svg';
import { ReactComponent as IconDashboard } from './icons/dashboard.svg';
import { ReactComponent as IconEquipmentType } from './icons/equipment-type.svg';
import { ReactComponent as IconGps } from './icons/gps.svg';
import { ReactComponent as IconGroup } from './icons/group.svg';
import { ReactComponent as IconHasNfc } from './icons/has-nfc.svg';
import { ReactComponent as IconHistory } from './icons/history.svg';
import { ReactComponent as IconInfo } from './icons/info.svg';
import { ReactComponent as IconInspect } from './icons/inspect.svg';
import { ReactComponent as IconInspectionReports } from './icons/inspection-reports.svg';
import { ReactComponent as IconInstructionsAdded } from './icons/instructions-added.svg';
import { ReactComponent as IconKitBag } from './icons/kit-bag.svg';
import { ReactComponent as IconKitList } from './icons/kit-list.svg';
import { ReactComponent as IconLocation } from './icons/location.svg';
import { ReactComponent as IconMap } from './icons/map.svg';
import { ReactComponent as IconMultiScan } from './icons/multi-scan.svg';
import { ReactComponent as IconMyInventory } from './icons/my-inventory.svg';
import { ReactComponent as IconNfcDisabled } from './icons/nfc-disabled.svg';
import { ReactComponent as IconNfcScan } from './icons/nfc-scan.svg';
import { ReactComponent as IconNotifications } from './icons/notifications.svg';
import { ReactComponent as IconOrganisations } from './icons/organisations.svg';
import { ReactComponent as IconPassedInspection } from './icons/passed-inspection.svg';
import { ReactComponent as IconPhotoAdded } from './icons/photo-added.svg';
import { ReactComponent as IconPro } from './icons/pro.svg';
import { ReactComponent as IconProducers } from './icons/producers.svg';
import { ReactComponent as IconProductDataCategories } from './icons/product-data-categories.svg';
import { ReactComponent as IconProductSearch } from './icons/product-search.svg';
import { ReactComponent as IconProductsCategories } from './icons/products-categories.svg';
import { ReactComponent as IconProducts } from './icons/products.svg';
import { ReactComponent as IconRemoveFromGroup } from './icons/remove-from-group.svg';
import { ReactComponent as IconRemoveFromInventory } from './icons/remove-from-inventory.svg';
import { ReactComponent as IconScan } from './icons/scan.svg';
import { ReactComponent as IconScannable } from './icons/scannable-icon.svg';
import { ReactComponent as IconScannableSquircle } from './icons/scannable-squircle.svg';
import { ReactComponent as IconSerialFormat } from './icons/serial-format.svg';
import { ReactComponent as IconSerialNumberClaimed } from './icons/serial-number-claimed.svg';
import { ReactComponent as IconSerialReport } from './icons/serial-report.svg';
import { ReactComponent as IconSettings } from './icons/settings.svg';
import { ReactComponent as IconShare } from './icons/share.svg';
import { ReactComponent as IconSpecs } from './icons/specs.svg';
import { ReactComponent as IconStaff } from './icons/staff.svg';
import { ReactComponent as IconTeam } from './icons/team.svg';
import { ReactComponent as IconUnassign } from './icons/unassign.svg';
import { ReactComponent as IconUpdateInformation } from './icons/update-information.svg';
import { ReactComponent as IconUser } from './icons/user.svg';
import { ReactComponent as IconUsers } from './icons/users.svg';
import { ReactComponent as IconVehicles } from './icons/vehicles.svg';
import { ReactComponent as IconWaves } from './icons/waves.svg';

export type IconType =
  | 'addNfc'
  | 'kitList'
  | 'removeFromInventory'
  | 'addToGroup'
  | 'location'
  | 'scan'
  | 'addToInventory'
  | 'map'
  | 'scannableSquircle'
  | 'assign'
  | 'multiScan'
  | 'serialFormat'
  | 'clients'
  | 'myInventory'
  | 'serialNumberClaimed'
  | 'components'
  | 'nfcDisabled'
  | 'serialReport'
  | 'createAssemblies'
  | 'nfcScan'
  | 'settings'
  | 'createSerials'
  | 'notifications'
  | 'share'
  | 'dashboard'
  | 'organisations'
  | 'specs'
  | 'gps'
  | 'passedInspection'
  | 'staff'
  | 'group'
  | 'photoAdded'
  | 'team'
  | 'history'
  | 'producers'
  | 'unassign'
  | 'info'
  | 'productDataCategories'
  | 'updateInformation'
  | 'inspect'
  | 'productSearch'
  | 'equipmentType'
  | 'inspectionReports'
  | 'productsCategories'
  | 'user'
  | 'instructionsAdded'
  | 'products'
  | 'users'
  | 'kitBag'
  | 'removeFromGroup'
  | 'vehicles'
  | 'pro'
  | 'target'
  | 'bell'
  | 'categories'
  | 'data'
  | 'inspections'
  | 'locations'
  | 'tasks'
  | 'scannable'
  | 'waves'
  | 'hasNfc'
  | 'bookOpen';

export interface IconProps {
  name: IconType;
  color?: string;
  className?: string;
  width?: number;
  height?: number;
}

export function Icon({
  name,
  color,
  className,
  width,
  height,
  ...props
}: IconProps) {
  const iconWidth = width ?? 24;
  const iconHeight = height ?? 24;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        {
          hasNfc: (
            <IconHasNfc
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              // viewBox="0 0 24 25"
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          addNfc: (
            <IconAddNfc
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          kitList: (
            <IconKitList
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          removeFromInventory: (
            <IconRemoveFromInventory
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          addToGroup: (
            <IconAddToGroup
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          location: (
            <IconLocation
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          scan: (
            <IconScan
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          addToInventory: (
            <IconAddToInventory
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          map: (
            <IconMap
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          scannableSquircle: (
            <IconScannableSquircle
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          assign: (
            <IconAssign
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          multiScan: (
            <IconMultiScan
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          serialFormat: (
            <IconSerialFormat
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          clients: (
            <IconClients
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          myInventory: (
            <IconMyInventory
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          serialNumberClaimed: (
            <IconSerialNumberClaimed
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          components: (
            <IconComponents
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          nfcDisabled: (
            <IconNfcDisabled
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          serialReport: (
            <IconSerialReport
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          createAssemblies: (
            <IconCreateAssemblies
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          nfcScan: (
            <IconNfcScan
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          settings: (
            <IconSettings
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          createSerials: (
            <IconCreateSerials
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          notifications: (
            <IconNotifications
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          share: (
            <IconShare
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          dashboard: (
            <IconDashboard
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          organisations: (
            <IconOrganisations
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          specs: (
            <IconSpecs
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          gps: (
            <IconGps
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          passedInspection: (
            <IconPassedInspection
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          staff: (
            <IconStaff
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          group: (
            <IconGroup
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          photoAdded: (
            <IconPhotoAdded
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          team: (
            <IconTeam
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          history: (
            <IconHistory
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          producers: (
            <IconProducers
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          unassign: (
            <IconUnassign
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          info: (
            <IconInfo
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          productDataCategories: (
            <IconProductDataCategories
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          updateInformation: (
            <IconUpdateInformation
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          inspect: (
            <IconInspect
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          productSearch: (
            <IconProductSearch
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          equipmentType: (
            <IconEquipmentType
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          inspectionReports: (
            <IconInspectionReports
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          productsCategories: (
            <IconProductsCategories
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          user: (
            <IconUser
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          instructionsAdded: (
            <IconInstructionsAdded
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          products: (
            <IconProducts
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          users: (
            <IconUsers
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          kitBag: (
            <IconKitBag
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          removeFromGroup: (
            <IconRemoveFromGroup
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          vehicles: (
            <IconVehicles
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          pro: (
            <IconPro
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          target: (
            <IconScan
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          bell: (
            <IconNotifications
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          categories: (
            <IconProductsCategories
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          data: (
            <IconProductDataCategories
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          inspections: (
            <IconInspect
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          locations: (
            <IconLocation
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          tasks: (
            <IconInstructionsAdded
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          scannable: (
            <IconScannable
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          waves: (
            <IconWaves
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
          bookOpen: (
            <IconBookOpen
              width={iconWidth}
              height={iconHeight}
              stroke={color}
              className={cn('scan-icon', className || '')}
              {...props}
            />
          ),
        }[name]
      }
    </>
  );
}

export default Icon;
