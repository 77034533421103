import { useLazyQuery } from '@apollo/client';

import { graphql } from '@scannable/graphql-queries';

const filter = {
  serialisedProductVariations: {
    some: {
      productVariation: {
        is: {
          id: {
            in: '{VAL}',
          },
        },
      },
    },
  },
};

export const SEARCH_GROUPS = graphql(`
  query SearchProductVariations($search: String!, $take: Int!) {
    searchProductVariations(search: $search, take: $take) {
      id
      name
      code
    }
  }
`);

export function useProductVariationFilter() {
  const [searchGroups, { loading }] = useLazyQuery(SEARCH_GROUPS);
  const loadOptions = async (
    search: string
  ): Promise<
    {
      label: string;
      value: number;
    }[]
  > => {
    const { data } = await searchGroups({
      variables: { search, take: 10 },
    });
    return data?.searchProductVariations
      ? data.searchProductVariations.map((variant) => ({
          label: variant.name || variant.code,
          value: variant.id,
        }))
      : [];
  };

  return {
    interpolatedFilter: filter,
    options: loadOptions,
    isLoading: loading,
  };
}
