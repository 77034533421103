import { useCallback, useEffect, useMemo } from 'react';

import {
  findSerialNumberByManufacturer,
  formatDateForAPI,
  isManufacturerName,
  ISO_8601_DATE_FORMAT,
} from '@scannable/common';

import { usePrevious } from '../usePrevious/usePrevious';

export const useSuggestedDOMDate = ({
  manufacturerName,
  serialNumber,
}: {
  manufacturerName?: string | null;
  serialNumber?: string;
}) => {
  if (!serialNumber) {
    return null;
  }
  if (!isManufacturerName(manufacturerName)) {
    return null;
  }

  try {
    const result = findSerialNumberByManufacturer(
      manufacturerName,
      serialNumber
    );

    return result?.date || null;
  } catch (e) {
    // no need to do anything here if the serial format isn't valid
    return null;
  }
};

export const useSuggestedDOMDateWithError = ({
  manufacturerName,
  serialNumber,
}: {
  manufacturerName?: string;
  serialNumber?: string;
}) => {
  const data = {
    result: null,
    error: null,
  };
  if (!serialNumber) {
    return data;
  }

  if (!isManufacturerName(manufacturerName)) {
    return data;
  }
  try {
    const result = findSerialNumberByManufacturer(
      manufacturerName,
      serialNumber
    );

    return {
      result: result?.date || null,
      error: null,
    };
  } catch (error) {
    return {
      result: null,
      error: error instanceof Error ? error.message : 'Could not calculate DOM',
    };
  }
};

const useHasChanged = <T>(value: T, callback: (value: T) => void) => {
  const previous = usePrevious(value);
  const hasChanged = previous !== value;
  useEffect(() => {
    if (hasChanged && value) {
      callback(value);
    }
  }, [callback, hasChanged, value]);
  return [hasChanged, previous];
};

export const useShouldUpdateToSuggestedDOM = ({
  manufacturerName,
  serialNumber,
  onUpdate,
  dom,
}: {
  manufacturerName?: string | null;
  serialNumber?: string;
  dom: string | null;
  onUpdate: (suggestedDom: string) => void;
}) => {
  const suggestedDOM = useSuggestedDOMDate({
    manufacturerName,
    serialNumber,
  });

  const suggestedDOMFormatted = useMemo(() => {
    return suggestedDOM
      ? formatDateForAPI(suggestedDOM, ISO_8601_DATE_FORMAT)
      : '';
  }, [suggestedDOM]);

  const handleUpdate = useCallback(
    (updatedDOM: string) => {
      if (!dom) {
        onUpdate(formatDateForAPI(new Date(updatedDOM), ISO_8601_DATE_FORMAT));
      }
    },
    [onUpdate, dom]
  );

  useHasChanged<string>(suggestedDOMFormatted, handleUpdate);

  const isUsingSuggestedDOM = Boolean(
    suggestedDOMFormatted && suggestedDOMFormatted === dom
  );

  return {
    isUsingSuggestedDOM,
  };
};
