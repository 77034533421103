import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { FormikProps } from 'formik';

import { CreateSerialisedRopeValues } from '@scannable/frontend/types';
import {
  MANUFACTURER_SPLICES,
  ORIGINAL_ROPE,
} from '@scannable/graphql-queries';

import { Button, Text } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import { Form } from '../Form/Form';

export interface AddSerialisedRopeFormProps<V> {
  formik: FormikProps<V>;
}

export function AddSerialisedRopeForm({
  formik,
}: AddSerialisedRopeFormProps<CreateSerialisedRopeValues>) {
  const { manufacturerId } = useAuth();
  const [createNew, setCreateNew] = useState(false);
  const { data } = useQuery(MANUFACTURER_SPLICES, {
    variables: {
      manufacturerId: Number(manufacturerId),
    },
    skip: !manufacturerId,
  });

  const { data: ropeData } = useQuery(ORIGINAL_ROPE, {
    variables: {
      manufacturerId: Number(manufacturerId),
      ropeId: formik.values.productVariation.id,
    },
    skip: !manufacturerId,
  });
  return (
    <Form formik={formik} data-cy="add-serialised-rope">
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <InputFieldGroup
                id="BatchNumber"
                name="batchNumber"
                type="text"
                formik={formik}
                label="Box batch number"
              />
              <InputFieldGroup
                id="DateOfManufacture"
                name="dateOfManufacture"
                type="date"
                formik={formik}
                label="Date of manufacture"
                inline
              />
            </div>
            <div className="sm:grid sm:grid-cols-2 sm:items-end sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <InputFieldGroup
                data-cy="existing-rope"
                name="existingProductId"
                type="select"
                label="Existing rope"
                formik={formik}
                options={
                  ropeData?.productVariations
                    ? ropeData.productVariations.map((pv) => ({
                        label: `${pv.name ? `${pv.name} - ` : ''}${pv.code}`,
                        value: Number(pv.id),
                      }))
                    : []
                }
              />
              <Text>
                <span
                  className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  data-cy="create-new-rope"
                  onClick={() => setCreateNew(!createNew)}
                >
                  {!createNew
                    ? 'Or create a new rope'
                    : 'Or select an existing rope'}
                </span>
              </Text>
            </div>
            {createNew && (
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <InputFieldGroup
                  id="Length"
                  name="length"
                  type="number"
                  formik={formik}
                  label="Length"
                />
                <InputFieldGroup
                  id="LengthUnit"
                  name="lengthUnit"
                  type="select"
                  formik={formik}
                  label="Length Unit"
                  options={[
                    {
                      value: 'm',
                      label: 'Meters',
                    },
                    {
                      value: 'cm',
                      label: 'Centimeters',
                    },
                    {
                      value: 'ft',
                      label: 'Feet',
                    },
                    {
                      value: 'in',
                      label: 'Inches',
                    },
                  ]}
                />
                <InputFieldGroup
                  name="spliceId"
                  type="select"
                  label="Splice"
                  formik={formik}
                  options={
                    data?.productVariations
                      ? data.productVariations.map((pv) => ({
                          label: `${pv.name || pv.code}`,
                          value: Number(pv.id),
                        }))
                      : []
                  }
                />
                <InputFieldGroup
                  id="productCode"
                  name="productCode"
                  type="text"
                  formik={formik}
                  label="Part Number"
                />
                <InputFieldGroup
                  id="SkuName"
                  name="skuName"
                  type="text"
                  formik={formik}
                  label="SKU Name"
                />
              </div>
            )}

            <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <InputFieldGroup
                id="SerialQuantity"
                name="serialQuantity"
                type="number"
                formik={formik}
                label="Serial quantity"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="button"
            color="white"
            onClick={() => formik.setFieldValue('productVariation', null)}
          >
            Cancel
          </Button>
          <Button type="submit" className="ml-3">
            Add rope
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default AddSerialisedRopeForm;
