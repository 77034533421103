import { useCallback } from 'react';
import { useQuery } from '@apollo/client';

import { PAGINATED_ITEM_HISTORY } from '@scannable/graphql-queries';

const pageSize = 50;

export function usePaginatedItemHistory(itemId?: number) {
  const orderBy: { id: 'desc' }[] = [{ id: 'desc' }];

  const { loading, data, fetchMore } = useQuery(PAGINATED_ITEM_HISTORY, {
    variables: {
      serialisedProductId: itemId as number,
      orderBy,
      skip: 0,
      take: pageSize,
    },
    skip: !itemId,
    fetchPolicy: 'network-only',
  });

  const hasMore =
    data &&
    (data?.paginatedItemHistory?.nodes?.length ?? 0) <
      data?.paginatedItemHistory?.totalCount;

  const loadMore = useCallback(() => {
    if (!loading && hasMore && data?.paginatedItemHistory.nodes) {
      fetchMore({
        variables: {
          skip: data.paginatedItemHistory.nodes.length || 0,
          take: pageSize,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const newItems = fetchMoreResult.paginatedItemHistory.nodes;
          if (!newItems) {
            return prev;
          }

          return {
            ...fetchMoreResult,
            paginatedItemHistory: {
              ...fetchMoreResult.paginatedItemHistory,
              nodes: [
                ...(prev?.paginatedItemHistory?.nodes || []),
                ...newItems,
              ],
            },
          };
        },
      });
    }
  }, [loading, hasMore, fetchMore, data?.paginatedItemHistory]);

  return { loading, data, loadMore, hasMore };
}
