import { useCallback, useState } from 'react';

export interface UsePagination {
  offset: number;
  next: () => void;
  prev: () => void;
  setPagination: (offset: number) => void;
}

export function usePagination(limit = 50): UsePagination {
  const [offset, setPagination] = useState(0);
  const next = useCallback(() => setPagination((x) => x + limit), [limit]);
  const prev = useCallback(
    () =>
      setPagination((x) => {
        if (offset - limit >= 0) {
          return x - limit;
        }
        return x;
      }),
    [limit, offset]
  );
  return { offset, next, prev, setPagination };
}

export default usePagination;
