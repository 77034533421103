import { Teams } from '@scannable/common';

import { graphql } from '../graphql';

export const ORGANISATION_TEAMS = graphql(`
  query ${Teams.GetTeams}(
    $where: TeamWhereInput
    $orderBy: [TeamOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedTeams(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      nodes {
        id
        name
      }
      totalCount
    }
  }
`);

export const ORGANISATION_TEAM_MEMBERS = graphql(`
  query ${Teams.GetTeamMembers}(
    $where: TeamMemberWhereInput
    $orderBy: [TeamMemberOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedTeamMembers(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        user {
          id
          firstName
          lastName
          avatar
        }
      }
      totalCount
    }
  }
`);

export const GET_TEAM = graphql(`query ${Teams.GetTeam}($id: String!) {
    team(id: $id) {
      id
      name
      members {
        user {
          id
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }`);
