import { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  AdjustmentsHorizontalIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { addTableFilter, removeTableFilter } from '@scannable/frontend/store';

import { useTable, useTranslation } from '../../hooks';
import { TableFilter } from '../../types/table.types';
import { useShowFilters } from './useShowFilters';
import { useUpdateQueryParams } from './useUpdateQueryParams';

function TableFilterPill({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) {
  return (
    <span
      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out"
      onClick={onClick}
      data-cy="table-filter-pill"
    >
      {label}
      <XMarkIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
    </span>
  );
}
interface TableFiltersProps {
  filters: TableFilter[];
  tableName: string;
}

export function TableFilters({ filters, tableName }: TableFiltersProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tableFilters } = useTable();
  const appliedFilters = tableFilters[tableName];

  const { clearParam } = useUpdateQueryParams();

  const showFilters = useShowFilters(filters, tableName);

  const router = useRouter();
  // on mount let's check the url and add filter to redux
  useEffect(() => {
    const query = router.query;
    if (query) {
      Object.keys(query).forEach((key) => {
        const filterQueryMatch = filters.find((f) => f.filterName === key);

        const urlParam = query[key];

        if (
          filterQueryMatch &&
          urlParam &&
          !Array.isArray(urlParam) && // We check !Array as we only work with strings not arrays just now
          filterQueryMatch.isUrlParamEnabled &&
          filterQueryMatch.options &&
          Array.isArray(filterQueryMatch.options)
        ) {
          const existingFilter =
            appliedFilters &&
            appliedFilters.find(
              (f) => f.filterName === filterQueryMatch.filterName
            );
          // extract the value from and convert to number if it is a number
          const urlParamValue =
            !isNaN(parseFloat(urlParam)) && isFinite(Number(urlParam))
              ? parseFloat(urlParam)
              : urlParam;
          // check if the value is already applied
          if (existingFilter?.values) {
            const isValueApplied = Array.isArray(existingFilter.values)
              ? existingFilter.values.some((val) => val.value === urlParamValue)
              : existingFilter.values.value === urlParamValue;

            if (isValueApplied) {
              return;
            }
          }
          const selectedOption = filterQueryMatch.options.filter((o) => {
            return o.value === urlParamValue;
          });
          if (selectedOption.length > 0) {
            const values = [...selectedOption];
            dispatch(
              addTableFilter({
                table: tableName,
                filter: {
                  filterName: filterQueryMatch.filterName,
                  interpolatedFilter: filterQueryMatch.interpolatedFilter,
                  computedFilter: filterQueryMatch.onFilterChanged
                    ? filterQueryMatch.onFilterChanged(
                        values.map((v) => v.value)
                      )
                    : null,
                  values: filterQueryMatch.isMulti ? values : values[0],
                },
              })
            );
          }
        }
      });
    }
  }, [appliedFilters, dispatch, filters, router.query, tableName]);

  return (
    <div className="flex justify-start">
      <div className="flex border-r border-gray-200 pr-2 mr-2">
        <span
          className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out"
          onClick={() => showFilters()}
        >
          <AdjustmentsHorizontalIcon className="h-4 w-4" aria-hidden="true" />
          {t('tables.filters_label')}
        </span>
      </div>
      <div className="flex space-x-1">
        {appliedFilters &&
          appliedFilters.length > 0 &&
          appliedFilters.map((f) => {
            if (Array.isArray(f.values)) {
              return f.values.map((val) => (
                <TableFilterPill
                  label={val.label.toString()}
                  key={val.value.toString()}
                  onClick={() => {
                    clearParam(f.filterName, () =>
                      dispatch(
                        removeTableFilter({
                          table: tableName,
                          filterName: f.filterName,
                          value: val.value,
                        })
                      )
                    );
                  }}
                />
              ));
            }
            return (
              <TableFilterPill
                label={`${f.values?.label}`}
                key={`${f.values?.value ?? ''}`}
                onClick={() => {
                  clearParam(f.filterName, () =>
                    dispatch(
                      removeTableFilter({
                        table: tableName,
                        filterName: f.filterName,
                      })
                    )
                  );
                }}
              />
            );
          })}
      </div>
    </div>
  );
}
