import { cn } from '@scannable/common';

import ResizedImage from '../../atoms/ResizedImage/ResizedImage';

export type AvatarUser = {
  id: number;
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  email?: string;
};

interface AvatarRowProps {
  users: AvatarUser[];
  onSelect: (id?: number) => void;
  selectedUser?: number;
}

export function AvatarRow({ users, onSelect, selectedUser }: AvatarRowProps) {
  return (
    <div className="flex flex-row space-x-5">
      <span
        onClick={() => onSelect(undefined)}
        className={cn(
          !selectedUser ? 'ring-4 ring-brand-primary' : '',
          'inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 hover:ring-4 hover:ring-brand-primary ring-offset-4 cursor-pointer'
        )}
      >
        <span className="leading-none">ALL</span>
      </span>
      {users.map((user) => (
        <div
          className={cn(
            selectedUser === user.id ? 'ring-4 ring-brand-primary' : '',
            'w-10 pb-0 pl-0 pr-0 pt-0 border-0 h-10 w-10cursor-pointer rounded-full bg-gray-200 hover:ring-4 hover:ring-brand-primary ring-offset-4 cursor-pointer'
          )}
          color="white"
          onClick={() => onSelect(user.id)}
          key={user.id}
          title={`${user.firstName} ${user.lastName}`}
        >
          {user.avatarUrl && user.avatarUrl !== '' ? (
            <ResizedImage
              className="object-contain object-center h-10 w-10 rounded-full"
              src={user?.avatarUrl}
              alt={user?.firstName}
              height={200}
              width={200}
              fit="fill"
            />
          ) : (
            <span
              className={cn(
                selectedUser === user.id ? 'ring-4 ring-brand-primary' : '',
                'inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 hover:ring-4 hover:ring-brand-primary ring-offset-4 cursor-pointer'
              )}
            >
              <span className="leading-none">
                {user.firstName[0]}
                {user.lastName[0]}
              </span>
            </span>
          )}
        </div>
      ))}
    </div>
  );
}

export default AvatarRow;
