import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import {
  reload as reloadTable,
  resetTableFilters as resetTableFiltersState,
  selectReloading,
  selectSelectedRows as selectSelectedRowsState,
  selectTableFilters as selectTableFiltersState,
  setHasError as setHasErrorState,
  setSelectedRows as setSelectedRowsState,
} from '@scannable/frontend/store';

export function useTable() {
  const dispatch = useDispatch();

  const reloadSelect = useSelector(selectReloading);
  const setHasError = useCallback(
    (x: Error | ApolloError) => dispatch(setHasErrorState(x)),
    [dispatch]
  );
  const reload = () => dispatch(reloadTable());
  const tableFilters = useSelector(selectTableFiltersState);
  const selectedRows = useSelector(selectSelectedRowsState);
  const setSelectedRows = useCallback(
    (x: number[]) => {
      dispatch(setSelectedRowsState(x));
    },
    [dispatch]
  );
  const resetTableFilters = useCallback(() => {
    dispatch(resetTableFiltersState());
  }, [dispatch]);

  const clearSelectedRows = useCallback(() => {
    setSelectedRows([]);
  }, [setSelectedRows]);

  return {
    reload,
    reloadSelect,
    setHasError,
    tableFilters,
    selectedRows,
    setSelectedRows,
    resetTableFilters,
    clearSelectedRows,
  };
}
