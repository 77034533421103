import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';

import {
  AccountModule,
  cn,
  Product as ProductGql,
  Role,
} from '@scannable/common';
import { SortOrder, tableBtnLabel } from '@scannable/frontend/common';
import { DELETE_PRODUCT, PAGINATED_PRODUCTS } from '@scannable/graphql-queries';

import { Link } from '../../atoms';
import { useIsAdmin, useModuleGuard, useRoleGuard } from '../../auth';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { ConfirmationModal, useModal } from '../../modals';
import {
  CreateProductSidePanel,
  EditProductSidePanel,
  useSidePanel,
} from '../../side-panels';
import { ImageCell, Table } from '../../tables';
import { TableRowType } from '../../types/table.types';
import { useManufacturerProductCategoryFilter } from './useManufacturerProductCategoryFilter';

export interface ProductsTableProps {
  manufacturerId?: number;
  isSuperAdmin?: boolean;
}

export function ProductsTable({
  manufacturerId,
  isSuperAdmin = false,
}: ProductsTableProps) {
  const isAdmin = useIsAdmin();
  const { showModal, hideModal } = useModal();
  const router = useRouter();
  const moduleGuard = useModuleGuard();
  const roleGuard = useRoleGuard();
  const { showSidePanel } = useSidePanel();

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [ProductGql.PaginatedProductsQuery],
  });

  const { t } = useTranslation();
  const { options, interpolatedFilter } = useManufacturerProductCategoryFilter(
    isSuperAdmin ? manufacturerId : undefined
  );
  return (
    <Table<typeof PAGINATED_PRODUCTS>
      uniqueTableKey="products"
      tableActions={[
        {
          label: `${tableBtnLabel(t('create_product_btn'))}`,
          callback: () =>
            showSidePanel(
              <CreateProductSidePanel manufacturerId={manufacturerId} />,
              {
                title: 'Create Product',
              }
            ),
          display:
            roleGuard([Role.Admin]) &&
            moduleGuard([AccountModule.Enterprise, AccountModule.Pro]),
          color: 'primary',
        },
      ]}
      title={t('products')}
      icon="products"
      fitScreen={{
        withTabs: true,
      }}
      resetFilters
      rowActions={[
        {
          label: t('delete'),
          type: TableRowType.danger,
          display: roleGuard([Role.Admin]),
          callback: (row) =>
            showModal(
              <ConfirmationModal
                title="Delete Product?"
                onConfirm={async () => {
                  await resolveMutation(
                    deleteProduct({
                      variables: {
                        data: {
                          id: row.id,
                        },
                      },
                      onCompleted: () => {
                        hideModal();
                      },
                    }),
                    {
                      successMessage: 'Product deleted',
                    }
                  );
                }}
              />
            ),
        },
      ]}
      columnSettings={[
        {
          label: t('table_name'),
          accessor: 'name',
          canSort: true,
          cellRender: ({ id, image, name, productGroupId }) => {
            return (
              <div
                className={cn(isAdmin ? 'cursor-pointer' : '')}
                data-cy="product-side-panel"
                onClick={() =>
                  isAdmin &&
                  showSidePanel(
                    <EditProductSidePanel
                      id={id}
                      name={name}
                      productGroupId={productGroupId}
                    />,
                    {
                      title: name,
                    }
                  )
                }
              >
                <ImageCell
                  image={{
                    url: image?.smallUrl,
                    uri: image?.uri,
                    alt: name ?? '',
                  }}
                >
                  {name ?? ''}
                </ImageCell>
              </div>
            );
          },
        },
        {
          accessor: 'id',
          label: 'Total SKUs',
          cellRender: ({ id, productVariationsCount }) => {
            return (
              <Link
                href={{
                  pathname: router.pathname,
                  query: {
                    ...(isSuperAdmin && { manufacturerId }),
                    tab: 'skus',
                    product: id,
                  },
                }}
                textLink
              >
                {productVariationsCount} &rarr;
              </Link>
            );
          },
        },
      ]}
      query={PAGINATED_PRODUCTS}
      variables={[
        {
          manufacturerId: {
            equals: manufacturerId,
          },
        },
      ]}
      searchFields={[
        {
          field: 'name',
        },
        {
          field: 'productVariations.code',
          label: 'part number',
          relation: 'many',
        },
        {
          field: 'productVariations.name',
          label: 'variant name',
          relation: 'many',
        },
      ]}
      advancedFilters={[
        {
          filterName: 'productGroup',
          options,
          interpolatedFilter,
          label: 'Filter by Category',
          placeholder: 'Select product categories',
        },
      ]}
      fetchPolicy="cache-and-network"
      skip={!manufacturerId}
      initialState={{
        paginationEnabled: true,
        useAdvancedPagination: true,
        pageSize: 25,
        orderBy: {
          name: SortOrder.asc,
        },
      }}
    />
  );
}

export default ProductsTable;
