import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { ProductCategories } from '@scannable/common';
import {
  CREATE_PRODUCT_GROUP_CATEGORY,
  UPDATE_PRODUCT_GROUP_CATEGORY,
} from '@scannable/graphql-queries';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export type ProductGroupCategoryCreateValues = {
  name: string;
  description: string;
  hidden: boolean;
};
type ProductGroupCategoryModalProps = {
  id?: number;
} & Partial<ProductGroupCategoryCreateValues>;

export function ProductGroupCategoryModal({
  id,
  name,
  description,

  hidden,
}: ProductGroupCategoryModalProps) {
  const [createProductGroupCategory] = useMutation(
    CREATE_PRODUCT_GROUP_CATEGORY,
    {
      refetchQueries: [ProductCategories.PaginatedProductGroupCategories],
    }
  );
  const [updateProductGroupCategory] = useMutation(
    UPDATE_PRODUCT_GROUP_CATEGORY,
    {
      refetchQueries: [ProductCategories.PaginatedProductGroupCategories],
    }
  );

  const { hideModal } = useModal();

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      description: description ?? '',
      hidden: hidden ?? false,
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
    }),
    onSubmit: async (
      values: ProductGroupCategoryCreateValues,
      actions: FormikHelpers<ProductGroupCategoryCreateValues>
    ) => {
      const data = {
        name: values.name,
        description: values.description,

        hidden: values.hidden,
      };
      const result = await resolveMutation(
        id
          ? updateProductGroupCategory({
              variables: {
                data: { ...data, id },
              },
            })
          : createProductGroupCategory({
              variables: {
                data,
              },
            }),
        {
          successMessage: id
            ? 'Update product group category'
            : 'Created product group category',
        }
      );
      if (result.ok) {
        hideModal();
      }
      actions.setSubmitting(false);
    },
  });

  return (
    <Modal
      overflowHidden={false}
      title={
        id ? 'Update Product Group Category' : 'Create Product Group Category'
      }
    >
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: id ? 'Update' : 'Create',
            type: 'submit',
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup type="text" name="name" label="Name" formik={formik} />
        <InputFieldGroup
          type="textarea"
          name="description"
          label="Description"
          formik={formik}
        />

        <InputFieldGroup
          type="radio"
          name="hidden"
          label="Product Group Category Access"
          options={[
            {
              label: 'Is active',
              value: false,
            },
            {
              label: 'Is hidden on the public facing app',
              value: true,
            },
          ]}
          formik={formik}
        />
      </ModalForm>
    </Modal>
  );
}

export default ProductGroupCategoryModal;
