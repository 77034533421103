import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

/**
 * Hook that listens for route changes (ignoring query params) and calls a callback when the route changes.
 *
 * @param {Function} callback - Function to be called when the route changes.
 */
export const useRouteChange = (callback: () => void) => {
  const router = useRouter();
  const previousPathname = useRef<string>(router.asPath.split('?')[0]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const cleanPathname = url.split('?')[0];
      if (previousPathname.current !== cleanPathname) {
        callback();
        previousPathname.current = cleanPathname;
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, callback]);
};
