import { useState } from 'react';

import { RadioInput } from '../../molecules';
import { SearchInput } from '../../organisms';
import { useSidePanel } from '../../side-panels';

export interface SearchValues {
  search: string;
}
type ProductSearchType = 'SkusOnly' | 'GlobalItemsOnly';

export function ProductSearchForm() {
  const [searchType, setSearchType] = useState<ProductSearchType>('SkusOnly');
  const { showItemSidePanel } = useSidePanel();

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-lg space-y-4">
        <div className="flex justify-end text-sm font-medium text-gray-700 space-x-3">
          <span>Search by:</span>
          <RadioInput
            inline
            name="searchType"
            options={[
              {
                label: 'Part #',
                value: 'SkusOnly',
              },
              {
                label: 'Serial #',
                value: 'GlobalItemsOnly',
              },
            ]}
            onChange={(v) =>
              setSearchType(v.currentTarget.value as ProductSearchType)
            }
            value={searchType}
            id=""
          />
        </div>

        <SearchInput
          name="productSearch"
          type={searchType}
          buttonLabel="Open"
          addToCallback={async (item) => {
            showItemSidePanel({
              serialisedProductId: item.serialisedProductId,
              productVariationId: item.productVariationId,
              showBanner: false,
              showOptionsButton: true,
              showAddToInventory: true,
            });
          }}
        />
      </div>
    </div>
  );
}

export default ProductSearchForm;
