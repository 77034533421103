import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { Manufacturer } from '@scannable/common';
import { useUpdateAccountManufacturerMutation } from '@scannable/frontend/common';
import { REMOVE_MANUFACTURER_LOGO } from '@scannable/graphql-queries';

import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Form from '../Form/Form';

interface ManufacturerDetailsValues {
  name: string;
  website: string;
  logo: string | null;
  verified?: boolean;
}

interface ManufacturerDetailsFormProps
  extends Partial<ManufacturerDetailsValues> {
  id: number;
  isSuperAdmin?: boolean;
  refetchQueries?: string[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a name'),
});

export function EditAccountManufacturerForm({
  id,
  name,
  website,
  logo,
  verified,
  isSuperAdmin,
}: ManufacturerDetailsFormProps) {
  const { t } = useTranslation();

  const [updateAccountManufacturer] = useUpdateAccountManufacturerMutation({
    refetchQueries: [Manufacturer.ManufacturerSettingsQuery],
  });
  const [removeManufacturerLogo] = useMutation(REMOVE_MANUFACTURER_LOGO, {
    refetchQueries: [Manufacturer.ManufacturerSettingsQuery],
  });

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      website: website ?? '',
      logo: null,
      verified: verified ?? false,
    } as ManufacturerDetailsValues,
    validationSchema,
    onSubmit: async (
      values: ManufacturerDetailsValues,
      actions: FormikHelpers<ManufacturerDetailsValues>
    ) => {
      await resolveMutation(
        updateAccountManufacturer({
          variables: {
            data: {
              name: values.name,
              website: values.website,
              logo: values.logo,
            },
          },
        }),
        { successMessage: 'Updated successfully' }
      );
      actions.setSubmitting(false);
    },
  });

  return (
    <Form
      formik={formik}
      buttons={[
        {
          type: 'submit',
          label: t('edit_manufacturer_button'),
        },
      ]}
    >
      <div className="sm:col-span-full">
        <div className="my-3">
          <InputFieldGroup
            label={t('edit_manufacturer_name')}
            type="text"
            name="name"
            placeholder={t('edit_manufacturer_name')}
            formik={formik}
          />
        </div>
        <div className="my-3">
          <InputFieldGroup
            label={t('manufacturers_website')}
            type="text"
            name="website"
            placeholder={t('manufacturers_website')}
            formik={formik}
          />
        </div>
        {isSuperAdmin && (
          <div className="my-3">
            <InputFieldGroup
              label="Verified"
              type="checkbox"
              name="verified"
              formik={formik}
            />
          </div>
        )}
        <div className="my-3">
          <InputFieldGroup
            id="logo"
            name="logo"
            type="image"
            label={t('edit_manufacturer_logo')}
            formik={formik}
            config={{
              currentImages: logo
                ? [
                    {
                      image: {
                        uri: logo,
                      },
                      removeFile: () =>
                        removeManufacturerLogo({
                          variables: {
                            id,
                          },
                        }),
                    },
                  ]
                : undefined,
            }}
          />
        </div>
      </div>
    </Form>
  );
}
