export const MISSING_IMAGE = '/static/images/no-image.jpeg';
export const PDF_PLACEHOLDER = '/static/images/pdf-placeholder.png';
export const GENERIC_ERROR_MESSAGE =
  "Bummer, somethings not working. Let us know and we'll get onto it ASAP.";
export const SIMPLE_ERROR_MESSAGE = 'Something went wrong.';
export const PLEASE_TRY_AGAIN = 'Something went wrong. Please try refreshing';
export const CHANGE_ORGANISATION = 'Changed account';
export const NETWORK_CONNECTION_ERROR_MESSAGE = 'Network connection error.';
export const KIT_ID_URL =
  process.env.NEXT_PUBLIC_KIT_ID_URL ||
  process.env.EXPO_PUBLIC_KIT_ID_URL ||
  'https://kit.id/';
export const AUTH_RESET_URL =
  process.env.NEXT_PUBLIC_AUTH_RESET_URL || 'http://localhost:3000/auth/clear';
export const NFC_URL = 'https://nfc.scannable.io/';
export const NANO_ID_ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz';
export const NANO_ID_LENGTH = 15;
export const CUSTOMER_TAG_NAME = 'Customer';
export const INVITE_ROUTE = '/admin/invitations';
export const ACTIVATION_ROUTE = '/admin/activation';
export const SUCCESS_ROUTE = '/admin/upgrade/success';
export const SIGN_IN_ROUTE = '/auth/sign-in';
export const SIGN_UP_ROUTE = '/auth/sign-up';
export const ADMIN_HOME_ROUTE = '/admin';
export const UPGRADE_ROUTE = '/admin/upgrade';
export const SETTINGS_ROUTE = '/admin/settings';
export const INVENTORY_ROUTE = '/admin/inventory';
export const DASHBOARD_HOME_ROUTE = '/';
export const FORBIDDEN_ROUTE = '/403';
export const SPLICE_GROUPS = ['Splice/Termination'];
export const ROPE_GROUPS = [
  'Ropes - Climbing Lines',
  'Ropes - Cord',
  'Ropes - Dynamic',
  'Ropes - Low Stretch',
  'Ropes - Rigging Lines',
  'Ropes - Prusiks, Friction Hitches, Blocs',
];
export const MAX_LIFESPAN_DATA_FIELD = 'max_lifespan';
export const CERTIFICATION_DATA_FIELD = 'certifications';
export const IMAGE_DATA_FIELD = 'image';
export const MAX_USER_UPLOAD_FILES = 10;
export const MAX_USER_UPLOAD_FILE_SIZE = 100000000; // 100 MB
export const ISO_8601_DATE_FORMAT = 'yyyy-MM-dd';
