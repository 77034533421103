import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { Manufacturer, Organisation } from '@scannable/common';
import { useUpdateManufacturerMutation } from '@scannable/frontend/common';
import { REMOVE_MANUFACTURER_LOGO } from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { FormAdminPanel, InputFieldGroup } from '../../molecules';
import Form from '../Form/Form';

interface EditManufacturerValues {
  name: string;
  website: string;
  logo: string | null;
  verified?: boolean;
}

interface EditManufacturerFormProps extends Partial<EditManufacturerValues> {
  id: number;
  isSuperAdmin?: boolean;
  refetchQueries?: string[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a name'),
});

export function EditManufacturerForm({
  id,
  name,
  website,
  logo,
  verified,
  isSuperAdmin,
}: EditManufacturerFormProps) {
  const { t } = useTranslation();
  const [updateManufacturer] = useUpdateManufacturerMutation({
    refetchQueries: [
      Manufacturer.EditManufacturerQuery,
      Organisation.GetOrganisationDetailQuery,
    ],
  });

  const [removeManufacturerLogo] = useMutation(REMOVE_MANUFACTURER_LOGO, {
    refetchQueries: [
      Manufacturer.EditManufacturerQuery,
      Organisation.GetOrganisationDetailQuery,
    ],
  });
  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      website: website ?? '',
      logo: null,
      verified: verified ?? false,
    } as EditManufacturerValues,
    validationSchema,
    onSubmit: async (
      values: EditManufacturerValues,
      actions: FormikHelpers<EditManufacturerValues>
    ) => {
      await resolveMutation(
        updateManufacturer({
          variables: {
            data: {
              id: id,
              name: values.name,
              website: values.website,
              logo: values.logo,
              verified: values.verified,
            },
          },
        }),
        { successMessage: 'Updated successfully' }
      );
      actions.setSubmitting(false);
    },
  });

  return (
    <Form formik={formik}>
      <FormAdminPanel
        renderFormFields={() => (
          <div className="sm:col-span-full">
            <div className="my-3">
              <InputFieldGroup
                label={t('edit_manufacturer_name')}
                type="text"
                name="name"
                placeholder={t('edit_manufacturer_name')}
                formik={formik}
              />
            </div>
            <div className="my-3">
              <InputFieldGroup
                label={t('manufacturers_website')}
                type="text"
                name="website"
                placeholder={t('manufacturers_website')}
                formik={formik}
              />
            </div>
            {isSuperAdmin && (
              <div className="my-3">
                <InputFieldGroup
                  label="Verified"
                  type="checkbox"
                  name="verified"
                  formik={formik}
                />
              </div>
            )}
            <div className="my-3">
              <InputFieldGroup
                id="logo"
                name="logo"
                type="image"
                label={t('edit_manufacturer_logo')}
                formik={formik}
                config={{
                  currentImages: logo
                    ? [
                        {
                          image: {
                            uri: logo,
                          },
                          removeFile: () =>
                            removeManufacturerLogo({
                              variables: {
                                id,
                              },
                            }),
                        },
                      ]
                    : undefined,
                }}
              />
            </div>
          </div>
        )}
        renderFormAction={() => (
          <Button
            label={t('update')}
            type="submit"
            loading={formik.isSubmitting}
          />
        )}
      />
    </Form>
  );
}
