import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { User as UserQueries } from '@scannable/common';
import {
  useRemoveUserAvatarMutation,
  useRemoveUserSignatureMutation,
  useUpdateUserMutation,
} from '@scannable/frontend/common';

import { Button } from '../../atoms';
import {
  useAuth,
  useIsCompetentPerson,
} from '../../auth/AuthContext/AuthContext';
import { resolveMutation } from '../../lib/lib';
import { FormAdminPanel } from '../../molecules/FormAdminPanel/FormAdminPanel';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import { Form } from '../Form/Form';

type UpdateProfileValues = {
  firstName: string;
  lastName: string;
  email: string;
  avatar: string | null;
  signature: string | null;
  inspectorDeclaration: string;
};
interface ProfileFormProps extends UpdateProfileValues {
  userId: number;
}

export function ProfileForm({
  userId,
  firstName,
  lastName,
  email,
  avatar,
  signature,
  inspectorDeclaration,
}: ProfileFormProps) {
  const { reloadUser } = useAuth();
  const isCompetent = useIsCompetentPerson();
  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [UserQueries.MyProfile],
  });
  const [removeAvatar] = useRemoveUserAvatarMutation({
    refetchQueries: [UserQueries.MyProfile],
  });
  const [removeSignature] = useRemoveUserSignatureMutation({
    refetchQueries: [UserQueries.MyProfile],
  });
  const profileForm = useFormik({
    initialValues: {
      firstName,
      lastName,
      email,
      avatar: null,
      signature: null,
      inspectorDeclaration,
    } as UpdateProfileValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string()
        .email('A valid email is required')
        .required('Email is required'),
    }),
    onSubmit: async (
      values: UpdateProfileValues,
      actions: FormikHelpers<UpdateProfileValues>
    ) => {
      const result = await resolveMutation(
        updateUser({
          variables: {
            data: {
              id: userId,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              avatar: values.avatar,
              signature: values.signature,
              inspectorDeclaration: values.inspectorDeclaration,
            },
          },
        }),
        {
          successMessage: 'Profile updated successfully',
          failureMessage: 'Failed to update profile',
        }
      );
      if (result.ok) {
        actions.resetForm();
        actions.setSubmitting(false);
        reloadUser();
      }
    },
  });

  return (
    <Form formik={profileForm}>
      <FormAdminPanel
        renderFormFields={() => (
          <>
            <InputFieldGroup
              type="text"
              name="firstName"
              label="First name"
              formik={profileForm}
            />
            <InputFieldGroup
              type="text"
              name="lastName"
              label="Last name"
              formik={profileForm}
            />
            <InputFieldGroup
              type="email"
              name="email"
              label="Email"
              formik={profileForm}
            />
            {isCompetent && (
              <InputFieldGroup
                type="textarea"
                name="inspectorDeclaration"
                label="Inspector Declaration"
                formik={profileForm}
              />
            )}
            <InputFieldGroup
              type="image"
              name="avatar"
              label="Avatar"
              config={{
                currentImages: avatar
                  ? [
                      {
                        image: {
                          uri: avatar,
                        },
                        removeFile: removeAvatar,
                      },
                    ]
                  : undefined,
              }}
              formik={profileForm}
            />
            {isCompetent && (
              <InputFieldGroup
                type="image"
                name="signature"
                label="Signature"
                config={{
                  currentImages: signature
                    ? [
                        {
                          image: {
                            uri: signature,
                          },
                          removeFile: removeSignature,
                        },
                      ]
                    : undefined,
                }}
                formik={profileForm}
              />
            )}
          </>
        )}
        renderFormAction={() => (
          <Button
            className="mr-4"
            disabled={!profileForm.isValid || profileForm.isSubmitting}
            loading={profileForm.isSubmitting}
            type="submit"
          >
            Update
          </Button>
        )}
      />
    </Form>
  );
}

export default ProfileForm;
