import { NFCAlias } from '@scannable/common';

import { graphql } from '../graphql';

export const LINK_NFC =
  graphql(`mutation ${NFCAlias.CreateItemAliasMutation} ($data: SerialisedProductAliasCreateInput!) {
    createSerialisedProductAlias(data: $data) {
      id
      alias
    }
}`);

export const REMOVE_ITEM_NFC_TAGS =
  graphql(`mutation ${NFCAlias.RemoveItemNFCTagsMutation} ($data: RemoveItemNfcInput!) {
    removeItemNFCTags(data: $data)
}`);

export const REMOVE_NFC = graphql(`
mutation ${NFCAlias.RemoveItemAliasMutation}($data: SerialisedProductAliasRemoveInput!) {
  removeNfc(data: $data) {
    id
    alias
  }
}
`);
