import { GroupType } from '@prisma/client';

import { ItemAction } from '@scannable/common';
import { useGetGroupQuery } from '@scannable/frontend/common';

import { IconType } from '../../atoms/Icon/Icon';
import AdminLayout from '../../layouts/AdminLayout/AdminLayout';
import { GroupedItemsTable } from '../../tables';

export interface GroupPageProps {
  icon: IconType;
  groupId: number;
  breadcrumbLabelMappings?: Record<string, string>;
  groupLabel: string;
}

export function GroupPage({
  icon,
  groupId,
  breadcrumbLabelMappings,
}: GroupPageProps) {
  const { data, loading, error } = useGetGroupQuery(groupId);

  return (
    <AdminLayout
      title={data?.group?.name || ''}
      breadcrumbLabelMappings={{
        ...breadcrumbLabelMappings,
        groupId: data?.group?.name || '',
      }}
      loading={loading}
      error={error}
      showTableFooter
    >
      <GroupedItemsTable
        groupId={groupId}
        groupName={data?.group?.name || ''}
        groupType={data?.group?.type || GroupType.KIT_BAG}
        publicId={`${data?.group?.uid}`}
        icon={icon}
        rowActionsToHide={[ItemAction.addUpdateGroups]}
      />
    </AdminLayout>
  );
}

export default GroupPage;
