import { useEffect } from 'react';

import { useAuth } from '../../auth/AuthContext/AuthContext';
import { useModal } from '../../modals/ModalContext/ModalContext';
import OrganisationSelectorModal from '../../modals/OrganisationSelectorModal/OrganisationSelectorModal';

export function useOrganisationSelector() {
  const { organisationId, organisations, loading } = useAuth();
  const { showModal, isModalOpen } = useModal();

  useEffect(() => {
    if (
      !organisationId &&
      organisations &&
      organisations.length > 0 &&
      !loading &&
      !isModalOpen
    ) {
      showModal(<OrganisationSelectorModal dismissable={false} />);
    }
  }, [organisationId, organisations, loading, isModalOpen, showModal]);
}

export default useOrganisationSelector;
