import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_CUSTOMERS } from '@scannable/graphql-queries';

export interface UseCustomerReferenceTag {
  loading: boolean;
  customerTags: { id: string; name: string }[] | undefined | null;
  customerOptions: { label: string; value: string }[];
}

export function useCustomerReferenceTag(): UseCustomerReferenceTag {
  const { data: tagsData, loading } = useQuery(GET_CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const customerOptions = useMemo(
    () =>
      tagsData?.customers
        ? tagsData?.customers.map((t) => ({
            label: t.name,
            value: t.name,
          }))
        : [],
    [tagsData?.customers]
  );

  return {
    loading: loading,
    customerTags: tagsData?.customers,
    customerOptions,
  };
}

export default useCustomerReferenceTag;
