import { useRouter } from 'next/router';
import { gql } from '@apollo/client';

import { ADMIN_HOME_ROUTE, dateFormat } from '@scannable/common';
import { useAcceptInviteMutation } from '@scannable/frontend/common';

import { Button } from '../../atoms';
import { useAuth } from '../../auth';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { Table } from '../Table/Table';

interface InvitesProps {
  title?: string;
}
export function Invites({ title }: InvitesProps) {
  const { refreshAccount } = useAuth();
  const { t } = useTranslation();
  const router = useRouter();
  const [acceptInvitation] = useAcceptInviteMutation();

  const acceptTeamInvite = async (id: number, organisationId: number) => {
    const result = await resolveMutation(
      acceptInvitation({
        variables: {
          data: {
            id,
          },
        },
      }),
      {
        successMessage: 'Invite accepted',
      }
    );
    if (result.ok) {
      await refreshAccount(organisationId);
      router.push(ADMIN_HOME_ROUTE);
    }
  };
  return (
    <Table
      title={title}
      columnSettings={[
        {
          label: 'Invite from',
          accessor: 'name',
        },
        {
          label: t('date_sent'),
          accessor: 'createdAt',
          cellRender: (row) => {
            return dateFormat(row.createdAt);
          },
        },
        {
          label: '',
          accessor: 'id',
          cellRender: (row) => {
            const { inviteId, organisationId } = row;
            return (
              <Button
                label={t('accept')}
                type="button"
                color="white"
                onClick={() => {
                  acceptTeamInvite(inviteId, organisationId);
                }}
                data-cy="accept-invite"
              />
            );
          },
        },
      ]}
      query={gql`
        query InvitesByUser {
          invitesByUser {
            id
            inviteId
            organisationId
            name
            createdAt
          }
        }
      `}
      fetchPolicy="cache-and-network"
      initialState={{}}
    />
  );
}
