import { whereNumeric } from 'iso-3166-1';

import { patterns as gs1_patterns } from './gs1-patterns';

// Extract GS1 data from a provided serial
export function extractGS1Data(serial: string): Record<string, string | null> {
  const results: Record<string, string | null> = {};

  for (const item of gs1_patterns) {
    const regex = new RegExp(item['Regular expression']);
    const match = serial.match(regex);
    // Application Identifiers
    const applicationIdentifier = item['AI'];
    const name = `(${applicationIdentifier}) ${item['Description']}`;

    if (match) {
      if (/\(YYMMDD\)/.test(name)) {
        const dom = match[1];
        let day = dom.slice(4, 6);
        const month = dom.slice(2, 4);
        const yearSuffix = dom.slice(0, 2);
        let century = '20';

        if (parseInt(yearSuffix) >= 50) {
          century = '19';
        }

        const fullYear = `${century}${yearSuffix}`;

        if (day === '00') {
          const date = new Date(Number(fullYear), Number(month) - 1, 1);
          // Set the date to the last day of the previous month
          date.setMonth(date.getMonth() + 1, 0);
          day = date.getDate().toString().padStart(2, '0'); // Pad to ensure it's always 2 digits
        }

        results[name] = `${day}/${month}/${yearSuffix}`;
      } else if (applicationIdentifier === '421') {
        const iso_code = whereNumeric(match[1]);
        results[name] = `${iso_code?.country} ${match[1]}`;
      } else {
        results[name] = match[1];
      }
    } else {
      results[name] = null;
    }
  }

  // Filter out null results
  const filteredResults: Record<string, string> = {};
  for (const key in results) {
    if (results[key] !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      filteredResults[key] = results[key]!;
    }
  }

  return filteredResults;
}
