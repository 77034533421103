import { useEffect, useState } from 'react';
import { GroupType } from '@prisma/client';

import {
  AssignToGroupAction,
  AssignToGroupActionType,
} from '@scannable/common';
import { FormFieldOptionType } from '@scannable/frontend/types';

import {
  useAddItemsToGroupMutation,
  useAddItemToGroupMuation,
} from '../groups/mutations';
import { useGetGroupsByTypeLazyQuery } from '../groups/queries';

interface AssignToGroupVariables {
  groupId?: number | null;
  serialisedProductId?: number;
  serialisedProductIds?: number[];
}

export interface UseAssignToGroup {
  options: FormFieldOptionType[];
  action: (variables: AssignToGroupVariables) => Promise<void>;
}

export function useAssignToGroup(
  groupType: GroupType | undefined,
  actionType: AssignToGroupActionType
) {
  const [options, setOptions] = useState<FormFieldOptionType[]>([]);
  const [getGroupsByType] = useGetGroupsByTypeLazyQuery({
    onCompleted: (data) => {
      setOptions(
        data?.groups
          ? data.groups.map((group) => ({
              label: group.name,
              value: group.id,
            }))
          : []
      );
    },
    fetchPolicy: 'cache-and-network',
  });
  const [addItemsToGroup] = useAddItemsToGroupMutation();
  const [addItemToGroup] = useAddItemToGroupMuation();
  useEffect(() => {
    if (groupType) {
      getGroupsByType({
        variables: {
          type: groupType,
        },
      });
    }
  }, [getGroupsByType, groupType]);

  const action = (variables: AssignToGroupVariables) => {
    switch (actionType) {
      case AssignToGroupAction.listOfItems:
        return addItemsToGroup({
          variables: {
            groupId: variables.groupId as number,
            serialisedProductIds: variables.serialisedProductIds as number[],
          },
        });
      case AssignToGroupAction.singleItem:
        return addItemToGroup({
          variables: {
            groupId: variables.groupId as number,
            serialisedProductId: variables.serialisedProductId as number,
          },
        });
      default:
        throw new Error(`Unknown action type: ${actionType}`);
    }
  };
  return {
    options: options.sort((a, b) =>
      a.label.toString().localeCompare(b.label.toString())
    ),
    action,
  };
}
