import { User } from '@scannable/common';

import { graphql } from '../graphql';

export const ACTIVATE_ACCOUNT = graphql(`
  mutation ${User.ActivateAccount}($token: String!) {
    activateMe(token: $token)
  }
`);

export const IMPERSONATE_ORGANISATION = graphql(`
  mutation ${User.ImpersonateOrganisation}($data: ImpersonateOrganisationInput!) {
    impersonateOrganisation(data: $data) {
      accessToken
      refreshToken
    }
  }
`);

export const CHANGE_MY_PASSWORD = graphql(`
  mutation ${User.ChangeMyPassword}($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      email
    }
  }
`);

export const UPDATE_USER =
  graphql(`mutation ${User.UpdateUser}($data: UpdateUserInput!) {
  updateUser(data: $data) {
    id
    }
    }`);

export const CREATE_USER = graphql(
  `mutation ${User.CreateUser}($data: CreateUserInput!) {
    createUser(data: $data) {
      id
    }
  }`
);

export const CHANGE_USER_PASSWORD =
  graphql(`mutation ${User.ChangeUserPassword}($data: ChangeUserPasswordInput!) {
  changeUserPassword(data: $data) {
    email
    }
  }`);

export const REMOVE_USER_AVATAR = graphql(`mutation ${User.RemoveUserAvatar} {
  removeUserAvatar {
    id
    }
  }`);

export const REMOVE_USER_SIGNATURE =
  graphql(`mutation ${User.RemoveUserSignature} {
  removeUserSignature {
    id
    }
    }`);

export const SEND_ACTIVATION_EMAIL = graphql(`
  mutation ${User.SendActivationEmail} {
    sendActivationEmail
  }
`);

export const LOG_OUT = graphql(`
  mutation ${User.LogOut} {
    logout {
      success
    }
  }
`);

export const USER_SIGNUP = graphql(`
  mutation ${User.UserSignup} ($data: SignupInput!) {
    signup(data: $data) {
      accessToken
      refreshToken
    }
  }
`);

export const USER_LOGIN = graphql(`
  mutation ${User.Login} ($data: LoginInput!) {
    login(data: $data) {
      accessToken
      refreshToken
    }
  }
`);

export const DELETE_ME = graphql(`
  mutation ${User.DeleteMe} {
    deleteMe {
      id
    }
  }
`);

export const CREATE_PASSWORD_RESET = graphql(`
  mutation ${User.CreatePasswordReset} ($data: CreatePasswordResetInput!) {
    createPasswordReset(data: $data) {
      email
    }
  }
`);

export const RESET_PASSWORD = graphql(`
  mutation ${User.ResetPassword} ($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      email
    }
  }
`);
