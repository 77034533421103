import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { cn } from '@scannable/common';

import { Icon, IconType, LoadingIcon } from '../../atoms';
import { useModal } from '../ModalContext/ModalContext';

export interface ModalProps {
  title?: string;
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
  overflowHidden?: boolean;
  loading?: boolean;
  description?: string;
  icon?: IconType;
  onDismiss?: () => void;
}

export function Modal({
  title,
  children,
  size = 'lg',
  loading = false,
  overflowHidden = true,
  description,
  icon,
  onDismiss,
}: ModalProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { isModalOpen, hideModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  const handleDismiss = useCallback(() => {
    hideModal();
    onDismiss?.();
  }, [hideModal, onDismiss]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[100]"
        onClose={handleDismiss}
        ref={ref}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-all" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={cn(
                'flex flex-col justify-between relative transform rounded-lg bg-white pb-2 text-left shadow-xl transition-all sm:w-full',
                `sm:max-w-${size}`,
                overflowHidden ? 'overflow-hidden' : ''
              )}
            >
              <div className="flex flex-col h-full">
                {icon && (
                  <div className="flex justify-center items-center bg-gray-200 rounded-lg h-10 w-10 mx-4 mt-4">
                    <Icon name={icon} className="h-6 w-6" />
                  </div>
                )}
                <div className="flex p-5 space-x-2 items-center border-b border-gray-200">
                  {title && (
                    <h3
                      data-cy="modal-heading"
                      className="text-lg leading-6 text-gray-900"
                    >
                      {title}
                    </h3>
                  )}
                  {description && (
                    <div className="pt-2 text-sm text-gray-500">
                      {description}
                    </div>
                  )}
                </div>
                <div>
                  {!loading ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>{children}</>
                  ) : (
                    <div className="relative flex min-h-[300px] items-center justify-center">
                      <LoadingIcon size="sm" />
                    </div>
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
