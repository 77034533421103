import { cn } from '@scannable/common';

import { useTable } from '../../hooks';
import { SidePanelButtonContainer } from '../../molecules';

interface SidePanelLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  actions?: () => React.ReactNode;
  isTabbedLayout?: boolean;
}
export function SidePanelLayout({
  children,
  actions,
  isTabbedLayout,
}: SidePanelLayoutProps) {
  const { selectedRows } = useTable();
  return (
    <div
      className={cn(
        'flex flex-1 flex-col overflow-y-auto rounded-xl bg-white',
        selectedRows.length > 0
          ? 'h-[calc(100vh_-_13rem)]'
          : 'h-[calc(100vh_-_9rem)]'
      )}
    >
      <div className={cn('flex-1', isTabbedLayout ? 'h-full' : 'p-5 pt-2')}>
        {children}
      </div>
      {actions && (
        <SidePanelButtonContainer>{actions()}</SidePanelButtonContainer>
      )}
    </div>
  );
}
export default SidePanelLayout;
