import { useCallback, useState } from 'react';

export function useValidateSerial() {
  const [isDuplicateSerial, setIsDuplicateSerial] = useState(false);
  const [isEmptySerial, setIsEmptySerial] = useState(false);
  const [confirmEmptySerial, setConfirmEmptySerial] = useState(false);

  const handleConfirmEmptySerial = useCallback((confirm: () => void) => {
    setIsEmptySerial(false);
    setConfirmEmptySerial(true);
    confirm();
  }, []);

  const handleConfirmDuplicateSerial = useCallback((confirm: () => void) => {
    setIsDuplicateSerial(false);
    confirm();
  }, []);

  const getMessageAndAction = useCallback(
    (setField: () => void, submit: () => void) => {
      if (isEmptySerial) {
        return {
          message: 'Serial Number is empty. Are you sure you want to continue?',
          primaryButtonLabel: 'Confirm',
          primaryButtonAction: () =>
            handleConfirmEmptySerial(() => {
              setField();
              submit();
            }),
        };
      } else if (isDuplicateSerial) {
        return {
          message:
            'An item with this Serial Number exists. Are you sure you want to create a new one?',
          primaryButtonLabel: 'Confirm',
          primaryButtonAction: () =>
            handleConfirmDuplicateSerial(() => {
              setField();
              submit();
            }),
        };
      }
      return {
        message: '',
        primaryButtonLabel: 'Save',
        primaryButtonAction: () => submit(),
      };
    },
    [
      isEmptySerial,
      isDuplicateSerial,
      handleConfirmEmptySerial,
      handleConfirmDuplicateSerial,
    ]
  );

  return {
    isEmptySerial,
    isDuplicateSerial,
    confirmEmptySerial,
    setConfirmEmptySerial,
    getMessageAndAction,
    setIsEmptySerial,
    setIsDuplicateSerial,
  };
}
