import { useCallback } from 'react';
import { useQuery } from '@apollo/client';

import { PAGINATED_GROUP_URL_ITEMS } from '@scannable/graphql-queries';

const pageSize = 20;

export function usePaginatedGroupUrlItems(groupUid?: string) {
  const { loading, data, fetchMore } = useQuery(PAGINATED_GROUP_URL_ITEMS, {
    variables: {
      where: {
        groupedItems: {
          some: {
            group: {
              is: {
                uid: {
                  equals: groupUid,
                },
              },
            },
          },
        },
      },
      skip: 0,
      take: pageSize,
    },
    skip: !groupUid,
  });

  const hasMore =
    data &&
    (data?.paginatedGroupUrlItems?.nodes?.length ?? 0) <
      data?.paginatedGroupUrlItems?.totalCount;

  const loadMore = useCallback(() => {
    if (!loading && hasMore && data?.paginatedGroupUrlItems.nodes) {
      fetchMore({
        variables: {
          skip: data.paginatedGroupUrlItems.nodes.length || 0,
          take: pageSize,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const newItems = fetchMoreResult.paginatedGroupUrlItems.nodes;
          if (!newItems) {
            return prev;
          }

          return {
            ...fetchMoreResult,
            paginatedGroupUrlItems: {
              ...fetchMoreResult.paginatedGroupUrlItems,
              nodes: [
                ...(prev?.paginatedGroupUrlItems?.nodes || []),
                ...newItems,
              ],
            },
          };
        },
      });
    }
  }, [loading, hasMore, fetchMore, data?.paginatedGroupUrlItems]);

  return { loading, data, loadMore, hasMore };
}

export default usePaginatedGroupUrlItems;
