import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { tInterpolate as tInterpolateFn } from '@scannable/frontend/common';
import {
  en,
  es,
  fr,
  Join,
  LanguageMap,
  PathsToStringProps,
  translate,
  UseTranslation,
} from '@scannable/frontend/translations';

export function useTranslation(): UseTranslation {
  const router = useRouter();
  const lang = useMemo<LanguageMap>(() => {
    if (router?.locale) {
      switch (router.locale) {
        case 'es':
          return es;
        case 'en':
          return en;
        case 'fr':
          return fr;
        default:
          return en;
      }
    }
    return en;
  }, [router?.locale]);

  const t = useCallback(
    (key: Join<PathsToStringProps<typeof en>>, replacement?: string) => {
      return translate(lang, key, replacement);
    },
    [lang]
  );

  const tInterpolate = useCallback(
    (key: Join<PathsToStringProps<typeof en>>, value: string | number) => {
      const textToInterpolate = translate(lang, key);
      return tInterpolateFn(textToInterpolate, value);
    },
    [lang]
  );

  return { t, tInterpolate };
}

export default useTranslation;
