import { useRouter } from 'next/router';

import { cn } from '@scannable/common';

import { Icon, IconType, Link, ToolTip } from '../../atoms';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';

export interface IMenuItem {
  name: string;
  href: string;
  langKey: string;
  showForRoles?: string[];
  hideForRoles?: string[];
  hidden?: boolean;
  current: boolean;
  // tooltip?: string;
  icon: IconType;
  tootipLangKey: string;
  testId?: string;
  group?: 'owner' | 'admin' | 'manufacturer' | 'groups';
  isProFeature?: boolean;
  external?: boolean;
  primaryAction?: boolean;
  featureFlag?: boolean;
}

export type MenuItemProps = IMenuItem;

export function MenuItem({
  href,
  external,
  icon,
  testId,
  name,
  tootipLangKey,
  langKey,
  isProFeature,
  primaryAction,
}: MenuItemProps) {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <li>
      <Link
        href={href}
        external={external}
        className={cn(
          router.asPath === href
            ? 'bg-gray-100 text-gray-900'
            : 'text-gray-900 hover:bg-gray-100 hover:text-gray-900',
          'group flex items-center font-medium justify-between rounded-md px-2 py-2 text-sm font-medium',
          primaryAction
            ? 'h-9 mb-4 text-sm font-medium rounded-md bg-white border-gray-200 border hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 shadow-sm cursor-pointer'
            : ''
        )}
      >
        <div className="flex items-center">
          <Icon
            name={icon}
            className={cn(
              router.asPath === href
                ? 'text-gray-900'
                : 'group-hover:text-gray-900 text-gray-400',
              'mr-3 h-5 w-5 flex-shrink-0'
            )}
            width={20}
            height={20}
            aria-hidden="true"
            data-cy={testId || null}
          />

          {t(`${langKey}` as 'menu_dashboard', name)}
          {tootipLangKey && (
            <ToolTip msg={t(tootipLangKey as 'menu_dashboard')} />
          )}
        </div>
        {isProFeature && (
          <Icon
            name="pro"
            width={30}
            className={cn(
              router.asPath === href
                ? 'text-gray-900'
                : 'group-hover:text-gray-900 text-gray-400'
            )}
          />
        )}
      </Link>
    </li>
  );
}

export default MenuItem;
