import { Fragment } from 'react';
import Head from 'next/head';
import { ApolloError } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import { RoleType, TextGenerator } from '@scannable/common';
import {
  cns,
  extractMessageFromError,
  useBoolean,
} from '@scannable/frontend/common';

import { Button, ScreenLoader, Text } from '../../atoms';
import { useAuth } from '../../auth';
import {
  useActivation,
  useNotifications,
  useOrganisationSelector,
  useSubscriptionSuccess,
  useTable,
  useTranslation,
  useWelcome,
} from '../../hooks';
import { AdminPanel } from '../../molecules';
import {
  BreadCrumbItemType,
  BreadCrumbs,
  BulkActionSelector,
  DynamicBreadcrumbs,
  ErrorBoundary,
  LoadingBlock,
  MainNavigation,
  MobileNavigation,
  OrganisationSelector,
  UserNavigation,
} from '../../organisms';
import { SidePanelPortal } from '../../side-panels';

export interface AdminLayoutProps {
  children: React.ReactNode;
  action?: React.ReactNode;
  title?: string;
  pageTitle?: string;
  renderFooter?: () => JSX.Element;
  primaryActionRoles?: RoleType[];
  breadcrumbs?: BreadCrumbItemType[] | null;
  hideSearch?: boolean | null;
  dynamicBreadcrumbs?: boolean;
  breadcrumbCallback?: TextGenerator;
  hiddenCrumbs?: string[];
  breadcrumbLabelMappings?: Record<string, string>;
  showTableFooter?: boolean;
  scrollableMain?: boolean;
  minMainHeightClassName?: string;
}

export interface IAdminState {
  loading?: boolean;
  data?: { [key: string]: unknown }[];
  error?: ApolloError | null;
}

function ErrorDisplay({ error }: { error: string }) {
  return <AdminPanel>{error || 'There was an error'}</AdminPanel>;
}

function Display({
  loading,
  error,
  children,
}: IAdminState & {
  children: React.ReactNode;
}) {
  if (error) {
    return <ErrorDisplay error={extractMessageFromError(error)} />;
  }
  if (loading) {
    return <LoadingBlock />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export function AdminLayout({
  children,
  title,
  breadcrumbs,
  hideSearch,
  primaryActionRoles,
  action,
  error,
  pageTitle,
  renderFooter,
  hiddenCrumbs,
  breadcrumbCallback,
  breadcrumbLabelMappings,
  minMainHeightClassName,
  scrollableMain = true,
  dynamicBreadcrumbs = true,
  showTableFooter = false,
  ...props
}: AdminLayoutProps & IAdminState) {
  const { loading, redirecting, isLoggedIn } = useAuth();
  const { selectedRows, clearSelectedRows } = useTable();
  const [isSidebarOpen, setIsSidebarOpen] = useBoolean();

  const { t } = useTranslation();
  useOrganisationSelector();
  useSubscriptionSuccess();
  useWelcome();
  useActivation();
  useNotifications();

  if (loading || redirecting || !isLoggedIn) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <ScreenLoader />
      </div>
    );
  }

  return (
    <>
      <Head>
        <title>Scannable | {title}</title>
      </Head>
      <div>
        <Transition.Root show={isSidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex"
            onClose={setIsSidebarOpen.off}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-50 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={setIsSidebarOpen.off}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <OrganisationSelector />
                </div>
                <MobileNavigation />
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <MainNavigation />
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 bg-gray-100 px-4 sm:gap-x-6 sm:px-6 lg:px-4">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={setIsSidebarOpen.on}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div>
              {!error && !dynamicBreadcrumbs && breadcrumbs && (
                <BreadCrumbs breadcrumbs={breadcrumbs} />
              )}
              {dynamicBreadcrumbs && (
                <DynamicBreadcrumbs
                  getTextGenerator={breadcrumbCallback}
                  hiddenCrumbs={hiddenCrumbs}
                  labelMappings={breadcrumbLabelMappings}
                />
              )}
            </div>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end">
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* for when we have notifications
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <UserNavigation />
              </div>
            </div>
          </div>

          <div className="flex flex-1 items-stretch overflow-hidden">
            {/* Main content. The height calculates full height minus the top user menu so we don't have overflow issues with dropdowns e.g. address country */}
            <main
              className={cns(
                'flex-1 overflow-y-auto',
                minMainHeightClassName
                  ? minMainHeightClassName
                  : 'min-h-[calc(100vh_-_64px)'
              )}
            >
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="flex min-w-0 flex-1 flex-col lg:order-last px-4 pb-4"
              >
                <ErrorBoundary>
                  <Display error={error} {...props}>
                    <div className="flex-1">{children}</div>
                  </Display>
                </ErrorBoundary>
              </section>
            </main>

            {/* Secondary column */}
            <SidePanelPortal />
          </div>

          {renderFooter && <>{renderFooter()}</>}
          {!renderFooter && showTableFooter && selectedRows.length > 0 && (
            <footer className="h-20 border-t border-gray-200 bg-white fixed bottom-0 w-full">
              {/* offset the width by 16rem to account for the sidebar */}
              <div className="flex justify-between px-10 py-5 items-center bg-white my-auto md:w-[calc(100%_-_16rem)] w-full">
                <div>
                  <Text data-cy="select-count">
                    {selectedRows.length} {t('kit_list_table_items')}
                  </Text>
                </div>
                <div className="flex space-x-4">
                  <div className="w-30 md:w-80">
                    <BulkActionSelector
                      isMultiScan={false}
                      spIds={selectedRows}
                    />
                  </div>
                  <Button
                    type="button"
                    color="secondary"
                    disabled={selectedRows.length === 0}
                    onClick={clearSelectedRows}
                  >
                    {t('clear')}
                  </Button>
                </div>
              </div>
            </footer>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminLayout;
