import { createSelector, createSlice } from '@reduxjs/toolkit';

export const APPLICATION_STATUS_FEATURE_KEY = 'applicationStatus';

export interface ApplicationStatusState {
  maintenanceMode: boolean;
}

export const initialApplicationStatusState = {
  maintenanceMode: false,
};
export const applicationStatusSlice = createSlice({
  name: APPLICATION_STATUS_FEATURE_KEY,
  initialState: initialApplicationStatusState,
  reducers: {
    turnOnMaintenanceMode: (state) => {
      state.maintenanceMode = true;
    },
    turnOffMaintenanceMode: (state) => {
      state.maintenanceMode = false;
    },
  },
});

export const applicationStatusReducer = applicationStatusSlice.reducer;

export const applicationStatusActions = applicationStatusSlice.actions;

export const { turnOnMaintenanceMode, turnOffMaintenanceMode } =
  applicationStatusSlice.actions;

export const getApplicationStatusState = (rootState: {
  [APPLICATION_STATUS_FEATURE_KEY]: ApplicationStatusState;
}): ApplicationStatusState => rootState[APPLICATION_STATUS_FEATURE_KEY];

export const selectMaintenanceMode = createSelector(
  getApplicationStatusState,
  (state: ApplicationStatusState) => state.maintenanceMode
);
