import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectTableFilters } from '@scannable/frontend/store';

export const useWhereClause = (
  variables: Record<string, unknown>[] | undefined,
  searchWhereClause:
    | {
        [key: string]: unknown;
      }[]
    | null
    | undefined,
  tableName: string,
  currentTableFilters?: string[]
) => {
  const tableFilters = useSelector(selectTableFilters);

  const currentTableAppliedFilters = tableFilters[tableName];
  const filterWhereClauses = useMemo(() => {
    const returned: unknown[] = [];

    if (
      currentTableFilters &&
      currentTableFilters.length > 0 &&
      currentTableAppliedFilters
    ) {
      currentTableFilters.forEach((k) => {
        const filterExistsOnTable = currentTableAppliedFilters.find(
          (f) => f.filterName === k
        );
        // handle when multiple values
        if (
          filterExistsOnTable &&
          Array.isArray(filterExistsOnTable.values) &&
          filterExistsOnTable.values.length > 0
        ) {
          returned.push(filterExistsOnTable.computedFilter);
        }
        // handle when single value
        if (
          filterExistsOnTable &&
          !Array.isArray(filterExistsOnTable.values) &&
          filterExistsOnTable.values
        ) {
          returned.push(filterExistsOnTable.computedFilter);
        }
      });
      return returned.filter(Boolean);
    }
    return null;
  }, [currentTableAppliedFilters, currentTableFilters]);

  const where = useMemo(() => {
    const clauses: unknown[] = [];
    const safeVariables = variables || []; // Provide a default empty array

    safeVariables
      .map((v) => removeEmpty(v || null)) // Tidy up empty vars
      .filter(Boolean)
      .forEach((filter) => clauses.push(filter));

    if (searchWhereClause) {
      clauses.push(...searchWhereClause);
    }

    if (filterWhereClauses && filterWhereClauses.length > 0) {
      clauses.push(...filterWhereClauses);
    }

    if (clauses.length > 0) {
      return { AND: clauses };
    }

    return {};
  }, [variables, searchWhereClause, filterWhereClauses]);

  return where;
};

// Utility function to remove empty fields from an object
function removeEmpty(obj: Record<string, unknown> | null) {
  if (!obj) {
    return null;
  }
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}
