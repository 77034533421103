import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { ADMIN_HOME_ROUTE, SIMPLE_ERROR_MESSAGE } from '@scannable/common';
import { useGetUsersOrganisationsQuery } from '@scannable/frontend/common';
import { FormFieldOptionType } from '@scannable/frontend/types';

import { useAuth } from '../../auth/AuthContext/AuthContext';
import ModalForm from '../../forms/ModalForm/ModalForm';
import { InputFieldGroup } from '../../molecules';
import { errorAlert } from '../../utils';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

interface SelectOrganisationValues {
  organisationId: number | undefined;
}

export interface SelectOrganisationModalProps {
  options: FormFieldOptionType[];
}

const selectOrgValidationSchema = yup.object().shape({
  organisationId: yup.number().required('Please select an organisation'),
});

export interface OrganisationSelectorModalProps {
  dismissable: boolean;
}

export function OrganisationSelectorModal({
  dismissable,
}: OrganisationSelectorModalProps) {
  const { organisationId, changeOrganisation } = useAuth();
  const { hideModal } = useModal();
  const router = useRouter();

  const { data } = useGetUsersOrganisationsQuery();

  const options = data?.myOrganisations.map((org) => ({
    label: org.name,
    value: org.id,
  }));
  const formik = useFormik({
    initialValues: {
      organisationId: organisationId || 0,
    },
    validationSchema: selectOrgValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values: SelectOrganisationValues) => {
      try {
        if (values.organisationId) {
          await changeOrganisation(values.organisationId);
          hideModal();
          router.push(ADMIN_HOME_ROUTE);
        } else {
          errorAlert(SIMPLE_ERROR_MESSAGE);
        }
      } catch (e) {
        errorAlert(SIMPLE_ERROR_MESSAGE);
      }
    },
  });

  return (
    <Modal title="Select an Organisation" size="sm" overflowHidden={false}>
      <ModalForm
        dismissable={dismissable}
        formik={formik}
        buttons={[
          {
            label: 'Select',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup
          {...{
            id: 'orgId',
            name: 'organisationId',
            type: 'react-select',
            label: 'Your organisations',
            options,
            loading: !options || options.length === 0,
          }}
          formik={formik}
        />
      </ModalForm>
    </Modal>
  );
}

export default OrganisationSelectorModal;
