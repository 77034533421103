import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  RequestHandler,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';

import { isDefined } from '@scannable/common';

import { jwtExpiryLink } from '../links/jwt-expiry.link';
import { onErrorLink } from '../links/on-error.link';
import { ApolloConfig } from '../types/client.types';

export const initApolloClient = (initialState = {}, opts: ApolloConfig) => {
  const { endpoint, getToken, getUserId, wsEndpoint, origin } = opts;
  const cache = new InMemoryCache().restore(initialState);

  const language = typeof navigator !== 'undefined' ? navigator.language : 'en';
  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    const userId = await getUserId();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        userid: userId,
        accept: 'application/json',
        'Accept-Language': language,
        origin,
      },
    };
  });
  const retryLink = new RetryLink({
    delay: {
      initial: 3000,
    },
    attempts: {
      max: 2,
      retryIf: (error) => !!error,
    },
  });
  const errorLink = onErrorLink(opts);
  const jwtLink = jwtExpiryLink(opts);
  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeFetchResult: true,
      includeError: true,
      includeCache: false,
    },
  });
  const httpLink = ApolloLink.from(
    [
      authLink,
      errorLink,
      sentryLink,
      jwtLink,
      retryLink,
      createUploadLink({
        uri: endpoint,
        credentials: 'include',
        headers: {
          'apollo-require-preflight': 'true',
        },
      }) as unknown as ApolloLink | RequestHandler,
    ].filter(isDefined)
  );

  const wsLink =
    typeof window !== 'undefined' && wsEndpoint
      ? new GraphQLWsLink(
          createClient({
            url: wsEndpoint,
            connectionParams: async () => {
              const token = await getToken();
              return { authorization: token ? `Bearer ${token}` : '' };
            },
          })
        )
      : null;

  const link =
    typeof window !== 'undefined' && wsLink != null
      ? split(
          ({ query }) => {
            const def = getMainDefinition(query);
            return (
              def.kind === 'OperationDefinition' &&
              def.operation === 'subscription'
            );
          },
          wsLink,
          httpLink
        )
      : httpLink;

  const client = new ApolloClient({
    ssrMode: false,
    link,
    cache,
  });

  return client;
};
