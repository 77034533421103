import { HTMLAttributes } from 'react';

import { cn } from '@scannable/common';

import { LoadingIcon } from '../../atoms';

export interface LoadingPanelProps extends HTMLAttributes<HTMLDivElement> {
  minHeight?: number;
}

export function LoadingPanel({
  className,
  minHeight = 300,
}: LoadingPanelProps) {
  return (
    <div
      className={cn(
        `relative flex min-h-[${minHeight}px] items-center justify-center`,
        className || ''
      )}
    >
      <LoadingIcon size="sm" />
    </div>
  );
}

export default LoadingPanel;
