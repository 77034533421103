import { ReactNode } from 'react';
import { FormikProps } from 'formik';

import { cn } from '@scannable/common';

import { Alert, Button, ButtonProps } from '../../atoms';
import { useModal } from '../../modals/ModalContext/ModalContext';
import { ModalFooter } from '../../molecules/ModalFooter/ModalFooter';
import Form from '../Form/Form';

interface ModalFormProps<V> {
  formik: FormikProps<V>;
  children?: ReactNode;
  warning?: string;
  buttons?: (ButtonProps & { 'data-cy'?: string })[];
  dismissable?: boolean;
  renderAction?: () => ReactNode;
  className?: string;
}

export function ModalForm<V>({
  formik,
  children,
  warning,
  buttons,
  renderAction,
  dismissable = true,
  className,
  ...props
}: ModalFormProps<V>) {
  const { hideModal } = useModal();
  return (
    <Form {...props} formik={formik}>
      <div className="flex flex-1 flex-col h-full">
        <div className="flex-1 p-5">
          <div className={cn('mt-2 space-y-4', className || '')}>
            {children}
          </div>
          {warning && (
            <div className="pt-4 text-sm">
              <Alert type="warning" message={warning} />
            </div>
          )}
        </div>
        <ModalFooter
          onClose={hideModal}
          dismissable
          renderAction={() => {
            if (renderAction) {
              return renderAction();
            }
            if (buttons && buttons.length > 0) {
              return (
                <div className="flex space-x-3">
                  {buttons.map((buttonProps, index) => (
                    <Button
                      key={index}
                      className="disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400 disabled:bg-gray-200"
                      disabled={formik.isSubmitting}
                      loading={formik.isSubmitting}
                      {...buttonProps}
                    />
                  ))}
                </div>
              );
            }
            return null;
          }}
        />
      </div>
    </Form>
  );
}

export default ModalForm;
