import { useQuery } from '@apollo/client';
import { ShieldCheckIcon } from '@heroicons/react/20/solid';

import { calculatePercentage } from '@scannable/common';
import { MANUFACTURER_STATS } from '@scannable/graphql-queries';

import { useTranslation } from '../../hooks';
import { DashboardStat } from '../../molecules';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EnterpriseStatisticsProps {}

export function EnterpriseStatistics(props: EnterpriseStatisticsProps) {
  const { t } = useTranslation();
  const { data, loading } = useQuery(MANUFACTURER_STATS, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div
      data-cy="manufacturer-stats"
      className="mb-5 rounded-lg shadow-md py-2 bg-brand-secondary mt-2 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 divide-x divide-gray-600"
    >
      <DashboardStat
        name={t('products')}
        isLoading={loading}
        value={data?.getManufacturerStats?.productCount}
        icon="products"
        ctaHref="/admin/products"
        ctaText="View all"
      />
      <DashboardStat
        name={t('dashboard.product_variations')}
        isLoading={loading}
        value={data?.getManufacturerStats?.productVariationCount}
        icon="data"
        ctaHref="/admin/products"
        ctaText="View all"
      />
      <DashboardStat
        name={t('dashboard.serialised_products')}
        isLoading={loading}
        value={data?.getManufacturerStats?.totalSerialisedItems}
        icon="data"
      />
      <DashboardStat
        name={t('dashboard.claimed_products')}
        isLoading={loading}
        value={data?.getManufacturerStats?.claimedProductCount}
        percent={calculatePercentage(
          data?.getManufacturerStats?.claimedProductCount,
          data?.getManufacturerStats?.totalSerialisedItems
        )}
        card={{ icon: ShieldCheckIcon }}
      />
    </div>
  );
}

export default EnterpriseStatistics;
