import { useQuery } from '@apollo/client';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';

import { calculatePercentage, Organisation } from '@scannable/common';
import { graphql } from '@scannable/graphql-queries';

import { useTranslation } from '../../hooks';
import { DashboardStat } from '../../molecules';

const SCANNABLE_STATS = graphql(`
  query ${Organisation.ScannableStats} {
    scannableStats {
      manufacturerCount
      productVariationCount
      serialisedProductCount
      claimedProductCount
      userCount
      signupCount
      inspectionCount
      inspectionCountLastWeek
      nfcReadCount
      qrCodeScanCount
    }
  }
`);

export function ScannableStatistics() {
  const { data, loading } = useQuery(SCANNABLE_STATS);

  const { t } = useTranslation();

  return (
    <div className="p-2 mb-5 rounded-lg shadow-md bg-brand-secondary divide-y divide-gray-600">
      <div className="pt-2 pb-2 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 divide-x divide-gray-600">
        <DashboardStat
          name={t('manufacturers')}
          isLoading={loading}
          value={data?.scannableStats?.manufacturerCount}
          icon="producers"
        />
        <DashboardStat
          name={t('dashboard.product_variations')}
          isLoading={loading}
          value={data?.scannableStats?.productVariationCount}
          icon="data"
        />
        <DashboardStat
          name={t('dashboard.serialised_products')}
          isLoading={loading}
          value={data?.scannableStats?.serialisedProductCount}
          icon="data"
        />
        <DashboardStat
          name={t('dashboard.claimed_products')}
          isLoading={loading}
          value={data?.scannableStats?.claimedProductCount}
          percent={calculatePercentage(
            data?.scannableStats?.claimedProductCount,
            data?.scannableStats?.serialisedProductCount
          )}
          card={{ icon: ShieldCheckIcon }}
        />
      </div>
      <div className="pt-2 pb-2 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 divide-x divide-gray-600">
        <DashboardStat
          name={t('users')}
          isLoading={loading}
          value={data?.scannableStats?.userCount}
          icon="users"
        />
        <DashboardStat
          name={t('dashboard.user_signups')}
          isLoading={loading}
          value={data?.scannableStats?.signupCount}
          icon="users"
        />
        <DashboardStat
          name={t('dashboard.inspections', 'Inspections')}
          isLoading={loading}
          value={data?.scannableStats?.inspectionCount}
          icon="inspections"
        />
        <DashboardStat
          name={t('dashboard.inspections_week', 'Inspections (last 7 days)')}
          isLoading={loading}
          value={data?.scannableStats?.inspectionCountLastWeek}
          icon="inspections"
        />
      </div>
      {/* <div className="pt-2 pb-2 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 divide-x divide-gray-600">
        <DashboardStat
          name="NFC Reads"
          isLoading={loading}
          value={data?.scannableStats?.nfcReadCount}
          icon="data"
        />
        <DashboardStat
          name="QR Code Scans"
          isLoading={loading}
          value={data?.scannableStats?.qrCodeScanCount}
          icon="data"
        />
      </div> */}
    </div>
  );
}

export default ScannableStatistics;
