import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useFormik } from 'formik';

import { Printing } from '@scannable/common';
import {
  GET_ALL_LABEL_TEMPLATES,
  UPDATE_LABEL_TEMPLATES,
} from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Form from '../Form/Form';

type EditLabelTemplatesValues = {
  templateUrl: string;
};

export function EditLabelTemplatesForm() {
  const { organisationId } = useAuth();
  const { data } = useQuery(GET_ALL_LABEL_TEMPLATES, {
    variables: { organisationId },
    fetchPolicy: 'cache-and-network',
    skip: !organisationId,
  });

  const [updateLabelTemplates] = useMutation(UPDATE_LABEL_TEMPLATES, {
    refetchQueries: [Printing.GetAllLabelTemplates],
  });
  const { t } = useTranslation();
  const [mode, setMode] = useState<'view' | 'add'>('view');
  const transformTemplateString = (template: string) => {
    const url = new URL(template);
    const design = url.searchParams.get('design') || '';
    const variables = url.searchParams.get('variables');
    const variablesObj = variables ? JSON.parse(variables) : {};
    const variableNames = Object.keys(variablesObj);

    return {
      id: design,
      variables: variableNames,
    };
  };

  const existingTemplates = useMemo(() => {
    return data?.labelTemplates
      ? data.labelTemplates.map((template) => {
          return {
            id: template.id,
            variables: template.variables,
          };
        })
      : [];
  }, [data?.labelTemplates]);

  const mergeTemplates = (newTemplate: { id: string; variables: string[] }) => {
    let mergedTemplates = [];
    const existingTemplateIndex = existingTemplates.findIndex(
      (template) => template.id === newTemplate.id
    );
    if (existingTemplateIndex > -1) {
      existingTemplates[existingTemplateIndex] = newTemplate;
      mergedTemplates = existingTemplates;
    } else {
      mergedTemplates = [...existingTemplates, ...[newTemplate]];
    }

    return mergedTemplates;
  };

  const submitTemplates = async (
    templates: { id: string; variables: string[] | null }[]
  ) => {
    return resolveMutation(
      updateLabelTemplates({
        variables: {
          data: {
            templates: templates,
          },
        },
      }),
      {
        successMessage: 'Templates updated',
      }
    );
  };

  const deleteTemplate = (templateId: string) => {
    const filteredTemplates = existingTemplates.filter(
      (t: { id: string }) => t.id !== templateId
    );
    updateLabelTemplates({
      variables: {
        data: {
          templates: filteredTemplates || [],
        },
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      templateUrl: '',
    },
    enableReinitialize: true,
    onSubmit: async (values: EditLabelTemplatesValues, { setSubmitting }) => {
      const transformedTemplate = transformTemplateString(values.templateUrl);
      const mergedTemplates = mergeTemplates(transformedTemplate);
      const result = await submitTemplates(mergedTemplates);
      if (result.ok) {
        setSubmitting(false);
        setMode('view');
      }
    },
  });
  return (
    <>
      <div className="mb-4">
        {data?.labelTemplates &&
          data.labelTemplates.length > 0 &&
          data.labelTemplates.map((template) => (
            <div
              key={template.id}
              className="flex flex-row justify-between items-center w-full p-2 border-b border-gray-200"
            >
              <div>{template.id}</div>
              <div className="flex flex-row space-x-2">
                <Button
                  color="white"
                  onClick={() => deleteTemplate(template.id)}
                >
                  <XMarkIcon className="h-5 w-5" />
                </Button>
              </div>
            </div>
          ))}
      </div>
      {mode !== 'add' && (
        <Button
          color="info"
          onClick={() => {
            formik.resetForm();
            setMode('add');
          }}
        >
          Add new template
        </Button>
      )}

      {mode === 'add' && (
        <Form
          formik={formik}
          buttons={[
            {
              type: 'button',
              color: 'info',
              className: 'mr-2',
              label: t('cancel'),
              onClick: () => {
                setMode('view');
              },
            },
            {
              type: 'submit',
              label: t('save'),
            },
          ]}
        >
          <InputFieldGroup
            formik={formik}
            name="templateUrl"
            type="text"
            label="Paste label live template HTTP POST URL"
            placeholder="e.g. http://localhost:11180/api/v1/print?design=template1..."
          />
        </Form>
      )}
    </>
  );
}
export default EditLabelTemplatesForm;
