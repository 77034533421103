import React from 'react';

import { cn } from '@scannable/common';

export interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export function Title({ children, size = 'h1', ...props }: TitleProps) {
  let classes = 'text-2xl';
  switch (size) {
    case 'h1':
      classes = 'text-xl';
      break;
    case 'h2':
      classes = 'text-lg';
      break;
    case 'h3':
      classes = 'text-lg';
      break;
    case 'h4':
      classes = 'text-md';
      break;
    case 'h5':
      classes = 'text-sm';
      break;
    case 'h6':
      classes = 'text-xs';
      break;
    default:
      break;
  }
  return React.createElement(
    size,
    {
      className: cn(
        'font-semibold text-gray-900 mt-2',
        classes,
        props.className || ''
      ),
    },
    children
  );
}

export default Title;
