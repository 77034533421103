import { AllHTMLAttributes } from 'react';

export function TableRow({
  children,
  className,
  ...rest
}: AllHTMLAttributes<HTMLTableRowElement>) {
  return (
    <tr className={className} {...rest}>
      {children}
    </tr>
  );
}

export default TableRow;
