import { Invites } from '@scannable/common';

import { graphql } from '../graphql';

export const SEND_INVITES =
  graphql(`mutation ${Invites.SendInvites} ($data: CreateInvitesInput!) {
    createInvitations(data: $data) {
        count
    }
}`);

export const ACCEPT_INVITE =
  graphql(`mutation ${Invites.AcceptInvitation} ($data: AcceptInviteInput!) {
  acceptInvitation(data: $data) {
  id
}
}`);

export const DECLINE_INVITE =
  graphql(`mutation ${Invites.Decline} ($data: DeclineInviteInput!) {
  declineInvitation(data: $data)
}`);

export const DELETE_INVITE = graphql(
  `mutation ${Invites.DeleteInvitation} ($data: DeleteInviteInput!) {
    deleteInvitation(data: $data) {
      id
    }
  }`
);
