import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { calculatePercentage } from '@scannable/common';
import { useAccountSubscription } from '@scannable/frontend/common';

import { Badge, Button, Image, Text } from '../../atoms';
import { resolveMutation } from '../../lib/lib';
import { ConfirmationModal, useModal } from '../../modals';
import {
  AccountSetting,
  ReactSelectInput,
  UsagePercentageBar,
} from '../../molecules';

export function SubscriptionsPanel() {
  const { showModal } = useModal();
  const [value, setValue] = useState(0);

  const { subscriptionInfo, updateSubscription, cancelSubscription } =
    useAccountSubscription({
      skipAccountInfo: false,
    });

  useEffect(() => {
    if (subscriptionInfo?.inventoryLimit) {
      setValue(subscriptionInfo.inventoryLimit);
    }
  }, [subscriptionInfo?.inventoryLimit]);

  if (!subscriptionInfo) {
    return null;
  }
  const interval = subscriptionInfo.interval === 'month' ? 'Monthly' : 'Yearly';
  const options = [];

  if (subscriptionInfo.priceTiers) {
    for (const tier of subscriptionInfo.priceTiers) {
      options.push({
        label: `From ${tier.from} to ${tier.upTo} items (${tier.priceDisplay})`,
        value: tier.upTo,
        isDisabled: tier.upTo <= subscriptionInfo.currentInventory,
      });
    }
  }

  return (
    <AccountSetting title="Subscription">
      <div>
        <div className="border border-gray-200 rounded-md w-full p-4 grid grid-cols-2">
          <div>
            <div className="flex">
              <Image
                src="/static/scannable-icon.svg"
                alt="Scannable"
                className="object-contain object-left"
                width={25}
                height={25}
              />
              <span className="mx-2">PRO</span> <Badge>Billed {interval}</Badge>
            </div>
            {!subscriptionInfo.cancelAtPeroidEnd && (
              <Text>
                Your plan renews on:{' '}
                {format(new Date(subscriptionInfo.peroidEnd), 'MMMM d, yyyy')}
              </Text>
            )}
            {subscriptionInfo.cancelAtPeroidEnd && (
              <Text className="text-red-400">
                Your subscription to Scannable Pro ends on{' '}
                {format(
                  new Date(subscriptionInfo.subscriptionEndDate),
                  'MMMM d, yyyy'
                )}
              </Text>
            )}
            <div className="px-1 my-2">
              <UsagePercentageBar
                percent={calculatePercentage(
                  subscriptionInfo.currentInventory,
                  subscriptionInfo.inventoryLimit
                )}
              />
            </div>
            {/* <Text>Your next estimated payment will be $50.00</Text> */}
            <Text>
              Current inventory count: {subscriptionInfo.currentInventory}
            </Text>
            <Text>Current plan limit: {subscriptionInfo.inventoryLimit}</Text>
          </div>
          {!subscriptionInfo.cancelAtPeroidEnd && (
            <div>
              <ReactSelectInput
                name="options"
                options={options}
                onChange={(v) => {
                  if (v) {
                    setValue(v.value);
                  }
                }}
                value={options.find((o) => o.value === value)}
              />
              <div className="flex justify-end">
                <Button
                  color="lime"
                  className="mt-4"
                  disabled={value === subscriptionInfo.inventoryLimit}
                  onClick={async () => {
                    showModal(
                      <ConfirmationModal
                        title={`Update your Plan to Max ${value} Items`}
                        type="lime"
                        message="Your plan will be updated to the new limit and you will be charged the difference."
                        buttonLabel="Update Plan"
                        hideOnConfirm
                        onConfirm={async () => {
                          await resolveMutation(
                            updateSubscription({
                              variables: {
                                data: {
                                  inventoryLimit: value,
                                },
                              },
                            }),
                            {
                              successMessage: 'Your plan has been updated',
                              failureMessage: 'Error updating your plan',
                            }
                          );
                        }}
                      />
                    );
                  }}
                >
                  Change Your Plan
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 flex space-x-4 justify-end">
          <Button
            color="info"
            onClick={() => {
              if (subscriptionInfo?.portalUrl) {
                window.location.href = subscriptionInfo.portalUrl;
              }
            }}
          >
            View Billing Info
          </Button>
          {!subscriptionInfo.cancelAtPeroidEnd && (
            <Button
              color="danger"
              onClick={() =>
                showModal(
                  <ConfirmationModal
                    title="Are You Sure You Want to Cancel Your Subscription?"
                    buttonLabel="Cancel"
                    hideOnConfirm
                    onConfirm={async () => {
                      await resolveMutation(cancelSubscription(), {
                        successMessage: 'Your subscription has been cancelled',
                        failureMessage: 'Error cancelling your subscription',
                      });
                    }}
                  />
                )
              }
            >
              Cancel Subscription
            </Button>
          )}
        </div>
      </div>
    </AccountSetting>
  );
}

export default SubscriptionsPanel;
