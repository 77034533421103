import { useMutation } from '@apollo/client';

import {
  UPDATE_ACCOUNT,
  UPDATE_ADDRESS,
  UPDATE_ORGANISATION,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useUpdateOrganisationMutation(
  options?: UseMutationOptions<typeof UPDATE_ORGANISATION>
) {
  return useMutation(UPDATE_ORGANISATION, options);
}

export function useUpdateAccountMutation(
  options?: UseMutationOptions<typeof UPDATE_ACCOUNT>
) {
  return useMutation(UPDATE_ACCOUNT, options);
}

export function useUpdateAddressMutation(
  options?: UseMutationOptions<typeof UPDATE_ADDRESS>
) {
  return useMutation(UPDATE_ADDRESS, options);
}
