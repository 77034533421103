import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';

import { PrinterOption } from '@scannable/common';
import { h } from '@scannable/frontend/common';
import { PRINT_ONE_SERIAL } from '@scannable/graphql-queries';

import { ModalForm } from '../../forms';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import InputFieldGroup from '../../molecules/InputFieldGroup/InputFieldGroup';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

interface PrintOneSerialValues {
  serialNumber: string;
}
type PrintOneSerialModalProps = {
  serialPurchaseOrderId: string;
  printer: PrinterOption;
};
export function PrintOneSerialModal({
  serialPurchaseOrderId,
  printer,
}: PrintOneSerialModalProps) {
  const { t } = useTranslation();
  const [printOneSerial] = useMutation(PRINT_ONE_SERIAL);
  const { hideModal } = useModal();
  const formik = useFormik({
    initialValues: {
      serialNumber: '',
    },
    onSubmit: async (
      values: PrintOneSerialValues,
      actions: FormikHelpers<PrintOneSerialValues>
    ) => {
      const result = await resolveMutation(
        printOneSerial({
          variables: {
            data: {
              serialNumber: values.serialNumber,
              serialPurchaseOrderId,
              printerId: printer.id,
            },
          },
        })
      );
      if (result.ok) {
        actions.setSubmitting(false);
        hideModal();
      }
    },
  });

  return (
    <Modal title={h(t('printing.print_one_serial'))}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: t('printing.print_label'),
            type: 'submit',
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup
          name="serialNumber"
          type="text"
          label="Serial number"
          placeholder="Enter a serial number from this serial run"
          formik={formik}
        />
      </ModalForm>
    </Modal>
  );
}
