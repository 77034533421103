import { useMutation } from '@apollo/client';

import {
  MARK_ALL_AS_READ,
  UPDATE_ORG_NOTIFICATION_PREFERENCES,
  UPDATE_USER_NOTIFICATION_PREFERENCES,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useUpdateOrgNotificationPreferencesMutation(
  options?: UseMutationOptions<typeof UPDATE_ORG_NOTIFICATION_PREFERENCES>
) {
  return useMutation(UPDATE_ORG_NOTIFICATION_PREFERENCES, options);
}

export function useUpdateUserNotificationPreferencesMutation(
  options?: UseMutationOptions<typeof UPDATE_USER_NOTIFICATION_PREFERENCES>
) {
  return useMutation(UPDATE_USER_NOTIFICATION_PREFERENCES, options);
}

export function useMarkAllAsReadMutation(
  options?: UseMutationOptions<typeof MARK_ALL_AS_READ>
) {
  return useMutation(MARK_ALL_AS_READ, options);
}
