import { ProductCategories } from '@scannable/common';

import { graphql } from '../graphql';

export const CREATE_PRODUCT_GROUP_CATEGORY = graphql(`
  mutation ${ProductCategories.CreateProductGroupCategory} (
    $data: CreateProductGroupCategoryInput!
  ) {
    createProductGroupCategory(data: $data) {
      id
    }
  }
`);

export const UPDATE_PRODUCT_GROUP_CATEGORY = graphql(`
  mutation ${ProductCategories.UpdateProductGroupCategory} (
    $data: UpdateProductGroupCategoryInput!
  ) {
    updateProductGroupCategory(data: $data) {
      id
    }
  }
`);

export const UPDATE_PRODUCT_GROUP = graphql(`
  mutation ${ProductCategories.UpdateProductGroup} ($data: UpdateProductGroupInput!) {
    updateProductGroup(data: $data) {
      id
    }
  }
`);

export const ADD_PRODUCT_GROUP_METADATA = graphql(`
  mutation ${ProductCategories.AddMetadataToGroup} ($data: AddMetadataToProductGroupInput!) {
    addMetadataToProductGroup(data: $data) {
      id
    }
  }
`);

export const REMOVE_PRODUCT_GROUP_METADATA = graphql(`
  mutation ${ProductCategories.RemoveMetadataFromProductGroup}(
    $data: RemoveMetadataFromProductGroupInput!
  ) {
    removeMetadataFromProductGroup(data: $data) {
      id
    }
  }
`);

export const UPDATE_PRODUCT_GROUP_METADATA_ORDER = graphql(`
  mutation ${ProductCategories.UpdateProductGroupMetadataOrder} (
    $data: UpdateProductGroupMetadataOrderInput!
  ) {
    updateProductGroupMetadataOrder(data: $data) {
      id
    }
  }
`);

export const CREATE_PRODUCT_GROUP = graphql(`
  mutation ${ProductCategories.CreateProductGroup} (
    $data: CreateProductGroupInput!
  ) {
    createProductGroup(data: $data) {
      id
    }
  }
`);
