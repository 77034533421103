import { dateFormat } from '@scannable/common';

import { InventoryInspectionsModal, useModal } from '../../modals';
import { InventoryData } from '../../types/table-data.types';

export interface LastInspectionProps {
  inspectionCount: number;
  data: InventoryData['tierOne'];
}

export function LastInspection({ inspectionCount, data }: LastInspectionProps) {
  const { showModal } = useModal();
  return (
    <div className="flex items-center -my-2 overflow-hidden">
      {inspectionCount && inspectionCount > 0 ? (
        <span
          onClick={() => {
            if (data && data.id) {
              showModal(
                <InventoryInspectionsModal
                  id={data.id}
                  name={data.name}
                  code={data.code}
                />
              );
            }
          }}
          className="cursor-pointer text-blue-800 mr-2"
        >
          [{inspectionCount}]
        </span>
      ) : (
        <span className="mr-2 whitespace-nowrap overflow-hidden overflow-ellipsis">
          [0]
        </span>
      )}
      <span className="mr-2 whitespace-nowrap overflow-hidden overflow-ellipsis">
        {data?.lastInspection?.nameOfInspector || '/ --'}
      </span>
      {data?.lastInspection?.date ? (
        <span className="mr-2 whitespace-nowrap overflow-hidden overflow-ellipsis">
          {dateFormat(
            JSON.stringify(data.lastInspection.date).replace(/"/g, '')
          )}
        </span>
      ) : (
        <span className="mr-2">/ --</span>
      )}
    </div>
  );
}

export default LastInspection;
