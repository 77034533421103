import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_PAGINATED_NOTIFICATIONS } from '@scannable/graphql-queries';

const pageSize = 20;

export function usePaginatedNotifications() {
  const { loading, data, fetchMore, refetch } = useQuery(
    GET_PAGINATED_NOTIFICATIONS,
    {
      variables: {
        skip: 0,
        take: pageSize,
      },
      fetchPolicy: 'network-only',
    }
  );

  const hasMore = useMemo(() => {
    if (!data?.paginatedNotifications) {
      return false;
    }
    return (
      data &&
      (data?.paginatedNotifications?.nodes?.length ?? 0) <
        data?.paginatedNotifications?.totalCount
    );
  }, [data]);

  const loadMore = useCallback(() => {
    if (!loading && hasMore && data?.paginatedNotifications?.nodes) {
      fetchMore({
        variables: {
          skip: data.paginatedNotifications.nodes.length || 0,
          take: pageSize,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const newItems = fetchMoreResult?.paginatedNotifications?.nodes;
          if (!newItems) {
            return prev;
          }

          return {
            ...fetchMoreResult,
            paginatedNotifications: {
              ...fetchMoreResult.paginatedNotifications,
              nodes: [
                ...(prev?.paginatedNotifications?.nodes || []),
                ...newItems,
              ],
              totalCount:
                fetchMoreResult?.paginatedNotifications?.totalCount ?? 0,
            },
          };
        },
      });
    }
  }, [loading, hasMore, fetchMore, data?.paginatedNotifications]);

  return { loading, data, loadMore, hasMore, refetch };
}
