import { useQuery } from '@apollo/client';

import {
  MANUFACTURER_SETTINGS,
  ORGANISATION_SUBSCRIPTION,
} from '@scannable/graphql-queries';

interface UseUpdateSettingsProps {
  manufacturerId: number;
  organisationId: number;
}

export function useUpdateSettings({
  manufacturerId,
  organisationId,
}: UseUpdateSettingsProps) {
  const { data: manufacturerData, loading: manLoading } = useQuery(
    MANUFACTURER_SETTINGS,
    {
      variables: {
        id: manufacturerId,
      },
      skip: !manufacturerId,
    }
  );
  const { data: organisationData, loading: orgLoading } = useQuery(
    ORGANISATION_SUBSCRIPTION,
    {
      variables: {
        id: organisationId,
      },
      skip: !organisationId,
    }
  );

  return {
    manufacturerData: manufacturerData?.manufacturer,
    organisationData: organisationData?.organisation,
    loading: manLoading || orgLoading,
  };
}
