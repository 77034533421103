import CookieConstentComponent, { OPTIONS } from 'react-cookie-consent';

import { Button, Link } from '../../atoms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CookieConsentProps {}

export function CookieConsent(props: CookieConsentProps) {
  return (
    <CookieConstentComponent
      location={OPTIONS.BOTTOM}
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      cookieName="acceptsCookies"
      style={{
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.6)',
      }}
      buttonId="CookieConsent"
      buttonClasses="text-black bg-brand-primary text-xs"
      buttonWrapperClasses="flex space-x-4 mr-4 items-center justify-center"
      containerClasses="bg-gray-800"
      declineButtonClasses="text-black bg-brand-primary text-xs"
      disableButtonStyles
      expires={150}
      ButtonComponent={Button}
    >
      We use essential cookies to make our web app work. We also set additional
      cookies that help us improve your experience, and perform analytics. For
      more information, please visit our{' '}
      <Link
        href="https://www.scannable.io/privacy-policy"
        external
        className="text-white underline"
      >
        Privacy Policy.
      </Link>
    </CookieConstentComponent>
  );
}

export default CookieConsent;
