import { useRouter } from 'next/router';

import {
  Inventory,
  redirectToSignIn,
  SerialisedProduct,
} from '@scannable/common';
import {
  useAssignItemToUserMutation,
  useIsItemInInventoryQuery,
} from '@scannable/frontend/common';
import { Search } from '@scannable/frontend/types';

import { Button } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { resolveMutation } from '../../lib/lib';
import { useModal } from '../../modals/ModalContext/ModalContext';
import { CreateInventoryItemSidePanel, useSidePanel } from '../../side-panels';
import { errorAlert } from '../../utils';

export interface AddToKitButtonValues {
  productVariation?: Search;
  serialisedProductId?: number;
}

export function AddToKitButton({
  serialisedProductId,
  productVariation,
}: AddToKitButtonValues) {
  const router = useRouter();
  const { t } = useTranslation();
  const { hideModal } = useModal();
  const { hideSidePanel, showSidePanel } = useSidePanel();
  const { isLoggedIn, userId } = useAuth();

  const { data, loading: isItemInInventoryLoading } = useIsItemInInventoryQuery(
    {
      variables: {
        serialisedProductId: Number(serialisedProductId),
      },
      skip: !serialisedProductId || !isLoggedIn,
    }
  );

  const [assignItemToUser, { loading: assignToUserLoading }] =
    useAssignItemToUserMutation();

  const isInInventory = data?.isItemInInventory;

  const claimProduct = async () => {
    if (!isLoggedIn) {
      hideModal();
      errorAlert(t('claimproduct_error_msg'));
      return;
    }
    if (serialisedProductId && userId) {
      await resolveMutation(
        assignItemToUser({
          variables: {
            serialisedProductId,
            userId,
          },
          refetchQueries: [
            Inventory.IsItemInInventory,
            SerialisedProduct.SerialisedProductForUniqueUrl,
          ],
        }),
        {
          successMessage: t('claimproduct_success_msg'),
        }
      );
    }
    hideModal();
  };

  const login = () => {
    router.push(redirectToSignIn(window.location.pathname));
  };

  const action = () => {
    if (!isLoggedIn) {
      return login();
    }
    if (isInInventory) {
      return null;
    }
    if (productVariation && !serialisedProductId) {
      hideSidePanel();
      showSidePanel(
        <CreateInventoryItemSidePanel productVariation={productVariation} />,
        {
          title: t('add_to_kit'),
        }
      );
      return;
    }

    return claimProduct();
  };

  if (!serialisedProductId && !productVariation) {
    return null;
  }

  return (
    <div className="pb-4 px-4 mt-4">
      <Button
        color="lime"
        className="flex justify-center font-bold text-sm w-full"
        type="submit"
        loading={assignToUserLoading || isItemInInventoryLoading}
        onClick={action}
      >
        {isInInventory && t('item_is_in_kitlist')}
        {(isLoggedIn && !isInInventory ? true : false) && t('add_to_kitlist')}
        {!isLoggedIn && t('login_to_add_to_kitlist')}
      </Button>
    </div>
  );
}

export default AddToKitButton;
