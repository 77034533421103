import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  FormikErrors,
  FormikProps,
  FormikState,
  FormikTouched,
} from 'formik';

import { Alert } from '../../atoms';
import { FormMessage } from '../../hooks/useFormSubmitMessage/useFormSubmitMessage';

export interface IFormik<Values> {
  initialValues: Values;
  initialErrors: FormikErrors<unknown>;
  initialTouched: FormikTouched<unknown>;
  initialStatus: unknown;
  handleBlur: {
    (e: React.FocusEvent<unknown>): void;
    <T = unknown>(fieldOrEvent: T): T extends string
      ? (e: unknown) => void
      : void;
  };
  handleChange: {
    (e: React.ChangeEvent<unknown>): void;
    <T_1 = string | React.ChangeEvent<unknown>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<unknown>
      ? void
      : (e: string | React.ChangeEvent<unknown>) => void;
  };
  handleReset: (e: unknown) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void;
  setErrors: (errors: FormikErrors<Values>) => void;
  setFormikState: (
    stateOrCb:
      | FormikState<Values>
      | ((state: FormikState<Values>) => FormikState<Values>)
  ) => void;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Values>> | Promise<void>;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Values>> | Promise<void>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: unknown) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  setTouched: (
    touched: FormikTouched<Values>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Values>> | Promise<void>;
  setValues: (
    values: React.SetStateAction<Values>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Values>> | Promise<void>;
  submitForm: () => Promise<unknown>;
  validateForm: (values?: Values) => Promise<FormikErrors<Values>>;
  validateField: (name: string) => Promise<void> | Promise<string | undefined>;
  isValid: boolean;
  dirty: boolean;
  unregisterField: (name: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerField: (name: string, { validate }: any) => void;
  getFieldProps: (nameOrOptions: unknown) => FieldInputProps<unknown>;
  getFieldMeta: (name: string) => FieldMetaProps<unknown>;
  getFieldHelpers: (name: string) => FieldHelperProps<unknown>;
  validateOnBlur: boolean;
  validateOnChange: boolean;
  validateOnMount: boolean;
  values: Values;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
  isSubmitting: boolean;
  isValidating: boolean;
  status?: unknown;
  submitCount: number;
}
export interface FormProps<V> {
  formik: FormikProps<V>;
  children: React.ReactNode;
  formMessage?: FormMessage | null;
  [key: string]: unknown;
}

// see here for inspiration: https://reakit.io/docs/form/
export function CustomForm<V>({
  formik,
  children,
  formMessage,
  ...props
}: FormProps<V>) {
  return (
    <form {...props} onSubmit={formik.handleSubmit} noValidate>
      {formMessage && !formMessage.ok && (
        <Alert
          type={!formMessage.ok ? 'error' : 'success'}
          message={formMessage.formMessage}
          messages={formMessage.messages}
        />
      )}
      {children}
    </form>
  );
}

export default CustomForm;
