import toast from 'react-hot-toast';

import { cn } from '@scannable/common';

import Button, { ButtonProps } from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';

export interface ShareButtonProps extends ButtonProps {
  label?: string;
  url: string;
  title?: string;
  text?: string;
  iconSize?: number;
}
export function ShareButton({
  label,
  title,
  text,
  url,
  color,
  className,
  iconSize = 22,
}: ShareButtonProps) {
  return (
    <Button
      color={color}
      className={cn(
        'border border-gray-300 text-base text-gray-500',
        className || ''
      )}
      onClick={() => {
        if (!navigator.canShare || !navigator.canShare()) {
          navigator.clipboard.writeText(url);
          toast.success('Link copied to clipboard');
        } else {
          navigator.share({
            title,
            text,
            url,
          });
        }
      }}
    >
      {label && <span className="mr-2">{label}</span>}
      <Icon name="share" height={iconSize} width={iconSize} />
    </Button>
  );
}

export default ShareButton;
