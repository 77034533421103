export interface FormAdminPanelProps {
  renderFormFields: () => JSX.Element;
  renderFormAction: () => JSX.Element;
}

export function FormAdminPanel({
  renderFormFields,
  renderFormAction,
}: FormAdminPanelProps) {
  return (
    <div className="shadow rounded-md bg-white">
      <div className="bg-white py-6 px-4 space-y-6 sm:p-6 rounded-t-md">
        {renderFormFields()}
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 rounded-b-md">
        {renderFormAction()}
      </div>
    </div>
  );
}

export default FormAdminPanel;
