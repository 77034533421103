import Icon, { IconType } from '../../atoms/Icon/Icon';

interface EmptyStateProps {
  title: string;
  description: string;
  icon?: IconType;
}

export function EmptyState({
  title,
  description,
  icon = 'specs',
}: EmptyStateProps) {
  return (
    <div className="">
      <div className="flex justify-center mt-8">
        <div className="flex h-12 w-12 bg-gray-50 rounded-lg items-center justify-center">
          <Icon name={icon} className="text-gray-800" />
        </div>
      </div>

      <div className="px-5 my-3 p-2 text-md font-semibold text-gray-800 text-center">
        {title}
      </div>
      <div className="px-5 text-sm text-gray-600 text-center">
        {description}
      </div>
    </div>
  );
}
