import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ChecklistType, InspectionStatus } from '@prisma/client';
import { useFormik } from 'formik';

import {
  determineIsFailResult,
  getChecklistResultStatus,
  Inventory,
  ItemHistory,
  MAX_USER_UPLOAD_FILES,
  SerialisedProduct,
} from '@scannable/common';
import {
  ActionType,
  useCreateMultipleInspectionsMutation,
  useCreateSingleInspectionMutation,
  useFindActiveExaminationsQuery,
} from '@scannable/frontend/common';
import {
  FIND_ITEM_DATA_FIELD,
  GET_CHECKLIST,
  GET_CHECKLISTS_FOR_SERIALISED_PRODUCT,
} from '@scannable/graphql-queries';

import { useSidePanel } from '..';
import { Divider } from '../../atoms';
import { useAuth, useIsAccess, useIsCompetentPerson } from '../../auth';
import { SidePanelForm } from '../../forms';
import { useFeatureFlag, useTable, useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { useConfirmModal } from '../../modals/ConfirmationModal/useConfirmModal';
import {
  InputFieldGroup,
  InspectionStatusBadge,
  ItemPreview,
} from '../../molecules';
import { InspectionStatusSelector } from '../../organisms';
import { withSidePanelLoading } from '../withSidePanelLoading/withSidePanelLoading';
import { InspectionChecklistInput } from './InspectionChecklistInput';
import {
  CreateInspectionSidePanelProps,
  InspectionChecklistWithCriteria,
  InspectionSidePanelProps,
  InspectionValues,
  validationSchema,
} from './InspectionSidePanel.types';

function InspectionSidePanel({
  id: serialisedProductId,
  ids: inspectionItems,
  actionType,
  examinationOptions,
  isCompetentPerson,
  checklists,
  isChecklistsEnabled,
  currentChecklistId,
  selectedChecklistCriteria,
  onChecklistChange,
  swlValue,
  ...props
}: InspectionSidePanelProps) {
  const { t } = useTranslation();
  const successMessage = 'Inspection completed';

  const { manufacturerId } = useAuth();
  const { hideSidePanel } = useSidePanel();
  const [createInspection] = useCreateSingleInspectionMutation({
    refetchQueries: [
      Inventory.PaginatedWebInventory,
      SerialisedProduct.SerialisedProductForUniqueUrl,
      ItemHistory.PaginatedItemHistory,
    ],
  });
  const [createInspections] = useCreateMultipleInspectionsMutation({
    refetchQueries: [
      Inventory.PaginatedWebInventory,
      SerialisedProduct.SerialisedProductForUniqueUrl,
      ItemHistory.PaginatedItemHistory,
    ],
  });

  const { showConfirmModal } = useConfirmModal();

  const formik = useFormik<InspectionValues>({
    initialValues: {
      manufacturerId: 0,
      examinationId: examinationOptions.length
        ? examinationOptions[0].value
        : null, // default to first option
      passed: false,
      notes: '',
      swl: isCompetentPerson && swlValue ? swlValue : null,
      status: InspectionStatus.PASSED,
      criteria: {},
    },
    validationSchema: validationSchema(selectedChecklistCriteria),
    onSubmit: async (values, actions) => {
      const checklistResult = !currentChecklistId
        ? null
        : {
            checklistId: currentChecklistId,
            criteriaResults: Object.keys(values.criteria).map((key) => ({
              checklistCriteriaId: key,
              result: values.criteria[key],
            })),
          };

      const value = {
        passed: values.passed,
        notes: values.notes,
        status: values.status,
        type: values.type,
        source: values.source,
        manufacturerId: manufacturerId ?? null,
        examinationId: values.examinationId ?? null,
        swl: values.swl ?? null,
        checklistResult,
      };

      const isFailResult = determineIsFailResult(
        checklistResult,
        values.status ?? undefined
      );
      if (isFailResult) {
        const confirmWantsFail = await showConfirmModal({
          title: t('inspections.confirm_fail_inspection_title'),
          message: t('inspections.confirm_fail_inspection_description'),
          buttonLabel: t('inspections.confirm_fail_inspection_button'),
        });
        if (!confirmWantsFail) {
          return;
        }
      }

      if (actionType === ActionType.SingleItem && serialisedProductId) {
        actions.setSubmitting(true);
        const result = await resolveMutation(
          createInspection({
            variables: {
              data: {
                serialisedProductId,
                images: values.images ?? null,
                pdfs: values.pdfs ?? null,
                value,
              },
            },
          }),
          {
            successMessage,
          }
        );
        actions.setSubmitting(false);
        if (result && !result.ok) {
          return;
        }
      }

      if (actionType === ActionType.ListOfItems && inspectionItems) {
        actions.setSubmitting(true);
        const inspections = inspectionItems.map((inspectionItem) => ({
          serialisedProductId: inspectionItem,
          images: values.images ?? undefined,
          pdfs: values.pdfs ?? undefined,
          value,
        }));

        const result = await resolveMutation(
          createInspections({
            variables: {
              data: inspections,
            },
          }),
          {
            successMessage,
          }
        );
        actions.setSubmitting(false);
        if (result && !result.ok) {
          return;
        }
      }
      hideSidePanel();
    },
  });

  const inspectionResult = useMemo(() => {
    return getChecklistResultStatus(
      selectedChecklistCriteria?.map((criteria) => {
        return {
          id: criteria.id,
          result: formik.values.criteria[criteria.id],
        };
      }) || []
    );
  }, [formik.values.criteria, selectedChecklistCriteria]);

  const uploadLimit = MAX_USER_UPLOAD_FILES;

  const imagesCount = formik.values.images?.length || 0;
  const pdfsCount = formik.values.pdfs?.length || 0;

  const maxFilesReachedMessage = t(
    'uploads.max_uploads_for_inspections'
  ).replace('#', `${uploadLimit}`);

  return (
    <SidePanelForm
      formik={formik}
      buttons={[
        {
          label:
            actionType === ActionType.ListOfItems && inspectionItems
              ? `Complete ${inspectionItems?.length} Inspections`
              : 'Complete Inspection',
          type: 'submit',
          onClick: () => {
            formik.submitForm();
          },
          color: 'primary',
        },
      ]}
      warning={
        actionType === ActionType.ListOfItems
          ? 'This will create the same inspection for all items selected'
          : undefined
      }
    >
      {actionType === ActionType.SingleItem && serialisedProductId && (
        <ItemPreview {...props} />
      )}
      {actionType === ActionType.ListOfItems && inspectionItems && (
        <div className="flex mb-6">
          <div className="text-md font-medium text-gray-900">
            {`Inspecting ${inspectionItems?.length} items`}
          </div>
        </div>
      )}
      {isCompetentPerson && (
        <>
          <InputFieldGroup
            id="examination"
            name="examinationId"
            type="select"
            label="Thorough Examination Job Reference"
            tooltip="Select the job reference of the thorough examination you are completing. You can ignore this if you do not want to generate a report for this inspection."
            formik={formik}
            options={examinationOptions}
            config={{
              isClearable: true,
            }}
          />
          <InputFieldGroup
            type="unitvalue"
            name="swl"
            label="Safe Working Load"
            formik={formik}
            tooltip="The maximum load the equipment can safely lift. If available, the Working Load Limit metric value of the item is pre-filled but can be edited."
            config={{
              secondaryOptions: [
                {
                  label: 'kN',
                  value: 'kN',
                },
                {
                  label: 'lbf',
                  value: 'lbf',
                },
                {
                  label: 'kg',
                  value: 'kg',
                },
              ],
            }}
          />
          <Divider />
        </>
      )}

      {isChecklistsEnabled && (
        <div className="mt-6">
          <InputFieldGroup
            id="checklist"
            name="checklistId"
            type="select"
            label="Checklist"
            placeholder="None"
            onChange={async (option) => {
              // await used fix a bug with validation after checklist change
              await formik.setFieldValue('criteria', {});
              onChecklistChange(option?.value || null);
              await formik.validateForm();
            }}
            options={checklists?.map((checklist) => ({
              value: checklist.id,
              label: checklist.name,
              description:
                checklist.type !== ChecklistType.EQUIPMENT_OWNER
                  ? checklist.organisation?.name
                  : '',
            }))}
            value={currentChecklistId ?? ''}
            config={{
              isClearable: true,
            }}
          />
          {currentChecklistId && (
            <InspectionChecklistInput
              criteriaValue={formik.values.criteria}
              setCriteria={async (id, result) => {
                // await used fix a bug with validation after checklist change
                await formik.setFieldValue(`criteria.${id}`, result);
                await formik.validateForm();
              }}
              checklistCriteria={selectedChecklistCriteria}
              errors={formik.errors.criteria}
              submitted={formik.submitCount > 0}
            />
          )}
        </div>
      )}
      {/* Checklist Result */}
      {currentChecklistId && (
        <InspectionStatusBadge mode="block" status={inspectionResult} />
      )}

      {!currentChecklistId && (
        <div className="mt-6">
          <InspectionStatusSelector
            onChange={formik.setFieldValue}
            name="status"
            status={formik.values.status}
          />
        </div>
      )}
      <div className="mt-6">
        <InputFieldGroup
          id="images"
          name="images"
          type="image"
          label="Inspection Images"
          formik={formik}
          config={{
            isMulti: true,
            maxFiles: MAX_USER_UPLOAD_FILES - pdfsCount,
            maxFilesReachedMessage,
          }}
        />
      </div>
      <div className="mt-6">
        <InputFieldGroup
          id="pdfs"
          name="pdfs"
          type="pdf"
          label="Inspection PDFs"
          formik={formik}
          config={{
            isMulti: true,
            maxFiles: MAX_USER_UPLOAD_FILES - imagesCount,
            maxFilesReachedMessage,
          }}
        />
      </div>
      <div className="mt-6">
        <InputFieldGroup
          id="notes"
          name="notes"
          type="textarea"
          label="Notes"
          formik={formik}
        />
      </div>
    </SidePanelForm>
  );
}
const WrappedInspectionSidePanel = withSidePanelLoading(InspectionSidePanel);

export function CreateInspectionSidePanel({
  id,
  ids,
  actionType,
  isMultiScanInspection,
  ...props
}: CreateInspectionSidePanelProps) {
  const isAccess = useIsAccess();
  const isCompetent = useIsCompetentPerson();
  const { data, loading, error } = useFindActiveExaminationsQuery({
    skip: !isCompetent || isAccess,
    fetchPolicy: 'cache-and-network',
  });
  const { hideSidePanel } = useSidePanel();
  const { selectedRows } = useTable();

  const [currentChecklist, setCurrentChecklist] =
    useState<InspectionChecklistWithCriteria | null>();

  const singleItemId = id || (ids && ids?.length === 1 ? ids[0] : null);

  const { data: wllData, loading: wllLoading } = useQuery(
    FIND_ITEM_DATA_FIELD,
    {
      variables: {
        data: {
          itemId: Number(singleItemId),
          dataKey: 'wll_metric',
        },
      },
      skip: !singleItemId,
    }
  );
  useEffect(() => {
    if (!isMultiScanInspection) {
      // close inspection panel when selection changes
      if (ids && selectedRows.length !== ids.length) {
        hideSidePanel();
      }
    }
  }, [selectedRows, ids, hideSidePanel, isMultiScanInspection]);

  const examinationOptions = data?.findActiveExaminations
    ? [...data.findActiveExaminations]
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
        .map((examination) => ({
          value: examination.id,
          label: examination.jobReference ?? examination.id,
        }))
    : [];
  const isChecklistsEnabled = useFeatureFlag('checklists');
  const inspectionIds = ids ? ids : ([id] as number[]);

  const [
    getChecklist,
    { loading: selectedChecklistLoading, error: selectedChecklistError },
  ] = useLazyQuery(GET_CHECKLIST, {
    onCompleted: (data) => {
      if (data?.getChecklist) {
        setCurrentChecklist(data.getChecklist);
      }
    },
  });

  const {
    data: checklists,
    loading: loadingChecklists,
    error: checklistError,
  } = useQuery(GET_CHECKLISTS_FOR_SERIALISED_PRODUCT, {
    variables: {
      ids: inspectionIds,
    },
    skip: !isChecklistsEnabled || isAccess,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.getChecklistsForSerialisedProducts) {
        const defaultChecklist = data.getChecklistsForSerialisedProducts.find(
          (checklist) => checklist.default
        );

        if (defaultChecklist) {
          getChecklist({
            variables: {
              id: defaultChecklist.id,
            },
          });
        }
      }
    },
  });

  return (
    <WrappedInspectionSidePanel
      {...props}
      id={id}
      ids={ids}
      isMultiScanInspection={isMultiScanInspection}
      actionType={actionType}
      loading={
        loading || loadingChecklists || selectedChecklistLoading || wllLoading
      }
      error={error || checklistError || selectedChecklistError}
      examinationOptions={examinationOptions}
      isCompetentPerson={isCompetent}
      checklists={checklists?.getChecklistsForSerialisedProducts}
      isChecklistsEnabled={isChecklistsEnabled && !isAccess}
      currentChecklistId={currentChecklist?.id || null}
      selectedChecklistCriteria={currentChecklist?.checklistCriteria}
      swlValue={wllData?.itemDataField?.value ?? null}
      onChecklistChange={(id: string | null) => {
        if (!id) {
          setCurrentChecklist(null);
          return;
        }
        getChecklist({
          variables: {
            id,
          },
        });
      }}
    />
  );
}
