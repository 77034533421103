import { OrganisationUsers } from '@scannable/common';

import { graphql } from '../graphql';

export const DELETE_ORGANISATION_USER = graphql(
  `mutation ${OrganisationUsers.DeleteOrganisationUser}($id: Int!) {
    deleteOrganisationUser(id: $id) {
        id
    }
  }`
);

export const UPDATE_ORGANISATION_USER = graphql(
  `mutation ${OrganisationUsers.UpdateOrganisationUser}($data: UpdateOrganisationUserInput!) {
    updateOrganisationUser(data: $data) {
        id
    }
  }`
);
export const ASSIGN_SERIAL_FORMATS =
  graphql(`mutation ${OrganisationUsers.AssignSerialFormats}($data: AssignSerialFormatsInput!) {
  assignSerialFormats(data: $data) {
    id
  }}`);
