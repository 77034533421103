import * as yup from 'yup';

import { WrappedForm } from '@scannable/frontend/common';
import { Search } from '@scannable/frontend/types';

export interface InventoryItemValues {
  serialNumber: string;
  batchNumber: string;
  dom?: string | null;
  dofu?: string | null;
  productVariationId: number | null;
  confirmDuplicateSerial?: boolean;
  externalId?: string | null;
}

export interface CreateInventoryItemProps {
  productVariation?: Search;
}

export type InventoryItemSidePanelProps = Partial<InventoryItemValues> &
  Partial<CreateInventoryItemProps> &
  Partial<WrappedForm>;

export const validationSchema = yup.object().shape({
  productVariationId: yup
    .number()
    .nullable(true)
    .required('Please select a SKU'),
});
