import Link from 'next/link';
import { useRouter } from 'next/router';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { classNames } from '@scannable/common';

export type BreadCrumbItemType = {
  label: string | undefined;
  route?: string;
};
export interface BreadCrumbsProps {
  breadcrumbs: BreadCrumbItemType[];
}

export function BreadCrumbs({ breadcrumbs }: BreadCrumbsProps) {
  const router = useRouter();
  return (
    <nav className="hidden sm:flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index}>
            <div
              className={classNames('flex', index > 0 ? 'items-center' : '')}
            >
              {index > 0 && (
                <ChevronRightIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              )}
              <Link
                href={breadcrumb.route || router.asPath}
                className="text-sm font-medium text-gray-400 hover:text-gray-200"
              >
                {breadcrumb.label}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default BreadCrumbs;
