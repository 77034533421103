import { useState } from 'react';

import {
  AddressGraphQLType,
  useCurrentAddressQuery,
  useMyAddressesQuery,
} from '@scannable/frontend/common';
import { FormFieldOptionType } from '@scannable/frontend/types';

function formatAddress(address: AddressGraphQLType): FormFieldOptionType {
  const { id, line1, line2, postCode, state, city, country, organisation } =
    address;
  return {
    value: id,
    label: organisation?.name ?? '',
    description: [line1, line2, postCode, city, state, country]
      .filter(Boolean)
      .join(', '),
  };
}
export function useExaminationAddresses() {
  const [customerAddresses, setCustomerAddresses] = useState<
    FormFieldOptionType[]
  >([]);
  const [issuedByAddresses, setIssuedByAddresses] = useState<
    FormFieldOptionType[]
  >([]);

  useCurrentAddressQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.currentAddress) {
        const customerAddress = formatAddress(data.currentAddress);
        setCustomerAddresses([customerAddress]);
      }
    },
  });
  useMyAddressesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.myAddresses) {
        const issuedByAddresses = data.myAddresses.map((address) =>
          formatAddress(address)
        );
        setIssuedByAddresses(issuedByAddresses);
      }
    },
  });
  return {
    customerAddresses,
    issuedByAddresses,
  };
}
