import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { AccountModule, classNames, cn, Role } from '@scannable/common';
import {
  selectMenuGroupState,
  toggleMenuGroupState,
} from '@scannable/frontend/store';

import { Icon, Link } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { Guard } from '../../guards/Guard';
import { useFeatureFlag } from '../../hooks/useFeatureFlag/useFeatureFlag';
import { navigationToShow } from '../../lib/navigation';
import BookDemoButton from '../../molecules/BookDemoButton/BookDemoButton';
import { MenuItem } from '../../molecules/MenuItem/MenuItem';
import NotificationsButton from '../../molecules/NotificationsButton/NotificationsButton';
import { SubscriptionBadge } from '../../molecules/SubscriptionBadge/SubscriptionBadge';
import { LanguageSelect } from '../LanguageSelect/LanguageSelect';
import { MenuGroupTitle } from '../MenuGroupTitle/MenuGroupTitle';
import OrganisationSelector from '../OrganisationSelector/OrganisationSelector';
import UserDropdown from '../UserDropdown/UserDropdown';

interface NavigationProps {
  roles: string[];
}
function Navigation({ roles }: NavigationProps) {
  const { isOnTrialOrAccess } = useAuth();
  const dispatch = useDispatch();
  const menuGroupState = useSelector(selectMenuGroupState);
  const toggle = (key: string) => dispatch(toggleMenuGroupState(key));
  return (
    <nav
      className={cn(
        'flex flex-1 flex-col pt-3 overflow-y-auto px-4',
        roles.includes(AccountModule.Enterprise)
          ? 'max-h-[calc(100vh_-_4rem)]'
          : 'max-h-[calc(100vh_-_8rem)]'
      )}
    >
      <ul className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul className="-mx-2 space-y-1">
            {navigationToShow(roles)
              .filter((item) => !item.group)
              .map((item, i) => (
                <MenuItem
                  {...item}
                  isProFeature={item.isProFeature && isOnTrialOrAccess}
                  key={`${item.name}_${i}`}
                />
              ))}
          </ul>
        </li>
        {roles.includes(Role.SuperAdmin) && (
          <li>
            <MenuGroupTitle
              title="Super Admin"
              group="admin"
              menuItems={navigationToShow(roles).filter(
                (item) => item.group === 'admin'
              )}
              isOpen={
                menuGroupState.filter((item) => item.key === 'admin')[0]?.active
              }
              isOnTrialOrAccess={isOnTrialOrAccess}
              toggleIsOpen={toggle}
            />
          </li>
        )}
        {roles.includes(AccountModule.Enterprise) &&
          !roles.includes(Role.Supplier) &&
          !roles.includes(AccountModule.Access) && (
            <li>
              <MenuGroupTitle
                title="Manufacturers/Resellers"
                group="manufacturer"
                menuItems={navigationToShow(roles).filter(
                  (item) => item.group === 'manufacturer'
                )}
                isOpen={
                  menuGroupState.filter(
                    (item) => item.key === 'manufacturer'
                  )[0]?.active
                }
                isOnTrialOrAccess={isOnTrialOrAccess}
                toggleIsOpen={toggle}
              />
            </li>
          )}
        <li>
          <MenuGroupTitle
            title="Equipment Owners"
            group="owner"
            menuItems={navigationToShow(roles).filter(
              (item) => item.group === 'owner'
            )}
            isOpen={
              menuGroupState.filter((item) => item.key === 'owner')[0]?.active
            }
            toggleIsOpen={toggle}
            isOnTrialOrAccess={isOnTrialOrAccess}
          />
        </li>
        <li>
          <MenuGroupTitle
            title="Groups"
            group="groups"
            menuItems={navigationToShow(roles).filter(
              (item) => item.group === 'groups'
            )}
            isOpen={
              menuGroupState.filter((item) => item.key === 'groups')[0]?.active
            }
            toggleIsOpen={toggle}
            isOnTrialOrAccess={isOnTrialOrAccess}
          />
        </li>
      </ul>
    </nav>
  );
}

export function MainNavigation() {
  const { roles, subscriptionStatus, subscriptionType, isOnTrial, trialEnds } =
    useAuth();

  return (
    <div className="flex flex-1 flex-col border-r border-gray-200 bg-gray-50">
      <div className="border-b border-gray-200 py-2 px-2">
        <OrganisationSelector />
      </div>
      <Guard roles={[Role.Admin]}>
        <SubscriptionBadge
          subscriptionStatus={subscriptionStatus}
          subscriptionType={subscriptionType}
          isOnTrial={isOnTrial}
          trialEnds={trialEnds}
        />
      </Guard>
      <Navigation roles={roles} />
    </div>
  );
}

export function UserNavigation() {
  const { isOnTrialOrAccess, isLoggedIn } = useAuth();
  const isNotificationsEnabled = useFeatureFlag('notifications');
  return (
    <>
      {isOnTrialOrAccess && <BookDemoButton />}
      {isNotificationsEnabled && isLoggedIn && <NotificationsButton />}
      <LanguageSelect />
      <UserDropdown />
    </>
  );
}

export function MobileNavigation() {
  const router = useRouter();
  const { roles, isOnTrialOrAccess } = useAuth();
  return (
    <div className="mt-5 h-0 flex-1 overflow-y-auto">
      <nav className="space-y-1 px-2">
        {navigationToShow(roles).map((item, i) => (
          <Link
            key={`${item.name}_${i}`}
            href={item.href}
            className={classNames(
              router.asPath === item.href
                ? 'bg-gray-100 text-gray-900'
                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
              'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
            )}
          >
            <Icon
              name={item.icon}
              className={classNames(
                router.asPath === item.href
                  ? 'text-gray-900'
                  : 'group-hover:text-gray-900 text-gray-400',
                'mr-3 h-6 w-6 flex-shrink-0'
              )}
              aria-hidden="true"
            />
            {item.name}
            {isOnTrialOrAccess && item.isProFeature && (
              <Icon
                name="pro"
                width={30}
                className={cn(
                  router.asPath === item.href
                    ? 'text-gray-900'
                    : 'group-hover:text-gray-900 text-gray-400',
                  'ml-4'
                )}
              />
            )}
          </Link>
        ))}
      </nav>
    </div>
  );
}

export default MainNavigation;
