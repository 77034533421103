import { useLazyQuery, useQuery } from '@apollo/client';

import {
  CLEAR_PRINTER_QUEUE,
  GET_ALL_PRINTERS,
  GET_USER_PRINTERS,
  UseLazyQueryOptions,
  UseQueryOptions,
} from '@scannable/graphql-queries';

export function useClearPrinterQueueLazyQuery(
  options?: UseLazyQueryOptions<typeof CLEAR_PRINTER_QUEUE>
) {
  return useLazyQuery(CLEAR_PRINTER_QUEUE, options);
}

export function useOrganisationPrintersQuery(
  options?: UseQueryOptions<typeof GET_ALL_PRINTERS>
) {
  const { data } = useQuery(GET_ALL_PRINTERS, options);
  const printers = (data?.printers || []).map((p) => ({
    label: p.name ?? p.id,
    value: p.id,
  }));
  return {
    printers,
  };
}

export function useUserPrinters(
  options?: UseQueryOptions<typeof GET_USER_PRINTERS>
) {
  const { data: userPrintersData } = useQuery(GET_USER_PRINTERS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });
  const userPrinters = (userPrintersData?.teamMemberPrinters || []).map(
    (p) => ({
      label: p.name ?? p.id,
      value: p.id,
    })
  );
  return {
    printers: userPrinters,
    userPrinters: userPrintersData?.teamMemberPrinters,
  };
}
