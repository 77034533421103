import { useEffect } from 'react';
import { WatchQueryFetchPolicy } from '@apollo/client';

import {
  dateFormat,
  ItemAction,
  Role,
  truncateString,
} from '@scannable/common';
import { INVENTORY_SEARCH_FIELDS } from '@scannable/frontend/apollo';
import {
  ActionType,
  downloadUrl,
  expiryFilterOptions,
  heading,
  inspectionOptions,
  NullsOrder,
  SortOrder,
  useGroupFilter,
  useManufacturerFilter,
  useProductGroupFilter,
  useRemoveInventoryItemMutation,
  userTimeZone,
  useTeamMemberFilter,
} from '@scannable/frontend/common';
import { PAGINATED_INVENTORY_QUERY } from '@scannable/graphql-queries';

import { IconType } from '../../atoms';
import {
  useAuth,
  useCurrentOrganisation,
  useIsAccess,
  useRoleGuard,
} from '../../auth';
import { useTable, useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import {
  ConfirmationModal,
  EditItemInspectionFrequencyModal,
  useModal,
} from '../../modals';
import {
  DownloadButton,
  InspectionStatusBadge,
  ThumbnailImage,
} from '../../molecules';
import { LastInspection, UpgradeGuard } from '../../organisms';
import {
  AssignGroupsSidePanel,
  CreateInspectionSidePanel,
  useSidePanel,
} from '../../side-panels';
import { InventoryData } from '../../types/table-data.types';
import {
  TableAction,
  TableRowAction,
  TableRowType,
} from '../../types/table.types';
import { Table } from '../Table/Table';
import { onExpiryFilter } from './ExpiryFilter';
import {
  nextInspectionFilterOptions,
  onNextInspectionFilter,
} from './NextInspectionFilter';

export interface InventoryProps {
  title?: string;
  subtitle?: string;
  skip?: boolean;
  selectable?: boolean;
  reportTitle?: string;
  variables?: Record<string, unknown>[];
  additionalRowActions?: TableRowAction<InventoryData>[];
  rowActionsToHide?: InventoryActionType[];
  fetchPolicy?: WatchQueryFetchPolicy;
  showUpgradeMessage?: boolean;
  tableActions?: TableAction[];
  icon?: IconType;
}

export type InventoryActionType =
  | ItemAction.removeItemFromInventory
  | ItemAction.assignToUser
  | ItemAction.addToKitBag
  | ItemAction.addToClient
  | ItemAction.addToLocation
  | ItemAction.addToVehicle
  | ItemAction.addToStaff
  | ItemAction.addToEquipmentType
  | ItemAction.addUpdateGroups;

export function Inventory({
  skip,
  selectable,
  reportTitle = 'Inventory Inspection Report',
  variables,
  additionalRowActions,
  rowActionsToHide,
  tableActions,
  title,
  subtitle,
  icon,
  showUpgradeMessage = false,
  fetchPolicy = 'cache-and-network',
}: InventoryProps) {
  const { guard } = useAuth();
  const isAccess = useIsAccess();
  const roleGuard = useRoleGuard();
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation();
  const { showSidePanel, showItemSidePanel } = useSidePanel();
  const { setSelectedRows } = useTable();
  const { token } = useAuth();
  const { uuid } = useCurrentOrganisation();
  const { reload } = useTable();

  const {
    options: productGroupOptions,
    interpolatedFilter: productGroupInterpolatedFilter,
  } = useProductGroupFilter();
  const {
    options: manufacturerOptions,
    interpolatedFilter: manufacturerInterpolatedFilter,
  } = useManufacturerFilter();
  const {
    options: teamMemberOptions,
    interpolatedFilter: teamMemberInterpolatedFilter,
  } = useTeamMemberFilter();
  const {
    options: groupOptions,
    interpolatedFilter: groupInterpolatedFilter,
    isLoading: groupsLoading,
  } = useGroupFilter();
  const [removeItemFromInventory] = useRemoveInventoryItemMutation({
    onCompleted: () => {
      reload();
      hideModal();
    },
  });

  useEffect(() => {
    if (selectable) {
      setSelectedRows([]);
    }
    return () => {
      if (selectable) {
        setSelectedRows([]);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UpgradeGuard shouldHide={showUpgradeMessage}>
      <Table<typeof PAGINATED_INVENTORY_QUERY>
        title={title}
        subtitle={subtitle}
        icon={icon}
        fitScreen
        resetFilters={false}
        selectable={selectable}
        tableActions={[
          ...(tableActions || []),
          {
            label: 'Download PDF',
            render: (context) => {
              let where = '';
              if (
                context.where !== null &&
                JSON.stringify(context.where) !== '{}'
              ) {
                where = encodeURIComponent(JSON.stringify(context.where));
              }
              return (
                <DownloadButton
                  token={`${token}`}
                  fileName="inventory"
                  file={`${downloadUrl('inventory')}/${uuid}${
                    where
                      ? `?where=${where}&tmz=${userTimeZone()}&title=${encodeURIComponent(
                          reportTitle
                        )}`
                      : `?tmz=${userTimeZone()}&title=${encodeURIComponent(
                          reportTitle
                        )}`
                  }`}
                >
                  Download PDF
                </DownloadButton>
              );
            },
            color: 'info' as const,
          },
        ]}
        rowActions={[
          {
            label: t('inspect'),
            key: ItemAction.inspect,
            callback: (row) =>
              showSidePanel(
                <CreateInspectionSidePanel
                  id={row.id}
                  uri={row.image?.uri}
                  serialNumber={row?.serialNumber}
                  code={row?.tierOne?.code}
                  name={row?.tierOne?.name}
                  productName={row?.tierOne?.productName}
                  manufacturerName={row?.tierOne?.manufacturerName}
                  hasNfc={Boolean(row?.tierOne?.nfcTag)}
                  actionType={ActionType.SingleItem}
                  isMultiScanInspection={true}
                />
              ),
          },
          {
            label: t('actions.assign_update_groups'),
            display: !isAccess && roleGuard([Role.Admin, Role.Member]),
            key: ItemAction.addUpdateGroups,
            callback: (row) =>
              showSidePanel(
                <AssignGroupsSidePanel
                  id={row.id}
                  uri={row.image?.uri}
                  serialNumber={row?.serialNumber}
                  code={row?.tierOne?.code}
                  name={row?.tierOne?.name}
                  productName={row?.tierOne?.productName}
                  manufacturerName={row?.tierOne?.manufacturerName}
                  hasNfc={Boolean(row?.tierOne?.nfcTag)}
                />,
                {
                  title: heading(t('actions.assign_update_groups')),
                }
              ),
          },
          {
            label: t('actions.set_inspection_frequency'),
            display: !isAccess && roleGuard([Role.Admin, Role.Member]),
            callback: (row) =>
              showModal(
                <EditItemInspectionFrequencyModal
                  title={t('actions.set_inspection_frequency')}
                  serialisedProductId={row.id}
                />
              ),
          },
          {
            label: t('actions.remove_item_from_inventory'),
            type: TableRowType.danger,
            key: ItemAction.removeItemFromInventory,
            display: !isAccess && guard([Role.Admin]),
            callback: (row) =>
              showModal(
                <ConfirmationModal
                  title="Are you sure you want to remove this item from inventory?"
                  buttonLabel="Remove"
                  onConfirm={async () => {
                    await resolveMutation(
                      removeItemFromInventory({
                        variables: {
                          id: row.id,
                        },
                      }),
                      {
                        successMessage: 'Item removed from inventory',
                        failureMessage: 'Failed to remove item from inventory',
                      }
                    );
                  }}
                />
              ),
          },
          ...(additionalRowActions ? additionalRowActions : []),
        ]}
        rowActionsToHide={rowActionsToHide}
        query={PAGINATED_INVENTORY_QUERY}
        columnSettings={[
          {
            label: t('image'),
            accessor: 'id',
            cellRender: (row) => {
              return (
                <div
                  className="h-10 w-10 flex-shrink-0 relative cursor-pointer"
                  onClick={() => {
                    showItemSidePanel({
                      serialisedProductId: row.id,
                      showBanner: false,
                      showOptionsButton: true,
                    });
                  }}
                >
                  <ThumbnailImage
                    src={row.image?.uri || ''}
                    alt={row.tierOne?.name || ''}
                    showNfcIcon={Boolean(row.tierOne?.nfcTag)}
                  />
                </div>
              );
            },
          },
          {
            label: t('serial_batch'),
            accessor: 'tierOne',
            cellRender: (row) => (
              <span
                onClick={() => {
                  showItemSidePanel({
                    serialisedProductId: row.id,
                    showOptionsButton: true,
                    showBanner: false,
                  });
                }}
                className="cursor-pointer"
              >
                <span className="text-blue-700 underline">
                  #
                  {truncateString(row.serialNumber, {
                    limit: 20,
                    spaceFiller: '...',
                  })}
                </span>
                {truncateString(row.tierOne?.batchNumber, {
                  limit: 10,
                  spaceFiller: '...',
                }) && <span> {row.tierOne?.batchNumber}</span>}
              </span>
            ),
          },
          {
            label: t('product_variant'),
            accessor: 'tierOne',
            cellRender: (row) => (
              <div className="overflow-hidden overflow-ellipsis w-24">
                {row.tierOne?.name ?? ''}
              </div>
            ),
          },
          {
            label: t('part_number'),
            accessor: 'tierOne',
            cellRender: (row) => <span>{row.tierOne?.code ?? ''}</span>,
          },
          {
            label: t('manufacturer'),
            accessor: 'tierOne',
            cellRender: (row) => (
              <div className="overflow-hidden overflow-ellipsis w-24">
                {row.tierOne?.manufacturerName ?? ''}
              </div>
            ),
          },
          {
            label: t('result'),
            accessor: 'id',
            cellRender: (row) => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {row.tierOne ? (
                  <InspectionStatusBadge
                    status={row.tierOne?.lastInspection?.status}
                    expiryDate={row.tierOne?.expiryDate}
                  />
                ) : null}
              </>
            ),
          },
          {
            label: t('inspection_by_when'),
            accessor: 'lastInspectionAt',
            canSort: {
              nulls: NullsOrder.last,
              sort: true,
            },
            cellRender: (row) => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {row.tierOne ? (
                  <LastInspection
                    data={row.tierOne}
                    inspectionCount={row.tierOne?.inspectionCount || 0}
                  />
                ) : null}
              </>
            ),
          },
          {
            label: t('next_due'),
            accessor: 'nextInspectionAt',
            canSort: {
              nulls: NullsOrder.last,
              sort: true,
            },
            cellRender: (row) => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {row.tierOne?.nextInspectionAt && (
                  <span data-cy="next-inspection">
                    {dateFormat(row.tierOne.nextInspectionAt)}
                  </span>
                )}
              </>
            ),
          },
          {
            label: t('shorthand_dom'),
            accessor: 'dom',
            canSort: {
              nulls: NullsOrder.last,
              sort: true,
            },
            cellRender: (row) => <span>{dateFormat(row.dom)}</span>,
          },
          {
            label: t('expiry_date'),
            accessor: 'expiryDate',
            canSort: {
              nulls: NullsOrder.last,
              sort: true,
            },
            cellRender: (row) => (
              <span>{dateFormat(row.tierOne?.expiryDate)}</span>
            ),
          },
        ]}
        fetchPolicy={fetchPolicy}
        initialState={{
          paginationEnabled: true,
          useAdvancedPagination: true,
          pageSize: 50,
          orderBy: {
            serialNumber: {
              sort: SortOrder.asc,
            },
          },
          secondaryOrderBy: {
            id: SortOrder.desc,
          },
        }}
        advancedFilters={[
          {
            filterName: 'inventoryProductCategory',
            options: productGroupOptions,
            interpolatedFilter: productGroupInterpolatedFilter,
            isMulti: true,
            label: 'Filter by Product Category',
            placeholder: 'Select product categories',
          },
          {
            filterName: 'inventoryManufacturer',
            display: !isAccess,
            options: manufacturerOptions,
            interpolatedFilter: manufacturerInterpolatedFilter,
            isUrlParamEnabled: false,
            label: 'Filter by Manufacturer',
            placeholder: 'Select manufacturers',
          },
          {
            filterName: 'teamMember',
            display: !isAccess,
            options: teamMemberOptions,
            interpolatedFilter: teamMemberInterpolatedFilter,
            label: 'Filter by Team Member',
            placeholder: 'Select team members',
          },
          {
            filterName: 'inventoryNextInspectionResult',
            display: !isAccess,
            isUrlParamEnabled: true,
            options: nextInspectionFilterOptions,
            onFilterChanged: onNextInspectionFilter,
            label: 'Filter by Next Inspection Due',
            isMulti: false,
          },
          {
            filterName: 'inventoryExpiryResult',
            display: !isAccess,
            isUrlParamEnabled: true,
            options: expiryFilterOptions,
            onFilterChanged: onExpiryFilter,
            label: 'Filter by Expiry Date',
            isMulti: false,
          },
          {
            filterName: 'inventoryLastInspectionResult',
            display: !isAccess,
            label: 'Filter by Last Inspection Result',
            isMulti: false,
            options: inspectionOptions,
            interpolatedFilter: {
              lastInspection: {
                is: {
                  status: {
                    equals: '{VAL}',
                  },
                },
              },
            },
          },
          {
            filterName: 'groupFilter',
            display: !isAccess,
            options: groupOptions,
            interpolatedFilter: groupInterpolatedFilter,
            label: 'Filter by Group',
            placeholder: 'Search groups',
            isLoading: groupsLoading,
            isMulti: true,
          },
        ]}
        searchFields={INVENTORY_SEARCH_FIELDS}
        skip={skip}
        variables={variables}
      />
    </UpgradeGuard>
  );
}

export default Inventory;
