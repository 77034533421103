import { useLazyQuery } from '@apollo/client';

import { useSkuScreenQuery } from '@scannable/frontend/common';
import { PRODUCT_VARIATION_SERIALS } from '@scannable/graphql-queries';

import { Button, Divider } from '../../atoms';
import {
  ProductDataForm,
  ProductVariantForm,
  ProductVariationPartsForm,
  UpdateProductVariationValues,
} from '../../forms';
import { SidePanelLayout } from '../../layouts';
import {
  ProductVariationSerial,
  Tab,
  TabProvider,
  TabsContainer,
} from '../../organisms';

type EditProductSidePanelProps = {
  id: number;
  manufacturerId?: number;
} & UpdateProductVariationValues;

export function EditProductVariationSidePanel({
  id,
  name,
  ean,
  status,
  type,
  productId,
  manufacturerId,
}: EditProductSidePanelProps) {
  const [serialisedProducts, { data: loadedSerials }] = useLazyQuery(
    PRODUCT_VARIATION_SERIALS
  );
  const { data, loading } = useSkuScreenQuery({
    productVariationId: id,
  });
  const dataFields = data?.productVariation?.data?.data
    ? [...data.productVariation.data.data]
    : [];

  const parts = data?.productVariation?.components || [];
  const productVariation = data?.productVariation;

  const serials = loadedSerials?.productVariation?.serialisedProducts;
  const serialsLoaded = Boolean(productVariation && serials);
  return (
    <SidePanelLayout isTabbedLayout>
      <TabProvider defaultTab="sku">
        <TabsContainer isInSidePanel />
        <Tab name="sku" label="SKU">
          <ProductVariantForm
            ean={ean}
            status={status}
            type={type}
            name={name}
            id={id}
            productId={productId}
            manufacturerId={manufacturerId}
          />
        </Tab>
        <Tab name="data" label="Data">
          <ProductDataForm
            productVariationId={id}
            dataFields={dataFields}
            loading={loading}
          />
        </Tab>
        <Tab name="parts" label="Parts">
          <ProductVariationPartsForm productVariationId={id} parts={parts} />
        </Tab>
        <Tab name="serials" label="Load Serials">
          <div className="p-4">
            {productVariation && (
              <>
                <Button
                  label={`Load ${productVariation.info?.code ?? ''} serials`}
                  onClick={() =>
                    serialisedProducts({
                      variables: {
                        id,
                      },
                    })
                  }
                />
                <Divider className="my-4" />
              </>
            )}
            {productVariation &&
              loadedSerials &&
              serials &&
              serials.length > 0 &&
              serials.map((s) => (
                <ProductVariationSerial
                  key={s.id}
                  serialNumber={s.serialNumber}
                  uid={s.uid}
                  uri={productVariation?.image?.uri}
                  name={productVariation.info?.name}
                />
              ))}
            {productVariation &&
              serialsLoaded &&
              serials &&
              serials.length === 0 && <p>No serials found for this SKU</p>}
          </div>
        </Tab>
      </TabProvider>
    </SidePanelLayout>
  );
}

export default EditProductVariationSidePanel;
