import { ProductCategories } from '@scannable/common';

import { graphql } from '../graphql';

export const GET_PRODUCT_CATEGORIES = graphql(`
  query ${ProductCategories.GetProductCategories} {
    productGroups(
        orderBy: { name: asc },
        where: { productGroupCategory: { is: { hidden: { equals: false } } } }
    ) {
        id
        name
    }
  }
`);

export const ALL_ACTIVE_PRODUCT_CATEGORIES = graphql(`
  query ${ProductCategories.AllActiveCategories} {
    productGroups(
      where: { productGroupCategory: { is: { hidden: { equals: false } } } }
    ) {
      id
      uuid
      hidden
      name
    }
  }
`);

export const PRODUCT_CATEGORIES_BY_MANUFACTURER = graphql(`
  query ${ProductCategories.CategroiesByManufacturer}($manufacturerId: Int!) {
    productGroups(
      where: {
      productGroupCategory: { is: { hidden: { equals: false } } }
      products: { some: { manufacturerId: { equals: $manufacturerId } } }
    }
    ) {
      id
      name
    }
  }
`);

export const MANUFACTURER_PRODUCT_GROUPS =
  graphql(`query ${ProductCategories.ManufacturerProductGroups} {
  manufacturerProductGroups {
    id
    name
  }
}`);

export const GET_GROUPS_WITH_CATEGORIES = graphql(
  `query ${ProductCategories.GetGroupsWithCategories}(
      $orderBy: [ProductGroupOrderByWithRelationInput!]
    ) {
      productGroups(orderBy: $orderBy) {
        id
        uuid
        name
        productGroupCategory {
          id
          name
        }
      }
    }
  `
);

export const PRODUCT_CATEGORY_DATA = graphql(`
query ${ProductCategories.ProductGroupMetadata}($id: Int!) {
  productGroup(id: $id) {
    id
    uuid
    hidden
    name
    description
    productGroupCategory {
      id
      name
    }
    productGroupMetadata {
      id
      dataTable
      order
      metadata {
        id
        isNumeric
        hasUnits
        unit
        uuid
        name
        type
      }
    }
  }
}
`);

export const PAGINATED_PRODUCT_CATEGORIES = graphql(`
query ${ProductCategories.PaginatedProductCategories}(
    $where: ProductGroupWhereInput
    $orderBy: [ProductGroupOrderByWithRelationInput!]
    $skip: Int
    $take: Int
  ) {
    paginatedProductGroups(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      nodes {
        id
        uuid
        name
        hidden
        description
        productGroupCategory {
          id
          name
        }
      }
      totalCount
    }
  }
`);

export const PRODUCT_CATEGORIES = graphql(`
  query ${ProductCategories.ProductGroupCategories} {
    productGroupCategories {
      id
      name
    }
  }
`);

export const PAGINATED_PRODUCT_GOUP_CATEGORIES = graphql(`
query ${ProductCategories.PaginatedProductGroupCategories}(
    $where: ProductGroupCategoryWhereInput
    $orderBy: [ProductGroupCategoryOrderByWithRelationInput!]
    $skip: Int
    $take: Int
  ) {
    paginatedProductGroupCategories(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      nodes {
        id
        uuid
        name
        description
        hidden
      }
      totalCount
    }
  }
`);
