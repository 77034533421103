import { Metadata } from '@scannable/common';

import { graphql } from '../graphql';

export const GET_METADATA = graphql(`
  query ${Metadata.GetImportantMetadata} (
    $where: MetadataWhereInput
    $orderBy: [MetadataOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    metadatas(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      name
      isImportant
      key
      type
    }
  }
`);

export const PAGINATED_METADATA = graphql(`
  query ${Metadata.PaginatedMetadata}(
    $where: MetadataWhereInput
    $orderBy: [MetadataOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedMetadata(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        type
        unit
        hasUnits
        isPublic
        availableUnits
        metadataCategoryId
      }
      totalCount
    }
  }
`);

export const PAGINATED_METADATA_CATEGORIES = graphql(`
  query ${Metadata.PaginatedMetadataCategories}(
    $where: MetadataCategoryWhereInput
    $orderBy: [MetadataCategoryOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedMetadataCategories(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
      }
      totalCount
    }
  }
`);

export const GET_METADATA_CATEGORIES = graphql(`
  query ${Metadata.GetMetadataCategories} {
    metadataCategories {
      id
      name
    }
  }
`);

export const ALL_METADATA_CATEGORIES = graphql(`
  query AllMetadataCategories {
    metadataCategories {
      id
      uuid
      name
      metadata {
        id
        uuid
        name
        hasUnits
        isNumeric
        type
        unit
      }
    }
  }
`);
