import { Checklist, Inspection, SerialisedProduct } from '@scannable/common';

import { graphql } from '../graphql';

export const PAGINATED_MANUFACTURER_INSPECTIONS = graphql(`
  query ${SerialisedProduct.SerialisedProductInspections}(
    $where: SerialisedProductWhereInput!
    $orderBy: [SerialisedProductOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedManufacturerInspectedItems(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        info {
          id
          name
          code
          ean
          serialNumber
        }
        image {
          id
          uri
        }
        inspections {
          nameOfInspector
          date
          status
          notes
          s3Key
          manufacturerId
        }
      }
      totalCount
    }
  }
`);

export const FIND_ACTIVE_EXAMINATIONS =
  graphql(`query ${Inspection.FindActiveExaminationsQuery} {
    findActiveExaminations {
      id
      createdAt
      jobReference
      inspectorId
  }
}`);

export const EXAMINATION_JOB =
  graphql(`query ${Inspection.ExaminationJobQuery} ($id: String!) {
    examination(id: $id) {
      id
      jobReference
      inspectionCount
      dateOfIssue
      declaration
      customerAddressId
      issueByAddressId
      inspectorId
      status
    }
}`);

export const EXAMINATIONS = graphql(`
  query ${Inspection.GetExaminations}(
    $where: ExaminationWhereInput
    $orderBy: [ExaminationOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedExaminations(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        createdAt
        inspectorName
        dateOfIssue
        status
        jobReference
      }
      totalCount
    }
  }
`);

export const EXAMINATION_INSPECTIONS = graphql(`
  query ${Inspection.PaginatedExaminationInspections}(
    $orderBy: [InspectionOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: InspectionWhereInput
  ) {
    paginatedInspections(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        createdAt
        status
        serialisedProduct {
          id
          info {
            id
            code
            name
            serialNumber
            batchNumber
            manufacturerName
          }
        }
      }
      totalCount
    }
  }
`);

export const GET_INSPECTION = graphql(`
  query ${Inspection.GetInspection} ($id: Int!) {
    inspection(id: $id) {
      id
      nameOfInspector
      date
      passed
      notes
      images
      pdfs
      type
      isCompetentInspector
      status
      checklistResult {
        checklist {
          name
        }
        criteriaResults {
          result
          checklistCriteria {
            title
            description
            order
          }
        }
      }
    }
  }
`);

export const GET_CHECKLISTS_FOR_SERIALISED_PRODUCT = graphql(`
  query ${Checklist.GetChecklistsForSerialisedProductQuery}($ids: [Int!]!){
    getChecklistsForSerialisedProducts(ids: $ids) {
      id
      name
      productVariationId
      productGroupId
      productId
      default
      type
      organisation {
        id
        name
      }
    }
  }`);

export const GET_CHECKLIST = graphql(`
  query ${Checklist.GetChecklistQuery}($id: ID!) {
    getChecklist(id: $id) {
      id
      name
      productVariationId
      productGroupId
      productId
      default
      published
      checklistCriteria {
        id
        title
        description
        order
      }
    }
  }`);

export const PAGINATED_CHECKLISTS = graphql(`
  query ${Checklist.PaginatedChecklistsQuery}(
    $where: ChecklistWhereInput
    $orderBy: [ChecklistOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedChecklists(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        productVariationId
        productGroupId
        productId
        default
        productVariation {
          id
          name
        }
        productGroup {
          id
          name
        }
      }
      totalCount
    }
  }
  `);
export const GET_INSPECTIONS_BY_SERIALISED_PRODUCT =
  graphql(`query ${Inspection.InspectionsByItem}($where: InspectionWhereInput, $orderBy: [InspectionOrderByWithRelationInput!]) {
    inspections(where: $where, orderBy: $orderBy)  {
    id
    nameOfInspector
    notes
    passed
    status
    images
    pdfs
    date
  }
  }`);
