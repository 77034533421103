import { useMutation, useQuery } from '@apollo/client';

import { GET_INVITE, USER_SIGNUP } from '@scannable/graphql-queries';

export function useSignUp(token?: string) {
  const { data, loading } = useQuery(GET_INVITE, {
    variables: {
      token: token as string,
    },
    skip: !token,
  });

  const [signup] = useMutation(USER_SIGNUP);

  return {
    signup,
    inviteEmail: data?.invite?.email,
    loadingInvite: loading,
  };
}
