import { OrganisationUsers } from '@scannable/common';

import { graphql } from '../graphql';

export const GET_ORGANISATION_USER =
  graphql(`query ${OrganisationUsers.GetOrganisationUser}($id: Int!) {
  organisationUser(id: $id) {
    id
    firstName
    lastName
    role
    competentPerson
  }
}`);
