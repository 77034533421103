import { useCallback, useEffect, useRef, useState } from 'react';
import { FormikProps } from 'formik';

import { Button, Icon } from '../../atoms';
import BarcodeModal from '../../modals/BarcodeModal/BarcodeModal';
import { useModal } from '../../modals/ModalContext/ModalContext';
import {
  InputGroup,
  InputGroupProps,
} from '../../molecules/InputGroup/InputGroup';

export interface CameraInputProps extends InputGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  containerClassName?: string;
  valueField: string;
  value: string;
  identifyingKey: string;
  identifyingValue: number;
}

export function CameraInput({
  formik,
  containerClassName,
  name,
  valueField,
  description,
  identifyingKey,
  identifyingValue,
  label,
  value,
  touched,
  error,
  ...props
}: CameraInputProps) {
  const cameraRef = useRef<HTMLInputElement>(null);
  const { showModal } = useModal();
  const [search, setSearch] = useState<string | null>(null);

  const handleNewValue = useCallback(
    (val: string | number) => {
      const newValue = {} as { [key: string]: string | number };
      // set the value field on the object
      newValue[valueField] = val;
      newValue[identifyingKey] = identifyingValue;
      if (identifyingKey && name) {
        const existingValue = [...(formik.values[name] || [])];
        const valueToUpdate = existingValue.find(
          (obj) => obj.id === Number(identifyingValue)
        );
        const filtered = existingValue.filter(
          (v) => v[identifyingKey] !== identifyingValue
        );
        return [...filtered, { ...valueToUpdate, ...newValue }];
      }
      return [newValue];
    },
    [formik.values, identifyingKey, identifyingValue, name, valueField]
  );
  useEffect(() => {
    if (search && valueField && identifyingKey && name) {
      const newFormState = handleNewValue(search);
      formik.setFieldValue(name, newFormState);
    }
    if (search && !valueField && name) {
      formik.setFieldValue(name, search);
    }
  }, [formik, handleNewValue, identifyingKey, name, search, valueField]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (name) {
      const newFormState = handleNewValue(event.target.value);
      formik.setFieldValue(name, newFormState);
    }
  };
  return (
    <InputGroup
      className={containerClassName}
      name={name}
      description={description}
      label={label}
      touched={touched}
      error={error}
    >
      <div className="relative mr-2 w-full text-gray-600">
        <input
          className="block w-full rounded-md border-gray-300 py-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          name={name}
          ref={cameraRef}
          placeholder="Search"
          onChange={handleOnChange}
          value={value}
          {...props}
        />
        <div className="absolute top-1/2 right-2 -translate-y-1/2 transform cursor-pointer">
          <Button
            color="white"
            type="button"
            size="xs"
            onClick={() => {
              showModal(<BarcodeModal setCameraValue={setSearch} />);
            }}
          >
            <div className="flex flex-row">
              <span>Scan</span>
              <Icon
                name="target"
                className="ml-2 mt-0.5 h-4 w-4 text-gray-800"
              />
            </div>
          </Button>
        </div>
      </div>
    </InputGroup>
  );
}

export default CameraInput;
