export enum GroupTypeMapping {
  KIT_BAG = 'kit_bags',
  LOCATION = 'locations',
  VEHICLE = 'vehicles',
  CLIENT = 'clients',
  STAFF = 'staff',
  EQUIPMENT_TYPE = 'equipment_type',
  CUSTOMER = 'customer',
}

export enum GroupTypeMappingSingle {
  KIT_BAG = 'kit_bag',
  LOCATION = 'location',
  VEHICLE = 'vehicle',
  CLIENT = 'client',
  STAFF = 'staff',
  EQUIPMENT_TYPE = 'equipment_type',
  CUSTOMER = 'customer',
}

export enum AddGroupTypeMapping {
  KIT_BAG = 'add_kit_bag',
  VEHICLE = 'add_vehicle',
  LOCATION = 'add_location',
  CLIENT = 'add_client',
  STAFF = 'add_staff',
  EQUIPMENT_TYPE = 'add_equipment_type',
}

export enum NewGroupTypeMapping {
  KIT_BAG = 'new_kit_bag',
  VEHICLE = 'new_vehicle',
  LOCATION = 'new_location',
  CLIENT = 'new_client',
  STAFF = 'new_staff',
  EQUIPMENT_TYPE = 'new_equipment_type',
}

export enum GroupTypeIconMapping {
  KIT_BAG = 'kitBag',
  LOCATION = 'locations',
  VEHICLE = 'vehicles',
  CLIENT = 'clients',
  STAFF = 'staff',
  EQUIPMENT_TYPE = 'equipmentType',
  CUSTOMER = 'customer',
}

export enum GroupTypePathMapping {
  KIT_BAG = 'kit-bags',
  LOCATION = 'locations',
  VEHICLE = 'vehicles',
  CLIENT = 'clients',
  STAFF = 'staff',
  EQUIPMENT_TYPE = 'equipment-type',
  CUSTOMER = 'customer',
}
