import { AllHTMLAttributes } from 'react';

import { classNames } from '@scannable/common';

export function TableHead({
  children,
  className,
  ...rest
}: AllHTMLAttributes<HTMLTableSectionElement>) {
  return (
    <thead className={classNames('bg-gray-100', className || '')} {...rest}>
      {children}
    </thead>
  );
}

export default TableHead;
