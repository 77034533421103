import { useMemo } from 'react';

import { ActionsButton } from '../../organisms';
import { TableRowAction } from '../../types/table.types';
import TableCell from '../TableCell/TableCell';

export interface TableRowActionsProps<T> {
  rowActions: TableRowAction<T>[];
  item: T;
}

export function TableRowActions<T>({
  rowActions,
  item,
}: TableRowActionsProps<T>) {
  const actions = useMemo(
    () =>
      rowActions.map((action) => ({
        ...action,
        item,
      })),
    [item, rowActions]
  );

  return (
    <TableCell className="sticky right-0 px-4  w-12">
      <ActionsButton<T> actions={actions} />
    </TableCell>
  );
}

export default TableRowActions;
