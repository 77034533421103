import {
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
} from '@apollo/client';
import {
  initGraphQLTada,
  ResultOf,
  TadaDocumentNode,
  VariablesOf,
} from 'gql.tada';

import type { introspection } from '../graphql-env.d.ts';

export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    DateTime: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JSON: any;
    ID: string;
    Upload: File | string;
  };
}>();

export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada';
export { readFragment } from 'gql.tada';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UseMutationOptions<T extends TadaDocumentNode<any, any>> =
  MutationHookOptions<ResultOf<T>, VariablesOf<T>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UseQueryOptions<T extends TadaDocumentNode<any, any>> =
  QueryHookOptions<ResultOf<T>, VariablesOf<T>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UseLazyQueryOptions<T extends TadaDocumentNode<any, any>> =
  LazyQueryHookOptions<ResultOf<T>, VariablesOf<T>>;
