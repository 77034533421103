import { configureStore } from '@reduxjs/toolkit';

import {
  APPLICATION_STATUS_FEATURE_KEY,
  applicationStatusReducer,
} from './application-status/application-status.slice';
import inspectionReducer, {
  INSPECTION_FEATURE_KEY,
} from './inspection/inspection.slice';
import {
  MULTI_SCAN_FEATURE_KEY,
  multiScanReducer,
} from './multi-scan/multi-scan.slice';
import {
  NAVIGATION_FEATURE_KEY,
  navigationReducer,
} from './navigation/navigation.slice';
import serialisationReducer, {
  SERIALISATION_FEATURE_KEY,
} from './serialisation/serialisation.slice';
import { TABLES_FEATURE_KEY, tablesReducer } from './tables/tables.slice';

export const store = configureStore({
  devTools: true,
  reducer: {
    [SERIALISATION_FEATURE_KEY]: serialisationReducer,
    [INSPECTION_FEATURE_KEY]: inspectionReducer,
    [TABLES_FEATURE_KEY]: tablesReducer,
    [NAVIGATION_FEATURE_KEY]: navigationReducer,
    [MULTI_SCAN_FEATURE_KEY]: multiScanReducer,
    [APPLICATION_STATUS_FEATURE_KEY]: applicationStatusReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['tables/setHasError', 'tables/setLoading'],
        ignoredPaths: ['tables.error'],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
