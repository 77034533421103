import { useEffect, useMemo } from 'react';
import { useQuery, WatchQueryOptions } from '@apollo/client';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { isObject } from '@sniptt/guards';
import { TadaDocumentNode, VariablesOf } from 'gql.tada';

import { cn } from '@scannable/common';
import { FirstNode } from '@scannable/frontend/common';

import Icon, { IconType } from '../../atoms/Icon/Icon';
import { useTable } from '../../hooks/useTable/useTable';
import { Pagination as BasicPagination } from '../../molecules';
import {
  BasicFilter,
  RowActionsType,
  TableAction,
  TableConfiguration,
  TableFilter,
  TableRowAction,
  TableSettingsProps,
} from '../../types/table.types';
import Pagination from '../Pagination/Pagination';
import SearchBox from '../SearchBox/SearchBox';
import TableActions from '../TableActions/TableActions';
import { TableBody } from '../TableBody/TableBody';
import { TableFilters } from '../TableFilters/TableFilters';
import { TableHead } from '../TableHead/TableHead';
import { TableHeadCell } from '../TableHeadCell/TableHeadCell';
import { TableHeadRow } from '../TableHeadRow/TableHeadRow';
import { TableTable } from '../TableTable/TableTable';
import {
  useOrderBy,
  usePagination,
  useSelectAllCheckboxState,
  useTableConfiguration,
  useTableSearch,
  useWhereClause,
} from './hooks';

type FitScreenOptions = {
  withTabs?: boolean;
};
export interface TableProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends TadaDocumentNode<any, any>
> extends TableSettingsProps<FirstNode<T>> {
  testId?: string;
  title?: string;
  subtitle?: string;
  icon?: IconType;
  tableActions?: TableAction[];
  initialState?: Partial<TableConfiguration>;
  query: TadaDocumentNode;
  selectable?: boolean;
  variables?: VariablesOf<T>['where'][];
  fetchPolicy?: WatchQueryOptions['fetchPolicy'];
  rowActions?: TableRowAction<FirstNode<T>>[];
  searchFields?: {
    field: string;
    label?: string;
    hideLabel?: boolean;
    type?: 'search' | 'contains';
    relation?: 'one' | 'many';
    defaultValue?: string;
  }[];
  skip?: boolean;
  pollInterval?: number;
  rowActionsToHide?: RowActionsType[];
  filters?: BasicFilter[];
  showUpgradeMessage?: boolean;
  resetFilters?: boolean;
  fitScreen?: boolean | FitScreenOptions;
  uniqueTableKey?: string;
  advancedFilters?: TableFilter[];
}

export function Table<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends TadaDocumentNode<any, any>
>({
  testId,
  title,
  icon,
  subtitle,
  tableActions,
  columnSettings,
  rowSettings,
  query,
  selectable,
  variables,
  fetchPolicy,
  searchFields,
  skip,
  initialState,
  pollInterval,
  rowActions,
  rowActionsToHide,
  resetFilters,
  filters,
  fitScreen,
  advancedFilters,
  uniqueTableKey = 'default',
}: TableProps<T>) {
  type Result = FirstNode<T>;

  const {
    reloadSelect,
    setHasError,
    selectedRows,
    setSelectedRows,
    resetTableFilters,
  } = useTable();

  useEffect(() => {
    const reset = () => {
      if (resetFilters) {
        resetTableFilters();
      }
    };
    return reset;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usesSearch = useMemo(
    () => searchFields && searchFields.length > 0,
    [searchFields]
  );
  const { tableConfiguration } = useTableConfiguration(initialState);
  const { next, prev, goToPage, offset, totalCount, setTotalCount } =
    usePagination(uniqueTableKey, tableConfiguration.pageSize);

  const {
    searchTerm,
    handleRemoveSearchTerm,
    searchWhereClause,
    handleSearch,
  } = useTableSearch<T>(searchFields, uniqueTableKey);
  const { activeSort, orderBy, setOrder } = useOrderBy<T>({
    initialOrderBy: initialState?.orderBy,
    secondaryOrderBy: initialState?.secondaryOrderBy,
  });

  const where = useWhereClause(
    variables,
    searchWhereClause,
    uniqueTableKey,
    (filters ?? advancedFilters ?? []).map((f) => f.filterName)
  );

  const queryVariables = useMemo(() => {
    return {
      where,
      orderBy,
      take: tableConfiguration.pageSize,
      skip: offset,
    };
  }, [where, orderBy, tableConfiguration.pageSize, offset]);

  const { data, loading, error, refetch } = useQuery(query, {
    variables: queryVariables,
    pollInterval,
    fetchPolicy,
    skip,
    onCompleted: (responseData) => {
      const key = Object.keys(responseData || {})[0];
      const newTotalCount = responseData?.[key]?.totalCount || 0;
      if (newTotalCount && newTotalCount !== totalCount) {
        setTotalCount(newTotalCount);
      }
    },
  });

  useEffect(() => {
    if (error) {
      setHasError(error);
    }
  }, [setHasError, error]);

  useEffect(() => {
    if (reloadSelect > 0) {
      refetch();
    }
  }, [refetch, reloadSelect]);

  const dataAccessKey = Object.keys(data || {})[0];
  const tableData = useMemo<Result[] | null>(() => {
    if (!data) {
      return null;
    }
    return tableConfiguration.useAdvancedPagination
      ? data[dataAccessKey]['nodes']
      : data[dataAccessKey];
  }, [data, dataAccessKey, tableConfiguration.useAdvancedPagination]);

  const { checkbox, checked, toggleAll } = useSelectAllCheckboxState(
    tableData?.map((d) => d.id) ?? [],
    selectedRows,
    setSelectedRows
  );

  // filter out any row actions from hide actions props
  const rowActionsToRender = useMemo(() => {
    if (!rowActions) {
      return null;
    }
    if (!rowActionsToHide) {
      return rowActions;
    }
    return rowActions.filter((r) => {
      if (!r.key) {
        return true;
      }
      return !rowActionsToHide.includes(r.key);
    });
  }, [rowActions, rowActionsToHide]);

  const maxPagination =
    (data &&
      tableConfiguration.pageSize &&
      data[dataAccessKey]?.length < tableConfiguration.pageSize) ||
    false;

  let fitScreenClasses = '';
  if (fitScreen) {
    const hasSelectedRows = selectedRows.length > 0;
    if (fitScreen === true && !hasSelectedRows) {
      fitScreenClasses =
        'max-h-[calc(100vh_-_26rem)] sm:max-h-[calc(100vh_-_310px)]';
    }
    if (fitScreen === true && hasSelectedRows) {
      fitScreenClasses =
        'max-h-[calc(100vh_-_30rem)] sm:max-h-[calc(100vh_-_375px)]';
    }
    if (isObject(fitScreen) && fitScreen.withTabs) {
      fitScreenClasses =
        'max-h-[calc(100vh_-_26rem)] sm:max-h-[calc(100vh_-_453px)]';
    }
  }

  return (
    <div className="flex flex-col" data-cy={testId}>
      <div className="bg-white rounded-t-xl h-2" />
      <div className="bg-white">
        <div className="p-4 pb-0 border-b border-gray-200">
          <div className="pb-2 sm:mb-4 sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <div className="flex flex-row items-center">
                <div className="flex justify-center items-center bg-gray-200 rounded-lg h-10 w-10 ">
                  <Icon name={icon ?? 'specs'} className="h-6 w-6" />
                </div>
                <div className="ml-4">
                  <h1
                    className={cn(
                      'leading-6 text-gray-900 text-sm font-medium'
                    )}
                  >
                    {title}
                  </h1>
                  {subtitle && (
                    <p className="text-sm leading-5 text-gray-500">
                      {subtitle}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <TableActions
                tableActions={tableActions}
                context={{ sort: orderBy, where: where }}
              />
            </div>
          </div>
        </div>
        {advancedFilters && (
          <div className="p-2">
            <div className="mb-2 sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <TableFilters
                  filters={advancedFilters}
                  tableName={uniqueTableKey}
                />
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none  sm:w-1/3 w-full">
                {usesSearch && searchFields && (
                  <SearchBox
                    searchTerm={searchTerm}
                    searchFields={searchFields
                      .filter((l) => !l.hideLabel)
                      .map((f) => f.label || f.field)}
                    clearSearch={handleRemoveSearchTerm}
                    handleSearch={handleSearch}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {!advancedFilters && (filters || (usesSearch && searchFields)) && (
          <div className="p-4 pb-2">
            <div className="mb-2 sm:flex sm:items-center">
              <div className="w-full flex space-x-2">
                {filters &&
                  filters.length > 0 &&
                  filters.map((f) =>
                    f.render({
                      key: f.filterName,
                      tableName: uniqueTableKey,
                      filterName: f.filterName,
                    })
                  )}
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none w-1/3">
                {usesSearch && searchFields && (
                  <SearchBox
                    searchTerm={searchTerm}
                    searchFields={searchFields
                      .filter((l) => !l.hideLabel)
                      .map((f) => f.label || f.field)}
                    clearSearch={handleRemoveSearchTerm}
                    handleSearch={handleSearch}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div
          className={cn(
            'overflow-x-auto border-b border-gray-200 print:border-0 print:shadow-none',
            fitScreenClasses
          )}
        >
          <TableTable>
            <TableHead className="sticky top-0 z-10">
              <TableHeadRow>
                {selectable && (
                  <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                    <input
                      id="TableSelectAll"
                      type="checkbox"
                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      ref={checkbox}
                      checked={checked}
                      onChange={toggleAll}
                      data-cy="select-all"
                    />
                  </th>
                )}
                {columnSettings.map((col, colNum) => {
                  if (col.canSort && activeSort) {
                    const currentSort = activeSort[col.accessor as string];
                    const isNullableSort = typeof currentSort === 'object';
                    return (
                      <TableHeadCell
                        key={`tablehead-sortable-${
                          col.accessor as string
                        }-${colNum}`}
                        className={cn(
                          !selectable && colNum === 0 ? 'px-4' : ''
                        )}
                      >
                        <span
                          onClick={() => {
                            setOrder(col);
                          }}
                          className="group inline-flex cursor-pointer truncate"
                        >
                          {col.label}
                          <span className="ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                            {currentSort === 'asc' ||
                            (isNullableSort && currentSort.sort === 'asc') ? (
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </span>
                      </TableHeadCell>
                    );
                  }
                  return (
                    <TableHeadCell
                      key={`tablehead-${col.accessor as string}-${colNum}`}
                      className={cn(!selectable && colNum === 0 ? 'px-4' : '')}
                    >
                      {col.label}
                    </TableHeadCell>
                  );
                })}
                {rowActionsToRender && <TableHeadCell />}
              </TableHeadRow>
            </TableHead>
            <TableBody<Result>
              selectable={selectable}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              error={error}
              tableData={tableData}
              rowSettings={rowSettings}
              columnSettings={columnSettings}
              next={next}
              prev={prev}
              goToPage={goToPage}
              maxPagination={maxPagination}
              hasPagination={tableConfiguration.paginationEnabled}
              useAdvancedPagination={tableConfiguration.useAdvancedPagination}
              paginationData={{
                totalCount,
                offset,
                defaultPageSize: tableConfiguration.pageSize,
              }}
              rowActions={rowActionsToRender}
            />
          </TableTable>
        </div>

        {tableConfiguration.paginationEnabled && (
          <div>
            {tableConfiguration.useAdvancedPagination && (
              <Pagination
                next={next}
                prev={prev}
                goToPage={goToPage}
                totalCount={totalCount}
                offset={offset}
                pageCount={tableData?.length || 0}
                defaultPageSize={tableConfiguration.pageSize}
              />
            )}
            {!tableConfiguration.useAdvancedPagination && (
              <BasicPagination
                next={next}
                prev={prev}
                maxPagination={maxPagination}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
