import { ReactNode } from 'react';

import { ItemAction } from '@scannable/common';
import { NullsOrder, SortOrder } from '@scannable/frontend/common';
import { Filter, FilterValue } from '@scannable/frontend/store';

import { ButtonProps } from '../atoms';

export type OrderBy = keyof typeof SortOrder;
export type NullsOrderBy = keyof typeof NullsOrder;
export type NullableOrderBy = {
  nulls?: NullsOrderBy;
  sort: OrderBy;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableActionContext<T = any> = {
  sort: TableOrderBy[] | undefined;
  where: T;
};

export interface TableOrderBy {
  [key: string]: OrderBy | NullableOrderBy;
}
export type CellContents = string | number;
export enum TableRowType {
  normal = 'normal',
  danger = 'danger',
}
export interface TableRowAction<T> {
  label: string;
  type?: TableRowType.danger | TableRowType.normal;
  key?: keyof typeof ItemAction; // user for hiding actions in certain scenarios
  display?: boolean;
  callback: (row: T) => void;
  filter?: (row: T) => boolean;
}
export interface TableAction {
  label?: string;
  display?: boolean;
  dataCy?: string;
  callback?: (row: TableActionContext) => void;
  filter?: (row: TableActionContext) => boolean;
  color?: ButtonProps['color'];
  render?: (row: TableActionContext) => React.ReactNode;
}
export interface TableColumn<T> {
  label: string;
  accessor: keyof T;
  canSort?: boolean | { nulls?: NullsOrderBy; sort: boolean };
  sortBy?: (o: OrderBy, set: (o: OrderBy) => void) => void;
  cellRender?: (row: T, rowNum: number) => React.ReactNode;
}
export interface TableConfiguration {
  paginationEnabled: boolean;
  useAdvancedPagination?: boolean;
  pageSize: number;
  orderBy: TableOrderBy;
  secondaryOrderBy?: TableOrderBy;
}
export interface TableSettingsProps<T> {
  columnSettings: TableColumn<T>[];
  rowSettings?: {
    rowRender?: (r: T, i: number, a: TableRowAction<T>[] | null) => ReactNode;
  };
}

export type TableFilterRenderProps = {
  tableName: string;
  filterName: string;
  key: string;
};

export type RowActionsType = keyof typeof ItemAction;

export type BasicFilter = {
  filterName: string;
  render: (props: TableFilterRenderProps) => React.ReactNode;
};
export type TableFilter = {
  filterName: string;
  display?: boolean;
  isUrlParamEnabled?: boolean;
  options:
    | { label: string; value: FilterValue }[]
    | ((inputValue: string) => Promise<unknown>);
  onFilterChanged?: (values: FilterValue | FilterValue[]) => Filter;
  interpolatedFilter?: Filter;
  isMulti?: boolean;
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
};
