import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Teams } from '@scannable/common';
import { useCreateTeamMutation } from '@scannable/frontend/common';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export interface CreateTeamModalProps {
  title: string;
}
interface CreateTeamValues {
  name: string;
}
const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter a name'),
});

export function CreateTeamModal({ title }: CreateTeamModalProps) {
  const { hideModal } = useModal();
  const [createTeam] = useCreateTeamMutation({
    refetchQueries: [Teams.GetTeams],
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    } as CreateTeamValues,
    validationSchema,
    onSubmit: async (
      values: CreateTeamValues,
      actions: FormikHelpers<CreateTeamValues>
    ) => {
      const result = await resolveMutation(
        createTeam({
          variables: {
            data: {
              name: values.name,
            },
          },
        }),
        {
          action: 'CREATE',
          model: 'team',
          successMessage: 'Created team successfully',
        }
      );
      actions.setSubmitting(false);
      if (result.ok) {
        hideModal();
      }
    },
  });
  return (
    <Modal title={title}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: 'Create',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
            'data-cy': 'save-team',
          },
        ]}
      >
        <InputFieldGroup
          data-cy="name"
          id="Name"
          name="name"
          type="text"
          formik={formik}
          label="Name"
        />
      </ModalForm>
    </Modal>
  );
}

export default CreateTeamModal;
