import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { imageFromS3Key, ProductVariation } from '@scannable/common';
import { filterByOr, ProductType, SkuParts } from '@scannable/frontend/common';
import { Search } from '@scannable/frontend/types';
import {
  ADD_COMPOSITE_CHILD_PRODUCT_VARIATION,
  REMOVE_COMPOSITE_CHILD_PRODUCT_VARIATION,
} from '@scannable/graphql-queries';

import { Button, Image, Text, Title } from '../../atoms';
import { resolveMutation } from '../../lib/lib';
import { CheckBoxInput, InputGroup } from '../../molecules';
import { SearchInput } from '../../organisms';

type ProductVariationPartsFormProps = {
  productVariationId: number;
  parts?: SkuParts;
};
export function ProductVariationPartsForm({
  productVariationId,
  parts,
}: ProductVariationPartsFormProps) {
  const [showComposites, setShowComposites] = useState(false);
  const [addChildProductVariation] = useMutation(
    ADD_COMPOSITE_CHILD_PRODUCT_VARIATION,
    {
      refetchQueries: [ProductVariation.SKUScreenData],
    }
  );
  const [removeChildProductVariation] = useMutation(
    REMOVE_COMPOSITE_CHILD_PRODUCT_VARIATION,
    {
      refetchQueries: [ProductVariation.SKUScreenData],
    }
  );
  const removeFromVariations = async (variationId: number, id: number) => {
    await resolveMutation(
      removeChildProductVariation({
        variables: {
          data: {
            id: variationId,
            childProductVariationId: id,
          },
        },
      }),
      {
        successMessage: 'Removed component',
      }
    );
  };
  const addToVariations = async (variation: Search) => {
    await resolveMutation(
      addChildProductVariation({
        variables: {
          data: {
            id: productVariationId,
            childProductVariationId: Number(variation.id),
          },
        },
      }),
      {
        successMessage: 'Successfully added component',
      }
    );
  };
  const productTypesToShow = [
    ProductType.COMPONENT,
    ProductType.COMPOSITE,
  ].filter((t) => (showComposites ? t : t !== ProductType.COMPOSITE));
  return (
    <div className="px-6 py-5">
      <div className="grid grid-cols-1 gap-x-2">
        {parts &&
          parts.map((part, i) => (
            <div
              key={`${part.id}_${i}`}
              className="relative rounded-lg border border-gray-300 bg-white px-4 py-4 mb-4 shadow-sm hover:border-gray-400"
            >
              <Title size="h4">{part.info?.productName}</Title>
              {part.info?.manufacturerLogo && (
                <div className="float-right">
                  <Image
                    src={imageFromS3Key(part.info?.manufacturerLogo, 'small')}
                    alt={part.info?.manufacturerName ?? ''}
                    style={{
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                    height={50}
                    width={50}
                    className="w-10 h-10 rounded-full"
                  />
                </div>
              )}
              <Text>Manufacturer: {part.info?.manufacturerName ?? ''}</Text>
              <Text>Product code: {part.info?.code ?? ''}</Text>
              <div className="flex mt-4 justify-end">
                <Button
                  type="button"
                  size="xs"
                  color="danger"
                  onClick={() =>
                    removeFromVariations(productVariationId, Number(part.id))
                  }
                >
                  Remove
                </Button>
              </div>
            </div>
          ))}
      </div>
      <InputGroup
        label="Include other cut length of ropes in search?"
        className="mb-4"
        htmlFor="ShowComposites"
      >
        <CheckBoxInput
          name="showComposites"
          id="ShowComposites"
          onChange={() => setShowComposites(!showComposites)}
          checked={showComposites}
        />
      </InputGroup>
      <SearchInput
        name="productVariationId"
        addToCallback={addToVariations}
        resetAfterCallback
        filters={[
          {
            key: 'productType',
            value: filterByOr('productType', productTypesToShow),
            custom: true,
          },
        ]}
      />
    </div>
  );
}

export default ProductVariationPartsForm;
