import { Group, InspectionStatus, ItemStatus } from '@prisma/client';

import { TranslateFn } from '@scannable/frontend/translations';
import { PAGINATED_ITEM_HISTORY } from '@scannable/graphql-queries';

import { UseItemScreenData } from '../../hooks/serialised-product/queries';
import { UseSkuOrItemData } from '../../hooks/useSkuOrItemScreenQuery/useSkuOrItemScreenQuery';
import { FirstNode } from '../../types/graphql.types';
import { makeComponentTabData } from './makeComponentTabData';
import { makeItemScreenData, ParsedStatus } from './makeItemScreenData';
import { ViewProductInfoItem, ViewProductLastInspection } from './types';

export interface ViewProductComponent {
  serialisedProductId?: number;
  productVariationId?: number;
  name?: string | null;
  category?: string | null;
  image?: string | null;
  uid?: string | null;
  hasNfc?: boolean;
  inspectionStatus?: InspectionStatus;
  serialNumber?: string | null;
  manufacturer?: string | null;
  code?: string | null;
}
type ItemScreenData = NonNullable<UseItemScreenData>;

export type ViewProductHistoryItem = FirstNode<typeof PAGINATED_ITEM_HISTORY>;

export type ItemData = NonNullable<
  NonNullable<ItemScreenData['data']>['data']
>[0];

export type ViewProductSpecificationPdf = ItemData['pdfs'];

export type ViewProductSpecificationCertification = ItemData['certifications'];

export type ViewProductSpecificationVideo = {
  url: string;
  isYoutube: boolean;
};

export type ViewProductSpecificationWebLink = {
  url: string;
};

export interface ViewProductSpecificationItem {
  name: string;
  value?: string;
  pdfs?: ViewProductSpecificationPdf;
  certifications?: ViewProductSpecificationCertification;
  video?: ViewProductSpecificationVideo;
  webLink?: ViewProductSpecificationWebLink;
}

export type ProductScreenData = {
  hasCertification?: boolean;
  hasComponents?: boolean;
  components?: ViewProductComponent[];
  hasNfc?: boolean;
  nfcTag?: string | null;
  productData?: ItemData[] | null;
  showPrivateData?: boolean;
  lastInspection?: ViewProductLastInspection;
  nextInspectionAt?: string | null;
  name?: string | null;
  serialNumber?: string | null;
  batchNumber?: string | null;
  history?: ViewProductHistoryItem[];
  groups?: Group[];
  expiryDate?: string | null;
  dom?: string | null;
  dofu?: string | null;
  isExpired?: boolean;
  parsedStatus?: ParsedStatus | null;
  proofOfPurchase?: string | null;
  assignedUser?: string | null;
  ownedByName?: string | null;
  status?: ItemStatus | null;
  itemInInventory?: boolean;
  aliases?: string[];
  specificationItems?: ViewProductSpecificationItem[];
  infoItems?: ViewProductInfoItem[];
  externalId?: string | null;
  nfcTags?: string[] | null | undefined;
};

export interface MakeProductScreenArgs {
  data?: UseSkuOrItemData | null;
  organisationId: number | null;
  t: TranslateFn;
}

export const makeProductScreenData = ({
  data,
  organisationId,
  t,
}: MakeProductScreenArgs): ProductScreenData => {
  if (!data) {
    return {};
  }

  const hasCertification = Boolean(
    data?.data?.certifications && data?.data?.certifications.length > 0
  );
  const productData = data?.data?.data as ItemData[];
  const hasComponents = Boolean(data?.components?.length);
  const components = makeComponentTabData(data);

  const itemData = makeItemScreenData({
    item: data,
    organisationId,
    t,
  });

  return {
    hasCertification,
    hasNfc: false,
    showPrivateData: false,
    name: data?.info?.name ?? '',
    isExpired: false,
    productData,
    hasComponents,
    components,
    ...itemData,
  };
};
