import { UseSkuOrItemData } from '../../hooks/useSkuOrItemScreenQuery/useSkuOrItemScreenQuery';
import { ViewProductComponent } from './makeProductScreenData';
import { isItem } from './types';

export const makeComponentTabData = (
  data: UseSkuOrItemData
): ViewProductComponent[] => {
  if (!data || !data.components || data.components.length === 0) {
    return [];
  }

  if (isItem(data)) {
    return data.components?.map((c) => ({
      serialisedProductId: c.id,
      name: c.info?.name,
      category: c.info?.productGroupName,
      image: c.image?.uri,
      uid: c.uid,
      hasNfc: c.info?.nfcTags ? c.info.nfcTags.length > 0 : false,
      inspectionStatus: c.info?.lastInspection?.status,
      serialNumber: c?.serialNumber,
      manufacturer: c.info?.manufacturerName,
    }));
  }

  return data?.components?.map((c) => ({
    productVariationId: c.id,
    name: c.info?.name,
    category: c.info?.productGroupName,
    image: c.image?.uri,
    manufacturer: c.info?.manufacturerName,
  }));
};
