import { createElement, forwardRef } from 'react';

import { classNames } from '@scannable/common';

import LoadingIcon from '../LoadingIcon/LoadingIcon';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?:
    | 'primary'
    | 'secondary'
    | 'lime'
    | 'success'
    | 'danger'
    | 'warning'
    | 'disabled'
    | 'white'
    | 'info'
    | 'black'
    | 'link'
    | 'purple'
    | 'blue';
  as?: 'button' | 'a';
  loading?: boolean;
  disabled?: boolean;
}

function ButtonComponent(
  {
    label,
    children,
    className,
    color = 'primary',
    size = 'md',
    type = 'submit',
    as = 'button',
    loading = false,
    disabled = false,
    ...props
  }: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const baseClasses = 'relative justify-center';

  let colorClasses =
    'bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
  switch (color) {
    case 'primary':
      colorClasses =
        'text-slate-900 bg-brand-primary border-lime-light border hover:bg-lime-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 shadow-sm';
      break;
    case 'secondary':
      colorClasses =
        'text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
      break;
    case 'lime':
      colorClasses =
        'text-slate-900 bg-brand-primary border-lime-light border hover:bg-lime-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 shadow-sm';
      break;
    case 'success':
      colorClasses =
        'text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500';
      break;
    case 'danger':
      colorClasses =
        'text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500';
      break;
    case 'warning':
      colorClasses =
        'text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500';
      break;
    case 'disabled':
      colorClasses =
        'text-white bg-gray-600 hover:bg-gray-200 focus:outline-none cursor-not-allowed';
      break;
    case 'white':
      colorClasses =
        'text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500';
      break;
    case 'info':
      colorClasses =
        'text-gray-600 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 border border-gray-300';
      break;
    case 'black':
      colorClasses =
        'text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
      break;
    case 'link':
      colorClasses = 'text-indigo-600 hover:text-indigo-900';
      break;
    case 'purple':
      colorClasses =
        'text-white bg-brand-purple hover:bg-brand-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-purple';
      break;
    case 'blue':
      colorClasses =
        'text-blue-800 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 border border-blue-400';
      break;
    default:
      break;
  }

  let classes =
    'inline-flex items-center px-4 py-2 text-base font-medium rounded-md';
  switch (size) {
    case 'xs':
      classes =
        'inline-flex items-center px-2.5 py-1 text-xs font-medium rounded';
      break;
    case 'sm':
      classes =
        'inline-flex items-center px-3 py-2 text-sm leading-4 font-medium rounded-md';
      break;
    case 'md':
      classes =
        'inline-flex items-center px-4 py-2 text-sm font-medium rounded-md';
      break;
    case 'lg':
      classes =
        'inline-flex items-center px-4 py-2 text-base font-medium rounded-md';
      break;
    case 'xl':
      classes =
        'inline-flex items-center px-6 py-3 text-base font-medium rounded-md';
      break;
    default:
      break;
  }
  const cursorClasses = !disabled ? 'cursor-pointer' : 'cursor-not-allowed';

  return createElement(
    as,
    {
      className: classNames(
        baseClasses,
        classes,
        colorClasses,
        cursorClasses,
        className || ''
      ),
      ref,
      type,
      disabled: loading || disabled,
      ...props,
    },
    <>
      <span
        className={classNames(loading ? 'invisible' : '', 'flex items-center')}
      >
        {label || children}
      </span>
      {loading && <LoadingIcon size="sm" />}
    </>
  );
}
export const Button = forwardRef(ButtonComponent);
export default Button;
