import { classNames } from '@scannable/common';
import { SecondaryOptionType } from '@scannable/frontend/types';

import Input from '../Input/Input';

export interface DataFieldInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: HTMLInputElement['name'];
  unit: string;
  availableUnits: SecondaryOptionType[];
  onChange?: (val: { unit?: string; value?: string }) => void;
}

export function DataFieldInput({
  name,
  className,
  unit,
  availableUnits,
  onChange,
  onBlur,
  value,
  ...props
}: DataFieldInputProps) {
  const options = [
    ...new Set([
      ...(availableUnits || []).map((unit) => unit.value),
      ...(unit ? [unit] : []),
    ]),
  ];
  return (
    <div className="mt-1 relative rounded-md shadow-sm">
      <Input
        style={props.style}
        type={props.type}
        name={name}
        id={props.id}
        className={classNames(
          'focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
          availableUnits.length > 0 ? 'pr-20' : ''
        )}
        placeholder=""
        onChange={(val) => {
          const value = val.target.value;
          if (onChange) {
            onChange({ value });
          }
        }}
        onBlur={onBlur}
        value={value}
      />
      {availableUnits.length > 0 && (
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="units" className="sr-only">
            Units
          </label>
          <select
            id="units"
            name="unit"
            value={unit}
            onChange={(val) => {
              const unit = val.target.value;
              if (onChange) {
                onChange({ unit });
              }
            }}
            className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
          >
            <option value="">Select unit</option>
            {[...new Set(options)].map((unit, i) => (
              <option key={`${i}_${unit}`}>{unit}</option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

export default DataFieldInput;
