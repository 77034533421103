import * as yup from 'yup';

export const batchValidationSchema = yup.object().shape({
  hasPurchaseOrderNumber: yup.boolean(),
  purchaseOrderNumber: yup.string().when('hasPurchaseOrderNumber', {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('A purchase order number is required'),
    otherwise: yup.string().nullable(),
  }),
  hasSalesOrderNumber: yup.boolean(),
  salesOrderNumber: yup.string().when('hasSalesOrderNumber', {
    is: true,
    then: yup.string().nullable().required('A sales order number is required'),
    otherwise: yup.string().nullable(),
  }),
  hasCustomerReference: yup.boolean(),
  customerReference: yup.string().when('hasCustomerReference', {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('A customer reference number is required'),
    otherwise: yup.string().nullable(),
  }),
  hasBatch: yup.boolean(),
  batchNumber: yup.string().when('hasBatch', {
    is: true,
    then: yup.string().nullable().required('A batch number is required'),
    otherwise: yup.string().nullable(),
  }),
  dom: yup.string().nullable().required('A date is required'),
});
