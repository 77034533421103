import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { formatDistanceToNowStrict } from 'date-fns';

import { cn, NotificationType } from '@scannable/common';
import { NotificationItem } from '@scannable/frontend/common';

import useTranslation from '../../hooks/useTranslation/useTranslation';

interface NotificationListItemProps {
  notification: NotificationItem;
}

export function NotificationListItem({
  notification,
}: NotificationListItemProps) {
  const { t } = useTranslation();
  const { push } = useRouter();
  const getNotificationParams = (type: keyof typeof NotificationType) => {
    switch (type) {
      case NotificationType.INSPECTION_DUE:
        return { inventoryNextInspectionResult: 'month' };
      case NotificationType.EXPIRY_DUE:
        return { inventoryExpiryResult: 'month' };
      default:
        return {};
    }
  };

  const handleOpenNotification = useCallback(() => {
    const params = getNotificationParams(notification.type);

    push({
      pathname: '/admin/inventory',
      query: params,
    });
  }, [push, notification.type]);

  return (
    <div
      key={notification.id}
      onClick={handleOpenNotification}
      className="py-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100"
    >
      <div className="flex flex-row items-center">
        <div className="px-4">
          <div
            data-cy="notification-indicator"
            className={cn(
              'w-2 h-2 rounded-full',
              notification.read ? 'bg-gray-200' : 'bg-blue-500'
            )}
          />
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row items-center justify-between">
            <div
              data-cy="notification-type"
              className="font-semibold text-sm text-gray-900"
            >
              {notification.type === NotificationType.INSPECTION_DUE &&
                t('notifications.inspection_due')}
              {notification.type === NotificationType.EXPIRY_DUE &&
                t('notifications.expiry_due')}
            </div>
            <div data-cy="notification-date" className="text-xs text-gray-500">
              {formatDistanceToNowStrict(new Date(notification.createdAt), {
                addSuffix: true,
              })}
            </div>
          </div>
          <div>
            <div
              data-cy="notification-message"
              className="text-xs text-gray-500"
            >
              {`${notification.payload.count} items due in the next 30 days`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
