import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';

export interface SortableWrapperProps {
  handleDragEnd: (event: DragEndEvent) => void;
  items: string[];
  children?: React.ReactNode;
  direction?: 'vertical' | 'horizontal';
}

export function SortableWrapper({
  children,
  handleDragEnd,
  items,
  direction = 'vertical',
}: SortableWrapperProps) {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    })
  );

  const modifiers =
    direction === 'vertical'
      ? [restrictToVerticalAxis]
      : [restrictToHorizontalAxis];

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={modifiers}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
}

export default SortableWrapper;
