import { useMemo } from 'react';

import { useCustomerReferenceTag } from '../../hooks';

const filter = {
  customer: {
    is: {
      id: {
        in: '{VAL}',
      },
    },
  },
};

export function useCustomerTagFilter() {
  const { customerTags } = useCustomerReferenceTag();
  const options = useMemo(
    () =>
      customerTags
        ? customerTags?.map((t) => ({
            label: t.name,
            value: t.id,
          }))
        : [],
    [customerTags]
  );
  return {
    options,
    interpolatedFilter: filter,
  };
}
