import { Notification, NotificationPreference } from '@scannable/common';

import { graphql } from '../graphql';

export const UPDATE_ORG_NOTIFICATION_PREFERENCES = graphql(`
  mutation ${NotificationPreference.UpdateOrgNotificationPreferences}($data: UpdateOrgNotificationPreferencesInput!) {
    updateOrgNotificationPreferences(data: $data) {
      id
      type
      status
      managers
      members
      assignedUsers
    }
  }`);

export const UPDATE_USER_NOTIFICATION_PREFERENCES = graphql(`
    mutation ${NotificationPreference.UpdateUserNotificationPreferences}($data: UpdateUserNotificationPreferencesInput!) {
      updateUserNotificationPreferences(data: $data) {
        type
        status
      }
    }`);

export const MARK_ALL_AS_READ = graphql(
  `mutation ${Notification.MarkAllAsRead} {
    markNotificationsAsRead
  }`
);

export const LINK_DEVICE_TOKEN = graphql(`
    mutation ${Notification.LinkDeviceToken}($data: LinkDeviceTokenInput!) {
      linkDeviceToken(data: $data)
    }`);
