import { InspectionFrequency } from '@scannable/common';

import { graphql } from '../graphql';

export const ALERT_TEMPLATES =
  graphql(`query ${InspectionFrequency.FindOneAlertTemplate} {
    organisationAlertTemplates {
      id
      name
    }
}`);

export const ALERT_TEMPLATE =
  graphql(`query ${InspectionFrequency.FindManyAlertTemplates}($id: Int!) {
    alertTemplate(id: $id) {
      id
      name
      unit
      quantity
      type
    }
}`);

export const ALL_INSPECTION_FREQUENCIES = graphql(`
  query ${InspectionFrequency.AllInspectionFrequencies}(
    $where: AlertTemplateWhereInput
    $orderBy: [AlertTemplateOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedAlertTemplates(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        quantity
        unit
        type
        createdAt
      }
      totalCount
    }
  }
`);
