import { useMutation } from '@apollo/client';

import {
  cn,
  isDefined,
  ProductVariation,
  Role,
  truncateString,
} from '@scannable/common';
import {
  NullsOrder,
  SortOrder,
  tableBtnLabel,
} from '@scannable/frontend/common';
import {
  DELETE_PRODUCT_VARIATION,
  PAGINATED_PRODUCT_VARIATIONS_BY_PRODUCT,
} from '@scannable/graphql-queries';

import { Badge, Button } from '../../atoms';
import { useAuth, useIsAdmin } from '../../auth';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { ConfirmationModal, useModal } from '../../modals';
import { ProductTypeBadge } from '../../molecules';
import {
  CreateProductVariationSidePanel,
  EditProductVariationSidePanel,
  useSidePanel,
} from '../../side-panels';
import { TableRowType } from '../../types/table.types';
import { ImageCell } from '../ImageCell/ImageCell';
import { Table } from '../Table/Table';
import { useVariantProductFilter } from './useVariantProductFilter';

type ProductVariationsTableProps = {
  manufacturerId?: number;
};
export function ProductVariationsTable({
  manufacturerId,
}: ProductVariationsTableProps) {
  const { showModal, hideModal } = useModal();
  const { guard } = useAuth();
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();

  const { showSidePanel, showItemSidePanel } = useSidePanel();

  const [deleteProductVariation] = useMutation(DELETE_PRODUCT_VARIATION, {
    refetchQueries: [ProductVariation.PaginatedProductVariationsByProduct],
  });
  const { options, interpolatedFilter } =
    useVariantProductFilter(manufacturerId);

  return (
    <Table<typeof PAGINATED_PRODUCT_VARIATIONS_BY_PRODUCT>
      uniqueTableKey="skus"
      title="SKUs"
      icon="products"
      fitScreen={{
        withTabs: true,
      }}
      tableActions={[
        {
          label: tableBtnLabel(t('create_label')),
          color: 'primary',
          dataCy: 'create-product-variation',
          display: guard([Role.Admin]),
          callback: () =>
            showSidePanel(
              <CreateProductVariationSidePanel
                manufacturerId={manufacturerId}
              />,
              {
                title: 'Create SKU',
              }
            ),
        },
      ]}
      rowActions={[
        {
          label: 'Preview',
          callback: (row) =>
            showItemSidePanel({
              productVariationId: row.id,
              showOptionsButton: false,
              showAddToInventory: false,
              showBanner: false,
            }),
        },
        {
          label: 'Clone SKU',
          callback: ({ productId, id }) =>
            showSidePanel(
              <CreateProductVariationSidePanel
                manufacturerId={manufacturerId}
                productId={productId}
                cloneId={id}
              />,
              {
                title: 'Create SKU',
              }
            ),
        },
        {
          label: t('delete'),
          display: guard([Role.Admin]),
          type: TableRowType.danger,
          callback: (row) =>
            showModal(
              <ConfirmationModal
                title="Delete Product Variation?"
                onConfirm={async () => {
                  await resolveMutation(
                    deleteProductVariation({
                      variables: {
                        data: {
                          id: row.id,
                        },
                      },
                      onCompleted: () => hideModal(),
                    })
                  );
                }}
              />
            ),
        },
      ]}
      columnSettings={[
        {
          label: 'Part Number',
          accessor: 'code',
          canSort: true,
          cellRender: ({
            id,
            name,
            ean,
            image,
            code,
            status,
            type,
            productId,
          }) => {
            return (
              <div
                data-cy="sku-side-panel"
                className={cn(isAdmin ? 'cursor-pointer' : '')}
                onClick={() =>
                  isAdmin &&
                  showSidePanel(
                    <EditProductVariationSidePanel
                      id={id}
                      name={name ?? ''}
                      status={status}
                      type={type}
                      productId={productId}
                      ean={ean ?? ''}
                      manufacturerId={manufacturerId}
                    />,
                    {
                      title: name || code,
                      headerAction: (
                        <Button
                          type="button"
                          color="info"
                          size="sm"
                          className="mr-2"
                          onClick={() =>
                            showItemSidePanel({
                              productVariationId: id,
                              showOptionsButton: false,
                              showAddToInventory: false,
                              showBanner: false,
                            })
                          }
                        >
                          Preview
                        </Button>
                      ),
                    }
                  )
                }
              >
                <ImageCell
                  image={{
                    uri: image?.uri,
                    alt: code ?? '',
                  }}
                >
                  {code ?? ''}
                </ImageCell>
              </div>
            );
          },
        },
        {
          label: 'SKU Name',
          accessor: 'name',
          canSort: {
            nulls: NullsOrder.last,
            sort: true,
          },
        },
        {
          label: 'Product Name',
          accessor: 'product',
          cellRender: ({ product }) =>
            truncateString(product?.name ?? '', {
              limit: 30,
              spaceFiller: '...',
            }),
        },
        {
          label: 'EAN/UPC/Barcode',
          accessor: 'ean',
          canSort: {
            nulls: NullsOrder.last,
            sort: true,
          },
        },
        {
          label: 'Type',
          accessor: 'type',
          cellRender: ({ type }) => {
            return (
              <ProductTypeBadge className="whitespace-nowrap" type={type} />
            );
          },
        },
        {
          label: 'Status',
          accessor: 'status',
          canSort: true,
          cellRender: ({ status }) => (
            <Badge colour={status === 'PUBLISHED' ? 'green' : 'yellow'}>
              {status}
            </Badge>
          ),
        },
      ]}
      query={PAGINATED_PRODUCT_VARIATIONS_BY_PRODUCT}
      advancedFilters={[
        {
          filterName: 'product',
          options,
          interpolatedFilter,
          label: 'Filter by Product',
          placeholder: 'Select products',
          isUrlParamEnabled: true,
          isMulti: true,
        },
      ]}
      searchFields={[
        {
          field: 'name',
        },
        { field: 'code' },
      ]}
      variables={[
        manufacturerId
          ? {
              manufacturerId: {
                equals: manufacturerId,
              },
            }
          : null,
      ].filter(isDefined)}
      initialState={{
        paginationEnabled: true,
        useAdvancedPagination: true,
        orderBy: {
          code: SortOrder.asc,
        },
      }}
    />
  );
}

export default ProductVariationsTable;
