import { useMutation, useQuery } from '@apollo/client';

import {
  AssignToTeamMemberAction,
  AssignToTeamMemberActionType,
  Inventory,
} from '@scannable/common';
import {
  useAssignItemToUserMutation,
  useAssignToUserByGroupMutation,
} from '@scannable/frontend/common';
import { FormFieldOptionType } from '@scannable/frontend/types';
import {
  ASSIGN_ITEMS_TO_USER,
  GET_ORGANISATION_USERS,
} from '@scannable/graphql-queries';

interface AssignItemVariables {
  userId?: number;
  serialisedProductId?: number;
  serialisedProductIds?: number[];
  groupId?: number;
}
export interface UseAssignToUser {
  options: FormFieldOptionType[];
  action: (variables: AssignItemVariables) => Promise<void>;
}

export function useAssignToUser(actionType: AssignToTeamMemberActionType) {
  const { data } = useQuery(GET_ORGANISATION_USERS);

  const [assignItemToUser] = useAssignItemToUserMutation({
    refetchQueries: [Inventory.PaginatedWebInventory],
  });
  const [assignToUserByGroup] = useAssignToUserByGroupMutation({
    refetchQueries: [Inventory.PaginatedWebInventory],
  });

  const [assignItemsToUser] = useMutation(ASSIGN_ITEMS_TO_USER, {
    refetchQueries: [Inventory.PaginatedWebInventory],
  });

  const options = data?.organisationUsers
    ? data.organisationUsers.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.userId,
      }))
    : [];

  const action = (variables: AssignItemVariables) => {
    switch (actionType) {
      case AssignToTeamMemberAction.singleItem:
        return assignItemToUser({
          variables: {
            serialisedProductId: variables.serialisedProductId as number,
            userId: variables.userId as number,
          },
        });
      case AssignToTeamMemberAction.group:
        return assignToUserByGroup({
          variables: {
            groupId: variables.groupId as number,
            userId: variables.userId as number,
          },
        });
      case AssignToTeamMemberAction.listOfItems:
        return assignItemsToUser({
          variables: {
            data: {
              userId: variables.userId as number,
              equipmentIds: variables.serialisedProductIds ?? [],
            },
          },
        });
      default:
        throw new Error(`Unknown action type: ${actionType}`);
    }
  };

  return {
    options,
    action,
  };
}
