import { ChangeEvent } from 'react';
import { useRouter } from 'next/router';

import { LanguageOptions } from '@scannable/common';

export function LanguageSelect() {
  const router = useRouter();
  const lang = router?.locale;

  const changeLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    const locale = e.target.value;
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, {
      locale: locale !== 'en' ? locale : false,
    });
  };

  return (
    <select
      id="AppLanguageSelect"
      onChange={changeLanguage}
      defaultValue={lang}
      className="bg-white rounded-[10px] text-sm border border-gray-300 h-10"
    >
      {Object.values(LanguageOptions).map((language) => (
        <option key={language} value={language}>
          {language.toUpperCase()}
        </option>
      ))}
    </select>
  );
}

export default LanguageSelect;
