import { useState } from 'react';

import { useSkuOrItemScreenQuery } from '@scannable/frontend/common';

import { LoadingIcon } from '../../atoms';
import { ProductDisplayOptions } from '../../types/product-display.types';
import ProductDisplay from '../ProductDisplay/ProductDisplay';

export interface LoadProductWrapperProps {
  productVariationId?: number;
  serialisedProductId?: number;
  showBanner?: boolean;
  openComponentsInModal?: boolean;
  openComponentsInPanel?: boolean;
}

export function LoadProductWrapper({
  productVariationId,
  serialisedProductId,
  showBanner,
  openComponentsInModal,
  openComponentsInPanel,
  showAddToInventory = false,
  showOptionsButton = false,
}: LoadProductWrapperProps & ProductDisplayOptions) {
  const [tabNumber, setTabNumber] = useState(0);

  const { data, error, loading } = useSkuOrItemScreenQuery({
    productVariationId,
    serialisedProductId,
  });

  if (loading || error || !data) {
    return (
      <div>
        <div className="relative flex min-h-[300px] items-center justify-center">
          <LoadingIcon size="sm" />
        </div>
      </div>
    );
  }
  return (
    <ProductDisplay
      product={data}
      tabNumber={tabNumber}
      changeTab={setTabNumber}
      showBanner={showBanner}
      openComponentsInModal={openComponentsInModal}
      openComponentsInPanel={openComponentsInPanel}
      showAddToInventory={showAddToInventory}
      showOptionsButton={showOptionsButton}
    />
  );
}

export default LoadProductWrapper;
