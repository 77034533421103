import { ucFirst } from '@scannable/common';

import { Link } from '../../atoms';

export interface TableLinkProps {
  path: string;
  type?: 'edit' | 'view';
}

export function TableLink({ path, type = 'view' }: TableLinkProps) {
  return (
    <div className="flex justify-end">
      <Link href={path} data-cy="edit" textLink>
        {`${ucFirst(type)}`} &rarr;
      </Link>
    </div>
  );
}

export default TableLink;
