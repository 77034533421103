import { CalendarIcon } from '@heroicons/react/24/outline';

import { Button, ButtonProps, Link } from '../../atoms';
import useTranslation from '../../hooks/useTranslation/useTranslation';

export function BookDemoButton(props: ButtonProps) {
  const { t } = useTranslation();
  return (
    <Link href="https://hubs.li/Q02LbJGq0" className="mx-2" external={true}>
      <Button className="pl-2 pr-3 h-10" color="purple" size="md" {...props}>
        <CalendarIcon className="mr-1.5 text-brand-primary" height={18} />
        <span>{t('book_demo')}</span>
      </Button>
    </Link>
  );
}

export default BookDemoButton;
