import { useState } from 'react';
import Link from 'next/link';
import { useMutation, useSubscription } from '@apollo/client';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';

import {
  dateFormat,
  Integration,
  PrinterOption,
  Role,
  SerialPurchaseOrder,
} from '@scannable/common';
import {
  backendUrl,
  downloadUrl,
  NullsOrder,
  SortOrder,
  useClearPrinterQueueLazyQuery,
  useGenerateSerialPurchaseOrderCsvMutation,
  useUserPrinters,
} from '@scannable/frontend/common';
import {
  DELETE_SERIAL_PURCHASE_ORDER,
  PAGINATED_SERIAL_PURCHASE_ORDERS,
  PRINT_ALL,
  PRINT_FIRST,
  SERIAL_PO_CSV_CREATED,
} from '@scannable/graphql-queries';

import { Button, ToolTip } from '../../atoms';
import { useAuth, useIntegrationGuard, useRoleGuard } from '../../auth';
import { useTable, useTranslation } from '../../hooks';
import { copyText, resolveMutation } from '../../lib/lib';
import {
  ConfirmationModal,
  PrintOneSerialModal,
  PrintWithLabelLiveModal,
  useModal,
} from '../../modals';
import { ReactSelectInput } from '../../molecules';
import { Table } from '../../tables';
import { SerialPurchaseOrderData } from '../../types/table-data.types';
import {
  TableColumn,
  TableRowAction,
  TableRowType,
} from '../../types/table.types';
import { successAlert } from '../../utils';
import ItemRow from './ItemRow';
import { useCustomerTagFilter } from './useCustomerTagFilter';
import { useProductCategoryFilter } from './useProductCategoryFilter';
import { useProductVariationFilter } from './useProductVariationFilter';

export interface UniqueItemsReportTableProps {
  title?: string;
}

export function UniqueItemsReportTable({ title }: UniqueItemsReportTableProps) {
  const { t } = useTranslation();
  const {
    options: customerOptions,
    interpolatedFilter: customerInterpolatedFilter,
  } = useCustomerTagFilter();
  const {
    options: productVariationOptions,
    interpolatedFilter: productVariationInterpolatedFilter,
    isLoading: productVariationIsLoading,
  } = useProductVariationFilter();
  const {
    options: productCategoryOptions,
    interpolatedFilter: productCategoryInterpolatedFilter,
  } = useProductCategoryFilter();
  const [printer, setPrinter] = useState<PrinterOption | null>(null);
  const { manufacturerId } = useAuth();
  const integrationGuard = useIntegrationGuard();
  const roleGuard = useRoleGuard();
  const { reload: setReload } = useTable();
  const { showModal } = useModal();
  const { printers } = useUserPrinters({
    onCompleted: (d) => {
      if (d?.teamMemberPrinters && d.teamMemberPrinters.length > 0) {
        const defaultPrinter =
          d.teamMemberPrinters.find((p) => p.name === 'Default') ??
          d.teamMemberPrinters[0];
        setPrinter({
          id: defaultPrinter.id,
          name: defaultPrinter.name as string,
        });
      }
    },
  });

  const [printFirst] = useMutation(PRINT_FIRST);
  const [printAll] = useMutation(PRINT_ALL);
  const [deleteSerialPurchaseOrder] = useMutation(
    DELETE_SERIAL_PURCHASE_ORDER,
    {
      refetchQueries: [SerialPurchaseOrder.PaginatedSerialPurchaseOrdersQuery],
    }
  );

  const [clearPrinterQueue] = useClearPrinterQueueLazyQuery({
    onCompleted: (d) => {
      if (d.clearPrinterQueue) {
        successAlert('Printer queue cleared');
      }
    },
  });
  useSubscription(SERIAL_PO_CSV_CREATED, {
    variables: {
      manufacturerId: manufacturerId as number,
    },
    skip: !manufacturerId,
    onData() {
      setReload();
    },
  });

  const [generateSerialPurchaseOrderCsv] =
    useGenerateSerialPurchaseOrderCsvMutation({
      onCompleted: () => successAlert('CSV generation started'),
    });

  const columns = [
    {
      label: t('serialisation.table_created'),
      accessor: 'createdAt',
      canSort: {
        nulls: NullsOrder.last,
        sort: true,
      },
      cellRender: (row) => <>{dateFormat(row.createdAt)}</>,
    },
    {
      label: t('create_serials_table_serial_format'),
      accessor: 'serialFormat',
      cellRender: ({ serialFormat }) => `${serialFormat?.name || ''}`,
    },
    {
      label: t('serialisation.table_sales_order_num'),
      accessor: 'salesOrderReference',
      canSort: {
        nulls: NullsOrder.last,
        sort: true,
      },
    },
    {
      label: t('serialisation.table_po_num'),
      accessor: 'purchaseOrderReference',
      canSort: {
        nulls: NullsOrder.last,
        sort: true,
      },
    },
    {
      label: t('serialisation.table_batch'),
      accessor: 'productBatch',
      cellRender: ({ productBatch }) => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{productBatch?.batchNumber ?? ''}</>
      ),
    },
    {
      label: t('serialisation.table_date_manufacturer'),
      accessor: 'dateOfManufacture',
      cellRender: ({ dateOfManufacture }) => (
        <>{dateFormat(dateOfManufacture)}</>
      ),
    },
    {
      label: t('serialisation.table_download'),
      accessor: 'uuid',
      cellRender: (row) => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {row?.downloadFileName ? (
            <Link
              href={`${downloadUrl('csv')}/${row.uuid}`}
              passHref
              rel="noopener noreferrer"
              target="_blank"
            >
              <ArrowDownTrayIcon className="h-5 w-5" />
            </Link>
          ) : (
            <ToolTip msg="Preparing download" />
          )}
        </>
      ),
    },
  ] as TableColumn<SerialPurchaseOrderData>[];

  return (
    <Table<typeof PAGINATED_SERIAL_PURCHASE_ORDERS>
      testId="created-serials"
      title={title}
      tableActions={[
        {
          display: Boolean(printer?.id),
          render: () => (
            <Button
              color="danger"
              onClick={() =>
                showModal(
                  <ConfirmationModal
                    title={`Are sure you want to clear the queue for printer ${printer?.id}?`}
                    hideOnConfirm
                    onConfirm={async () => {
                      if (printer?.id) {
                        await clearPrinterQueue({
                          variables: {
                            printerId: printer.id,
                          },
                        });
                      }
                    }}
                    buttonLabel="Clear Queue"
                  />
                )
              }
            >
              {t('printing.clear_queue')}
            </Button>
          ),
        },
        {
          display: Boolean(printer?.id),
          render: () => (
            <div className="flex items-center">
              <label
                htmlFor="Printer"
                className="inline-block text-sm text-gray-700 cursor-pointer font-medium pr-4"
              >
                {t('serialisation.select_printer')}
              </label>
              <ReactSelectInput<{
                label: string;
                value: string;
              }>
                id="Printer"
                options={printers}
                value={
                  printer ? { label: printer.name, value: printer.id } : null
                }
                onChange={(v) =>
                  setPrinter(
                    v
                      ? {
                          id: v.value,
                          name: v.label,
                        }
                      : null
                  )
                }
                data-cy="nfc-printer"
              />
            </div>
          ),
        },
      ]}
      advancedFilters={[
        {
          filterName: 'cutomerFilter',
          options: customerOptions,
          interpolatedFilter: customerInterpolatedFilter,
          label: t('serialisation.filter_customer_label'),
          placeholder: t('serialisation.filter_customer_placeholder'),
        },
        {
          filterName: 'productVariationFilter',
          label: t('serialisation.filter_sku_label'),
          placeholder: t('serialisation.filter_sku_placeholder'),
          options: productVariationOptions,
          isLoading: productVariationIsLoading,
          interpolatedFilter: productVariationInterpolatedFilter,
        },
        {
          filterName: 'productCatgeoryFilter',
          label: t('serialisation.filter_category_label'),
          placeholder: t('serialisation.filter_category_placeholder'),
          options: productCategoryOptions,
          interpolatedFilter: productCategoryInterpolatedFilter,
        },
      ]}
      rowActions={[
        ...((printer
          ? [
              {
                label: t('printing.preview_label'),
                callback: (row) =>
                  copyText(
                    backendUrl(
                      `print/preview/${row.uuid}?printerId=${printer.id}`
                    )
                  ),
              },
              {
                label: t('printing.print_one_label'),
                callback: async (row) => {
                  await resolveMutation(
                    printFirst({
                      variables: {
                        data: {
                          printerId: printer.id,
                          serialPurchaseOrderId: row.uuid,
                        },
                      },
                    }),
                    {
                      successMessage: t('printing.success_message'),
                    }
                  );
                },
              },
              {
                label: t('printing.print_all_label'),
                callback: async (row) => {
                  await resolveMutation(
                    printAll({
                      variables: {
                        data: {
                          printerId: printer.id,
                          serialPurchaseOrderId: row.uuid,
                        },
                      },
                    }),
                    {
                      successMessage: t('printing.success_message'),
                    }
                  );
                },
              },
              {
                label: t('printing.print_one_serial'),
                callback: async ({ uuid }: { uuid: string }) => {
                  showModal(
                    <PrintOneSerialModal
                      serialPurchaseOrderId={uuid}
                      printer={printer}
                    />
                  );
                },
              },
            ]
          : []) as TableRowAction<SerialPurchaseOrderData>[]),
        {
          label: 'Generate CSV',
          callback: (row) =>
            generateSerialPurchaseOrderCsv({
              variables: {
                data: {
                  serialPurchaseOrderId: row.id,
                },
              },
            }),
        },
        ...(integrationGuard(Integration.LabelLive)
          ? ([
              {
                label: t('printing.print_with_label_live'),
                callback: async ({ uuid }: { uuid: string }) => {
                  showModal(
                    <PrintWithLabelLiveModal serialPurchaseOrderId={uuid} />
                  );
                },
              },
            ] as TableRowAction<SerialPurchaseOrderData>[])
          : []),
        {
          label: 'Delete Serials',
          type: TableRowType.danger,
          display: roleGuard([Role.Admin]),
          callback(row) {
            showModal(
              <ConfirmationModal
                title="Are you sure you want to delete these serials?"
                message="All serials will be deleted and cannot be recovered. Any serials that a user has claimed will not be removed."
                hideOnConfirm
                onConfirm={async () => {
                  await resolveMutation(
                    deleteSerialPurchaseOrder({
                      variables: {
                        id: row.uuid,
                      },
                    }),
                    {
                      successMessage: 'Serials deleted successfully',
                    }
                  );
                }}
                buttonLabel="Delete"
              />
            );
          },
        },
      ]}
      columnSettings={columns}
      rowSettings={{
        rowRender: (row, rowNum, a) => (
          <ItemRow
            key={rowNum}
            row={row}
            rowNum={rowNum}
            columns={columns}
            rowActions={a}
          />
        ),
      }}
      query={PAGINATED_SERIAL_PURCHASE_ORDERS}
      fetchPolicy="cache-and-network"
      initialState={{
        useAdvancedPagination: true,
        paginationEnabled: true,
        orderBy: {
          createdAt: {
            sort: SortOrder.desc,
          },
        },
      }}
    />
  );
}

export default UniqueItemsReportTable;
