import { addDays, addMonths } from 'date-fns';

import { FilterValue } from '@scannable/frontend/store';

export const makeInventoryExpiryClause = (value: FilterValue) => {
  const today = new Date();
  let lte = today.toISOString();
  let gte = undefined;
  switch (value) {
    case 'overdue':
      lte = today.toISOString();
      break;
    case '7days':
      lte = addDays(today, 7).toISOString();
      gte = today.toISOString();
      break;
    case 'month':
      lte = addMonths(today, 1).toISOString();
      gte = today.toISOString();
      break;
    case '3month':
      lte = addMonths(today, 3).toISOString();
      gte = today.toISOString();
      break;
    default:
      break;
  }

  return {
    expiryDate: {
      lte,
      gte,
    },
  };
};
