import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { ViewProductComponent } from '@scannable/frontend/common';

import { Link, ResizedImage } from '../../atoms';

export interface ProductComponentProps {
  component: ViewProductComponent;
}

export interface ProductPageComponentProps extends ProductComponentProps {
  onOpenComponent?: (
    item: Pick<
      ViewProductComponent,
      'productVariationId' | 'serialisedProductId'
    >
  ) => void;
}

function Component({ component }: ProductComponentProps) {
  return (
    <div className="flex justify-between items-center gap-6">
      <div className="my-4 w-20">
        <ResizedImage
          src={component.image ?? ''}
          alt={component.name ?? 'Component'}
          className="object-contain object-center rounded-sm"
          width={64}
          height={64}
          fit="fill"
        />
      </div>
      <div className="text-sm text-gray-900 font-meduim w-full">
        {component.name}
        {component.category && (
          <div className="text-sm text-gray-500">{component.category}</div>
        )}
      </div>
      <div className="w-6">
        <ChevronRightIcon className="h-6 w-6 bg-gray-300 text-white rounded-md cursor-pointer" />
      </div>
    </div>
  );
}

export function ProductPageComponent({
  component,
  onOpenComponent,
}: ProductPageComponentProps) {
  if (onOpenComponent) {
    return (
      <div className="font-medium">
        <span
          className="cursor-pointer"
          onClick={() => onOpenComponent(component)}
        >
          <Component component={component} />
        </span>
      </div>
    );
  }
  return (
    <div className="font-medium">
      <Link href={`/${component.uid || ''}`} passHref>
        <Component component={component} />
      </Link>
    </div>
  );
}

export default ProductPageComponent;
