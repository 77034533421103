import { useMutation } from '@apollo/client';

import {
  CHANGE_MY_PASSWORD,
  CHANGE_USER_PASSWORD,
  REMOVE_USER_AVATAR,
  REMOVE_USER_SIGNATURE,
  SEND_ACTIVATION_EMAIL,
  UPDATE_USER,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useUpdateUserMutation(
  options?: UseMutationOptions<typeof UPDATE_USER>
) {
  return useMutation(UPDATE_USER, options);
}

export function useChangeMyPasswordMutation(
  options?: UseMutationOptions<typeof CHANGE_MY_PASSWORD>
) {
  return useMutation(CHANGE_MY_PASSWORD, options);
}

export function useChangeUserPasswordMutation(
  options?: UseMutationOptions<typeof CHANGE_USER_PASSWORD>
) {
  return useMutation(CHANGE_USER_PASSWORD, options);
}

export function useRemoveUserAvatarMutation(
  options?: UseMutationOptions<typeof REMOVE_USER_AVATAR>
) {
  return useMutation(REMOVE_USER_AVATAR, options);
}

export function useRemoveUserSignatureMutation(
  options?: UseMutationOptions<typeof REMOVE_USER_SIGNATURE>
) {
  return useMutation(REMOVE_USER_SIGNATURE, options);
}

export function useSendActivationEmailMutation(
  options?: UseMutationOptions<typeof SEND_ACTIVATION_EMAIL>
) {
  return useMutation(SEND_ACTIVATION_EMAIL, options);
}
