import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { cn } from '@scannable/common';

import { LoadingIcon, Title } from '../../atoms';
import { useModal } from '../ModalContext/ModalContext';

export interface SimpleModalProps {
  title?: string;
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
  overflowHidden?: boolean;
  dismissable?: boolean;
  loading?: boolean;
}

export function SimpleModal({
  title,
  children,
  size = 'lg',
  loading = false,
  overflowHidden = true,
  dismissable = true,
}: SimpleModalProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { isModalOpen, hideModal } = useModal();

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={hideModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  'relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-20 sm:p-6 sm:w-full',
                  `sm:max-w-${size}`,
                  overflowHidden ? 'overflow-hidden' : ''
                )}
              >
                <div ref={ref} className="w-full">
                  <div className="sm:block absolute top-0 right-0 pt-4 pr-4">
                    {dismissable && (
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => hideModal()}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    )}
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <Title
                      size="h3"
                      className="text-lg leading-6 font-medium text-gray-900 font-weight-500"
                    >
                      {title}
                    </Title>
                    {!loading ? (
                      children
                    ) : (
                      <div className="relative flex min-h-[300px] items-center justify-center">
                        <LoadingIcon size="sm" />
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SimpleModal;
