import { HTMLAttributes } from 'react';
import Link from 'next/link';

import { cn, MISSING_IMAGE } from '@scannable/common';

import { Image, ResizedImage } from '../../atoms';

export interface ImageCellProps extends HTMLAttributes<HTMLDivElement> {
  href?: string;
  image?: {
    url?: string | null;
    alt?: string;
    uri?: string | null;
  } | null;
  imageAlt?: string;
  className?: string;
}

export function ImageCell({
  image,
  href,
  children,
  className,
}: ImageCellProps) {
  if (image?.uri && href) {
    return (
      <div className="flex items-center overflow-hidden">
        <Link href={href} className="flex">
          <div className="h-10 w-10 flex-shrink-0 relative">
            <ResizedImage
              src={image.uri}
              alt={image.alt || ''}
              width={48}
              height={48}
              className={cn(
                className ? className : 'rounded object-cover object-center'
              )}
              fit="cover"
            />
          </div>
          <div className="ml-4 flex justify-center items-center">
            <div className="font-medium text-gray-900">{children}</div>
          </div>
        </Link>
      </div>
    );
  }
  if (image?.url && href) {
    return (
      <div className="flex items-center">
        <Link href={href} className="flex">
          <div className="h-10 w-10 flex-shrink-0 relative">
            <Image
              className="rounded object-cover object-center"
              src={image.url}
              alt={image.alt || ''}
              fill
            />
          </div>
          <div className="ml-4 flex justify-center items-center">
            <div className="font-medium text-gray-900">{children}</div>
          </div>
        </Link>
      </div>
    );
  }
  if (image?.uri) {
    return (
      <div className="flex items-center">
        <div className="h-10 w-10 flex-shrink-0 relative">
          <ResizedImage
            src={image.uri}
            alt={image.alt || ''}
            width={48}
            height={48}
            className="object-contain object-center"
            fit="fill"
          />
        </div>
        <div className="ml-4 flex justify-center items-center">{children}</div>
      </div>
    );
  }
  if (image?.url) {
    return (
      <div className="flex items-center">
        <div className="h-10 w-10 flex-shrink-0 relative">
          <Image
            className="rounded object-contain object-center"
            src={image.url}
            alt={image.alt || ''}
            fill
          />
        </div>
        <div className="ml-4 flex justify-center items-center">{children}</div>
      </div>
    );
  }

  if (href) {
    return (
      <div className="flex items-center">
        <Link href={href} className="flex">
          <div className="h-10 w-10 flex-shrink-0 relative">
            <Image
              className="rounded object-contain object-center"
              src={MISSING_IMAGE}
              alt={image?.alt || ''}
              fill
            />
          </div>
          <div className="ml-4 flex justify-center items-center">
            {children}
          </div>
        </Link>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <div className="h-10 w-10 flex-shrink-0 relative">
        <Image
          className="rounded object-contain object-center"
          src={MISSING_IMAGE}
          alt={image?.alt || ''}
          fill
        />
      </div>
      <div className="ml-4 flex justify-center items-center">{children}</div>
    </div>
  );
}

export default ImageCell;
