import { useCallback, useMemo } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

import { cn, SerialFormatComponent } from '@scannable/common';
import {
  SerialFormatComponentParts,
  SerialFormatFormValues,
  valuesOrderedComponentsForDisplay,
} from '@scannable/frontend/common';

import { SortableItem } from '../SortableItem/SortableItem';
import SortableWrapper from '../SortableWrapper/SortableWrapper';

type SerialFormatDisplayProps = {
  components: SerialFormatFormValues & SerialFormatComponentParts;
  onOrderChange: (parts: SerialFormatComponent[]) => void;
};

export function SerialFormatSort({
  components,
  onOrderChange,
}: SerialFormatDisplayProps) {
  const parts = useMemo(
    () => valuesOrderedComponentsForDisplay(components),
    [components]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = parts.findIndex((part) => part.type === active.id);
        const newIndex = parts.findIndex((part) => part.type === over?.id);
        const newParts = [...parts].map((part) => {
          if (part.type === active.id) {
            return { ...part, order: newIndex };
          }
          if (part.type === over?.id) {
            return { ...part, order: oldIndex };
          }
          return part;
        });

        onOrderChange(newParts);
      }
    },
    [onOrderChange, parts]
  );

  return (
    <SortableWrapper
      handleDragEnd={handleDragEnd}
      items={parts.map((parts) => parts.type)}
      direction="horizontal"
    >
      <div className="flex space-x-4">
        {parts.map((part) => {
          const baseClassName =
            'flex text-gray-400 bg-white items-center h-16 px-5 py-2 border border-gray-200 rounded-md shadow-sm';
          const resolvedClassName = cn(
            baseClassName,
            part.sortable ? 'cursor-pointer' : 'cursor-not-allowed'
          );
          return (
            <SortableItem
              key={part.type}
              id={part.type}
              className={resolvedClassName}
              draggedClassName="!border-blue-400 border-2 !text-blue-400"
              disabled={!part.sortable}
            >
              {part.sortable && (
                <div className="mr-1 ml-[-10px]">
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </div>
              )}

              <div className="flex flex-col justify-center">
                <div className="font-semibold text-sm text-gray-800">
                  {part.label}
                </div>
                <div className="text-xs text-gray-500">
                  {part.value ?? 'Set during Serialisation'}
                </div>
              </div>
            </SortableItem>
          );
        })}
      </div>
    </SortableWrapper>
  );
}
