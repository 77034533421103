import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  useBoolean,
  useSkuOrItemScreenQuery,
} from '@scannable/frontend/common';

import { LoadingIcon } from '../../atoms';
import { ProductDisplay } from '../../organisms';
import { SidePanel } from '../../side-panels';

type SidePanelType = JSX.Element | ReactNode | null | undefined;
type SidePanelOptionsType = {
  title?: string;
  onBack?: () => void;
  headerAction?: ReactNode;
};
type ItemSidePanelOptionsType = SidePanelOptionsType & {
  serialisedProductId?: number | null;
  productVariationId?: number | null;
  showAddToInventory?: boolean;
  showOptionsButton?: boolean;
  showBanner?: boolean;
};
type HandleSidePanelType = (
  m: SidePanelType,
  o?: SidePanelOptionsType | null
) => void;

type HandleItemSidePanelType = (o: ItemSidePanelOptionsType | null) => void;

export interface SidePanelContextState {
  sidePanel: boolean;
  handleSidePanel: HandleSidePanelType;
  handleItemSidePanel: HandleItemSidePanelType;
  closeSidePanel: () => void;
  sidePanelContent: SidePanelType;
  sidePanelOptions: SidePanelOptionsType | null;
  itemSidePanelOptions: ItemSidePanelOptionsType | null;
  isSidePanelOpen: boolean;
}

const initState = {
  sidePanel: false,
  handleSidePanel: (m: SidePanelType, o?: SidePanelOptionsType | null) => ({}),
  handleItemSidePanel: (o: ItemSidePanelOptionsType | null) => ({}),
  closeSidePanel: () => ({}),
  sidePanelContent: null,
  sidePanelOptions: null,
  itemSidePanelOptions: null,
  isSidePanelOpen: false,
};

const SidePanelContext = createContext<SidePanelContextState>(initState);
const { Provider } = SidePanelContext;

const SidePanelProvider = ({ children }: { children: ReactNode }) => {
  const [sidePanel, setSidePanel] = useBoolean();
  const [sidePanelContent, setSidePanelContent] = useState<SidePanelType>(null);
  const [sidePanelOptions, setSidePanelOptions] =
    useState<SidePanelOptionsType | null>(initState.sidePanelOptions);
  const [itemSidePanelOptions, setItemSidePanelOptions] =
    useState<ItemSidePanelOptionsType | null>(initState.itemSidePanelOptions);

  const handleSidePanel = useCallback(
    (
      content = null as SidePanelType,
      options = {} as SidePanelOptionsType | null
    ) => {
      setSidePanel.on();
      setSidePanelContent(content);
      setSidePanelOptions({ ...options });
    },
    [setSidePanel]
  );
  const handleItemSidePanel = useCallback(
    (options = null as ItemSidePanelOptionsType | null) => {
      setSidePanel.on();
      setSidePanelContent(null);
      setItemSidePanelOptions(options);
    },
    [setSidePanel]
  );

  const closeSidePanel = useCallback(() => {
    setSidePanel.off();
    setSidePanelContent(null);
    setSidePanelOptions(initState.sidePanelOptions);
    setItemSidePanelOptions(initState.itemSidePanelOptions);
  }, [setSidePanel]);

  const contextValue = useMemo(
    () => ({
      sidePanel,
      handleSidePanel,
      handleItemSidePanel,
      closeSidePanel,
      sidePanelContent,
      sidePanelOptions,
      itemSidePanelOptions,
      isSidePanelOpen: sidePanel,
    }),
    [
      sidePanel,
      handleSidePanel,
      handleItemSidePanel,
      closeSidePanel,
      sidePanelContent,
      sidePanelOptions,
      itemSidePanelOptions,
    ]
  );

  return <Provider value={contextValue}>{children}</Provider>;
};

const useSidePanel = () => {
  const context = useContext(SidePanelContext);

  const hideSidePanel = useCallback(() => {
    context.closeSidePanel();
  }, [context]);

  const showSidePanel = useCallback(
    (m: SidePanelType, t?: SidePanelOptionsType) => {
      context.handleSidePanel(m, t);
    },
    [context]
  );
  const showItemSidePanel = useCallback(
    (t: ItemSidePanelOptionsType) => context.handleItemSidePanel(t),
    [context]
  );

  const isSidePanelOpen = context.sidePanel;
  return {
    sidePanelContent: context.sidePanelContent,
    sidePanelOptions: context.sidePanelOptions,
    itemSidePanelOptions: context.itemSidePanelOptions,
    hideSidePanel,
    showSidePanel,
    isSidePanelOpen,
    showItemSidePanel,
  };
};

function SidePanelPortal() {
  const {
    sidePanelContent,
    isSidePanelOpen,
    sidePanelOptions,
    itemSidePanelOptions,
  } = useSidePanel();
  const [tabNumber, setTabNumber] = useState(0);
  const { data, error, loading } = useSkuOrItemScreenQuery({
    serialisedProductId: itemSidePanelOptions?.serialisedProductId,
    productVariationId: itemSidePanelOptions?.productVariationId,
    skip: !itemSidePanelOptions?.serialisedProductId,
  });

  if (isSidePanelOpen && sidePanelContent) {
    return (
      <SidePanel
        title={sidePanelOptions?.title}
        onBack={sidePanelOptions?.onBack}
        headerAction={sidePanelOptions?.headerAction}
      >
        {sidePanelContent}
      </SidePanel>
    );
  }

  if (itemSidePanelOptions && (loading || error || !data)) {
    return (
      <SidePanel
        title={itemSidePanelOptions?.title}
        onBack={itemSidePanelOptions?.onBack}
        headerAction={itemSidePanelOptions?.headerAction}
      >
        <div className="relative flex min-h-[300px] items-center justify-center">
          <LoadingIcon size="sm" />
        </div>
      </SidePanel>
    );
  }
  if (itemSidePanelOptions && data) {
    return (
      <SidePanel
        title={itemSidePanelOptions?.title}
        onBack={itemSidePanelOptions?.onBack}
        headerAction={itemSidePanelOptions?.headerAction}
      >
        <ProductDisplay
          product={data}
          tabNumber={tabNumber}
          changeTab={setTabNumber}
          showBanner={itemSidePanelOptions?.showBanner}
          showAddToInventory={itemSidePanelOptions?.showAddToInventory}
          showOptionsButton={itemSidePanelOptions?.showOptionsButton}
        />
      </SidePanel>
    );
  }
  return null;
}

export {
  SidePanelContext,
  SidePanelProvider,
  initState,
  useSidePanel,
  SidePanelPortal,
};
