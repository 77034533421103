import { cn } from '@scannable/common';
import { FormFieldOptionType } from '@scannable/frontend/types';

import { ToolTip } from '../../atoms';

export interface RadioInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  options: FormFieldOptionType[];
  name: string;
  inline?: boolean;
  cols?: number;
}

export function RadioInput({
  options,
  name,
  onChange,
  onBlur,
  id,
  value,
  inline = false,
  cols,
  ...props
}: RadioInputProps) {
  return (
    <div
      className={cn(
        inline ? 'flex justify space-x-auto' : '',
        cols ? `flex flex-row justify-center space-x-4 mx-3` : ''
      )}
    >
      {options.map((option) => {
        const inputId = `${id || name}-${option.value}`;
        return (
          <div
            className={cn(
              'pl-2',
              inline ? 'flex justify-start space-x-4' : 'my-2',
              cols ? `w-[${100 / cols}%]` : ''
            )}
            key={`${option.value}`}
          >
            <div
              className={cn('flex items-center', cols ? 'justify-center' : '')}
            >
              <input
                id={inputId}
                name={name}
                type="radio"
                onChange={onChange}
                onBlur={onBlur}
                value={`${option.value}`}
                className={cn(
                  'h-4 w-4  border-gray-300',
                  `focus:ring-${option.color}-500 text-${option.color}-600`
                )}
                checked={value === option.value}
                {...props}
              />
              <label
                htmlFor={inputId}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {option.label}
                {option.tooltip && <ToolTip msg={option.tooltip} />}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RadioInput;
