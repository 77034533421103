import { FormikProps } from 'formik';

import { classNames, Unit } from '@scannable/common';

import Input from '../Input/Input';

export interface InputWithUnitsProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: HTMLInputElement['name'];
  unit?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  availableUnits: Unit[];
}

/**
 * This should be retired. Use DataFieldInput instead.
 * @param param0
 * @returns
 */
export function InputWithUnits({
  name,
  formik,
  className,
  unit,
  availableUnits,
  value,
  ...props
}: InputWithUnitsProps) {
  const handleUnitChange = (newUnit: string) => {
    formik.setFieldValue(`${name}[unit]`, newUnit);
  };
  // create a unique set of options form existing and available units if they exist
  const options = availableUnits
    .reduce((prev, curr) => (curr ? [...prev, curr.value] : prev), [unit])
    .filter(Boolean);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(`${name}[value]`, e.target.value);
  };
  return (
    <div className="mt-1 relative rounded-md shadow-sm">
      <Input
        style={props.style}
        type={props.type}
        name={name}
        id={props.id}
        className={classNames(
          'focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
          availableUnits.length > 0 ? 'pr-20' : ''
        )}
        placeholder=""
        onChange={onChange}
        value={value}
      />
      {availableUnits.length > 0 && (
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="units" className="sr-only">
            Units
          </label>
          <select
            id="units"
            name="unit"
            value={unit}
            onChange={(e) => handleUnitChange(e.target.value)}
            className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
          >
            <option value="">Select unit</option>
            {[...new Set(options)].map((unit, i) => (
              <option key={`${i}_${unit}`}>{unit}</option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

export default InputWithUnits;
