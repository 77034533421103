import { useMutation, useQuery } from '@apollo/client';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { HardwareType, Printing } from '@scannable/common';
import {
  DELETE_HARDWARE,
  GET_HARDWARE_BY_TYPE,
} from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import CreateHardwarePrinterForm from '../../forms/CreateHardwarePrinterForm/CreateHardwarePrinterForm';
import EditHardwarePrinterForm from '../../forms/EditHardwarePrinterForm/EditHardwarePrinterForm';
import { resolveMutation } from '../../lib/lib';
import { useModal } from '../../modals/ModalContext/ModalContext';

export function HardwarePrinters() {
  const { showModal } = useModal();
  const { data } = useQuery(GET_HARDWARE_BY_TYPE, {
    variables: {
      data: {
        type: HardwareType.PRINTER,
      },
    },
  });

  const [deletePrinter] = useMutation(DELETE_HARDWARE, {
    refetchQueries: [Printing.GetHardwareByType],
  });
  return (
    <div className="mb-4">
      {data?.hardwareByType &&
        data.hardwareByType.map((item) => (
          <div
            key={item.publicId}
            className="flex flex-row justify-between items-center w-full p-2 border-b border-gray-200"
          >
            <div
              className="cursor-pointer"
              onClick={() => showModal(<EditHardwarePrinterForm item={item} />)}
            >
              {item.name}
            </div>
            <div className="flex flex-row space-x-2">
              <Button
                color="white"
                onClick={async () => {
                  await resolveMutation(
                    deletePrinter({
                      variables: {
                        publicId: item.publicId,
                      },
                    }),
                    {
                      successMessage: 'Printer deleted',
                    }
                  );
                }}
              >
                <XMarkIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
        ))}
      <Button
        className="mt-4"
        onClick={() => {
          showModal(<CreateHardwarePrinterForm />);
        }}
        color="info"
      >
        Add Printer
      </Button>
    </div>
  );
}
export default HardwarePrinters;
