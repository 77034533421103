import { UPGRADE_ROUTE } from '@scannable/common';

import { Button, Link, Text } from '../../atoms';
import { AdminPanel } from '../../molecules/AdminPanel/AdminPanel';

export function UpgradePanel() {
  return (
    <AdminPanel className="h-96">
      <div className="flex justify-center items-center h-full">
        <div className="flex-col justify-center text-center w-60">
          <Text>
            To get access to this feature, upgrade to Scannable Pro.{' '}
            <Link
              href="https://www.scannable.io/pro"
              external
              className="text-gray-400 hover:text-gray-600 underline underline-offset-2 text-sm hover:no-underline "
            >
              Learn more about Scannable Pro here &rarr;
            </Link>
          </Text>
          <Link href={UPGRADE_ROUTE}>
            <Button type="button" color="lime" className="mt-4">
              Upgrade to Scannable Pro
            </Button>
          </Link>
        </div>
      </div>
    </AdminPanel>
  );
}

export default UpgradePanel;
