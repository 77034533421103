function parseField(
  parts: string | string[],
  searchTerm: string,
  type: string,
  relation?: 'one' | 'many'
): { [key: string]: unknown } {
  const partsArray = Array.isArray(parts) ? [...parts] : parts.split('.');
  if (!partsArray.length) {
    return { [type]: searchTerm };
  }
  const field = partsArray.shift() as string;
  if (partsArray.length === 0) {
    return { [field]: { [type]: searchTerm } };
  }
  if (relation && relation === 'one') {
    return {
      [field]: {
        is: parseField(partsArray, searchTerm, type),
      },
    };
  }
  if (relation && relation === 'many') {
    return {
      [field]: {
        some: parseField(partsArray, searchTerm, type),
      },
    };
  }
  throw new Error('Invalid search field');
}

type SearchField = {
  type?: 'search' | 'contains';
  relation?: 'one' | 'many';
  field: string;
};

/**
 * @remarks This function is used in the Table component to resolve the search filters.
 * It is also used in the app e.g. on the Inventory screen.
 */
export function makeSearchClauseFromFilters(
  searchTerm: string,
  searchFields?: SearchField[]
) {
  if (searchTerm) {
    const fields = searchFields?.map((f) => {
      const searchType = f.type || 'search';
      const term =
        searchType === 'search'
          ? searchTerm.trim().split(' ').join(' | ')
          : searchTerm.trim();
      return parseField(f.field, term, searchType, f.relation);
    });
    if (fields && fields.length > 1) {
      return [
        {
          OR: fields,
        },
      ];
    }
    return fields;
  }
  return null;
}

export const INVENTORY_SEARCH_FIELDS: {
  field: string;
  label?: string;
  relation?: 'one' | 'many';
  hideLabel?: boolean;
}[] = [
  {
    field: 'serialNumber',
    label: 'serial',
  },
  {
    field: 'name',
  },
  {
    field: 'aliases.alias',
    label: 'NFC',
    relation: 'many',
  },
  {
    field: 'productBatch.batchNumber',
    label: 'batch',
    relation: 'one',
  },
  {
    field: 'productVariation.name',
    hideLabel: true,
    relation: 'one',
  },
  {
    field: 'productVariation.code',
    label: 'Part',
    relation: 'one',
  },
  {
    field: 'product.name',
    hideLabel: true,
    relation: 'one',
  },
];
