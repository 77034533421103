import { GroupType } from '@prisma/client';

import { Groups } from '@scannable/common';

import { graphql } from '../graphql';

export const GET_GROUPS_BY_TYPE =
  graphql(`query ${Groups.GetGroupsByType}($type: GroupType!) {
    groups(where: {type: {equals: $type}}) {
      id
      name
    }
  }`);

export const GET_GROUPS_BY_TYPE_WITH_LAST_CHECK_OFF =
  graphql(`query ${Groups.GetGroupsByTypeWithLastCheckOff}($type: GroupType!) {
    groups(where: {type: {equals: $type}}) {
      id
      name
      lastCheckOff {
        id
        date
        result
      }
    }
  }`);

export const GET_ALL_GROUPS = graphql(`query ${Groups.GetGroupsByType} {
    groups(where: {type: {not: {equals: ${GroupType.CUSTOMER}}}}) {
      id
      name
      type
    }
  }`);

export const GET_GROUPS_BY_SERIALISED_PRODUCT_ID = graphql(`
  query ${Groups.GetGroupsBySerialisedProductId}($serialisedProductId: Int!) {
    groupsBySerialisedProductId(serialisedProductId: $serialisedProductId) {
      id
      type
      name
    }
  }
`);

export const PUBLIC_GROUP = graphql(`
  query ${Groups.GroupByUid} ($id: String!) {
    publicGroup(id: $id) {
      id
      uid
      name
    }
  }
`);

export const INVENTORY_GROUPS = graphql(`
  query ${Groups.InventoryGroups} (
    $where: GroupWhereInput
    $orderBy: [GroupOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedGroups(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        type
        uid
      }
      totalCount
    }
  }
`);

export const GET_GROUP = graphql(`
  query ${Groups.GetGroup}($id: Int!) {
    group(where: {id: {equals: $id}}) {
      id
      name
      type
      uuid
      uid
      lastCheckOff {
        id
        date
        result
      }
      aliases {
        alias
      }
    }
  }
`);

export const GET_GROUP_WITH_ITEMS = graphql(`
  query ${Groups.GetGroupWithItems}($id: Int!) {
    group(where: {id: {equals: $id}}) {
      id
      name
      type
      inventory {
        id
        name
        tierOne {
          name
          manufacturerName
          code
          ean
          serialNumber
          batchNumber
          expiryDate
          nextInspection
          lastInspection {
            status
            passed
            date
          }
          nfcTags
        }
        image {
          uri
        }
      }
    }
  }
`);

export const SEARCH_GROUPS = graphql(`
  query ${Groups.SearchGroups}($search: String!, $take: Int!) {
    searchGroups(search: $search, take: $take) {
      id
      name
      type
    }
  }
`);
