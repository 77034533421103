import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { FirstNode, NullsOrder } from '@scannable/frontend/common';
import { selectTableFilters } from '@scannable/frontend/store';
import {
  GET_METADATA,
  PAGINATED_PRODUCT_VARIATIONS_QUERY,
} from '@scannable/graphql-queries';

import { useManufacturerProductGrouping } from '../../hooks';
import {
  ProductTypeBadge,
  ReactSelectInput,
  ThumbnailImage,
} from '../../molecules';
import { useSidePanel } from '../../side-panels';
import { TableColumn } from '../../types/table.types';
import { FieldFilter } from '../FieldFilter/FieldFilter';
import { Table } from '../Table/Table';

interface ProductDataProps {
  manufacturerId: number;
}
export function ProductData({ manufacturerId }: ProductDataProps) {
  const [selectedMetadata, setSelectedMetadata] = useState<string[]>([]);
  const router = useRouter();
  const tableFilters = useSelector(selectTableFilters);
  const filter = tableFilters['default']
    ? tableFilters['default'].find((f) => f.filterName === 'productGroup')
    : null;
  const selectedGroups =
    filter && Array.isArray(filter.values)
      ? (filter?.values?.map((v) => v.value) as number[])
      : [];

  const { showItemSidePanel } = useSidePanel();
  const { options } = useManufacturerProductGrouping();
  const productGroupIds =
    selectedGroups && selectedGroups.length > 0
      ? selectedGroups
      : options.map((option) => option.value);
  const { data } = useQuery(GET_METADATA, {
    variables: {
      where: {
        productGroupMetadata: {
          some: {
            productGroupId: {
              in: productGroupIds,
            },
          },
        },
      },
    },
    skip: productGroupIds.length === 0,
  });
  const metadata = useMemo(() => data?.metadatas || [], [data?.metadatas]);
  const metadataOptions = useMemo(
    () => metadata.map((mc) => ({ label: mc.name, value: mc.key })),
    [metadata]
  );

  const metadataColumns: TableColumn<
    FirstNode<typeof PAGINATED_PRODUCT_VARIATIONS_QUERY>
  >[] = useMemo(() => {
    return metadata
      .filter((md) => !selectedMetadata.includes(md.key))
      .map((md) => ({
        label: md.name,
        accessor: 'data',
        cellRender: (row) => {
          const metadataValue = row?.data?.data?.find((d) => d.key === md.key);
          return metadataValue ? metadataValue.valueForDisplay : '';
        },
      }));
  }, [metadata, selectedMetadata]);

  return (
    <Table<typeof PAGINATED_PRODUCT_VARIATIONS_QUERY>
      title="Product Variations"
      icon="producers"
      columnSettings={[
        {
          label: 'Part Number',
          accessor: 'code',
          canSort: true,
          cellRender: ({ id, code, image }) => {
            return (
              <div
                className="h-10 w-10 flex-shrink-0 relative cursor-pointer"
                onClick={() => {
                  showItemSidePanel({
                    productVariationId: id,
                    showBanner: false,
                    showOptionsButton: true,
                  });
                }}
              >
                <ThumbnailImage
                  src={image?.uri || ''}
                  alt={code || ''}
                  showNfcIcon={false}
                />
              </div>
            );
          },
        },
        {
          label: 'Name',
          accessor: 'name',
          canSort: {
            nulls: NullsOrder.last,
            sort: true,
          },
          cellRender: (row) => {
            return (
              <>
                {row.product?.name}
                <br />
                {row.name}
              </>
            );
          },
        },
        {
          label: 'Type',
          accessor: 'type',
          cellRender: ({ type }) => {
            return (
              <ProductTypeBadge className="whitespace-nowrap" type={type} />
            );
          },
        },
        ...metadataColumns,
      ]}
      query={PAGINATED_PRODUCT_VARIATIONS_QUERY}
      resetFilters={true}
      skip={!manufacturerId}
      initialState={{
        paginationEnabled: true,
        useAdvancedPagination: true,
      }}
      variables={[
        {
          manufacturerId: {
            equals: Number(manufacturerId),
          },
        },
      ]}
      searchFields={[
        {
          field: 'name',
        },
        { field: 'code' },
      ]}
      rowActions={[
        {
          label: 'Update',
          callback: (value) =>
            router.push(
              `/admin/manufacturers/${manufacturerId}/products/${value.productId}/variation/${value.id}`
            ),
        },
      ]}
      filters={[
        {
          filterName: 'productGroup',
          render: (props) => (
            <FieldFilter<number>
              {...props}
              className="sm:max-w-xs"
              interpolatedFilter={{
                OR: [
                  {
                    product: {
                      is: {
                        productGroupId: {
                          in: '{VAL}',
                        },
                      },
                    },
                  },
                ],
              }}
              placeholder="Filter by product group"
              options={options}
            />
          ),
        },
        {
          filterName: 'metadata',
          render: ({ key }) => (
            <div className="sm:max-w-xs" key={key}>
              <ReactSelectInput<{ value: string; label: string }, true>
                name="filter"
                options={metadataOptions}
                onChange={(selectedOptions) => {
                  setSelectedMetadata(
                    selectedOptions.map((option) => option.value)
                  );
                }}
                value={metadataOptions.filter((option) =>
                  selectedMetadata.includes(option.value)
                )}
                placeholder="Select columns to hide"
                isMulti
              />
            </div>
          ),
        },
      ]}
    />
  );
}
