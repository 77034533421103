import Head from 'next/head';

import { dateFormat } from '@scannable/common';
import { GraphQLResult } from '@scannable/frontend/common';
import { PUBLIC_GROUP } from '@scannable/graphql-queries';

import { Icon, IconType, Image, Link, LoadingIcon } from '../../atoms';
import { usePaginatedGroupUrlItems, useTranslation } from '../../hooks';
import useInfiniteScroll from '../../hooks/useInfiniteScroll/useInfiniteScroll';
import { InspectionStatusBadge, ThumbnailImage } from '../../molecules';
import { PageHeadOg } from '../../organisms';

type GroupedPageData = GraphQLResult<typeof PUBLIC_GROUP>;
export interface GroupedItemsPageProps {
  title: string;
  icon: IconType;
  data?: GroupedPageData;
}

export function GroupedItemsPage({ icon, title, data }: GroupedItemsPageProps) {
  const { t } = useTranslation();
  const {
    loading,
    data: groupData,
    loadMore,
    hasMore,
  } = usePaginatedGroupUrlItems(data?.uid);

  const inventoryItems = groupData?.paginatedGroupUrlItems?.nodes
    ? groupData.paginatedGroupUrlItems.nodes.filter((r) => Boolean(r))
    : [];

  const loader = useInfiniteScroll({ loadMore, hasMore });

  return (
    <>
      <Head>
        <title>{`Scannable | ${data?.name ?? title}`}</title>
        <PageHeadOg title={`Scannable | ${data?.name ?? title}`} />
      </Head>
      {!data?.id ? (
        <>
          <div className="sm:m-6 sm:mt-4">
            <div className="p-4 sm:p-0 mb-2 sm:m-0 sm:mb-4 bg-white sm:bg-transparent">
              <div className="relative w-full flex items-center sm:py-3">
                <div className="cursor-pointer flex items-center">
                  <Link href="/">
                    <Image
                      src="/static/scannable-dashboard-logo.svg"
                      alt="Scannable"
                      className="object-contain object-center"
                      width={150}
                      height={40}
                      priority
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden sm:rounded-xl bg-white shadow sm:m-6">
            <div className="px-4 pb-0 pt-5 sm:px-6">
              <div className="mb-4 py-16 text-lg font-semibold text-gray-900 text-center">
                No Group Found
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="sm:m-6 sm:mt-4">
          <div className="p-4 sm:p-0 sm:m-0 sm:mb-4 bg-white sm:bg-transparent">
            <div className="relative w-full flex items-center sm:py-3">
              <div className="cursor-pointer flex items-center">
                <Link href="/">
                  <Image
                    src="/static/scannable-dashboard-logo.svg"
                    alt="Scannable"
                    className="object-contain object-center"
                    width={150}
                    height={40}
                    priority
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="bg-gray-200 sm:hidden rounded-t-xl h-2" />
          </div>
          <div className="overflow-hidden sm:rounded-xl bg-white shadow">
            <div className="pb-0 pt-5 sm:px-6">
              <div className="px-4 sm:px-2 sm:pt-2 sm:pb-6 sm:flex sm:items-center border-b border-gray-200">
                <div className="flex flex-row">
                  <div className="flex justify-center items-center bg-gray-200 rounded-lg h-10 w-10 ">
                    <Icon name={icon} className="h-6 w-6" />
                  </div>
                  <div className="ml-4">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                      {data.name}
                    </h1>
                    <p className="text-sm text-gray-700">{title}</p>
                  </div>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
              </div>
            </div>
            {/* Large Screens */}
            <div className="px-6 hidden sm:block">
              <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-20 pr-3 text-left text-sm text-gray-500 font-medium"
                          >
                            Ser No / Batch No
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 text-left text-sm text-gray-500 font-medium"
                          >
                            Product / Variant Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm text-gray-500 font-medium"
                          >
                            Part
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm text-gray-500 font-medium"
                          >
                            Certifications
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm text-gray-500 font-medium"
                          >
                            Expiry
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm text-gray-500 font-medium"
                          >
                            DOM
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm text-gray-500 font-medium"
                          >
                            First Use
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm text-gray-500 font-medium"
                          >
                            Result
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm text-gray-500 font-medium"
                          >
                            Next Due
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {loading && (
                          <tr>
                            <td colSpan={9}>
                              <div className="flex flex-col items-center justify-center py-10 relative">
                                <LoadingIcon size="md" />
                              </div>
                            </td>
                          </tr>
                        )}
                        {!loading && !inventoryItems && (
                          <tr>
                            <td colSpan={9}>
                              <div className="flex flex-col items-center justify-center py-4 text-gray-500">
                                {t('no_data_found')}
                              </div>
                            </td>
                          </tr>
                        )}

                        {inventoryItems &&
                          inventoryItems.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                  <Link href={`/${item.uid}`}>
                                    <div className="flex flex-row items-center">
                                      <ThumbnailImage
                                        src={item.image?.uri || ''}
                                        alt={item.tierOne?.name || ''}
                                        showNfcIcon={Boolean(
                                          item.tierOne?.nfcTags?.length
                                        )}
                                      />
                                      <div className="ml-2 px-3 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                        <span className="text-sm text-blue-700 underline">
                                          #{item.tierOne?.serialNumber}
                                        </span>
                                        {item.tierOne?.batchNumber}
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-800 sm:pl-0">
                                  {item.tierOne?.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                                  {item.tierOne?.code}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                                  {item.data &&
                                    item.data.certifications?.map(
                                      (cert: { id: number; name: string }) => (
                                        <div key={cert.id}>{cert.name}</div>
                                      )
                                    )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                                  {item.tierOne?.expiryDate ? (
                                    dateFormat(item.tierOne.expiryDate)
                                  ) : (
                                    <span>--</span>
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                                  {item.dom ? (
                                    dateFormat(item.dom)
                                  ) : (
                                    <span>--</span>
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                                  {item.dofu ? (
                                    dateFormat(item.dofu)
                                  ) : (
                                    <span>--</span>
                                  )}
                                </td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-800">
                                  {item.tierOne?.lastInspection !== null && (
                                    <InspectionStatusBadge
                                      status={
                                        item.tierOne?.lastInspection?.status
                                      }
                                      expiryDate={item.tierOne?.expiryDate}
                                    />
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                                  {item.tierOne?.nextInspectionAt ? (
                                    dateFormat(item.tierOne.nextInspectionAt)
                                  ) : (
                                    <span>--</span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Small Screens */}
            <div className="py-5 sm:hidden">
              <div>
                <div className="flex flex-col">
                  {loading && (
                    <div className="flex flex-col items-center justify-center py-10 relative">
                      <LoadingIcon size="md" />
                    </div>
                  )}
                  {!loading && !inventoryItems && (
                    <div className="flex flex-col items-center justify-center py-4 text-gray-500">
                      {t('no_data_found')}
                    </div>
                  )}
                  {inventoryItems &&
                    inventoryItems.map((item) => {
                      return (
                        <div
                          className="flex flex-row mb-4 border-b border-gray-200 px-4 pb-4 last:border-b-0"
                          key={item.id}
                        >
                          <Link href={`/${item.uid}`}>
                            <div className="flex flex-row items-center">
                              <ThumbnailImage
                                src={item.image?.uri || ''}
                                alt={item.tierOne?.name || ''}
                                showNfcIcon={Boolean(
                                  item.tierOne?.nfcTags?.length
                                )}
                              />
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {item.tierOne?.name}
                                </div>
                                <div className="text-sm text-gray-500 py-1">
                                  {item.tierOne?.manufacturerName && (
                                    <span className="mr-2">
                                      {item.tierOne?.manufacturerName}
                                    </span>
                                  )}
                                  {item.tierOne?.code}
                                </div>
                                <div className="flex flex-row w-full justify-between">
                                  <div className="flex-col text-sm text-gray-900 font-medium underline flex-grow">
                                    #{item.tierOne?.serialNumber}
                                  </div>
                                  <div className="absolute right-4">
                                    {item.tierOne?.lastInspection !== null && (
                                      <InspectionStatusBadge
                                        status={
                                          item.tierOne?.lastInspection?.status
                                        }
                                        expiryDate={item.tierOne?.expiryDate}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div ref={loader} className="h-10"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default GroupedItemsPage;
