import { ReactNode, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DocumentTextIcon,
} from '@heroicons/react/20/solid';

import { fileUrl, InspectionFile } from '@scannable/common';
import { SortOrder } from '@scannable/frontend/common';
import { GET_INSPECTIONS_BY_SERIALISED_PRODUCT } from '@scannable/graphql-queries';

import { Button, Link, ResizedImage } from '../../atoms';
import InspectionStatusBadge from '../../molecules/InspectionStatusBadge/InspectionStatusBadge';
import { ModalFooter } from '../../molecules/ModalFooter/ModalFooter';
import UniqueUrlCarousel from '../../organisms/UniqueUrlCarousel/UniqueUrlCarousel';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export interface InventoryInspectionsModalProps {
  id: number;
  name?: string | null;
  code?: string | null;
}

export function InventoryInspectionsModal({
  id,
  name,
  code,
}: InventoryInspectionsModalProps) {
  const { hideModal } = useModal();
  const [index, setIndex] = useState(1);
  const handleIndexChange = (newIndex: number) => {
    setIndex(newIndex);
  };
  const { data } = useQuery(GET_INSPECTIONS_BY_SERIALISED_PRODUCT, {
    variables: {
      where: {
        serialisedProductId: {
          equals: id,
        },
      },
      orderBy: [
        {
          date: {
            sort: SortOrder.desc,
          },
        },
      ],
    },
    skip: !id,
  });

  const handleNext = () => {
    handleIndexChange(
      index === data?.inspections?.length ? data.inspections.length : index + 1
    );
  };
  const handlePrevious = () => {
    handleIndexChange(index === 1 ? 1 : index - 1);
  };
  const currentInspection = data?.inspections
    ? data.inspections[index - 1]
    : null;

  const images = currentInspection?.images as InspectionFile[];
  const imgSrc = images?.length ? images[0].s3Key : null;
  const pdfs = currentInspection?.pdfs as InspectionFile[];
  return (
    <Modal size="sm">
      <div className="flex-1 p-5">
        <div className="flex flex-row mb-6">
          <span className="mt-1">Inspection</span>
          <input
            name="index"
            value={index}
            className="w-11 text-center border border-gray-300 rounded-md mx-2 text-sm py-1"
            onChange={() => handleIndexChange}
          />
          <span className="mt-1 mx-2">of</span>
          <span className="mt-1">{data?.inspections?.length}</span>
          <span className="flex-grow"></span>
          <div className="flex-row ml-2">
            {index !== 1 && (
              <Button
                color="white"
                className="mr-2 px-2"
                onClick={handlePrevious}
              >
                <ArrowLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-500" />
              </Button>
            )}
            {index !== data?.inspections?.length && (
              <Button color="white" className="px-2" onClick={handleNext}>
                <ArrowRightIcon className="flex-shrink-0 h-5 w-5 text-gray-500" />
              </Button>
            )}
          </div>
        </div>
        {(name || code) && (
          <div>
            {name && (
              <p className="text-lg font-medium text-gray-900">{name}</p>
            )}
            {code && <p className="text-gray-500 text-sm">{code}</p>}
          </div>
        )}
        <div className="max-w-sm my-3">
          <InspectionStatusBadge status={currentInspection?.status} />
        </div>
        <div className="mb-10   flex flex-row">
          <div>
            {(currentInspection?.date as ReactNode) && (
              <p className="text-gray-500 text-sm mt-2">
                <span className="font-medium">Date: </span>{' '}
                {new Date(
                  JSON.stringify(currentInspection?.date).replace(/"/g, '')
                ).toDateString()}
              </p>
            )}
            {currentInspection?.nameOfInspector && (
              <p className="text-gray-500 text-sm mt-2">
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                <>
                  <span className="font-medium">Inspector: </span>{' '}
                  {currentInspection?.nameOfInspector}
                </>
              </p>
            )}

            {currentInspection?.notes && (
              <p className="text-gray-500 text-sm mt-2">
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                <>
                  <span className="font-medium">Notes: </span>{' '}
                  {currentInspection?.notes}
                </>
              </p>
            )}
            {pdfs && (
              <div className="text-gray-500 text-sm mt-2">
                <span className="font-medium">PDFs: </span>{' '}
                {pdfs.map((pdf) => (
                  <div key={pdf.internalId} className="py-2">
                    <Link
                      href={fileUrl(pdf.s3Key)}
                      external
                      className="bg-blue-100 text-grey-800 px-3 py-1 text-xs mb-1 rounded-full"
                    >
                      <DocumentTextIcon className="h-4 w-4 inline mr-1" />
                      <span className="inline">
                        {pdf.sourceName ?? pdf.value}
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {images && images?.length > 0 ? (
          <UniqueUrlCarousel
            images={images.map((image) => {
              return {
                id: image.internalId,
                uri: image.s3Key,
                name: image.sourceName,
              };
            })}
          />
        ) : (
          <ResizedImage
            src={imgSrc ?? ''}
            alt={name ?? ''}
            height={400}
            width={400}
            fit="fill"
            className="rounded-md object-contain object-center"
          />
        )}
      </div>
      <ModalFooter onClose={hideModal} />
    </Modal>
  );
}

export default InventoryInspectionsModal;
