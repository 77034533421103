import { useState } from 'react';
import * as Sentry from '@sentry/nextjs';

import { decodeJwt } from '@scannable/common';
import { refreshJwtToken } from '@scannable/frontend/apollo';

import { Button, ButtonProps } from '../../atoms';
import { config as authConfig } from '../../auth';
import { errorAlert } from '../../utils';

interface DownloadButtonProps extends ButtonProps {
  file: string;
  token: string;
  fileName?: string;
}

export function DownloadButton({
  file,
  token,
  children,
  fileName = 'file',
  ...buttonProps
}: DownloadButtonProps) {
  const [downloading, setDownloading] = useState(false);

  const downloadFile = async () => {
    try {
      let currentToken = token;
      setDownloading(true);
      const { exp } = decodeJwt(currentToken);
      if (exp * 1000 < Date.now()) {
        const { data, errors } = await refreshJwtToken(authConfig.endpoint);
        if (errors || !data?.refreshToken?.accessToken) {
          setDownloading(false);
          errorAlert('Failed to download file');
          return;
        }
        currentToken = data.refreshToken.accessToken;
      }
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${currentToken}`);

      const response = await fetch(file, { headers });
      if (!response.ok) {
        setDownloading(false);
        errorAlert('Failed to download file');
        return;
      }
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${fileName}_${new Date().toLocaleDateString()}`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      setDownloading(false);
    } catch (error) {
      Sentry.captureException(error);
      setDownloading(false);
      errorAlert('Failed to download file');
    }
  };

  return (
    <Button
      onClick={downloadFile}
      loading={downloading}
      disabled={downloading}
      type="button"
      color="info"
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

export default DownloadButton;
