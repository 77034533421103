import { useEffect, useState } from 'react';
import Link from 'next/link';
import router from 'next/router';
import { useMutation } from '@apollo/client';
import { isNumber } from '@sniptt/guards';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { SerialFormatOption, serialFormatParts } from '@scannable/common';
import { RequiredSerialFormatValues } from '@scannable/frontend/common';
import { clearSerialisationState, RootState } from '@scannable/frontend/store';
import {
  FormFieldOptionType,
  SerialisationFormValues,
} from '@scannable/frontend/types';
import { CREATE_SERIALISED_PRODUCTS } from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { useCustomerReferenceTag } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import { errorAlert } from '../../utils';
import { batchValidationSchema } from '../../validation/batch-validation.schema';
import Form from '../Form/Form';

export interface AssemblyDetailsFormProps {
  initialSerialFormatId: number;
  availableSerialFormats: SerialFormatOption[];
}
export function AssemblyDetailsForm({
  initialSerialFormatId,
  availableSerialFormats,
}: AssemblyDetailsFormProps) {
  const { manufacturerId, organisationId } = useAuth();
  const [createSerialisedProducts] = useMutation(CREATE_SERIALISED_PRODUCTS);
  const { skus } = useSelector((state: RootState) => ({
    ...state.serialisation,
  }));
  const { customerOptions } = useCustomerReferenceTag();
  const dispatch = useDispatch();
  const [selectedSerialFormatId, setSelectedSerialFormatId] = useState<number>(
    initialSerialFormatId
  );
  const formik = useFormik({
    initialValues: {
      hasCountryOfOrgin: false,
      hasPurchaseOrderNumber: false,
      hasCustomerReference: false,
      hasSalesOrderNumber: false,
      hasDom: false,
      hasBatch: false,
      purchaseOrderNumber: '',
      salesOrderNumber: '',
      customerReference: '',
      batchNumber: '',
      dom: new Date().toDateString(),
    },
    validationSchema: batchValidationSchema,
    onSubmit: async (
      values: SerialisationFormValues & RequiredSerialFormatValues
    ) => {
      if (skus.length === 0) {
        errorAlert('Please add some assemblies');
        return;
      }
      if (!selectedSerialFormatId) {
        errorAlert('Please select a serial format');
        return;
      }
      const result = await resolveMutation(
        createSerialisedProducts({
          variables: {
            data: {
              serialCreationData: skus.map((s) => ({
                serialQuantity: 1,
                productBatchNumber: s.productBatchNumber || '',
                productVariationId: s.productVariationId,
                componentSerials: s.componentSerials,
                productType: 'ASSEMBLY',
                productDom: s.productDom,
              })),
              serialFormatId: selectedSerialFormatId,
              customerReference: values.customerReference,
              organisationId,
              manufacturerId,
              purchaseOrderNumber: values.purchaseOrderNumber,
              salesOrderNumber: values.salesOrderNumber,
              batchNumber: values.batchNumber,
              dom: values.dom as string,
            },
          },
        }),
        {
          failureMessage: 'Failed to create',
          successMessage: 'Successfully created',
        }
      );
      if (result.ok) {
        dispatch(clearSerialisationState());
        router.push('/admin/serialisation');
      }
    },
  });

  useEffect(() => {
    if (selectedSerialFormatId) {
      const selectedSerialFormat = availableSerialFormats?.find(
        (f) => f.id === selectedSerialFormatId
      );
      const {
        hasPurchaseOrderNumber,
        hasSalesOrderNumber,
        hasCustomerReference,
        hasBatch,
        hasDom,
      } = serialFormatParts(selectedSerialFormat?.format ?? null);
      formik.setValues({
        ...formik.values,
        hasPurchaseOrderNumber,
        hasSalesOrderNumber,
        hasCustomerReference,
        hasBatch,
        hasDom,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSerialFormats, selectedSerialFormatId]);

  return (
    <Form formik={formik} data-cy="assembly-details">
      <div className="bg-white shadow rounded-lg p-6 mb-5">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col w-auto mb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              2. Add assembly details
            </h3>
            <p className="text-sm leading-5 font-normal text-gray-500">
              Add information to make it easier to find your assemblies later on
            </p>
          </div>
          <div className="flex flex-col w-auto md:ml-auto">
            {skus && (
              <div>
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <InputFieldGroup
                          className="text-sm"
                          name="customerReference"
                          type="createable-select"
                          label="Customer Reference"
                          formik={formik}
                          options={customerOptions}
                        />

                        <InputFieldGroup
                          className="border-gray-200 rounded text-sm w-full"
                          id="DateOfManufacture"
                          name="dom"
                          type="date"
                          formik={formik}
                          label="Manufacturer Date"
                        />
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <InputFieldGroup
                          id="PurchaseOrder"
                          name="purchaseOrderNumber"
                          type="text"
                          formik={formik}
                          label="Purchase Order"
                        />
                        <InputFieldGroup
                          id="SalesOrder"
                          name="salesOrderNumber"
                          type="text"
                          formik={formik}
                          label="Sales Order"
                        />
                        <InputFieldGroup
                          id="Batch"
                          name="batchNumber"
                          type="text"
                          formik={formik}
                          label="Batch Number"
                        />
                      </div>

                      <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <InputFieldGroup
                          name="serialFormatId"
                          type="select"
                          label="Serial Format"
                          onChange={(format: FormFieldOptionType) => {
                            if (isNumber(format.value)) {
                              setSelectedSerialFormatId(format.value);
                            }
                          }}
                          value={selectedSerialFormatId}
                          options={(availableSerialFormats || []).map((f) => ({
                            label: f.name ?? 'No name',
                            value: f.id,
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Link href="/admin/assemblies">
            <Button type="button" color="white" className="text-gray-500">
              Cancel
            </Button>
          </Link>
          <Button type="submit" className="ml-3" loading={formik.isSubmitting}>
            Create
          </Button>
        </div>
      </div>
    </Form>
  );
}
export default AssemblyDetailsForm;
