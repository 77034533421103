import {
  classNames,
  incrementPaddingValue,
  isDefined,
  SerialFormatComponentType,
  SerialFormatOption,
} from '@scannable/common';
import { testId } from '@scannable/frontend/common';

import { Badge, Text } from '../../atoms';

export interface SerialFormatDisplayProps {
  format: SerialFormatOption;
}

const FormatItem = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col items-center">{children}</div>
);

export function SerialFormatDisplay({ format }: SerialFormatDisplayProps) {
  const serialFormatComponents = (format.format || [])
    .filter(isDefined)
    .sort((a, b) => a.order - b.order);

  return (
    <div
      className={classNames(
        'flex',
        format.name ? 'justify-between' : 'justify-evenly'
      )}
    >
      {format.name && (
        <div className="flex items-center justify-center">
          <Text data-cy={testId(format.name, 'serial-format')}>
            {format.name}
          </Text>
        </div>
      )}
      {serialFormatComponents.map((part, index) => {
        switch (part.type) {
          case SerialFormatComponentType.prefix:
            return (
              <FormatItem key={index}>
                <Badge colour="green" size="lg">
                  Prefix
                </Badge>
                <Text>{part.value}</Text>
              </FormatItem>
            );
          case SerialFormatComponentType.dom:
            return (
              <FormatItem key={index}>
                <Badge colour="green" size="lg">
                  DOM
                </Badge>
                <Text>{part.value}</Text>
              </FormatItem>
            );
          case SerialFormatComponentType.batch:
            return (
              <FormatItem key={index}>
                <Badge colour="green" size="lg">
                  Batch
                </Badge>
              </FormatItem>
            );
          case SerialFormatComponentType.customerReference:
            return (
              <FormatItem key={index}>
                <Badge colour="green" size="lg">
                  Customer
                </Badge>
              </FormatItem>
            );
          case SerialFormatComponentType.salesOrderNumber:
            return (
              <FormatItem key={index}>
                <Badge colour="green" size="lg">
                  Sales order
                </Badge>
              </FormatItem>
            );
          case SerialFormatComponentType.purchaseOrderNumber:
            return (
              <FormatItem key={index}>
                <Badge colour="green" size="lg">
                  Purchase order
                </Badge>
              </FormatItem>
            );
          case SerialFormatComponentType.increments:
            return (
              <FormatItem key={index}>
                <Badge colour="green" size="lg">
                  Increments
                </Badge>
                <Text>{incrementPaddingValue(Number(part.value))}</Text>
              </FormatItem>
            );
          case SerialFormatComponentType.suffix:
            return (
              <FormatItem key={index}>
                <Badge colour="green" size="lg">
                  Suffix
                </Badge>
                <Text>{part.value}</Text>
              </FormatItem>
            );
          case SerialFormatComponentType.countryOfOrigin:
            return (
              <FormatItem key={index}>
                <Badge colour="green" size="lg">
                  Country
                </Badge>
                <Text>{part.value}</Text>
              </FormatItem>
            );
          default:
            return null;
        }
      })}
    </div>
  );
}

export default SerialFormatDisplay;
