import * as Sentry from '@sentry/nextjs';
import { Breadcrumb } from '@sentry/types';

export function addBreadcrumb(breadcrumb: Breadcrumb) {
  Sentry.addBreadcrumb(breadcrumb);
}

export function captureException(error: unknown) {
  Sentry.captureException(error);
}
