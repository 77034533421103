import { useMutation } from '@apollo/client';

import {
  UPDATE_ORGANISATION_USER,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useUpdateOrganisationUserMutation(
  options?: UseMutationOptions<typeof UPDATE_ORGANISATION_USER>
) {
  return useMutation(UPDATE_ORGANISATION_USER, options);
}
