export enum Inventory {
  PaginatedInventoryQuery = 'PaginatedInventoryQuery',
  PaginatedWebInventory = 'PaginatedWebInventory',
  AddToInventory = 'AddToInventory',
  CreateAndAddToInventory = 'CreateAndAddToInventory',
  AssignItemsToUser = 'AssignItemsToUser',
  AssignItemsToGroups = 'AssignItemsToGroups',
  AssignToUserByGroup = 'AssignToUserByGroup',
  AssignItemToUserMutation = 'AssignItemToUserMutation',
  RemoveItemFromInventory = 'RemoveItemFromInventory',
  RemoveItemsFromInventory = 'RemoveItemsFromInventory',
  UnassignItemFromUser = 'UnassignItemFromUser',
  IsItemInInventory = 'IsItemInInventory',
  PaginatedUserInventoryQuery = 'PaginatedUserInventoryQuery',
  ChangeItemSku = 'ChangeItemSku',
  RemoveItemNFC = 'RemoveItemNFC',
  PaginatedInventoryReportQuery = 'PaginatedInventoryReportQuery',
  AssignItemToMeMutation = 'AssignItemToMeMutation',
  UnAssignItemMutation = 'UnAssignItemMutation',
  CreateCheckOffMutation = 'CreateCheckOffMutation',
  PaginatedGroupUrlItems = 'PaginatedGroupUrlItems',
  AssignItemsToSku = 'AssignItemsToSku',
}

export enum NFCAlias {
  RemoveItemAliasMutation = 'RemoveItemAliasMutation',
  CreateItemAliasMutation = 'CreateItemAliasMutation',
  FindByNfcTagQuery = 'FindByNfcTagQuery',
  RemoveItemNFCTagsMutation = 'RemoveItemNFCTagsMutation',
}

export enum Inspection {
  CreateInspection = 'CreateInspection',
  CreateInspections = 'CreateInspections',
  CreateExaminationMutation = 'CreateExaminationMutation',
  UpdateExaminationMutation = 'UpdateExaminationMutation',
  GetExaminations = 'GetExaminations',
  FindActiveExaminationsQuery = 'FindActiveExaminationsQuery',
  ExaminationJobQuery = 'ExaminationJobQuery',
  GetInspection = 'GetInspection',
  PaginatedExaminationInspections = 'PaginatedExaminationInspections',
  InspectionsByItem = 'InspectionsByItem',
}

export enum Checklist {
  GetChecklistsForSerialisedProductQuery = 'GetChecklistsForSerialisedProductQuery',
  CopyChecklistMutation = 'CopyChecklistMutation',
  DeleteChecklistMutation = 'DeleteChecklistMutation',
  UpdateChecklistMutation = 'UpdateChecklistMutation',
  CreateChecklistMutation = 'CreateChecklistMutation',
  GetChecklistQuery = 'GetChecklistQuery',
  PaginatedChecklistsQuery = 'PaginatedChecklistsQuery',
}

export enum Groups {
  RemoveItemFromGroup = 'RemoveItemFromGroup',
  GetGroupsByType = 'GetGroupsByType',
  GetGroupsByTypeWithLastCheckOff = 'GetGroupsByTypeWithLastCheckOff',
  CreateGroup = 'CreateGroup',
  UpdateGroup = 'UpdateGroup',
  DeleteGroup = 'DeleteGroup',
  AddItemToGroup = 'AddItemToGroup',
  GetGroup = 'GetGroup',
  GetGroupsBySerialisedProductId = 'GetGroupsBySerialisedProductId',
  AssignItemToGroups = 'AssignItemToGroups',
  GroupByUid = 'GroupByUid',
  InventoryGroups = 'InventoryGroups',
  GetGroupWithItems = 'GetGroupWithItems',
  AddItemsToGroup = 'AddItemsToGroup',
  GetCustomerTags = 'GetCustomerTags',
  SearchGroups = 'SearchGroups',
  RemoveItemsFromGroup = 'RemoveItemsFromGroup',
}

export enum Teams {
  CreateTeam = 'CreateTeam',
  UpdateTeam = 'UpdateTeam',
  DeleteTeam = 'DeleteTeam',
  GetTeam = 'GetTeam',
  GetTeams = 'GetTeams',
  GetTeamInvetory = 'PaginatedInventoryQuery',
  GetTeamMembers = 'PaginatedTeamMembersQuery',
  RemoveTeamMember = 'RemoveTeamMember',
  CreateTeamMember = 'CreateTeamMember',
}

export enum Invites {
  GetInvitesByOrganisation = 'InvitesByOrganisation',
  GetInvite = 'GetInvite',
  Decline = 'Decline',
  AcceptInvitation = 'AcceptInvitation',
  SendInvites = 'SendInvites',
  DeleteInvitation = 'DeleteInvitation',
}

export enum SerialisedProduct {
  SerialisedProductForUniqueUrl = 'SerialisedProductForUniqueUrl',
  CreateSerialisedProductImages = 'CreateSerialisedProductImages',
  GenerateSerialPurchaseOrderCsv = 'GenerateSerialPurchaseOrderCsv',
  UpdateSerialisedProduct = 'UpdateSerialisedProduct',
  GetSerialisedProductForUpdateQuery = 'GetSerialisedProductForUpdateQuery',
  DeleteSerialisedProductImage = 'DeleteSerialisedProductImage',
  CreateSerialisedProducts = 'CreateSerialisedProducts',
  TestSerialisationConfiguration = 'TestSerialisationConfiguration',
  GetOneSerialisedProduct = 'GetOneSerialisedProduct',
  SerialisedProductInspections = 'SerialisedProductInspections',
  CreateSerialisedProduct = 'CreateSerialisedProduct',
  CaptureSerials = 'CaptureSerials',
  CreateSerialisedSplicedRopes = 'CreateSerialisedSplicedRopes',
}

export enum Printing {
  PrintAll = 'PrintAll',
  PrintFirst = 'PrintFirst',
  ClearPrinterQueue = 'ClearPrinterQueue',
  GetAllPrintersQuery = 'GetAllPrintersQuery',
  UpdatePrinters = 'UpdatePrinters',
  GetUserPrinters = 'GetUserPrinters',
  AssignPrinters = 'AssignPrinters',
  PrintOneSerial = 'PrintOneSerial',
  UpdateLabelTemplates = 'UpdateLabelTemplates',
  GetAllLabelTemplates = 'GetAllLabelTemplates',
  GetHardwareByType = 'GetHardwareByType',
  CreateHardware = 'CreateHardware',
  UpdateHardware = 'UpdateHardware',
  DeleteHardware = 'DeleteHardware',
}

export enum InspectionFrequency {
  FindManyAlertTemplates = 'FindManyAlertTemplates',
  FindOneAlertTemplate = 'FindOneAlertTemplate',
  CreateAlertTemplate = 'CreateAlertTemplate',
  UpdateAlertTemplate = 'UpdateAlertTemplate',
  DeleteAlertTemplate = 'DeleteAlertTemplate',
  AllInspectionFrequencies = 'AllInspectionFrequencies',
}

export enum Alerts {
  CreateAlerts = 'CreateAlerts',
}

export enum Organisation {
  GetUserOrganisations = 'GetUserOrganisations',
  UpdateOrganisation = 'UpdateOrganisation',
  OrganisationSubscriptionQuery = 'OrganisationSubscriptionQuery',
  CurrentAddressQuery = 'CurrentAddressQuery',
  UpdateAddress = 'UpdateAddress',
  UpdateAccount = 'UpdateAccount',
  GetOrganisationUsersQuery = 'GetOrganisationUsersQuery',
  GetOrganisationDetailQuery = 'GetOrganisationDetailQuery',
  MyAddressesQuery = 'MyAddressesQuery',
  OrganisationAdminDetailQuery = 'OrganisationAdminDetailQuery',
  GetOrganisation = 'GetOrganisation',
  AllOrganisations = 'AllOrganisations',
  CreateOrganisation = 'CreateOrganisation',
  AccountSubscriptionInfo = 'AccountSubscriptionInfo',
  UpdateAccountSubscription = 'UpdateAccountSubscription',
  CancelSubscription = 'CancelSubscription',
  InventoryCount = 'InventoryCount',
  ManufacturerStats = 'ManufacturerStats',
  EquipmentOwnerOverviewStats = 'EquipmentOwnerOverviewStats',
  ScannableStats = 'ScannableStats',
  ManufacturerProductStats = 'ManufacturerProductStats',
  GetUserOrganisation = 'GetUserOrganisation',
  AllOrganisationsNames = 'AllOrganisationsNames',
  OrganisationTeam = 'OrganisationTeam',
  GetCustomers = 'GetCustomers',
}

export enum NotificationPreference {
  UpdateOrgNotificationPreferences = 'UpdateOrgNotificationPreferences',
  GetOrgNotificationPreferences = 'GetOrgNotificationPreferences',
  UpdateUserNotificationPreferences = 'UpdateUserNotificationPreferences',
  GetUserNotificationPreferences = 'GetUserNotificationPreferences',
}

export enum Notification {
  PaginatedNotifications = 'PaginatedNotifications',
  GetNotificationCount = 'GetNotificationCount',
  NotificationSubscription = 'NotificationSubscription',
  MarkAllAsRead = 'MarkAllAsRead',
  LinkDeviceToken = 'LinkDeviceToken',
}

export enum OrganisationUsers {
  DeleteOrganisationUser = 'DeleteOrganisationUser',
  UpdateOrganisationUser = 'UpdateOrganisationUser',
  AssignSerialFormats = 'AssignSerialFormats',
  GetOrganisationUser = 'GetOrganisationUser',
}

export enum Auth {
  RefreshTokensWithOrganisation = 'RefreshTokensWithOrganisation',
  WebTokenRefresh = 'WebTokenRefresh',
  NativeTokenRefresh = 'NativeTokenRefresh',
}

export enum ApiKeys {
  GetApiKeys = 'GetApiKeys',
  PaginatedApiKeys = 'PaginatedApiKeys',
  CreateApiKey = 'CreateApiKey',
  DeleteApiKey = 'DeleteApiKey',
}

export enum Manufacturer {
  ManufacturerSettingsQuery = 'ManufacturerSettingsQuery',
  UpdateManufacturer = 'UpdateManufacturer',
  UploadManufacturerLogoMutation = 'UploadManufacturerLogoMutation',
  EditManufacturerQuery = 'EditManufacturerQuery',
  GetManufacturer = 'GetManufacturer',
  UpdateAccountManufacturer = 'UpdateAccountManufacturer',
  GetManufacturerOptions = 'GetManufacturerOptions',
  AllManufacturers = 'AllManufacturers',
  RemoveManufacturerLogo = 'RemoveManufacturerLogo',
  PaginatedManufacturersQuery = 'PaginatedManufacturersQuery',
  InventoryManufacturers = 'InventoryManufacturers',
  ManufacturerSerialFormats = 'ManufacturerSerialFormats',
  PaginatedSerialFormats = 'PaginatedSerialFormats',
}

export enum User {
  UpdateUser = 'UpdateUser',
  ChangeUserPassword = 'ChangeUserPassword',
  RemoveUserAvatar = 'RemoveUserAvatar',
  RemoveUserSignature = 'RemoveUserSignature',
  UserSignup = 'UserSignup',
  SendActivationEmail = 'SendActivationEmail',
  ActivateAccount = 'ActivateAccount',
  ImpersonateOrganisation = 'ImpersonateOrganisation',
  ChangeMyPassword = 'ChangeMyPassword',
  LogOut = 'LogOut',
  MeQuery = 'MeQuery',
  CreateUser = 'CreateUser',
  Login = 'Login',
  MyProfile = 'MyProfile',
  UserDataForUpdate = 'UserDataForUpdate',
  CreatePasswordReset = 'CreatePasswordReset',
  ResetPassword = 'ResetPassword',
  GetUsers = 'GetUsers',
  DeleteMe = 'DeleteMe',
}

export enum ProductVariation {
  CreateProductVariation = 'CreateProductVariation',
  DeleteProductVariation = 'DeleteProductVariation',
  ProductVariationsQuery = 'ProductVariationsQuery',
  AddCompositeChildProductVariation = 'AddCompositeChildProductVariation',
  PaginatedProductVariationsByProduct = 'PaginatedProductVariationsByProduct',
  ProductVariationsByManufacturer = 'ProductVariationsByManufacturer',
  PaginatedProductVariationsQuery = 'PaginatedProductVariationsQuery',
  ProductVariationAdmin = 'ProductVariationAdmin',
  ProductVariationManufacturersAdmin = 'ProductVariationManufacturersAdmin',
  ProductVariationSerialisedProducts = 'ProductVariationSerialisedProducts',
  MoveProductVariation = 'MoveProductVariation',
  SKUScreenData = 'SKUScreenData',
  UpdateProductVariation = 'UpdateProductVariation',
  AddSpliceQuery = 'AddSpliceQuery',
}

export enum Product {
  GetProductDataQuery = 'GetProductDataQuery',
  DeleteProduct = 'DeleteProduct',
  PaginatedProductsQuery = 'PaginatedProductsQuery',
  UpdateProduct = 'UpdateProduct',
  MoveAllProductsToGroup = 'MoveAllProductsToGroup',
  CreateProduct = 'CreateProduct',
  AllOfMyProducts = 'AllOfMyProducts',
}

export enum ItemProductData {
  UpdateMetadataCertification = 'UpdateMetadataCertification',
  CreateMetadataPhoto = 'CreateMetadataPhoto',
  CreateMetadataFile = 'CreateMetadataFile',
  DeleteMetadataFile = 'DeleteMetadataFile',
  UpdateProductData = 'UpdateProductData',
  ItemDataField = 'ItemDataField',
}

export enum ProductCategories {
  GetProductCategories = 'GetProductCategories',
  CreateProductGroupCategory = 'CreateProductGroupCategory',
  UpdateProductGroupCategory = 'UpdateProductGroupCategory',
  UpdateProductGroup = 'UpdateProductGroup',
  AddMetadataToGroup = 'AddMetadataToGroup',
  RemoveMetadataFromProductGroup = 'RemoveMetadataFromProductGroup',
  UpdateProductGroupMetadataOrder = 'UpdateProductGroupMetadataOrder',
  CreateProductGroup = 'CreateProductGroup',
  FindInventoryProductGroups = 'FindInventoryProductGroups',
  FindUserInventoryProductGroups = 'FindUserInventoryProductGroups',
  GetGroupsWithCategories = 'GetGroupsWithCategories',
  ProductGroupMetadata = 'ProductGroupMetadata',
  PaginatedProductCategories = 'PaginatedProductCategories',
  ProductGroupCategories = 'ProductGroupCategories',
  PaginatedProductGroupCategories = 'PaginatedProductGroupCategories',
  AllActiveCategories = 'AllActiveCategories',
  ManufacturerProductGroups = 'ManufacturerProductGroups',
  CategroiesByManufacturer = 'CategroiesByManufacturer',
}

export enum SerialPurchaseOrder {
  PaginatedSerialPurchaseOrdersQuery = 'PaginatedSerialPurchaseOrdersQuery',
  SerialPurchaseOrderCsvCreated = 'SerialPurchaseOrderCsvCreated',
  GetFirstSerialPrintData = 'GetFirstSerialPrintData',
  DeleteSerialPurchaseOrder = 'DeleteSerialPurchaseOrder',
}

export enum SerialFormat {
  FindOneSerialFormat = 'FindOneSerialFormat',
  DeleteSerialFormat = 'DeleteSerialFormat',
  CreateSerialFormat = 'CreateSerialFormat',
  UpdateSerialFormat = 'UpdateSerialFormat',
  GetManufacturersSerialFormats = 'GetManufacturersSerialFormats',
  GetUserSerialFormats = 'GetUserSerialFormats',
}

export enum Certification {
  FindAllCertifications = 'FindAllCertifications',
  PaginatedCertifications = 'PaginatedCertifications',
  GetCertification = 'GetCertification',
  UpdateCertification = 'UpdateCertification',
  CreateCertification = 'CreateCertification',
}

export enum Metadata {
  CreateMetadataCategory = 'CreateMetadataCategory',
  UpdateMetadataCategory = 'UpdateMetadataCategory',
  GetImportantMetadata = 'GetImportantMetadata',
  GetMetadataCategory = 'GetMetadataCategory',
  UpdateMetadata = 'UpdateMetadata',
  CreateMetadata = 'CreateMetadata',
  PaginatedMetadata = 'PaginatedMetadata',
  PaginatedMetadataCategories = 'PaginatedMetadataCategories',
  GetMetadataCategories = 'GetMetadataCategories',
}

export enum Invites {
  GetInvites = 'GetInvites',
}

export enum System {
  AppSystemStatus = 'AppSystemStatus',
}

export enum ItemHistory {
  PaginatedItemHistory = 'PaginatedItemHistory',
}
