import { en } from '../lang/en';
import { Join, LanguageMap, PathsToStringProps } from '../types/types';

export function translate(
  lang: LanguageMap,
  key: Join<PathsToStringProps<typeof en>>,
  replacement?: string
) {
  const translation = key.split('.').reduce((o: LanguageMap, i) => {
    if (!o[i]) {
      return '';
    }
    return o[i];
  }, lang);
  if (!translation) {
    if (replacement) {
      return replacement;
    }
    if (key.includes('.')) {
      const [ns, k] = key.split('.');
      return (en as LanguageMap)[ns][k];
    }
    return en[key as keyof typeof en];
  }
  return translation;
}
