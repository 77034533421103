import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';

import { classNames } from '@scannable/common';

export interface AccordionRowProps {
  title: string;
  expanded: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

export function AccordionRow({
  title,
  children,
  expanded,
  onClick,
}: AccordionRowProps) {
  return (
    <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
      <dt
        className={classNames(
          'relative cursor-pointer py-4 bg-white pl-4 border-b'
        )}
        onClick={onClick}
      >
        {title}
      </dt>
      <Transition
        as={Fragment}
        show={expanded}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 mx-h-full"
        leaveTo="opacity-0 mx-h-0"
      >
        <dd>
          <div className="bg-gray-200 p-5">{children}</div>
        </dd>
      </Transition>
    </div>
  );
}

export interface AccordionBlock {
  id: string | number;
  title: string;
  active: boolean;
  children: React.ReactNode;
  callback?: (i: AccordionBlock) => void;
}
interface AccordionProps {
  initialState: AccordionBlock[];
}
export function Accordion({ initialState }: AccordionProps) {
  const [list, setListState] = useState<AccordionBlock[]>(initialState);

  const toggle = (index: number) => () => {
    const newItems = [...list];
    newItems[index].active = !list[index].active;
    setListState(newItems);

    const callback = newItems[index].callback;
    if (callback && newItems[index].active) {
      callback(newItems[index]);
    }
  };

  return (
    <div>
      <dl>
        {list.map((item, index) => (
          <AccordionRow
            key={index}
            title={item.title}
            onClick={toggle(index)}
            expanded={item.active}
          >
            {item.children}
          </AccordionRow>
        ))}
      </dl>
    </div>
  );
}

export default Accordion;
