import { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { isString } from '@sniptt/guards';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { Search } from '@scannable/frontend/types';
import {
  CREATE_PRODUCT,
  GET_PRODUCT_CATEGORIES,
} from '@scannable/graphql-queries';

import { Divider, Text, Title } from '../../atoms';
import SidePanelForm from '../../forms/SidePanelForm/SidePanelForm';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldArray, InputFieldGroup } from '../../molecules';
import { AddComponentsInput } from '../../organisms';
import { useSidePanel } from '../SidePanelContext/SidePanelContext';

export const schema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  productGroupId: z.number().min(1, { message: 'A product group is required' }),
  variations: z
    .array(
      z.object({
        partNumber: z.string().min(1),
        name: z.string().optional(),
        ean: z.string().optional(),
      })
    )
    .nonempty('All variations must have a part number'),
});

export type VariationValue = {
  key?: number;
  partNumber?: string;
  name?: string;
  ean?: string;
};
export interface CreateProductFormValues {
  name: string;
  description: string;
  productGroupId?: number;
  isAssembly: boolean;
  variations: VariationValue[];
}

export interface CreateProductFormProps {
  manufacturerId: number;
  productGroupId?: number;
}

type CreateProductSidePanelProps = {
  productGroupId?: number;
  manufacturerId?: number;
};

export function CreateProductSidePanel({
  productGroupId,
  manufacturerId,
}: CreateProductSidePanelProps) {
  const [components, setComponents] = useState<Search[]>([]);
  const { hideSidePanel } = useSidePanel();
  const [createProduct] = useMutation(CREATE_PRODUCT, {
    refetchQueries: 'active',
  });

  const { data } = useQuery(GET_PRODUCT_CATEGORIES);

  const formik = useFormik<CreateProductFormValues>({
    initialValues: {
      name: '',
      description: '',
      productGroupId: undefined,
      isAssembly: false,
      variations: [
        {
          partNumber: '',
          name: '',
          ean: '',
        },
      ],
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values, actions) => {
      await resolveMutation(
        createProduct({
          variables: {
            data: {
              name: values.name,
              variations: values.variations
                .filter((v) => isString(v.partNumber))
                .map((v) => ({
                  partNumber: v.partNumber ?? '',
                  name: v.name,
                  ean: v.ean,
                })),
              manufacturerId,
              isAssembly: values.isAssembly,
              productGroupId: productGroupId ?? values.productGroupId ?? 0,
              components: components.map((c) => c.id),
            },
          },
        }),
        {
          successMessage: 'Product created',
        }
      );
      actions.resetForm();
      actions.setSubmitting(false);
      hideSidePanel();
    },
  });
  const addComponent = useCallback(
    (variant: Search) => {
      setComponents([...components, variant]);
    },
    [components]
  );
  const removeComponent = useCallback(
    (componentId: number) => {
      const newComponents = components.filter(
        (c) => c.productVariationId !== componentId
      );
      setComponents([...newComponents]);
    },
    [components]
  );
  const { t } = useTranslation();
  const options =
    data && data.productGroups
      ? data.productGroups
          .map((productGroup) => ({
            label: productGroup.name,
            value: productGroup.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];

  return (
    <SidePanelForm
      formik={formik}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      buttons={[
        {
          type: 'submit',
          loading: formik.isSubmitting,
          color: 'primary',
          label: 'Create Product',
        },
      ]}
    >
      {!productGroupId && (
        <InputFieldGroup
          type="react-select"
          name="productGroupId"
          data-cy="product-group-select"
          label={t('product_group')}
          tooltip={t('group_product_tip')}
          options={options}
          formik={formik}
        />
      )}
      <InputFieldGroup
        type="text"
        name="name"
        data-cy="product-name"
        label={t('products_name')}
        tooltip={t('product_name_tip')}
        formik={formik}
      />
      <Divider />
      <Title size="h3">Add Variants (SKUs)</Title>
      <InputFieldArray
        formik={formik}
        buttonLabel="SKU"
        name="variations"
        cols={1}
        options={[
          {
            type: 'text',
            name: 'partNumber',
            label: t('part_number'),
            tooltip: t('name_part_tip'),
            placeholder: t('part_number'),
          },
          {
            type: 'text',
            name: 'name',
            label: t('products_sku_name'),
            tooltip: t('sku_tip'),
            placeholder: t('products_sku_name'),
          },
          {
            type: 'text',
            name: 'ean',
            label: 'EAN / UPC / Barcode',
            tooltip: t('barcode_tip'),
            placeholder: 'EAN / UPC / Barcode',
          },
        ]}
      />
      <Divider />
      <div>
        <Title size="h3" className="">
          {t('add_components')}
        </Title>
        <Text className="mb-4">{t('below_components_will_be_added')}</Text>

        <InputFieldGroup
          containerClassName="mb-5"
          type="checkbox"
          name="isAssembly"
          label="Include other cut length of ropes in search?"
          formik={formik}
        />
        <AddComponentsInput
          existingComponents={components}
          showComposites={formik.values.isAssembly}
          addComponent={addComponent}
          removeComponent={removeComponent}
        />
      </div>
    </SidePanelForm>
  );
}

export default CreateProductSidePanel;
