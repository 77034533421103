import { Carousel } from 'react-responsive-carousel';

import { FeatureImage, MISSING_IMAGE } from '@scannable/common';

import { ResizedImage } from '../../atoms';

export interface UniqueUrlCarouselProps {
  images?: FeatureImage[] | null;
  name?: string | null;
}

export function UniqueUrlCarousel({
  images,
  name = '',
}: UniqueUrlCarouselProps) {
  return (
    <>
      <style>
        {`
        /* override carousel lib styles */
        .carousel-root {
          width: 100%;
        }
        .carousel {
          flex-shrink: inherit;
        }
        .carousel .control-dots .dot {
          background: #d1d5db;
          box-shadow: none;
          margin: 0 3px;
        }
        .carousel .carousel-status {
          text-shadow: none;
          color: #000000;
          background-color: #e5e7eb;
          border-radius: 7px;
          position: absolute;
          margin: 14px;
          font-size: 12px;
          font-weight: 500;
        }
    `}
      </style>
      <div className="flex w-3/4 justify-center m-auto mt-4">
        {images && images?.length > 0 ? (
          <Carousel
            swipeable
            emulateTouch
            showStatus={images.length > 1}
            showIndicators={images.length > 1}
            showArrows={false}
            showThumbs={false}
          >
            {images.map((image) => (
              <ResizedImage
                key={`${image.id}`}
                src={image.uri ?? ''}
                alt={image.name ?? name ?? ''}
                priority
                className="object-contain object-center max-h-64"
                width={410}
                height={410}
                fit="fill"
              />
            ))}
          </Carousel>
        ) : (
          <ResizedImage
            src={MISSING_IMAGE}
            alt={name ?? ''}
            height={200}
            width={200}
            fit="fill"
            className="rounded-md object-contain object-center"
          />
        )}
      </div>
    </>
  );
}

export default UniqueUrlCarousel;
