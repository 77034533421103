import { useState } from 'react';

export type FormMessage = {
  ok: boolean;
  messages: string[] | null;
  formMessage: string | null;
};
export interface UseFormSubmitMessage {
  formMessageState: FormMessage | null;
  setFormState: (s: FormMessage) => void;
}

export function useFormSubmitMessage(): UseFormSubmitMessage {
  const [formMessageState, setFormState] = useState<FormMessage | null>(null);

  return { formMessageState, setFormState };
}

export default useFormSubmitMessage;
