import {
  InspectionStatus,
  SubscriptionStatus,
  SubscriptionType,
} from '@prisma/client';

import { Role, ucFirst } from '@scannable/common';

import { featureFlagEnabled } from './feature-flag.utils';

export enum ProductType {
  COMPONENT = 'Component',
  ASSEMBLY = 'Assembly',
  COMPOSITE = 'Composite',
}

export enum RoleLabel {
  ADMIN = 'Manager',
  MEMBER = 'Member',
  SUPPLIER = 'Supplier',
  USER = 'User',
}
export const userRoleOptions = Object.keys(RoleLabel)
  .filter((r) => {
    if (r === 'USER' && !featureFlagEnabled('limitedUser')) {
      return false;
    }
    return true;
  })
  .map((key) => ({
    label:
      RoleLabel[key as keyof typeof RoleLabel] ??
      Role[key as keyof typeof Role],
    value: key,
  }));

export enum InspectionTypeLabel {
  GENERAL = 'Regular',
  THOROUGH = 'Thorough',
}

export const inspectionTypeOptions = Object.keys(InspectionTypeLabel).map(
  (key) => ({
    label: InspectionTypeLabel[key as keyof typeof InspectionTypeLabel],
    value: key,
  })
);

export enum OrganisationSubscriptionStatus {
  NONE = 'None',
  CANCEL_AT_PERIOD_END = 'Cancel at period end',
  CANCELLED = 'Cancelled',
  TRIAL = 'Trial',
  ACTIVE = 'Active',
}
export const organisationSubscriptionStatusOptions = Object.keys(
  OrganisationSubscriptionStatus
).map((key) => ({
  label:
    OrganisationSubscriptionStatus[
      key as keyof typeof OrganisationSubscriptionStatus
    ] ?? String(SubscriptionStatus[key as SubscriptionStatus]),
  value: key,
}));

export enum OrganisationType {
  ACCESS = 'Access',
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise',
  INSPECTOR = 'Inspector',
  INSPECTOR_CUSTOMER = 'Inspector Customer',
}

export const organisationTypeOptions = Object.keys(OrganisationType).map(
  (key) => ({
    label:
      OrganisationType[key as keyof typeof OrganisationType] ??
      String(SubscriptionType[key as SubscriptionType]),
    value: key,
  })
);

export enum ActionType {
  SingleItem = 'SingleItem',
  ListOfItems = 'ListOfItems',
}

export enum ExaminationStatusLabel {
  ACTIVE = 'In Progress',
  COMPLETE = 'Complete',
}

export enum PublishedStatusLabel {
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
}

export const publishedStatusOptions = Object.keys(PublishedStatusLabel).map(
  (key) => ({
    label: PublishedStatusLabel[key as keyof typeof PublishedStatusLabel],
    value: key,
  })
);

export enum ProductTypeLabel {
  COMPONENT = 'Component',
  ASSEMBLY = 'Assembly',
  COMPOSITE = 'Cut length of rope',
}

export const productTypeOptions = Object.keys(ProductTypeLabel).map((key) => ({
  label: ProductTypeLabel[key as keyof typeof ProductTypeLabel],
  value: key,
}));

export const inspectionOptions = Object.values(InspectionStatus)
  .map((status) => ({
    label: ucFirst(status.toLowerCase()),
    value: status,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));
