import { Auth } from '@scannable/common';

import { graphql } from '../graphql';

export const REFRESH_TOKENS_WITH_ORGANISATION = graphql(`
  mutation ${Auth.RefreshTokensWithOrganisation} ($organisationId: Int!) {
    refreshTokenWithCookie(data: { organisationId: $organisationId }) {
      accessToken
      refreshToken
    }
  }
`);

export const WEB_REFRESH_TOKENS = graphql(`
  mutation ${Auth.WebTokenRefresh} {
    refreshToken: refreshTokenWithCookie {
      accessToken
      refreshToken
    }
  }
`);

export const NATIVE_REFERESH_TOKENS = graphql(`
  mutation ${Auth.NativeTokenRefresh} ($refreshToken: String!, $organisationId: Int!) {
    refreshToken(token: $refreshToken, organisationId: $organisationId) {
      accessToken
      refreshToken
    }
  }
`);
