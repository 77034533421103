import { Inventory } from '@scannable/common';

import { graphql } from '../graphql';

export const CREATE_CHECK_OFF = graphql(`
    mutation ${Inventory.CreateCheckOffMutation}($data: CreateCheckOffInput!) {
        createCheckOff(data: $data) {
        id
        }
    }
`);
