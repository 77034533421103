import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { ADMIN_HOME_ROUTE } from '@scannable/common';

import { Button, Link, Text } from '../../atoms';
import { useModal } from '../../modals/ModalContext/ModalContext';
import ScannableModal from '../../modals/ScannableModal/ScannableModal';

export function useSubscriptionSuccess() {
  const { showModal, hideModal } = useModal();
  const [shown, setShown] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (router.query.success && !shown) {
      setShown(true);
      showModal(
        <ScannableModal
          title="Congratulations!"
          subTitle="Welcome to Scannable PRO"
        >
          <div className="text-center w-80 mx-auto">
            <Text>
              Get started by inviting some teammates{' '}
              <Link href="/admin/team" textLink onClick={() => hideModal()}>
                here &#8594;
              </Link>{' '}
              or start adding items to your inventory{' '}
              <Link
                href="/admin/inventory"
                textLink
                onClick={() => hideModal()}
              >
                here &#8594;
              </Link>
            </Text>
          </div>

          <div className="flex justify-center mt-8">
            <Button
              color="lime"
              className="mx-auto"
              onClick={() => hideModal()}
              data-cy="get-started"
              type="button"
            >
              Get Started &rarr;
            </Button>
          </div>
        </ScannableModal>
      );
      router.push(ADMIN_HOME_ROUTE);
    }
  }, [hideModal, router, router.query, showModal, shown]);
}

export default useSubscriptionSuccess;
