import toast, { ToastOptions } from 'react-hot-toast';

export const successAlert = (message: string, options?: ToastOptions) => {
  toast.success(
    message,
    options || {
      duration: 5000,
      position: 'bottom-left',
    }
  );
};

export const errorAlert = (message: string, options?: ToastOptions) => {
  toast.error(
    message,
    options || {
      duration: 5000,
      position: 'bottom-left',
    }
  );
};
