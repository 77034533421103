import { cn } from '@scannable/common';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: HTMLInputElement['name'];
}

export function Input({
  id,
  name,
  className,
  onChange,
  onBlur,
  value,
  ...props
}: InputProps) {
  return (
    <input
      id={id}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      className={cn(
        className
          ? className
          : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded'
      )}
      {...props}
    />
  );
}

export default Input;
