import Link from 'next/link';

import { cn } from '@scannable/common';

import { Icon, IconType, LoadingPlaceholder, ToolTip } from '../../atoms';
import UsagePercentageBar from '../UsagePercentageBar/UsagePercentageBar';

export interface DashboardStatProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  card?: { icon: any };
  value?: string | number;
  percent?: number;
  ctaHref?: string;
  ctaText?: string;
  tooltip?: string;
  showWarning?: boolean;
  icon?: IconType;
  testId?: string;
  isLoading?: boolean;
}

export function DashboardStat({
  name,
  card,
  value,
  percent,
  ctaHref,
  ctaText,
  tooltip,
  icon,
  testId,
  isLoading,
  showWarning = false,
}: DashboardStatProps) {
  return (
    <div className="bg-brand-secondary overflow-hidden mb-2">
      <div className="p-5 pb-2">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <div className="rounded-lg bg-brand-primary p-2 shadow-lg">
              {icon && (
                <Icon
                  name={icon}
                  className={cn(
                    'h-6 w-6',
                    !showWarning ? 'text-gray-800' : 'text-red-600'
                  )}
                  aria-hidden="true"
                />
              )}
              {card && (
                <card.icon
                  className={cn(
                    'h-6 w-6',
                    !showWarning ? 'text-gray-800' : 'text-red-600'
                  )}
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt
                className={cn(
                  'text-sm font-medium text-white truncate',
                  !showWarning ? 'text-white' : 'text-red-600'
                )}
              >
                {name}
                {tooltip && <ToolTip msg={tooltip} />}
              </dt>
              <dd>
                <div
                  className={cn(
                    'text-lg font-medium',
                    !showWarning ? 'text-white' : 'text-red-600'
                  )}
                >
                  {isLoading ? (
                    <LoadingPlaceholder
                      height="5"
                      width="12"
                      color="brand-grey"
                    />
                  ) : (
                    value
                  )}
                  {!isLoading && percent
                    ? ` (${percent > 100 ? '>100' : percent}%)`
                    : ''}
                  {Boolean(!isLoading && percent) && percent && (
                    <UsagePercentageBar percent={percent} />
                  )}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {ctaHref && (
        <div className="px-5 pb-3">
          <div className="text-sm">
            <Link
              href={ctaHref}
              data-cy={testId}
              passHref
              className={cn(
                'font-weight-500 underline',
                !showWarning
                  ? 'text-gray-400 hover:text-gray-600'
                  : 'text-red-600 hover:text-red-800'
              )}
            >
              {ctaText || 'View'} &#8594;
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
export default DashboardStat;
