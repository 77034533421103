import { addDays, addMonths } from 'date-fns';

import { Filter, FilterValue } from '@scannable/frontend/store';

export const nextInspectionFilterOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: 'Inspection is overdue',
    value: 'overdue',
  },
  {
    label: 'Inspection due in next 7 days',
    value: '7days',
  },
  {
    label: 'Inspection due in next month',
    value: 'month',
  },
  {
    label: 'Inspection due in next 3 months',
    value: '3month',
  },
];

export const onNextInspectionFilter = (
  option: FilterValue | FilterValue[]
): Filter => {
  const value = Array.isArray(option) ? option[0] : option;
  const today = new Date();
  let lte = today.toISOString();
  switch (value) {
    case 'overdue':
      lte = today.toISOString();
      break;
    case '7days':
      lte = addDays(today, 7).toISOString();
      break;
    case 'month':
      lte = addMonths(today, 1).toISOString();
      break;
    case '3month':
      lte = addMonths(today, 3).toISOString();
      break;
    default:
      break;
  }

  return {
    nextInspectionAt: {
      lte,
    },
  };
};
