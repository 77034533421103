import { useManufacturerProducts } from '../../hooks';

const filter = {
  productId: {
    in: '{VAL}',
  },
};

export function useVariantProductFilter(manufacturerId?: number) {
  const { options } = useManufacturerProducts({
    manufacturerId,
  });

  return {
    options,
    interpolatedFilter: filter,
  };
}
