import { useState } from 'react';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  WrenchIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { CriteriaResult } from '@prisma/client';
import { FormikErrors } from 'formik';

import { cn } from '@scannable/common';
import { getCriteriaResultColor } from '@scannable/frontend/common';

import { Button } from '../../atoms';
import { InputFieldGroup } from '../../molecules';
import { InspectionChecklistCriteria } from './InspectionSidePanel.types';

type InspectionChecklistInputProps = {
  checklistCriteria?: InspectionChecklistCriteria[] | null;
  criteriaValue?: Record<string, CriteriaResult>;
  setCriteria?: (id: string, result: CriteriaResult) => void;

  errors?: FormikErrors<Record<string, CriteriaResult>>;
  submitted?: boolean;
};
export function InspectionChecklistInput({
  criteriaValue,
  checklistCriteria,
  setCriteria,
  errors,
  submitted,
}: InspectionChecklistInputProps) {
  const [shownDescriptions, setShownDescriptions] = useState(new Set());
  const toggleDescription = (id: string) => {
    setShownDescriptions((prev) => {
      const newShownDescriptions = new Set(prev);
      if (newShownDescriptions.has(id)) {
        newShownDescriptions.delete(id);
      } else {
        newShownDescriptions.add(id);
      }
      return newShownDescriptions;
    });
  };

  return (
    <div className="border border-gray-200 rounded-lg p-4 pt-0 mt-4">
      <div className="mt-2 pb-2 pt-2 pr-2 flex  space-x-auto text-xs sticky top-0 bg-white w-full border-b border-gray-200 z-10">
        <div className="flex flex-col justify-center items-center space-y-4 w-[20%]">
          <CheckCircleIcon className="h-6 w-6" />
          Pass
        </div>
        <div className="flex flex-col justify-center items-center space-y-4 w-[20%]">
          <MagnifyingGlassIcon className="h-6 w-6" />
          Monitor
        </div>
        <div className="flex flex-col justify-center items-center space-y-4 w-[20%]">
          <WrenchIcon className="h-6 w-6" />
          Repair
        </div>
        <div className="flex flex-col justify-center items-center space-y-4 w-[20%]">
          <XMarkIcon className="h-6 w-6" />
          Fail
        </div>
        <div className="flex flex-col justify-center items-center text-gray-500 text-lg space-y-4 w-[20%]">
          NA
        </div>
      </div>
      {checklistCriteria?.map(
        (criteria: InspectionChecklistCriteria, index: number) => (
          <div
            key={criteria.id}
            className={cn(
              'mt-3  pb-4',
              checklistCriteria?.length &&
                index !== checklistCriteria?.length - 1
                ? 'border-b border-gray-200'
                : ''
            )}
          >
            <div className="flex flex-row justify-between pb-1">
              <div className="text-sm font-medium">{criteria.title}</div>
              {criteria.description && (
                <div className="text-xs text-gray-500">
                  <Button
                    type="button"
                    className="pl-1 pr-1 pt-1 pb-1"
                    color="info"
                    onClick={() => toggleDescription(criteria.id)}
                  >
                    {shownDescriptions.has(criteria.id) ? (
                      <ChevronUpIcon className="h-3 w-3" />
                    ) : (
                      <ChevronDownIcon className="h-3 w-3" />
                    )}
                  </Button>
                </div>
              )}
            </div>
            {shownDescriptions.has(criteria.id) && (
              <div className="text-sm text-gray-500 pb-2 whitespace-pre-line">
                {criteria.description}
              </div>
            )}
            <InputFieldGroup
              id={criteria.id}
              name={`criteria.${criteria.id}`}
              type="radio"
              inline
              onChange={(v) => {
                setCriteria?.(
                  criteria.id,
                  v.currentTarget.value as CriteriaResult
                );
              }}
              error={
                errors?.[criteria.id] ? 'This criteria is required' : undefined
              }
              touched={submitted}
              value={criteriaValue?.[criteria.id]}
              cols={5}
              options={[
                {
                  value: CriteriaResult.PASSED,
                  label: '',
                  color: getCriteriaResultColor(CriteriaResult.PASSED),
                },
                {
                  value: CriteriaResult.MONITORING,
                  label: '',
                  color: getCriteriaResultColor(CriteriaResult.MONITORING),
                },
                {
                  value: CriteriaResult.REPAIR,
                  label: '',
                  color: getCriteriaResultColor(CriteriaResult.REPAIR),
                },
                {
                  value: CriteriaResult.FAILED,
                  label: '',
                  color: getCriteriaResultColor(CriteriaResult.FAILED),
                },
                {
                  value: CriteriaResult.NOT_APPLICABLE,
                  label: '',
                  color: getCriteriaResultColor(CriteriaResult.NOT_APPLICABLE),
                },
              ]}
            />
          </div>
        )
      )}
      {/* Select All */}
      <div className="border-t border-gray-200 pt-2">
        <InputFieldGroup
          id="selectAll"
          name="selectAll"
          label="Select All"
          type="radio"
          onChange={(v) => {
            checklistCriteria?.forEach((criteria) => {
              setCriteria?.(
                criteria.id,
                v.currentTarget.value as CriteriaResult
              );
            });
          }}
          value={
            criteriaValue &&
            Object.keys(criteriaValue).every(
              (key: string) =>
                criteriaValue[key] ===
                criteriaValue[Object.keys(criteriaValue)[0]]
            )
              ? criteriaValue[Object.keys(criteriaValue)[0]]
              : undefined
          }
          inline={true}
          cols={5}
          options={[
            {
              value: CriteriaResult.PASSED,
              label: '',
              color: getCriteriaResultColor(CriteriaResult.PASSED),
            },
            {
              value: CriteriaResult.MONITORING,
              label: '',
              color: getCriteriaResultColor(CriteriaResult.MONITORING),
            },
            {
              value: CriteriaResult.REPAIR,
              label: '',
              color: getCriteriaResultColor(CriteriaResult.REPAIR),
            },
            {
              value: CriteriaResult.FAILED,
              label: '',
              color: getCriteriaResultColor(CriteriaResult.FAILED),
            },
            {
              value: CriteriaResult.NOT_APPLICABLE,
              label: '',
              color: getCriteriaResultColor(CriteriaResult.NOT_APPLICABLE),
            },
          ]}
        />
      </div>
    </div>
  );
}
