type SidePanelButtonContainerProps = {
  children: React.ReactNode;
};
export function SidePanelButtonContainer({
  children,
}: SidePanelButtonContainerProps) {
  return (
    <div className="border-t bg-white sticky bottom-0 px-4 py-2 mt-4 z-1">
      {children}
    </div>
  );
}

export default SidePanelButtonContainer;
