import { SerialFormat } from '@scannable/common';

import { graphql } from '../graphql';

export const DELETE_SERIAL_FORMAT = graphql(`
  mutation ${SerialFormat.DeleteSerialFormat} ($data: DeleteSerialFormatInput!) {
    deleteSerialFormat(data: $data) {
      id
    }
  }
`);
export const CREATE_MANUFACTURERS_SERIAL_FORMAT = graphql(`
  mutation ${SerialFormat.CreateSerialFormat} (
    $data: CreateSerialFormatInput!
  ) {
    createSerialFormat(data: $data) {
      id
    }
  }
`);

export const UPDATE_SERIAL_FORMAT = graphql(`
  mutation ${SerialFormat.UpdateSerialFormat} (
    $data: UpdateSerialFormatInput!
  ) {
    updateSerialFormat(data: $data) {
      id
    }
  }
`);
