import { ProductVariation } from '@scannable/common';

import { graphql } from '../graphql';

export const ADD_COMPOSITE_CHILD_PRODUCT_VARIATION = graphql(`
  mutation ${ProductVariation.AddCompositeChildProductVariation}(
    $data: CreateChildProductVariationInput!
  ) {
    addChildProductVariation(data: $data) {
      id
    }
  }
`);

export const REMOVE_COMPOSITE_CHILD_PRODUCT_VARIATION = graphql(`
  mutation ${ProductVariation.AddCompositeChildProductVariation}(
    $data: DeleteChildProductVariationInput!
  ) {
    removeChildProductVariation(data: $data) {
      id
    }
  }
`);

export const CREATE_PRODUCT_VARIATION = graphql(`
  mutation ${ProductVariation.CreateProductVariation}($data: CreateProductVariationInput!) {
    createProductVariation(data: $data) {
      id
    }
  }`);

export const UPDATE_PRODUCT_VARIATION = graphql(`
  mutation ${ProductVariation.UpdateProductVariation}($data: UpdateProductVariationInput!) {
    updateProductVariation(data: $data) {
      id
    }
  }`);

export const DELETE_PRODUCT_VARIATION = graphql(`
  mutation ${ProductVariation.DeleteProductVariation}($data: DeleteProductVariationInput!) {
    deleteProductVariation(data: $data) {
      id
    }
  }`);
