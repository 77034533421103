export interface SquircleProps {
  children: React.ReactNode;
  width: number;
}

export function Squircle({ children, width }: SquircleProps) {
  let strokeWidth = 2;
  if (width > 150) {
    strokeWidth = 1;
  }
  if (width > 260) {
    strokeWidth = 0.5;
  }
  return (
    <div
      style={{
        height: width,
        width: width,
      }}
    >
      <div className="relative flex justify-center items-center">
        <div className="w-full h-full">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 86 86"
          >
            <path
              d="M43 84.5c-9.687 0-16.815-.003-22.32-.68-5.469-.674-9.131-1.995-11.99-4.486a20.99 20.99 0 0 1-2.024-2.025c-2.491-2.858-3.812-6.52-4.485-11.99C1.503 59.816 1.5 52.688 1.5 43s.003-16.815.68-22.32c.674-5.469 1.995-9.131 4.486-11.99a20.978 20.978 0 0 1 2.025-2.024c2.857-2.491 6.52-3.812 11.99-4.485C26.184 1.503 33.312 1.5 43 1.5s16.815.003 22.32.68c5.469.674 9.132 1.995 11.99 4.486a20.99 20.99 0 0 1 2.024 2.025c2.491 2.857 3.812 6.52 4.485 11.99.678 5.504.681 12.632.681 22.319s-.003 16.815-.68 22.32c-.674 5.469-1.995 9.132-4.486 11.99a21.003 21.003 0 0 1-2.025 2.024c-2.858 2.491-6.52 3.812-11.99 4.485-5.504.678-12.632.681-22.319.681Z"
              stroke="#E5E7EB"
              strokeWidth={strokeWidth}
            />
          </svg>
        </div>
        <div className="absolute z-10">{children}</div>
      </div>
    </div>
  );
}

export default Squircle;
