import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ChecklistType, PublishedStatus } from '@prisma/client';
import { FormikHelpers, useFormik } from 'formik';

import { Checklist, ChecklistAppliesTo } from '@scannable/common';
import { SkuPreview } from '@scannable/frontend/common';
import {
  CREATE_CHECKLIST,
  GET_CHECKLIST,
  GET_PRODUCT_CATEGORIES,
  SKU_SCREEN_DATA,
  UPDATE_CHECKLIST,
} from '@scannable/graphql-queries';

import ResizedImage from '../../atoms/ResizedImage/ResizedImage';
import { SidePanelForm } from '../../forms';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldArray, InputFieldGroup, ItemInfo } from '../../molecules';
import SearchInput from '../../organisms/SearchInput/SearchInput';
import { useSidePanel } from '../SidePanelContext/SidePanelContext';
import { withSidePanelLoading } from '../withSidePanelLoading/withSidePanelLoading';
import {
  ChecklistSidePanelProps,
  ChecklistValues,
  EditChecklistSidePanelProps,
  validationSchema,
} from './ChecklistSidePanel.types';

function SidePanel({
  id: checklistId,
  isManufacturer,
  name,
  checklistCriteria,
  productVariationId,
  default: isDefault,
  published,
  productGroupId,
  appliesTo,
}: ChecklistSidePanelProps) {
  const { t } = useTranslation();
  const { hideSidePanel } = useSidePanel();
  const [createChecklist] = useMutation(CREATE_CHECKLIST, {
    refetchQueries: [Checklist.PaginatedChecklistsQuery],
  });
  const [updateChecklist] = useMutation(UPDATE_CHECKLIST, {
    refetchQueries: [
      Checklist.PaginatedChecklistsQuery,
      Checklist.GetChecklistQuery,
    ],
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: name ?? '',
      checklistCriteria: checklistCriteria ?? [
        {
          title: '',
          description: '',
          order: 0,
        },
      ],
      productVariationId: productVariationId ?? null,
      default: isDefault ?? false,
      published: published || PublishedStatus.PUBLISHED,
      productGroupId: productGroupId ?? null,
      appliesTo:
        appliesTo ?? isManufacturer
          ? ChecklistAppliesTo.PRODUCT_GROUP
          : ChecklistAppliesTo.ALL_ITEMS,
    } as ChecklistValues,
    onSubmit: async (
      values: ChecklistValues,
      actions: FormikHelpers<ChecklistValues>
    ) => {
      if (checklistId) {
        await resolveMutation(
          updateChecklist({
            variables: {
              data: {
                publicId: checklistId,
                name: values.name,
                type: isManufacturer
                  ? ChecklistType.MANUFACTURER
                  : ChecklistType.EQUIPMENT_OWNER,
                default: values.default,
                published: values.published
                  ? PublishedStatus.PUBLISHED
                  : PublishedStatus.UNPUBLISHED,
                checklistCriteria: values.checklistCriteria.map(
                  (criteria, index) => ({
                    publicId: criteria.id,
                    title: criteria.title,
                    description: criteria.description,
                    order: index,
                  })
                ),
                productVariationId:
                  values.appliesTo === ChecklistAppliesTo.SKU
                    ? values.productVariationId ?? null
                    : null,
                productGroupId:
                  values.appliesTo === ChecklistAppliesTo.PRODUCT_GROUP
                    ? values.productGroupId ?? null
                    : null,
              },
            },
          }),
          {
            successMessage: 'Updated Checklist',
          }
        );
        actions.setSubmitting(false);
        return;
      }
      const createResult = await resolveMutation(
        createChecklist({
          variables: {
            data: {
              name: values.name,
              type: isManufacturer
                ? ChecklistType.MANUFACTURER
                : ChecklistType.EQUIPMENT_OWNER,
              default: values.default,
              published: values.published
                ? PublishedStatus.PUBLISHED
                : PublishedStatus.UNPUBLISHED,
              checklistCriteria: values.checklistCriteria.map(
                (criteria, index) => ({
                  title: criteria.title,
                  description: criteria.description,
                  order: index,
                })
              ),
              productVariationId:
                values.appliesTo === ChecklistAppliesTo.SKU
                  ? values.productVariationId ?? null
                  : null,
              productGroupId:
                values.appliesTo === ChecklistAppliesTo.PRODUCT_GROUP
                  ? values.productGroupId ?? null
                  : null,
            },
          },
          refetchQueries: [
            Checklist.PaginatedChecklistsQuery,
            Checklist.GetChecklistsForSerialisedProductQuery,
          ],
        }),
        {
          successMessage: 'Created Checklist',
        }
      );

      actions.setSubmitting(false);
      if (createResult && createResult.ok) {
        hideSidePanel();
      }
    },
  });

  const [result, setResult] = useState<SkuPreview | null>(null);

  const setProductVariation = (productVariation: SkuPreview | null) => {
    formik.setFieldValue(
      'productVariationId',
      productVariation ? productVariation.id : null
    );
    setResult(productVariation);
  };

  const { data: productGroupData } = useQuery(GET_PRODUCT_CATEGORIES);

  const productGroups = (productGroupData?.productGroups ?? []).map(
    (productGroup) => {
      const result = {
        value: productGroup.id,
        label: productGroup.name,
      };
      return result;
    }
  );

  const { data: skuData } = useQuery(SKU_SCREEN_DATA, {
    variables: {
      id: formik.values.productVariationId || 0,
    },
    skip: !formik.values.productVariationId,
  });

  useEffect(() => {
    if (skuData?.productVariation) {
      setResult(skuData.productVariation);
    }
  }, [formik, skuData]);

  return (
    <SidePanelForm
      formik={formik}
      buttons={[
        {
          type: 'submit',
          loading: formik.isSubmitting,
          color: 'primary',
          label: !checklistId ? t('create_checklist') : t('update_checklist'),
        },
      ]}
    >
      <InputFieldGroup
        name="name"
        type="text"
        formik={formik}
        label="Checklist Name"
        value={formik.values.name}
      />
      {!isManufacturer && (
        <InputFieldGroup
          name="appliesTo"
          type="select"
          formik={formik}
          label="Applies to"
          tooltip="Select the type of items this checklist will be used to inspect."
          options={[
            { label: 'Any item', value: ChecklistAppliesTo.ALL_ITEMS },
            {
              label: 'Product Category',
              value: ChecklistAppliesTo.PRODUCT_GROUP,
            },
            { label: 'Single SKU', value: ChecklistAppliesTo.SKU },
          ]}
        />
      )}
      {formik.values.appliesTo === ChecklistAppliesTo.SKU && (
        <>
          {!result && (
            <SearchInput
              name="productVariationId"
              showScannerInput={false}
              addToCallback={(result) => {
                setProductVariation(result as SkuPreview);
              }}
              resetAfterCallback
            />
          )}
          {result && (
            <>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    setProductVariation(null);
                  }}
                  className="text-xs text-indigo-600 hover:text-gray-800"
                >
                  Change
                </button>
              </div>
              <div className="flex flex-row border-b border-gray-200 cursor-pointer pb-4">
                <div className="h-12 relative">
                  <ResizedImage
                    className="object-contain object-center rounded-md border border-gray-200"
                    src={result.image?.uri || ''}
                    alt={result.info?.name || ''}
                    height={48}
                    width={48}
                    fit="fill"
                  />
                </div>
                <div className="ml-4 flex flex-1 flex-col mr-5">
                  <ItemInfo
                    name={result?.info?.name}
                    manufacturerName={result.info?.manufacturerName}
                    code={result.info?.code}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {formik.values.appliesTo === ChecklistAppliesTo.PRODUCT_GROUP && (
        <InputFieldGroup
          name="productGroupId"
          type="select"
          formik={formik}
          label="Product Category"
          options={productGroups}
        />
      )}
      <div className="border-b border-gray-200 mb-4">
        <div className="flex flex-row justify-between pb-4">
          <div>
            <div className="text-gray-700 font-medium text-sm">
              Checklist Default
            </div>
            <div className="text-sm text-gray-500">
              {`Should this checklist be pre-selected when inspecting ${
                formik.values.appliesTo === ChecklistAppliesTo.PRODUCT_GROUP
                  ? 'items from this category'
                  : ''
              }${
                formik.values.appliesTo === ChecklistAppliesTo.SKU
                  ? 'items of this SKU'
                  : ''
              }${
                formik.values.appliesTo === ChecklistAppliesTo.ALL_ITEMS
                  ? 'any item'
                  : ''
              }?`}
            </div>
          </div>
          <InputFieldGroup name="default" type="checkbox" formik={formik} />
        </div>

        {isManufacturer && (
          <div className="flex flex-row justify-between mb-4">
            <div>
              <div className="text-gray-700 font-medium text-sm">Published</div>
            </div>

            <InputFieldGroup
              name="published"
              type="checkbox"
              formik={formik}
              value={formik.values.published}
            />
          </div>
        )}
      </div>

      <InputFieldArray
        formik={formik}
        group
        buttonLabel="Checklist Step"
        name="checklistCriteria"
        cols={1}
        sortable={true}
        options={[
          {
            type: 'text',
            name: 'title',
            placeholder: t('title'),
            value: '',
          },
          {
            type: 'textarea',
            name: 'description',
            placeholder: t('description'),
            value: '',
          },
          {
            type: 'hidden',
            name: 'order',
            value: '',
          },
          {
            type: 'hidden',
            name: 'id',
            value: '',
          },
        ]}
      />
    </SidePanelForm>
  );
}

const ChecklistSidePanel = withSidePanelLoading(SidePanel);

export function EditChecklistSidePanel({
  id: checklistId,
  isManufacturer,
}: EditChecklistSidePanelProps) {
  const {
    data: checklistData,
    loading,
    error,
  } = useQuery(GET_CHECKLIST, {
    variables: {
      id: checklistId ?? '',
    },
    skip: !checklistId,
    fetchPolicy: 'cache-and-network',
  });
  const appliesTo: ChecklistAppliesTo = useMemo(() => {
    if (isManufacturer) {
      return ChecklistAppliesTo.PRODUCT_GROUP;
    }
    if (checklistData?.getChecklist?.productVariationId) {
      return ChecklistAppliesTo.SKU;
    }
    if (checklistData?.getChecklist?.productGroupId) {
      return ChecklistAppliesTo.PRODUCT_GROUP;
    }
    return ChecklistAppliesTo.ALL_ITEMS;
  }, [checklistData, isManufacturer]);

  return (
    <ChecklistSidePanel
      id={checklistId}
      isManufacturer={isManufacturer}
      name={checklistData?.getChecklist?.name}
      checklistCriteria={checklistData?.getChecklist?.checklistCriteria}
      productVariationId={checklistData?.getChecklist?.productVariationId}
      default={checklistData?.getChecklist?.default}
      published={checklistData?.getChecklist?.published}
      productGroupId={checklistData?.getChecklist?.productGroupId}
      appliesTo={appliesTo}
      loading={loading}
      error={error}
    />
  );
}

export function CreateChecklistSidePanel(props: ChecklistSidePanelProps) {
  return <ChecklistSidePanel {...props} />;
}
