import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/nextjs';
import { Barcode, ScanSettings } from 'scandit-sdk';

import { Button, LoadingIcon } from '../../atoms';

const ScanditBarcodeScanner = dynamic(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => import('scandit-sdk-react' as any),
  {
    ssr: false,
  }
);

const licenseKey = process.env.NEXT_PUBLIC_SCANDIT_LICENCE_KEY;
type BarcodeScan = { barcodes: { data: string }[] };

export const ScanditScanner = ({
  visible,
  setVisible,
  onScan,
  onScanError,
}: {
  visible: boolean;
  setVisible: (v: boolean) => void;
  onScan: (v: BarcodeScan) => void;
  onScanError?: (v: unknown) => void;
}) => {
  const [symbologies, setSymbologies] = useState<Barcode.Symbology[]>([]);
  const [scanditSettings, setScanditSettings] = useState<null | ScanSettings>(
    null
  );
  const [isColorInverted, setIsColorInverted] = useState(false);
  const [pause, setPause] = useState(true);

  const handleColorInversionChange = async () => {
    // Check if we are on the client side
    if (typeof window === 'undefined' || !scanditSettings) {
      return;
    }

    const inverted = !isColorInverted;

    // Create new settings based on existing settings
    const ScanditSDKImport = await import('scandit-sdk');
    const updatedSettings = new ScanditSDKImport.ScanSettings({
      enabledSymbologies: symbologies,
    });

    // Update the color inversion for the new settings
    for (const symbology of symbologies) {
      const qr = updatedSettings.getSymbologySettings(symbology);
      qr.setColorInvertedEnabled(inverted);
    }

    // Update the state
    setIsColorInverted(inverted);
    setScanditSettings(updatedSettings);
  };

  const dynamicallyImportPackage = async () => {
    const ScanditSDKImport = await import('scandit-sdk');

    const scanSymbology = [
      ScanditSDKImport.Barcode.Symbology.EAN8,
      ScanditSDKImport.Barcode.Symbology.EAN13,
      ScanditSDKImport.Barcode.Symbology.UPCA,
      ScanditSDKImport.Barcode.Symbology.UPCE,
      ScanditSDKImport.Barcode.Symbology.QR,
      ScanditSDKImport.Barcode.Symbology.DATA_MATRIX,
    ];

    const settings = new ScanditSDKImport.ScanSettings({
      enabledSymbologies: scanSymbology,
    });
    // Update the color inversion for the new settings
    for (const symbology of symbologies) {
      const qr = settings.getSymbologySettings(symbology);
      qr.setColorInvertedEnabled(false);
    }
    setSymbologies(scanSymbology);
    setScanditSettings(settings);
  };

  useEffect(() => {
    dynamicallyImportPackage();
    setPause(!visible);

    return () => {
      setPause(true);
      setVisible(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVisible, visible]);

  if (!scanditSettings) {
    return (
      <div className="flex justify-center" data-testid="scandit-loading">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div>
      <ScanditBarcodeScanner
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        licenseKey={licenseKey}
        engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build"
        onScan={(v: BarcodeScan) => {
          setVisible(false);
          onScan(v);
        }}
        pause={pause}
        onScanError={(e: unknown) =>
          onScanError ? onScanError(e) : Sentry.captureException(e)
        }
        scanSettings={scanditSettings}
        accessCamera={!pause}
        visible={!pause}
        playSoundOnScan
        vibrateOnScan
      />
      <div className="flex justify-center mt-3">
        <Button
          type="button"
          onClick={handleColorInversionChange}
          color="secondary"
        >
          ({isColorInverted ? 'Inverted' : 'Normal'}) Change Color Inversion
        </Button>
      </div>
    </div>
  );
};

export default ScanditScanner;
