import { Groups } from '@scannable/common';

import { graphql } from '../graphql';

export const ASSIGN_ITEM_TO_GROUPS =
  graphql(`mutation ${Groups.AssignItemToGroups} ($data: AssignItemToGroupsInput!) {
  assignItemToGroups(data: $data) {
    id
  }
}`);

export const CREATE_GROUP =
  graphql(`mutation ${Groups.CreateGroup} ($name: String!, $type: GroupType!) {
  createGroup(data: { name: $name, type: $type }) {
    id
    name
    type
  }
}`);

export const DELETE_GROUP =
  graphql(`mutation ${Groups.DeleteGroup} ($id: Int!) {
  deleteGroup(id: $id) {
    id
  }
}`);

export const UPDATE_GROUP =
  graphql(`mutation ${Groups.UpdateGroup} ($data: UpdateGroupInput!) {
    updateGroup(data: $data) {
      id
      name
    }
  }
`);

export const ADD_ITEM_TO_GROUP = graphql(`
  mutation ${Groups.AddItemToGroup}($groupId: Int!, $serialisedProductId: Int!) {
    addItemToGroup(data: { groupId: $groupId, itemId: $serialisedProductId }) {
      id
    }
  }
`);

export const REMOVE_ITEM_FROM_GROUP = graphql(`
  mutation ${Groups.RemoveItemFromGroup}($itemId: Int!, $groupId: Int!) {
    removeGroupItem(data: { itemId: $itemId, groupId: $groupId }) {
      id
    }
  }
`);

export const REMOVE_ITEMS_FROM_GROUP = graphql(`
  mutation ${Groups.RemoveItemsFromGroup} ($itemIds: [Int!]!, $groupId: Int!) {
    removeGroupItems(data: { itemIds: $itemIds, groupId: $groupId })
  }
`);

export const ADD_ITEMS_TO_GROUP = graphql(`
  mutation ${Groups.AddItemsToGroup}($groupId: Int!, $serialisedProductIds: [Int!]!) {
    addItemsToGroup(data: { groupId: $groupId, itemIds: $serialisedProductIds })
  }
`);
