export function navigationBasePath(path: string) {
  return path
    .split('/')
    .filter(
      (part, index, array) =>
        index < array.findIndex((part) => part.startsWith('[')) ||
        array.findIndex((part) => part.startsWith('[')) === -1
    )
    .join('/');
}
