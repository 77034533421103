import { ItemProductData, SerialisedProduct } from '@scannable/common';

import { graphql } from '../graphql';

export const UPDATE_METADATA_CERT = graphql(`
  mutation ${ItemProductData.UpdateMetadataCertification} (
    $data: UpdateMetadataCertificationInput!
  ) {
    updateMetadataCertification(data: $data) {
      id
    }
  }
`);

export const CREATE_PRODUCT_OR_VARIATION_METADATA_PHOTO = graphql(`
  mutation ${ItemProductData.CreateMetadataPhoto} ($data: CreateMetadataPhotoInput!) {
    createMetadataPhoto(data: $data) {
      id
    }
  }
`);

export const CREATE_PRODUCT_OR_VARIATION_METADATA_FILE = graphql(`
  mutation ${ItemProductData.CreateMetadataFile} ($data: CreateMetadataFileInput!) {
    createMetadataFile(data: $data) {
      id
    }
  }
`);

export const DELETE_PRODUCT_OR_VARIATION_METADATA_FILE = graphql(`
  mutation ${ItemProductData.DeleteMetadataFile} ($data: DeleteMetadataFileInput!) {
    deleteMetadataFile(data: $data) {
      id
    }
  }
`);

export const DELETE_SERIALISED_PRODUCT_IMAGE = graphql(`
  mutation ${SerialisedProduct.DeleteSerialisedProductImage} ($data: DeleteSerialisedProductImageInput!) {
    deleteSerialisedProductImage(data: $data) {
      id
    }
  }
`);

export const UPDATE_PRODUCT_DATA = graphql(`
  mutation ${ItemProductData.UpdateProductData} ($data: UpdateProductDataInput!) {
    updateData(data: $data) {
      id
    }
  }
`);

export const UPDATE_MANY_PRODUCT_DATA = graphql(`
  mutation ${ItemProductData.UpdateProductData} ($data: UpdateManyProductDataInput!) {
    updateManyData(data: $data) {
      id
    }
  }
`);
