import { useState } from 'react';

import { TableConfiguration } from '../../../types/table.types';

const DEFAULT_PAGE_SIZE = 25;

export const useTableConfiguration = (
  userInitialState?: Partial<TableConfiguration>
) => {
  const [tableConfiguration] = useState<Required<TableConfiguration>>({
    paginationEnabled: userInitialState?.paginationEnabled || false,
    useAdvancedPagination: userInitialState?.useAdvancedPagination || false,
    orderBy: userInitialState?.orderBy ?? {},
    secondaryOrderBy: userInitialState?.secondaryOrderBy ?? {},
    pageSize: userInitialState?.pageSize ?? DEFAULT_PAGE_SIZE,
  });

  return { tableConfiguration };
};
