//slice to handle inspection of serialised products
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UniqueItem } from '@scannable/common';

export const INSPECTION_FEATURE_KEY = 'inspection';
export interface InspectionState {
  serialisedProduct: UniqueItem | null;
  inspectionItems: number[] | null;
}

export const inspectionSlice = createSlice({
  name: INSPECTION_FEATURE_KEY,
  initialState: {
    serialisedProduct: null,
    inspectionItems: null,
  } as InspectionState,
  reducers: {
    setSerialisedProduct: (state, action: PayloadAction<UniqueItem>) => {
      state.serialisedProduct = action.payload;
    },
    clearSerialisedProduct: (state) => {
      state.serialisedProduct = null;
    },
    setInspectionItems: (
      state,
      action: PayloadAction<InspectionState['inspectionItems']>
    ) => {
      state.inspectionItems = action.payload;
    },
    clearInspectionItems: (state) => {
      state.inspectionItems = null;
    },
  },
});

export const getInspectionState = (rootState: {
  [INSPECTION_FEATURE_KEY]: InspectionState;
}): InspectionState => rootState[INSPECTION_FEATURE_KEY];

export const selectInspectionItems = createSelector(
  getInspectionState,
  (state: InspectionState) => state?.inspectionItems
);

export const {
  setSerialisedProduct,
  clearSerialisedProduct,
  setInspectionItems,
} = inspectionSlice.actions;

export default inspectionSlice.reducer;
