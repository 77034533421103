import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { ADMIN_HOME_ROUTE } from '@scannable/common';

import { successAlert } from '../../utils';

export function useActivation() {
  const router = useRouter();

  useEffect(() => {
    if (router.query.activated) {
      successAlert('Your account has been activated!');
      router.push(ADMIN_HOME_ROUTE);
    }
  }, [router.query, router]);
}

export default useActivation;
