import { useMutation, useQuery } from '@apollo/client';

import { Organisation } from '@scannable/common';
import {
  ACCOUNT_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from '@scannable/graphql-queries';

interface UseAccountSubscription {
  skipAccountInfo?: boolean;
}

export function useAccountSubscription({
  skipAccountInfo = false,
}: UseAccountSubscription) {
  const { data } = useQuery(ACCOUNT_SUBSCRIPTION, {
    skip: skipAccountInfo,
  });

  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION, {
    refetchQueries: [Organisation.AccountSubscriptionInfo],
  });
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION, {
    refetchQueries: [Organisation.AccountSubscriptionInfo],
  });

  return {
    subscriptionInfo: data?.subscriptionInfo,
    updateSubscription,
    cancelSubscription,
  };
}
