import { FormikHelpers, useFormik } from 'formik';

import {
  NotificationPreference,
  NotificationPreferenceStatus,
  NotificationType,
} from '@scannable/common';
import {
  GetUserNotificationPreferencesData,
  useUpdateUserNotificationPreferencesMutation,
} from '@scannable/frontend/common';

import { Text } from '../../atoms';
import Form from '../../forms/Form/Form';
import useTranslation from '../../hooks/useTranslation/useTranslation';
import { resolveMutation } from '../../lib/lib';
import Modal from '../../modals/Modal/Modal';
import { useModal } from '../../modals/ModalContext/ModalContext';
import InputFieldGroup from '../../molecules/InputFieldGroup/InputFieldGroup';
import { EditUserNotificationPreferencesValues } from '../../side-panels/NotificationPreferencesSidePanel/NotificationPreferencesSidePanel.types';

export interface NotificationPreferencesModalProps {
  onDismiss: () => void;
  preferences: GetUserNotificationPreferencesData;
}

const NotificationPreferencesModal = (
  props: NotificationPreferencesModalProps
) => {
  const { hideModal } = useModal();
  const { t } = useTranslation();
  const [update] = useUpdateUserNotificationPreferencesMutation({
    refetchQueries: [NotificationPreference.GetUserNotificationPreferences],
  });

  const inspectionDue = props.preferences.find(
    (p) => p.type === NotificationType.INSPECTION_DUE
  );
  const expiryDue = props?.preferences.find(
    (p) => p.type === NotificationType.EXPIRY_DUE
  );

  const formik = useFormik({
    initialValues: {
      ...(inspectionDue?.id && { inspectionDueId: inspectionDue?.id }),
      inspectionDue: inspectionDue
        ? inspectionDue?.status === NotificationPreferenceStatus.ENABLED
        : true,
      ...(expiryDue?.id && { expiryDueId: expiryDue?.id }),
      expiryDue: expiryDue
        ? expiryDue?.status === NotificationPreferenceStatus.ENABLED
        : true,
    },
    enableReinitialize: true,
    onSubmit: async (
      values: EditUserNotificationPreferencesValues,
      actions: FormikHelpers<EditUserNotificationPreferencesValues>
    ) => {
      const result = await resolveMutation(
        update({
          variables: {
            data: {
              preferences: [
                {
                  id: values.inspectionDueId,
                  type: NotificationType.INSPECTION_DUE,
                  status: values.inspectionDue
                    ? NotificationPreferenceStatus.ENABLED
                    : NotificationPreferenceStatus.DISABLED,
                },
                {
                  id: values.expiryDueId,
                  type: NotificationType.EXPIRY_DUE,
                  status: values.expiryDue
                    ? NotificationPreferenceStatus.ENABLED
                    : NotificationPreferenceStatus.DISABLED,
                },
              ],
            },
          },
        }),
        {
          successMessage: 'Updated item successfully',
        }
      );
      actions.setSubmitting(false);
      if (result.ok) {
        hideModal();
      }
    },
  });

  return (
    <Modal title="Notifications" onDismiss={formik.submitForm}>
      <div className="p-4 pb-0">
        <div className="text-center w-80 mx-auto mb-6">
          <Text>
            Choose which notifications you want to receive. Change your
            preferences in Settings.
          </Text>
        </div>
        <Form
          formik={formik}
          buttons={[
            {
              label: t('save'),
              type: 'submit',
              loading: formik.isSubmitting,
              color: 'primary',
              'data-cy': 'save-item',
            },
          ]}
        >
          <div className="flex flex-row justify-between mb-4 pb-4 border-b border-gray-200">
            <div>
              <div className="text-sm font-semibold text-gray-800">
                {t('notifications.inspection_due_next_30_days')}
              </div>
              <div className="text-xs text-gray-500">
                {t('notifications.sent_weekly')}
              </div>
            </div>
            <InputFieldGroup
              data-cy="inspectionDue"
              id="inspectionDue"
              name="inspectionDue"
              type="checkbox"
              formik={formik}
            />
          </div>
          <div className="flex flex-row justify-between mb-4 pb-4 border-b border-gray-200">
            <div>
              <div className="text-sm font-semibold text-gray-800">
                {t('notifications.expiry_due_next_30_days')}
              </div>
              <div className="text-xs text-gray-500">
                {t('notifications.sent_weekly')}
              </div>
            </div>
            <InputFieldGroup
              data-cy="expiryDue"
              id="expiryDue"
              name="expiryDue"
              type="checkbox"
              formik={formik}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default NotificationPreferencesModal;
