import { ApiKeys } from '@scannable/common';

import { graphql } from '../graphql';

export const GET_API_KEYS = graphql(`
  query ${ApiKeys.GetApiKeys} ($organisationId: Int!) {
    apiKeys(organisationId: $organisationId) {
      id
      uuid
      createdAt
    }
  }
`);

export const PAGINATED_API_KEYS = graphql(`
  query ${ApiKeys.PaginatedApiKeys} (
    $where: ApiKeyWhereInput
    $orderBy: [ApiKeyOrderByWithRelationInput!]
    $take: Int
    $skip: Int) {
    paginatedApiKeys(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        uuid
        createdAt
      }
      totalCount
    }
  }
`);
