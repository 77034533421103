import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiValue, SingleValue } from 'react-select';

import { cn, ucFirst } from '@scannable/common';
import {
  addTableFilter,
  Filter,
  FilterOption,
  FilterValue,
  removeTableFilter,
  selectTableFilters,
} from '@scannable/frontend/store';

import { InputGroup, ReactSelectInput } from '../../molecules';
import { TableFilterRenderProps } from '../../types/table.types';
import { useUpdateQueryParams } from '../TableFilters/useUpdateQueryParams';

type FieldFilterProps<T extends FilterValue> = {
  className?: string;
  name?: string;
  interpolatedFilter?: Filter;
  callbackFilter?: (values: T | T[]) => Filter;
  options:
    | { label: string; value: T }[]
    | ((inputValue: string) => Promise<unknown>);
  placeholder?: string;
  label?: string;
  isMulti?: boolean;
  onInputChange?: typeof ReactSelectInput.prototype.props.onInputChange;
  isLoading?: boolean;
  isUrlParamEnabled?: boolean;
} & TableFilterRenderProps;

export function FieldFilter<T extends FilterValue>({
  className,
  tableName,
  name,
  interpolatedFilter,
  callbackFilter,
  filterName,
  options,
  placeholder,
  label,
  isMulti = true,
  onInputChange,
  isLoading,
  isUrlParamEnabled,
}: FieldFilterProps<T>) {
  const dispatch = useDispatch();
  const tableFilters = useSelector(selectTableFilters);
  const isAsync = typeof options === 'function';

  const handleInputChange = (
    search: string,
    { action }: { action: string }
  ) => {
    if (action === 'input-change' && onInputChange) {
      onInputChange(search, { action });
    }
  };

  const value =
    tableFilters[tableName]?.find((x) => x.filterName === filterName)?.values ??
    [];

  const inputName = name ?? filterName;
  const htmlId = ucFirst(inputName);

  const { clearParam } = useUpdateQueryParams();

  const onChange = useCallback(
    (v: MultiValue<FilterOption> | SingleValue<FilterOption>) => {
      if (Array.isArray(v)) {
        const values = [...v];

        clearParam(filterName, () =>
          dispatch(
            addTableFilter({
              table: tableName,
              filter: {
                filterName,
                interpolatedFilter,
                computedFilter: callbackFilter
                  ? callbackFilter(values.map((v) => v.value) as T[])
                  : null,
                values,
              },
            })
          )
        );
      } else {
        const singleValue = v ? ({ ...v } as FilterOption) : null;

        clearParam(filterName, () =>
          dispatch(
            singleValue
              ? addTableFilter({
                  table: tableName,
                  filter: {
                    filterName,
                    interpolatedFilter,
                    computedFilter:
                      callbackFilter && singleValue
                        ? callbackFilter(singleValue.value as T)
                        : null,
                    values: singleValue,
                  },
                })
              : removeTableFilter({
                  table: tableName,
                  filterName,
                })
          )
        );
      }
    },
    [
      callbackFilter,
      clearParam,
      dispatch,
      filterName,
      interpolatedFilter,
      tableName,
    ]
  );

  return (
    <div className={cn('flex-1', className ?? '')}>
      {label ? (
        <InputGroup htmlFor={htmlId} name={name} label={label}>
          <ReactSelectInput<FilterOption, typeof isMulti>
            id={htmlId}
            name={inputName}
            options={!isAsync ? options : []}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            isMulti={isMulti}
            isClearable
            onInputChange={handleInputChange}
            isLoading={isLoading}
            loadOptions={isAsync ? options : undefined}
          />
        </InputGroup>
      ) : (
        <ReactSelectInput<FilterOption, typeof isMulti>
          id={htmlId}
          name={inputName}
          options={!isAsync ? options : []}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          isMulti={isMulti}
          isClearable
          onInputChange={onInputChange}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}
