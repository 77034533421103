import { FormikProps } from 'formik';

import { Text } from '../../atoms';
import { DescriptionListItem } from '../../molecules/DescriptionList/DescriptionList';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';

export interface DateSelectionDescriptionListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  fieldName: string;
  label: string;
}

export function DateSelectionDescriptionListItem({
  formik,
  fieldName,
  label,
}: DateSelectionDescriptionListProps) {
  return (
    <DescriptionListItem title={label}>
      <Text className="my-3">Date selected: {formik.values[fieldName]}</Text>
      <InputFieldGroup
        id="SerialDOM"
        placeholder="Please select a date of manufacture"
        name={fieldName}
        type="date"
        formik={formik}
        inline
      />
    </DescriptionListItem>
  );
}

export default DateSelectionDescriptionListItem;
