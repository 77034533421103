import { useQuery } from '@apollo/client';

import { GET_TEAM } from '@scannable/graphql-queries';

export function useGetTeamQuery(id: string) {
  return useQuery(GET_TEAM, {
    variables: { id },
    skip: !id,
  });
}
