export const patterns = [
  {
    AI: '00',
    Description: 'Serial Shipping Container Code (SSCC)',
    Format: 'N2+N18',
    'Data title': 'SSCC',
    'FNC1 required?': 'No',
    'Regular expression': '00(\\d{18})',
  },
  {
    AI: '01',
    Description: 'Global Trade Item Number (GTIN)',
    Format: 'N2+N14',
    'Data title': 'GTIN',
    'FNC1 required?': 'No',
    'Regular expression': '01(\\d{14})',
  },
  {
    AI: '02',
    Description: 'Global Trade Item Number (GTIN) of contained trade items',
    Format: 'N2+N14',
    'Data title': 'CONTENT',
    'FNC1 required?': 'No',
    'Regular expression': '02(\\d{14})',
  },
  {
    AI: '10',
    Description: 'Batch or lot number',
    Format: 'N2+X..20',
    'Data title': 'BATCH/LOT',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '10([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '11',
    Description: 'Production date (YYMMDD)',
    Format: 'N2+N6',
    'Data title': 'PROD DATE',
    'FNC1 required?': 'No',
    'Regular expression': '11(\\d{6})',
  },
  {
    AI: '12',
    Description: 'Due date (YYMMDD)',
    Format: 'N2+N6',
    'Data title': 'DUE DATE',
    'FNC1 required?': 'No',
    'Regular expression': '12(\\d{6})',
  },
  {
    AI: '13',
    Description: 'Packaging date (YYMMDD)',
    Format: 'N2+N6',
    'Data title': 'PACK DATE',
    'FNC1 required?': 'No',
    'Regular expression': '13(\\d{6})',
  },
  {
    AI: '15',
    Description: 'Best before date (YYMMDD)',
    Format: 'N2+N6',
    'Data title': 'BEST BEFORE or BEST BY',
    'FNC1 required?': 'No',
    'Regular expression': '15(\\d{6})',
  },
  {
    AI: '16',
    Description: 'Sell by date (YYMMDD)',
    Format: 'N2+N6',
    'Data title': 'SELL BY',
    'FNC1 required?': 'No',
    'Regular expression': '16(\\d{6})',
  },
  {
    AI: '17',
    Description: 'Expiration date (YYMMDD)',
    Format: 'N2+N6',
    'Data title': 'USE BY OR EXPIRY',
    'FNC1 required?': 'No',
    'Regular expression': '17(\\d{6})',
  },
  {
    AI: '20',
    Description: 'Internal product variant',
    Format: 'N2+N2',
    'Data title': 'VARIANT',
    'FNC1 required?': 'No',
    'Regular expression': '20(\\d{2})',
  },
  {
    AI: '21',
    Description: 'Serial number',
    Format: 'N2+X..20',
    'Data title': 'SERIAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '21([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '22',
    Description: 'Consumer product variant',
    Format: 'N2+X..20',
    'Data title': 'CPV',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '22([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '235',
    Description:
      'Third Party Controlled, Serialised Extension of Global  Trade Item Number (GTIN) (TPX)',
    Format: 'N3+X..28',
    'Data title': 'TPX',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '235([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,28})',
  },
  {
    AI: '240',
    Description:
      'Additional product identification assigned by the  manufacturer',
    Format: 'N3+X..30',
    'Data title': 'ADDITIONAL ID',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '240([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '241',
    Description: 'Customer part number',
    Format: 'N3+X..30',
    'Data title': 'CUST. PART No.',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '241([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '242',
    Description: 'Made-to-Order variation number',
    Format: 'N3+N..6',
    'Data title': 'MTO VARIANT',
    'FNC1 required?': 'Yes',
    'Regular expression': '242(\\d{0,6})',
  },
  {
    AI: '243',
    Description: 'Packaging component number',
    Format: 'N3+X..20',
    'Data title': 'PCN',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '243([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '250',
    Description: 'Secondary serial number',
    Format: 'N3+X..30',
    'Data title': 'SECONDARY SERIAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '250([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '251',
    Description: 'Reference to source entity',
    Format: 'N3+X..30',
    'Data title': 'REF. TO SOURCE',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '251([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '253',
    Description: 'Global Document Type Identifier (GDTI)',
    Format: 'N3+N13[+X..17]',
    'Data title': 'GDTI',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '253(\\d{13})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,17})',
  },
  {
    AI: '254',
    Description: 'Global Location Number (GLN) extension component',
    Format: 'N3+X..20',
    'Data title': 'GLN EXTENSION COMPONENT',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '254([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '255',
    Description: 'Global Coupon Number (GCN)',
    Format: 'N3+N13[+N..12]',
    'Data title': 'GCN',
    'FNC1 required?': 'Yes',
    'Regular expression': '255(\\d{13})(\\d{0,12})',
  },
  {
    AI: '30',
    Description: 'Variable count of items (variable measure trade item)',
    Format: 'N2+N..8',
    'Data title': 'VAR. COUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '30(\\d{0,8})',
  },
  {
    AI: '3100',
    Description: 'Net weight, kilograms (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3100(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
  },
  {
    AI: '3101',
    Description: 'Net weight, kilograms (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3101(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
  },
  {
    AI: '3102',
    Description: 'Net weight, kilograms (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3102(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
  },
  {
    AI: '3103',
    Description: 'Net weight, kilograms (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3103(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
  },
  {
    AI: '3104',
    Description: 'Net weight, kilograms (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3104(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
  },
  {
    AI: '3105',
    Description: 'Net weight, kilograms (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3105(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
  },
  {
    AI: '3110',
    Description:
      'Length or first dimension, metres (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3110(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
  },
  {
    AI: '3111',
    Description:
      'Length or first dimension, metres (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3111(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
  },
  {
    AI: '3112',
    Description:
      'Length or first dimension, metres (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3112(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
  },
  {
    AI: '3113',
    Description:
      'Length or first dimension, metres (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3113(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
  },
  {
    AI: '3114',
    Description:
      'Length or first dimension, metres (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3114(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
  },
  {
    AI: '3115',
    Description:
      'Length or first dimension, metres (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3115(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
  },
  {
    AI: '3120',
    Description:
      'Width, diameter, or second dimension, metres (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3120(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
  },
  {
    AI: '3121',
    Description:
      'Width, diameter, or second dimension, metres (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3121(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
  },
  {
    AI: '3122',
    Description:
      'Width, diameter, or second dimension, metres (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3122(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
  },
  {
    AI: '3123',
    Description:
      'Width, diameter, or second dimension, metres (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3123(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
  },
  {
    AI: '3124',
    Description:
      'Width, diameter, or second dimension, metres (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3124(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
  },
  {
    AI: '3125',
    Description:
      'Width, diameter, or second dimension, metres (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3125(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
  },
  {
    AI: '3130',
    Description:
      'Depth, thickness, height, or third dimension, metres  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3130(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
  },
  {
    AI: '3131',
    Description:
      'Depth, thickness, height, or third dimension, metres  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3131(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
  },
  {
    AI: '3132',
    Description:
      'Depth, thickness, height, or third dimension, metres  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3132(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
  },
  {
    AI: '3133',
    Description:
      'Depth, thickness, height, or third dimension, metres  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3133(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
  },
  {
    AI: '3134',
    Description:
      'Depth, thickness, height, or third dimension, metres  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3134(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
  },
  {
    AI: '3135',
    Description:
      'Depth, thickness, height, or third dimension, metres  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m)',
    'FNC1 required?': 'No',
    'Regular expression': '3135(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
  },
  {
    AI: '3140',
    Description: 'Area, square metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3140(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
  },
  {
    AI: '3141',
    Description: 'Area, square metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3141(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
  },
  {
    AI: '3142',
    Description: 'Area, square metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3142(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
  },
  {
    AI: '3143',
    Description: 'Area, square metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3143(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
  },
  {
    AI: '3144',
    Description: 'Area, square metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3144(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
  },
  {
    AI: '3145',
    Description: 'Area, square metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3145(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
  },
  {
    AI: '3150',
    Description: 'Net volume, litres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (l)',
    'FNC1 required?': 'No',
    'Regular expression': '3150(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
  },
  {
    AI: '3151',
    Description: 'Net volume, litres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (l)',
    'FNC1 required?': 'No',
    'Regular expression': '3151(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
  },
  {
    AI: '3152',
    Description: 'Net volume, litres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (l)',
    'FNC1 required?': 'No',
    'Regular expression': '3152(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
  },
  {
    AI: '3153',
    Description: 'Net volume, litres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (l)',
    'FNC1 required?': 'No',
    'Regular expression': '3153(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
  },
  {
    AI: '3154',
    Description: 'Net volume, litres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (l)',
    'FNC1 required?': 'No',
    'Regular expression': '3154(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
  },
  {
    AI: '3155',
    Description: 'Net volume, litres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (l)',
    'FNC1 required?': 'No',
    'Regular expression': '3155(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
  },
  {
    AI: '3160',
    Description: 'Net volume, cubic metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (m<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3160(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
  },
  {
    AI: '3161',
    Description: 'Net volume, cubic metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (m<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3161(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
  },
  {
    AI: '3162',
    Description: 'Net volume, cubic metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (m<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3162(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
  },
  {
    AI: '3163',
    Description: 'Net volume, cubic metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (m<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3163(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
  },
  {
    AI: '3164',
    Description: 'Net volume, cubic metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (m<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3164(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
  },
  {
    AI: '3165',
    Description: 'Net volume, cubic metres (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (m<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3165(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
  },
  {
    AI: '3200',
    Description: 'Net weight, pounds (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3200(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
  },
  {
    AI: '3201',
    Description: 'Net weight, pounds (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3201(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
  },
  {
    AI: '3202',
    Description: 'Net weight, pounds (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3202(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
  },
  {
    AI: '3203',
    Description: 'Net weight, pounds (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3203(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
  },
  {
    AI: '3204',
    Description: 'Net weight, pounds (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3204(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
  },
  {
    AI: '3205',
    Description: 'Net weight, pounds (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3205(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
  },
  {
    AI: '3210',
    Description:
      'Length or first dimension, inches (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3210(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
  },
  {
    AI: '3211',
    Description:
      'Length or first dimension, inches (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3211(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
  },
  {
    AI: '3212',
    Description:
      'Length or first dimension, inches (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3212(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
  },
  {
    AI: '3213',
    Description:
      'Length or first dimension, inches (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3213(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
  },
  {
    AI: '3214',
    Description:
      'Length or first dimension, inches (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3214(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
  },
  {
    AI: '3215',
    Description:
      'Length or first dimension, inches (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3215(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
  },
  {
    AI: '3220',
    Description:
      'Length or first dimension, feet (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3220(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
  },
  {
    AI: '3221',
    Description:
      'Length or first dimension, feet (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3221(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
  },
  {
    AI: '3222',
    Description:
      'Length or first dimension, feet (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3222(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
  },
  {
    AI: '3223',
    Description:
      'Length or first dimension, feet (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3223(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
  },
  {
    AI: '3224',
    Description:
      'Length or first dimension, feet (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3224(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
  },
  {
    AI: '3225',
    Description:
      'Length or first dimension, feet (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3225(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
  },
  {
    AI: '3230',
    Description:
      'Length or first dimension, yards (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3230(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
  },
  {
    AI: '3231',
    Description:
      'Length or first dimension, yards (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3231(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
  },
  {
    AI: '3232',
    Description:
      'Length or first dimension, yards (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3232(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
  },
  {
    AI: '3233',
    Description:
      'Length or first dimension, yards (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3233(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
  },
  {
    AI: '3234',
    Description:
      'Length or first dimension, yards (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3234(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
  },
  {
    AI: '3235',
    Description:
      'Length or first dimension, yards (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3235(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
  },
  {
    AI: '3240',
    Description:
      'Width, diameter, or second dimension, inches (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3240(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
  },
  {
    AI: '3241',
    Description:
      'Width, diameter, or second dimension, inches (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3241(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
  },
  {
    AI: '3242',
    Description:
      'Width, diameter, or second dimension, inches (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3242(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
  },
  {
    AI: '3243',
    Description:
      'Width, diameter, or second dimension, inches (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3243(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
  },
  {
    AI: '3244',
    Description:
      'Width, diameter, or second dimension, inches (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3244(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
  },
  {
    AI: '3245',
    Description:
      'Width, diameter, or second dimension, inches (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3245(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
  },
  {
    AI: '3250',
    Description:
      'Width, diameter, or second dimension, feet (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3250(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
  },
  {
    AI: '3251',
    Description:
      'Width, diameter, or second dimension, feet (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3251(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
  },
  {
    AI: '3252',
    Description:
      'Width, diameter, or second dimension, feet (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3252(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
  },
  {
    AI: '3253',
    Description:
      'Width, diameter, or second dimension, feet (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3253(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
  },
  {
    AI: '3254',
    Description:
      'Width, diameter, or second dimension, feet (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3254(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
  },
  {
    AI: '3255',
    Description:
      'Width, diameter, or second dimension, feet (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3255(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
  },
  {
    AI: '3260',
    Description:
      'Width, diameter, or second dimension, yards (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3260(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
  },
  {
    AI: '3261',
    Description:
      'Width, diameter, or second dimension, yards (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3261(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
  },
  {
    AI: '3262',
    Description:
      'Width, diameter, or second dimension, yards (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3262(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
  },
  {
    AI: '3263',
    Description:
      'Width, diameter, or second dimension, yards (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3263(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
  },
  {
    AI: '3264',
    Description:
      'Width, diameter, or second dimension, yards (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3264(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
  },
  {
    AI: '3265',
    Description:
      'Width, diameter, or second dimension, yards (variable  measure trade item)',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3265(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
  },
  {
    AI: '3270',
    Description:
      'Depth, thickness, height, or third dimension, inches  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3270(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
  },
  {
    AI: '3271',
    Description:
      'Depth, thickness, height, or third dimension, inches  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3271(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
  },
  {
    AI: '3272',
    Description:
      'Depth, thickness, height, or third dimension, inches  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3272(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
  },
  {
    AI: '3273',
    Description:
      'Depth, thickness, height, or third dimension, inches  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3273(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
  },
  {
    AI: '3274',
    Description:
      'Depth, thickness, height, or third dimension, inches  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3274(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
  },
  {
    AI: '3275',
    Description:
      'Depth, thickness, height, or third dimension, inches  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in)',
    'FNC1 required?': 'No',
    'Regular expression': '3275(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
  },
  {
    AI: '3280',
    Description:
      'Depth, thickness, height, or third dimension, feet  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3280(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
  },
  {
    AI: '3281',
    Description:
      'Depth, thickness, height, or third dimension, feet  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3281(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
  },
  {
    AI: '3282',
    Description:
      'Depth, thickness, height, or third dimension, feet  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3282(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
  },
  {
    AI: '3283',
    Description:
      'Depth, thickness, height, or third dimension, feet  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3283(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
  },
  {
    AI: '3284',
    Description:
      'Depth, thickness, height, or third dimension, feet  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3284(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
  },
  {
    AI: '3285',
    Description:
      'Depth, thickness, height, or third dimension, feet  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft)',
    'FNC1 required?': 'No',
    'Regular expression': '3285(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
  },
  {
    AI: '3290',
    Description:
      'Depth, thickness, height, or third dimension, yards  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3290(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
  },
  {
    AI: '3291',
    Description:
      'Depth, thickness, height, or third dimension, yards  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3291(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
  },
  {
    AI: '3292',
    Description:
      'Depth, thickness, height, or third dimension, yards  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3292(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
  },
  {
    AI: '3293',
    Description:
      'Depth, thickness, height, or third dimension, yards  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3293(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
  },
  {
    AI: '3294',
    Description:
      'Depth, thickness, height, or third dimension, yards  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3294(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
  },
  {
    AI: '3295',
    Description:
      'Depth, thickness, height, or third dimension, yards  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd)',
    'FNC1 required?': 'No',
    'Regular expression': '3295(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
  },
  {
    AI: '3300',
    Description: 'Logistic weight, kilograms',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3300(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms',
  },
  {
    AI: '3301',
    Description: 'Logistic weight, kilograms',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3301(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms',
  },
  {
    AI: '3302',
    Description: 'Logistic weight, kilograms',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3302(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms',
  },
  {
    AI: '3303',
    Description: 'Logistic weight, kilograms',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3303(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms',
  },
  {
    AI: '3304',
    Description: 'Logistic weight, kilograms',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3304(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms',
  },
  {
    AI: '3305',
    Description: 'Logistic weight, kilograms',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (kg)',
    'FNC1 required?': 'No',
    'Regular expression': '3305(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms',
  },
  {
    AI: '3310',
    Description: 'Length or first dimension, metres',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3310(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
  },
  {
    AI: '3311',
    Description: 'Length or first dimension, metres',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3311(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
  },
  {
    AI: '3312',
    Description: 'Length or first dimension, metres',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3312(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
  },
  {
    AI: '3313',
    Description: 'Length or first dimension, metres',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3313(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
  },
  {
    AI: '3314',
    Description: 'Length or first dimension, metres',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3314(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
  },
  {
    AI: '3315',
    Description: 'Length or first dimension, metres',
    Format: 'N4+N6',
    'Data title': 'LENGTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3315(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
  },
  {
    AI: '3320',
    Description: 'Width, diameter, or second dimension, metres',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3320(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
  },
  {
    AI: '3321',
    Description: 'Width, diameter, or second dimension, metres',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3321(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
  },
  {
    AI: '3322',
    Description: 'Width, diameter, or second dimension, metres',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3322(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
  },
  {
    AI: '3323',
    Description: 'Width, diameter, or second dimension, metres',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3323(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
  },
  {
    AI: '3324',
    Description: 'Width, diameter, or second dimension, metres',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3324(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
  },
  {
    AI: '3325',
    Description: 'Width, diameter, or second dimension, metres',
    Format: 'N4+N6',
    'Data title': 'WIDTH (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3325(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
  },
  {
    AI: '3330',
    Description: 'Depth, thickness, height, or third dimension, metres',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3330(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn metres',
  },
  {
    AI: '3331',
    Description: 'Depth, thickness, height, or third dimension, metres',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3331(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n metres',
  },
  {
    AI: '3332',
    Description: 'Depth, thickness, height, or third dimension, metres',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3332(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn metres',
  },
  {
    AI: '3333',
    Description: 'Depth, thickness, height, or third dimension, metres',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3333(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn metres',
  },
  {
    AI: '3334',
    Description: 'Depth, thickness, height, or third dimension, metres',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3334(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn metres',
  },
  {
    AI: '3335',
    Description: 'Depth, thickness, height, or third dimension, metres',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (m), log',
    'FNC1 required?': 'No',
    'Regular expression': '3335(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn metres',
  },
  {
    AI: '3340',
    Description: 'Area, square metres',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3340(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square metres',
  },
  {
    AI: '3341',
    Description: 'Area, square metres',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3341(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square metres',
  },
  {
    AI: '3342',
    Description: 'Area, square metres',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3342(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square metres',
  },
  {
    AI: '3343',
    Description: 'Area, square metres',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3343(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square metres',
  },
  {
    AI: '3344',
    Description: 'Area, square metres',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3344(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square metres',
  },
  {
    AI: '3345',
    Description: 'Area, square metres',
    Format: 'N4+N6',
    'Data title': 'AREA (m<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3345(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square metres',
  },
  {
    AI: '3350',
    Description: 'Logistic volume, litres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (l), log',
    'FNC1 required?': 'No',
    'Regular expression': '3350(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn litres',
  },
  {
    AI: '3351',
    Description: 'Logistic volume, litres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (l), log',
    'FNC1 required?': 'No',
    'Regular expression': '3351(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n litres',
  },
  {
    AI: '3352',
    Description: 'Logistic volume, litres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (l), log',
    'FNC1 required?': 'No',
    'Regular expression': '3352(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn litres',
  },
  {
    AI: '3353',
    Description: 'Logistic volume, litres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (l), log',
    'FNC1 required?': 'No',
    'Regular expression': '3353(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn litres',
  },
  {
    AI: '3354',
    Description: 'Logistic volume, litres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (l), log',
    'FNC1 required?': 'No',
    'Regular expression': '3354(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn litres',
  },
  {
    AI: '3355',
    Description: 'Logistic volume, litres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (l), log',
    'FNC1 required?': 'No',
    'Regular expression': '3355(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn litres',
  },
  {
    AI: '3360',
    Description: 'Logistic volume, cubic metres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (m<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3360(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic metres',
  },
  {
    AI: '3361',
    Description: 'Logistic volume, cubic metres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (m<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3361(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic metres',
  },
  {
    AI: '3362',
    Description: 'Logistic volume, cubic metres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (m<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3362(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic metres',
  },
  {
    AI: '3363',
    Description: 'Logistic volume, cubic metres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (m<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3363(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic metres',
  },
  {
    AI: '3364',
    Description: 'Logistic volume, cubic metres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (m<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3364(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic metres',
  },
  {
    AI: '3365',
    Description: 'Logistic volume, cubic metres',
    Format: 'N4+N6',
    'Data title': 'VOLUME (m<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3365(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic metres',
  },
  {
    AI: '3370',
    Description: 'Kilograms per square metre',
    Format: 'N4+N6',
    'Data title': 'KG PER m<sup>2</sup>',
    'FNC1 required?': 'No',
    'Regular expression': '3370(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms per square metre',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms per square metre',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms per square metre',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms per square metre',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms per square metre',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn kilograms per square metre',
  },
  {
    AI: '3371',
    Description: 'Kilograms per square metre',
    Format: 'N4+N6',
    'Data title': 'KG PER m<sup>2</sup>',
    'FNC1 required?': 'No',
    'Regular expression': '3371(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms per square metre',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms per square metre',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms per square metre',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms per square metre',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms per square metre',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n kilograms per square metre',
  },
  {
    AI: '3372',
    Description: 'Kilograms per square metre',
    Format: 'N4+N6',
    'Data title': 'KG PER m<sup>2</sup>',
    'FNC1 required?': 'No',
    'Regular expression': '3372(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms per square metre',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms per square metre',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms per square metre',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms per square metre',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms per square metre',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn kilograms per square metre',
  },
  {
    AI: '3373',
    Description: 'Kilograms per square metre',
    Format: 'N4+N6',
    'Data title': 'KG PER m<sup>2</sup>',
    'FNC1 required?': 'No',
    'Regular expression': '3373(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms per square metre',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms per square metre',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms per square metre',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms per square metre',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms per square metre',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn kilograms per square metre',
  },
  {
    AI: '3374',
    Description: 'Kilograms per square metre',
    Format: 'N4+N6',
    'Data title': 'KG PER m<sup>2</sup>',
    'FNC1 required?': 'No',
    'Regular expression': '3374(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms per square metre',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms per square metre',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms per square metre',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms per square metre',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms per square metre',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn kilograms per square metre',
  },
  {
    AI: '3375',
    Description: 'Kilograms per square metre',
    Format: 'N4+N6',
    'Data title': 'KG PER m<sup>2</sup>',
    'FNC1 required?': 'No',
    'Regular expression': '3375(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms per square metre',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms per square metre',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms per square metre',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms per square metre',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms per square metre',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn kilograms per square metre',
  },
  {
    AI: '3400',
    Description: 'Logistic weight, pounds',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3400(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn pounds',
  },
  {
    AI: '3401',
    Description: 'Logistic weight, pounds',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3401(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n pounds',
  },
  {
    AI: '3402',
    Description: 'Logistic weight, pounds',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3402(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn pounds',
  },
  {
    AI: '3403',
    Description: 'Logistic weight, pounds',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3403(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn pounds',
  },
  {
    AI: '3404',
    Description: 'Logistic weight, pounds',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3404(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn pounds',
  },
  {
    AI: '3405',
    Description: 'Logistic weight, pounds',
    Format: 'N4+N6',
    'Data title': 'GROSS WEIGHT (lb)',
    'FNC1 required?': 'No',
    'Regular expression': '3405(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn pounds',
  },
  {
    AI: '3410',
    Description: 'Length or first dimension, inches',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3410(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
  },
  {
    AI: '3411',
    Description: 'Length or first dimension, inches',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3411(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
  },
  {
    AI: '3412',
    Description: 'Length or first dimension, inches',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3412(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
  },
  {
    AI: '3413',
    Description: 'Length or first dimension, inches',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3413(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
  },
  {
    AI: '3414',
    Description: 'Length or first dimension, inches',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3414(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
  },
  {
    AI: '3415',
    Description: 'Length or first dimension, inches',
    Format: 'N4+N6',
    'Data title': 'LENGTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3415(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
  },
  {
    AI: '3420',
    Description: 'Length or first dimension, feet',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3420(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
  },
  {
    AI: '3421',
    Description: 'Length or first dimension, feet',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3421(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
  },
  {
    AI: '3422',
    Description: 'Length or first dimension, feet',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3422(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
  },
  {
    AI: '3423',
    Description: 'Length or first dimension, feet',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3423(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
  },
  {
    AI: '3424',
    Description: 'Length or first dimension, feet',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3424(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
  },
  {
    AI: '3425',
    Description: 'Length or first dimension, feet',
    Format: 'N4+N6',
    'Data title': 'LENGTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3425(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
  },
  {
    AI: '3430',
    Description: 'Length or first dimension, yards',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3430(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
  },
  {
    AI: '3431',
    Description: 'Length or first dimension, yards',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3431(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
  },
  {
    AI: '3432',
    Description: 'Length or first dimension, yards',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3432(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
  },
  {
    AI: '3433',
    Description: 'Length or first dimension, yards',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3433(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
  },
  {
    AI: '3434',
    Description: 'Length or first dimension, yards',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3434(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
  },
  {
    AI: '3435',
    Description: 'Length or first dimension, yards',
    Format: 'N4+N6',
    'Data title': 'LENGTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3435(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
  },
  {
    AI: '3440',
    Description: 'Width, diameter, or second dimension, inches',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3440(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
  },
  {
    AI: '3441',
    Description: 'Width, diameter, or second dimension, inches',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3441(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
  },
  {
    AI: '3442',
    Description: 'Width, diameter, or second dimension, inches',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3442(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
  },
  {
    AI: '3443',
    Description: 'Width, diameter, or second dimension, inches',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3443(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
  },
  {
    AI: '3444',
    Description: 'Width, diameter, or second dimension, inches',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3444(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
  },
  {
    AI: '3445',
    Description: 'Width, diameter, or second dimension, inches',
    Format: 'N4+N6',
    'Data title': 'WIDTH (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3445(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
  },
  {
    AI: '3450',
    Description: 'Width, diameter, or second dimension, feet',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3450(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
  },
  {
    AI: '3451',
    Description: 'Width, diameter, or second dimension, feet',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3451(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
  },
  {
    AI: '3452',
    Description: 'Width, diameter, or second dimension, feet',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3452(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
  },
  {
    AI: '3453',
    Description: 'Width, diameter, or second dimension, feet',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3453(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
  },
  {
    AI: '3454',
    Description: 'Width, diameter, or second dimension, feet',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3454(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
  },
  {
    AI: '3455',
    Description: 'Width, diameter, or second dimension, feet',
    Format: 'N4+N6',
    'Data title': 'WIDTH (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3455(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
  },
  {
    AI: '3460',
    Description: 'Width, diameter, or second dimension, yard',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3460(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
  },
  {
    AI: '3461',
    Description: 'Width, diameter, or second dimension, yard',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3461(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
  },
  {
    AI: '3462',
    Description: 'Width, diameter, or second dimension, yard',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3462(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
  },
  {
    AI: '3463',
    Description: 'Width, diameter, or second dimension, yard',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3463(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
  },
  {
    AI: '3464',
    Description: 'Width, diameter, or second dimension, yard',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3464(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
  },
  {
    AI: '3465',
    Description: 'Width, diameter, or second dimension, yard',
    Format: 'N4+N6',
    'Data title': 'WIDTH (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3465(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
  },
  {
    AI: '3470',
    Description: 'Depth, thickness, height, or third dimension, inches',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3470(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn inches',
  },
  {
    AI: '3471',
    Description: 'Depth, thickness, height, or third dimension, inches',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3471(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n inches',
  },
  {
    AI: '3472',
    Description: 'Depth, thickness, height, or third dimension, inches',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3472(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn inches',
  },
  {
    AI: '3473',
    Description: 'Depth, thickness, height, or third dimension, inches',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3473(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn inches',
  },
  {
    AI: '3474',
    Description: 'Depth, thickness, height, or third dimension, inches',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3474(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn inches',
  },
  {
    AI: '3475',
    Description: 'Depth, thickness, height, or third dimension, inches',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (in), log',
    'FNC1 required?': 'No',
    'Regular expression': '3475(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn inches',
  },
  {
    AI: '3480',
    Description: 'Depth, thickness, height, or third dimension, feet',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3480(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn feet',
  },
  {
    AI: '3481',
    Description: 'Depth, thickness, height, or third dimension, feet',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3481(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n feet',
  },
  {
    AI: '3482',
    Description: 'Depth, thickness, height, or third dimension, feet',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3482(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn feet',
  },
  {
    AI: '3483',
    Description: 'Depth, thickness, height, or third dimension, feet',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3483(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn feet',
  },
  {
    AI: '3484',
    Description: 'Depth, thickness, height, or third dimension, feet',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3484(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn feet',
  },
  {
    AI: '3485',
    Description: 'Depth, thickness, height, or third dimension, feet',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (ft), log',
    'FNC1 required?': 'No',
    'Regular expression': '3485(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn feet',
  },
  {
    AI: '3490',
    Description: 'Depth, thickness, height, or third dimension, yards',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3490(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn yards',
  },
  {
    AI: '3491',
    Description: 'Depth, thickness, height, or third dimension, yards',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3491(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n yards',
  },
  {
    AI: '3492',
    Description: 'Depth, thickness, height, or third dimension, yards',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3492(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn yards',
  },
  {
    AI: '3493',
    Description: 'Depth, thickness, height, or third dimension, yards',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3493(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn yards',
  },
  {
    AI: '3494',
    Description: 'Depth, thickness, height, or third dimension, yards',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3494(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn yards',
  },
  {
    AI: '3495',
    Description: 'Depth, thickness, height, or third dimension, yards',
    Format: 'N4+N6',
    'Data title': 'HEIGHT (yd), log',
    'FNC1 required?': 'No',
    'Regular expression': '3495(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn yards',
  },
  {
    AI: '3500',
    Description: 'Area, square inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3500(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
  },
  {
    AI: '3501',
    Description: 'Area, square inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3501(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
  },
  {
    AI: '3502',
    Description: 'Area, square inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3502(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
  },
  {
    AI: '3503',
    Description: 'Area, square inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3503(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
  },
  {
    AI: '3504',
    Description: 'Area, square inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3504(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
  },
  {
    AI: '3505',
    Description: 'Area, square inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3505(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
  },
  {
    AI: '3510',
    Description: 'Area, square feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3510(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
  },
  {
    AI: '3511',
    Description: 'Area, square feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3511(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
  },
  {
    AI: '3512',
    Description: 'Area, square feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3512(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
  },
  {
    AI: '3513',
    Description: 'Area, square feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3513(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
  },
  {
    AI: '3514',
    Description: 'Area, square feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3514(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
  },
  {
    AI: '3515',
    Description: 'Area, square feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3515(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
  },
  {
    AI: '3520',
    Description: 'Area, square yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3520(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
  },
  {
    AI: '3521',
    Description: 'Area, square yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3521(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
  },
  {
    AI: '3522',
    Description: 'Area, square yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3522(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
  },
  {
    AI: '3523',
    Description: 'Area, square yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3523(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
  },
  {
    AI: '3524',
    Description: 'Area, square yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3524(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
  },
  {
    AI: '3525',
    Description: 'Area, square yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3525(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
  },
  {
    AI: '3530',
    Description: 'Area, square inches',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3530(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square inches',
  },
  {
    AI: '3531',
    Description: 'Area, square inches',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3531(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square inches',
  },
  {
    AI: '3532',
    Description: 'Area, square inches',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3532(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square inches',
  },
  {
    AI: '3533',
    Description: 'Area, square inches',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3533(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square inches',
  },
  {
    AI: '3534',
    Description: 'Area, square inches',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3534(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square inches',
  },
  {
    AI: '3535',
    Description: 'Area, square inches',
    Format: 'N4+N6',
    'Data title': 'AREA (in<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3535(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square inches',
  },
  {
    AI: '3540',
    Description: 'Area, square feet',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3540(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square feet',
  },
  {
    AI: '3541',
    Description: 'Area, square feet',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3541(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square feet',
  },
  {
    AI: '3542',
    Description: 'Area, square feet',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3542(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square feet',
  },
  {
    AI: '3543',
    Description: 'Area, square feet',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3543(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square feet',
  },
  {
    AI: '3544',
    Description: 'Area, square feet',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3544(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square feet',
  },
  {
    AI: '3545',
    Description: 'Area, square feet',
    Format: 'N4+N6',
    'Data title': 'AREA (ft<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3545(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square feet',
  },
  {
    AI: '3550',
    Description: 'Area, square yards',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3550(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn square yards',
  },
  {
    AI: '3551',
    Description: 'Area, square yards',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3551(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n square yards',
  },
  {
    AI: '3552',
    Description: 'Area, square yards',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3552(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn square yards',
  },
  {
    AI: '3553',
    Description: 'Area, square yards',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3553(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn square yards',
  },
  {
    AI: '3554',
    Description: 'Area, square yards',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3554(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn square yards',
  },
  {
    AI: '3555',
    Description: 'Area, square yards',
    Format: 'N4+N6',
    'Data title': 'AREA (yd<sup>2</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3555(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn square yards',
  },
  {
    AI: '3560',
    Description: 'Net weight, troy ounces (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (t oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3560(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn troy ounces',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn troy ounces',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn troy ounces',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn troy ounces',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn troy ounces',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn troy ounces',
  },
  {
    AI: '3561',
    Description: 'Net weight, troy ounces (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (t oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3561(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n troy ounces',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n troy ounces',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n troy ounces',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n troy ounces',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n troy ounces',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n troy ounces',
  },
  {
    AI: '3562',
    Description: 'Net weight, troy ounces (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (t oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3562(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn troy ounces',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn troy ounces',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn troy ounces',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn troy ounces',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn troy ounces',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn troy ounces',
  },
  {
    AI: '3563',
    Description: 'Net weight, troy ounces (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (t oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3563(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn troy ounces',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn troy ounces',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn troy ounces',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn troy ounces',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn troy ounces',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn troy ounces',
  },
  {
    AI: '3564',
    Description: 'Net weight, troy ounces (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (t oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3564(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn troy ounces',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn troy ounces',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn troy ounces',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn troy ounces',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn troy ounces',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn troy ounces',
  },
  {
    AI: '3565',
    Description: 'Net weight, troy ounces (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET WEIGHT (t oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3565(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn troy ounces',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn troy ounces',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn troy ounces',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn troy ounces',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn troy ounces',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn troy ounces',
  },
  {
    AI: '3570',
    Description:
      'Net weight (or volume), ounces (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3570(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn ounces',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn ounces',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn ounces',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn ounces',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn ounces',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn ounces',
  },
  {
    AI: '3571',
    Description:
      'Net weight (or volume), ounces (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3571(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n ounces',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n ounces',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n ounces',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n ounces',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n ounces',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n ounces',
  },
  {
    AI: '3572',
    Description:
      'Net weight (or volume), ounces (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3572(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn ounces',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn ounces',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn ounces',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn ounces',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn ounces',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn ounces',
  },
  {
    AI: '3573',
    Description:
      'Net weight (or volume), ounces (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3573(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn ounces',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn ounces',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn ounces',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn ounces',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn ounces',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn ounces',
  },
  {
    AI: '3574',
    Description:
      'Net weight (or volume), ounces (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3574(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn ounces',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn ounces',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn ounces',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn ounces',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn ounces',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn ounces',
  },
  {
    AI: '3575',
    Description:
      'Net weight (or volume), ounces (variable measure trade  item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (oz)',
    'FNC1 required?': 'No',
    'Regular expression': '3575(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn ounces',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn ounces',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn ounces',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn ounces',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn ounces',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn ounces',
  },
  {
    AI: '3600',
    Description: 'Net volume, quarts (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (qt)',
    'FNC1 required?': 'No',
    'Regular expression': '3600(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
  },
  {
    AI: '3601',
    Description: 'Net volume, quarts (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (qt)',
    'FNC1 required?': 'No',
    'Regular expression': '3601(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
  },
  {
    AI: '3602',
    Description: 'Net volume, quarts (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (qt)',
    'FNC1 required?': 'No',
    'Regular expression': '3602(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
  },
  {
    AI: '3603',
    Description: 'Net volume, quarts (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (qt)',
    'FNC1 required?': 'No',
    'Regular expression': '3603(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
  },
  {
    AI: '3604',
    Description: 'Net volume, quarts (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (qt)',
    'FNC1 required?': 'No',
    'Regular expression': '3604(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
  },
  {
    AI: '3605',
    Description: 'Net volume, quarts (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (qt)',
    'FNC1 required?': 'No',
    'Regular expression': '3605(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
  },
  {
    AI: '3610',
    Description: 'Net volume, gallons U.S. (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (gal.)',
    'FNC1 required?': 'No',
    'Regular expression': '3610(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
  },
  {
    AI: '3611',
    Description: 'Net volume, gallons U.S. (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (gal.)',
    'FNC1 required?': 'No',
    'Regular expression': '3611(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
  },
  {
    AI: '3612',
    Description: 'Net volume, gallons U.S. (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (gal.)',
    'FNC1 required?': 'No',
    'Regular expression': '3612(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
  },
  {
    AI: '3613',
    Description: 'Net volume, gallons U.S. (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (gal.)',
    'FNC1 required?': 'No',
    'Regular expression': '3613(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
  },
  {
    AI: '3614',
    Description: 'Net volume, gallons U.S. (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (gal.)',
    'FNC1 required?': 'No',
    'Regular expression': '3614(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
  },
  {
    AI: '3615',
    Description: 'Net volume, gallons U.S. (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'NET VOLUME (gal.)',
    'FNC1 required?': 'No',
    'Regular expression': '3615(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
  },
  {
    AI: '3620',
    Description: 'Logistic volume, quarts',
    Format: 'N4+N6',
    'Data title': 'VOLUME (qt), log',
    'FNC1 required?': 'No',
    'Regular expression': '3620(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn quarts',
  },
  {
    AI: '3621',
    Description: 'Logistic volume, quarts',
    Format: 'N4+N6',
    'Data title': 'VOLUME (qt), log',
    'FNC1 required?': 'No',
    'Regular expression': '3621(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n quarts',
  },
  {
    AI: '3622',
    Description: 'Logistic volume, quarts',
    Format: 'N4+N6',
    'Data title': 'VOLUME (qt), log',
    'FNC1 required?': 'No',
    'Regular expression': '3622(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn quarts',
  },
  {
    AI: '3623',
    Description: 'Logistic volume, quarts',
    Format: 'N4+N6',
    'Data title': 'VOLUME (qt), log',
    'FNC1 required?': 'No',
    'Regular expression': '3623(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn quarts',
  },
  {
    AI: '3624',
    Description: 'Logistic volume, quarts',
    Format: 'N4+N6',
    'Data title': 'VOLUME (qt), log',
    'FNC1 required?': 'No',
    'Regular expression': '3624(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn quarts',
  },
  {
    AI: '3625',
    Description: 'Logistic volume, quarts',
    Format: 'N4+N6',
    'Data title': 'VOLUME (qt), log',
    'FNC1 required?': 'No',
    'Regular expression': '3625(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn quarts',
  },
  {
    AI: '3630',
    Description: 'Logistic volume, gallons U.S.',
    Format: 'N4+N6',
    'Data title': 'VOLUME (gal.), log',
    'FNC1 required?': 'No',
    'Regular expression': '3630(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn US gallons',
  },
  {
    AI: '3631',
    Description: 'Logistic volume, gallons U.S.',
    Format: 'N4+N6',
    'Data title': 'VOLUME (gal.), log',
    'FNC1 required?': 'No',
    'Regular expression': '3631(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n US gallons',
  },
  {
    AI: '3632',
    Description: 'Logistic volume, gallons U.S.',
    Format: 'N4+N6',
    'Data title': 'VOLUME (gal.), log',
    'FNC1 required?': 'No',
    'Regular expression': '3632(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn US gallons',
  },
  {
    AI: '3633',
    Description: 'Logistic volume, gallons U.S.',
    Format: 'N4+N6',
    'Data title': 'VOLUME (gal.), log',
    'FNC1 required?': 'No',
    'Regular expression': '3633(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn US gallons',
  },
  {
    AI: '3634',
    Description: 'Logistic volume, gallons U.S.',
    Format: 'N4+N6',
    'Data title': 'VOLUME (gal.), log',
    'FNC1 required?': 'No',
    'Regular expression': '3634(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn US gallons',
  },
  {
    AI: '3635',
    Description: 'Logistic volume, gallons U.S.',
    Format: 'N4+N6',
    'Data title': 'VOLUME (gal.), log',
    'FNC1 required?': 'No',
    'Regular expression': '3635(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn US gallons',
  },
  {
    AI: '3640',
    Description: 'Net volume, cubic inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3640(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
  },
  {
    AI: '3641',
    Description: 'Net volume, cubic inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3641(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
  },
  {
    AI: '3642',
    Description: 'Net volume, cubic inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3642(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
  },
  {
    AI: '3643',
    Description: 'Net volume, cubic inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3643(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
  },
  {
    AI: '3644',
    Description: 'Net volume, cubic inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3644(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
  },
  {
    AI: '3645',
    Description: 'Net volume, cubic inches (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3645(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
  },
  {
    AI: '3650',
    Description: 'Net volume, cubic feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3650(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
  },
  {
    AI: '3651',
    Description: 'Net volume, cubic feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3651(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
  },
  {
    AI: '3652',
    Description: 'Net volume, cubic feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3652(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
  },
  {
    AI: '3653',
    Description: 'Net volume, cubic feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3653(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
  },
  {
    AI: '3654',
    Description: 'Net volume, cubic feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3654(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
  },
  {
    AI: '3655',
    Description: 'Net volume, cubic feet (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3655(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
  },
  {
    AI: '3660',
    Description: 'Net volume, cubic yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3660(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
  },
  {
    AI: '3661',
    Description: 'Net volume, cubic yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3661(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
  },
  {
    AI: '3662',
    Description: 'Net volume, cubic yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3662(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
  },
  {
    AI: '3663',
    Description: 'Net volume, cubic yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3663(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
  },
  {
    AI: '3664',
    Description: 'Net volume, cubic yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3664(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
  },
  {
    AI: '3665',
    Description: 'Net volume, cubic yards (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>)',
    'FNC1 required?': 'No',
    'Regular expression': '3665(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
  },
  {
    AI: '3670',
    Description: 'Logistic volume, cubic inches',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3670(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic inches',
  },
  {
    AI: '3671',
    Description: 'Logistic volume, cubic inches',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3671(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic inches',
  },
  {
    AI: '3672',
    Description: 'Logistic volume, cubic inches',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3672(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic inches',
  },
  {
    AI: '3673',
    Description: 'Logistic volume, cubic inches',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3673(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic inches',
  },
  {
    AI: '3674',
    Description: 'Logistic volume, cubic inches',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3674(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic inches',
  },
  {
    AI: '3675',
    Description: 'Logistic volume, cubic inches',
    Format: 'N4+N6',
    'Data title': 'VOLUME (in<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3675(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic inches',
  },
  {
    AI: '3680',
    Description: 'Logistic volume, cubic feet',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3680(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic feet',
  },
  {
    AI: '3681',
    Description: 'Logistic volume, cubic feet',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3681(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic feet',
  },
  {
    AI: '3682',
    Description: 'Logistic volume, cubic feet',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3682(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic feet',
  },
  {
    AI: '3683',
    Description: 'Logistic volume, cubic feet',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3683(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic feet',
  },
  {
    AI: '3684',
    Description: 'Logistic volume, cubic feet',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3684(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic feet',
  },
  {
    AI: '3685',
    Description: 'Logistic volume, cubic feet',
    Format: 'N4+N6',
    'Data title': 'VOLUME (ft<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3685(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic feet',
  },
  {
    AI: '3690',
    Description: 'Logistic volume, cubic yards',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3690(\\d{6})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnnn cubic yards',
  },
  {
    AI: '3691',
    Description: 'Logistic volume, cubic yards',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3691(\\d{6})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnnn.n cubic yards',
  },
  {
    AI: '3692',
    Description: 'Logistic volume, cubic yards',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3692(\\d{6})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnnn.nn cubic yards',
  },
  {
    AI: '3693',
    Description: 'Logistic volume, cubic yards',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3693(\\d{6})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nnn.nnn cubic yards',
  },
  {
    AI: '3694',
    Description: 'Logistic volume, cubic yards',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3694(\\d{6})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = nn.nnnn cubic yards',
  },
  {
    AI: '3695',
    Description: 'Logistic volume, cubic yards',
    Format: 'N4+N6',
    'Data title': 'VOLUME (yd<sup>3</sup>), log',
    'FNC1 required?': 'No',
    'Regular expression': '3695(\\d{6})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N6 = n.nnnnn cubic yards',
  },
  {
    AI: '37',
    Description:
      'Count of trade items or trade item pieces contained in a  logistic unit',
    Format: 'N2+N..8',
    'Data title': 'COUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '37(\\d{0,8})',
  },
  {
    AI: '3900',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3900(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of local currency',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of local currency',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of local currency',
  },
  {
    AI: '3901',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3901(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of local currency',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of local currency',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of local currency',
  },
  {
    AI: '3902',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3902(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of local currency',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of local currency',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of local currency',
  },
  {
    AI: '3903',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3903(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of local currency',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of local currency',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of local currency',
  },
  {
    AI: '3904',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3904(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of local currency',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of local currency',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of local currency',
  },
  {
    AI: '3905',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3905(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of local currency',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of local currency',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of local currency',
  },
  {
    AI: '3906',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3906(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of local currency',
    Description:
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of local currency',
    Format:
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of local currency',
  },
  {
    AI: '3907',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3907(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of local currency',
    Description:
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of local currency',
    Format:
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of local currency',
  },
  {
    AI: '3908',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3908(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of local currency',
    Description:
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of local currency',
    Format:
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of local currency',
  },
  {
    AI: '3909',
    Description: 'Applicable amount payable or Coupon value, local  currency',
    Format: 'N4+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3909(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of local currency',
    Description:
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of local currency',
    Format:
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of local currency',
    'Data title':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of local currency',
    'FNC1 required?':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of local currency',
    'Regular expression':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of local currency',
  },
  {
    AI: '3910',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3910(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3911',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3911(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3912',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3912(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3913',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3913(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3914',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3914(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3915',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3915(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3916',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3916(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3917',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3917(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3918',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3918(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3919',
    Description: 'Applicable amount payable with ISO currency code',
    Format: 'N4+N3+N..15',
    'Data title': 'AMOUNT',
    'FNC1 required?': 'Yes',
    'Regular expression': '3919(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3920',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3920(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3921',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3921(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3922',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3922(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3923',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3923(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3924',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3924(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3925',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3925(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3926',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3926(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3927',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3927(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3928',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3928(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3929',
    Description:
      'Applicable amount payable, single monetary area (variable  measure trade item)',
    Format: 'N4+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3929(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency within the single monetary area',
    Description:
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency within the single monetary area',
    Format:
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency within the single monetary area',
    'Data title':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency within the single monetary area',
    'FNC1 required?':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency within the single monetary area',
    'Regular expression':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency within the single monetary area',
  },
  {
    AI: '3930',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3930(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 0 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3931',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3931(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 1 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3932',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3932(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 2 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3933',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3933(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 3 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3934',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3934(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (4) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 4 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3935',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3935(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (5) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 5 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3936',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3936(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (6) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 6 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3937',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3937(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (7) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 7 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3938',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3938(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (8) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 8 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3939',
    Description:
      'Applicable amount payable with ISO currency code (variable  measure trade item)',
    Format: 'N4+N3+N..15',
    'Data title': 'PRICE',
    'FNC1 required?': 'Yes',
    'Regular expression': '3939(\\d{3})(\\d{0,15})',
  },
  {
    AI: 'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Description:
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    Format:
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Data title':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'FNC1 required?':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
    'Regular expression':
      'The fourth digit (9) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).  i.e. N..15 is a floating-point value with 9 decimal places in  units of currency where N3 = ISO 4217 numeric currency code',
  },
  {
    AI: '3940',
    Description: 'Percentage discount of a coupon',
    Format: 'N4+N4',
    'Data title': 'PRCNT OFF',
    'FNC1 required?': 'Yes',
    'Regular expression': '3940(\\d{4})',
  },
  {
    AI: 'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    Description:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    Format:
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'Data title':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'FNC1 required?':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'Regular expression':
      'The fourth digit (0) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
  },
  {
    AI: '3941',
    Description: 'Percentage discount of a coupon',
    Format: 'N4+N4',
    'Data title': 'PRCNT OFF',
    'FNC1 required?': 'Yes',
    'Regular expression': '3941(\\d{4})',
  },
  {
    AI: 'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    Description:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    Format:
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'Data title':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'FNC1 required?':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'Regular expression':
      'The fourth digit (1) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
  },
  {
    AI: '3942',
    Description: 'Percentage discount of a coupon',
    Format: 'N4+N4',
    'Data title': 'PRCNT OFF',
    'FNC1 required?': 'Yes',
    'Regular expression': '3942(\\d{4})',
  },
  {
    AI: 'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    Description:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    Format:
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'Data title':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'FNC1 required?':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'Regular expression':
      'The fourth digit (2) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
  },
  {
    AI: '3943',
    Description: 'Percentage discount of a coupon',
    Format: 'N4+N4',
    'Data title': 'PRCNT OFF',
    'FNC1 required?': 'Yes',
    'Regular expression': '3943(\\d{4})',
  },
  {
    AI: 'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    Description:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    Format:
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'Data title':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'FNC1 required?':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
    'Regular expression':
      'The fourth digit (3) of this AI indicates the number of  decimal places (see GS1 General Specifications for details).',
  },
  {
    AI: '3950',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3950(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '3951',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3951(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '3952',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3952(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '3953',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3953(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '3954',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3954(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '3955',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3955(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '3956',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3956(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '3957',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3957(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '3958',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3958(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '3959',
    Description:
      'Amount Payable per unit of measure single monetary area  (variable measure trade item)',
    Format: 'N4+N6',
    'Data title': 'PRICE/UoM',
    'FNC1 required?': 'Yes',
    'Regular expression': '3959(\\d{0,6})',
  },
  {
    AI: 'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Description:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    Format:
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Data title':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'FNC1 required?':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
    'Regular expression':
      'The fourth digit of this AI indicates the number of decimal  places. For example if the fourth digit is 3 the decimal is in  the 3rd position (nnn.nnn), if the fourth digit is 1 the  decimal is in the 1st position (nnnnn.n). See GS1 General  Specifications for full details.',
  },
  {
    AI: '400',
    Description: 'Customers purchase order number',
    Format: 'N3+X..30',
    'Data title': 'ORDER NUMBER',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '400([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '401',
    Description: 'Global Identification Number for Consignment (GINC)',
    Format: 'N3+X..30',
    'Data title': 'GINC',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '401([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '402',
    Description: 'Global Shipment Identification Number (GSIN)',
    Format: 'N3+N17',
    'Data title': 'GSIN',
    'FNC1 required?': 'Yes',
    'Regular expression': '402(\\d{17})',
  },
  {
    AI: '403',
    Description: 'Routing code',
    Format: 'N3+X..30',
    'Data title': 'ROUTE',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '403([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '410',
    Description: 'Ship to / Deliver to Global Location Number (GLN)',
    Format: 'N3+N13',
    'Data title': 'SHIP TO LOC',
    'FNC1 required?': 'No',
    'Regular expression': '410(\\d{13})',
  },
  {
    AI: '411',
    Description: 'Bill to / Invoice to Global Location Number (GLN)',
    Format: 'N3+N13',
    'Data title': 'BILL TO',
    'FNC1 required?': 'No',
    'Regular expression': '411(\\d{13})',
  },
  {
    AI: '412',
    Description: 'Purchased from Global Location Number (GLN)',
    Format: 'N3+N13',
    'Data title': 'PURCHASE FROM',
    'FNC1 required?': 'No',
    'Regular expression': '412(\\d{13})',
  },
  {
    AI: '413',
    Description:
      'Ship for / Deliver for - Forward to Global Location Number  (GLN)',
    Format: 'N3+N13',
    'Data title': 'SHIP FOR LOC',
    'FNC1 required?': 'No',
    'Regular expression': '413(\\d{13})',
  },
  {
    AI: '414',
    Description:
      'Identification of a physical location - Global Location  Number (GLN)',
    Format: 'N3+N13',
    'Data title': 'LOC No.',
    'FNC1 required?': 'No',
    'Regular expression': '414(\\d{13})',
  },
  {
    AI: '415',
    Description: 'Global Location Number (GLN) of the invoicing party',
    Format: 'N3+N13',
    'Data title': 'PAY TO',
    'FNC1 required?': 'No',
    'Regular expression': '415(\\d{13})',
  },
  {
    AI: '416',
    Description:
      'Global Location Number (GLN) of the production or service  location',
    Format: 'N3+N13',
    'Data title': 'PROD/SERV LOC',
    'FNC1 required?': 'No',
    'Regular expression': '416(\\d{13})',
  },
  {
    AI: '417',
    Description: 'Party Global Location Number (GLN)',
    Format: 'N3+N13',
    'Data title': 'PARTY',
    'FNC1 required?': 'No',
    'Regular expression': '417(\\d{13})',
  },
  {
    AI: '420',
    Description:
      'Ship to / Deliver to postal code within a single postal  authority',
    Format: 'N3+X..20',
    'Data title': 'SHIP TO POST',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '420([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '421',
    Description: 'Ship to / Deliver to postal code with ISO country code',
    Format: 'N3+N3+X..9',
    'Data title': 'SHIP TO POST',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '421(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,9})',
  },
  {
    AI: '422',
    Description: 'Country of origin of a trade item',
    Format: 'N3+N3',
    'Data title': 'ORIGIN',
    'FNC1 required?': 'Yes',
    'Regular expression': '422(\\d{3})',
  },
  {
    AI: '423',
    Description: 'Country of initial processing',
    Format: 'N3+N3+N..12',
    'Data title': 'COUNTRY - INITIAL PROCESS',
    'FNC1 required?': 'Yes',
    'Regular expression': '423(\\d{3})(\\d{0,12})',
  },
  {
    AI: '424',
    Description: 'Country of processing',
    Format: 'N3+N3',
    'Data title': 'COUNTRY - PROCESS',
    'FNC1 required?': 'Yes',
    'Regular expression': '424(\\d{3})',
  },
  {
    AI: '425',
    Description: 'Country of disassembly',
    Format: 'N3+N3+N..12',
    'Data title': 'COUNTRY - DISASSEMBLY',
    'FNC1 required?': 'Yes',
    'Regular expression': '425(\\d{3})(\\d{0,12})',
  },
  {
    AI: '426',
    Description: 'Country covering full process chain',
    Format: 'N3+N3',
    'Data title': 'COUNTRY - FULL PROCESS',
    'FNC1 required?': 'Yes',
    'Regular expression': '426(\\d{3})',
  },
  {
    AI: '427',
    Description: 'Country subdivision Of origin',
    Format: 'N3+X..3',
    'Data title': 'ORIGIN SUBDIVISION',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '427([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,3})',
  },
  {
    AI: '4300',
    Description: 'Ship-to / Deliver-to company name',
    Format: 'N4+X..35',
    'Data title': 'SHIP TO COMP',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4300([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})',
  },
  {
    AI: '4301',
    Description: 'Ship-to / Deliver-to contact',
    Format: 'N4+X..35',
    'Data title': 'SHIP TO NAME',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4301([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})',
  },
  {
    AI: '4302',
    Description: 'Ship-to / Deliver-to address line 1',
    Format: 'N4+X..70',
    'Data title': 'SHIP TO ADD1',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4302([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4303',
    Description: 'Ship-to / Deliver-to address line 2',
    Format: 'N4+X..70',
    'Data title': 'SHIP TO ADD2',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4303([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4304',
    Description: 'Ship-to / Deliver-to suburb',
    Format: 'N4+X..70',
    'Data title': 'SHIP TO SUB',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4304([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4305',
    Description: 'Ship-to / Deliver-to locality',
    Format: 'N4+X..70',
    'Data title': 'SHIP TO LOC',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4305([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4306',
    Description: 'Ship-to / Deliver-to region',
    Format: 'N4+X..70',
    'Data title': 'SHIP TO REG',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4306([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4307',
    Description: 'Ship-to / Deliver-to country code',
    Format: 'N4+X2',
    'Data title': 'SHIP TO COUNTRY',
    'FNC1 required?': 'Yes',
    'Regular expression': '4307([A-Z]{2})',
  },
  {
    AI: '4308',
    Description: 'Ship-to / Deliver-to telephone number',
    Format: 'N4+X..30',
    'Data title': 'SHIP TO PHONE',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4308([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '4309',
    Description: 'Ship-to / Deliver-to GEO location',
    Format: 'N4+N20',
    'Data title': 'SHIP TO GEO',
    'FNC1 required?': 'Yes',
    'Regular expression': '4309(\\d{20})',
  },
  {
    AI: '4310',
    Description: 'Return-to company name',
    Format: 'N4+X..35',
    'Data title': 'RTN TO COMP',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4310([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})',
  },
  {
    AI: '4311',
    Description: 'Return-to contact',
    Format: 'N4+X..35',
    'Data title': 'RTN TO NAME',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4311([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})',
  },
  {
    AI: '4312',
    Description: 'Return-to address line 1',
    Format: 'N4+X..70',
    'Data title': 'RTN TO ADD1',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4312([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4313',
    Description: 'Return-to address line 2',
    Format: 'N4+X..70',
    'Data title': 'RTN TO ADD2',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4313([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4314',
    Description: 'Return-to suburb',
    Format: 'N4+X..70',
    'Data title': 'RTN TO SUB',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4314([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4315',
    Description: 'Return-to locality',
    Format: 'N4+X..70',
    'Data title': 'RTN TO LOC',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4315([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4316',
    Description: 'Return-to region',
    Format: 'N4+X..70',
    'Data title': 'RTN TO REG',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4316([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '4317',
    Description: 'Return-to country code',
    Format: 'N4+X2',
    'Data title': 'RTN TO COUNTRY',
    'FNC1 required?': 'Yes',
    'Regular expression': '4317([A-Z]{2})',
  },
  {
    AI: '4318',
    Description: 'Return-to postal code',
    Format: 'N4+X..20',
    'Data title': 'RTN TO POST',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4318([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '4319',
    Description: 'Return-to telephone number',
    Format: 'N4+X..30',
    'Data title': 'RTN TO PHONE',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4319([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '4320',
    Description: 'Service code description',
    Format: 'N4+X..35',
    'Data title': 'SRV DESCRIPTION',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '4320([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})',
  },
  {
    AI: '4321',
    Description: 'Dangerous goods flag',
    Format: 'N4+N1',
    'Data title': 'DANGEROUS GOODS',
    'FNC1 required?': 'Yes',
    'Regular expression': '4321([01])',
  },
  {
    AI: '4322',
    Description: 'Authority to leave',
    Format: 'N4+N1',
    'Data title': 'AUTH TO LEAVE',
    'FNC1 required?': 'Yes',
    'Regular expression': '4322([01])',
  },
  {
    AI: '4323',
    Description: 'Signature required flag',
    Format: 'N4+N1',
    'Data title': 'SIG REQUIRED',
    'FNC1 required?': 'Yes',
    'Regular expression': '4323([01])',
  },
  {
    AI: '4324',
    Description: 'Not before delivery date time',
    Format: 'N4+N10',
    'Data title': 'NBEF DEL DT',
    'FNC1 required?': 'Yes',
    'Regular expression': '4324(\\d{10})',
  },
  {
    AI: '4325',
    Description: 'Not after delivery date time',
    Format: 'N4+N10',
    'Data title': 'NAFT DEL DT',
    'FNC1 required?': 'Yes',
    'Regular expression': '4325(\\d{10})',
  },
  {
    AI: '4326',
    Description: 'Release date',
    Format: 'N4+N6',
    'Data title': 'REL DATE',
    'FNC1 required?': 'Yes',
    'Regular expression': '4326(\\d{6})',
  },
  {
    AI: '7001',
    Description: 'NATO Stock Number (NSN)',
    Format: 'N4+N13',
    'Data title': 'NSN',
    'FNC1 required?': 'Yes',
    'Regular expression': '7001(\\d{13})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7002',
    Description: 'UN/ECE meat carcasses and cuts classification',
    Format: 'N4+X..30',
    'Data title': 'MEAT CUT',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7002([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7003',
    Description: 'Expiration date and time',
    Format: 'N4+N10',
    'Data title': 'EXPIRY TIME',
    'FNC1 required?': 'Yes',
    'Regular expression': '7003(\\d{10})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7004',
    Description: 'Active potency',
    Format: 'N4+N..4',
    'Data title': 'ACTIVE POTENCY',
    'FNC1 required?': 'Yes',
    'Regular expression': '7004(\\d{0,4})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7005',
    Description: 'Catch area',
    Format: 'N4+X..12',
    'Data title': 'CATCH AREA',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7005([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,12})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7006',
    Description: 'First freeze date',
    Format: 'N4+N6',
    'Data title': 'FIRST FREEZE DATE',
    'FNC1 required?': 'Yes',
    'Regular expression': '7006(\\d{6})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7007',
    Description: 'Harvest date',
    Format: 'N4+N6..12',
    'Data title': 'HARVEST DATE',
    'FNC1 required?': 'Yes',
    'Regular expression': '7007(\\d{6,12})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7008',
    Description: 'Species for fishery purposes',
    Format: 'N4+X..3',
    'Data title': 'AQUATIC SPECIES',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7008([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,3})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7009',
    Description: 'Fishing gear type',
    Format: 'N4+X..10',
    'Data title': 'FISHING GEAR TYPE',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7009([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,10})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7010',
    Description: 'Production method',
    Format: 'N4+X..2',
    'Data title': 'PROD METHOD',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7010([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,2})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7011',
    Description: 'Test by date',
    Format: 'N4+N6[+N..4]',
    'Data title': 'TEST BY DATE',
    'FNC1 required?': 'Yes',
    'Regular expression': '7011(\\d{6})(\\d{0,4})',
  },
  {
    AI: '7020',
    Description: 'Refurbishment lot ID',
    Format: 'N4+X..20',
    'Data title': 'REFURB LOT',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7020([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7021',
    Description: 'Functional status',
    Format: 'N4+X..20',
    'Data title': 'FUNC STAT',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7021([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7022',
    Description: 'Revision status',
    Format: 'N4+X..20',
    'Data title': 'REV STAT',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7022([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7023',
    Description: 'Global Individual Asset Identifier (GIAI) of an assembly',
    Format: 'N4+X..30',
    'Data title': 'GIAI - ASSEMBLY',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7023([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7030',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 0',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7030(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7031',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 1',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7031(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7032',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 2',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7032(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7033',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 3',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7033(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7034',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 4',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7034(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7035',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 5',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7035(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7036',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 6',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7036(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7037',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 7',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7037(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7038',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 8',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7038(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7039',
    Description: 'Number of processor with ISO Country Code',
    Format: 'N4+N3+X..27',
    'Data title': 'PROCESSOR # 9',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7039(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7040',
    Description: 'GS1 UIC with Extension 1 and Importer index',
    Format: 'N4+N1+X3',
    'Data title': 'UIC+EXT',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7040(\\d[\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x41-\\x5A\\x5F\\x61-\\x7A]{3})',
  },
  {
    AI: '710',
    Description:
      'National Healthcare Reimbursement Number (NHRN) - Germany  PZN',
    Format: 'N3+X..20',
    'Data title': 'NHRN PZN',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '710([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '711',
    Description:
      'National Healthcare Reimbursement Number (NHRN) - France  CIP',
    Format: 'N3+X..20',
    'Data title': 'NHRN CIP',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '711([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '712',
    Description: 'National Healthcare Reimbursement Number (NHRN) - Spain  CN',
    Format: 'N3+X..20',
    'Data title': 'NHRN CN',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '712([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '713',
    Description:
      'National Healthcare Reimbursement Number (NHRN) - Brasil  DRN',
    Format: 'N3+X..20',
    'Data title': 'NHRN DRN',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '713([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '714',
    Description:
      'National Healthcare Reimbursement Number (NHRN) - Portugal  AIM',
    Format: 'N3+X..20',
    'Data title': 'NHRN AIM',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '714([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '715',
    Description:
      'National Healthcare Reimbursement Number (NHRN) - United  States of America NDC',
    Format: 'N3+X..20',
    'Data title': 'NHRN NDC',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '714([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: 'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Description:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    Format:
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Data title':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'FNC1 required?':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
    'Regular expression':
      'AIs issued in the 7 series represent a special case because  they are restricted to: one or a small number of sectors (i.e.  are not multi-sectoral); or a country or a region (i.e. are  not global).',
  },
  {
    AI: '7230',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #1',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7230([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7231',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #2',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7231([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7232',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #3',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7232([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7233',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #4',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7233([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7234',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #5',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7234([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7235',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #6',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7235([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7236',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #7',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7236([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7237',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #8',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7237([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7238',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #9',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7238([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7239',
    Description: 'Certification reference',
    Format: 'N4+X2+X..28',
    'Data title': 'CERT #10',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7239([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})',
  },
  {
    AI: '7240',
    Description: 'Protocol ID',
    Format: 'N4+X..20',
    'Data title': 'PROTOCOL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '7240  ([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '8001',
    Description:
      'Roll products (width, length, core diameter, direction,  splices)',
    Format: 'N4+N14',
    'Data title': 'DIMENSIONS',
    'FNC1 required?': 'Yes',
    'Regular expression': '8001(\\d{14})',
  },
  {
    AI: '8002',
    Description: 'Cellular mobile telephone identifier',
    Format: 'N4+X..20',
    'Data title': 'CMT No.',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8002([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '8003',
    Description: 'Global Returnable Asset Identifier (GRAI)',
    Format: 'N4+N14+X..16',
    'Data title': 'GRAI',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8003(\\d{14})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,16})',
  },
  {
    AI: '8004',
    Description: 'Global Individual Asset Identifier (GIAI)',
    Format: 'N4+X..30',
    'Data title': 'GIAI',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8004([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '8005',
    Description: 'Price per unit of measure',
    Format: 'N4+N6',
    'Data title': 'PRICE PER UNIT',
    'FNC1 required?': 'Yes',
    'Regular expression': '8005(\\d{6})',
  },
  {
    AI: '8006',
    Description: 'Identification of an individual trade item piece (ITIP)',
    Format: 'N4+N14+N2+N2',
    'Data title': 'ITIP',
    'FNC1 required?': 'Yes',
    'Regular expression': '8006(\\d{14})(\\d{2})(\\d{2})',
  },
  {
    AI: '8007',
    Description: 'International Bank Account Number (IBAN)',
    Format: 'N4+X..34',
    'Data title': 'IBAN',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8007([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,34})',
  },
  {
    AI: '8008',
    Description: 'Date and time of production',
    Format: 'N4+N8[+N..4]',
    'Data title': 'PROD TIME',
    'FNC1 required?': 'Yes',
    'Regular expression': '8008(\\d{8})(\\d{0,4})',
  },
  {
    AI: '8009',
    Description: 'Optically Readable Sensor Indicator',
    Format: 'N4+X..50',
    'Data title': 'OPTSEN',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8009([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,50})',
  },
  {
    AI: '8010',
    Description: 'Component/Part Identifier (CPID)',
    Format: 'N4+Y..30',
    'Data title': 'CPID',
    'FNC1 required?': 'Yes',
    'Regular expression': '8010([\\x23\\x2D\\x2F\\x30-\\x39\\x41-\\x5A]{5,30})',
  },
  {
    AI: '8011',
    Description: 'Component/Part Identifier serial number (CPID SERIAL)',
    Format: 'N4+N..12',
    'Data title': 'CPID SERIAL',
    'FNC1 required?': 'Yes',
    'Regular expression': '8011(\\d{0,12})',
  },
  {
    AI: '8012',
    Description: 'Software version',
    Format: 'N4+X..20',
    'Data title': 'VERSION',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8012([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})',
  },
  {
    AI: '8013',
    Description: 'Global Model Number (GMN)',
    Format: 'N4+X..25',
    'Data title':
      'GMN (for medical devices, the default, global data title is  BUDI-DI)',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8013([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,25})',
  },
  {
    AI: '8017',
    Description:
      'Global Service Relation Number (GSRN) to identify the  relationship between an organisation offering services and  the provider of services',
    Format: 'N4+N18',
    'Data title': 'GSRN - PROVIDER',
    'FNC1 required?': 'Yes',
    'Regular expression': '8017(\\d{18})',
  },
  {
    AI: '8018',
    Description:
      'Global Service Relation Number (GSRN) to identify the  relationship between an organisation offering services and  the recipient of services',
    Format: 'N4+N18',
    'Data title': 'GSRN - RECIPIENT',
    'FNC1 required?': 'Yes',
    'Regular expression': '8018(\\d{18})',
  },
  {
    AI: '8019',
    Description: 'Service Relation Instance Number (SRIN)',
    Format: 'N4+N..10',
    'Data title': 'SRIN',
    'FNC1 required?': 'Yes',
    'Regular expression': '8019(\\d{0,10})',
  },
  {
    AI: '8020',
    Description: 'Payment slip reference number',
    Format: 'N4+X..25',
    'Data title': 'REF No.',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8020([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,25})',
  },
  {
    AI: '8026',
    Description:
      'Identification of pieces of a trade item (ITIP) contained  in a logistic unit',
    Format: 'N4+N14+N2+N2',
    'Data title': 'ITIP CONTENT',
    'FNC1 required?': 'Yes',
    'Regular expression': '8026(\\d{14})(\\d{2})(\\d{2})',
  },
  {
    AI: '8110',
    Description: 'Coupon code identification for use in North America',
    Format: 'N4+X..70',
    'Data title': null,
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8110([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '8111',
    Description: 'Loyalty points of a coupon',
    Format: 'N4+N4',
    'Data title': 'POINTS',
    'FNC1 required?': 'Yes',
    'Regular expression': '8111(\\d{4})',
  },
  {
    AI: '8112',
    Description:
      'Paperless coupon code identification for use in North  America',
    Format: 'N4+X..70',
    'Data title': null,
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8112([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '8200',
    Description: 'Extended Packaging URL',
    Format: 'N4+X..70',
    'Data title': 'PRODUCT URL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '8200([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})',
  },
  {
    AI: '90',
    Description: 'Information mutually agreed between trading partners',
    Format: 'N2+X..30',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '90([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})',
  },
  {
    AI: '91',
    Description: 'Company internal information',
    Format: 'N2+X..90',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '91([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})',
  },
  {
    AI: '92',
    Description: 'Company internal information',
    Format: 'N2+X..90',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '92([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})',
  },
  {
    AI: '93',
    Description: 'Company internal information',
    Format: 'N2+X..90',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '93([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})',
  },
  {
    AI: '94',
    Description: 'Company internal information',
    Format: 'N2+X..90',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '94([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})',
  },
  {
    AI: '95',
    Description: 'Company internal information',
    Format: 'N2+X..90',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '95([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})',
  },
  {
    AI: '96',
    Description: 'Company internal information',
    Format: 'N2+X..90',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '96([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})',
  },
  {
    AI: '97',
    Description: 'Company internal information',
    Format: 'N2+X..90',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '97([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})',
  },
  {
    AI: '98',
    Description: 'Company internal information',
    Format: 'N2+X..90',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '98([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})',
  },
  {
    AI: '99',
    Description: 'Company internal information',
    Format: 'N2+X..90',
    'Data title': 'INTERNAL',
    'FNC1 required?': 'Yes',
    'Regular expression':
      '99([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})',
  },
];
