import { AllHTMLAttributes } from 'react';

import { cn } from '@scannable/common';

export function TableHeadCell({
  children,
  className,
  ...rest
}: AllHTMLAttributes<HTMLTableCellElement>) {
  return (
    <th
      scope="col"
      className={cn(
        'px-2 py-3 text-left text-sm font-medium text-gray-500 truncate capitalize',
        className || ''
      )}
      {...rest}
    >
      {children}
    </th>
  );
}

export default TableHeadCell;
