import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { cn } from '@scannable/common';

export type LinkProps = Omit<NextLinkProps, 'href'> & {
  external?: boolean;
  style?: 'default' | 'traditonal';
  href?: NextLinkProps['href'];
};

export function Link({
  children,
  className,
  href,
  style = 'default',
  external = false,
  textLink = false,
  ...props
}: LinkProps & {
  children: React.ReactNode;
  className?: string;
  textLink?: boolean;
}) {
  const color =
    style === 'default'
      ? 'text-gray-500 hover:text-gray-700 underline'
      : 'text-indigo-600 hover:text-indigo-900';
  if (external) {
    return (
      <a
        href={href as string}
        className={cn(className || color)}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    );
  }
  if (!href) {
    return (
      <span className={cn(className || color)} {...props}>
        {children}
      </span>
    );
  }
  return (
    <NextLink
      href={href}
      className={cn(textLink ? color : '', className || '')}
      {...props}
    >
      {children}
    </NextLink>
  );
}

export default Link;
