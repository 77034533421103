import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { GroupType } from '@prisma/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { useGetGroupsByTypeLazyQuery } from '@scannable/frontend/common';
import { GroupTypeMappingSingle } from '@scannable/frontend/translations';
import { FormFieldOptionType } from '@scannable/frontend/types';
import { REMOVE_ITEMS_FROM_GROUP } from '@scannable/graphql-queries';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { useTable, useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

interface RemoveFromGroupFormValues {
  groupId?: number | null;
}

const validationSchema = yup.object().shape({
  groupId: yup.number().nullable().required('Please select a group'),
});

function RemoveFromGroupWithForm({
  itemIds,
  groupId,
  groupType,
}: {
  itemIds: number[];
  groupId?: string | null;
  groupType?: GroupType;
}) {
  const { hideModal } = useModal();
  const { t } = useTranslation();
  const [selectedGroupType, setSelectedGroupType] = useState<
    GroupType | undefined
  >(groupType ?? undefined);
  const [removeItemsFromGroup] = useMutation(REMOVE_ITEMS_FROM_GROUP);
  const { reload, clearSelectedRows } = useTable();
  const [options, setOptions] = useState<FormFieldOptionType[]>([]);
  const [getGroupsByType] = useGetGroupsByTypeLazyQuery({
    onCompleted: (data) => {
      setOptions(
        data?.groups
          ? data.groups.map((group) => ({
              label: group.name,
              value: group.id,
            }))
          : []
      );
    },
  });
  useEffect(() => {
    if (selectedGroupType) {
      getGroupsByType({
        variables: {
          type: selectedGroupType,
        },
      });
    }
  }, [getGroupsByType, selectedGroupType]);

  const formik = useFormik<RemoveFromGroupFormValues>({
    initialValues: {
      groupId: groupId ? parseInt(groupId) : null,
    },
    validationSchema,
    onSubmit: async (
      values: RemoveFromGroupFormValues,
      actions: FormikHelpers<RemoveFromGroupFormValues>
    ) => {
      if (!values.groupId) {
        return;
      }

      const res = await resolveMutation(
        removeItemsFromGroup({
          variables: {
            itemIds,
            groupId: values.groupId,
          },
        }),
        {
          failureMessage: 'Error removing items from group',
          successMessage: 'Successfully removed items from group',
        }
      );
      actions.setSubmitting(false);
      if (res.ok) {
        reload();
        clearSelectedRows();
        hideModal();
      }
    },
  });

  const handleGroupTypeSelect = (selectedType: GroupType) => {
    formik.setValues({ groupId: null });
    setSelectedGroupType(selectedType);
  };

  return (
    <Modal title="Remove Items From Group" size="sm" overflowHidden={false}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: t('remove'),
            color: 'danger',
          },
        ]}
      >
        <InputFieldGroup
          id="groupType"
          name="groupType"
          label="Select a group type"
          type="select"
          options={[
            { label: t('clients'), value: GroupType.CLIENT },
            { label: t('equipment_type'), value: GroupType.EQUIPMENT_TYPE },
            { label: t('kit_bags'), value: GroupType.KIT_BAG },
            { label: t('locations'), value: GroupType.LOCATION },
            { label: t('staff'), value: GroupType.STAFF },
            { label: t('vehicles'), value: GroupType.VEHICLE },
          ]}
          onChange={(selectedType: FormFieldOptionType) =>
            handleGroupTypeSelect(selectedType.value as GroupType)
          }
          value={selectedGroupType}
        />
        {selectedGroupType && options && (
          <InputFieldGroup
            id="GroupId"
            name="groupId"
            type="select"
            isClearable={true}
            formik={formik}
            label={`Select ${t(GroupTypeMappingSingle[selectedGroupType])}`}
            options={options}
          />
        )}
      </ModalForm>
    </Modal>
  );
}

interface RemoveFromGroupModalProps {
  itemIds: number[];
  groupType?: GroupType;
  groupId: string | null;
}

export function RemoveFromGroupModal({
  itemIds,
  groupType,
  groupId,
}: RemoveFromGroupModalProps) {
  return (
    <RemoveFromGroupWithForm
      itemIds={itemIds}
      groupId={groupId}
      groupType={groupType}
    />
  );
}
