import { ItemInfo, ItemInfoProps } from '../ItemInfo/ItemInfo';
import ThumbnailImage from '../ThumbnailImage/ThumbnailImage';

export interface ItemPreviewProps extends ItemInfoProps {
  uri?: string | null;
  name?: string | null;
  hasNfc?: boolean;
}

export function ItemPreview({ uri, name, hasNfc, ...props }: ItemPreviewProps) {
  return (
    <div className="flex border border-gray-200 rounded-md p-2">
      <div className="h-12 relative">
        <ThumbnailImage
          src={uri ?? ''}
          alt={name ?? ''}
          showNfcIcon={Boolean(hasNfc)}
        />
      </div>
      <div className="ml-4 flex flex-1 flex-col mr-5">
        <ItemInfo name={name} {...props} />
      </div>
    </div>
  );
}

export default ItemPreview;
