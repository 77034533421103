import { useEffect, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';

import { cn, ProductRecord, SearchFilter, SearchType } from '@scannable/common';
import { useProductSearch } from '@scannable/frontend/common';
import { Search } from '@scannable/frontend/types';

import { Button, Icon, LoadingIcon } from '../../atoms';
import { BarcodeModal, useModal } from '../../modals';
import { SearchResultItem } from '../../molecules';

export interface SearchInputProps {
  name: HTMLInputElement['name'];
  manufacturerId?: number;
  resetAfterCallback?: boolean;
  placeholder?: string;
  showScannerInput?: boolean;
  buttonLabel?: string;
  type?: SearchType;
  filters?: SearchFilter<ProductRecord>[];
  addToCallback?: (result: Search) => void;
  onSearchCallback?: (result: Search) => void;
}
interface KeyboardEvent {
  key: string;
}

export function SearchInput({
  name,
  manufacturerId,
  addToCallback,
  resetAfterCallback = false,
  placeholder,
  filters,
  buttonLabel,
  onSearchCallback,
  showScannerInput = true,
  type = 'SkusOnly',
  ...props
}: SearchInputProps) {
  const searchRef = useRef<HTMLInputElement>(null);
  const { showModal } = useModal();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [showResults, setShowResults] = useState(false);
  const resetSearch = () => {
    if (resetAfterCallback && searchRef.current) {
      searchRef.current.value = '';
      setShowResults(false);
      setSearchTerm(null);
    }
  };

  const { loading, data, search } = useProductSearch({
    onSearchCallback,
    resetSearch,
  });

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && searchRef.current?.value) {
        setShowResults(true);
        search(searchRef.current.value, type, filters);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [filters, search, type]);

  useEffect(() => {
    if (searchTerm) {
      setShowResults(true);
      search(searchTerm, type, filters);
    }
  }, [filters, search, type, searchTerm]);

  const defaultPlaceholder =
    type === 'SkusOnly'
      ? 'Search by SKU name or code'
      : 'Search by serial number, nfc tag or UID';

  return (
    <div className="transform rounded-xl bg-white p-2 ring-1 ring-black ring-opacity-5 transition-all">
      <Combobox>
        <div className="flex justify-between">
          <div className="relative text-gray-600 w-full mr-2">
            <input
              className="shadow-sm focus:ring-indigo-500 py-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              type="text"
              name={name}
              ref={searchRef}
              placeholder={placeholder ? placeholder : defaultPlaceholder}
              {...props}
            />
            {showScannerInput && (
              <div className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer">
                <Button
                  color="white"
                  type="button"
                  size="xs"
                  onClick={() => {
                    showModal(
                      <BarcodeModal
                        setCameraValue={(t) => {
                          if (t && searchRef.current) {
                            searchRef.current.value = t;
                            setSearchTerm(t);
                          }
                        }}
                      />
                    );
                  }}
                >
                  <div className="flex flex-row">
                    <span>Scan</span>
                    <Icon
                      name="target"
                      className="text-gray-800 ml-2 mt-0.5"
                      width={14}
                      height={14}
                    />
                  </div>
                </Button>
              </div>
            )}
          </div>
          <Button
            label="Search"
            type="button"
            data-cy="search"
            size="xs"
            loading={loading}
            onClick={() => setSearchTerm(`${searchRef.current?.value || ''}`)}
          />
        </div>
        {!onSearchCallback && showResults && data && data.length > 0 && (
          <Combobox.Options
            static
            className="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
            data-cy="serial-search-results"
          >
            {!onSearchCallback &&
              showResults &&
              data.map((resultItem) => (
                <Combobox.Option
                  key={resultItem.id}
                  value={resultItem}
                  className={({ active }) =>
                    cn(
                      'cursor-default select-none rounded-md px-4',
                      active ? ' bg-gray-100 text-white' : ''
                    )
                  }
                >
                  <SearchResultItem
                    result={{
                      ...resultItem,
                      id: (resultItem.serialisedProductId ||
                        resultItem.productVariationId) as number,
                    }}
                    buttonLabel={buttonLabel}
                    onClick={(v) => {
                      // todo less than ideal, ill come back to later
                      if (addToCallback && v.serialisedProductId) {
                        addToCallback({ ...v, id: v.serialisedProductId });
                        resetSearch();
                      }
                      if (
                        addToCallback &&
                        !v.serialisedProductId &&
                        v.productVariationId
                      ) {
                        addToCallback({ ...v, id: v.productVariationId });
                        resetSearch();
                      }
                    }}
                  />
                </Combobox.Option>
              ))}
          </Combobox.Options>
        )}
        {loading && (
          <div
            className="px-4 py-10 text-center sm:px-14"
            data-cy="serial-search-results"
          >
            <div className="flex justify-center items-center mt-6 py-5 relative">
              <LoadingIcon />
            </div>
          </div>
        )}
        {data && data.length === 0 && (
          <div
            className="px-4 py-10 text-center sm:px-14"
            data-cy="serial-search-results"
          >
            <Icon name="products" className="mx-auto h-6 w-6 text-gray-400" />
            <p className="mt-4 text-sm text-gray-900">No results found</p>
          </div>
        )}
      </Combobox>
    </div>
  );
}

export default SearchInput;
