import { useEffect, useId, useRef } from 'react';
import Select, {
  ActionMeta,
  components,
  ContainerProps,
  GroupBase,
  OptionProps,
  Props,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import { cn } from '@scannable/common';
import { FormFieldDefaultOptionType } from '@scannable/frontend/types';

import Icon, { IconType } from '../../atoms/Icon/Icon';

declare module 'react-select' {
  export interface ContainerProps {
    selectProps: Props & { 'data-cy': string };
  }
  export interface OptionProps {
    selectProps: Props & { 'data-cy': string };
  }
}
interface DefaultOptionsProps {
  defaultOptions?: FormFieldDefaultOptionType[];
}

interface AsyncProps {
  loadOptions?: typeof AsyncSelect.prototype.props.loadOptions;
}

// till we have better typing on Options
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CustomSelectContainer(props: ContainerProps<any>) {
  if (!props.selectProps['data-cy']) {
    return <components.SelectContainer {...props} />;
  }
  return (
    <div data-cy={`${props.selectProps['data-cy']}`}>
      <components.SelectContainer {...props} />
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CustomOption(props: OptionProps<any>) {
  const isDanger = props.data?.type === 'danger';

  return (
    <div
      data-cy={`${props.selectProps['data-cy'] ?? ''}-option-${
        props.data.id || props.data.value
      }`}
      key={props.innerProps.key}
    >
      <components.Option {...props}>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            {props?.data?.icon && (
              <Icon
                name={props.data.icon as IconType}
                className="mr-2"
                height={16}
                width={16}
              />
            )}
            <span
              className={cn(
                'flex items-center',
                isDanger && !props.isSelected ? 'text-red-600' : ''
              )}
            >
              {props.data.label}
            </span>
          </div>
          {props.data.description && (
            <span className="text-gray-400 text-xs">
              {props.data.description}
            </span>
          )}
        </div>
      </components.Option>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CustomSingleValue(props: any) {
  return (
    <components.SingleValue {...props}>
      <div className="input-select">
        <div className="input-select__single-value flex justify-between">
          <span>{props.data.label}</span>
          {props.data.description && (
            <span className="text-gray-400 text-xs flex items-center">
              {props.data.description}
            </span>
          )}
        </div>
      </div>
    </components.SingleValue>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CustomMultiValueLabel(props: any) {
  return (
    <components.MultiValueLabel {...props}>
      <div className="flex items-center">
        {props?.data?.icon && (
          <Icon
            name={props.data.icon as IconType}
            className="mr-1"
            height={16}
            width={16}
          />
        )}
        <span className="flex items-center">{props.data.label}</span>
      </div>
    </components.MultiValueLabel>
  );
}

export function ReactSelectInput<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  id,
  name,
  className,
  defaultOptions,
  options = [],
  isMulti,
  value,
  onChange,
  onBlur,
  loadOptions,
  ...props
}: Props<Option, IsMulti, Group> & DefaultOptionsProps & AsyncProps) {
  const selectRef = useRef(null);
  const onChangeWrapper = (
    /* eslint-disable-next-line */
    value: any,
    actionMeta: ActionMeta<Option>
  ): void => {
    onChange && onChange(value, actionMeta);
    defaultOptions?.forEach((option) => {
      if (option.callback && option.value === value.value) {
        option.callback(value);
      }
    });
  };

  useEffect(() => {
    if (selectRef.current && loadOptions) {
      (selectRef.current as HTMLSelectElement).focus();
    }
  }, [loadOptions, options]);

  const cleanedDefaultOptions = defaultOptions?.map((option) => ({
    label: option.label,
    value: option.value,
  })) as unknown as Option[];
  const mergedOptions = [...options, ...(cleanedDefaultOptions || [])];
  const SelectComponent = loadOptions ? AsyncSelect : Select;
  return (
    <SelectComponent
      id={id}
      instanceId={useId()}
      ref={selectRef}
      isMulti={isMulti}
      name={name}
      defaultValue={value}
      inputId={`${name}Input`}
      loadOptions={(inputValue) => {
        if (loadOptions) {
          return loadOptions(inputValue);
        }
      }}
      styles={{
        control: (baseStyles, state) => {
          const {
            borderRadius,
            borderColor,
            borderStyle,
            boxShadow,
            ...styles
          } = baseStyles;
          delete styles['&:hover'];
          return {
            ...styles,
          };
        },
        multiValue: (base) => ({
          ...base,
          borderRadius: '1rem',
          border: '1px solid #D1D5DB',
          backgroundColor: '#F3F4F6',
        }),
        multiValueRemove: (base) => ({
          ...base,
          borderRadius: '0 1rem 1rem 0',
        }),
        option: (base) => ({
          ...base,
          zIndex: 11,
        }),
        menu: (base) => ({
          ...base,
          zIndex: 11,
        }),
      }}
      classNames={{
        control: (state) => {
          // eslint-disable-next-line no-lone-blocks
          {
            const baseClassNames =
              'block w-full text-base border-gray-300 sm:text-sm rounded-md shadow-sm';
            return state.isFocused
              ? `outline-none ring-1 ring-indigo-500 border-indigo-500 ${baseClassNames}`
              : baseClassNames;
          }
        },
      }}
      options={mergedOptions}
      onChange={onChangeWrapper}
      onBlur={onBlur}
      classNamePrefix="select"
      components={{
        Option: (props) => <CustomOption {...props} />,
        SelectContainer: (props) => <CustomSelectContainer {...props} />,
        MultiValueLabel: (props) => <CustomMultiValueLabel {...props} />,
        SingleValue: (props) => <CustomSingleValue {...props} />,
        Input: (props) => (
          <components.Input {...props} aria-activedescendant={undefined} /> // to fix https://github.com/JedWatson/react-select/issues/5459#issuecomment-1875022105
        ),
      }}
      value={value}
      {...props}
    />
  );
}

export default ReactSelectInput;
