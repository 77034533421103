import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Groups } from '@scannable/common';
import {
  useGetGroupQuery,
  useUpdateGroupMutation,
} from '@scannable/frontend/common';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { useTable } from '../../hooks/useTable/useTable';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export interface EditGroupModalProps {
  title: string;
  id: number;
}
interface EditGroupValues {
  name: string;
  string: string;
  nfc: string;
}
const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter a name'),
});

export function EditGroupModal({ title, id }: EditGroupModalProps) {
  const { hideModal } = useModal();
  const { reload } = useTable();
  const [updateGroup] = useUpdateGroupMutation({
    refetchQueries: [Groups.GetGroupsByType, Groups.GetGroup],
  });
  const { data: groupData } = useGetGroupQuery(id);

  const formik = useFormik({
    initialValues: {
      name: groupData?.group?.name || '',
      nfc: '',
    } as EditGroupValues,
    validationSchema,
    onSubmit: async (
      values: EditGroupValues,
      actions: FormikHelpers<EditGroupValues>
    ) => {
      const result = await resolveMutation(
        updateGroup({
          variables: {
            data: {
              name: values.name,
              nfc: values.nfc,
              id,
            },
          },
        }),
        {
          action: 'CREATE',
          model: 'group',
          successMessage: 'Edited group successfully',
        }
      );
      actions.setSubmitting(false);
      if (result.ok) {
        hideModal();
        reload();
      }
    },
  });
  return (
    <Modal title={title}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: 'Save',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
            'data-cy': 'save-group',
          },
        ]}
      >
        <InputFieldGroup
          data-cy="name"
          id="Name"
          name="name"
          type="text"
          formik={formik}
          label="Name"
          value={formik.values.name}
        />
        <InputFieldGroup
          data-cy="nfc"
          id="NFC"
          name="nfc"
          type="text"
          formik={formik}
          label="Add NFC"
          value={formik.values.nfc}
          placeholder="Click here and scan a tag with your NFC reader"
        />
      </ModalForm>
    </Modal>
  );
}

export default EditGroupModal;
