import { cn } from '@scannable/common';

type DividerProps = React.HTMLAttributes<HTMLHRElement>;

export function Divider({ className }: DividerProps) {
  return (
    <hr className={cn('border-gray-200 mb-5', className ? className : '')} />
  );
}
export default Divider;
