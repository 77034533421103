export enum DataType {
  GROUP = 'GROUP',
  PRODUCT = 'PRODUCT',
  VARIANT = 'VARIANT',
  SERIAL = 'SERIAL',
}

export enum SerialisationProcessType {
  CutRopeSplice = 'CutRopeSplice',
}

export enum Role {
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  Member = 'MEMBER',
  Supplier = 'SUPPLIER',
  User = 'USER',
  CompetentPerson = 'COMPETENT_PERSON',
}
export enum AccountModule {
  Pro = 'PRO',
  Enterprise = 'ENTERPRISE',
  Access = 'ACCESS',
  Inspector = 'INSPECTOR',
  InspectorCustomer = 'INSPECTOR_CUSTOMER',
}
export const proModules = [
  AccountModule.Pro,
  AccountModule.Enterprise,
  AccountModule.Inspector,
  AccountModule.InspectorCustomer,
];

export type RoleType = typeof Role[keyof typeof Role];
export type AccountModuleType =
  typeof AccountModule[keyof typeof AccountModule];

export enum SearchFilterOperator {
  And = 'AND',
  Or = 'OR',
  Not = 'NOT',
  AndNot = 'AND NOT',
  OrNot = 'OR NOT',
}

export enum ChecklistAppliesTo {
  ALL_ITEMS = 'ALL_ITEMS',
  SKU = 'SKU',
  PRODUCT_GROUP = 'PRODUCT_GROUP',
}

export enum Integration {
  LabelLive = 'LABEL_LIVE',
}
export type IntegrationType = typeof Integration[keyof typeof Integration];

export enum LabelSize {
  Small = 'Small',
  Large = 'Large',
}

export enum HardwareType {
  PRINTER = 'PRINTER',
}

export enum NotificationType {
  INSPECTION_DUE = 'INSPECTION_DUE',
  EXPIRY_DUE = 'EXPIRY_DUE',
  INSPECTION_FAILED = 'INSPECTION_FAILED',
  RECALL = 'RECALL',
  OTHER = 'OTHER',
}

export enum NotificationPreferenceStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum NotificationStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  FAILED = 'FAILED',
}

export enum NotificationRecipientStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  FAILED = 'FAILED',
  READ = 'READ',
}
