import { Inventory } from '@scannable/common';

import { graphql } from '../graphql';

export const UNASSIGN_ITEM = graphql(`
  mutation ${Inventory.UnAssignItemMutation}($serialisedProductId: Int!) {
    unassignItem(serialisedProductId: $serialisedProductId) {
      id
    }
  }
`);

export const ASSIGN_ITEM_TO_USER = graphql(`
  mutation ${Inventory.AssignItemToUserMutation}($serialisedProductId: Int!, $userId: Int!) {
    assignItemToUser(data: { equipmentId: $serialisedProductId, userId: $userId }) {
      id
    }
  }
`);

export const ASSIGN_ITEM_TO_ME = graphql(`
  mutation ${Inventory.AssignItemToMeMutation}($serialisedProductId: Int!) {
    assignItemToMe(data: { serialisedProductId: $serialisedProductId }) {
      id
    }
  }
`);

export const ASSIGN_ITEMS_TO_GROUPS =
  graphql(`mutation ${Inventory.AssignItemsToGroups} ($data: AssignItemsToGroupsInput!) {
    assignItemsToGroups(data: $data)
}`);

export const ASSIGN_ITEMS_TO_USER =
  graphql(`mutation ${Inventory.AssignItemsToUser} ($data: CreateUserEquipmentsInput!) {
    assignItemsToUser(data: $data)
}`);

export const REMOVE_INVENTORY_ITEMS =
  graphql(`mutation ${Inventory.RemoveItemsFromInventory} ($itemIds: [Int!]!) {
    removeInventoryItems(data: { itemIds: $itemIds })
}`);

export const CREATE_AND_ADD_TO_INVENTORY =
  graphql(`mutation ${Inventory.CreateAndAddToInventory} ($data: CreateAndAddInventoryInput!) {
    createAndAddToInventory(data: $data) {
      id
    }
  }`);

export const ASSIGN_TO_USER_BY_GROUP =
  graphql(`mutation ${Inventory.AssignToUserByGroup} ($groupId: Int!, $userId: Int!) {
    assignToUserByGroup(data: { groupId: $groupId, userId: $userId })
    }`);

export const ADD_TO_INVENTORY =
  graphql(`mutation ${Inventory.AddToInventory}($data: AddItemToInventoryInput!) {
  addToInventory(data: $data) {
    id
    }
  }`);

export const REMOVE_ITEM_FROM_INVENTORY =
  graphql(`mutation ${Inventory.RemoveItemFromInventory}($itemId: Int!) {
    removeInventoryItem(data: { itemId: $itemId }) {
      id
    }
  }`);

export const REMOVE_INVENTORY_ITEM =
  graphql(`mutation ${Inventory.RemoveItemFromInventory}($id: Int!) {
  removeInventoryItem(data: { itemId: $id }) {
    id
    }
    }`);

export const CHANGE_ITEM_SKU = graphql(`
  mutation ${Inventory.ChangeItemSku}($data: ChangeItemSkuInput!) {
    changeItemSku(data: $data) {
      id
    }
  }
`);

export const ASSIGN_ITEMS_TO_SKU = graphql(`
  mutation ${Inventory.AssignItemsToSku}($data: AssignItemsToSkuInput!) {
    assignItemsToSku(data: $data) {
      id
    }
  }
`);
