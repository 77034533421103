import { Teams } from '@scannable/common';

import { graphql } from '../graphql';

export const UPDATE_TEAM = graphql(`
  mutation ${Teams.UpdateTeam}($data: UpdateTeamInput!) {
    updateTeam(data: $data) {
      id
      name
    }
  }`);

export const REMOVE_TEAM_MEMBER = graphql(`
  mutation ${Teams.RemoveTeamMember}($data: RemoveTeamMemberInput!) {
    removeTeamMember(data: $data) {
      id
    }
  }`);

export const CREATE_TEAM_MEMBER = graphql(`
    mutation ${Teams.CreateTeamMember}($data: CreateTeamMemberInput!) {
        createTeamMember(data: $data) {
        id
        }
    }`);

export const DELETE_TEAM = graphql(`
    mutation ${Teams.DeleteTeam}($id: String!) {
        deleteTeam(id: $id) {
        id
        }
    }`);

export const CREATE_TEAM = graphql(`
    mutation ${Teams.CreateTeam}($data: CreateTeamInput!) {
        createTeam(data: $data) {
        id
        }
    }`);
