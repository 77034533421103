import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Certification } from '@scannable/common';
import {
  CREATE_CERTIFICATION,
  UPDATE_CERTIFICATION,
} from '@scannable/graphql-queries';

import { ModalForm } from '../../forms';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  eso: yup.string().optional(),
  legislation: yup.string().optional(),
  displayName: yup.string().optional(),
});

type CertificationValues = {
  name: string;
  eso: string;
  legislation: string;
  displayName: string;
};
interface CertificationModalProps extends Partial<CertificationValues> {
  id?: number;
}
export function CertificationModal({
  id,
  name,
  eso,
  legislation,
  displayName,
}: CertificationModalProps) {
  const [createCertification] = useMutation(CREATE_CERTIFICATION, {
    refetchQueries: [Certification.PaginatedCertifications],
  });
  const [updateCertification] = useMutation(UPDATE_CERTIFICATION, {
    refetchQueries: [Certification.PaginatedCertifications],
  });
  const { hideModal } = useModal();

  const formik = useFormik({
    initialValues: {
      name: name || '',
      eso: eso || '',
      legislation: legislation || '',
      displayName: displayName || '',
    },
    validationSchema,
    onSubmit: async (
      values: CertificationValues,
      actions: FormikHelpers<CertificationValues>
    ) => {
      const data = {
        name: values.name,
        eso: values.eso,
        legislation: values.legislation,
        displayName: values.displayName,
      };

      const result = await resolveMutation(
        id
          ? updateCertification({
              variables: {
                data: { ...data, id },
              },
            })
          : createCertification({
              variables: {
                data,
              },
            }),
        {
          successMessage: id
            ? 'Certification updated successfully'
            : 'Certification created successfully',
        }
      );

      if (result.ok) {
        hideModal();
        actions.setSubmitting(false);
      }
    },
  });

  return (
    <Modal title={id ? 'Update Certification' : 'Create Certification'}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: id ? 'Update' : 'Create',
            type: 'submit',
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup type="text" name="name" label="Name" formik={formik} />
        <InputFieldGroup type="text" name="eso" label="ESO" formik={formik} />
        <InputFieldGroup
          type="text"
          name="displayName"
          label="Display Name"
          formik={formik}
        />
        <InputFieldGroup
          type="text"
          name="legislation"
          label="Legislation"
          formik={formik}
        />
      </ModalForm>
    </Modal>
  );
}

export default CertificationModal;
