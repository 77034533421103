import { GroupType } from '@prisma/client';

import { getGroupPath, Groups, Role } from '@scannable/common';
import {
  SortOrder,
  tableBtnLabel,
  useDeleteGroupMutation,
} from '@scannable/frontend/common';
import { INVENTORY_GROUPS } from '@scannable/graphql-queries';

import { IconType, Link } from '../../atoms';
import { useIsAccess, useRoleGuard } from '../../auth';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { ConfirmationModal, CreateGroupModal } from '../../modals';
import { useModal } from '../../modals/ModalContext/ModalContext';
import { ShareButton } from '../../molecules';
import { UpgradeGuard } from '../../organisms';
import { TableRowType } from '../../types/table.types';
import { Table } from '../Table/Table';
import { TableLink } from '../TableLink/TableLink';

export interface GroupsTableProps {
  createTitle: string;
  groupType: GroupType;
  icon: IconType;
  path: string;
  title?: string;
}

export function GroupsTable({
  createTitle,
  groupType,
  icon,
  path,
  title,
}: GroupsTableProps) {
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();
  const roleGuard = useRoleGuard();
  const isAccess = useIsAccess();
  const [deleteGroup] = useDeleteGroupMutation({
    onCompleted: () => {
      hideModal();
    },
    refetchQueries: [Groups.InventoryGroups],
  });
  return (
    <div>
      <UpgradeGuard shouldHide={isAccess}>
        <Table<typeof INVENTORY_GROUPS>
          title={title}
          icon={icon}
          tableActions={[
            {
              label: `${tableBtnLabel(createTitle)}`,
              callback: () =>
                showModal(
                  <CreateGroupModal title={createTitle} groupType={groupType} />
                ),
              display: roleGuard([Role.Admin]),
              color: 'primary',
            },
          ]}
          rowActions={[
            {
              label: 'Delete',
              type: TableRowType.danger,
              display: roleGuard([Role.Admin]),
              callback: (row) =>
                showModal(
                  <ConfirmationModal
                    title="Are you sure you want to delete?"
                    message="Any items will be removed before deletion and will remain in your inventory"
                    onConfirm={async () => {
                      await resolveMutation(
                        deleteGroup({
                          variables: {
                            id: row.id,
                          },
                        }),
                        {
                          successMessage: 'Group deleted',
                        }
                      );
                    }}
                  />
                ),
            },
          ]}
          columnSettings={[
            {
              label: t('name'),
              accessor: 'name',
              canSort: true,
              cellRender(row) {
                return (
                  <div className="flex flex-row items-center">
                    <Link href={`${path}/${row.id}`} textLink>
                      {row.name}
                    </Link>
                    <ShareButton
                      color="info"
                      className="border-none ml-2 px-0 pl-1 pr-1 h-6 w-6 text-gray-500 bg-transparent hover:bg-transparent hover:text-gray-800 focus:ring-0 focus:ring-offset-0 focus:ring-transparent focus:outline"
                      iconSize={16}
                      url={`${window.location.host}${getGroupPath(
                        groupType,
                        row.uid
                      )}`}
                    />
                  </div>
                );
              },
            },
            {
              label: '',
              accessor: 'id',
              cellRender({ id }) {
                return <TableLink path={`${path}/${id}`} />;
              },
            },
          ]}
          query={INVENTORY_GROUPS}
          searchFields={[
            {
              field: 'name',
            },
          ]}
          variables={[
            {
              type: {
                equals: groupType,
              },
            },
          ]}
          initialState={{
            orderBy: {
              name: SortOrder.asc,
            },
            paginationEnabled: true,
            useAdvancedPagination: true,
            pageSize: 50,
          }}
        />
      </UpgradeGuard>
    </div>
  );
}

export default GroupsTable;
