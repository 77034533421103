import {
  Inventory,
  ProductCategories,
  SerialisedProduct,
} from '@scannable/common';

import { graphql, ResultOf } from '../graphql';

export const PAGINATED_GROUP_URL_ITEMS = graphql(`
  query ${Inventory.PaginatedGroupUrlItems}(
    $where: InventoryItemWhereInput
    $orderBy: [SerialisedProductOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedGroupUrlItems(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        uid
        dom
        dofu
        image {
          id
          uri
        }
        tierOne {
          serialNumber
          batchNumber
          name
          code
          expiryDate
          nfcTags
          lastInspection {
            status
            passed
            date
          }
          nextInspectionAt
          aliases
          manufacturerName
        }
        data {
          certifications {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`);

export const PAGINATED_INVENTORY_QUERY = graphql(`
  query ${Inventory.PaginatedWebInventory} (
    $where: SerialisedProductWhereInput
    $orderBy: [SerialisedProductOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedInventory(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        serialNumber
        dom
        expiryDate
        lastInspectionAt
        nextInspectionAt
        image {
          id
          uri
        }
        tierOne {
          id
          code
          name
          nextInspection
          nextInspectionAt
          expiryDate
          manufacturerName
          productGroupName
          inspectionCount
          productName
          equipmentId
          batchNumber
          belongsToMe
          nfcTag
          lastInspection {
            id
            date
            notes
            passed
            status
            nameOfInspector
            s3Key
          }
        }
      }
      totalCount
    }
  }
`);

export const APP_PAGINATED_INVENTORY = graphql(
  `query ${Inventory.PaginatedInventoryQuery}($where: SerialisedProductWhereInput, $take: Int!, $skip: Int!) {
    paginatedInventory(where: $where, take: $take, skip: $skip) {
      nodes {
        id
        uid
        serialNumber
        dom
        image {
          id
          uri
          smallUrl
        }
        productVariationId
        tierOne {
          name
          manufacturerName
          status
          lastInspection {
            status
          }
          nfcTags
          ean
          expiryDate
          code
        }
      }
      totalCount
    }
  }`
);

export const FIND_SERIALISED_PRODUCT_FOR_MULTI_SCAN =
  graphql(`query ${SerialisedProduct.GetSerialisedProductForUpdateQuery} ($id: Int!) {
  serialisedProduct(where: { id: { equals: $id } }) {
    parentId
    name
    id
    info {
      productName
      serialNumber
      manufacturerName
      code
      productGroupName
      lastInspection {
        status
      }
      aliases
      expiryDate
      isPart
      ownedById
      ean
      nfcTag
      nextInspectionAt
      name
    }
    image {
      uri
      id
      key
    }
  }
}`);

export const INVENTORY_PRODUCT_GROUPS =
  graphql(`query ${ProductCategories.FindInventoryProductGroups} {
  getInventoryProductGroups {
    id
    name
  }
}`);

export const INVENTORY_USER_PRODUCT_GROUPS =
  graphql(`query ${ProductCategories.FindUserInventoryProductGroups} {
  getUserInventoryProductGroups {
    id
    name
  }
}`);

export const INVENTORY_SEARCH = graphql(`
  query InventorySearch($data: FindUniqueItemsInput!) {
    inventorySearch(data: $data) {
      id
      parentId
      info {
        name
        productName
        serialNumber
        manufacturerName
        code
        productGroupName
        lastInspection {
          status
        }
        aliases
        expiryDate
        nextInspectionAt
        nfcTag
        ean
        isPart
      }
      image {
        uri
        id
        key
      }
      productVariationId
      serialisedProductId
    }
  }
`);

export const GLOBAL_ITEM_SEARCH = graphql(`
  query GlobalItemSearch($data: FindUniqueItemsInput!) {
    globalItemSearch(data: $data) {
      id
      parentId
      info {
        name
        productName
        serialNumber
        manufacturerName
        code
        productGroupName
        lastInspection {
          status
        }
        aliases
        expiryDate
        nextInspectionAt
        nfcTag
        ean
        isPart
      }
      image {
        uri
        id
        key
      }
      productVariationId
      serialisedProductId
    }
  }
`);

export const SKU_SEARCH = graphql(`
  query SkuSearch($data: SearchProductsInput!) {
    skuSearch(data: $data) {
      id
      parentId
      info {
        name
        productName
        serialNumber
        manufacturerName
        code
        productGroupName
        productType
        lastInspection {
          status
        }
        aliases
        expiryDate
        nextInspectionAt
        nfcTag
        ean
        isPart
      }
      image {
        uri
        id
        key
      }
      productVariationId
      serialisedProductId
      components {
        id
        productVariationId
        productGroupName
        code
        ean
        name
      }
    }
  }
`);

export const GLOBAL_NFC_SEARCH = graphql(`
  query GlobalNFCSearch($data: NfcSearchInput!) {
    nfcGlobalSearch(data: $data) {
      serialisedProductId
      groupId
      item {
        id
        parentId
        info {
          name
          productName
          serialNumber
          manufacturerName
          code
          productGroupName
          productType
          lastInspection {
            status
          }
          aliases
          expiryDate
          nextInspectionAt
          nfcTag
          ean
          isPart
        }
      }
    }
  }
`);

export type GlobalNFCSearchResult = ResultOf<
  typeof GLOBAL_NFC_SEARCH
>['nfcGlobalSearch'];

export const ACCOUNT_NFC_SEARCH = graphql(`
  query AccountNFCSearch($data: NfcSearchInput!) {
    nfcAccountSearch(data: $data) {
      serialisedProductId
      groupId
      item {
        id
        parentId
        info {
          name
          productName
          serialNumber
          manufacturerName
          code
          productGroupName
          productType
          lastInspection {
            status
          }
          aliases
          expiryDate
          nextInspectionAt
          nfcTag
        }
      }
    }
  }
`);

export const IS_ITEM_IN_INVENTORY = graphql(`
  query ${Inventory.IsItemInInventory}($serialisedProductId: Int!) {
    isItemInInventory(serialisedProductId: $serialisedProductId)
  }
`);
