import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateSerialisedRopeValues,
  CutRopeOrderLineItem,
  SerialPuchaseOrderLineItem,
  SerialPurchaseOrderValues,
} from '@scannable/frontend/types';

export const SERIALISATION_FEATURE_KEY = 'serialisation';

export type SerialisationState = {
  serialPurchaseOrder: SerialPurchaseOrderValues | null;
  skus: SerialPuchaseOrderLineItem[];
  cutRopes: CutRopeOrderLineItem[];
};

const initialState: SerialisationState = {
  serialPurchaseOrder: null,
  skus: [],
  cutRopes: [],
};

export const serialisationSlice = createSlice({
  name: SERIALISATION_FEATURE_KEY,
  initialState,
  reducers: {
    setSerialPurchaseOrderValues: (
      state,
      action: PayloadAction<SerialPurchaseOrderValues>
    ) => {
      switch (action.type) {
        case 'serialisation/setSerialPurchaseOrderValues':
          state.serialPurchaseOrder = action.payload;
          return state;
        default:
          return state;
      }
    },
    addSku: (state, action: PayloadAction<SerialPuchaseOrderLineItem>) => {
      switch (action.type) {
        case 'serialisation/addSku':
          state.skus = [...state.skus, action.payload];
          return state;
        default:
          return state;
      }
    },
    addCutRope: (state, action: PayloadAction<CreateSerialisedRopeValues>) => {
      switch (action.type) {
        case 'serialisation/addCutRope':
          state.cutRopes = [
            ...state.cutRopes,
            {
              ...action.payload,
              length: action.payload.length ?? 0,
              productVariationId: action.payload.productVariation.id,
            },
          ];
          return state;
        default:
          return state;
      }
    },
    removeSkuByIndex: (state, action: PayloadAction<number>) => {
      switch (action.type) {
        case 'serialisation/removeSkuByIndex':
          state.skus = state.skus.filter(
            (item, index) => index !== action.payload
          );
          return state;
        default:
          return state;
      }
    },
    clearSerialisationState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  addSku,
  addCutRope,
  setSerialPurchaseOrderValues,
  removeSkuByIndex,
  clearSerialisationState,
} = serialisationSlice.actions;

export default serialisationSlice.reducer;
