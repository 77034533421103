import { useState } from 'react';
import Image from 'next/image';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import { Inspection } from '@prisma/client';

import { imageFromS3Key } from '@scannable/common';

import { Button } from '../../atoms';
import { InspectionStatusBadge } from '../../molecules';
import { useModal } from '../ModalContext/ModalContext';
import SimpleModal from '../SimpleModal/SimpleModal';

export interface PreviewInspectionsModalProps {
  name: string;
  code: string;
  inspections: Inspection[];
}

export function PreviewInspectionsModal({
  name,
  code,
  inspections,
}: PreviewInspectionsModalProps) {
  const { hideModal } = useModal();
  const [index, setIndex] = useState(1);
  const handleIndexChange = (newIndex: number) => {
    setIndex(newIndex);
  };
  const handleNext = () => {
    handleIndexChange(
      index === inspections.length ? inspections.length : index + 1
    );
  };
  const handlePrevious = () => {
    handleIndexChange(index === 1 ? 1 : index - 1);
  };
  const currentInspection = inspections[index - 1];
  return (
    <SimpleModal size="sm">
      <div className="">
        <div className="flex flex-row mt-6 mb-6">
          <span className="mt-1">Inspection</span>
          <input
            name="index"
            value={index}
            className="w-8 text-center border border-gray-300 rounded-md mx-2 text-sm py-1"
            onChange={() => handleIndexChange}
          />
          <span className="mt-1 mx-2">of</span>
          <span className="mt-1">{inspections.length}</span>
          <span className="flex-grow"></span>
          <div className="flex-row ml-2">
            {index !== 1 && (
              <Button
                color="white"
                className="mr-2 px-2"
                onClick={handlePrevious}
              >
                <ArrowLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-500" />
              </Button>
            )}
            {index !== inspections.length && (
              <Button color="white" className="px-2" onClick={handleNext}>
                <ArrowRightIcon className="flex-shrink-0 h-5 w-5 text-gray-500" />
              </Button>
            )}
          </div>
        </div>
        <div>
          <p className="text-lg font-medium text-gray-900">{name}</p>
          <p className="text-gray-500 text-sm">{code}</p>
        </div>
        <div className="mb-10 mt-6 flex flex-row">
          <div>
            {currentInspection?.date && (
              <p className="text-gray-500 text-sm mt-2">
                <span className="font-medium">Date: </span>{' '}
                {new Date(
                  JSON.stringify(currentInspection.date).replace(/"/g, '')
                ).toDateString()}
              </p>
            )}
            {currentInspection?.nameOfInspector && (
              <p className="text-gray-500 text-sm mt-2">
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                <>
                  <span className="font-medium">Inspector: </span>{' '}
                  {currentInspection?.nameOfInspector}
                </>
              </p>
            )}

            {currentInspection?.notes && (
              <p className="text-gray-500 text-sm mt-2">
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                <>
                  <span className="font-medium">Notes: </span>{' '}
                  {currentInspection?.notes}
                </>
              </p>
            )}
          </div>
          <div className="flex-grow"></div>
          <InspectionStatusBadge status={currentInspection?.status || ''} />
        </div>
        <Image
          src={
            currentInspection?.s3Key
              ? imageFromS3Key(`${currentInspection.s3Key}`)
              : '/static/images/no-image.jpeg'
          }
          alt="Inspection Image"
          width={400}
          height={400}
          style={{
            objectFit: 'contain',
          }}
        />
        <div className="flex flex-row flex-spacing-4 border-t-gray-200 border-t pt-5">
          <div className="flex-grow" />
          <Button
            label="Close"
            type="button"
            color="white"
            className="text-red-600"
            onClick={() => hideModal()}
          />
        </div>
      </div>
    </SimpleModal>
  );
}

export default PreviewInspectionsModal;
