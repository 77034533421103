import { useQuery } from '@apollo/client';

import {
  MY_ORGANISATIONS,
  MY_PROFILE,
  UseQueryOptions,
} from '@scannable/graphql-queries';

export function useGetUsersOrganisationsQuery(
  options?: UseQueryOptions<typeof MY_ORGANISATIONS>
) {
  return useQuery(MY_ORGANISATIONS, options);
}

export function useMeQuery(options?: UseQueryOptions<typeof MY_PROFILE>) {
  return useQuery(MY_PROFILE, options);
}
