import { useQuery } from '@apollo/client';

import {
  GET_NOTIFICATION_COUNT,
  GET_ORG_NOTIFICATION_PREFERENCES,
  GET_PAGINATED_NOTIFICATIONS,
  GET_USER_NOTIFICATION_PREFERENCES,
  ResultOf,
  UseQueryOptions,
} from '@scannable/graphql-queries';

export type GetOrgNotificationPreferencesData = ResultOf<
  typeof GET_ORG_NOTIFICATION_PREFERENCES
>['orgNotificationPreferences'];

export function useGetOrgNotificationPreferencesQuery(
  options?: UseQueryOptions<typeof GET_ORG_NOTIFICATION_PREFERENCES>
) {
  return useQuery(GET_ORG_NOTIFICATION_PREFERENCES, options);
}

export type GetUserNotificationPreferencesData = ResultOf<
  typeof GET_USER_NOTIFICATION_PREFERENCES
>['preferences'];

export function useGetUserNotificationPreferencesQuery(
  options?: UseQueryOptions<typeof GET_USER_NOTIFICATION_PREFERENCES>
) {
  return useQuery(GET_USER_NOTIFICATION_PREFERENCES, options);
}

export type PaginatedNotificationsData = ResultOf<
  typeof GET_PAGINATED_NOTIFICATIONS
>['paginatedNotifications'];

export function useGetPaginatedNotificationsQuery(
  options?: UseQueryOptions<typeof GET_PAGINATED_NOTIFICATIONS>
) {
  return useQuery(GET_PAGINATED_NOTIFICATIONS, options);
}

export function useGetNotificationCountQuery(
  options?: UseQueryOptions<typeof GET_NOTIFICATION_COUNT>
) {
  return useQuery(GET_NOTIFICATION_COUNT, options);
}
