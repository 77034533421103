import { useQuery } from '@apollo/client';

import {
  ResultOf,
  SERIALISED_PRODUCT_UNIQUE_URL,
} from '@scannable/graphql-queries';

export type UseItemScreenData = NonNullable<
  ResultOf<typeof SERIALISED_PRODUCT_UNIQUE_URL>['serialisedProduct']
>;

interface UseUniqueItemQueryProps {
  serialisedProductId?: number | null;
}

export function useItemScreenQuery({
  serialisedProductId,
}: UseUniqueItemQueryProps) {
  return useQuery(SERIALISED_PRODUCT_UNIQUE_URL, {
    variables: {
      where: {
        id: {
          equals: serialisedProductId,
        },
      },
    },
    fetchPolicy: 'network-only',
    skip: !serialisedProductId,
  });
}
