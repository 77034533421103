import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GroupTypeIconMapping } from '@scannable/frontend/translations';
import { SEARCH_GROUPS } from '@scannable/graphql-queries';

const filter = {
  groupedItems: {
    some: {
      group: {
        is: {
          id: {
            in: '{VAL}',
          },
        },
      },
    },
  },
};

export function useGroupFilter() {
  const [searchGroups, { loading }] = useLazyQuery(SEARCH_GROUPS);

  const loadOptions = useCallback(
    async (
      inputValue: string
    ): Promise<
      {
        label: string;
        value: number;
        icon: string;
      }[]
    > => {
      const { data } = await searchGroups({
        variables: { search: inputValue, take: 10 },
      });
      return data?.searchGroups
        ? data.searchGroups.map((group) => ({
            label: group.name,
            value: group.id,
            icon: GroupTypeIconMapping[group.type],
          }))
        : [];
    },
    [searchGroups]
  );

  return {
    interpolatedFilter: filter,
    options: loadOptions,
    isLoading: loading,
  };
}
