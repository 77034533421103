import { InspectionStatus } from '@prisma/client';
import {
  differenceInCalendarMonths,
  differenceInDays,
  differenceInWeeks,
  differenceInYears,
  format,
  isDate,
  isPast,
  isToday,
} from 'date-fns';

import { KIT_ID_URL } from './constants';
import { dateFormat } from './dates.utils';

export const incrementPaddingValue = (value: number) => {
  switch (value) {
    case 0:
      return 'No increment';
    case 3:
      return '001';
    case 4:
      return '0001';
    case 5:
      return '00001';
    default:
      return 'No increment';
  }
};

export function fileExtensionLowerCase(word: string, extensionName: string) {
  const fileExtensionString = word.toUpperCase();

  return fileExtensionString.replace(
    extensionName,
    extensionName.toLowerCase()
  );
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
export const cn = (...cn: string[]) => classNames(...cn);

export function capitaliseFirstLetter(word: string) {
  //if a string comes in all uppercase (happens with serialisedProduct.detail.data)
  if (word.toUpperCase() === word && word !== word.toLowerCase()) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function ucFirst(word: string) {
  return capitaliseFirstLetter(word);
}

/**
 * here we can clean up a search term to make it easier to parse.
 * We can add any other clean up here when scenarios arises
 * @param term
 * @returns
 */
export const cleanseSearchString = (term: string) => {
  let cleanedUp = term.trim();
  cleanedUp = removeNfcIdFromString(cleanedUp);
  cleanedUp = removePrependedUrl(cleanedUp);

  return cleanedUp;
};

export const removeNfcIdFromString = (s: string) => {
  if (s.startsWith('0x')) {
    return s.slice(2);
  }
  return s;
};

export const removePrependedUrl = (s: string) => {
  if (s.startsWith('URL:')) {
    return s.slice(4);
  }
  return s;
};

export const lastInspectionFormat = (lastInspection?: {
  status: InspectionStatus;
  date: string;
}) => {
  if (!lastInspection) {
    return '';
  }
  const dateStr = dateFormat(lastInspection.date);

  const titles: Record<InspectionStatus, string> = {
    FAILED: 'Failed',
    PASSED: 'Passed',
    QUARANTINED: 'Quarantined',
    MONITORING: 'Monitoring',
  };

  const statusText = !lastInspection.status
    ? ''
    : titles[lastInspection.status];

  return `${statusText}: ${dateStr}`;
};

export const isRouteProtected = (path: string) => {
  return path.includes('/admin') || path.includes('/upgrade');
};

export const formatUnit = (unit: string | undefined | null) => {
  if (!unit) {
    return null;
  }
  switch (unit) {
    case 'percentage':
      return '%';

    default:
      return unit;
  }
};

export const getNextDueMessage = (dueDate: Date) => {
  if (!dueDate || !isDate(dueDate)) {
    return '';
  }

  if (isPast(dueDate)) {
    return `Past Due`;
  }

  if (isToday(dueDate)) {
    return `Today`;
  }

  const daysDifference = differenceInDays(dueDate, new Date());
  const weeksDifference = differenceInWeeks(dueDate, new Date());
  const monthsDifference = differenceInCalendarMonths(dueDate, new Date());
  const yearsDifference = differenceInYears(dueDate, new Date());

  if (daysDifference < 7) {
    const days = daysDifference < 1 ? 1 : daysDifference;
    return `${days} day${days !== 1 ? 's' : ''}`;
  } else if (weeksDifference < 4) {
    const weeks = weeksDifference < 1 ? 1 : weeksDifference;
    return `${weeks} week${weeks !== 1 ? 's' : ''}`;
  } else if (monthsDifference < 12) {
    const months = monthsDifference < 1 ? 1 : monthsDifference;
    return `${months} month${months !== 1 ? 's' : ''}`;
  }
  const years = yearsDifference < 1 ? 1 : yearsDifference;
  return `${years} year${years !== 1 ? 's' : ''}`;
};

export const justAdded = (createdAt: string) => {
  const now = new Date();
  const created = new Date(createdAt);
  const diff = now.getTime() - created.getTime();
  const diffInMinutes = Math.round(diff / 60000);
  return diffInMinutes < 5 ? 'Just added' : format(created, 'd MMM yyyy');
};

export function itemUidUrl(uid?: string) {
  return `${KIT_ID_URL}${
    KIT_ID_URL && !KIT_ID_URL.endsWith('/') ? '/' : ''
  }${uid}`;
}

export function truncateString(
  search?: string | null,
  config?: { limit: number; spaceFiller?: string }
) {
  if (!search) {
    return '';
  }
  const limit = config?.limit || 200;
  const spaceFiller = config?.spaceFiller || '';
  if (search.length > limit) {
    return `${search.trim().substring(0, limit)}${spaceFiller}`;
  }
  return search;
}

export function currencyDisplay(currency: string) {
  switch (currency) {
    case 'usd':
      return '$USD';
    case 'eur':
      return '€EUR';
    case 'gbp':
      return '£GBP';
    case 'nzd':
      return '$NZD';
    default:
      return currency;
  }
}
