import { ApolloError, ServerError } from '@apollo/client';
import { GraphQLFormattedError } from 'graphql';

import { decodeJwt } from '@scannable/common';
import * as Sentry from '@scannable/frontend/sentry';

import { refreshJwtToken } from './new-token-queries';

export let isRefreshing = false;
let pendingRequests: (() => unknown)[] = [];
let newJwtToken = '';
export const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

export const addPendingRequest = (pendingRequest: () => unknown) => {
  pendingRequests.push(pendingRequest);
};

export const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

export const clearPendingRequests = () => {
  pendingRequests = [];
};

export const setNewJwtToken = (value: string) => {
  newJwtToken = value;
};
export const getNewJwtToken = () => newJwtToken;

export const getNewToken = async (
  endpoint: string,
  getOrganisationId?: () => Promise<number | null>,
  getRefreshToken?: () => Promise<string | null>
) => {
  try {
    let organisationId: number | null = null;
    let refreshToken: string | null = null;
    // because cookies are not well supported in react native, we need to use fetch token from storage
    // we can assume this is a native refresh if getRefreshToken and getOrganisationId are defined
    if (getRefreshToken && getOrganisationId) {
      organisationId = await getOrganisationId();
      if (!organisationId) {
        throw new Error('No organisation');
      }
      refreshToken = await getRefreshToken();
    }
    return await refreshJwtToken(endpoint, refreshToken, organisationId);
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, errors: [error] };
  }
};
export const checkTokenExpiry = (
  currentToken: string | null,
  offsetMs = 10000
) => {
  if (!currentToken) {
    return false;
  }
  const { exp } = decodeJwt(currentToken);
  // check if token is expired in 10 seconds
  if (exp && exp * 1000 - Date.now() < offsetMs) {
    return true;
  }
  return false;
};
export const apolloError = (err: GraphQLFormattedError, errorMessage: string) =>
  new ApolloError({
    errorMessage,
    networkError: {
      response: err.extensions?.response,
      result: {},
      statusCode: err.extensions?.originalError
        ? Number(
            (
              err.extensions.originalError as {
                response: unknown;
                result: unknown;
                statusCode: number;
              }
            ).statusCode
          )
        : null,
    } as ServerError,
  });

export const extractOrginalError = (extension: {
  originalError: {
    message: string[] | string;
    statusCode: number;
    error: string;
  };
  code: string;
  status: string;
}) => {
  if (!extension.originalError?.message) {
    return null;
  }
  if (Array.isArray(extension.originalError.message)) {
    return extension.originalError.message[0];
  }
  return extension.originalError.message;
};
