import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Teams } from '@scannable/common';
import {
  useGetTeamQuery,
  useUpdateTeamMutation,
} from '@scannable/frontend/common';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export interface EditTeamModalProps {
  title: string;
  id: string;
}
interface EditTeamValues {
  name: string;
  string: string;
  nfc: string;
}
const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter a name'),
});

export function EditTeamModal({ title, id }: EditTeamModalProps) {
  const { hideModal } = useModal();

  const [updateTeam] = useUpdateTeamMutation({
    refetchQueries: [Teams.GetTeams, Teams.GetTeam],
  });
  const { data: teamData } = useGetTeamQuery(id);

  const formik = useFormik({
    initialValues: {
      name: teamData?.team?.name ?? '',
    } as EditTeamValues,
    validationSchema,
    onSubmit: async (
      values: EditTeamValues,
      actions: FormikHelpers<EditTeamValues>
    ) => {
      const result = await resolveMutation(
        updateTeam({
          variables: {
            data: {
              name: values.name,
              id,
            },
          },
        }),
        {
          successMessage: 'Update team successfully',
        }
      );
      actions.setSubmitting(false);
      if (result.ok) {
        hideModal();
      }
    },
  });
  return (
    <Modal title={title}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: 'Save',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
            'data-cy': 'save-team',
          },
        ]}
      >
        <InputFieldGroup
          data-cy="name"
          id="Name"
          name="name"
          type="text"
          formik={formik}
          label="Name"
          value={formik.values.name}
        />
      </ModalForm>
    </Modal>
  );
}

export default EditTeamModal;
