export interface CheckBoxInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: HTMLInputElement['name'];
}

export function CheckBoxInput(props: CheckBoxInputProps) {
  return (
    <input
      type="checkbox"
      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      {...props}
    />
  );
}

export default CheckBoxInput;
