import { FormikHelpers, useFormik } from 'formik';

import {
  NotificationPreference,
  NotificationPreferenceStatus,
  NotificationType,
} from '@scannable/common';
import {
  useGetUserNotificationPreferencesQuery,
  useUpdateUserNotificationPreferencesMutation,
} from '@scannable/frontend/common';

import { useSidePanel } from '..';
import { SidePanelForm } from '../../forms';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import { withSidePanelLoading } from '../withSidePanelLoading/withSidePanelLoading';
import {
  EditUserNotificationPreferencesValues,
  SidePanelProps,
} from './NotificationPreferencesSidePanel.types';

function EditUserNotificationPreferencesSidePanel({
  preferences: initialPreferences,
}: SidePanelProps) {
  const { hideSidePanel } = useSidePanel();
  const { t } = useTranslation();
  const [update] = useUpdateUserNotificationPreferencesMutation({
    refetchQueries: [NotificationPreference.GetUserNotificationPreferences],
  });
  const inspectionDue = initialPreferences.find(
    (p) => p.type === NotificationType.INSPECTION_DUE
  );
  const expiryDue = initialPreferences.find(
    (p) => p.type === NotificationType.EXPIRY_DUE
  );

  const formik = useFormik<EditUserNotificationPreferencesValues>({
    initialValues: {
      ...(inspectionDue?.id && { inspectionDueId: inspectionDue?.id }),
      inspectionDue: inspectionDue
        ? inspectionDue?.status === NotificationPreferenceStatus.ENABLED
        : true,
      ...(expiryDue?.id && { expiryDueId: expiryDue?.id }),
      expiryDue: expiryDue
        ? expiryDue?.status === NotificationPreferenceStatus.ENABLED
        : true,
    },
    onSubmit: async (
      values: EditUserNotificationPreferencesValues,
      actions: FormikHelpers<EditUserNotificationPreferencesValues>
    ) => {
      const result = await resolveMutation(
        update({
          variables: {
            data: {
              preferences: [
                {
                  id: values.inspectionDueId,
                  type: NotificationType.INSPECTION_DUE,
                  status: values.inspectionDue
                    ? NotificationPreferenceStatus.ENABLED
                    : NotificationPreferenceStatus.DISABLED,
                },
                {
                  id: values.expiryDueId,
                  type: NotificationType.EXPIRY_DUE,
                  status: values.expiryDue
                    ? NotificationPreferenceStatus.ENABLED
                    : NotificationPreferenceStatus.DISABLED,
                },
              ],
            },
          },
        }),
        {
          successMessage: 'Notification preferences updated',
        }
      );
      actions.setSubmitting(false);
      if (result.ok) {
        hideSidePanel();
      }
    },
  });

  return (
    <SidePanelForm
      formik={formik}
      buttons={[
        {
          label: t('save'),
          type: 'submit',
          loading: formik.isSubmitting,
          color: 'primary',
          disabled: !formik.dirty,
          'data-cy': 'save-item',
        },
      ]}
    >
      <div className="flex flex-row justify-between mb-4 pb-4 border-b border-gray-200">
        <div>
          <div className="text-sm font-semibold text-gray-800">
            {t('notifications.inspection_due_next_30_days')}
          </div>
          <div className="text-xs text-gray-500">
            {t('notifications.sent_weekly')}
          </div>
        </div>
        <InputFieldGroup
          data-cy="inspectionDue"
          id="inspectionDue"
          name="inspectionDue"
          type="checkbox"
          formik={formik}
        />
      </div>
      <div className="flex flex-row justify-between mb-4 pb-4 border-b border-gray-200">
        <div>
          <div className="text-sm font-semibold text-gray-800">
            {t('notifications.expiry_due_next_30_days')}
          </div>
          <div className="text-xs text-gray-500">
            {t('notifications.sent_weekly')}
          </div>
        </div>
        <InputFieldGroup
          data-cy="expiryDue"
          id="expiryDue"
          name="expiryDue"
          type="checkbox"
          formik={formik}
        />
      </div>
    </SidePanelForm>
  );
}

const WrappedSidePanel = withSidePanelLoading(
  EditUserNotificationPreferencesSidePanel
);

export function NotificationPreferencesSidePanel() {
  const { data, loading, error } = useGetUserNotificationPreferencesQuery();

  return (
    <WrappedSidePanel
      loading={loading}
      error={error}
      preferences={data?.preferences ?? []}
    />
  );
}
export default NotificationPreferencesSidePanel;
