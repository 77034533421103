import { useQuery } from '@apollo/client';

import { PRODUCT_CATEGORIES_BY_MANUFACTURER } from '@scannable/graphql-queries';

import { useAuth } from '../../auth/AuthContext/AuthContext';

export interface UseManufacturerProductGrouping {
  options: { label: string; value: number }[];
}
export function useManufacturerProductGrouping(
  manufacturerId?: number | null
): UseManufacturerProductGrouping {
  const { manufacturerId: useManufacturerId } = useAuth();
  const id = manufacturerId ?? useManufacturerId;
  const { data } = useQuery(PRODUCT_CATEGORIES_BY_MANUFACTURER, {
    variables: { manufacturerId: id },
    skip: !id,
  });

  const options = (data?.productGroups ?? [])
    .map((pg) => ({
      label: pg.name,
      value: pg.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    options: options ?? [],
  };
}

export default useManufacturerProductGrouping;
