import { useManufacturerProductGrouping } from '../../hooks';

const filter = {
  productGroupId: {
    in: '{VAL}',
  },
};

export function useManufacturerProductCategoryFilter(manufacturerId?: number) {
  const { options } = useManufacturerProductGrouping(manufacturerId);

  return {
    options,
    interpolatedFilter: filter,
  };
}
