export enum FileFolder {
  Avatar = 'avatar',
  Inspections = 'inspections',
  Logos = 'logos',
  Pdfs = 'pdfs',
  Photos = 'photos',
  Serials = 'serials',
  Static = 'static',
  Signature = 'signature',
}
