import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';

import { Inventory, ItemHistory, SerialisedProduct } from '@scannable/common';
import { LINK_NFC, REMOVE_ITEM_NFC_TAGS } from '@scannable/graphql-queries';

import { GroupListItem } from '../../atoms';
import useTranslation from '../../hooks/useTranslation/useTranslation';
import { resolveMutation } from '../../lib/lib';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';
import { useModal } from '../../modals/ModalContext/ModalContext';
import { NfcInput } from '../../molecules/NfcInput/NfcInput';

interface NfcListItemProps {
  nfcTag?: string | null;
  hasNfc: boolean;
  serialisedProductId?: number;
  isPublicUrl?: boolean;
  nfcTags: string[] | null | undefined;
}

export function NfcListItem({
  nfcTag,
  isPublicUrl,
  hasNfc,
  serialisedProductId,
  nfcTags,
}: NfcListItemProps) {
  const [removeAliases] = useMutation(REMOVE_ITEM_NFC_TAGS, {
    refetchQueries: [
      SerialisedProduct.SerialisedProductForUniqueUrl,
      Inventory.PaginatedWebInventory,
      ItemHistory.PaginatedItemHistory,
    ],
  });
  const [value, setValue] = useState(nfcTag ?? '');
  const [linkNfc] = useMutation(LINK_NFC, {
    refetchQueries: [
      SerialisedProduct.SerialisedProductForUniqueUrl,
      Inventory.PaginatedWebInventory,
      ItemHistory.PaginatedItemHistory,
    ],
  });
  const { showModal, hideModal } = useModal();
  const { t, tInterpolate } = useTranslation();

  const handleRemoveNFC = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t('nfc.remove_tags')}
        message={t('nfc.confirm_remove_tags_from_item')}
        buttonLabel={t('nfc.remove_tags')}
        onConfirm={async () => {
          if (!serialisedProductId) {
            return;
          }
          const result = await resolveMutation(
            removeAliases({
              variables: {
                data: {
                  itemId: serialisedProductId,
                },
              },
            }),
            {
              successMessage: t('nfc.tags_removed_successfully'),
              failureMessage: t('nfc.tags_removed_failed'),
            }
          );

          if (result.ok) {
            setValue('');
            hideModal();
          }
        }}
      />
    );
  }, [hideModal, removeAliases, serialisedProductId, showModal, t]);

  if (isPublicUrl && !hasNfc) {
    return null;
  }
  if (isPublicUrl && hasNfc) {
    const defaultNFCAddedText = t('nfc.one_nfc_added');

    const nfcValue = Array.isArray(nfcTags)
      ? nfcTags.length > 1
        ? tInterpolate('nfc.multiple_nfcs_added', nfcTags.length)
        : defaultNFCAddedText
      : defaultNFCAddedText;

    return <GroupListItem name="NFC" value={nfcValue} isNfc />;
  }

  return (
    <GroupListItem
      name="NFC"
      isNfc
      renderValue={() => {
        return (
          <NfcInput
            nfcTags={nfcTags}
            id="NfcInput"
            name="nfc"
            onChange={(e) => setValue(e.target.value)}
            clearNfc={() => setValue('')}
            value={value}
            onRemove={handleRemoveNFC}
            onNfcScanComplete={async (nfc) => {
              await resolveMutation(
                linkNfc({
                  variables: {
                    data: {
                      serialisedProductId,
                      alias: nfc,
                    },
                  },
                }),
                {
                  successMessage: 'NFC Added',
                  failureMessage: 'Could Not Link NFC',
                }
              );
            }}
          />
        );
      }}
    />
  );
}

export default NfcListItem;
