import { useMutation } from '@apollo/client';

import {
  CREATE_EXAMINATION,
  CREATE_INSPECTION,
  CREATE_MANY_INSPECTIONS,
  UPDATE_EXAMINATION,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useCreateMultipleInspectionsMutation(
  options?: UseMutationOptions<typeof CREATE_MANY_INSPECTIONS>
) {
  return useMutation(CREATE_MANY_INSPECTIONS, options);
}

export function useCreateSingleInspectionMutation(
  options?: UseMutationOptions<typeof CREATE_INSPECTION>
) {
  return useMutation(CREATE_INSPECTION, options);
}

export function useCreateExaminationMutation(
  options?: UseMutationOptions<typeof CREATE_EXAMINATION>
) {
  return useMutation(CREATE_EXAMINATION, options);
}

export function useUpdateExaminationMutation(
  options?: UseMutationOptions<typeof UPDATE_EXAMINATION>
) {
  return useMutation(UPDATE_EXAMINATION, options);
}
