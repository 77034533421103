import { useApplicationStatus } from '../../hooks/useApplicationStatus/useApplicationStatus';

interface ApplicationsStatusProviderProps {
  children: React.ReactNode;
  renderMaintenanceMode?: () => React.ReactNode;
}

export function ApplicationStatusProvider({
  children,
  renderMaintenanceMode,
}: ApplicationsStatusProviderProps) {
  const { isInMaintenanceMode } = useApplicationStatus();

  if (isInMaintenanceMode && renderMaintenanceMode) {
    return <>{renderMaintenanceMode()}</>;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
