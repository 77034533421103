import {
  CriteriaResult,
  InspectionSource,
  InspectionStatus,
  InspectionType,
} from '@prisma/client';
import * as yup from 'yup';

import { DataValue } from '@scannable/common';
import {
  ActionType,
  GraphQLResult,
  GraphQLResultFromList,
  WrappedForm,
} from '@scannable/frontend/common';
import {
  GET_CHECKLIST,
  GET_CHECKLISTS_FOR_SERIALISED_PRODUCT,
} from '@scannable/graphql-queries';

import { ItemPreviewProps } from '../../molecules/ItemPreview/ItemPreview';

export const validationSchema = (
  checklistCriteria: InspectionChecklistCriteria[] | null | undefined
) =>
  yup.lazy(() => {
    if (checklistCriteria) {
      return yup.object().shape({
        criteria: yup.object().shape(
          checklistCriteria.reduce((acc, criteria) => {
            return {
              ...acc,
              [criteria.id]: yup.string().required(),
            };
          }, {})
        ),
      });
    }
    return yup.object().shape({});
  });

export type InspectionValues = {
  criteria: Record<string, CriteriaResult>;
  images?: File[];
  pdfs?: File[];
  passed: boolean;
  notes: string;
  status?: InspectionStatus | null;
  type?: InspectionType | null;
  source?: InspectionSource | null;
  manufacturerId: number;
  examinationId: null | string;
  swl: null | DataValue;
  selectAll?: CriteriaResult | null;
};

export type InspectionChecklist = GraphQLResultFromList<
  typeof GET_CHECKLISTS_FOR_SERIALISED_PRODUCT
>;

export type InspectionChecklistWithCriteria = GraphQLResult<
  typeof GET_CHECKLIST
>;

export type InspectionChecklistCriteria =
  InspectionChecklistWithCriteria['checklistCriteria'][0];

export type InspectionSidePanelProps = CreateInspectionSidePanelProps & {
  currentChecklistId?: string | null;
  checklists?: InspectionChecklist[] | null;
  selectedChecklistCriteria?: InspectionChecklistCriteria[] | null;
  onChecklistChange: (id: string | null) => void;
  isChecklistsEnabled?: boolean;
  examinationOptions: { value: string; label: string }[];
  isCompetentPerson: boolean;
  swlValue?: DataValue;
} & Partial<InspectionValues> &
  Partial<WrappedForm>;

export type CreateInspectionSidePanelProps = {
  isMultiScanInspection: boolean;
  id?: number;
  ids?: number[];
  actionType: ActionType;
} & ItemPreviewProps;
