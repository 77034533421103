import { XCircleIcon } from '@heroicons/react/20/solid';

export interface AlertProps {
  type: 'success' | 'info' | 'warning' | 'error';
  message?: React.ReactNode;
  messages?: string[] | null;
}
export function Alert({ type, message, messages }: AlertProps) {
  let color = 'red';
  switch (type) {
    case 'success':
      color = 'green';
      break;
    case 'warning':
      color = 'yellow';
      break;
    case 'info':
      color = 'blue';
      break;
  }
  return (
    <div className={`rounded-md bg-${color}-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon
            className={`h-5 w-5 text-${color}-400`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {message && (
            <h3 className={`text-sm font-medium text-${color}-800`}>
              {message}
            </h3>
          )}
          {messages && messages.length > 0 && (
            <div className={`mt-2 text-sm text-${color}-700`}>
              <ul className="list-disc pl-5 space-y-1">
                {messages.map((m, i) => (
                  <li key={i}>{m}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Alert;
