import { classNames } from '@scannable/common';

interface ProductPageDataTabProps {
  tabIndex: number;
  selectedTabNumber: number;
  children: React.ReactNode;
}

export function ProductPageDataTab({
  selectedTabNumber,
  tabIndex,
  children,
}: ProductPageDataTabProps) {
  return (
    <div
      className={classNames(
        `p-6 bg-white rounded-b-3xl flex-wrap`,
        tabIndex === selectedTabNumber ? '' : 'hidden'
      )}
    >
      {children}
    </div>
  );
}

export default ProductPageDataTab;
