import { createContext } from 'react';
import { FetchResult } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';

import {
  initialAuthState,
  RoleType,
  SIMPLE_ERROR_MESSAGE,
} from '@scannable/common';
import { ApolloConfig } from '@scannable/frontend/apollo';
import { AuthContextState } from '@scannable/frontend/types';

import { errorAlert } from '../../utils';

export const AuthContext = createContext<AuthContextState>({
  ...initialAuthState,
  isLoggedIn: false,
  manufacturerId: 0,
  organisationId: 0,
  isPersonalAccount: false,
  signIn: () => new Promise(() => null),
  signOut: () => new Promise(() => null),
  impersonate: (organisationId: number) => new Promise(() => null),
  retrieveLocalState: () => new Promise(() => null),
  changeOrganisation: (organisationId: number) => new Promise(() => null),
  refreshAccount: (organisationId?: number) => new Promise(() => null),
  reloadUser: () => new Promise(() => null),
  guard: (roles: RoleType[] | undefined) => false,
  isAManager: false,
  isOnTrial: false,
  isOnTrialOrAccess: false,
});

export const config = {
  key: 'CLIENT',
  origin: process.env.NEXT_PUBLIC_ORIGIN,
  endpoint: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  wsEndpoint: process.env.NEXT_PUBLIC_GRAPHQL_WS_ENDPOINT,
  wsReconnectAttempts: 5,
} as ApolloConfig;

// helper function for apollo client mutations and queries. Could be extracted in future
export async function resolve<T>(
  promise: Promise<FetchResult<T>>,
  errorMessage = SIMPLE_ERROR_MESSAGE,
  showError = true
): Promise<[FetchResult<T> | null, unknown]> {
  try {
    const result = await promise;
    if (result.errors || !result.data) {
      Sentry.captureMessage(
        result.errors ? result.errors[0].message : 'No data returned'
      );
      if (showError) {
        errorAlert(errorMessage);
      }
      return [null, result.errors];
    }
    return [result, null];
  } catch (error) {
    Sentry.captureException(error);
    if (showError) {
      errorAlert(errorMessage);
    }
    return [null, error];
  }
}
