import { useDispatch } from 'react-redux';

import { resetTableFilters } from '@scannable/frontend/store';

import { Button } from '../../atoms';
import { SidePanelLayout } from '../../layouts';
import { FieldFilter } from '../../tables/FieldFilter/FieldFilter';
import { TableFilter } from '../../types/table.types';

interface FilterSidePanelProps {
  filters: TableFilter[];
  tableName: string;
}
export function FilterSidePanel({ filters, tableName }: FilterSidePanelProps) {
  const dispatch = useDispatch();
  return (
    <SidePanelLayout
      actions={() => (
        <Button
          type="button"
          onClick={() => dispatch(resetTableFilters())}
          className="w-full"
        >
          Clear Filters
        </Button>
      )}
    >
      <div className="space-y-4">
        {filters
          .filter((f) => f.display === undefined || f.display === true)
          .map((f) => (
            <FieldFilter
              {...f}
              tableName={tableName}
              key={f.filterName}
              callbackFilter={f.onFilterChanged}
            />
          ))}
      </div>
    </SidePanelLayout>
  );
}
