import { useMutation } from '@apollo/client';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { SessionType } from '@prisma/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { USER_LOGIN } from '@scannable/graphql-queries';

import { Button, Link, LoadingIcon } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import useTranslation from '../../hooks/useTranslation/useTranslation';
import { resolveMutation } from '../../lib/lib';
import { AdminPanel } from '../../molecules/AdminPanel/AdminPanel';
import { CustomForm } from '../../molecules/CustomForm/CustomForm';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import { LoggedInMessage } from '../../molecules/LoggedInMessage/LoggedInMessage';
import { setLastEmail } from '../../utils';

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
});

export interface SignInFormmValues {
  email: string;
  password: string;
}

export interface SignInFormProps {
  initialEmail: string;
}

export function SignInForm(props: SignInFormProps) {
  const {
    signIn,
    loading,
    isLoggedIn,
    userId,
    redirecting,
    signOut,
    retrieveLocalState,
  } = useAuth();
  const [login] = useMutation(USER_LOGIN);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: props.initialEmail || '',
      password: '',
    },
    validationSchema: signInValidationSchema,
    onSubmit: async (values: SignInFormmValues) => {
      const localState = await retrieveLocalState();
      const result = await resolveMutation(
        login({
          variables: {
            data: {
              ...values,
              organisationId: localState.organisationId,
              manufacturerId: localState.manufacturerId,
              sessionType: SessionType.WEB,
            },
          },
        }),
        {
          successMessage: 'Welcome back',
          failureMessage: 'Incorrect email or password',
        }
      );

      if (result.ok) {
        signIn(result.data.login.accessToken);
        setLastEmail(values.email);
      }
    },
  });
  if (loading || redirecting) {
    return (
      <AdminPanel>
        <div className="relative flex min-h-[300px] items-center justify-center">
          <LoadingIcon size="sm" />
        </div>
      </AdminPanel>
    );
  }
  if (isLoggedIn || userId) {
    return (
      <AdminPanel>
        <LoggedInMessage signOut={signOut} />
      </AdminPanel>
    );
  }

  return (
    <CustomForm className="mt-8 space-y-6" formik={formik}>
      <div className="min-h-[300px] shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-4 bg-white py-3 px-4 sm:p-6">
          <InputFieldGroup
            type="email"
            name="email"
            label={t('email')}
            placeholder={t('email')}
            formik={formik}
          />
          <InputFieldGroup
            type="password"
            name="password"
            label={t('password')}
            placeholder={t('password')}
            formik={formik}
          />
        </div>

        <div className="bg-gray-50 px-4 py-4 text-right sm:px-6">
          <Button
            type="submit"
            className="relative flex w-full justify-center py-2 px-4"
            loading={formik.isSubmitting}
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon
                className="text-whit h-5 w-5"
                aria-hidden="true"
              />
            </span>
            {t('sign_in')}
          </Button>
          <div className="my-4 flex items-center justify-end">
            <div className="text-sm">
              <Link
                href="/auth/sign-up"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Don&apos;t have an account? Sign up
              </Link>
            </div>
          </div>
          <div className="my-4 flex items-center justify-end">
            <div className="text-sm">
              <Link
                href="/auth/forgot-password"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {t('auth.forgot_password')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </CustomForm>
  );
}

export default SignInForm;
