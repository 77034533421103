import { useMutation } from '@apollo/client';

import {
  ACCEPT_INVITE,
  DECLINE_INVITE,
  SEND_INVITES,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useDeclineInviteMutation(
  options?: UseMutationOptions<typeof DECLINE_INVITE>
) {
  return useMutation(DECLINE_INVITE, options);
}

export function useAcceptInviteMutation(
  options?: UseMutationOptions<typeof ACCEPT_INVITE>
) {
  return useMutation(ACCEPT_INVITE, options);
}

export function useSendInvitesMutation(
  options?: UseMutationOptions<typeof SEND_INVITES>
) {
  return useMutation(SEND_INVITES, options);
}
