import { FormikProps } from 'formik';

import { SkuSearchResult } from '@scannable/frontend/types';

import {
  DescriptionList,
  DescriptionListItem,
  Input,
  InputGroup,
} from '../../molecules';

export interface ComponentsDescriptionListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  composite: SkuSearchResult;
}

export function ComponentsDescriptionList({
  formik,
  composite,
}: ComponentsDescriptionListProps) {
  return (
    <DescriptionList
      title="Components"
      subTitle="This is a composite product. Please enter batch numbers for each of components"
    >
      <DescriptionListItem>
        {composite?.components &&
          composite.components.map((child, i) => (
            <div className="my-5" key={`${child.id}`}>
              <InputGroup
                label={`Batch number for ${child.name}`}
                htmlFor={`BatchNumber_${child.id}`}
                name={`componentBatchNumbers[${i}]`}
                touched={formik.touched[`componentBatchNumbers[${i}]`]}
                error={formik.errors[`componentBatchNumbers[${i}]`]}
              >
                <Input
                  id={`BatchNumber_${child.id}`}
                  placeholder="Enter a batch number"
                  name={`componentBatchNumbers[${i}]`}
                  type="text"
                  onBlur={formik.handleBlur}
                  value={formik.values[`componentBatchNumbers[${i}]`]}
                  onChange={(e) => {
                    formik.setFieldValue(`componentBatchNumbers[${i}]`, {
                      productVariationId: child.id,
                      batchNumber: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </div>
          ))}
      </DescriptionListItem>
    </DescriptionList>
  );
}

export default ComponentsDescriptionList;
