import { GetServerSideProps } from 'next';
import * as Sentry from '@sentry/nextjs';

import { ApolloConfig, initApolloClient } from '@scannable/frontend/apollo';
import { PUBLIC_GROUP } from '@scannable/graphql-queries';

export const getServerSideGroup: GetServerSideProps = async (context) => {
  const { uuid } = context.query;
  const config = {
    key: 'SERVER',
    origin: process.env.NEXT_PUBLIC_ORIGIN,
    endpoint: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
    getToken: async () => {
      return '';
    },
    getUserId: async () => {
      return '';
    },
  } as ApolloConfig;
  const apolloClient = initApolloClient({}, config);
  try {
    const { data } = await apolloClient.query({
      query: PUBLIC_GROUP,
      variables: {
        id: uuid as string,
      },
    });
    return {
      props: {
        data: data?.publicGroup || null,
      },
    };
  } catch (error) {
    Sentry.captureException(error);
    return {
      props: {
        data: null,
      },
    };
  }
};
