import Link from 'next/link';

import { AuthContextState } from '@scannable/frontend/types';

import { Text } from '../../atoms';

export interface LoggedInMessageProps {
  signOut: AuthContextState['signOut'];
}

export function LoggedInMessage({ signOut }: LoggedInMessageProps) {
  return (
    <div className="text-center">
      <Text>You are already logged in.</Text>
      <Text>
        Perhaps you want to go to the{' '}
        <Link
          href="/admin"
          passHref
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          dashboard
        </Link>
        ?
      </Text>
      <Text>
        Or sign out{' '}
        <span
          className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
          onClick={() => signOut()}
        >
          here
        </span>
        .
      </Text>
    </div>
  );
}

export default LoggedInMessage;
