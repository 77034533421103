import { ReactNode } from 'react';
import { FormikProps } from 'formik';

import { Alert, Button, ButtonProps } from '../../atoms';
import { SidePanelLayout } from '../../layouts';
import Form from '../Form/Form';

type SidePanelFormProps<V> = {
  formik: FormikProps<V>;
  children?: ReactNode;
  warning?: string;
  buttons?: (ButtonProps & { 'data-cy'?: string })[];
} & React.HTMLAttributes<HTMLFormElement>;

export function SidePanelForm<V>({
  formik,
  children,
  warning,
  buttons,
  onKeyDown,
  ...props
}: SidePanelFormProps<V>) {
  return (
    <Form formik={formik} onKeyDown={onKeyDown} {...props}>
      <SidePanelLayout
        actions={() => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {buttons && buttons.length > 0 && (
              <div className="flex space-x-3">
                {buttons.map((buttonProps, index) => (
                  <Button
                    key={index}
                    className="w-full disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400 disabled:bg-gray-200"
                    disabled={formik.isSubmitting || !formik.isValid}
                    loading={formik.isSubmitting}
                    {...buttonProps}
                  />
                ))}
              </div>
            )}
          </>
        )}
      >
        <div className="mt-2 space-y-4">{children}</div>

        {warning && (
          <div className="pt-4 text-sm">
            <Alert type="warning" message={warning} />
          </div>
        )}
      </SidePanelLayout>
    </Form>
  );
}

export default SidePanelForm;
