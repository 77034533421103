import { useCallback, useEffect, useState } from 'react';
import { ProductType } from '@prisma/client';
import { isNumber } from '@sniptt/guards';
import { useDispatch } from 'react-redux';

import { UniqueItemComponent } from '@scannable/common';
import { addSku } from '@scannable/frontend/store';
import { Search, SerialPuchaseOrderLineItem } from '@scannable/frontend/types';

import { Button, ResizedImage } from '../../atoms';
import { useTranslation } from '../../hooks';
import { SearchInput } from '../../organisms';
import { errorAlert } from '../../utils';
import { useModal } from '../ModalContext/ModalContext';
import SimpleModal from '../SimpleModal/SimpleModal';

export interface CreateAssemblyModalProps {
  assembly: Search;
  components: UniqueItemComponent[] | null;
}

export function CreateAssemblyModal({
  assembly,
  components,
}: CreateAssemblyModalProps) {
  const { t } = useTranslation();
  const [componentsState, setComponentsState] = useState<
    UniqueItemComponent[] | null
  >(components);
  const { hideModal } = useModal();
  const [isComplete, setIsComplete] = useState(false);
  const dispatch = useDispatch();

  const addItemToAssembly = (item: Search) => {
    if (!componentsState) {
      return;
    }
    const matchingComponentIndex = componentsState.findIndex(
      (component) =>
        !component.serialNumber &&
        component.productVariationId === item?.productVariationId
    );
    if (matchingComponentIndex < 0) {
      errorAlert(
        'Scan does not match any component of this assembly, or it has already been scanned'
      );
      return;
    }
    setComponentsState((prev) => {
      if (prev) {
        const newComponents = [...prev];
        const matchingComponent = newComponents[matchingComponentIndex];
        matchingComponent.serialisedProductId = item.serialisedProductId;
        matchingComponent.serialNumber = item.info?.serialNumber || '';
        return newComponents;
      }
      return null;
    });
  };

  useEffect(() => {
    if (componentsState) {
      setIsComplete(
        componentsState.every((component) => component.serialisedProductId)
      );
    }
  }, [componentsState]);

  const handleProductVariationSubmit = useCallback(
    (values: SerialPuchaseOrderLineItem) => {
      dispatch(addSku({ ...values }));
    },
    [dispatch]
  );

  const handleRemoveSerial = useCallback((component: UniqueItemComponent) => {
    setComponentsState((prev) => {
      if (prev) {
        const newComponents = [...prev];
        const matchingComponent = newComponents.findIndex(
          (c) => c.productVariationId === component.productVariationId
        );
        if (matchingComponent > -1) {
          newComponents[matchingComponent].serialisedProductId = undefined;
          newComponents[matchingComponent].serialNumber = undefined;
        }
        return newComponents;
      }
      return null;
    });
  }, []);
  const title = 'Create an Assembly';

  return (
    <SimpleModal title={title} size="lg">
      <div className="w-full">
        <SearchInput
          name="serialisedProductId"
          type="GlobalItemsOnly"
          buttonLabel="Search"
          onSearchCallback={async (item) => {
            if (item.serialisedProductId) {
              addItemToAssembly({ ...item, id: item.serialisedProductId });
            }
          }}
          resetAfterCallback
        />
      </div>
      <div className="mb-10">
        <ul>
          {(componentsState || []).map((component, i) => (
            <li
              key={`${component.productVariationId}-${i}`}
              className="relative py-4 flex flex-row border-t-gray-200 border-t"
            >
              <ResizedImage
                className="rounded object-contain object-center"
                src={component.featureImage?.uri || ''}
                alt={component.name || ''}
                height={40}
                width={40}
                fit="fill"
              />
              <div className="ml-3">
                <h3 className="text-gray-900 text-sm">{component.name}</h3>
                {component.serialisedProductId && (
                  <p className="text-sm text-green-500">COMPLETED</p>
                )}
              </div>
              <div className="flex-grow"></div>
              {component.serialisedProductId && (
                <div className="flex ml-5">
                  <Button
                    label="Remove Serial"
                    type="button"
                    color="white"
                    className="text-gray-700"
                    onClick={() => handleRemoveSerial(component)}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-row flex-spacing-4 border-t-gray-200 border-t pt-5">
        <div className="flex-grow" />
        <Button
          label="Close"
          type="button"
          color="white"
          className="text-red-600"
          onClick={() => hideModal()}
        />
        <Button
          className="ml-3"
          disabled={!isComplete}
          color={isComplete ? 'primary' : 'disabled'}
          data-cy="create-assembly-button"
          onClick={() => {
            handleProductVariationSubmit({
              productVariationId: Number(assembly.id),
              serialQuantity: 1,
              productType: ProductType.ASSEMBLY,
              componentSerials: (componentsState || [])
                .filter(
                  (c) =>
                    isNumber(c.productVariationId) &&
                    isNumber(c.serialisedProductId)
                )
                .map((c) => ({
                  serialisedProductId: c.serialisedProductId as number,
                  serialNumber: c.serialNumber ?? '',
                  productVariationId: c.productVariationId as number,
                })),
              productDom: null,
              productBatchNumber: '',
              productVariation: assembly,
              componentBatchNumbers: [],
            });
            hideModal();
          }}
        >
          {t('create_label')}
        </Button>
      </div>
    </SimpleModal>
  );
}

export default CreateAssemblyModal;
