export * from './types/types';
export * from './utils/db-enum-mappings.utils';
export * from './utils/translate.utils';
export * from './lang/en';
export * from './lang/es';
export * from './lang/fr';

export function interpolate() {
  return 'plural';
}
