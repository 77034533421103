import { getYoutubeId } from '@scannable/common';

export interface VideoEmbedProps {
  videoUrl: string;
}

export function VideoEmbed({ videoUrl }: VideoEmbedProps) {
  const embedId = getYoutubeId(videoUrl);

  if (embedId) {
    return (
      <iframe
        width="240"
        height="150"
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Youtube video"
      />
    );
  }

  return (
    <a
      href={`${videoUrl}`}
      className="underline"
      target="_blank"
      rel="noopener noreferrer"
    >
      {videoUrl}
    </a>
  );
}

export default VideoEmbed;
