import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const NAVIGATION_FEATURE_KEY = 'navigation';

export interface NavigationState {
  menuGroups: {
    key: string;
    active: boolean;
  }[];
  productVariationId?: number;
  serialisedProductId?: number;
}

export const initialNavigationState: NavigationState = {
  menuGroups: [
    {
      key: 'admin',
      active: false,
    },
    {
      key: 'owner',
      active: true,
    },
    {
      key: 'manufacturer',
      active: true,
    },
    {
      key: 'groups',
      active: true,
    },
  ],
  productVariationId: undefined,
  serialisedProductId: undefined,
};

export const navigationSlice = createSlice({
  name: NAVIGATION_FEATURE_KEY,
  initialState: initialNavigationState,
  reducers: {
    toggleMenuGroupState: (state, action: PayloadAction<string>) => {
      const group = state.menuGroups.find((g) => g.key === action.payload);
      if (group) {
        group.active = !group.active;
      }
    },
    setProductVariationId: (state, action: PayloadAction<number>) => {
      state.serialisedProductId = undefined;
      state.productVariationId = action.payload;
    },
    setSerialisedProductId: (state, action: PayloadAction<number>) => {
      state.productVariationId = undefined;
      state.serialisedProductId = action.payload;
    },
  },
});

export const {
  toggleMenuGroupState,
  setProductVariationId,
  setSerialisedProductId,
} = navigationSlice.actions;

export const navigationReducer = navigationSlice.reducer;

export const navigationActions = navigationSlice.actions;

export const getNavigationState = (rootState: {
  [NAVIGATION_FEATURE_KEY]: NavigationState;
}): NavigationState => rootState[NAVIGATION_FEATURE_KEY];

export const selectMenuGroupState = createSelector(
  getNavigationState,
  (state: NavigationState) => state.menuGroups
);

export const selectSkuOrItem = createSelector(
  getNavigationState,
  (state: NavigationState) => {
    if (state.productVariationId) {
      return {
        type: 'sku',
        id: state.productVariationId,
      };
    }
    if (state.serialisedProductId) {
      return {
        type: 'item',
        id: state.serialisedProductId,
      };
    }

    return undefined;
  }
);
