import { Icon, ResizedImage } from '../../atoms';

export interface ThumbnailImageProps {
  src: string;
  alt: string;
  showNfcIcon: boolean;
}
export function ThumbnailImage({ src, alt, showNfcIcon }: ThumbnailImageProps) {
  return (
    <div className="relative flex-shrink-0">
      <div className="rounded-lg overflow-hidden h-10 w-10">
        <ResizedImage
          src={src}
          alt={alt || ''}
          width={48}
          height={48}
          fit="fill"
        />
      </div>
      {showNfcIcon && (
        <div className="absolute bottom-0 right-0 bg-white rounded-full w-4 h-4 flex -mr-[1px] -mb-[1px]">
          <div className="flex-col bg-brand-primary rounded-lg w-5 h-5 pr-1 pt-1 pl-1">
            <Icon name="hasNfc" width={12} height={12} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ThumbnailImage;
