import { useRef, useState } from 'react';

import { cn, ItemAction } from '@scannable/common';
import { UseItemScreenData } from '@scannable/frontend/common';

import { Button, Icon, IconType } from '../../atoms';
import useOnClickOutside from '../../hooks/useOnClickOutside/useOnClickOutside';

export interface ItemOptionsAction {
  label: string;
  type?: 'normal' | 'danger';
  key?: keyof typeof ItemAction;
  display?: boolean;
  callback: (item: UseItemScreenData) => void;
  filter?: (item?: UseItemScreenData) => boolean;
  item: UseItemScreenData;
  icon?: IconType;
}

export interface OptionsMenuProps {
  actions: ItemOptionsAction[];
}

export function OptionsMenu({ actions }: OptionsMenuProps) {
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useOnClickOutside<HTMLDivElement>(menuRef, () => setIsOpen(false));

  return (
    <>
      {isOpen && (
        <div
          className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-20"
          onClick={() => {
            closeMenu();
          }}
        />
      )}
      <div className="absolute bottom-0 w-full">
        {isOpen && (
          <div
            ref={menuRef}
            className="bottom-0 py-4 mt-2 bg-white ring-1 ring-black ring-opacity-5 rounded-t-lg shadow-lg origin-bottom-right focus:outline-none"
          >
            <div className="px-6 text-sm text-gray-400">Options</div>
            <div className="py-1">
              {actions.map((action) =>
                action.display === false ? null : (
                  <button
                    key={action.label}
                    onClick={() => {
                      action.callback(action.item);
                      closeMenu();
                    }}
                    className={cn(
                      'block px-6 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 w-full text-left',
                      action.type === 'danger' ? 'text-red-600' : ''
                    )}
                  >
                    <div className="flex flex-row justify-between items-center">
                      {action.icon && (
                        <Icon
                          name={action.icon}
                          className={cn(
                            'mr-2 text-gray-400',
                            action.type === 'danger' ? 'text-red-600' : ''
                          )}
                          height={20}
                          width={20}
                        />
                      )}
                      <span className="flex-1">{action.label}</span>
                    </div>
                  </button>
                )
              )}
            </div>
          </div>
        )}
        <div className="flex justify-end bg-white pt-2 border-t border-gray-200">
          <Button onClick={toggleMenu} className="w-full mx-6 mb-2 px-4 py-2">
            Options
          </Button>
        </div>
      </div>
    </>
  );
}

export default OptionsMenu;
