import { usePaginatedItemHistory } from '@scannable/frontend/common';

import { EmptyState } from '../../atoms/EmptyState/EmptyState';
import LoadingIcon from '../../atoms/LoadingIcon/LoadingIcon';
import useInfiniteScroll from '../../hooks/useInfiniteScroll/useInfiniteScroll';
import HistoryItem from '../HistoryItem/HistoryItem';

export function PaginatedHistory({
  itemId,
  includePrivateData,
}: {
  itemId: number;
  includePrivateData?: boolean;
}) {
  const { loading, data, loadMore, hasMore } = usePaginatedItemHistory(itemId);

  const loader = useInfiniteScroll({ loadMore, hasMore });

  if (loading && !data) {
    return (
      <div className="relative h-40">
        <LoadingIcon size="sm" />
      </div>
    );
  }

  if (data?.paginatedItemHistory?.totalCount === 0) {
    return (
      <EmptyState
        title="Item history will show here."
        description="Get started by scanning the item, recording an inspection, or assigning the item to a group or team member."
      />
    );
  }

  return (
    <div>
      <div className="divide-y divide-gray-100">
        {data?.paginatedItemHistory?.nodes?.map((item, i) => (
          <HistoryItem
            key={i}
            item={item}
            includePrivateData={includePrivateData}
          />
        ))}
      </div>
      {loading && (
        <div className="relative h-40">
          <LoadingIcon size="sm" />
        </div>
      )}
      <div ref={loader} className="h-10"></div>
    </div>
  );
}
