const general = {
  create_btn: 'Créer',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  email: 'E-mail',
  password: 'Mot de passe',
  manufacturer: 'Fabricant',
  edit_manufacturer: 'Modifier fabricant',
  gear_list: 'Liste de matériel',
  role: 'Rôle',
  invites: 'Invitations',
  invitations: 'Invitations',
  subscription: 'Abonnement',
  accept: 'Accepter',
  decline: 'Refuser',
  date: 'Date',
  date_sent: "Date d'envoi",
  accepted: 'Accepté',
  pending: 'En attente',
  super_user: 'Super utilisateur',
  submit_btn: 'Soumettre',
  next_btn: 'Suivant',
  previous_btn: 'Précédent',
  table_name: 'Nom',
  search_btn: 'Rechercher',
  print_btn: 'Imprimer',
  settings: 'Paramètres',
  name: 'Nom',
  view: 'Voir',
  delete: 'Supprimer',
  reset: 'Réinitialiser',
  submit: 'Soumettre',
  table_view: 'Voir',
  table_email: 'E-mail',
  edit: 'Modifier',
  users: 'Utilisateurs',
  login: 'Connexion',
  plan: 'Plan Scannable',
  category_name: 'Nom de catégorie',
  category_description: 'Description de la catégorie',
  select: 'Sélectionner',
  upload_a_file: 'Télécharger un fichier',
  drag_and_drop: 'ou glisser-déposer',
  unsupported_field: 'Champ non pris en charge',
  type: 'Type',
  unit: 'Unité',
  verified: 'Vérifié',
  available_units: 'Unités disponibles',
  inspect: 'Inspecter',
  inspection: 'Inspection',
  dashboard: 'Tableau de bord',
  manufacturers: 'Fabricants',
  inventory: 'Inventaire',
  nav_product_data: 'Données produit',
  products: 'Produits',
  create_serials: 'Créer des séries',
  create_assembles: 'Créer des assemblages',
  create_certification: 'Créer une certification',
  inspection_reports: "Rapports d'inspection",
  serial_reports: 'Rapports de série',
  serial_format: 'Format de série',
  kit_list: 'Liste de kits',
  my_inventory: 'Mon inventaire',
  organisations: 'Organisations',
  product_search: 'Recherche de produit',
  assemblies: 'Assemblages',
  data_field: 'Champ de données',
  product_data_category: 'Catégorie de données produit',
  data_imports: 'Importations de données',
  data_imports_h2: 'Importer depuis CSV',
  upload_csv_data: 'Télécharger des données CSV',
  status: 'Statut',
  result: 'Résultat',
  message: 'Message',
  climber: 'Grimpeur',
  climber_number: 'Numéro de grimpeur',
  process_complete: 'Processus terminé',
  image: 'image',
  product: 'Produit',
  yes: 'Oui',
  no: 'Non',
  no_inspections: "Pas d'inspections",
  remove: 'Supprimer',
  add: 'Ajouter',
  asset_management: 'Gestion des actifs',
  ecommerce_intergration:
    "Intégrations de gestion du commerce électronique / de l'inventaire",
  empty_product:
    "Ceci est une URL unique qui peut être connectée à un article d'équipement. Bientôt, vous pourrez utiliser notre application pour connecter cette étiquette à votre équipement.",
  unauthorized_page:
    'Désolé, il semble que vous ne puissiez pas voir cette page.',
  return_to_dashboard: 'Retour au tableau de bord',
  return_home: "Retour à l'accueil",
  page_not_found: 'Page non trouvée!',
  scan_qr: 'Scanner QR',
  sign_into_your_account: 'Connectez-vous à votre compte',
  sign_up: "S'inscrire",
  an_account: 'un compte?',
  error: 'Erreur',
  please_enter_a_serial_number: 'Veuillez entrer un numéro de série',
  serial: 'série',
  batch: 'lot',
  save_serialised_product: 'Enregistrer le produit sérialisé dans le kit',
  no_data_found: 'Aucune donnée trouvée',
  sign_out: 'Déconnexion',
  update_user: "Mettre à jour l'utilisateur",
  profile: 'Profil',
  update: 'Mettre à jour',
  reset_password: 'Réinitialiser le mot de passe',
  serial_number: 'Numéro de série',
  cancel_scan: 'Annuler le scan',
  sign_in: 'Connexion',
  no_results: 'Aucun résultat',
  part_number: 'Numéro de pièce',
  last_inspection: 'Dernière inspection',
  specifications: 'Spécifications',
  specs: 'Caractéristiques',
  component_data: 'Données des composants',
  cancel: 'Annuler',
  no_components_found_for_this_search:
    'Aucun composant trouvé pour ce terme de recherche. Veuillez réessayer.',
  you_nust_be_logged_in:
    'Vous devez être connecté pour réclamer un article de kit',
  there_was_an_error: 'Il y a eu une erreur',
  add_to_kit: "Ajouter à l'inventaire",
  sign_up_for_an_account: 'Inscrivez-vous pour un compte',
  product_group: 'Groupe de produits',
  group_product_tip:
    "Sélectionnez un groupe de produits qui, selon vous, décrit le mieux l'article que vous créez. Nous utilisons ce groupe de produits pour définir un modèle de base de données à attribuer à votre nouveau produit.",
  product_name_tip:
    "Le nom couvrant toutes les variantes d'un produit. Il est recommandé de ne pas inclure de spécificités comme la taille ou la couleur dans le nom qui sont définies au niveau de la variante. Ceci est parfois appelé le nom du style.",
  single_item_cannot_be_dissembled:
    'Un seul article, qui ne peut pas être démonté. Nous appelons cela un composant.',
  single_item_cannot_be_dissembled_tip:
    'Si votre produit est un article unique, généralement avec un numéro de série unique, et ne peut pas être divisé en parties sérialisées constitutives - choisissez composant. Exemples: Mousqueton, Sangle, Casque',
  a_cut_length_of_rope:
    'Est-ce une longueur de corde coupée avec une épissure ou une terminaison cousue',
  a_cut_length_of_rope_tip:
    'Nous avons créé ce "type de produit" pour refléter les réalités des revendeurs de corde coupant et épissant des longueurs de corde à partir de bobines / boîtes originales d\'un fabricant. Utilisez ce "type de produit" pour garder toutes les données de bobine d\'origine connectées à chaque longueur de corde.',
  product_consists_of_multiple:
    'Un produit qui se compose de plusieurs composants, comme une longe. Nous appelons cela un assemblage',
  product_consists_of_multiple_tip:
    'Pour un produit qui est assemblé à partir de deux ou plusieurs articles sérialisés comme un système de longe ou un harnais avec des ponts de corde remplaçables.',
  add_variation: 'Ajouter une variation',
  name_part_tip:
    'Également connu sous le nom de code produit, ou code SKU. Utilisez votre convention de code standard pour composer le numéro / code que vous souhaitez utiliser ici.',
  sku_tip:
    'Également connu sous le nom de code produit, ou code SKU. Utilisez votre convention de code standard pour composer le numéro / code que vous souhaitez utiliser ici.',
  barcode_tip:
    "Vous devrez créer un compte avec votre bureau régional GS1 pour créer vos EAN / UPC / Codes-barres. Nous travaillons sur une intégration directe pour créer des codes-barres GS1 ici. Restez à l'écoute.",
  add_components:
    "Ajouter des composants, corde d'origine ou épissure / terminaison de corde",
  below_components_will_be_added:
    'Si votre produit comprend 2 ou plusieurs articles sérialisés, ajoutez-les ici. Si vous créez une longueur de corde, avec une épissure ou une terminaison, ajoutez-les ici.',
  product_code: 'Code produit',
  serial_formats: 'Formats de série',
  include_batch_number: 'Inclure le numéro de lot',
  do_not_include_batch_number: 'Ne pas inclure le numéro de lot',
  suffix: 'Suffixe',
  update_product_category: 'Mettre à jour la catégorie de produit',
  product_groups: 'Groupes de produits',
  product_groups_are_a_grouping:
    'Les groupes de produits sont un regroupement de produits similaires en conception, spécification ou nature.',
  title: 'Titre',
  prefix: 'Préfixe',
  parts: 'Pièces',
  history: 'Historique',
  composite_data: 'Données composites',
  product_history: 'Historique du produit',
  shorthand_dom: 'DOM',
  customer: 'Client',
  sales_order: 'Commande client',
  purchase_order: "Commande d'achat",
  tasks: 'Tâches',
  alerts: 'Alertes',
  clear: 'Effacer',
  serial_batch: 'Série / Lot',
  inspection_by_when: 'Inspection / par / quand',
  product_variant: 'Produit / Variante',
  next_due: 'Prochaine échéance',
  save: 'Enregistrer',
  share: 'Partager',
  update_information: 'Mettre à jour les informations',
  date_of_first_use: 'Date de première utilisation',
  date_of_manufacture: 'Date de fabrication',
  batch_number: 'Numéro de lot',
  remove_all_groups: 'Supprimer tous les groupes',
  are_you_sure_you_want_to_remove_all_groups:
    'Êtes-vous sûr de vouloir supprimer tous les groupes?',
  no_options: 'Aucune option',
  teams: 'Équipes',
  members: 'Membres',
  team_inventory: "Inventaire de l'équipe",
  book_demo: 'Réserver une démo',
  description: 'Description',
  my_account: 'Mon compte',
  view_items: 'Voir les articles',
  share_app: `Partager l'application`,
  app: 'Application',
  close: 'Fermer',
};

const menu = {
  menu_dashboard: 'Aperçu',
  menu_users: 'Utilisateurs',
  menu_team: 'Équipe',
  menu_updates: 'Mises à jour Scannable',
  menu_knowledge_base: 'Base de connaissances',
  menu_manufacturers: 'Fabricants',
  menu_product_categories: 'Catégories de produits',
  menu_product_data_categories: 'Champs de données produit',
  menu_products: 'Produits',
  menu_create_serials: 'Créer des séries',
  create_assemblies: 'Créer des assemblages',
  menu_inspections: 'Inspections',
  inspection_reports: "Rapports d'inspection",
  menu_serial_reports: 'Rapports de série',
  menu_serial_format: 'Formats de série',
  menu_kit_list: 'Liste de kits',
  menu_organisations: 'Organisations',
  menu_product_search: 'Recherche de produit',
  menu_inventory: 'Inventaire',
  menu_my_inventory: 'Mon inventaire',
  menu_kit_bags: 'Sacs à kit',
  menu_locations: 'Emplacements',
  menu_vehicles: 'Véhicules',
  menu_staff: 'Personnel',
  menu_equipment_type: "Type d'équipement",
  menu_clients: 'Clients',
  tooltip_scannable_updates:
    'Restez à jour avec les nouveautés de Scannable. Voici un résumé des changements.',
  tooltip_knowledge_base: 'How-to guides and FAQs',
  menu_buy_nfc_tags: 'Acheter NFC',
};

const dashboard = {
  title: 'Aperçu',
  subtitle: 'Aperçu',
  manufacturers: 'Fabricants',
  user_signups: 'Inscriptions utilisateurs (derniers 7 jours)',
  inspections: 'Inspections',
  inspections_week: 'Inspections (derniers 7 jours)',
  product_variations: 'Variations de produits (vérifiées)',
  serialised_products: 'Produits sérialisés',
  claimed_products: 'Produits réclamés',
  items_inspected: 'Articles inspectés',
  items_overdue: 'Articles en retard',
  items_due_30: 'Articles à échéance dans 30 jours',
  items_due_60: 'Articles à échéance dans 60 jours',
};

const users = {
  users_table_activated: 'Activé',
  users_activated_: 'Oui/Non',
  users_select_option: 'Sélectionner une option',
  users_super_user_option: 'Super utilisateur',
  user_banned_option: 'Banni',
};

const team = {
  title: 'Équipe',
  invite_team_members: "Inviter des membres de l'équipe",
  add_emails: "Ajouter des adresses e-mail de l'équipe",
  create_team: 'Créer une équipe',
  invite_members: 'Inviter des membres',
  send_invites: 'Envoyer des invitations',
};

const frequencies = {
  create_frequency: "Créer une fréquence d'inspection",
  update_frequency: "Mettre à jour la fréquence d'inspection",
  delete_frequency: "Supprimer la fréquence d'inspection",
  frequencies: "Fréquences d'inspection",
  start_date: 'Date de Début',
};

const manufacturers = {
  manufacturers_table_website: 'Site Web',
  manufacturers_table_units: 'Unités',
  create_manfacturer_btn: 'Créer un fabricant',
  create_manufacturer_title: 'Créer un fabricant',
  manufacturers_website: 'Site Web',
  manufacturers_measurements: 'Unités de mesure',
  manufacturers_measurement_metric:
    'Les unités de mesure des produits sont métriques',
  manufacturers_measurement_imperial:
    'Les unités de mesure des produits sont impériales',
  edit_manufacturer_name: 'Nom du fabricant',
  edit_manufacturer_logo: 'Logo',
  edit_manufacturer_button: 'Mettre à jour les détails du fabricant',
};

const organisations = {
  edit_organisations_button: 'Mettre à jour les détails du compte',
  edit_organisation_name: 'Nom du compte',
};

const product_categories = {
  title: 'Catégories de produits',
  subtitle:
    'Catégoriser les regroupements de produits par industrie ou application',
  table_hidden: 'Caché',
  is_hidden: 'Oui/Non',
  create_label: 'Créer une nouvelle catégorie de produits',
};

const product_data_fields = {
  title: 'Champs de données produit',
  subtitle:
    'Les catégories de données produit sont un groupe de champs de données de spécification ou nature similaire',
  data_fields: 'Champs de données',
};

const products = {
  create_product_btn: 'Créer un produit',
  create_product_title: 'Créer un produit',
  move_products_btn: 'Déplacer des produits',
  products_group: 'Groupe de produits',
  products_name: 'Nom du produit',
  comp_product: 'Produit composite',
  products_comp_product_option1:
    'Un seul article, qui ne peut pas être démonté. Nous appelons cela un composant.',
  products_comp_product_option2:
    'Une longueur de corde coupée avec une épissure ou une terminaison cousue',
  products_comp_product_option3:
    'Un produit qui se compose de plusieurs composants, comme une longe. Nous appelons cela un assemblage.',
  products_part_num: 'Numéro de pièce',
  products_sku_name: 'Nom du SKU',
  products_ean_upc_barcode: 'EAN/UPC/Code-barres',
  claimproduct_error_msg:
    'Vous devez être connecté pour réclamer un article de kit',
  claimproduct_success_msg: "Produit ajouté à l'inventaire",
  claimproduct_failure_msg: 'Désolé, quelque chose a mal tourné',
  item_is_in_kitlist: "L'article est dans votre inventaire",
  add_to_kitlist: "Ajouter à l'inventaire",
  login_to_add_to_kitlist: "Connectez-vous pour ajouter à l'inventaire",
  certified: 'Certifié',
};

const create_serials = {
  serial_creation_wizard_title: 'Assistant de création de séries',
  create_component_serials: 'Créer des séries de composants',
  create_rope_length_serials: 'Créer des séries de longueurs de corde',
  created_serials_title: 'Séries créées',
  create_serials_table_serial_format: 'Format de série',
  create_serials_table_date_created: 'Date de création',
  create_serials_table_reference_number: 'Numéro de référence',
  create_serials_table_download: 'Télécharger',
  create_serialised_title: 'Créer des produits sérialisés',
  create_serials_ref_num_serial_format:
    'Numéros de référence et format de série',
  create_serials_select_serial_format:
    'Sélectionner un format de série à utiliser',
  create_serials_petzl_format: 'Format Petzl',
  create_serials_dom: 'DOM',
  create_serials_batch: 'Lot',
  create_serials_customer: 'Client',
  create_serials_sales_order: 'Commande client',
  create_serials_purchase_order: "Commande d'achat",
  create_serials_increments: 'Incréments',
  create_serials_suffix: 'Suffixe',
  create_serials_p_o_num: "Numéro de commande d'achat",
  create_serials_enter_ref_num: 'Entrer un numéro de référence',
  create_serials_sales_order_num: 'Numéro de commande client',
  create_serials_enter_sales_order_num: 'Référence client',
  create_serials_customer_ref: 'Entrer un numéro de commande client',
  create_serials_batch_num: 'Numéro de lot',
  create_serials_enter_batch_num: 'Entrer un numéro de lot',
  create_serials_select_date_manu:
    'Sélectionner une date de fabrication à utiliser comme partie de la création de série',
  create_serials_date_selected: 'Date sélectionnée:',
  create_serials_preferences_btn:
    'Enregistrer les préférences de référence et de format de série',
  create_serialised_products: 'Créer des produits sérialisés',
  reference_numbers_serial_format: 'Numéros de référence et format de série',
  enter_ref_number: 'Entrer un numéro de référence',
  sales_order_number: 'Numéro de commande client',
  enter_sales_order_number: 'Entrer un numéro de commande client',
  customer_reference: 'Référence client',
  enter_batch_number: 'Entrer un numéro de lot',
  save_reference_serial_format_btn:
    'Enregistrer les préférences de référence et de format de série',
  create_serials_for_cutrope: 'Créer des séries pour corde coupée',
  rope_details: 'Détails de la corde',
  select_rope_cutting_from:
    'Sélectionner la corde à couper et confirmer les détails',
  choose_rope: 'Choisir la corde',
  selected_rope: 'Corde sélectionnée',
  rope_for_serialisation: 'Cordes pour sérialisation',
  tooltip_create_serials:
    "Utilisez cet outil pour créer des séries en masse pour un ou plusieurs composants. Utile si vous devez créer des séries pour des commandes d'achat destinées à un fournisseur ou si vous avez besoin de plusieurs séries pour un lot d'articles en production",
  tooltip_splice_rope:
    'Utilisez cet outil pour créer des séries pour des longueurs de corde coupées, cousues ou épissées.',
  tooltip_create_serial_menu:
    'Utilisez cet outil pour créer des séries pour les produits que vous avez créés ou pour des longueurs de corde nouvellement coupées, épissées ou cousues.',
};

const assemblies = {
  assemblies_table_assembly_name: "Nom de l'assemblage",
  assemblies_table_sales_order_number: 'Numéro de commande client',
  assemblies_table_purchase_order_number: "Numéro de commande d'achat",
  assemblies_table_batch_number: 'Numéro de lot',
  assemblies_table_date_manufacturer: 'Date de fabrication',
  assemblies_create_assemblie_btn: 'Créer un nouvel assemblage',
  assemblies_create_assemblies: 'Créer des assemblages',
  assemblies_choose_assembly: 'Choisir un assemblage',
  assemblies_select_assembly_building:
    "Recherchez et sélectionnez l'assemblage que vous souhaitez construire.",
  selected_assembly: 'Assemblage sélectionné',
  create_assembly: '1. Créer un assemblage',
  create_assembly_adding_serials:
    'Créer un assemblage en ajoutant des séries aux composants correspondants',
  create_another_assembly: 'Créer un autre assemblage',
  create_an_assembly: 'Créer un assemblage',
  product_data: 'Données produit',
  tooltip_create_assemblies:
    'Utilisez cet outil pour regrouper des composants en séries en assemblages',
};

const inspections = {
  title: 'Inspections',
  inspections_search_product_title: 'Rechercher un article',
  inspections_search_product_subtitle:
    "Trouvez l'article dans votre inventaire que vous souhaitez inspecter",
  inspections_table_serial: 'Série',
  confirm_fail_inspection_title: `Échec de l'Inspection`,
  confirm_fail_inspection_description: `Êtes-vous sûr de vouloir marquer cette inspection comme échouée?`,
  confirm_fail_inspection_button: `Oui, Terminer l'Inspection`,
};

const inspection_reports = {
  inspection_reports_climber_sup_admin: 'Grimpeur : Super admin',
  inspection_comments: "Commentaires sur l'inspection",
  image_provided: 'Image fournie',
  inspection_reports_officials_initials: 'Initiales des officiels :',
};

const serialisation = {
  title: 'Sérialisation',
  table_created: 'Créé',
  table_sales_order_num: 'Commande client #',
  table_po_num: "Commande d'achat #",
  table_batch: 'Lot #',
  table_date_manufacturer: 'DOM',
  table_download: 'Télécharger',
  filter_customer_label: 'Client',
  filter_customer_placeholder: 'Sélectionner un client',
  filter_sku_label: 'SKU',
  filter_sku_placeholder: 'Sélectionner un SKU',
  filter_category_label: 'Catégorie',
  filter_category_placeholder: 'Sélectionner une catégorie',
  select_printer: 'Sélectionner une imprimante',
};

const serial_format_ = {
  serial_format_define_format_serial1:
    "C'est ici que vous définissez votre format de numéro de série préféré.",
  serial_format_define_format_serial2:
    'En tant que partenaire GS1, nous intégrerons votre série dans divers formats de numéros de série GS1, pour différentes applications.',
  serial_format_define_format_serial3:
    'Nous construisons des numéros de série en utilisant les composants définissables suivants. Tous les composants de série ne sont pas requis. Il suffit de définir ceux que vous souhaitez utiliser.',
  serial_format_subtitle: 'Titre',
  serial_format_title_req: 'Le titre est requis',
  serial_format_prefix: 'Préfixe',
  serial_format_prefix_option_serial:
    'Le préfixe est un composant de série optionnel',
  serial_format_serial_have_prefix: 'Votre numéro de série a-t-il un préfixe ?',
  serial_format_yes_option: 'Oui, je veux un préfixe pour ce format de série.',
  serial_format_no_option: "Non, le format de série n'a pas de préfixe.",
  serial_format_date_of_manu_optional:
    'Date de fabrication. La date de fabrication est un composant de série optionnel',
  serial_format_sf_batch_num1:
    "Nous recommandons généralement de ne pas inclure le numéro de lot dans le numéro de série, mais plutôt d'ajouter le SGTIN de GS1 pour ajouter les informations de série.",
  serial_format_sf_batch_num2:
    "Mais pour les fabricants qui le souhaitent, l'option reste d'inclure un numéro de lot dans leur numéro de série.",
  serial_format_sf_batch_num3:
    "En sélectionnant « Inclure le numéro de lot », cela prendra le numéro de lot saisi lors de la création des numéros de série et l'inclura dans les numéros de série.",
  serial_format_cust_ref: 'Référence client',
  serial_format_dont_incl_cus_ref: 'Ne pas inclure la référence client',
  serial_format_sales_order: 'Numéro de commande client',
  serial_format_incl_order: 'Inclure le numéro de commande client',
  serial_format_dont_incl_order: 'Ne pas inclure le numéro de commande client',
  serial_format_p_o: "Numéro de commande d'achat",
  serial_format_incl_purchase_order: "Inclure le numéro de commande d'achat",
  serial_format_dont_incl_purchase_order:
    "Ne pas inclure le numéro de commande d'achat",
  serial_format_incl_cus_ref: 'Inclure la référence client',
  increments: 'Incréments',
  serial_format_increments:
    'Les incréments sont un composant de série optionnel requis.',
  serial_format_increments2:
    'Les incréments permettent de quantifier la production, avec un lot, jour, mois. Nous suggérons généralement 3 à 5 caractères, avec des nombres « remplis », par ex. 0001,0002,...9999',
  serial_format_padding: 'Appliquer un remplissage ?',
  serial_format_pad_serial_num: 'Oui, remplir les numéros de série',
  serial_format_dont_pad_srial_num: 'Non, ne pas remplir les numéros de série',
  serial_format_suffix:
    'Suffixe. Le suffixe est un composant de série optionnel',
  serial_format_does_serial_num_have_suffix:
    'Votre numéro de série a-t-il un suffixe ?',
  serial_format_yes_suffix_for_serial_format:
    'Oui, je veux un suffixe pour ce format de série.',
  serial_format_no_suffix_for_serial_format:
    "Non, le format de série n'a pas de suffixe.",
  serial_format_your_serial_num_format: 'Votre format de numéro de série',
  serial_format_create_serial_format: 'Créer un format de série',
  number_of_increment_characters: "Nombre de caractères d'incrément",
};

const serial_format = {
  title: 'Titre',
  suffix_label: 'Suffixe',
  title_label: 'Titre',
  prefix_label: 'Préfixe',
  date_format_label: 'Format de date',
  batch_label: 'Lot',
  increments_label: 'Incréments',
  descriptive_title: 'Entrez un titre descriptif',
  dom: 'Date de fabrication',
  dom_yes: 'Oui, je veux une date de fabrication pour ce format de série.',
  dom_no: "Non, le format de série n'a pas de date de fabrication.",
};

const kit_list = {
  kit_list_table_category: 'Catégorie',
  kit_list_table_items: 'Articles',
};

const product_search = {
  product_search_search: 'Rechercher',
};

const settings = {
  settings_logo: 'Logo',
};

const actions = {
  assign_to_sku: 'Attribuer à un SKU',
  assign_to_a_team_member: "Attribuer à un membre de l'équipe",
  assign_to_a_kit_bag: 'Attribuer à un sac de kit',
  assign_to_a_location: 'Attribuer à un emplacement',
  assign_to_a_client: 'Attribuer à un client',
  assign_to_a_vehicle: 'Attribuer à un véhicule',
  assign_to_staff: 'Attribuer au personnel',
  assign_to_an_equipment_type: "Attribuer à un type d'équipement",
  set_inspection_frequency: "Définir la fréquence d'inspection",
  remove_item_from_inventory: "Retirer de l'inventaire",
  assign_to_group: 'Attribuer à un groupe',
  assign_update_groups: 'Attribuer à des groupes / Équipe',
  bulk_remove_from_inventory: `Retirer de l'inventaire`,
  bulk_remove_from_group: 'Retirer du groupe',
};

const groups = {
  groups: 'Groupes',
  kit_bags: 'Sacs de kit',
  add_kit_bag: 'Ajouter un sac de kit',
  new_kit_bag: 'Nouveau sac de kit',
  locations: 'Emplacements',
  add_location: 'Ajouter un emplacement',
  new_location: 'Nouvel emplacement',
  vehicles: 'Véhicules',
  add_vehicle: 'Ajouter un véhicule',
  new_vehicle: 'Nouveau véhicule',
  clients: 'Clients',
  add_client: 'Ajouter un client',
  new_client: 'Nouveau client',
  staff: 'Personnel',
  add_staff: 'Ajouter du personnel',
  new_staff: 'Nouveau personnel',
  equipment_type: "Type d'équipement",
  add_equipment_type: "Ajouter un type d'équipement",
  new_equipment_type: "Nouveau type d'équipement",
  kit_bag: 'Sac de kit',
  location: 'Emplacement',
  vehicle: 'Véhicule',
  client: 'Client',
  select_a_group: 'Sélectionner un groupe',
};

const checklists = {
  create_checklist: 'Créer une liste de contrôle',
  update_checklist: 'Mettre à jour la liste de contrôle',
  delete_checklist: 'Supprimer la liste de contrôle',
  checklists: 'Listes de contrôle',
};

const serialised_products = {
  dom: 'Date de fabrication',
  dofu: 'Date de première utilisation',
  expiry_date: "Date d'expiration",
  external_id: 'ID alternatif',
};

const auth = {
  forgot_password: 'Mot de passe oublié?',
  send_reset_password_email:
    'Envoyer un e-mail de réinitialisation de mot de passe',
  password_reset_description:
    'Remplissez votre e-mail ci-dessous et nous vous enverrons un lien de récupération pour réinitialiser votre mot de passe.',
  change_password: 'Changer le mot de passe',
  switch_account: 'Changer de compte',
};

const printing = {
  preview_label: 'Aperçu de l’étiquette',
  print_one_label: 'Imprimer une étiquette',
  print_all_label: 'Imprimer toutes les étiquettes',
  success_message: 'Succès',
  print_one_serial: 'Imprimer une série',
  print_label: 'Imprimer',
  clear_queue: 'Effacer la file d’attente',
  print_with_label_live: 'Imprimer avec Label Live',
  fail_to_connect_to_printer:
    "Échec de la connexion à l'imprimante. Assurez-vous d'activer HTTP dans les paramètres d'intégration de Label Live",
};

const onboarding = {
  skip: 'Passer',
};

const bottom_tabs = {
  home: 'Accueil',
  inventory: 'Inventaire',
  groups: 'Groupes',
  me: 'Moi',
};

const nfc = {
  remove_tags: 'Supprimer les étiquettes NFC',
  confirm_remove_tags_from_item:
    'Êtes-vous sûr de vouloir supprimer toutes les étiquettes NFC de cet article?',
  add_tag: 'Ajouter NFC',
  one_nfc_added: 'NFC ajouté',
  multiple_nfcs_added: '# NFC Tags',
  scan_dialogue_instructions: `Tenez l'étiquette NFC contre le point optimal NFC de votre téléphone.`,
};

const multiScanNFCPrompt = `Tenez l'étiquette NFC contre le point optimal NFC de votre téléphone. Attendez quelques instants.

Si l'étiquette ne se lit pas, retirez le téléphone et essayez de nouveau.`;

const multi_scan = {
  title: 'Scan Multiple',
  nfc_prompt_message: multiScanNFCPrompt,
  nfc_session_will_end_soon: 'La session NFC se terminera bientôt',
  nfc_timeout_title: `Temps d'attente NFC atteint`,
  nfc_timeout_description: `Le scanner NFC s'arrête après 60 secondes. Appuyez sur "Scanner NFC" pour continuer à scanner.`,
  nfc_ios_unexpected_error_description: `C'est une erreur connue d'iOS. Appuyez sur "Scanner NFC" pour continuer à scanner.`,
  nfc_unexpected_error_description: `Quelque chose s'est mal passé. Veuillez réessayer.`,
  nfc_unexpected_error_title: `La session NFC a échoué de manière inattendue.`,
  ready_to_scan: 'Prêt à scanner',
};

const tables = {
  apply_filters: 'Appliquer les filtres',
  filters_label: 'Filtres',
};

const notifications = {
  notification: 'Notification',
  notifications: 'Notifications',
  inspection_due: 'Inspection due',
  expiry_due: 'Expiration due',
  inspection_due_next_30_days: 'Inspection prévue dans les 30 prochains jours',
  expiry_due_next_30_days: 'Expiration prévue dans les 30 prochains jours',
  sent_weekly: 'Envoyé chaque semaine',
  notification_settings: 'Paramètres de notification',
  notification_preferences_saved: 'Préférences de notification enregistrées',
};

export const fr = {
  ...general,
  ...menu,
  ...users,
  ...manufacturers,
  ...organisations,
  ...products,
  ...create_serials,
  ...assemblies,
  ...inspection_reports,
  ...serial_format_,
  ...kit_list,
  ...product_search,
  ...settings,
  ...groups,
  ...frequencies,
  ...checklists,
  ...serialised_products,
  actions,
  notifications,
  serialisation,
  inspections,
  team,
  serial_format,
  product_categories,
  product_data_fields,
  dashboard,
  auth,
  printing,
  onboarding,
  bottom_tabs,
  nfc,
  multi_scan,
  tables,
};
