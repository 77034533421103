import { classNames } from '@scannable/common';

export interface FilterButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  label: string;
  count?: string | number;
  active?: boolean;
}

export function FilterButton({
  label,
  count,
  active,
  ...props
}: FilterButtonProps) {
  return (
    <button
      type="button"
      className={`relative z-0 inline-flex shadow-sm rounded-md hover:bg-gray-50  ${
        active ? 'z-10 outline-none ring-1 ring-gray-500 border-gray-500' : ''
      }`}
      {...props}
    >
      <span
        className={classNames(
          'relative inline-flex items-center px-4 py-2  border border-gray-300 bg-white text-sm font-medium text-gray-700',
          count ? 'rounded-l-md' : 'rounded-md'
        )}
      >
        {label}
      </span>
      {count && (
        <span className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700">
          {count}
        </span>
      )}
    </button>
  );
}

export default FilterButton;
