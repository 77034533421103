import { Image } from '../../atoms';
import SimpleModal from '../SimpleModal/SimpleModal';

export interface ScannableModalProps {
  title: string;
  subTitle: string;
  children: React.ReactNode;
}

export function ScannableModal({
  title,
  subTitle,
  children,
}: ScannableModalProps) {
  return (
    <SimpleModal>
      <div className="max-w-4xl text-center">
        <Image
          src="/static/scannable-icon.svg"
          alt="Scannable"
          className="object-contain object-center mx-auto"
          width={60}
          height={60}
          priority
        />
        <h3 className="mt-4 text-2xl font-semibold text-gray-800">{title}</h3>
        <p className="text-gray-700 text-lg mt-3 font-sans">{subTitle}</p>
      </div>
      <div className="border-gray-200 border-b my-8" />
      {children}
    </SimpleModal>
  );
}

export default ScannableModal;
