import { FormikProps, FormikValues } from 'formik';
import DatePicker from 'react-datepicker';

import { classNames } from '@scannable/common';

export interface ReactDatePickerProps<V>
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: HTMLSelectElement['name'];
  formik: FormikProps<V>;
}

export function ReactDatePicker<V extends FormikValues>({
  name,
  formik,
  className,
  ...props
}: ReactDatePickerProps<V>) {
  const formatUTC = (dateInt: string | null, addOffset = false) => {
    const date =
      !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
    if (typeof dateInt === 'string') {
      return date;
    }
    const offset = addOffset
      ? date.getTimezoneOffset()
      : -date.getTimezoneOffset();
    const offsetDate = new Date();
    offsetDate.setTime(date.getTime() + offset * 60000);
    return offsetDate;
  };

  const onChange = (value: Date | null) => {
    if (value) {
      const date = formatUTC(value.toString());
      return formik.setFieldValue('value', date.toISOString().split('T')[0]);
    }
    return formik.setFieldValue('value', null);
  };

  return (
    <DatePicker
      selected={
        formik?.values?.value && formik.values.value !== 'null'
          ? formatUTC(formik.values.value)
          : null
      }
      className={classNames(
        className
          ? className
          : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded'
      )}
      onChange={(e) => onChange(e)}
      dateFormat="dd/MM/yyyy"
      isClearable
    />
  );
}

export default ReactDatePicker;
