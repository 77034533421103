import { AllHTMLAttributes, HTMLAttributes } from 'react';
import Link from 'next/link';

import { classNames } from '@scannable/common';

import { Button, ResizedImage } from '../../atoms';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { Pagination } from '../../molecules/Pagination/Pagination';
import LoadingBlock from '../LoadingBlock/LoadingBlock';

export function DataGridRow({
  children,
  ...props
}: HTMLAttributes<HTMLTableRowElement>) {
  return <tr {...props}>{children}</tr>;
}
export function DivDataGridRow({
  children,
  className,
  href,
  ...props
}: { href?: string } & HTMLAttributes<HTMLDivElement | HTMLAnchorElement>) {
  if (href) {
    return (
      <Link
        href={href}
        passHref
        className={classNames('table-row', className || '')}
        {...props}
      >
        {children}
      </Link>
    );
  }
  return (
    <div className={classNames('table-row', className || '')} {...props}>
      {children}
    </div>
  );
}

export function DivDataHeader({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames('table-header-group bg-gray-50', className || '')}
      {...props}
    >
      {children}
    </div>
  );
}

export function DivDataGridBody({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames('table-row-group', className || '')} {...props}>
      {children}
    </div>
  );
}

type DataGridCellContentsType = {
  href?: string;
  image?: {
    url: string | null;
    alt?: string | null;
  } | null;
  imageAlt?: string;
};

function DataGridCellContents({
  image,
  href,
  children,
}: HTMLAttributes<HTMLDivElement> & DataGridCellContentsType) {
  if (image && !href) {
    return (
      <div className="flex items-center">
        <div className="relative h-10 w-10 flex-shrink-0">
          <ResizedImage
            className="rounded object-contain object-center"
            src={image.url ?? ''}
            alt={image.alt ?? ''}
            fill
          />
        </div>
        <div className="ml-4 flex items-center justify-center">
          <div className="font-medium text-gray-900">{children}</div>
        </div>
      </div>
    );
  }
  if (image && href) {
    return (
      <div className="flex items-center">
        <Link href={href} className="flex">
          <div className="relative h-10 w-10 flex-shrink-0">
            <ResizedImage
              className="rounded object-contain object-center"
              src={image.url ?? ''}
              alt={image.alt ?? ''}
              fill
            />
          </div>
          <div className="ml-4 flex items-center justify-center">
            <div className="font-medium text-gray-900">{children}</div>
          </div>
        </Link>
      </div>
    );
  }

  if (href) {
    return (
      <div className="flex items-center">
        <Link href={href} className="flex">
          {children}
        </Link>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

interface DataGridCellProps extends AllHTMLAttributes<HTMLTableCellElement> {
  isAction?: boolean;
}

export function DataGridCell({
  isAction = false,
  ...props
}: DataGridCellProps & DataGridCellContentsType) {
  return (
    <td
      className={
        !isAction
          ? 'px-6 py-4'
          : 'whitespace-nowrap px-6 py-4 text-right text-sm font-medium'
      }
      {...props}
    >
      <DataGridCellContents {...props} />
    </td>
  );
}

export function DivDataGridCell({
  children,
  className,
  isAction = false,
  ...props
}: {
  isAction?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        'table-cell',
        !isAction
          ? 'whitespace-nowrap px-6 py-4'
          : 'whitespace-nowrap px-6 py-4 text-right text-sm font-medium',
        className || ''
      )}
      {...props}
    >
      {children}
    </div>
  );
}

type ActionsList = 'CREATE' | 'EDIT' | 'DELETE';
export interface DataGridProps {
  title?: string;
  titles: string[];
  children: React.ReactNode;
  showBreadCrumbs?: boolean;
  actions?: ActionsList[];
  rootPath?: string;
  next?: () => void;
  prev?: () => void;
  data?: { [key: string]: unknown }[];
  limit?: number;
}

export function DivDataGrid({
  title,
  titles,
  children,
  actions = [] as ActionsList[],
  rootPath,
  showBreadCrumbs = false,
  next,
  prev,
  data,
  limit,
}: DataGridProps) {
  const maxPagination = (data && limit && data.length < limit) || false;
  const { t } = useTranslation();
  return (
    <>
      {(actions.includes('CREATE') || showBreadCrumbs) && (
        <div className="mb-10">
          <div className="mt-2 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              {title && (
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  {title}
                </h2>
              )}
            </div>
            {actions && (
              <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-4">
                {/* {actions.includes('EDIT') && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
              >
                Edit
              </button>
            )} */}
                {actions.includes('CREATE') && (
                  <Link href={`${rootPath}/create`} passHref>
                    <Button type="button">{t('create_label')}</Button>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <div className="table min-w-full divide-y divide-gray-200">
                <DivDataHeader>
                  <DivDataGridRow>
                    {titles.map((title, index) => (
                      <DivDataGridCell
                        key={index}
                        // scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        {title}
                      </DivDataGridCell>
                    ))}
                    {actions.includes('EDIT') && (
                      <DivDataGridCell className="relative px-6 py-3">
                        <span className="sr-only">{t('edit')}</span>
                      </DivDataGridCell>
                    )}
                  </DivDataGridRow>
                </DivDataHeader>

                {children ? (
                  <DivDataGridBody className="divide-y divide-gray-200 bg-white">
                    {children}
                    {next && prev && (
                      <DivDataGridRow>
                        {/* colSpan={titles.length + 1} */}
                        <DivDataGridCell>
                          <Pagination
                            next={next}
                            prev={prev}
                            maxPagination={maxPagination}
                          />
                        </DivDataGridCell>
                      </DivDataGridRow>
                    )}
                  </DivDataGridBody>
                ) : (
                  <DivDataGridBody>
                    <DivDataGridRow>
                      <DivDataGridCell>
                        <LoadingBlock className="m-0 mt-0 border-none sm:rounded-t-none" />
                      </DivDataGridCell>
                    </DivDataGridRow>
                  </DivDataGridBody>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function DataGrid({
  title,
  titles,
  children,
  actions = [] as ActionsList[],
  rootPath,
  showBreadCrumbs = false,
  next,
  prev,
  data,
  limit,
}: DataGridProps) {
  const maxPagination = (data && limit && data.length < limit) || false;
  const { t } = useTranslation();
  return (
    <>
      {(actions.includes('CREATE') || showBreadCrumbs) && (
        <div className="mb-10">
          <div className="mt-2 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              {title && (
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  {title}
                </h2>
              )}
            </div>
            {actions && (
              <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-4">
                {/* {actions.includes('EDIT') && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
              >
                Edit
              </button>
            )} */}
                {actions.includes('CREATE') && (
                  <Link href={`${rootPath}/create`} passHref legacyBehavior>
                    <Button type="button" as="a">
                      {t('create_label')}
                    </Button>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-col">
        <div className="inline-block min-w-full">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {titles.map((title, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {title}
                    </th>
                  ))}
                  {actions.includes('EDIT') && (
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">{t('edit')}</span>
                    </th>
                  )}
                </tr>
              </thead>

              {children ? (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {children}
                  {next && prev && (
                    <tr>
                      <td colSpan={titles.length + 1}>
                        <Pagination
                          next={next}
                          prev={prev}
                          maxPagination={maxPagination}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={titles.length + 1}>
                      <LoadingBlock className="m-0 mt-0 border-none sm:rounded-t-none" />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataGrid;
