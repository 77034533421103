import { Organisation, Printing } from '@scannable/common';

import { graphql } from '../graphql';

export const GET_INVENTORY_COUNT =
  graphql(`query ${Organisation.InventoryCount} {
    getEquipmentOwnerStats {
      inventoryCount
      inventoryLimit
    }
}`);

export const ORGANISATION_SUBSCRIPTION =
  graphql(`query ${Organisation.OrganisationSubscriptionQuery}($id: Int!) {
  organisation(where: {id: {
    equals: $id
  }}) {
    id
    name
    hasSubscription
  }
}`);

export const ACCOUNT_SUBSCRIPTION =
  graphql(`query ${Organisation.AccountSubscriptionInfo} {
    subscriptionInfo {
      portalUrl
      inventoryLimit
      currentInventory
      peroidEnd
      cancelAtPeroidEnd
      subscriptionEndDate
      interval
      priceTiers {
        priceDisplay
        from
        upTo
        flatAmount
      }
    }
  }`);

export const EQUIPMENT_OWNER_WEB_STATS = graphql(`
  query ${Organisation.EquipmentOwnerOverviewStats}  {
    getEquipmentOwnerStats {
      inventoryCount
      inventoryLimit
      teamCount
      subscriptionType
      itemsInspected
      overdueInspectionCount
      inspectionDue30DaysCount
      inspectionDue60DaysCount
    }
  }
`);

export const MANUFACTURER_PRODUCT_STATS = graphql(`
query ${Organisation.ManufacturerProductStats} {
  getManufacturerStats {
    id
    serialisedComponentCount
    serialisedCompositeCount
    serialisedAssemblyCount
    serialFormatCount
  }
}
`);
export const MANUFACTURER_STATS = graphql(`
  query ${Organisation.ManufacturerStats} {
    getManufacturerStats {
      totalSerialisedItems
      productCount
      productVariationCount
      claimedProductCount
    }
  }
`);

export const GET_ORGANISATION_USERS = graphql(`
  query ${Organisation.GetOrganisationUsersQuery} {
    organisationUsers {
      id
      userId
      firstName
      lastName
      avatar
    }
  }
`);

export const GET_ORGANISATION_DETAILS = graphql(`
  query ${Organisation.GetOrganisationDetailQuery}($id: Int!) {
    organisation: getOrganisation(id: $id) {
      id
      name
      subscriptionType
      subscriptionStatus
      trialEnds
      manufacturer {
        id
        name
        verified
        website
        logo
      }
      isManufacturer
      isReseller
      isEquipmentOwner
      inventoryLimit
      isInspector
      isTrainingCentre
      integrations
    }
  }
`);

export const GET_ORGANISATION = graphql(`
  query ${Organisation.GetUserOrganisation} {
    getUserOrganisation {
      id
      uuid
      name
      subscriptionStatus
      subscriptionType
      trialEnds
      integrations
    }
  }
`);

export const MY_ORGANISATIONS =
  graphql(`query ${Organisation.GetUserOrganisations} {
  myOrganisations {
    id
    name
  }
}`);

export const ORANISATION_TEAM = graphql(`
  query ${Organisation.OrganisationTeam}(
    $where: OrganisationUserWhereInput
    $orderBy: [OrganisationUserOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedOrganisationUsers(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        firstName
        lastName
        avatar
        email
        role
        organisationUserId
        competentPerson
        printerIds
        serialFormatIds
      }
      totalCount
    }
  }
`);

export const ORGANISATION_INVITES = graphql(`
  query InvitesByOrganisation {
    invitesByOrganisation {
      inviteId
      name
      createdAt
      email
      accepted
    }
  }
`);

export const ALL_ORGANISATIONS = graphql(`
  query ${Organisation.AllOrganisations}(
    $where: OrganisationWhereInput
    $orderBy: [OrganisationOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedOrganisations(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        subscriptionStatus
        subscriptionType
        isManufacturer
        isReseller
        isEquipmentOwner
        isInspector
        isTrainingCentre
      }
      totalCount
    }
  }
`);

export const ALL_ORGANISATIONS_NAMES = graphql(
  `query ${Organisation.AllOrganisationsNames} {
    adminOrganisations {
      id
      name
    }
}`
);

export const CLEAR_PRINTER_QUEUE =
  graphql(`query ${Printing.ClearPrinterQueue}($printerId: String!) {
  clearPrinterQueue(printerId: $printerId)
}`);

export const GET_ALL_PRINTERS = graphql(
  `query ${Printing.GetAllPrintersQuery}($organisationId: Int!) {
    printers(organisationId: $organisationId) {
      id
      name
      double
      logo
      labelSize
    }
  }`
);

export const GET_ALL_LABEL_TEMPLATES = graphql(
  `query ${Printing.GetAllLabelTemplates}($organisationId: Int!) {
    labelTemplates(organisationId: $organisationId) {
      id
      variables
    }
  }`
);

export const GET_HARDWARE_BY_TYPE =
  graphql(`query ${Printing.GetHardwareByType}($data: FindHardwareByTypeInput!) {
    hardwareByType(data: $data) {
      publicId
      hardwareId
      name
      type
    }
  }`);

export const CREATE_HARDWARE = graphql(
  `mutation ${Printing.CreateHardware}($data: CreateHardwareInput!) {
    createHardware(data: $data)
  }`
);

export const UPDATE_HARDWARE = graphql(
  `mutation ${Printing.UpdateHardware}($data: UpdateHardwareInput!) {
    updateHardware(data: $data)
  }`
);

export const DELETE_HARDWARE = graphql(
  `mutation ${Printing.DeleteHardware}($publicId: String!) {
    deleteHardware(publicId: $publicId)
  }`
);

export const GET_USER_PRINTERS = graphql(`query ${Printing.GetUserPrinters} {
  teamMemberPrinters {
    id
    name
    double
    logo
    labelSize
  }
}`);

export const UPDATE_PRINTERS = graphql(
  `mutation ${Printing.UpdatePrinters}($data: UpdatePrintersInput!) {
    updatePrinters(data: $data) {
      id
      name
      double
      logo
      labelSize
    }
  }`
);

export const CURRENT_ADDRESS =
  graphql(`query ${Organisation.CurrentAddressQuery} {
  currentAddress {
    id
    line1
    line2
    postCode
    city
    country
    state
    organisation {
      id
      name
    }
  }
}`);

export const MY_ADDRESSES = graphql(
  `query ${Organisation.MyAddressesQuery} {
    myAddresses {
      id
      line1
      line2
      postCode
      city
      country
      state
      organisation {
        id
        name
      }
    }
  }`
);

export const GET_CUSTOMERS = graphql(`query ${Organisation.GetCustomers} {
  customers {
    id
    name
  }
}`);
