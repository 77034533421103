const general = {
  create_label: 'Crear',
  first_name: 'Primer nombre',
  last_name: 'Apellido ',
  email: 'Correo electrónico',
  password: 'Contraseña',
  manufacturer: 'Fabricante',
  role: 'Role',
  invites: 'Invita',
  invitations: 'Invitaciones',
  accept: 'Aceptar',
  date: 'Fecha',
  date_sent: 'Fecha de envío',
  accepted: 'Aceptado',
  pending: 'Pendiente',
  super_user: 'Súper usuario',
  submit_btn: 'Sumar',
  next_btn: 'Siguiente',
  previous_btn: 'Previo',
  table_name: 'Nombre',
  settings: 'Ajustes',
  name: 'Nombre',
  view: 'Vista',
  delete: 'Eliminado',
  reset: 'Reiniciar',
  submit: 'Entregar',
  table_view: 'Vista',
  table_email: 'Correo electrónico',
  edit: 'Editar',
  users: 'Usuarios',
  login: 'Acceso',
  category_name: 'nombre de la categoría',
  category_description: 'Descripción de categoría',
  select: 'seleccione',
  upload_a_file: 'Cargar un archivo',
  drag_and_drop: 'o arrastrar y soltar',
  unsupported_field: 'Campo no admitido',
  type: 'Escriba',
  unit: 'Unidad',
  available_units: 'Unidades disponibles',
  inspect: 'Inspeccionar',
  inspection: 'Inspección',
  dashboard: 'Panel de control',
  manufacturers: 'Fabricantes',
  inventory: 'Inventario',
  nav_product_data: 'Datos del producto',
  products: 'Productos',
  create_serials: 'Crear series',
  create_assembles: 'Crear conjuntos',
  create_certification: 'Crear Certificación',
  inspection_reports: 'Informes de inspección',
  serial_reports: 'Informes de serie',
  kit_list: 'Lista de kits',
  my_inventory: 'Mi inventario',
  organisations: 'Organizaciones',
  product_search: 'Búsqueda de productos',
  assemblies: 'Ajustes',
  data_field: 'Campo de datos',
  product_data_category: 'Categoría de datos del producto',
  // maybe move out of general?
  data_imports: 'Importaciones de datos',
  data_imports_h2: 'Importar desde CSV',
  upload_csv_data: 'Subir datos CSV',
  status: 'estado',
  message: 'Mensaje',
  climber: 'Trepador',
  climber_number: 'Número de escalador',
  process_complete: 'Proceso completo',
  image: 'imagen',
  product: 'producto',
  yes: 'Sí',
  no: 'No',
  no_inspections: 'Sin inspecciones',
  remove: 'Remover',
  add: 'Agregar',
  asset_management: 'Gestión de Activos',
  ecommerce_intergration:
    'Integraciones de comercio electrónico/gestión de inventario',
  empty_product:
    'Esta es una URL única que se puede conectar a un elemento del equipo. Pronto podrá usar nuestra aplicación para conectar esta etiqueta a su equipo',
  unauthorized_page:
    'Lo sentimos, no está autorizado para acceder a este recurso',
  return_to_dashboard: 'Volver al tablero',
  return_home: 'Volver a casa',
  page_not_found: '¡Página no encontrada!',
  scan_qr: 'escanear qr',
  sign_into_your_account: 'Iniciar sesión en su cuenta',
  sign_up: 'Inscribirse',
  an_account: 'una cuenta',
  error: 'Error',
  please_enter_a_serial_number: 'Por favor ingrese un número de serie',
  serial: 'de serie',
  batch: 'lote',
  save_serialised_product: 'Guarde el producto serializado en el kit',
  no_data_found: 'Datos no encontrados',
  sign_out: 'Desconectar',
  update_user: 'Actualizar usuario',
  profile: 'Perfil',
  update: 'actualizar',
  reset_password: 'Restablecer la contraseña',
  serial_number: 'Número de serie',
  cancel_scan: 'Cancelar escaneo',
  sign_in: ' Iniciar sesión',
  no_results: 'no hay resultados',
  part_number: 'número de parte',
  specifications: 'Especificaciones',
  specs: 'Especificaciones',
  component_data: 'Datos del componente',
  cancel: 'cancelar',
  no_components_found_for_this_search:
    'No se encontraron componentes para este término de búsqueda. Inténtalo de nuevo.',
  there_was_an_error: 'Hubo un error',
  add_to_kit: 'Agregar al equipo',
  sign_up_for_an_account: 'Regístrese para obtener una cuenta',
  product_group: ' grupo de productos',
  group_product_tip:
    'Seleccione un grupo de productos que crea que describe mejor el artículo que está creando. Usamos este grupo de productos para definir una plantilla básica de datos para asignar a su nuevo producto.',
  product_name_tip:
    'El nombre que cubre todas las variaciones de un producto. Se recomienda no incluir detalles como el tamaño o el color en el nombre que se definen en el nivel de variante. Esto a veces se conoce como el nombre del estilo.',
  single_item_cannot_be_dissembled:
    'Un solo artículo, que no se puede desmontar. A esto lo llamamos un componente.',
  single_item_cannot_be_dissembled_tip:
    'Si su producto es un artículo único, generalmente con un número de serie único, y no se puede dividir en partes seriadas, elija el componente. Ejemplos: mosquetón, cabestrillo, casco',
  a_cut_length_of_rope:
    'Un trozo cortado de cuerda con un empalme o terminación cosida',
  a_cut_length_of_rope_tip:
    'Hemos creado este "tipo de producto" para reflejar las realidades de los revendedores de cuerdas que cortan y empalman longitudes de cuerdas de carretes/cajas originales de un fabricante. Use este "tipo de producto" para mantener todos los datos del carrete original conectados a cada longitud de cuerda',
  product_consists_of_multiple:
    ' Un producto que consta de varios componentes, como un cordón. Llamamos a esto una asamblea',
  product_consists_of_multiple_tip:
    'Para un producto que se ensambla a partir de dos o más artículos serializados, como un sistema de cuerda de seguridad o un arnés con puentes de cuerda reemplazables.',
  add_variation: 'Añadir variación',
  name_part_tip:
    'También conocido como código de producto o código SKU. Use su convención de código estándar para crear el número/código que desea usar aquí.',
  sku_tip:
    'También conocido como código de producto o código SKU. Use su convención de código estándar para crear el número/código que desea usar aquí.',
  barcode_tip:
    'Deberá crear una cuenta con su oficina regional de GS1 para crear sus EAN/UPC/códigos de barras. Estamos trabajando en una integración directa para crear códigos de barras GS1 aquí. Mira este espacio.',
  add_components: 'agregar componentes',
  below_components_will_be_added:
    'Los siguientes componentes se agregarán a cada variante que especifique arriba',
  product_code: 'código de producto',
  serial_formats: 'Formato de serie',
  include_batch_number: 'incluir número de lote',
  do_not_include_batch_number: ' No incluya el número de lote',
  update_product_category: 'Actualizar categoría de producto',
  product_groups: 'Grupos de productos',
  product_groups_are_a_grouping:
    'Los grupos de productos son una agrupación de productos similares en diseño, especificación o naturaleza.',
  title: 'título',
  prefix: 'prefijo',
  parts: 'Parts',
  history: 'historia',
  composite_data: 'Datos compuestos',
  product_history: 'historia del producto',
  shorthand_dom: 'DOM',
  customer: 'Cliente',
  sales_order: 'Pedido de venta',
  purchase_order: 'Orden de compra',
  tasks: 'Tareas',
  clear: 'Limpiar',
  go_back: 'Regresar',
  my_account: 'Mi Cuenta',
  edit_profile: 'Editar perfil',
  view_items: 'Ver artículos',
  share_app: 'Compartir aplicación',
  app: 'Aplicación',
  close: 'Cerrar',
};

const menu = {
  menu_dashboard: 'Descripción general',
  menu_users: 'Usuarios',
  menu_manufacturers: 'Fabricantes',
  menu_product_categories: 'Categorías de productos',
  menu_product_data_categories: 'Categorías de datos de productos',
  menu_products: 'Productos',
  menu_create_serials: 'Crear series',
  create_assemblies: 'Crear conjuntos',
  menu_inspections: 'Inspecciones',
  inspection_reports: 'Informes de inspección',
  menu_serial_reports: 'Informes de serie',
  menu_serial_format: 'Formato de serie',
  menu_kit_list: 'Lista de kits',
  menu_organisations: 'Organizaciones',
  menu_product_search: 'Búsqueda de productos',
  menu_inventory: 'Inventario',
  menu_my_inventory: 'Mi Inventario',
  menu_kit_bags: 'Bolsa de equipo',
  menu_locations: 'Ubicaciones',
  menu_vehicles: 'Vehículos',
  menu_clients: 'Clientes',
};

const dashboard = {
  title: 'Panel de control',
  subtitle: 'Vista general',
  user_signups: 'Registros de usuarios (últimos 7 días)',
  inspections: 'Inspección',
  inspections_week: 'Inspección (últimos 7 días)',
  product_variations: 'Variaciones de productos',
  serialised_products: 'Productos serializados',
  claimed_products: 'Productos reclamados',
};

const user = {
  create_label: 'Crear usuario',
  activated_label: 'Activado',
};

const manufacturers = {
  manufacturers_table_website: 'Página web',
  manufacturers_table_units: 'Unidades',
  create_manfacturer_btn: 'Crear fabricante',
  create_manufacturer_title: 'Crear fabricante',
  manufacturers_website: 'Página web',
  manufacturers_measurements: 'Unidades de medida',
  manufacturers_measurement_metric:
    'Las unidades de medida del producto son métricas',
  manufacturers_measurement_imperial:
    'Las unidades de medida de los productos son imperiales',
};

const product_categories = {
  title: 'Categorías de datos de productos',
  subtitle:
    'Las categorías de productos clasifican las agrupaciones de productos por industria o aplicación',
  table_hidden: 'Oculto',
  is_hidden: 'Sí/No',
  create_label: 'Crear una nueva categoría de productos',
};

const product_data_fields = {
  title: 'Categorías de productos',
  subtitle:
    'Product data categories are a group of data fields of a similar specification or nature',
  data_fields: 'Data fields',
};

const products = {
  create_product_btn: 'Crear producto',
  create_product_title: 'Crear producto',
  products_group: 'Grupo de productos',
  products_name: 'Nombre del producto',
  comp_product: 'Producto compuesto',
  products_comp_product_option1:
    'Un artículo único, que no puede ser desmontado. Lo llamamos componente.',
  products_comp_product_option2:
    'Una longitud de cuerda cortada con un empalme o una terminación cosida',
  products_comp_product_option3:
    'Un producto que consta de varios componentes, como una cuerda de seguridad. A esto lo llamamos un conjunto.',
  products_part_num: 'Número de pieza',
  products_sku_name: 'SKU Nombre',
  products_ean_upc_barcode: 'EAN/UPC/Barcode',
  claimproduct_error_msg:
    'Debe iniciar sesión para reclamar un artículo del kit',
  claimproduct_success_msg: 'Producto agregado a la lista de kits',
  claimproduct_failure_msg: 'Perdón, algo salió mal',
  item_is_in_kitlist: ' El artículo está en la lista de kits',
  add_to_kitlist: 'Agregar a la lista de equipos',
  login_to_add_to_kitlist: 'Inicie sesión para agregar a la lista de kits',
  certified: 'Certificado',
};

const create_serials = {
  serial_creation_wizard_title: 'Asistente de creación de series',
  create_component_serials: 'Crear series de componentes',
  create_rope_length_serials: 'Crear series de longitudes de cuerda',
  created_serials_title: 'Series creadas ',
  create_serials_table_serial_format: 'Formato de serie',
  create_serials_table_date_created: 'Fecha de creacion',
  create_serials_table_reference_number: 'Numero de referencia',
  create_serials_table_download: 'Descargar',
  create_serialised_title: 'Crear productos serializados',
  create_serials_ref_num_serial_format:
    'Números de referencia y formato de serie',
  create_serials_select_serial_format:
    'Seleccione el formato de serie a utilizar',
  create_serials_petzl_format: 'Formato Petzl',
  create_serials_dom: 'DOM',
  create_serials_batch: 'Lote',
  create_serials_customer: 'Cliente',
  create_serials_sales_order: 'Pedido de venta',
  create_serials_purchase_order: 'Orden de compra',
  create_serials_increments: 'Incrementos',
  create_serials_suffix: 'Sufijo',
  create_serials_p_o_num: 'Número de orden de compra',
  create_serials_enter_ref_num: 'Introduzca un número de referencia',
  create_serials_sales_order_num: 'Número de orden de venta',
  create_serials_enter_sales_order_num:
    'Introduzca un número de orden de venta',
  create_serials_customer_ref: 'Referencia del cliente',
  create_serials_batch_num: 'Número de lote',
  create_serials_enter_batch_num: 'Introduzca un número de lote',
  create_serials_select_date_manu:
    'Seleccione una fecha de fabricación para el usuario como parte de la creación de la serie',
  create_serials_date_selected: 'Fecha seleccionada:',
  create_serials_preferences_btn:
    'Guardar las preferencias de referencia y formato de la serie',
  create_serialised_products: 'Crear productos serializados',
  reference_numbers_serial_format: 'Números de referencia y formato de serie',
  enter_ref_number: 'Introduzca un número de referencia',
  sales_order_number: 'Número de orden de venta',
  enter_sales_order_number: 'Introduzca un número de orden de venta',
  customer_reference: 'Referencia del cliente',
  enter_batch_number: 'Introduzca un número de lote',
  save_reference_serial_format_btn:
    'Guardar las preferencias de referencia y formato de la serie',
  create_serials_for_cutrope: 'Crear seriales para cuerda cortada',
  rope_details: 'detalles de la cuerda',
  select_rope_cutting_from:
    'Seleccione la cuerda de la que está cortando y confirme los detalles',
  choose_rope: 'elegir cuerda',
  selected_rope: 'Cuerda seleccionada',
  rope_for_serialisation: 'Cuerdas para serialización',
  tooltip_create_serials:
    'Utilice esta herramienta para crear publicaciones seriadas de forma masiva para uno o más componentes. Útil si necesita crear seriales para órdenes de compra que van a un proveedor o si necesita múltiples seriales para un lote de artículos en producción',
  tooltip_splice_rope:
    'Utilice esta herramienta para crear series de longitudes de cuerda cortadas, cosidas o empalmadas.',
  tooltip_create_serial_menu:
    'Utilice esta herramienta para crear series contra productos que ha creado o tramos de cuerda recién cortados, empalmados o cosidos. ',
};

const assemblies = {
  assemblies_table_assembly_name: 'Nombre del conjunto',
  assemblies_table_sales_order_number: 'Numero de orden de venta',
  assemblies_table_purchase_order_number: 'Numero de orden de compra',
  assemblies_table_batch_number: 'Numero de lote',
  assemblies_table_date_manufacturer: 'Fecha del fabricante',
  assemblies_create_assemblie_btn: 'Crear nuevos conjuntos',
  assemblies_create_assemblies: 'Crear conjuntos',
  assemblies_choose_assembly: 'Elija un conjunto',
  assemblies_select_assembly_building:
    'Seleccione el conjunto que va a construir',
  selected_assembly: 'Seleccione un conjunto',
  create_assembly: '1. Crear conjuntos',
  create_assembly_adding_serials:
    'Cree un ensamblaje agregando seriales a los componentes correspondientes',
  create_another_assembly: 'Crear otro ensamblaje',
  create_an_assembly: 'Crear un ensamblaje',
  product_data: 'Datos del producto',
  tooltip_create_assemblies:
    'Use esta herramienta para agrupar componentes en serie en ensamblajes ',
};

const inspections = {
  title: 'Inspecciones',
  inspections_search_product_title: 'Buscar un producto',
  inspections_search_product_subtitle:
    'Encuentre el producto que desea inspeccionar',
  inspections_table_serial: 'Serie',
  confirm_fail_inspection_title: 'Inspección Fallida',
  confirm_fail_inspection_description:
    '¿Está seguro de que desea marcar esta inspección como fallida?',
  confirm_fail_inspection_button: 'Sí, Completar Inspección',
};

const inspection_reports = {
  inspection_reports_climber_sup_admin: 'Escalador: Super Admin',
  inspection_reports_table_inspection_comments: 'Comentarios de inspeccion',
  inspection_reports_table_image_provided: 'Imagenes proporcionadas',
  inspection_reports_officials_initials: 'Las iniciales de los funcionarios:',
};

const serialisation = {
  title: 'Publicación por entregas',
  table_created: 'Creado',
  table_sales_order_num: 'Número de orden de venta',
  table_po_num: 'Número de orden de compra',
  table_batch: 'Número de lote',
  table_date_manufacturer: 'Fecha de manfacturación',
  table_download: 'Descargar',
  filter_customer_label: 'Cliente',
  filter_customer_placeholder: 'Seleccione un cliente',
  filter_sku_label: 'SKU',
  filter_sku_placeholder: 'Buscar SKU',
  filter_category_label: 'Categoría',
  filter_category_placeholder: 'Seleccione una categoría',
  select_printer: 'Seleccionar impresora',
};

const serial_format_ = {
  serial_format_define_format_serial1:
    'Aquí es donde define su formato preferido de número de serie.',
  serial_format_define_format_serial2:
    ' Como socio de GS1, construiremos su serie en varios formatos de número de serie GS1, para diferentes aplicaciones',
  serial_format_define_format_serial3:
    'Construimos números de serie usando los siguientes componentes definibles. No todos los componentes en serie son necesarios. Simplemente defina los que desea utilizar.',
  serial_format_subtitle: 'Título',
  serial_format_title_req: 'El título es obligatorio',
  serial_format_prefix: 'Prefijo',
  serial_format_prefix_option_serial:
    'El prefijo es un componente opcional del número de serie',
  serial_format_serial_have_prefix: '¿Su número de serie tiene un prefijo?',
  serial_format_yes_option: 'Sí, quiero un prefijo para este formato de serie.',
  serial_format_no_option: 'No, el formato de serie no tiene prefijo.',
  serial_format_date_of_manu_optional:
    'Fecha de fabricación. La fecha de fabricación es un componente de serie opcional',

  serial_format_sf_batch_num1:
    ' Por lo general, recomendamos NO incluir el número de lote dentro del número de serie y, en su lugar, incluir GS1 SGTIN para agregar la información de serie.',
  serial_format_sf_batch_num2:
    'Pero para los fabricantes que lo deseen, queda la opción de incluir un número de lote dentro de su número de serie.',
  serial_format_sf_batch_num3:
    '  Al seleccionar Incluir número de lote, esto tomará el número de lote ingresado en la creación de números de serie y lo incluirá en los números de serie.',
  serial_format_cust_ref: 'Referencia del cliente',
  serial_format_dont_incl_cus_ref: 'No incluir la referencia del cliente',
  serial_format_sales_order: 'Número de orden de venta',
  serial_format_incl_order: 'Incluya el número de pedido de cliente',
  serial_format_dont_incl_order: 'No incluya el número de orden de venta',
  serial_format_p_o: 'Número de orden de compra',
  serial_format_incl_purchase_order: 'Incluya el número de orden de compra',
  serial_format_dont_incl_purchase_order:
    'No incluir número de orden de comprar',
  serial_format_incl_cus_ref: 'Incluir la referencia del cliente',
  serial_format_increments:
    'Los incrementos son un componente opcional requerido de la serie. ',
  serial_format_increments2:
    'Los incrementos permiten la cantidad de producción, con un lote, día, mes. Normalmente sugerimos de 3 a 5 caracteres, con números "rellenos", por ejemplo 0001,0002,....9999',
  serial_format_padding: '¿Aplicar el relleno?',
  serial_format_pad_serial_num: 'Sí, rellena los números de serie.',
  serial_format_dont_pad_srial_num:
    'No, no aplique relleno a los Números de Serie',
  serial_format_suffix:
    'Sufijo. El sufijo es un componente opcional de la serie',
  serial_format_does_serial_num_have_suffix:
    '¿Su número de serie tiene un sufijo?',
  serial_format_yes_suffix_for_serial_format:
    'Sí, quiero un sufijo para este formato de serie.',
  serial_format_no_suffix_for_serial_format:
    'No, el formato serial no tiene sufijo.',
  serial_format_your_serial_num_format: 'El formato de su número de serie',
  serial_format_create_serial_format: 'Crear formato serial',
  number_of_increment_characters: ' Número de caracteres de incremento',
};

const serial_format = {
  title: 'Formato de serie',
  suffix_label: 'Sufijo',
  table_label: 'Título',
  prefix_label: 'Prefijo',
  date_format_label: 'Formato de la fecha',
  batch_label: 'Lote',
  increments_label: 'Incrementos',
  descriptive_title: 'Introduzca un título descriptivo',
  dom: 'Fecha de fabricación',
  dom_yes: 'Sí, quiero una Fecha de Fabricación para este formato de Serie.',
  dom_no: 'No, el formato de serie no tiene fecha de fabricación',
};
const kit_list = {
  kit_list_table_category: 'Categoría',
  kit_list_table_items: 'Elementos',
};

const product_search = {
  product_search_search: 'Búsqueda',
};

const settings = {
  settings_logo: 'Iniciar sesión',
};

const actions = {
  assign_to_sku: 'Asignar a SKU',
  assign_to_a_team_member: 'Assign to team member',
  bulk_remove_from_inventory: 'Eliminar artículos del inventario',
  bulk_remove_from_group: 'Eliminar artículos del grupo',
};

const groups = {
  kit_bags: 'Bolsas para equipos',
  add_kit_bag: 'Agregar bolsa de equipo',
  locations: 'Ubicaciones',
  add_location: 'Agregar Direccion',
  vehicles: 'Vehículos',
  add_vehicle: 'Añadir vehículo',
  clients: 'Clientes',
  add_client: 'Agregar cliente',
  select_a_group: 'Seleccionar un grupo',
};

const auth = {
  forgot_password: '¿Olvidaste tu contraseña?',
  send_reset_password_email: 'Enviar correo de restablecimiento',
  password_reset_description:
    'Ingresa tu correo electrónico abajo y te enviaremos un enlace de recuperación para restablecer tu contraseña.',
  change_password: 'Cambiar contraseña',
  switch_account: 'Cambiar cuenta',
};

const team = {
  title: 'Equipo',
};

const onboarding = {
  skip: 'Omitir',
};

const bottom_tabs = {
  home: 'Inicio',
  inventory: 'Inventario',
  groups: 'Grupos',
  me: 'Yo',
};

const nfc = {
  remove_tags: 'Eliminar etiquetas NFC',
  confirm_remove_tags_from_item:
    '¿Estás seguro de que quieres eliminar todas las etiquetas NFC de este artículo?',
  add_tag: 'Añadir NFC',
  one_nfc_added: 'NFC añadido',
  multiple_nfcs_added: '# NFC Tags',
  scan_dialogue_instructions: `Sostenga la etiqueta NFC contra el punto óptimo NFC de su teléfono.`,
  ready_to_scan: 'Listo para escanear',
};

const multiScanNFCPrompt = `Sostenga la etiqueta NFC contra el punto óptimo NFC de su teléfono. Dale unos momentos.

Si la etiqueta no se lee, retire el teléfono y vuelva a intentarlo.`;

const multi_scan = {
  title: 'Escáner Múltiple',
  nfc_prompt_message: multiScanNFCPrompt,
  nfc_session_will_end_soon: 'La sesión NFC terminará pronto',
  nfc_timeout_title: 'Tiempo de espera NFC alcanzado',
  nfc_timeout_description: `El escáner NFC se detiene después de 60 segundos. Toque "Escanear NFC" para continuar escaneando.`,
  nfc_ios_unexpected_error_description: `Este es un error conocido de iOS. Toque "Escanear NFC" para continuar escaneando.`,
  nfc_unexpected_error_description: `Algo salió mal. Por favor, inténtelo de nuevo.`,
  nfc_unexpected_error_title: `La sesión NFC falló inesperadamente.`,
};

const tables = {
  apply_filters: 'Aplicar filtros',
  filters_label: 'Filtros',
};

const notifications = {
  notification: 'Notificación',
  notifications: 'Notificaciones',
  inspection_due: 'Inspección debida',
  expiry_due: 'Vencimiento debido',
  inspection_due_next_30_days: 'Inspección debida en los próximos 30 días',
  expiry_due_next_30_days: 'Vencimiento debido en los próximos 30 días',
  sent_weekly: 'Enviado semanalmente',
  notification_settings: 'Configuración de notificaciones',
  notification_preferences_saved: 'Preferencias de notificación guardadas',
};

export const es = {
  ...general,
  ...menu,
  ...manufacturers,
  ...products,
  ...create_serials,
  ...assemblies,
  ...inspection_reports,
  ...serial_format_,
  ...kit_list,
  ...product_search,
  ...settings,
  ...groups,
  actions,
  notifications,
  serialisation,
  inspections,
  team,
  serial_format,
  user,
  product_categories,
  dashboard,
  product_data_fields,
  auth,
  onboarding,
  bottom_tabs,
  nfc,
  multi_scan,
  tables,
};
