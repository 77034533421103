import { useQuery } from '@apollo/client';

import { ResultOf, SKU_SCREEN_DATA } from '@scannable/graphql-queries';

export type UseSkuScreenData = NonNullable<
  ResultOf<typeof SKU_SCREEN_DATA>['productVariation']
>;

export type SkuScreenDataField = NonNullable<
  NonNullable<UseSkuScreenData['data']>['data']
>[0];

export type SkuParts = NonNullable<NonNullable<UseSkuScreenData['components']>>;
interface UseSkuScreenQueryProps {
  productVariationId?: number | null;
}

export function useSkuScreenQuery({
  productVariationId,
}: UseSkuScreenQueryProps) {
  return useQuery(SKU_SCREEN_DATA, {
    variables: {
      id: Number(productVariationId),
    },
    skip: !productVariationId,
    fetchPolicy: 'cache-and-network',
  });
}
