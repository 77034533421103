import { XMarkIcon } from '@heroicons/react/20/solid';

import { cn } from '@scannable/common';
import { Search } from '@scannable/frontend/types';

import { ResizedImage } from '../../atoms';
import ItemInfo from '../ItemInfo/ItemInfo';

export interface SearchResultItemProps {
  result: Search;
  onClick?: (result: Search) => void;
  buttonLabel?: string;
  classNames?: string;
  isMultiScan?: boolean;
}

export function SearchResultItem({
  result,
  onClick,
  classNames,
  isMultiScan,
}: SearchResultItemProps) {
  const handleClick = () => {
    if (result.serialisedProductId || result.productVariationId) {
      const id = (result.serialisedProductId ||
        result.productVariationId) as number;
      onClick?.({
        ...result,
        id,
      });
    }
  };
  return (
    <div
      onClick={() => {
        if (!onClick) {
          return;
        }

        // we only want to listen to the onClick on the X button
        // for multi-scan results
        if (isMultiScan) {
          return;
        }
        handleClick();
      }}
      data-cy="search-result"
      className={cn(
        classNames || '',
        'flex flex-row border-b border-gray-200 py-4',
        isMultiScan ? '' : 'cursor-pointer'
      )}
    >
      <div className="h-12 relative">
        <ResizedImage
          className="object-contain object-center rounded-md border border-gray-200"
          src={result.image?.uri || ''}
          alt={result.info?.name || ''}
          height={48}
          width={48}
          fit="fill"
        />
      </div>
      <div className="ml-4 flex flex-1 flex-col mr-5">
        <ItemInfo
          name={result?.info?.name}
          serialNumber={result.info?.serialNumber}
          manufacturerName={result.info?.manufacturerName}
          code={result.info?.code}
          productName={result.info?.productName}
        />
      </div>
      {isMultiScan && (
        <div>
          <button
            onClick={() => {
              handleClick();
            }}
            className="ml-2 rounded-full flex justify-center items-center text-gray-400 bg-gray-100 h-8 w-8"
          >
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  );
}

export default SearchResultItem;
