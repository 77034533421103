import { useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { h } from '@scannable/frontend/common';

import { Alert } from '../../atoms';
import ModalForm from '../../forms/ModalForm/ModalForm';
import { useAlert } from '../../hooks/useAlert/useAlert';
import { useTable } from '../../hooks/useTable/useTable';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

interface EditItemInspectionFrequencyValues {
  alertTemplateId?: number;
  name: string;
  alertUnitValue?: string;
  quantity?: number;
  due?: string | null;
  startDate?: string | null;
}

export interface EditItemInspectionFrequencyModalProps {
  title: string;
  serialisedProductId?: number;
  serialisedProductIds?: number[];
}
const validationSchema = yup.object().shape({
  alertTemplateId: yup.number().required('Please make a selection'),
  name: yup.string().when('alertTemplateId', {
    is: 0,
    then: yup.string().required('Please enter a name'),
  }),
  alertUnitValue: yup.string().when('alertTemplateId', {
    is: 0,
    then: yup.string().required('Please select a unit'),
  }),
});

export function EditItemInspectionFrequencyModal({
  title,
  serialisedProductIds,
  serialisedProductId,
}: EditItemInspectionFrequencyModalProps) {
  const { hideModal } = useModal();
  const {
    addAlertTemplate,
    addAlerts,
    alertUnitFromValue,
    alertUnits,
    alertTemplateOptions,
  } = useAlert();
  const { reload } = useTable();
  const [showCustom, setShowCustom] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      alertTemplateId: undefined,
      name: '',
      alertUnitValue: '',
      quantity: 0,
      due: undefined,
      startDate: undefined,
    } as EditItemInspectionFrequencyValues,
    validationSchema,
    onSubmit: async (
      values: EditItemInspectionFrequencyValues,
      actions: FormikHelpers<EditItemInspectionFrequencyValues>
    ) => {
      const serialisedProductIdsPayload =
        serialisedProductIds ??
        (serialisedProductId ? [serialisedProductId] : []);
      if (values?.alertTemplateId && values?.alertTemplateId !== 0) {
        await resolveMutation(
          addAlerts({
            variables: {
              data: {
                alertTemplateId: values?.alertTemplateId,
                serialisedProductIds: serialisedProductIdsPayload,
                startDate: values?.startDate,
              },
            },
          }),
          {
            successMessage: 'Successfully set inspection frequency',
          }
        );
      } else {
        const { schedule } = alertUnitFromValue(
          values?.alertUnitValue as string
        );
        await resolveMutation(
          addAlertTemplate({
            variables: {
              data: {
                name: values?.name,
                unit: schedule.unit,
                quantity: schedule.quantity,
                serialisedProductIds: serialisedProductIdsPayload,
                startDate: values?.startDate,
              },
            },
          }),
          {
            successMessage: 'Successfully set inspection frequencies',
          }
        );
      }
      reload();
      actions.setSubmitting(false);
      hideModal();
    },
  });

  // handle showing custom fields
  useEffect(() => {
    if (formik.values.alertTemplateId === 0 && !showCustom) {
      setShowCustom(true);
    }
  }, [formik, formik.values.alertTemplateId, showCustom]);

  return (
    <Modal title={h(title)} size="sm" overflowHidden={false}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: 'Set Inspection Frequency',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
            'data-cy': 'edit-inspection-frequency',
          },
        ]}
      >
        <InputFieldGroup
          id="template-select"
          name="alertTemplateId"
          type="select"
          data-cy="alert-template"
          formik={formik}
          label="Choose a frequency"
          options={alertTemplateOptions}
          config={{
            defaultOptions: [
              {
                label: 'Create new...',
                value: 0,
              },
            ],
          }}
        />

        {showCustom && (
          <>
            <InputFieldGroup
              id="name"
              name="name"
              type="text"
              formik={formik}
              label="Label"
              data-cy="alert-label"
            />
            <InputFieldGroup
              id="unit-select"
              name="alertUnitValue"
              type="react-select"
              data-cy="alert-frequency"
              formik={formik}
              label="Inspection frequency"
              options={alertUnits.map((unit) => ({
                label: unit.label,
                value: unit.value,
              }))}
            />
          </>
        )}
        <InputFieldGroup
          id="startDate"
          name="startDate"
          type="date"
          formik={formik}
          label="Start Date"
          placeholder="Start Date"
          wrapperClassName="w-full"
        />
        {!formik.values.startDate && (
          <div className="pt-4 text-sm">
            <Alert
              type="warning"
              message="Please note: If a start date is not provided any frequency set here will be applied from each item's last inspection date. If there is no last inspection date, it will be set from today's date."
            />
          </div>
        )}
      </ModalForm>
    </Modal>
  );
}

export default EditItemInspectionFrequencyModal;
