import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';

import { Organisation, SerialFormat } from '@scannable/common';
import { ASSIGN_SERIAL_FORMATS } from '@scannable/graphql-queries';

import ModalForm from '../../forms/ModalForm/ModalForm';
import useSerialFormat from '../../hooks/useSerialFormat/useSerialFormat';
import useTranslation from '../../hooks/useTranslation/useTranslation';
import { resolveMutation } from '../../lib/lib';
import InputFieldGroup from '../../molecules/InputFieldGroup/InputFieldGroup';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

type UserSerialFormatValues = {
  serialFormats: number[];
};

export interface UserSerialFormatsModalProps {
  teamMemberName: string;
  organisationUserId: number;
  serialFormatIds: number[];
}

export function UserSerialFormatsModal({
  teamMemberName,
  organisationUserId,
  serialFormatIds,
}: UserSerialFormatsModalProps) {
  const { hideModal } = useModal();
  const [assignSerialFormats] = useMutation(ASSIGN_SERIAL_FORMATS, {
    refetchQueries: [
      Organisation.OrganisationTeam,
      SerialFormat.GetUserSerialFormats,
    ],
  });

  const { manufacturerSerialFormats } = useSerialFormat();
  const options = manufacturerSerialFormats
    ? manufacturerSerialFormats.map((s) => ({
        label: s.name ?? '',
        value: s.id,
      }))
    : [];
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      serialFormats: serialFormatIds ?? [],
    },
    onSubmit: async (values: UserSerialFormatValues, { setSubmitting }) => {
      const result = await resolveMutation(
        assignSerialFormats({
          variables: {
            data: {
              organisationUserId,
              formatIds: values.serialFormats,
            },
          },
        }),
        {
          successMessage: 'Serial formats assigned successfully',
        }
      );
      if (result.ok) {
        setSubmitting(false);
        hideModal();
      }
    },
  });

  return (
    <Modal
      title={`Assign Serial Formats to ${teamMemberName}`}
      overflowHidden={false}
    >
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: t('assign'),
            type: 'submit',
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup
          name="serialFormats"
          type="select"
          formik={formik}
          label="Select Serial Formats"
          data-cy="user-serial-formats"
          options={options}
          config={{
            isMulti: true,
          }}
        />
      </ModalForm>
    </Modal>
  );
}

export default UserSerialFormatsModal;
