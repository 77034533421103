import { FormikProps } from 'formik';

import { DescriptionListItem, InputFieldGroup } from '../../molecules';

export interface BatchNumberDescriptionListProps {
  formik: FormikProps<unknown>;
}

export function BatchNumberDescriptionListItem({
  formik,
}: BatchNumberDescriptionListProps) {
  return (
    <DescriptionListItem title="Batch number">
      <InputFieldGroup
        id="BatchNumber"
        label="Batch number"
        placeholder="Enter a batch number"
        name="batchNumber"
        type="text"
        formik={formik}
      />
    </DescriptionListItem>
  );
}

export default BatchNumberDescriptionListItem;
