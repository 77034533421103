import {
  CanView,
  featureFlagEnabled,
  features,
} from '@scannable/frontend/common';

import { useIsPro, useIsSuperAdmin } from '../../auth';

export function useFeatureFlag(flag: keyof typeof features) {
  const isSuperAdmin = useIsSuperAdmin();
  const isPro = useIsPro();

  const feature = features[flag];

  if (feature.canView === CanView.everyone) {
    return true;
  }
  if (feature.canView === CanView.superAdmin && isSuperAdmin) {
    return true;
  }
  const isFeatureEnabled = featureFlagEnabled(flag);

  if ('isPro' in feature) {
    return isPro && isFeatureEnabled;
  }

  return isFeatureEnabled;
}
