import { useLazyQuery } from '@apollo/client';

import {
  ACCOUNT_NFC_SEARCH,
  GLOBAL_ITEM_SEARCH,
  GLOBAL_NFC_SEARCH,
  INVENTORY_SEARCH,
  SKU_SEARCH,
  UseLazyQueryOptions,
} from '@scannable/graphql-queries';

export function useLazyInventorySearch(
  options?: UseLazyQueryOptions<typeof INVENTORY_SEARCH>
) {
  return useLazyQuery(INVENTORY_SEARCH, options);
}

export function useLazyGlobalItemSearch(
  options?: UseLazyQueryOptions<typeof GLOBAL_ITEM_SEARCH>
) {
  return useLazyQuery(GLOBAL_ITEM_SEARCH, options);
}

export function useLazySkuSearch(
  options?: UseLazyQueryOptions<typeof SKU_SEARCH>
) {
  return useLazyQuery(SKU_SEARCH, options);
}

export function useLazyGlobalNFCSearch(
  options?: UseLazyQueryOptions<typeof GLOBAL_NFC_SEARCH>
) {
  return useLazyQuery(GLOBAL_NFC_SEARCH, options);
}

export function useLazyAccountNFCSearch(
  options?: UseLazyQueryOptions<typeof ACCOUNT_NFC_SEARCH>
) {
  return useLazyQuery(ACCOUNT_NFC_SEARCH, options);
}
