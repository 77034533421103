import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';

import { Organisation } from '@scannable/common';
import { FormFieldOptionType } from '@scannable/frontend/types';
import { ASSIGN_PRINTERS } from '@scannable/graphql-queries';

import { ModalForm } from '../../forms';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

type UserPrinterValues = {
  printers: string[];
};

type UserPrintersModalProps = {
  options: FormFieldOptionType[];
  organisationUserId: number;
  teamMemberName: string;
} & Partial<UserPrinterValues>;

export function UserPrintersModal({
  options,
  organisationUserId,
  teamMemberName,
  printers,
}: UserPrintersModalProps) {
  const { hideModal } = useModal();
  const [assignPrinter] = useMutation(ASSIGN_PRINTERS, {
    refetchQueries: [Organisation.OrganisationTeam],
  });
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      printers: printers ?? [],
    },
    onSubmit: async (values: UserPrinterValues, { setSubmitting }) => {
      const result = await resolveMutation(
        assignPrinter({
          variables: {
            data: {
              organisationUserId: organisationUserId,
              printerIds: values.printers,
            },
          },
        }),
        {
          successMessage: 'Printers assigned successfully',
        }
      );
      if (result.ok) {
        setSubmitting(false);
        hideModal();
      }
    },
  });

  return (
    <Modal
      title={`Assign Printers to ${teamMemberName}`}
      overflowHidden={false}
    >
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: t('assign'),
            type: 'submit',
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup
          id="userId"
          name="printers"
          type="select"
          formik={formik}
          label="Select printers"
          data-cy="user-printers"
          options={options}
          config={{
            isMulti: true,
          }}
        />
      </ModalForm>
    </Modal>
  );
}
