import { useState } from 'react';

import { SortOrder } from '@scannable/frontend/common';

import {
  NullableOrderBy,
  TableColumn,
  TableOrderBy,
} from '../../../types/table.types';

type UseOrderBy = {
  initialOrderBy: TableOrderBy | undefined;
  secondaryOrderBy: TableOrderBy | undefined;
};
export const useOrderBy = <T>({
  initialOrderBy,
  secondaryOrderBy,
}: UseOrderBy) => {
  // tracks the current sort order
  const [activeSort, setActiveSort] = useState<TableOrderBy | undefined>(
    initialOrderBy
  );
  // tracks both current and secondary sort
  const [orderBy, setOrderBy] = useState<TableOrderBy[] | undefined>(
    [initialOrderBy, secondaryOrderBy].filter(Boolean) as TableOrderBy[]
  );

  const updateSort = (newSort: TableOrderBy) => {
    if (secondaryOrderBy) {
      setOrderBy([newSort, secondaryOrderBy]);
    } else {
      setOrderBy([newSort]);
    }
    setActiveSort(newSort);
  };
  const setOrder = (col: TableColumn<T>) => {
    const isBasicSort = typeof col.canSort === 'boolean';
    const isNullableSort = typeof col.canSort === 'object' && col.canSort.nulls;
    const defaultSort = SortOrder.asc;

    // Determine the current sort order for the column
    const currentSort = activeSort && activeSort[col.accessor as string];
    const hasCurrentSort = currentSort !== undefined;

    if (isNullableSort && !hasCurrentSort) {
      // If nullable sort and not currently sorted, set initial sort
      updateSort({
        [col.accessor]: {
          sort: defaultSort,
        },
      });
    } else if (isNullableSort && hasCurrentSort) {
      // Toggle between asc and desc for nullable sort
      const nullableSort = currentSort as NullableOrderBy;
      const newSort =
        nullableSort.sort === SortOrder.desc ? SortOrder.asc : SortOrder.desc;
      updateSort({
        [col.accessor]: {
          sort: newSort,
        },
      });
    } else if (isBasicSort) {
      // Toggle between asc and desc for basic sort
      const newSort =
        !activeSort || currentSort === SortOrder.desc
          ? SortOrder.asc
          : SortOrder.desc;
      updateSort({ [col.accessor]: newSort });
    }
  };

  return { orderBy, activeSort, setOrder };
};
