import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';

import { Inventory } from '@scannable/common';
import { Search } from '@scannable/frontend/types';
import { ASSIGN_ITEMS_TO_SKU } from '@scannable/graphql-queries';

import { SidePanelForm } from '../../forms';
import { resolveMutation } from '../../lib/lib';
import { SearchResultItem } from '../../molecules';
import { SearchInput } from '../../organisms';
import { useSidePanel } from '../SidePanelContext/SidePanelContext';

interface AssignSkuSidePanelProps {
  itemIds: number[];
}
type AssignSkuValues = {
  productVariationId: number | null;
};

export function AssignSkuSidePanel({ itemIds }: AssignSkuSidePanelProps) {
  const { hideSidePanel } = useSidePanel();
  const [assignItemsToSku] = useMutation(ASSIGN_ITEMS_TO_SKU, {
    refetchQueries: [Inventory.PaginatedWebInventory],
  });
  const formik = useFormik<AssignSkuValues>({
    initialValues: {
      productVariationId: null,
    },
    onSubmit: async (values, actions) => {
      const result = await resolveMutation(
        assignItemsToSku({
          variables: {
            data: {
              itemIds,
              productVariationId: Number(values.productVariationId),
            },
          },
        }),
        {
          successMessage: 'Items assigned to SKU',
        }
      );
      actions.setSubmitting(false);
      if (result.ok) {
        hideSidePanel();
      }
    },
  });
  const [result, setResult] = useState<Search | null>(null);
  const setProductVariation = (productVariation: Search) => {
    setResult(productVariation);
    formik.setFieldValue('productVariationId', productVariation.id);
  };
  return (
    <SidePanelForm
      formik={formik}
      buttons={[
        {
          label:
            itemIds?.length > 1
              ? `Assign ${itemIds?.length} Items to New SKU`
              : 'Assign to New SKU',
          type: 'submit',
          color: 'primary',
        },
      ]}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      {result && <SearchResultItem result={result} />}
      <SearchInput
        name="productVariationId"
        showScannerInput={false}
        addToCallback={setProductVariation}
        resetAfterCallback
      />
    </SidePanelForm>
  );
}

export default AssignSkuSidePanel;
