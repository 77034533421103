import { useId } from 'react';
import { components, GroupBase, Props } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { FormFieldOptionType } from '@scannable/frontend/types';

export interface CreateableSelectInputProps {
  name: HTMLSelectElement['name'];
}

export function CreateableSelectInput<
  IsMulti extends boolean = false,
  Group extends GroupBase<FormFieldOptionType> = GroupBase<FormFieldOptionType>
>({
  id,
  name,
  onChange,
  options,
}: Props<FormFieldOptionType, IsMulti, Group> & CreateableSelectInputProps) {
  return (
    <CreatableSelect
      instanceId={useId()}
      id={id}
      name={name}
      isClearable
      onChange={onChange}
      options={options}
      classNamePrefix="select"
      placeholder="Type a new value or select an exising"
      components={{
        Input: (props) => (
          <components.Input {...props} aria-activedescendant={undefined} /> // to fix https://github.com/JedWatson/react-select/issues/5459#issuecomment-1875022105
        ),
      }}
    />
  );
}

export default CreateableSelectInput;
