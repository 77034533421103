import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';

import { LabelSize, Printing } from '@scannable/common';
import { UPDATE_PRINTERS } from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { FormAdminPanel, InputFieldArray } from '../../molecules';
import Form from '../Form/Form';

type EditPrintersValues = {
  printers: {
    name: string;
    id: string;
    double?: boolean;
    logo?: string;
    labelSize?: 'Large' | 'Small';
  }[];
};
type EditPrintersFormProps = EditPrintersValues & {
  organisationId: number;
};

export function EditPrintersForm({
  printers,
  organisationId,
}: EditPrintersFormProps) {
  const [updatePrinters] = useMutation(UPDATE_PRINTERS, {
    refetchQueries: [Printing.GetAllPrintersQuery],
  });
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      printers:
        printers && printers.length > 0
          ? printers.map((printer) => ({
              name: printer.name,
              id: printer.id,
              double: printer.double,
              logo: printer.logo,
              labelSize: printer.labelSize,
            }))
          : [
              {
                name: '',
                id: '',
                double: false,
                logo: '',
                labelSize: LabelSize.Large,
              },
            ],
    },
    enableReinitialize: true,
    onSubmit: async (values: EditPrintersValues, { setSubmitting }) => {
      const result = await resolveMutation(
        updatePrinters({
          variables: {
            data: {
              printers: values.printers,
              organisationId,
            },
          },
        }),
        {
          successMessage: 'Printers updated',
        }
      );
      if (result.ok) {
        setSubmitting(false);
      }
    },
  });
  return (
    <Form formik={formik}>
      <FormAdminPanel
        renderFormFields={() => (
          <InputFieldArray
            formik={formik}
            name="printers"
            buttonLabel="Printer"
            options={[
              {
                type: 'text',
                name: 'name',
                label: 'Printer Name',
                placeholder: 'Printer Name (e.g. Warehouse)',
              },
              {
                type: 'text',
                name: 'id',
                label: 'Printer ID',
                placeholder: 'Printer ID (e.g. FM123456)',
              },
              {
                type: 'react-select',
                name: 'double',
                label: 'Print Double Labels',
                placeholder: 'No',
                options: [
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ],
              },
              {
                type: 'text',
                name: 'logo',
                label: 'Logo Number',
                placeholder: '(e.g. 1)',
              },
              {
                type: 'react-select',
                name: 'labelSize',
                label: 'Label Size',
                placeholder: 'Large',
                options: [
                  { value: LabelSize.Large, label: '30mm' },
                  { value: LabelSize.Small, label: '18mm' },
                ],
              },
            ]}
          />
        )}
        renderFormAction={() => (
          <Button
            label={t('update')}
            type="submit"
            loading={formik.isSubmitting}
          />
        )}
      />
    </Form>
  );
}
export default EditPrintersForm;
