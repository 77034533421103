import { Alerts, InspectionFrequency } from '@scannable/common';

import { graphql } from '../graphql';

export const CREATE_ALERT_TEMPLATE =
  graphql(`mutation ${InspectionFrequency.CreateAlertTemplate} ($data: CreateAlertTemplateInput!) {
    createAlertTemplate(data: $data) {
      id
    }
}`);

export const UPDATE_ALERT_TEMPLATE =
  graphql(`mutation ${InspectionFrequency.UpdateAlertTemplate}($data: UpdateAlertTemplateInput!) {
    updateAlertTemplate(data: $data) {
      id
    }
  }`);

export const DELETE_ALERT_TEMPLATE = graphql(`
  mutation ${InspectionFrequency.DeleteAlertTemplate}($id: Int!) {
    deleteAlertTemplate(id: $id) {
      id
    }
  }
`);
export const CREATE_ALERTS = graphql(`
  mutation ${Alerts.CreateAlerts}($data: CreateAlertsInput!) {
    createAlerts(data: $data) {
      count
    }
  }
`);
