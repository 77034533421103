import { useCallback } from 'react';

import useTranslation from '../../hooks/useTranslation/useTranslation';
import { useModal } from '../ModalContext/ModalContext';
import ConfirmationModal from './ConfirmationModal';

export function useConfirmModal() {
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation();

  const handleShowConfirmModal = useCallback(
    ({
      title,
      message,
      buttonLabel,
    }: {
      title?: string;
      message?: string;
      buttonLabel?: string;
    }) => {
      const resolvedTitle = title || t('are_you_sure');

      return new Promise((resolve, reject) => {
        showModal(
          <ConfirmationModal
            title={resolvedTitle}
            message={message}
            onConfirm={async () => {
              hideModal();
              resolve(true);
            }}
            buttonLabel={buttonLabel || t('yes')}
            onDismiss={() => {
              hideModal();
              resolve(false);
            }}
          />
        );
      });
    },
    [hideModal, showModal, t]
  );

  return {
    showConfirmModal: handleShowConfirmModal,
  };
}
