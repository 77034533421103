import Link from 'next/link';

import { cn } from '@scannable/common';

import { LoadingPlaceholder, ToolTip } from '../../atoms';

export interface DashboardCardProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  card: { icon: any };
  amount?: string | number;
  percent?: string | number;
  ctaHref?: string;
  ctaText?: string;
  tooltip?: string;
  showWarning?: boolean;
}

export function DashboardCard({
  name,
  card,
  amount,
  percent,
  ctaHref,
  ctaText,
  tooltip,
  showWarning = false,
}: DashboardCardProps) {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <card.icon
              className={cn(
                'h-6 w-6 ',
                !showWarning ? 'text-gray-400' : 'text-red-600'
              )}
              aria-hidden="true"
            />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt
                className={cn(
                  'text-sm font-medium text-gray-500 truncate',
                  !showWarning ? 'text-gray-500' : 'text-red-600'
                )}
              >
                {name}
                {tooltip && <ToolTip msg={tooltip} />}
              </dt>
              <dd>
                <div
                  className={cn(
                    'text-lg font-medium',
                    !showWarning ? 'text-gray-900' : 'text-red-600'
                  )}
                >
                  {amount || <LoadingPlaceholder height="6" width="14" />}
                  {percent ? ` (${percent} %)` : ''}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {ctaHref && (
        <div className="bg-gray-50 px-5 py-3">
          <div className="text-sm">
            <Link
              href={ctaHref}
              passHref
              className={cn(
                'font-medium ',
                !showWarning
                  ? 'text-cyan-700 hover:text-cyan-900'
                  : 'text-red-600 hover:text-red-800'
              )}
            >
              {ctaText || 'View'}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
export default DashboardCard;
