import { AllHTMLAttributes } from 'react';

import { classNames } from '@scannable/common';

export function TableTable({
  children,
  className,
  ...rest
}: AllHTMLAttributes<HTMLTableElement>) {
  return (
    <table
      className={classNames(
        'min-w-full divide-y divide-gray-200',
        className || ''
      )}
      {...rest}
    >
      {children}
    </table>
  );
}

export default TableTable;
