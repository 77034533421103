const expiryValues = ['overdue', '7days', 'month', '3month'];

export type ExpiryValue = typeof expiryValues[number];

export const expiryFilterOptions: {
  label: string;
  value: ExpiryValue;
}[] = [
  {
    label: 'Expired',
    value: 'overdue',
  },
  {
    label: 'Expires in next 7 days',
    value: '7days',
  },
  {
    label: 'Expires in next month',
    value: 'month',
  },
  {
    label: 'Expires in next 3 months',
    value: '3month',
  },
];
