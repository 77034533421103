import { useQuery } from '@apollo/client';

import { FIND_MANY_PRODUCT_VARIATIONS } from '@scannable/graphql-queries';

export function useProductSkus(productId?: number | null) {
  const { data } = useQuery(FIND_MANY_PRODUCT_VARIATIONS, {
    variables: {
      where: {
        productId: {
          equals: Number(productId),
        },
      },
    },
    skip: !productId,
  });
  const productVariationOptions = (data?.productVariations ?? [])
    .map((pv) => ({ label: pv.code || pv.name || '', value: pv.id }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    options: productVariationOptions,
  };
}
