import { Product } from '@scannable/common';

import { graphql } from '../graphql';

export const PAGINATED_PRODUCTS = graphql(`
    query ${Product.PaginatedProductsQuery} (
          $where: ProductWhereInput
          $orderBy: [ProductOrderByWithRelationInput!]
          $take: Int
          $skip: Int
        ) {
          paginatedProducts(
            where: $where
            orderBy: $orderBy
            take: $take
            skip: $skip
          ) {
            nodes {
              id
              name
              productGroupId
              productVariationsCount
              image {
                id
                smallUrl
                uri
              }
            }
            totalCount
          }
        }
      `);

export const GET_ALL_MY_PRODUCTS = graphql(`
  query ${Product.AllOfMyProducts}(
    $where: ProductWhereInput
    $orderBy: [ProductOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    products(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
    }
  }
`);

export const GET_PRODUCT_DATA = graphql(`
   query ${Product.GetProductDataQuery}($id: Int!) {
      product(id: $id) {
        id
        name
        data {
          data {
            id
            dataId
            name
            dataType
            value
            hasUnits
            unit
            isNumeric
            availableUnits
            metadataId
            productDataType
            key
            order
            pdfs {
              id
              name
              url
            }
            images {
              id
              name
              uri
            }
            certifications {
              id
              name
              displayName
            }
          }
        }
        productGroupCategory {
          id
          name
        }
        productGroup {
          id
          uuid
          hidden
          name
          productGroupCategory {
            id
            uuid
            name
          }
        }
        manufacturer {
          id
          name
        }
      }
   }
  `);
