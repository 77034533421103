import { MouseEvent, useMemo } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { FormikProps } from 'formik';

import { cn } from '@scannable/common';
import { tableBtnLabel } from '@scannable/frontend/common';

import { Button } from '../../atoms';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { FormFieldConfig } from '../../types/forms.types';
import InputFieldGroup from '../InputFieldGroup/InputFieldGroup';
import { SortableItem } from '../SortableItem/SortableItem';
import { SortableWrapper } from '../SortableWrapper/SortableWrapper';

function InputError({
  fieldErrorsArray,
  rowKey,
  subFieldName,
}: {
  fieldErrorsArray: { [k: string]: string }[];
  rowKey: number;
  subFieldName: string;
}) {
  if (
    fieldErrorsArray &&
    fieldErrorsArray[rowKey] &&
    fieldErrorsArray[rowKey][subFieldName]
  ) {
    return (
      <p className="mt-2 text-sm text-red-600" id="VariationsError">
        {fieldErrorsArray[rowKey][subFieldName]}
      </p>
    );
  }
  return null;
}

export interface OptionProp extends FormFieldConfig {
  tooltip?: string | undefined;
  defaultValue?: string | undefined;
  group?: string;
}

export interface InputFieldArrayProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  name: string;
  options: OptionProp[];
  enableReset?: boolean;
  cols?: number;
  colsSm?: number;
  group?: boolean;
  buttonLabel?: string;
  sortable?: boolean;
}

export function InputFieldArray({
  formik,
  name,
  options,
  enableReset,
  colsSm,
  cols,
  group,
  buttonLabel,
  sortable = false,
}: InputFieldArrayProps) {
  const { t } = useTranslation();
  const item = useMemo(() => {
    return Object.fromEntries(
      options.map((k) => {
        return [k.name, k.defaultValue || ''];
      })
    );
  }, [options]);

  const addField = (e: MouseEvent | null = null) => {
    const newValue = [...formik.values[name], item];
    formik.setFieldValue(name, newValue);
  };

  const removeField = (index: number) => {
    const newValue = formik.values[name].filter(
      (value: FormFieldConfig['value'], rowKey: number) => rowKey !== index
    );
    formik.setFieldValue(name, newValue);
  };

  const reset = (e: MouseEvent) => {
    e.preventDefault();
    formik.setFieldValue(name, [item]);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (sortable && active.id !== over?.id) {
      const oldIndex = formik.values[name].findIndex(
        (item: HTMLElement, index: number) => index.toString() === active.id
      );
      const newIndex = formik.values[name].findIndex(
        (item: HTMLElement, index: number) => index.toString() === over?.id
      );

      const newValue = arrayMove(formik.values[name], oldIndex, newIndex);
      formik.setFieldValue(name, newValue);
    }
  };

  const renderField = (value: FormFieldConfig['value'], rowKey: number) => (
    <div
      className={cn(
        cols && cols <= 1 ? 'flex-col' : 'flex-row flex items-center space-x-2'
      )}
    >
      {group && (
        <div className="flex flex-row justify-between w-full">
          <span className="text-xs bg-gray-100 px-2 py-1 rounded-md">
            Step #{rowKey + 1}
          </span>
          {sortable && (
            <div className="cursor-move">
              <EllipsisVerticalIcon className="w-5 h-5 text-gray-400" />
            </div>
          )}
        </div>
      )}
      {options &&
        options.map((option, FieldKey: number) => {
          return (
            <div
              key={FieldKey}
              className={cn(
                option.type === 'hidden'
                  ? 'hidden'
                  : 'flex flex-col flex-grow my-2'
              )}
            >
              <InputFieldGroup
                formik={formik}
                data-cy={name}
                {...{
                  type: option.type,
                  name: `${name}[${rowKey}][${option.name}]`,
                  label: rowKey === 0 ? option.label : undefined,
                  tooltip: rowKey === 0 ? option.tooltip : undefined,
                  options: option.options,
                  placeholder: option.placeholder,
                  value:
                    formik.values[name][rowKey][option.name] ||
                    option.value ||
                    '',
                }}
              />
              <InputError
                fieldErrorsArray={
                  formik.errors[name] as { [k: string]: string }[]
                }
                rowKey={rowKey}
                subFieldName={option.name}
              />
            </div>
          );
        })}
      {formik.values[name].length > 1 && (
        <div>
          {cols && cols <= 1 ? (
            <div className="flex justify-end">
              <span
                onClick={() => removeField(rowKey)}
                title="Remove"
                className="cursor-pointer justify-self-end text-xs text-gray-500 pb-4"
              >
                Remove
              </span>
            </div>
          ) : (
            <div
              onClick={() => removeField(rowKey)}
              title="Remove"
              className={cn(
                'cursor-pointer rounded-full bg-gray-300 hover:bg-gray-200 w-4 h-4 pl-0.5 pt-0.5 text-white',
                rowKey === 0 && cols !== 1 ? 'mt-6' : ''
              )}
            >
              <div>
                <XMarkIcon className="w-3 h-3" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <>
      {sortable ? (
        <SortableWrapper
          handleDragEnd={handleDragEnd}
          items={formik.values[name].map((_: unknown, index: number) =>
            index.toString()
          )}
        >
          <div>
            {formik.values[name] &&
              formik.values[name].map(
                (value: FormFieldConfig['value'], rowKey: number) => (
                  <SortableItem key={rowKey} id={rowKey.toString()}>
                    {renderField(value, rowKey)}
                  </SortableItem>
                )
              )}
          </div>
        </SortableWrapper>
      ) : (
        <div>
          {formik.values[name] &&
            formik.values[name].map(
              (value: FormFieldConfig['value'], rowKey: number) => (
                <div key={rowKey}>{renderField(value, rowKey)}</div>
              )
            )}
        </div>
      )}
      {formik.errors[name] && !Array.isArray(formik.errors[name]) && (
        <p className="mt-2 text-sm text-red-600" id="VariationsError">
          {formik.errors[name] as string}
        </p>
      )}
      <div>
        <Button onClick={addField} color="info" type="button">
          {tableBtnLabel(`${t('add')} ${buttonLabel ?? name}`)}
        </Button>
        {enableReset && (
          <Button
            label={`${t('reset')} ${buttonLabel ?? name}`}
            type="button"
            onClick={reset}
          />
        )}
      </div>
    </>
  );
}

export default InputFieldArray;
