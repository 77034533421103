const general = {
  create_label: 'Create',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email',
  password: 'Password',
  manufacturer: 'Manufacturer',
  edit_manufacturer: 'Edit manufacturer',
  gear_list: 'Gear list',
  role: 'Role',
  invites: 'Invites',
  invitations: 'Invitations',
  subscription: 'Subscription',
  accept: 'Accept',
  decline: 'Decline',
  date: 'Date',
  date_sent: 'Date sent',
  accepted: 'Accepted',
  pending: 'Pending',
  super_user: 'Super user',
  submit_btn: 'Submit',
  done_btn: 'Done',
  next_btn: 'Next',
  previous_btn: 'Previous',
  table_name: 'Name',
  settings: 'Settings',
  name: 'Name',
  view: 'View',
  delete: 'Delete',
  reset: 'Reset',
  submit: 'Submit',
  assign: 'Assign',
  table_view: 'View',
  table_email: 'Email',
  edit: 'Edit',
  users: 'Users',
  login: 'Login',
  plan: 'Scannable Plan',
  category_name: 'Category name',
  category_description: 'Category description',
  select: 'Select',
  upload_a_file: 'Upload a file',
  drag_and_drop: 'or drag and drop',
  unsupported_field: 'Unsupported field',
  type: 'Type',
  unit: 'Unit',
  verified: 'Verified',
  available_units: 'Available units',
  inspect: 'Inspect',
  inspection: 'Inspection',
  dashboard: 'Dashboard',
  manufacturers: 'Manufacturers',
  inventory: 'Inventory',
  nav_product_data: 'Product Data',
  products: 'Products',
  create_serials: 'Create Serials',
  create_assembles: 'Create Assemblies',
  create_certification: 'Create Certification',
  inspection_reports: 'Inspection Reports',
  serial_reports: 'Serial Reports',
  kit_list: 'Kit List',
  my_inventory: 'My Inventory',
  organisations: 'Organisations',
  product_search: 'Product Search',
  assemblies: 'Assemblies',
  data_field: 'Data field',
  product_data_category: 'Product Data Category',
  // maybe move out of general?
  data_imports: 'Data imports',
  data_imports_h2: 'Import from CSV',
  upload_csv_data: 'Upload CSV data',
  status: 'Status',
  result: 'Result',
  message: 'Message',
  climber: 'Climber',
  climber_number: 'Climber number',
  process_complete: 'Process complete',
  image: 'image',
  product: 'Product',
  yes: 'Yes',
  no: 'No',
  no_inspections: 'No inspections',
  remove: 'Remove',
  add: 'Add',
  asset_management: 'Assest management',
  ecommerce_intergration: 'E-commerce / Inventory management integrations',
  empty_product:
    'This is a unique url that can be connected to an item of equipment. Soon you`ll be able to use our app to connect this tag to your equipment.',
  unauthorized_page: "Sorry, it looks like you can't view this page.",
  return_to_dashboard: 'Return to dashboard',
  return_home: 'Return home',
  page_not_found: 'Page not found!',
  scan_qr: 'Scan QR',
  sign_into_your_account: 'Sign In to Your Account',
  sign_up: 'Sign Up',
  an_account: 'an account?',
  error: 'Error',
  please_enter_a_serial_number: 'Please enter a serial number',
  save_serialised_product: 'Save serialised product to kit',
  no_data_found: 'No data found',
  sign_out: 'Sign Out',
  update_user: 'Update user',
  profile: 'Profile',
  update: 'Update',
  create_api_key: 'Create API Key',
  reset_password: 'Reset password',
  serial_number: 'Serial number',
  cancel_scan: 'Cancel scan',
  sign_in: 'Sign In',
  no_results: 'No results',
  part_number: 'Part number',
  last_inspection: 'Last inspection',
  next_inspection: 'Next inspection',
  specifications: 'Specifications',
  specs: 'Specs',
  component_data: 'Component data',
  cancel: 'Cancel',
  no_components_found_for_this_search:
    'No components found for this search term. Please try again.',
  there_was_an_error: 'There was an error',
  add_to_kit: 'Add to inventory',
  sign_up_for_an_account: 'Sign Up for an Account',
  product_group: 'Product group',
  group_product_tip:
    'Select a product group that you think best describes the item you are creating. We use this product group to define a basic template of data to assign to your new product.',
  product_name_tip:
    'The name covering all variations of a product. It is recommended not to include specifics like size or colour in the name which are defined at the variant level. This is sometimes referred to as the style name.',
  single_item_cannot_be_dissembled:
    'A single item, which cannot be disassembled. We call this a component.',
  single_item_cannot_be_dissembled_tip:
    'If your product is a single item, typically with a unique serial number, and can not be divided into constitute serialied parts - choose component. Examples: Carabiner, Sling, Helmet',
  a_cut_length_of_rope:
    'Is this a cut length of rope with a splice or sewn termination',
  a_cut_length_of_rope_tip:
    'We have created this "product type" to reflect the realities of resellers of rope cutting & splicing lengths of rope from orginal spools/boxes from a manufacturer. Use this "product type" to keep all original spool data connected to each rope length.',
  product_consists_of_multiple:
    'A product that consists of multiple components, such as a lanyard. We call this an assembly',
  product_consists_of_multiple_tip:
    'For a product which is assembled from two or more serialised items such as a lanyard system or harness with replaceable rope bridges.',
  add_variation: 'Add variation',
  name_part_tip:
    ' Otherwise known as product code, or SKU code. Use your standard code convention to make up the number/code you want to use here.',
  sku_tip:
    ' Otherwise known as product code, or SKU code. Use your standard code convention to make up the number/code you want to use here.',
  barcode_tip:
    'You will need to create an account with your regional GS1 office to create your EAN/UPC/Barcodes. We are working on a direct integration to create GS1 barcodes here. Watch this space.',
  add_components: 'Add components, original rope or rope splice/term',
  below_components_will_be_added:
    'If your product comprises of 2 or more serialised items add them here. If you are creating a length of rope, with a splice or termination add them here.',
  product_code: 'Product code',
  serial_formats: 'Serial formats',
  include_batch_number: 'Include batch number',
  do_not_include_batch_number: 'Do not include Batch Number',

  update_product_category: 'Update Product Category',
  product_groups: 'Product Groups',
  product_groups_are_a_grouping:
    'Product groups are a grouping of products similar in design, specification or nature.',
  title: 'Title',
  prefix: 'Prefix',
  parts: 'Parts',
  history: 'History',
  composite_data: 'Composite Data',
  product_history: 'Product History',
  shorthand_dom: 'DOM',
  customer: 'Customer',
  sales_order: 'Sales order',
  purchase_order: 'Purchase order',
  tasks: 'Tasks',
  alerts: 'Alerts',
  clear: 'Clear',
  serial_batch: 'Serial / Batch',
  inspection_by_when: 'Inspection / by / when',
  product_variant: 'Product / Variant',
  next_due: 'Next due',
  save: 'Save',
  share: 'Share',
  update_information: 'Update Information',
  date_of_first_use: 'Date of first use',
  date_of_manufacture: 'Date of manufacture',
  batch_number: 'Batch number',
  remove_all_groups: 'Remove all Groups',
  are_you_sure_you_want_to_remove_all_groups:
    'Are you sure you want to remove all groups?',
  no_options: 'No options',
  teams: 'Teams',
  members: 'Members',
  managers: 'Managers',
  assigned_users: 'Assigned Users',
  team_inventory: 'Team Inventory',
  book_demo: 'Book a Demo',
  description: 'Description',
  go_back: 'Go Back',
  my_account: 'My Account',
  edit_profile: 'Edit Profile',
  view_items: 'View Items',
  share_app: 'Share App',
  app: 'App',
  close: 'Close',
  are_you_sure: 'Are you sure?',
};

const menu = {
  menu_dashboard: 'Overview',
  menu_users: 'Users',
  menu_team: 'Team',
  menu_updates: 'Scannable Updates',
  menu_knowledge_base: 'Knowledge Base',
  menu_manufacturers: 'Manufacturers',
  menu_product_categories: 'Product Categories',
  menu_product_data_categories: 'Product Data Fields',
  menu_products: 'Products/SKUs',
  menu_create_serials: 'Create Serials',
  create_assemblies: 'Create Assemblies',
  menu_inspections: 'Inspections',
  inspection_reports: 'Inspection Reports',
  menu_serial_reports: 'Serial Reports',
  menu_serial_format: 'Serial Formats',
  menu_kit_list: 'Kit List',
  menu_organisations: 'Organisations',
  menu_product_search: 'Product Search',
  menu_inventory: 'Inventory',
  menu_my_inventory: 'My Inventory',
  menu_kit_bags: 'Kit Bags',
  menu_locations: 'Locations',
  menu_vehicles: 'Vehicles',
  menu_staff: 'Staff',
  menu_equipment_type: 'Equipment Type',
  menu_clients: 'Clients',
  tooltip_scannable_updates:
    "Keep up to date with what's new in Scannable. Here’s a summary of what’s changed.",
  tooltip_knowledge_base: 'How-to guides and FAQs',
  menu_buy_nfc_tags: 'Buy NFC Tags',
};

const dashboard = {
  title: 'Overview',
  subtitle: 'Overview',
  manufacturers: 'Manufacturers',
  user_signups: 'User Signups (last 7 days)',
  inspections: 'Inspections',
  inspections_week: 'Inspections (last 7 days)',
  product_variations: 'Product Variations (verified)',
  serialised_products: 'Serialised Products',
  claimed_products: 'Claimed Products',
  items_inspected: 'Items Inspected',
  items_overdue: 'Items Overdue',
  items_due_30: 'Items Due in 30 Days',
  items_due_60: 'Items Due in 60 Days',
};

const user = {
  create_label: 'Create user',
  activated_label: 'Activated',
};

const team = {
  title: 'Team',
  invite_team_members: 'Invite Team Members',
  add_emails: 'Add team email addresses',
  create_team: 'Create Team',
  invite_members: 'Invite Members',
  send_invites: 'Send Invites',
  assign_printers: 'Assign Printers',
  assign_serial_formats: 'Assign Serial Formats',
};

const frequencies = {
  create_frequency: 'Create Inspection Frequency',
  update_frequency: 'Update Inspection Frequency',
  delete_frequency: 'Delete Inspection Frequency',
  frequencies: 'Inspection Frequencies',
  start_date: 'Start Date',
};

const manufacturers = {
  manufacturers_table_website: 'Website',
  manufacturers_table_units: 'Units',
  create_manfacturer_btn: 'Create manufacturer',
  create_manufacturer_title: 'Create manufacturer',
  manufacturers_website: 'Website',
  manufacturers_measurements: 'Measurement units',
  manufacturers_measurement_metric: 'Product measurement units are metric',
  manufacturers_measurement_imperial: 'Product measurement units are imperial',
  edit_manufacturer_name: 'Manufacturer name',
  edit_manufacturer_logo: 'Logo',
  edit_manufacturer_button: 'Update Manufacturer Details',
};

const organisations = {
  edit_organisations_button: 'Update Account Details',
  edit_organisation_name: 'Account Name',
};

const product_categories = {
  title: 'Product Categories',
  subtitle: 'Categorise groupings of products by industry or application',
  table_hidden: 'Hidden',
  is_hidden: 'Yes/No',
  create_label: 'Create new product category',
};

const product_data_fields = {
  title: 'Product Data Fields',
  subtitle:
    'Product data categories are a group of data fields of a similar specification or nature',
  data_fields: 'Data fields',
};

const products = {
  create_product_btn: 'Create product',
  create_product_title: 'Create product',
  move_products_btn: 'Move products',
  products_group: 'Product group',
  products_name: 'Product name',
  comp_product: 'Composite product',
  products_comp_product_option1:
    'A single item, which cannot be disassembled. We call this a component.',
  products_comp_product_option2:
    'A cut length of rope with a splice or sewn termination',
  products_comp_product_option3:
    'A product that consists of multiple components, such as a lanyard. We call this an assembly.',
  products_part_num: 'Part number',
  products_sku_name: 'SKU name',
  products_ean_upc_barcode: 'EAN/UPC/Barcode',
  claimproduct_error_msg: 'You must be logged in to claim a kit item',
  claimproduct_success_msg: 'Product added to inventory',
  claimproduct_failure_msg: 'Sorry, something went wrong',
  item_is_in_kitlist: 'Item is in your inventory',
  add_to_kitlist: 'Add to Inventory',
  login_to_add_to_kitlist: 'Log in to add to inventory',
  certified: 'Certified',
};

const create_serials = {
  serial_creation_wizard_title: 'Serial Creation Wizard',
  create_component_serials: 'Create Component Serials',
  create_rope_length_serials: 'Create Rope Length Serials',
  created_serials_title: 'Created Serials',
  create_serials_table_serial_format: 'Serial Format',
  create_serials_table_date_created: 'Date Created',
  create_serials_table_reference_number: 'Reference Number',
  create_serials_table_download: 'Download',
  create_serialised_title: 'Create serialised products',
  create_serials_ref_num_serial_format: 'Reference Numbers and Serial Format',
  create_serials_select_serial_format: 'Select a serial format to use',
  create_serials_petzl_format: 'Petzl Format',
  create_serials_dom: 'DOM',
  create_serials_batch: 'Batch',
  create_serials_customer: 'Customer',
  create_serials_sales_order: 'Sales order',
  create_serials_purchase_order: 'Purchase order',
  create_serials_increments: 'Increments',
  create_serials_suffix: 'Suffix',
  create_serials_p_o_num: 'Purchase order number',
  create_serials_enter_ref_num: 'Enter a reference number',
  create_serials_sales_order_num: 'Sales order number',
  create_serials_enter_sales_order_num: 'Customer reference',
  create_serials_customer_ref: 'Enter a sales order number',
  create_serials_batch_num: 'Batch number',
  create_serials_enter_batch_num: 'Enter a batch number',
  create_serials_select_date_manu:
    'Select a date of manufature to user as part of serial creation ',
  create_serials_date_selected: 'Date selected:',
  create_serials_preferences_btn:
    'Save reference and serial format preferences',
  create_serialised_products: 'Create serialised products',
  reference_numbers_serial_format: 'Reference numbers and serial format',
  enter_ref_number: 'Enter a reference number',
  sales_order_number: 'Sales order number',
  enter_sales_order_number: 'Enter a sales order number',
  customer_reference: 'Customer reference',
  enter_batch_number: 'Enter a batch number',
  save_reference_serial_format_btn:
    'Save reference and serial format preferences',
  create_serials_for_cutrope: 'Create serials for cut rope',
  rope_details: 'Rope details',
  select_rope_cutting_from:
    'Select the rope you are cutting from and confirm details',
  choose_rope: 'Choose rope',
  selected_rope: 'Selected rope',
  rope_for_serialisation: 'Ropes for serialisation',
  tooltip_create_serials:
    'Use this tool to create serials in bulk for one or more components. Useful if you need to create serials for purchase orders going to a supplier or you need multiple serials for a batch of items in production',
  tooltip_splice_rope:
    'Use this tool to create serials for cut, sewn or spliced lengths of rope.',
  tooltip_create_serial_menu:
    'Use this tool to create serials against products you have created or newly cut, spliced or sewn lengths of rope.',
};

const assemblies = {
  assemblies_table_assembly_name: 'Assembly name',
  assemblies_table_sales_order_number: 'Sales order number',
  assemblies_table_purchase_order_number: 'Purchase order number',
  assemblies_table_batch_number: 'Batch number',
  assemblies_table_date_manufacturer: 'Date of manufacturer',
  assemblies_create_assemblie_btn: 'Create new assembly',
  assemblies_create_assemblies: 'Create Assemblies',
  assemblies_choose_assembly: 'Choose an Assembly',
  assemblies_select_assembly_building:
    "Search and select  the assembly you'd like to build.",
  selected_assembly: 'Selected assembly',
  create_assembly: '1. Create assembly',
  create_assembly_adding_serials:
    'Create an assembly by adding serials to the corresponding components',
  create_another_assembly: 'Create another assembly',
  create_an_assembly: 'Create an assembly',
  product_data: 'Product data',
  tooltip_create_assemblies:
    'Use this tool to group serial components into assemblies',
};

const inspections = {
  title: 'Inspections',
  inspections_search_product_title: 'Search for an Item',
  inspections_search_product_subtitle:
    'Find the item in your inventory you want to inspect',
  inspections_table_serial: 'Serial',
  confirm_fail_inspection_title: 'Fail Inspection',
  confirm_fail_inspection_description:
    'Are you sure you want to mark this inspection as failed?',
  confirm_fail_inspection_button: 'Yes, Complete Inspection',
};

const inspection_reports = {
  inspection_reports_climber_sup_admin: 'Climber: Super admin',
  inspection_comments: 'Inspection comments',
  image_provided: 'Image provided',
  inspection_reports_officials_initials: 'Officials initials:',
};

const serialisation = {
  title: 'Serialisation',
  table_created: 'Created',
  table_sales_order_num: 'Sales order #',
  table_po_num: 'Purchase order #',
  table_batch: 'Batch #',
  table_date_manufacturer: 'DOM',
  table_download: 'Download',
  filter_customer_label: 'Filter by Customer',
  filter_customer_placeholder: 'Select customers',
  filter_sku_label: 'Filter by SKU',
  filter_sku_placeholder: 'Search by part number (SKU code)',
  filter_category_label: 'Filter by Category',
  filter_category_placeholder: 'Select categories',
  select_printer: 'Select Printer',
};

const serial_format_ = {
  serial_format_define_format_serial1:
    'This is where you define your preferred format of serial number.',
  serial_format_define_format_serial2:
    'As a GS1 partner, we will build your serial into various GS1 serial number formats, for different applications.',
  serial_format_define_format_serial3:
    'We construct serial numbers using the following definable components. Not all serial components are required. Just define the ones you want to use.',
  serial_format_subtitle: 'Title',
  serial_format_title_req: 'Title is required',
  serial_format_prefix: 'Prefix',
  serial_format_prefix_option_serial: 'Prefix is an optional Serial component',
  serial_format_serial_have_prefix: 'Does your serial number have a Prefix?',
  serial_format_yes_option: 'Yes, I want a prefix for this Serial format.',
  serial_format_no_option: 'No, Serial format does not have a Prefix.',
  serial_format_date_of_manu_optional:
    'Date of Manufacture. Date of Manufacture is an optional Serial component',
  serial_format_sf_batch_num1:
    'We typically advocate NOT to include the batch number within the serial number, and to instead include GS1 SGTIN to add the serial info.',
  serial_format_sf_batch_num2:
    'But for manufacturers who want to, the option remains to include a batch number within their serial number.',
  serial_format_sf_batch_num3:
    ' By selecting ‘Include batch number’, this will take the batch number entered on creation of serial numbers, and include it in the serial numbers.',
  serial_format_cust_ref: 'Customer Reference',
  serial_format_dont_incl_cus_ref: 'Do not include customer reference',
  serial_format_sales_order: 'Sales Order Number',
  serial_format_incl_order: 'Include sales order number',
  serial_format_dont_incl_order: 'Do not include sales order number',
  serial_format_p_o: 'Purchase Order Number',
  serial_format_incl_purchase_order: 'Include purchase order number',
  serial_format_dont_incl_purchase_order:
    'Do not include purchase order number',
  serial_format_incl_cus_ref: 'Include customer reference',
  serial_format_increments:
    'Increments is a required optional serial component.',
  serial_format_increments2:
    'Increments allow for production quantity, with a batch, day, month. We typically suggest 3-5 characters, with ‘padded’ numbers, i.e 0001,0002,....9999',
  serial_format_padding: 'Apply padding?',
  serial_format_pad_serial_num: 'Yes, pad the serial numbers',
  serial_format_dont_pad_srial_num:
    'No, do not apply padding to the serial numbers',
  serial_format_suffix: 'Suffix. Suffix is an optional serial component',
  serial_format_does_serial_num_have_suffix:
    'Does your serial number have a suffix?',
  serial_format_yes_suffix_for_serial_format:
    'Yes, I want a suffix for this serial format.',
  serial_format_no_suffix_for_serial_format:
    'No, Serial format does not have a suffix.',
  serial_format_your_serial_num_format: 'Your serial number format',
  serial_format_create_serial_format: 'Create serial format',
  number_of_increment_characters: 'Number of increment characters',
};

const serial_format = {
  title: 'Serial Format',
  suffix_label: 'Suffix',
  title_label: 'Name',
  prefix_label: 'Prefix',
  date_format_label: 'Date format',
  batch_label: 'Batch',
  increments_label: 'Increments',
  descriptive_title: 'Enter a descriptive name for this serial format',

  dom: 'Date of manufacture',
  dom_yes: 'Yes, I want a Date of Manufacture for this Serial format.',
  dom_no: 'No, Serial format does not have a date of manufacture.',

  country_of_origin: 'Country of Origin',
  country_of_origin_label: 'Enter Country of Origin',
  country_of_origin_placeholder: 'Enter a country code e.g. USA',
  country_of_origin_sub_title:
    'Enter a country code to be use in serialisation e.g. USA',
  country_of_origin_yes:
    'Yes, I want a country of origin for this serial format.',
  country_of_origin_no:
    'No, the serial format does not have a country of origin.',
};

const kit_list = {
  kit_list_table_category: 'Category',
  kit_list_table_items: 'Items',
};

const product_search = {
  product_search_search: 'Search',
};

const settings = {
  settings_logo: 'Logo',
};

const actions = {
  assign_to_sku: 'Assign to SKU',
  assign_to_a_team_member: 'Assign to a team member',
  assign_to_a_kit_bag: 'Assign to a kit bag',
  assign_to_a_location: 'Assign to a location',
  assign_to_a_client: 'Assign to a client',
  assign_to_a_vehicle: 'Assign to a vehicle',
  assign_to_staff: 'Assign to staff',
  assign_to_an_equipment_type: 'Assign to an equipment type',
  set_inspection_frequency: 'Set inspection frequency',
  remove_item_from_inventory: 'Remove from inventory',
  assign_to_group: 'Assign to group',
  assign_update_groups: 'Assign to Groups / Team',
  bulk_remove_from_inventory: 'Remove items from inventory',
  bulk_remove_from_group: 'Remove items from group',
};

const groups = {
  groups: 'Groups',
  kit_bags: 'Kit Bags',
  add_kit_bag: 'Add Kit Bag',
  new_kit_bag: 'New Kit Bag',
  locations: 'Locations',
  add_location: 'Add Location',
  new_location: 'New Location',
  vehicles: 'Vehicles',
  add_vehicle: 'Add Vehicle',
  new_vehicle: 'New Vehicle',
  clients: 'Clients',
  add_client: 'Add Client',
  new_client: 'New Client',
  staff: 'Staff',
  add_staff: 'Add Staff',
  new_staff: 'New Staff',
  equipment_type: 'Equipment Type',
  add_equipment_type: 'Add Equipment Type',
  new_equipment_type: 'New Equipment Type',
  kit_bag: 'Kit Bag',
  location: 'Location',
  vehicle: 'Vehicle',
  client: 'Client',
  select_a_group: 'Select a Group',
};

const checklists = {
  create_checklist: 'Create Checklist',
  update_checklist: 'Update Checklist',
  delete_checklist: 'Delete Checklist',
  checklists: 'Checklists',
};

const serialised_products = {
  dom: 'Date of Manufacture',
  dofu: 'Date of First Use',
  expiry_date: 'Expiry date',
  external_id: 'Alternative ID',
};

const printing = {
  preview_label: 'Copy url for preview',
  print_one_label: 'Print one (first) label',
  print_all_label: 'Print all labels',
  success_message: 'Sent to printer successfully',
  print_one_serial: 'Print one serial',
  print_label: 'Print',
  clear_queue: 'Clear Printer Queue',
  print_with_label_live: 'Print with Label Live',
  fail_to_connect_to_printer:
    'Failed to connect to printer. Ensure you enable HTTP in Label Live integration settings',
};

const auth = {
  forgot_password: 'Forgot Password',
  send_reset_password_email: 'Send a password reset email',
  password_reset_description:
    'Fill in your email below and we will send you a recovery link to reset you password.',
  change_password: 'Change Password',
  switch_account: 'Switch Account',
};

const onboarding = {
  skip: 'Skip',
};

const bottom_tabs = {
  home: 'Home',
  inventory: 'Inventory',
  groups: 'Groups',
  me: 'Me',
};

const nfc = {
  remove_tags: 'Remove NFC Tags',
  confirm_remove_tags_from_item:
    'Are you sure you want to remove all NFC tags from this item?',
  add_tag: 'Add NFC',
  one_nfc_added: 'NFC Added',
  multiple_nfcs_added: '# NFC Tags',
  tags_removed_successfully: 'NFC Tags Removed',
  tags_removed_failed: 'Failed to Remove NFC Tags',
  scan_dialogue_instructions: `Hold the NFC Tag against your phone's NFC Sweet Spot.`,
  ready_to_scan: 'Ready to scan',
};

const multiScanNFCPrompt = `Hold the NFC Tag against your phone's NFC Sweet Spot. Give it a few moments.

If the tag doesn't read, pull the phone back and try again.`;

const multi_scan = {
  title: 'Multi-scan',
  nfc_prompt_message: multiScanNFCPrompt,
  nfc_session_will_end_soon: 'NFC session will end soon',
  nfc_timeout_title: 'NFC Timeout Reached',
  nfc_timeout_description: `The NFC scanner times out after 60 seconds. Tap "Scan NFC" to continue scanning.`,
  nfc_ios_unexpected_error_description: `This is a known iOS error. Tap "Scan NFC" to continue scanning.`,
  nfc_unexpected_error_description: `Something went wrong. Please try again.`,
  nfc_unexpected_error_title: `NFC Session failed unexpectedly.`,
};

const uploads = {
  max_uploads_for_inspections:
    'You can upload a maximum of # files in total per inspection.',
  max_uploads_reached:
    'You have reached the maximum number of uploads for this action.',
};

const tables = {
  apply_filters: 'Apply filters',
  filters_label: 'Filters',
};

const notifications = {
  notification: 'Notification',
  notifications: 'Notifications',
  inspection_due: 'Inspection due',
  expiry_due: 'Expiry due',
  inspection_due_next_30_days: 'Inspection due in the next 30 days',
  expiry_due_next_30_days: 'Expiry due in the next 30 days',
  sent_weekly: 'Sent weekly',
  notification_settings: 'Notification Settings',
  notification_preferences_saved: 'Notification preferences saved',
  onboarding_settings_description:
    'Choose which notifications you want to receive. Change your preferences in your profile.',
};

export const en = {
  ...general,
  ...menu,
  ...manufacturers,
  ...organisations,
  ...products,
  ...create_serials,
  ...assemblies,
  ...inspection_reports,
  ...serial_format_,
  ...kit_list,
  ...product_search,
  ...settings,
  ...groups,
  ...frequencies,
  ...checklists,
  ...serialised_products,
  actions,
  inspections,
  team,
  serial_format,
  printing,
  user,
  product_categories,
  product_data_fields,
  dashboard,
  auth,
  onboarding,
  bottom_tabs,
  nfc,
  multi_scan,
  uploads,
  serialisation,
  tables,
  notifications,
};
