import { FormikProps } from 'formik';

import { classNames } from '@scannable/common';

export interface TextareaProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: HTMLInputElement['name'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
}

export function Textarea({ name, formik, className, ...props }: TextareaProps) {
  const onChange = (event: React.FormEvent<HTMLTextAreaElement>): void => {
    const canSetValue = event.currentTarget.value.length < 1100;
    if (canSetValue) {
      formik.setFieldValue('value', event.currentTarget.value);
    }
  };

  return (
    <textarea
      name={name}
      value={formik.values.value}
      onChange={(e) => onChange(e)}
      rows={5}
      cols={60}
      className={classNames(
        className
          ? className
          : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded'
      )}
      {...props}
    />
  );
}

export default Textarea;
