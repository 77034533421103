import { ComponentSerialValuesWithProductVariation } from '@scannable/frontend/types';

import { Button, Text } from '../../atoms';
import {
  DescriptionList,
  DescriptionListItem,
} from '../../molecules/DescriptionList/DescriptionList';

export interface SerialsToCreateDescriptionListProps {
  submitProductsForSerialisation: () => void;
  productVariationsToCreate: ComponentSerialValuesWithProductVariation[];
}

export function SerialsToCreateDescriptionList({
  submitProductsForSerialisation,
  productVariationsToCreate,
}: SerialsToCreateDescriptionListProps) {
  return (
    <DescriptionList
      title="Products to create"
      subTitle="Here is your list of products to create"
    >
      <DescriptionListItem>
        {productVariationsToCreate.map((p, i) => (
          <div key={`${p.productVariation}_${i}`} className="mb-5">
            <div className="flex flex-col">
              <div>
                <div className="flex justify-between text-base font-medium text-gray-900">
                  <h3>{p.productVariation.info?.name}</h3>
                  <p className="ml-4">{p.productVariation.info?.code}</p>
                </div>
                <Text>Serial quantity: {p.serialQuantity}</Text>
                {p.productBatchNumber && (
                  <Text>Batch number: {p.productBatchNumber}</Text>
                )}
                {p.productDom && (
                  <Text>
                    <>DOM: {p.productDom}</>
                  </Text>
                )}
                {p.componentBatchNumbers && (
                  <div>
                    <Text>Component batch numbers:</Text>
                    <ul className="text-sm text-gray-500 ml-5">
                      {p.componentBatchNumbers.map((c) => (
                        <li
                          key={`${c.productVariationId}_${c.batchNumber}`}
                          className="list-disc"
                        >
                          {c.batchNumber}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        <Button type="button" onClick={() => submitProductsForSerialisation()}>
          Create serials
        </Button>
      </DescriptionListItem>
    </DescriptionList>
  );
}

export default SerialsToCreateDescriptionList;
