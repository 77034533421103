import { Notification, NotificationPreference } from '@scannable/common';

import { graphql } from '../graphql';

export const GET_ORG_NOTIFICATION_PREFERENCES =
  graphql(`query ${NotificationPreference.GetOrgNotificationPreferences} {
    orgNotificationPreferences {
      id
      type
      managers
      members
      assignedUsers
    }
  }
`);
export const GET_USER_NOTIFICATION_PREFERENCES =
  graphql(`query ${NotificationPreference.GetUserNotificationPreferences} {
    preferences: userNotificationPreferences {
      id
      type
      status
    }
  }
`);

export const GET_PAGINATED_NOTIFICATIONS =
  graphql(`query ${Notification.PaginatedNotifications}(
    $take: Int 
    $skip: Int
    ) {
  paginatedNotifications(
    where: {
    }
      take: $take
      skip: $skip
  ) {
    nodes {
      id
      type
      payload
      createdAt
      read
    }
    totalCount
  }
}
`);

export const GET_NOTIFICATION_COUNT =
  graphql(`query ${Notification.GetNotificationCount} {
  notificationCount
}
`);

export const NOTIFICATIONS_SUBSCRIPTION =
  graphql(`subscription ${Notification.NotificationSubscription} {
  notificationCreated {
    id
  }
}
`);
