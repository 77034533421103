import { SerialFormatOption } from '@scannable/common';

import {
  DescriptionListItem,
  RadioGroupTable,
  SerialFormatDisplay,
} from '../../molecules';

export interface SerialFormatDescriptionListProps {
  availableSerialFormats: SerialFormatOption[];
  onChange: (selectedValue: number | null) => void;
  value?: number | null;
  error?: string;
  touched?: boolean;
}

export function SerialFormatDescriptionListItem({
  availableSerialFormats,
  onChange,
  ...props
}: SerialFormatDescriptionListProps) {
  return (
    <DescriptionListItem title="Select a serial format to use">
      <RadioGroupTable<number | null>
        label="Select a serial format"
        name="serialFormatId"
        onChange={onChange}
        options={availableSerialFormats.map((serialFormat) => ({
          label: <SerialFormatDisplay format={serialFormat} />,
          value: serialFormat.id,
        }))}
        {...props}
      />
    </DescriptionListItem>
  );
}

export default SerialFormatDescriptionListItem;
