import React, { useCallback, useContext } from 'react';

type ModalType = JSX.Element | React.ReactNode | null | undefined;
type ModalOptionsType = {
  type: null | string;
  showCloseIcon: boolean;
  closeIconColor: null | string;
  disableEscExit: boolean;
};
type HandleModalType = (m: ModalType, o?: ModalOptionsType) => void;

export interface ModalContextState {
  modal: boolean;
  handleModal: HandleModalType;
  modalContent: ModalType;
  modalOptions: ModalOptionsType;
  isModalOpen: boolean;
}

const initialState = {
  modal: false,
  handleModal: (m: ModalType, o?: ModalOptionsType) => ({}),
  modalContent: null,
  modalOptions: {
    type: null,
    showCloseIcon: true,
    closeIconColor: null,
    disableEscExit: false,
  },
  isModalOpen: false,
};

const ModalContext = React.createContext<ModalContextState>(initialState);
const { Provider } = ModalContext;

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modal, setModal] = React.useState(false);

  const [modalContent, setModalContent] = React.useState<ModalType>(null);
  const [modalOptions, setModalOptions] = React.useState<ModalOptionsType>(
    initialState.modalOptions
  );

  // todo this should be done with useReducer
  const handleModal = useCallback(
    (content = null as ModalType, options = {} as ModalOptionsType) => {
      setModal(Boolean(content));
      setModalContent(content);
      if (options) {
        setModalOptions({ ...modalOptions, ...options });
      }
    },
    [modalOptions]
  );

  return (
    <Provider
      value={{
        modal,
        handleModal,
        modalContent,
        modalOptions,
        isModalOpen: modal,
      }}
    >
      {children}
    </Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);

  const hideModal = useCallback(
    () => context.handleModal(null, initialState.modalOptions),
    [context]
  );
  const showModal = useCallback(
    (m: ModalType, t?: ModalOptionsType) => context.handleModal(m, t),
    [context]
  );

  const isModalOpen = !!context.modal;
  return {
    ...context,
    hideModal,
    showModal,
    isModalOpen,
  };
};

function ModalPortal() {
  const { modalContent } = useModal();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{modalContent as JSX.Element}</>;
}

export { ModalContext, ModalProvider, initialState, useModal, ModalPortal };
