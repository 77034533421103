import React from 'react';

import { cn } from '@scannable/common';

import { Title } from '../../atoms';

export interface AccountSettingProps {
  title: string;
  children: React.ReactNode;
  isFirst?: boolean;
}

export function AccountSetting({
  title,
  children,
  isFirst = false,
}: AccountSettingProps) {
  return (
    <div
      className={cn(
        isFirst
          ? 'grid grid-cols-1 gap-x-8 gap-y-10 pb-6 md:grid-cols-3'
          : 'grid grid-cols-1 gap-x-8 gap-y-10 pb-6 md:grid-cols-3 pt-6 border-t border-gray-900/10 '
      )}
    >
      <div>
        <Title size="h3">{title}</Title>
        <p className="mt-1 text-sm leading-6 text-gray-600"></p>
      </div>
      <div className="col-span-2">{children}</div>
    </div>
  );
}

export default AccountSetting;
