export enum AssignToGroupAction {
  singleItem = 'singleItem',
  listOfItems = 'listOfItems',
}
export type AssignToGroupActionType = keyof typeof AssignToGroupAction;

export enum InventoryAction {
  assignToTeamMember = 'assignToTeamMember',
  addToInventory = 'addToInventory',
  assignItemToUser = 'assignItemToUser',
  removeItemFromInventory = 'removeItemFromInventory',
  addUpdateGroups = 'addUpdateGroups',
}
export type InventoryActionType = keyof typeof InventoryAction;

export enum AssignToTeamMemberAction {
  singleItem = 'singleItem',
  listOfItems = 'listOfItems',
  group = 'group',
}
export type AssignToTeamMemberActionType =
  keyof typeof AssignToTeamMemberAction;

export enum Tasks {
  assignToSku = 'assignToSku',
  assignToTeamMember = 'assignToTeamMember',
  assignToKitBag = 'assignToKitBag',
  assignToClient = 'assignToClient',
  assignToLocation = 'assignToLocation',
  assignToVehicle = 'assignToVehicle',
  assignToStaff = 'assignToStaff',
  assignToEquipmentType = 'assignToEquipmentType',
  assignToGroup = 'assignToGroup',
  addAlert = 'addAlert',
  inspect = 'inspect',
  bulkRemoveFromInventory = 'bulkRemoveFromInventory',
  bulkRemoveFromGroup = 'bulkRemoveFromGroup',
}
export const TaskDefinition = {
  [Tasks.assignToTeamMember]: {
    actions: AssignToTeamMemberAction,
  },
  [Tasks.assignToKitBag]: {
    actions: AssignToGroupAction,
  },
  [Tasks.assignToClient]: {
    actions: AssignToGroupAction,
  },
  [Tasks.assignToLocation]: {
    actions: AssignToGroupAction,
  },
  [Tasks.assignToVehicle]: {
    actions: AssignToGroupAction,
  },
  [Tasks.assignToStaff]: {
    actions: AssignToGroupAction,
  },
  [Tasks.assignToEquipmentType]: {
    actions: AssignToGroupAction,
  },
};
