import { useQuery } from '@apollo/client';

import { ProductCategoryData } from '@scannable/frontend/common';
import {
  ALL_METADATA_CATEGORIES,
  PRODUCT_CATEGORY_DATA,
} from '@scannable/graphql-queries';

import { Divider, LoadingIcon, Title } from '../../atoms';
import { SortableMetadataForm } from '../../forms';
import { SidePanelLayout } from '../../layouts';
import { AssignMetadataModal, useModal } from '../../modals';
import { InputFieldGroup } from '../../molecules';

type ProductCategorySidePanelProps = {
  productCategoryId: number;
};
export function ProductCategorySidePanel({
  productCategoryId,
}: ProductCategorySidePanelProps) {
  const { showModal } = useModal();
  const { data, loading } = useQuery(PRODUCT_CATEGORY_DATA, {
    variables: {
      id: productCategoryId,
    },
    skip: !productCategoryId,
  });
  const { data: metadata } = useQuery(ALL_METADATA_CATEGORIES);

  const productGroupMetadata: ProductCategoryData[] =
    data?.productGroup?.productGroupMetadata || [];
  const orderedMetadata = [...productGroupMetadata].sort(
    (a, b) => a.order - b.order
  );

  const options = (metadata?.metadataCategories || [])
    .map((mC) => {
      const { metadata } = mC;
      return [...(metadata || [])].filter(
        (fm) =>
          !productGroupMetadata.find(
            (existingPm) => existingPm.metadata?.id === fm.id
          )
      );
    })
    .flat()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <SidePanelLayout>
      <Title size="h4" className="mb-5">
        Add Data Fields
      </Title>

      <div className="grid grid-cols-1 gap-4 gap-y-4">
        <InputFieldGroup
          id="metadata"
          type="select"
          name="metadata"
          label="Select data field to add"
          options={options.map((m) => ({
            label: m.name,
            value: m.id,
          }))}
          onChange={(m) => {
            showModal(
              <AssignMetadataModal
                productCategoryId={productCategoryId}
                metadataId={m.value}
                metadataName={m.label}
              />
            );
          }}
        />
      </div>

      <Divider className="my-5" />
      <Title size="h4" className="mb-5">
        Sort Existing Data Fields
      </Title>
      {loading && <LoadingIcon size="sm" />}
      {!loading && orderedMetadata.length > 0 && (
        <div>
          <SortableMetadataForm
            orderedMetadata={orderedMetadata}
            productGroupId={productCategoryId}
          />
        </div>
      )}
    </SidePanelLayout>
  );
}
