import * as yup from 'yup';

import {
  SerialFormatComponentParts,
  SerialFormatFormValues,
  WrappedForm,
} from '@scannable/frontend/common';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  prefix: yup.object().when('hasPrefix', {
    is: true,
    then: yup.object({
      value: yup
        .string()
        .nullable()
        .required("A prefix is required if you have selected you'd like one"),
    }),
    otherwise: yup.object({
      value: yup.string().nullable(),
    }),
  }),
  dom: yup.object().when('hasDom', {
    is: true,
    then: yup.object({
      value: yup
        .string()
        .nullable()
        .required(
          "A date of manufacturer is required if you have selected you'd like one"
        ),
    }),
    otherwise: yup.object({
      value: yup.string().nullable(),
    }),
  }),
  increments: yup.object().when('hasIncrements', {
    is: true,
    then: yup.object({
      value: yup
        .number()
        .positive('Increments must be a positive number and greater than 3')
        .required('Padding increments is required'),
    }),
  }),
  suffix: yup.object().when('hasSuffix', {
    is: true,
    then: yup.object({
      value: yup
        .string()
        .nullable()
        .required("A suffix is required if you have selected you'd like one"),
    }),
    otherwise: yup.object({
      value: yup.string().nullable(),
    }),
  }),
});

export type SerialFormatValues = {
  name: string;
} & SerialFormatComponentParts &
  SerialFormatFormValues;

export type SerialFormatFormProps = Partial<SerialFormatValues> & {
  id?: number;
} & Partial<WrappedForm>;

export type EditSerialFormatFormProps = {
  id: number;
} & Partial<SerialFormatFormProps>;
