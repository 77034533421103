import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { HardwareType, Printing } from '@scannable/common';
import { UPDATE_HARDWARE } from '@scannable/graphql-queries';

import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import Modal from '../../modals/Modal/Modal';
import { useModal } from '../../modals/ModalContext/ModalContext';
import { InputFieldGroup } from '../../molecules';
import ModalForm from '../ModalForm/ModalForm';

export interface EditHardwarePrinterFormProps {
  item: {
    publicId: string;
    hardwareId: string;
    name: string;
    type: 'PRINTER';
  };
}

type EditHardwarePrinterValues = {
  hardwareId: string;
  name: string;
  type: HardwareType;
};

export function EditHardwarePrinterForm({
  item,
}: EditHardwarePrinterFormProps) {
  const [updateHardware] = useMutation(UPDATE_HARDWARE, {
    refetchQueries: [Printing.GetHardwareByType],
  });
  const { hideModal } = useModal();
  const { t } = useTranslation();
  const validationSchema = {
    hardwareId: yup.string().required('Printer ID is required'),
    name: yup.string().required('Name is required'),
  };
  const formik = useFormik({
    initialValues: {
      hardwareId: item.hardwareId,
      name: item.name,
      type: HardwareType.PRINTER,
    },
    validationSchema: yup.object(validationSchema),
    onSubmit: async (values: EditHardwarePrinterValues, { setSubmitting }) => {
      const result = await resolveMutation(
        updateHardware({
          variables: {
            data: {
              publicId: item.publicId,
              hardwareId: values.hardwareId,
              name: values.name,
              type: HardwareType.PRINTER,
            },
          },
        }),
        {
          successMessage: 'Printer added',
        }
      );
      if (result.ok) {
        setSubmitting(false);
        hideModal();
      }
    },
  });
  return (
    <Modal title="Edit Printer">
      <ModalForm
        formik={formik}
        buttons={[
          {
            type: 'submit',
            label: t('save'),
            disabled: !formik.isValid,
          },
        ]}
      >
        <InputFieldGroup formik={formik} name="name" label="Name" type="text" />
        <InputFieldGroup
          formik={formik}
          name="hardwareId"
          label="Printer ID"
          type="text"
        />
      </ModalForm>
    </Modal>
  );
}
export default EditHardwarePrinterForm;
