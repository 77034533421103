import { useQuery } from '@apollo/client';

import { INVENTORY_PRODUCT_GROUPS } from '@scannable/graphql-queries';

import { TableFilterRenderProps } from '../../types/table.types';
import { FieldFilter } from '../FieldFilter/FieldFilter';

const filter = {
  OR: [
    {
      productGroupId: {
        in: '{VAL}',
      },
    },
    {
      productVariation: {
        is: {
          product: {
            is: {
              productGroup: {
                is: {
                  id: {
                    in: '{VAL}',
                  },
                },
              },
            },
          },
        },
      },
    },
  ],
};

export function InspectionProductCategoryFilter(props: TableFilterRenderProps) {
  const { data } = useQuery(INVENTORY_PRODUCT_GROUPS);

  return (
    <FieldFilter<number>
      {...props}
      interpolatedFilter={filter}
      placeholder="Select product categories"
      className="sm:max-w-xs"
      options={
        data?.getInventoryProductGroups
          ? data.getInventoryProductGroups.map((group) => ({
              label: group.name,
              value: group.id,
            }))
          : []
      }
    />
  );
}
