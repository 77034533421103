export enum ItemEvent {
  scanned = 'item_scanned',
  domAdded = 'item_domAdded',
  inspected = 'item_inspected',
  addedToInventory = 'item_addedToInventory',
  removedFromInventory = 'item_removedFromInventory',
  assignedToUser = 'item_assignedToUser',
  unassignedFromUser = 'item_unassignedFromUser',
  addedToGroup = 'item_addedToGroup',
  removedFromGroup = 'item_removedFromGroup',
  updated = 'item_updated',
  created = 'item_created',
}

// not sure whe have to have this enum, as the grapqhl uses non item_ prefix for the events
export const enum GraphQLItemEvent {
  addedToGroup = 'addedToGroup',
  addedToInventory = 'addedToInventory',
  assignedToUser = 'assignedToUser',
  created = 'created',
  domAdded = 'domAdded',
  inspected = 'inspected',
  removedFromGroup = 'removedFromGroup',
  removedFromInventory = 'removedFromInventory',
  scanned = 'scanned',
  unassignedFromUser = 'unassignedFromUser',
  updated = 'updated',
}

export enum CheckOffItemResult {
  PRESENT = 'PRESENT',
  MISSING = 'MISSING',
}

export enum ItemEventLabel {
  scanned = 'Scanned',
  domAdded = 'Date of Manufacture Added',
  inspected = 'Inspection',
  addedToInventory = 'Added to Inventory',
  removedFromInventory = 'Removed from Inventory',
  assignedToUser = 'Assigned',
  unassignedFromUser = 'Unassigned',
  addedToGroup = 'Added to Group',
  removedFromGroup = 'Removed from Group',
  updated = 'Updated',
  created = 'Created',
}

export const getItemEventLabel = (event: ItemEvent): ItemEventLabel => {
  const eventLabelMapping: Record<ItemEvent, ItemEventLabel> = {
    [ItemEvent.scanned]: ItemEventLabel.scanned,
    [ItemEvent.domAdded]: ItemEventLabel.domAdded,
    [ItemEvent.inspected]: ItemEventLabel.inspected,
    [ItemEvent.addedToInventory]: ItemEventLabel.addedToInventory,
    [ItemEvent.removedFromInventory]: ItemEventLabel.removedFromInventory,
    [ItemEvent.assignedToUser]: ItemEventLabel.assignedToUser,
    [ItemEvent.unassignedFromUser]: ItemEventLabel.unassignedFromUser,
    [ItemEvent.addedToGroup]: ItemEventLabel.addedToGroup,
    [ItemEvent.removedFromGroup]: ItemEventLabel.removedFromGroup,
    [ItemEvent.updated]: ItemEventLabel.updated,
    [ItemEvent.created]: ItemEventLabel.created,
  };
  if (Object.prototype.hasOwnProperty.call(eventLabelMapping, event)) {
    return eventLabelMapping[event];
  }

  throw new Error(`Unknown ItemEvent: ${event}`);
};
