import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { currentDate, Inspection } from '@scannable/common';
import { useCreateExaminationMutation } from '@scannable/frontend/common';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

interface CreateThoroughExaminationValues {
  jobReference: string;
  dateOfIssue: string;
}

const validationSchema = yup.object().shape({
  jobReference: yup.string().required('Please enter a job number'),
});

export function CreateThoroughExaminationModal() {
  const { hideModal } = useModal();
  const [createExamination] = useCreateExaminationMutation({
    refetchQueries: [Inspection.GetExaminations],
  });

  const formik = useFormik({
    initialValues: {
      jobReference: '',
      dateOfIssue: currentDate,
    },
    validationSchema,
    onSubmit: async (
      values: CreateThoroughExaminationValues,
      actions: FormikHelpers<CreateThoroughExaminationValues>
    ) => {
      const result = await resolveMutation(
        createExamination({
          variables: {
            data: {
              dateOfIssue: values.dateOfIssue,
              jobReference: values.jobReference,
            },
          },
        }),
        {
          successMessage: 'Created examination successfully',
        }
      );
      actions.setSubmitting(false);
      if (result.ok) {
        hideModal();
      }
    },
  });
  return (
    <Modal title="Create Thorough Examination">
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: 'Create',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup
          id="Name"
          name="jobReference"
          type="text"
          formik={formik}
          label="Job Number"
        />
        <InputFieldGroup
          name="dateOfIssue"
          type="date"
          formik={formik}
          inline
          label="Date of Issue"
        />
      </ModalForm>
    </Modal>
  );
}

export default CreateThoroughExaminationModal;
