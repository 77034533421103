import { ExaminationStatus, InspectionStatus } from '@prisma/client';

import Badge from '../../atoms/Badge/Badge';

export interface StatusBadgeProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  status?: string;
  label?: string;
}

export function StatusBadge({ status, label }: StatusBadgeProps) {
  const title = label ?? status;

  let color: 'green' | 'red' | 'blue' | 'gray' | 'yellow';
  switch (status) {
    case ExaminationStatus.COMPLETE:
    case InspectionStatus.PASSED:
      color = 'green';
      break;
    case InspectionStatus.FAILED:
      color = 'red';
      break;
    case ExaminationStatus.ACTIVE:
    case InspectionStatus.QUARANTINED:
      color = 'blue';
      break;
    case InspectionStatus.MONITORING:
      color = 'yellow';
      break;
    default:
      color = 'gray';
  }

  return (
    <Badge
      size="lg"
      colour={color}
      className={`ml-2 text-${color}-400 whitespace-nowrap`}
    >
      <span className={`h-3 w-3 rounded-full mr-2 bg-${color}-400`} />
      {title}
    </Badge>
  );
}

export default StatusBadge;
