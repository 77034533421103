import { useEffect } from 'react';

export function useAsyncEffect(
  fn: () => Promise<void | (() => void)>,
  dependencies?: React.DependencyList
) {
  return useEffect(() => {
    const destructorPromise = fn();
    return () => {
      destructorPromise.then((destructor) => destructor && destructor());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
