import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  MinusCircleIcon,
} from '@heroicons/react/20/solid';

import { cn } from '@scannable/common';

import Button from '../../atoms/Button/Button';

export interface TopNoticeProps extends React.HTMLAttributes<HTMLDivElement> {
  status:
    | 'success'
    | 'failure'
    | 'quarantine'
    | 'warning'
    | 'info'
    | 'monitoring';
  title: string;
  summary?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
  isSidePanel?: boolean;
}

const statusStyles = {
  success: {
    bgColor: 'bg-green-light',
    textColor: 'text-green-800',
    iconColor: 'text-green-medium',
    borderColor: 'bg-green-medium',
    Icon: CheckCircleIcon,
  },
  failure: {
    bgColor: 'bg-red-100',
    textColor: 'text-red-800',
    iconColor: 'text-red-400',
    borderColor: 'bg-red-400',
    Icon: ExclamationCircleIcon,
  },
  quarantine: {
    bgColor: 'bg-blue-100',
    textColor: 'text-blue-800',
    iconColor: 'text-blue-400',
    borderColor: 'bg-blue-400',
    Icon: MinusCircleIcon,
  },
  monitoring: {
    bgColor: 'bg-yellow-100',
    textColor: 'text-yellow-800',
    iconColor: 'text-yellow-400',
    borderColor: 'bg-yellow-400',
    Icon: ExclamationCircleIcon,
  },
  warning: {
    bgColor: 'bg-yellow-100',
    textColor: 'text-yellow-800',
    iconColor: 'text-yellow-400',
    borderColor: 'bg-yellow-400',
    Icon: ExclamationCircleIcon,
  },
  info: {
    bgColor: 'bg-gray-200',
    textColor: 'text-gray-800',
    iconColor: 'text-gray-400',
    borderColor: 'bg-gray-400',
    Icon: InformationCircleIcon,
  },
};

export function TopNotice({
  status,
  title,
  summary,
  buttonText,
  buttonOnClick,
  isSidePanel = false,
  ...props
}: TopNoticeProps) {
  const { bgColor, textColor, iconColor, borderColor, Icon } =
    statusStyles[status];

  return (
    <div
      {...props}
      className={cn(
        'flex flex-col justify-between',
        bgColor,
        !isSidePanel ? 'rounded-t-lg' : '',
        'text-sm'
      )}
    >
      <div className="p-4 pb-0">
        <div className="flex flex-row">
          <div>
            {Icon && (
              <Icon className={cn(iconColor, 'h-6 w-6')} aria-hidden="true" />
            )}
          </div>
          <div className="flex flex-col flex-grow pl-2">
            <div className={cn(textColor, 'font-semibold')}>{title}</div>
            {summary && (
              <div className={cn(textColor, 'font-medium')}>{summary}</div>
            )}
          </div>
          {buttonText && buttonOnClick && (
            <Button
              className="ml-4"
              size="sm"
              color="white"
              onClick={buttonOnClick}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
      <div
        className={cn(
          borderColor,
          'h-2 w-full mt-4',
          !isSidePanel ? 'rounded-t-md' : ''
        )}
      />
    </div>
  );
}

export default TopNotice;
