import { InspectionStatus } from '@prisma/client';

import Badge from '../../atoms/Badge/Badge';

export interface InspectionStatusBadgeProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  status?: string;
  mode?: 'inline' | 'block';
  expiryDate?: string | null;
}

export function InspectionStatusBadge({
  status,
  expiryDate,
  mode = 'inline',
}: InspectionStatusBadgeProps) {
  let title = status
    ? `${status[0].toUpperCase()}${status.slice(1).toLowerCase()}`
    : 'Not inspected';
  let color: 'green' | 'red' | 'blue' | 'gray' | 'yellow';
  switch (status) {
    case InspectionStatus.PASSED:
      color = 'green';
      break;
    case InspectionStatus.FAILED:
      color = 'red';
      break;
    case InspectionStatus.QUARANTINED:
      color = 'blue';
      break;
    case InspectionStatus.MONITORING:
      color = 'yellow';
      break;
    default:
      color = 'gray';
  }

  const isExpired = expiryDate ? new Date(expiryDate) < new Date() : false;
  if (isExpired) {
    title = 'Expired';
    color = 'red';
  }

  return (
    <div>
      {mode === 'inline' ? (
        <Badge
          size="sm"
          colour={color}
          className={`text-${color}-400 whitespace-nowrap`}
        >
          <span className={`h-2 w-2 rounded-full mr-1 bg-${color}-400`} />
          {title}
        </Badge>
      ) : (
        <div
          className={`flex items-center bg-${color}-100 text-${color}-700 text-sm p-4 py-4 rounded-md`}
        >
          <span className={`h-2 w-2 rounded-full mr-2 bg-${color}-400`} />
          {title}
        </div>
      )}
    </div>
  );
}

export default InspectionStatusBadge;
