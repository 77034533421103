import { FileFolder } from '../types/file-folder.types';

const imageBaseUrl =
  process.env.NEXT_PUBLIC_IMAGE_DOMAIN || process.env.IMAGE_DOMAIN;

const isLocalImage = imageBaseUrl?.includes('localhost');

const fileBaseUrl =
  (process.env.NEXT_PUBLIC_FILE_DOMAIN ||
    process.env.EXPO_PUBLIC_FILE_DOMAIN ||
    process.env.FILE_DOMAIN) ??
  'https://scannable-storage.s3.us-west-2.amazonaws.com';

type AvatarSizeType = 'small' | 'large';
type ImageSize = 'small' | 'medium' | 'large';

export const usersAvatarUrl = (
  avatar: string,
  size: AvatarSizeType = 'small'
) => {
  if (avatar) {
    return `${imageBaseUrl}/${FileFolder.Avatar}/${avatar}`;
  }
  return `/static/images/avatar/${size}.png`;
};

export const fileUrl = (file: string) => {
  return `${fileBaseUrl}/${file}`;
};

export const imageFromS3Key = (image: string, size?: ImageSize | null) => {
  let dimensions = null;
  switch (size) {
    case 'small':
      dimensions = '100x0';
      break;
    case 'medium':
      dimensions = '400x0';
      break;
    case 'large':
      dimensions = '1000x0';
      break;
    default:
      break;
  }
  return `${imageBaseUrl}/${dimensions ? `${dimensions}/` : ''}${image}`;
};

export const resizedImage = (
  image: string,
  imageSettings?: {
    width?: number;
    height?: number;
    fit?: 'fill' | 'cover';
  } | null
) => {
  if (isLocalImage) {
    return `${imageBaseUrl}/${image}`;
  }
  const dimensions = `${imageSettings?.width || 0}x${
    imageSettings?.height || 0
  }${imageSettings?.fit ? `/filters:${imageSettings?.fit}(fff)` : ''}`;

  return `${imageBaseUrl}/${dimensions ? `${dimensions}/` : ''}${image}`;
};

/**
 * Currently not used, but option if we want to generate base64 image urls
 */
export const base64FormattedImage = (
  image: string,
  resize: { width?: number; height?: number; fit?: 'cover' | 'contain' }
) => {
  const bucket = process.env.AWS_PUBLIC_BUCKET_NAME;
  const resizeParams = {
    bucket,
    key: image,
    edits: {
      // resize: {
      //   width: 1000,
      //   fit: 'cover',
      // },
      resize,
    },
  };
  return `${imageBaseUrl}/${Buffer.from(JSON.stringify(resizeParams)).toString(
    'base64'
  )}`;
};
