import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { TadaDocumentNode } from 'gql.tada';

import { makeSearchClauseFromFilters } from '@scannable/frontend/apollo';

import { TableProps } from '../Table';

export const useTableSearch = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends TadaDocumentNode<any, any>
>(
  searchFields: TableProps<T>['searchFields'],
  table: string
) => {
  const [searchTerm, setSearchTerm] = useState<null | string>(null);
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const searchTermFromUrl = router.query.search || '';
      const tableFromUrl = router.query.table || '';
      if (Array.isArray(searchTermFromUrl)) {
        return;
      }
      if (!searchTermFromUrl) {
        return;
      }
      // load in search term on initial load
      if (!table || table === 'default' || tableFromUrl === table) {
        setSearchTerm(searchTermFromUrl);
      }
    }
  }, [router.isReady, router.query.search, router.query.table, table]);

  const searchWhereClause = useMemo(() => {
    return makeSearchClauseFromFilters(searchTerm, searchFields);
  }, [searchTerm, searchFields]);

  const handleSearch = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      search: HTMLInputElement;
    };

    const search = formElements.search.value.trim();

    if (search) {
      setSearchTerm(search);
      // Update URL with the search term
      const currentQuery = router.query;
      const updatedQuery = { ...currentQuery, search, table };

      router.push(
        { pathname: router.pathname, query: updatedQuery },
        undefined,
        {
          shallow: true,
        }
      );
      return;
    }
    // clear search term if it's an empty submission
    handleRemoveSearchTerm();
  };

  const handleRemoveSearchTerm = () => {
    setSearchTerm(null);
    const currentQuery = router.query;
    delete currentQuery.search;
    delete currentQuery.table;
    router.push({ pathname: router.pathname, query: currentQuery }, undefined, {
      shallow: true,
    });
  };

  return {
    searchTerm,
    setSearchTerm,
    searchWhereClause,
    handleSearch,
    handleRemoveSearchTerm,
  };
};
