import { ApiKeys } from '@scannable/common';

import { graphql } from '../graphql';

export const CREATE_API_KEY = graphql(`
  mutation ${ApiKeys.CreateApiKey} ($organisationId: Int!) {
    createApiKey(organisationId: $organisationId) {
      apiKey
    }
  }
`);
