export enum CanView {
  everyone = 'everyone', // everyone can view
  superAdmin = 'super-admin', // show only for super -admin
  featureFlag = 'feature-flag', // show only under feature flag
}
export const features = {
  checklists: {
    canView: CanView.everyone,
    featureFlag: process.env.NEXT_PUBLIC_CHECKLISTS_ENABLED === '1',
  },
  notifications: {
    isPro: true,
    canView: CanView.featureFlag,
    featureFlag:
      process.env.NEXT_PUBLIC_NOTIFICATIONS_ENABLED === '1' ||
      process.env.EXPO_PUBLIC_NOTIFICATIONS_ENABLED === '1',
  },
  limitedUser: {
    canView: CanView.superAdmin,
    featureFlag: process.env.NEXT_PUBLIC_LIMITED_USER_ENABLED === '1',
  },
};

export function featureFlagEnabled(flag: keyof typeof features) {
  const feature = features[flag];
  const isFeatureEnabled = feature.featureFlag;

  return isFeatureEnabled;
}
