import { AccountModuleType, RoleType } from '@scannable/common';

import { useAuth } from '../auth/AuthContext/AuthContext';

export function Guard({
  children,
  roles,
  accountTypes,
}: {
  children: React.ReactNode;
  roles: RoleType[] | undefined;
  accountTypes?: AccountModuleType[];
}) {
  const { guard, subscriptionType } = useAuth();
  // we hide if accountType is not included in subscriptionType before role type
  if (
    accountTypes &&
    subscriptionType &&
    !accountTypes.includes(subscriptionType as AccountModuleType)
  ) {
    return null;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return guard(roles) ? <>{children}</> : null;
}
