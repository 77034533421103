import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Metadata } from '@scannable/common';
import {
  CREATE_METADATA_CATEGORY,
  UPDATE_METADATA_CATEGORY,
} from '@scannable/graphql-queries';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

type MetadataCategoryValues = {
  name: string;
};
export interface MetadataCategoryModalProps
  extends Partial<MetadataCategoryValues> {
  id?: number;
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter a name'),
});

export function MetadataCategoryModal({
  name,
  id,
}: MetadataCategoryModalProps) {
  const [createMetadataCategory] = useMutation(CREATE_METADATA_CATEGORY, {
    refetchQueries: [Metadata.PaginatedMetadataCategories],
  });
  const [updateMetadataCategory] = useMutation(UPDATE_METADATA_CATEGORY, {
    refetchQueries: [Metadata.PaginatedMetadataCategories],
  });
  const { hideModal } = useModal();
  const formik = useFormik({
    initialValues: {
      name: name || '',
    } as MetadataCategoryValues,
    validationSchema,
    onSubmit: async (
      values: MetadataCategoryValues,
      actions: FormikHelpers<MetadataCategoryValues>
    ) => {
      const data = {
        name: values.name,
      };

      const result = await resolveMutation(
        id
          ? updateMetadataCategory({
              variables: {
                data: { ...data, id },
              },
            })
          : createMetadataCategory({
              variables: {
                data,
              },
            }),
        {
          successMessage: id
            ? 'Created metadata category'
            : 'Updated metadata category',
        }
      );

      if (result.ok) {
        hideModal();
        actions.setSubmitting(false);
      }
    },
  });
  return (
    <Modal title={id ? 'Update Category' : 'Create Category'}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: id ? 'Update' : 'Create',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup name="name" type="text" formik={formik} label="Name" />
      </ModalForm>
    </Modal>
  );
}

export default MetadataCategoryModal;
