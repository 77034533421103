import { User } from '@scannable/common';

import { graphql } from '../graphql';

export const ME_QUERY = graphql(`
  query ${User.MeQuery} {
    me {
      id
      email
      firstName
      lastName
      avatar
    }
  }
`);

export const MY_PROFILE = graphql(`query ${User.MyProfile}{
  me {
    id
    email
    firstName
    lastName
    avatar
    signature
    inspectorDeclaration
  }
}`);

export const USER_DATA_FOR_UPDATE =
  graphql(`query ${User.UserDataForUpdate}($id: Int!) {
  user (id: $id) {
    id
    firstName
    lastName
    email
    avatar
    superUser
    ban
    userOrganisations {
      id
      name
    }
  }
}`);

export const USERS_QUERY = graphql(`
  query ${User.GetUsers} (
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    users(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      avatar
      firstName
      lastName
      email
      lastLogin
      activated
    }
  }
`);
