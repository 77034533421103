import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { cn } from '@scannable/common';

import { useRouteChange } from '../../hooks/useRouteChange/useRouteChange';
import { useTable } from '../../hooks/useTable/useTable';
import { useSidePanel } from '../SidePanelContext/SidePanelContext';

interface SidePanelProps {
  isOpen?: boolean;
  onToggleOpen?: (open: boolean) => void;
  children: ReactNode;
  title?: string;
  onBack?: () => void;
  headerAction?: ReactNode;
}

export function SidePanel({
  onToggleOpen,
  children,
  title,
  onBack,
  headerAction,
}: SidePanelProps) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const { isSidePanelOpen, hideSidePanel } = useSidePanel();
  const { selectedRows } = useTable();

  const handleClose = () => {
    hideSidePanel();
    onToggleOpen && onToggleOpen(false);
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === overlayRef.current) {
      handleClose();
    }
  };

  useRouteChange(() => {
    hideSidePanel();
  });

  const [isShowing, setIsShowing] = useState(false);
  useEffect(() => {
    setIsShowing(isSidePanelOpen);
  }, [isSidePanelOpen]);

  return (
    <aside className="w-96 overflow-y-auto">
      <div
        className={cn(
          'mr-4 bg-white rounded-xl overflow-hidden',
          selectedRows.length > 0 ? 'h-[calc(100vh-10rem)]' : ''
        )}
      >
        <div
          className="flex items-center w-full sm:justify-center"
          ref={overlayRef}
          onClick={handleOverlayClick}
        >
          <Transition.Root show={isShowing} as={Fragment}>
            <Transition.Child
              as={Fragment}
              enter="transform transition grow duration-300 sm:duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition grow duration-300 sm:duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="flex flex-col w-full ">
                <div className="relative">
                  <div className=" bg-white rounded-xl ">
                    <div className="flex flex-row items-center py-2 border-b border-gray-200">
                      <div className="flex justify-start ">
                        <button
                          type="button"
                          className="rounded-full flex justify-center items-center text-gray-400 bg-gray-100 h-8 w-8 overflow-hidden ml-2"
                          onClick={onBack || handleClose}
                        >
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="text-center flex-1 font-medium text-gray-900">
                        {title}
                      </div>
                      <div className="flex justify-end ">
                        {headerAction ? (
                          <div className="flex flex-row items-center">
                            {headerAction}
                          </div>
                        ) : (
                          <span className="w-6 h-6" />
                        )}
                      </div>
                    </div>
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Transition.Root>
        </div>
      </div>
    </aside>
  );
}

export default SidePanel;
