import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';

import {
  classNames,
  DEFAULT_BATCH_ORDER,
  DEFAULT_COUNTRY_OF_ORGIN_ORDER,
  DEFAULT_CUSTOMER_REFERENCE_ORDER,
  DEFAULT_DOM_ORDER,
  DEFAULT_INCREMENTS,
  DEFAULT_INCREMENTS_ORDER,
  DEFAULT_PREFIX_ORDER,
  DEFAULT_PURCHASE_ORDER_NUMBER_ORDER,
  DEFAULT_SALES_ORDER_NUMBER_ORDER,
  DEFAULT_SUFFIX_ORDER,
  SerialFormatComponent,
} from '@scannable/common';
import { valuesToSerialFormatComponents } from '@scannable/frontend/common';
import {
  CREATE_MANUFACTURERS_SERIAL_FORMAT,
  UPDATE_SERIAL_FORMAT,
} from '@scannable/graphql-queries';

import { Text } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import {
  DescriptionList,
  DescriptionListItem,
  InputFieldGroup,
  RadioGroupTable,
  SerialFormatSort,
} from '../../molecules';
import Form from '../Form/Form';
import { withFormLoading } from '../withFormLoading/withFormLoading';
import {
  EditSerialFormatFormProps,
  SerialFormatFormProps,
  SerialFormatValues,
  validationSchema,
} from './SerialFormatFormat.types';

export function SerialFormatForm({
  id,
  name,
  prefix,
  hasPrefix,
  hasDom,
  dom,
  hasBatch,
  batch,
  hasCountryOfOrgin,
  hasCustomerReference,
  customerReference,
  hasSalesOrderNumber,
  countryOfOrigin,
  salesOrderNumber,
  hasPurchaseOrderNumber,
  purchaseOrderNumber,
  hasIncrements,
  increments,
  hasSuffix,
  suffix,
}: SerialFormatFormProps) {
  const { t } = useTranslation();
  const { manufacturerId } = useAuth();
  const router = useRouter();
  const [createSerialFormat] = useMutation(CREATE_MANUFACTURERS_SERIAL_FORMAT);
  const [updateSerialFormat] = useMutation(UPDATE_SERIAL_FORMAT);

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      hasPrefix: hasPrefix ?? false,
      hasDom: hasDom ?? false,
      hasBatch: hasBatch ?? false,
      hasCustomerReference: hasCustomerReference ?? false,
      hasCountryOfOrgin: hasCountryOfOrgin ?? false,
      hasSalesOrderNumber: hasSalesOrderNumber ?? false,
      hasPurchaseOrderNumber: hasPurchaseOrderNumber ?? false,
      hasIncrements: hasIncrements ?? true,
      hasSuffix: hasSuffix ?? false,
      prefix: prefix ?? { value: '', order: DEFAULT_PREFIX_ORDER },
      dom: dom ?? { value: '', order: DEFAULT_DOM_ORDER },
      batch: batch ?? { order: DEFAULT_BATCH_ORDER },
      customerReference: customerReference ?? {
        order: DEFAULT_CUSTOMER_REFERENCE_ORDER,
      },
      salesOrderNumber: salesOrderNumber ?? {
        order: DEFAULT_SALES_ORDER_NUMBER_ORDER,
      },
      purchaseOrderNumber: purchaseOrderNumber ?? {
        order: DEFAULT_PURCHASE_ORDER_NUMBER_ORDER,
      },
      countryOfOrigin: countryOfOrigin ?? {
        value: '',
        order: DEFAULT_COUNTRY_OF_ORGIN_ORDER,
      },
      increments: increments ?? {
        value: DEFAULT_INCREMENTS,
        order: DEFAULT_INCREMENTS_ORDER,
      },
      suffix: suffix ?? { value: '', order: DEFAULT_SUFFIX_ORDER },
    },
    validationSchema: validationSchema,
    onSubmit: async (
      values: SerialFormatValues,
      actions: FormikHelpers<SerialFormatValues>
    ) => {
      const components = valuesToSerialFormatComponents(values);
      const data = {
        name: values.name,
        components,
      };

      const result = await resolveMutation(
        id
          ? updateSerialFormat({
              variables: {
                data: { ...data, id },
              },
            })
          : createSerialFormat({
              variables: {
                data: {
                  ...data,
                  manufacturerId: manufacturerId as number,
                },
              },
            }),
        {
          successMessage: id
            ? 'Serial format updated'
            : 'Serial format created',
        }
      );

      actions.setSubmitting(false);
      if (result.ok) {
        router.push('/admin/serialisation/serial-format');
      }
    },
  });

  const onOrderChange = useCallback(
    (parts: SerialFormatComponent[]) => {
      parts.forEach((part) => {
        formik.setFieldValue(part.type, {
          ...formik.values[part.type],
          order: part.order,
        });
      });
    },
    [formik]
  );

  return (
    <Form
      formik={formik}
      buttons={[
        {
          type: 'submit',
          label: id ? 'Update' : 'Create',
        },
      ]}
    >
      <div className="my-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <Text>{t('serial_format_define_format_serial1')}</Text>
        <Text>{t('serial_format_define_format_serial2')}</Text>
        <Text>{t('serial_format_define_format_serial3')}</Text>
      </div>
      <div className="space-y-3 px-1">
        <DescriptionList title={t('serial_format.title_label')}>
          <DescriptionListItem>
            <InputFieldGroup
              placeholder={t('serial_format.descriptive_title')}
              name="name"
              type="text"
              formik={formik}
            />
          </DescriptionListItem>
        </DescriptionList>

        <DescriptionList
          title={t('serial_format_prefix')}
          subTitle={t('serial_format_prefix_option_serial')}
        >
          <DescriptionListItem title={t('serial_format_serial_have_prefix')}>
            <RadioGroupTable<boolean>
              label="Serial prefix"
              name="hasPrefix"
              value={formik.values.hasPrefix}
              onChange={(value) => formik.setFieldValue('hasPrefix', value)}
              options={[
                {
                  label: t('serial_format_yes_option'),
                  value: true,
                },
                {
                  label: t('serial_format_no_option'),
                  value: false,
                },
              ]}
            />
          </DescriptionListItem>
          <div
            className={classNames(
              !formik.values.hasPrefix ? 'hidden' : 'block'
            )}
          >
            <DescriptionListItem title="Prefix component">
              <InputFieldGroup
                id="prefix"
                placeholder="Enter a prefix e.g. ABC"
                name="prefix"
                type="text"
                error={formik.errors.prefix?.value}
                touched={formik.touched.prefix?.value}
                value={formik.values.prefix.value}
                onChange={(e) => {
                  formik.setFieldValue('prefix', {
                    value: e.target.value,
                    order: formik.values.prefix.order,
                  });
                }}
              />
            </DescriptionListItem>
          </div>
        </DescriptionList>

        <DescriptionList
          title={t('serial_format.dom')}
          subTitle={t('serial_format_date_of_manu_optional')}
        >
          <DescriptionListItem title={t('serial_format.dom')}>
            <RadioGroupTable<boolean>
              label={t('serial_format.dom')}
              name="hasDom"
              value={formik.values.hasDom}
              onChange={(value) => formik.setFieldValue('hasDom', value)}
              options={[
                {
                  label: t('serial_format.dom_yes'),
                  value: true,
                },
                {
                  label: t('serial_format.dom_no'),
                  value: false,
                },
              ]}
            />
          </DescriptionListItem>
          <div
            className={classNames(!formik.values.hasDom ? 'hidden' : 'block')}
          >
            <DescriptionListItem title="We offer the following pre-sets:">
              <RadioGroupTable<string>
                label="Date of manufacture format"
                name="dom"
                value={formik.values.dom.value}
                onChange={(value) => {
                  formik.setFieldValue('dom', {
                    value,
                    order: formik.values.dom.order,
                  });
                }}
                options={[
                  {
                    label: 'YYMM e.g. 2201 where 22 = 2022, 01 = January',
                    value: 'YYMM',
                  },
                  {
                    label:
                      'YYMMDD e.g. 220101 where 22 = 2022, 01 = January, 01 = 1st day of the month',
                    value: 'YYMMDD',
                  },
                  {
                    label: 'YYM e.g. 22A where 22 = 2022, A = January, ',
                    value: 'YYM',
                  },
                  {
                    label:
                      'YYDDD e.g. 22123 where 22 = 2022, 123 = 123rd day of the year / 3rd May 2022',
                    value: 'YYDDD',
                  },
                  {
                    label:
                      'YYYYMMDD e.g. 20220101 where 2022, 01 = January, 01 = 1st day of month',
                    value: 'YYYYMMDD',
                  },
                  {
                    label:
                      'DDDYY e.g. 12322 123 = 123rd day of the year, where 22 = 2022 / 3rd May 2022',
                    value: 'DDDYY',
                  },
                ]}
              />
            </DescriptionListItem>
          </div>
        </DescriptionList>

        <DescriptionList
          title={t('create_serials_batch_num')}
          description={
            <>
              <Text>{t('serial_format_sf_batch_num1')}</Text>
              <Text>{t('serial_format_sf_batch_num3')}</Text>
              <Text>{t('serial_format_sf_batch_num2')}</Text>
            </>
          }
        >
          <DescriptionListItem title={t('create_serials_batch_num')}>
            <RadioGroupTable
              label="Batch number"
              name="hasBatch"
              value={formik.values.hasBatch}
              onChange={(value) => formik.setFieldValue('hasBatch', value)}
              options={[
                {
                  label: t('include_batch_number'),
                  value: true,
                },
                {
                  label: t('do_not_include_batch_number'),
                  value: false,
                },
              ]}
            />
          </DescriptionListItem>
        </DescriptionList>

        <DescriptionList title={t('customer_reference')}>
          <DescriptionListItem title={t('customer_reference')}>
            <RadioGroupTable<boolean>
              label="Customer reference"
              name="hasCustomerReference"
              value={formik.values.hasCustomerReference}
              onChange={(value) =>
                formik.setFieldValue('hasCustomerReference', value)
              }
              options={[
                {
                  label: t('serial_format_incl_cus_ref'),
                  value: true,
                },
                {
                  label: t('serial_format_dont_incl_cus_ref'),
                  value: false,
                },
              ]}
            />
          </DescriptionListItem>
        </DescriptionList>

        <DescriptionList title={t('serial_format_sales_order')}>
          <DescriptionListItem title={t('serial_format_sales_order')}>
            <RadioGroupTable<boolean>
              label="Sales Order Number"
              name="hasSalesOrderNumber"
              value={formik.values.hasSalesOrderNumber}
              onChange={(value) =>
                formik.setFieldValue('hasSalesOrderNumber', value)
              }
              options={[
                {
                  label: t('serial_format_incl_order'),
                  value: true,
                },
                {
                  label: t('serial_format_dont_incl_order'),
                  value: false,
                },
              ]}
            />
          </DescriptionListItem>
        </DescriptionList>

        <DescriptionList title={t('serial_format_p_o')}>
          <DescriptionListItem title={t('serial_format_p_o')}>
            <RadioGroupTable
              label="Purchase Order Number"
              name="hasPurchaseOrderNumber"
              value={formik.values.hasPurchaseOrderNumber}
              onChange={(value) =>
                formik.setFieldValue('hasPurchaseOrderNumber', value)
              }
              options={[
                {
                  label: t('serial_format_incl_purchase_order'),
                  value: true,
                },
                {
                  label: t('serial_format_dont_incl_purchase_order'),
                  value: false,
                },
              ]}
            />
          </DescriptionListItem>
        </DescriptionList>

        <DescriptionList
          title={t('serial_format.increments_label')}
          description={
            <>
              <Text>{t('serial_format_increments')}</Text>
              <Text>{t('serial_format_increments2')}</Text>
            </>
          }
        >
          <DescriptionListItem title={t('serial_format_padding')}>
            <RadioGroupTable
              label="Apply padding?"
              name="padding"
              value={formik.values.hasIncrements}
              onChange={(value) => formik.setFieldValue('hasIncrements', value)}
              options={[
                {
                  label: t('serial_format_pad_serial_num'),
                  value: true,
                },
                {
                  label: t('serial_format_dont_pad_srial_num'),
                  value: false,
                },
              ]}
            />
          </DescriptionListItem>
          <div
            className={classNames(
              !formik.values.hasIncrements ? 'hidden' : 'block'
            )}
          >
            <DescriptionListItem title={t('number_of_increment_characters')}>
              <InputFieldGroup
                id="increments"
                placeholder="Enter a numeric value between 3 and 5"
                name="increments"
                type="number"
                min="3"
                max="5"
                value={formik.values.increments.value}
                error={formik.errors.increments?.value}
                touched={formik.touched.increments?.value}
                onChange={(e) => {
                  formik.setFieldValue('increments', {
                    value: Number(e.target.value),
                    order: formik.values.increments.order,
                  });
                }}
              />
            </DescriptionListItem>
          </div>
        </DescriptionList>

        <DescriptionList
          title={t('serial_format.country_of_origin')}
          subTitle={t('serial_format.country_of_origin_sub_title')}
        >
          <DescriptionListItem title={t('serial_format.country_of_origin')}>
            <RadioGroupTable<boolean>
              label={t('serial_format.country_of_origin')}
              name="hasCountryOfOrgin"
              value={formik.values.hasCountryOfOrgin}
              onChange={(value) =>
                formik.setFieldValue('hasCountryOfOrgin', value)
              }
              options={[
                {
                  label: t('serial_format.country_of_origin_yes'),
                  value: true,
                },
                {
                  label: t('serial_format.country_of_origin_no'),
                  value: false,
                },
              ]}
            />
          </DescriptionListItem>
          <div
            className={classNames(
              !formik.values.hasCountryOfOrgin ? 'hidden' : 'block'
            )}
          >
            <DescriptionListItem title="Country of Origin component">
              <InputFieldGroup
                placeholder={t('serial_format.country_of_origin_placeholder')}
                name="countryOfOrigin"
                type="text"
                error={formik.errors.countryOfOrigin?.value}
                touched={formik.touched.countryOfOrigin?.value}
                value={formik.values.countryOfOrigin.value}
                onChange={(e) => {
                  formik.setFieldValue('countryOfOrigin', {
                    value: e.target.value,
                    order: formik.values.countryOfOrigin.order,
                  });
                }}
              />
            </DescriptionListItem>
          </div>
        </DescriptionList>

        <DescriptionList
          title={t('serial_format.suffix_label')}
          subTitle={t('serial_format_suffix')}
        >
          <DescriptionListItem
            title={t('serial_format_does_serial_num_have_suffix')}
          >
            <RadioGroupTable
              label={t('serial_format_does_serial_num_have_suffix')}
              name="hasSuffix"
              value={formik.values.hasSuffix}
              onChange={(value) => formik.setFieldValue('hasSuffix', value)}
              options={[
                {
                  label: t('serial_format_yes_suffix_for_serial_format'),
                  value: true,
                },
                {
                  label: t('serial_format_no_suffix_for_serial_format'),
                  value: false,
                },
              ]}
            />
          </DescriptionListItem>
          <div
            className={classNames(
              !formik.values.hasSuffix ? 'hidden' : 'block'
            )}
          >
            <DescriptionListItem title="Suffix component">
              <InputFieldGroup
                id="suffix"
                placeholder="Enter a suffix"
                name="suffix"
                type="text"
                value={formik.values.suffix.value}
                error={formik.errors.suffix?.value}
                touched={formik.touched.suffix?.value}
                onChange={(e) => {
                  formik.setFieldValue('suffix', {
                    value: e.target.value,
                    order: formik.values.suffix.order,
                  });
                }}
              />
            </DescriptionListItem>
          </div>
        </DescriptionList>

        <DescriptionList title={t('serial_format_your_serial_num_format')}>
          <DescriptionListItem>
            <SerialFormatSort
              components={formik.values}
              onOrderChange={onOrderChange}
            />
          </DescriptionListItem>
        </DescriptionList>
      </div>
    </Form>
  );
}

const WrappedForm = withFormLoading(SerialFormatForm);

export function EditSerialFormatForm({
  id,
  ...props
}: EditSerialFormatFormProps) {
  return <WrappedForm id={id} {...props} />;
}
export default SerialFormatForm;
