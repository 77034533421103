import { Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { SIMPLE_ERROR_MESSAGE } from '@scannable/common';

import { ApolloConfig } from '../types/client.types';
import { apolloError, extractOrginalError } from '../utils/utils';

export const onErrorLink = ({
  onNetworkError,
  onError: onErrorCb,
  isDebugMode,
}: ApolloConfig) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (onErrorCb) {
      onErrorCb({
        graphQLErrors,
        networkError,
        operation,
      });
    }

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const extension = err.extensions as {
          originalError: {
            message: string[] | string;
            statusCode: number;
            error: string;
          };
          code: string;
          status: string;
        };
        // code could be on extensions.code or extensions.status: code usually has the number, code has the string
        const code = extension.status || extension.code;
        const orginalError = extractOrginalError(extension);
        const errorMessage = err.message || SIMPLE_ERROR_MESSAGE;

        switch (`${code}`) {
          case 'UNAUTHENTICATED':
          case '401':
            return new Observable((observer) => {
              observer.error(
                apolloError(
                  err,
                  orginalError === 'Forbidden resource'
                    ? 'Action not allowed'
                    : orginalError ?? errorMessage
                )
              );
            });
          default:
            if (isDebugMode === true) {
              console.debug(
                `[GraphQL error]: Message: ${err.message}, Location: ${
                  err.locations ? JSON.stringify(err.locations) : err.locations
                }, Path: ${err.path}`
              );
            }
            return new Observable((observer) => {
              observer.error(
                apolloError(
                  err,
                  orginalError === 'Forbidden resource'
                    ? 'Action not allowed'
                    : orginalError ?? errorMessage
                )
              );
            });
        }
      }
    }
    if (networkError && onNetworkError) {
      onNetworkError(networkError);
    }
    return forward(operation);
  });
