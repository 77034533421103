import { Manufacturer } from '@scannable/common';

import { graphql } from '../graphql';

export const UPDATE_MANUFACTURER = graphql(`
  mutation ${Manufacturer.UpdateManufacturer}($data: UpdateManufacturerInput!) {
    updateManufacturer(data: $data) {
      id
    }
  }`);

export const UPDATE_ACCOUNT_MANUFACTURER = graphql(`
  mutation ${Manufacturer.UpdateAccountManufacturer} ($data: UpdateAccountManufacturerInput!) {
    updateAccountManufacturer(data: $data) {
      id
    }
  }`);

export const REMOVE_MANUFACTURER_LOGO = graphql(`
mutation ${Manufacturer.RemoveManufacturerLogo} ($id: Int!) {
  removeManufacturerLogo(id: $id) {
    id
  }
}
`);
