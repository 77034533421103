import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import { capitaliseFirstLetter, classNames, cn } from '@scannable/common';

import { Icon } from '../../atoms';

type SetSelectedTabType = (t: string) => void;
export interface ITab {
  name: string;
  isProFeature?: boolean;
  label?: string;
  dataCy?: string;
}

interface TabState {
  selectedTab: string | null;
  setSelectedTab: SetSelectedTabType;
  tabs: ITab[];
  initTab: (tab: ITab) => void;
}

const TabContext = createContext<TabState>({
  selectedTab: null,
  setSelectedTab: () => null,
  tabs: [],
  initTab: () => null,
});

export const useTabs = () => useContext(TabContext);

export function TabProvider({
  children,
  defaultTab,
}: {
  children: React.ReactNode;
  defaultTab: string;
}) {
  const router = useRouter();

  const [selectedTab, setSelectedTab] = useState<string | null>(defaultTab);
  const [tabs, setTabs] = useState<ITab[]>([]);

  const setTabFromQuery = useCallback(
    (tabFromUrl: string) => {
      const tabExists = tabs.some((tab) => tab.name === tabFromUrl);
      if (tabExists) {
        setSelectedTab(tabFromUrl);
      }
    },
    [tabs]
  );

  useEffect(() => {
    if (router.query.tab) {
      if (Array.isArray(router.query.tab)) {
        router.query.tab.map((tab) => setTabFromQuery(tab));
      } else {
        setTabFromQuery(router.query.tab);
      }
    }
  }, [router.query.tab, setTabFromQuery, tabs]);

  const initTab = useCallback((tab: ITab) => {
    setTabs((existingTabs) => [...existingTabs, tab]);
  }, []);

  const changeTab = useCallback(
    (tab: string) => {
      setSelectedTab(tab);
      if (tabs.some((t) => t.name === tab)) {
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              tab,
            },
          },
          undefined,
          { shallow: true }
        );
      }
    },
    [router, tabs]
  );

  return (
    <TabContext.Provider
      value={{ selectedTab, setSelectedTab: changeTab, tabs, initTab }}
    >
      {children}
    </TabContext.Provider>
  );
}
export function TabsContainer({ isInSidePanel }: { isInSidePanel?: boolean }) {
  return (
    <div
      className={cn(
        'relative pb-5 border-b border-gray-200 sm:pb-0',
        isInSidePanel ? 'px-5' : ''
      )}
    >
      <Tabs />
    </div>
  );
}
export function Tabs() {
  const { setSelectedTab, tabs, selectedTab } = useTabs();
  return (
    <div className="mt-4">
      <div className="sm:hidden">
        <label htmlFor="current-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="current-tab"
          name="current-tab"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          onChange={(e) => setSelectedTab(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.label ? tab.label : capitaliseFirstLetter(tab.name)}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <span
              key={tab.name}
              data-cy={tab.dataCy}
              onClick={() => setSelectedTab(tab.name)}
              className={classNames(
                selectedTab &&
                  tab.name.toLowerCase() === selectedTab.toLowerCase()
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'flex items-center whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
              )}
              aria-current={
                selectedTab &&
                tab.name.toLowerCase() === selectedTab.toLowerCase()
                  ? 'page'
                  : undefined
              }
            >
              {tab.label ? tab.label : capitaliseFirstLetter(tab.name)}
              {tab.isProFeature && (
                <Icon name="pro" className="text-gray-900 ml-1" />
              )}
            </span>
          ))}
        </nav>
      </div>
    </div>
  );
}

export default Tabs;

export function Tab({
  name,
  label,
  children,
  dataCy,
  isProFeature,
  ...props
}: {
  name: string;
  label?: string;
  dataCy?: string;
  isProFeature?: boolean;
  children: React.ReactNode;
}) {
  const { selectedTab, initTab, tabs } = useTabs();
  useEffect(() => {
    if (!tabs.find((t) => t.name.toLowerCase() === name.toLowerCase())) {
      initTab({ name, label, dataCy, isProFeature });
    }
  }, [name, initTab, label, dataCy, tabs, isProFeature]);

  return (
    <div
      className={
        selectedTab && name.toLowerCase() === selectedTab.toLowerCase()
          ? 'h-full' // ensure tab is full height for sticky content
          : 'hidden'
      }
      {...props}
    >
      {children}
    </div>
  );
}
