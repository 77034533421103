import { SerialPurchaseOrder } from '@scannable/common';

import { graphql } from '../graphql';

export const PAGINATED_SERIAL_PURCHASE_ORDERS = graphql(`
  query ${SerialPurchaseOrder.PaginatedSerialPurchaseOrdersQuery}(
    $where: SerialPurchaseOrderWhereInput
    $orderBy: [SerialPurchaseOrderOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedSerialPurchaseOrders(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        uuid
        createdAt
        purchaseOrderReference
        salesOrderReference
        dateOfManufacture
        downloadFileName
        productBatch {
          id
          batchNumber
        }
        serialFormat {
          id
          name
        }
        serialisedProductVariations {
          id
          serialQuantity
          productVariation {
            id
            productId
            name
            code
          }
        }
      }
      totalCount
    }
  }
`);

export const SERIAL_PO_CSV_CREATED = graphql(`
  subscription ${SerialPurchaseOrder.SerialPurchaseOrderCsvCreated}($manufacturerId: Int!) {
    serialPurchaseOrderCsvCreated(manufacturerId: $manufacturerId) {
      id
    }
  }`);

export const GET_FIRST_SERIAL_PRINT_DATA = graphql(`
  query ${SerialPurchaseOrder.GetFirstSerialPrintData}($serialPurchaseOrderId: String!) {
    getFirstSerialPrintData(serialPurchaseOrderId: $serialPurchaseOrderId) {
      id
      data
    }
  }
`);
