import { AllHTMLAttributes } from 'react';

import { cn } from '@scannable/common';

import { Text } from '../../atoms';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { TableRowAction, TableSettingsProps } from '../../types/table.types';
import TableCell from '../TableCell/TableCell';
import TableRow from '../TableRow/TableRow';
import TableRowActions from '../TableRowActions/TableRowActions';

export interface TableBodyProps<T>
  extends AllHTMLAttributes<HTMLTableSectionElement> {
  tableData: T[] | null;
  paginationData: {
    totalCount: number;
    offset: number;
    defaultPageSize: number;
  };
  hasPagination: boolean;
  next: () => void;
  prev: () => void;
  goToPage: (p: number) => void;
  useAdvancedPagination: boolean;
  maxPagination: boolean;
  loading: boolean;
  error: Error | undefined;
  selectable?: boolean;
  selectedRows: number[];
  setSelectedRows: (rows: number[]) => void;
  rowActions?: TableRowAction<T>[] | null;
}
function LoadingRow() {
  return (
    <div className="flex justify-between items-center pt-4 animate-pulse">
      <div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24 mb-2.5"></div>
        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500"></div>
      </div>
      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-12"></div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function TableBody<T extends Record<string, any>>({
  tableData,
  rowSettings,
  columnSettings,
  hasPagination,
  useAdvancedPagination,
  className,
  next,
  prev,
  goToPage,
  maxPagination,
  loading,
  error,
  paginationData,
  selectable,
  selectedRows,
  setSelectedRows,
  rowActions,
  ...rest
}: TableBodyProps<T> & TableSettingsProps<T>) {
  const { t } = useTranslation();

  const colSpan =
    columnSettings.length +
    Number(selectable ?? 0) +
    Number(rowActions ? 1 : 0);

  if (loading) {
    return (
      <tbody className="bg-white divide-y divide-gray-200 text-sm" {...rest}>
        <TableRow>
          <TableCell colSpan={colSpan} className="px-4">
            <div
              role="status"
              className="space-y-4 divide-y divide-gray-200 animate-pulse"
            >
              {Array.from({ length: paginationData.defaultPageSize }).map(
                (_, i) => (
                  <LoadingRow key={i} />
                )
              )}
              {/* <span className="sr-only">Loading...</span> */}
            </div>
          </TableCell>
        </TableRow>
      </tbody>
    );
  }
  if (error) {
    return (
      <tbody className="bg-white divide-y divide-gray-200 text-sm" {...rest}>
        <TableRow>
          <TableCell colSpan={columnSettings.length + 1} className="px-4">
            <div>{error.message}</div>
          </TableCell>
        </TableRow>
      </tbody>
    );
  }
  return (
    <tbody className="bg-white divide-y divide-gray-200 text-sm" {...rest}>
      {tableData &&
        tableData.map((row, rowNum) => {
          if (rowSettings && rowSettings.rowRender) {
            return rowSettings.rowRender(row, rowNum, rowActions ?? null);
          }
          return (
            <TableRow
              key={`row-${rowNum}`}
              data-cy="table-row"
              className={cn(
                selectedRows.includes(row['id']) ? 'bg-gray-50' : ''
              )}
            >
              {selectable && (
                <td className="relative px-7 sm:w-12 sm:px-6">
                  {selectedRows.includes(row['id']) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                  )}
                  <input
                    name={`select-row-${rowNum}`}
                    type="checkbox"
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    value={row['id']}
                    checked={selectedRows.includes(row['id'])}
                    onChange={(e) =>
                      setSelectedRows(
                        e.target.checked
                          ? [...selectedRows, row['id']]
                          : selectedRows.filter((s) => s !== row['id'])
                      )
                    }
                    data-cy={`select-row-${rowNum}`}
                  />
                </td>
              )}
              {columnSettings.map((cell, cellNum) => {
                return (
                  <TableCell
                    key={`cell-${rowNum}-${cellNum}`}
                    className={cn(
                      selectable && cellNum === 0 ? 'px-2' : 'px-4'
                    )}
                  >
                    {cell.cellRender
                      ? cell.cellRender(row, rowNum)
                      : row[cell.accessor]}
                  </TableCell>
                );
              })}
              {rowActions && (
                <TableRowActions<T> rowActions={rowActions} item={row} />
              )}
            </TableRow>
          );
        })}
      {tableData && tableData.length === 0 && (
        <TableRow>
          <TableCell colSpan={colSpan} className="px-4">
            <Text>{t('no_data_found')}</Text>
          </TableCell>
        </TableRow>
      )}
    </tbody>
  );
}

export default TableBody;
