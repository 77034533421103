import {
  DEFAULT_BATCH_ORDER,
  DEFAULT_COUNTRY_OF_ORGIN_ORDER,
  DEFAULT_CUSTOMER_REFERENCE_ORDER,
  DEFAULT_DOM_ORDER,
  DEFAULT_INCREMENTS,
  DEFAULT_INCREMENTS_ORDER,
  DEFAULT_PREFIX_ORDER,
  DEFAULT_PURCHASE_ORDER_NUMBER_ORDER,
  DEFAULT_SALES_ORDER_NUMBER_ORDER,
  DEFAULT_SUFFIX_ORDER,
  isDefined,
  SerialFormatComponent,
  SerialFormatComponentType,
} from '@scannable/common';

import {
  SerialFormatComponentParts,
  SerialFormatFormValues,
} from '../types/serial-format.types';

export function valuesToSerialFormatComponents(
  parts: SerialFormatFormValues & SerialFormatComponentParts
): SerialFormatComponent[] {
  return [
    parts.hasPrefix
      ? {
          type: SerialFormatComponentType.prefix,
          value: parts.prefix.value,
          order: parts.prefix.order ?? DEFAULT_PREFIX_ORDER,
        }
      : null,
    parts.hasDom
      ? {
          type: SerialFormatComponentType.dom,
          value: parts.dom.value,
          order: parts.dom.order ?? DEFAULT_DOM_ORDER,
        }
      : null,
    parts.hasBatch
      ? {
          type: SerialFormatComponentType.batch,
          order: parts.batch.order ?? DEFAULT_BATCH_ORDER,
        }
      : null,
    parts.hasCustomerReference
      ? {
          type: SerialFormatComponentType.customerReference,
          order:
            parts.customerReference.order ?? DEFAULT_CUSTOMER_REFERENCE_ORDER,
        }
      : null,
    parts.hasSalesOrderNumber
      ? {
          type: SerialFormatComponentType.salesOrderNumber,
          order:
            parts.salesOrderNumber.order ?? DEFAULT_SALES_ORDER_NUMBER_ORDER,
        }
      : null,
    parts.hasPurchaseOrderNumber
      ? {
          type: SerialFormatComponentType.purchaseOrderNumber,
          order:
            parts.purchaseOrderNumber.order ??
            DEFAULT_PURCHASE_ORDER_NUMBER_ORDER,
        }
      : null,
    parts.hasIncrements
      ? {
          type: SerialFormatComponentType.increments,
          value: (parts.increments.value ?? DEFAULT_INCREMENTS).toString(),
          order: parts.increments.order ?? DEFAULT_INCREMENTS_ORDER,
        }
      : null,
    parts.hasSuffix
      ? {
          type: SerialFormatComponentType.suffix,
          value: parts.suffix.value,
          order: parts.suffix.order ?? DEFAULT_SUFFIX_ORDER,
        }
      : null,
    parts.hasCountryOfOrgin
      ? {
          type: SerialFormatComponentType.countryOfOrigin,
          value: parts.countryOfOrigin.value,
          order: parts.countryOfOrigin.order ?? DEFAULT_COUNTRY_OF_ORGIN_ORDER,
        }
      : null,
  ]
    .filter(isDefined)
    .sort((a, b) => a.order - b.order);
}

export function serialFormatComponentDisplayName(
  type: SerialFormatComponentType
) {
  switch (type) {
    case SerialFormatComponentType.prefix:
      return 'Prefix';
    case SerialFormatComponentType.dom:
      return 'DOM';
    case SerialFormatComponentType.batch:
      return 'Batch';
    case SerialFormatComponentType.customerReference:
      return 'Customer Reference';
    case SerialFormatComponentType.salesOrderNumber:
      return 'Sales Order Number';
    case SerialFormatComponentType.purchaseOrderNumber:
      return 'Purchase Order Number';
    case SerialFormatComponentType.increments:
      return 'Increments';
    case SerialFormatComponentType.countryOfOrigin:
      return 'Country of Origin';
    case SerialFormatComponentType.suffix:
      return 'Suffix';
  }
}

export function valuesOrderedComponentsForDisplay(
  components: SerialFormatFormValues & SerialFormatComponentParts
) {
  return valuesToSerialFormatComponents(components).map((component) => ({
    ...component,
    label: serialFormatComponentDisplayName(component.type),
    sortable: ![
      SerialFormatComponentType.prefix,
      SerialFormatComponentType.suffix,
    ].includes(component.type),
  }));
}
