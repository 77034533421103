import { HTMLAttributes } from 'react';

import { cn } from '@scannable/common';

import Image from '../Image/Image';

/* eslint-disable-next-line */
export interface ScreenLoaderProps extends HTMLAttributes<HTMLDivElement> {}

export function ScreenLoader({ className }: ScreenLoaderProps) {
  return (
    <div
      style={{ borderTopColor: 'transparent' }}
      className={cn('animate-logo', className || '')}
    >
      <Image
        src="/static/scannable-icon.svg"
        alt="Scannable"
        className="object-contain object-center"
        width={80}
        height={80}
        priority
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default ScreenLoader;
