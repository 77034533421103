import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';

import { ADMIN_HOME_ROUTE, DASHBOARD_HOME_ROUTE } from '@scannable/common';

import { ScreenLoader } from '../../atoms';
import { useAuth } from '../../auth';
import { PageHeadOg, UserNavigation } from '../../organisms';

export interface GeneralLayoutProps {
  children: React.ReactNode;
  loading?: boolean;
  pageTitle?: string;
}

export function GeneralLayout({
  children,
  loading,
  pageTitle,
}: GeneralLayoutProps) {
  const { isLoggedIn } = useAuth();
  const homeLink = isLoggedIn ? ADMIN_HOME_ROUTE : DASHBOARD_HOME_ROUTE;
  const router = useRouter();
  const htmlTitle = `Scannable | ${
    pageTitle ?? 'Easily scan, track, and manage your safety equipment'
  }`;
  const image = '/static/scannable-og-image.png';
  const url = `https://app.scannable.io${router.asPath}`;
  if (loading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <PageHeadOg pageTitle={htmlTitle} image={image} url={url} />
        <ScreenLoader />
      </div>
    );
  }
  return (
    <div className="min-h-full">
      <PageHeadOg pageTitle={htmlTitle} image={image} url={url} />
      <Disclosure
        as="nav"
        className="border-b-1 flex border-gray-200 bg-gray-50"
      >
        <div className="relative flex h-16 w-full grow items-center justify-end">
          <div className="absolute flex w-full items-center pl-4 sm:justify-start md:justify-center md:pl-0">
            <Link href={homeLink} passHref>
              <Image
                src="/static/scannable-dashboard-logo.svg"
                alt="Scannable"
                className="object-contain object-center"
                width={190}
                height={50}
                priority
              />
            </Link>
          </div>
          <div>
            <div className="relative mr-4 flex h-full items-center justify-end">
              <UserNavigation />
            </div>
          </div>
        </div>
      </Disclosure>

      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">{children}</div>
        </div>
      </main>
    </div>
  );
}

export default GeneralLayout;
