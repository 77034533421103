import { format } from 'date-fns';
import { FormikProps } from 'formik';
import { ActionMeta, SingleValue } from 'react-select';

import { ucFirst } from '@scannable/common';
import { FormFieldOptionType } from '@scannable/frontend/types';

import FieldBuilder from '../../forms/FieldBuilder/FieldBuilder';
import { FormFieldConfig } from '../../types/forms.types';
import InputGroup from '../InputGroup/InputGroup';

export interface InputFieldGroupProps extends FormFieldConfig {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: FormikProps<any>;
  label?: string;
  description?: string;
  containerClassName?: string;
  wrapperClassName?: string;
  tooltip?: string;
  loading?: boolean;
  cols?: number;
  min?: number | string;
  max?: number | string;
  readOnly?: boolean;
  isClearable?: boolean;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  touched?: boolean;
  error?: string;
}

export function InputFieldGroup({
  name,
  type,
  label,
  value,
  description,
  options,
  onChange,
  onBlur,
  id,
  formik,
  placeholder,
  containerClassName,
  validationType,
  tooltip,
  loading,
  config,
  disabled,
  inline,
  cols,
  touched,
  error,
  ...props
}: InputFieldGroupProps) {
  const htmlId = ucFirst(id ?? name);
  const isMulti = config?.isMulti;

  let onChangeHandler = onChange ?? formik?.handleChange;
  const onBlurHandler = onBlur ?? formik?.handleBlur;
  switch (type) {
    case 'checkbox':
      onChangeHandler = formik
        ? (e) => {
            formik.setFieldValue(name, e.target.checked);
          }
        : onChange;
      break;
    case 'select':
    case 'react-select':
    case 'country':
      onChangeHandler = formik
        ? (value: unknown) => {
            if (isMulti && Array.isArray(value)) {
              formik.setFieldValue(
                name,
                value.map((v) => v.value)
              );
              return;
            }
            const singleValue = value as SingleValue<FormFieldOptionType>;
            formik.setFieldValue(name, singleValue ? singleValue.value : null);
            return;
          }
        : onChange;
      break;
    case 'createable-select':
      onChangeHandler = formik
        ? (
            selectedValue: SingleValue<FormFieldOptionType>,
            actionMeta: ActionMeta<FormFieldOptionType>
          ) => formik.setFieldValue(name, selectedValue?.value)
        : onChange;
      break;
    case 'image':
    case 'pdf':
    case 'recaptcha':
    case 'address':
      onChangeHandler = formik
        ? (v) => formik.setFieldValue(name, v)
        : onChange;
      break;
    case 'camera':
      onChangeHandler = formik
        ? (v) => {
            const value = typeof v === 'object' ? '' : v;
            formik.setFieldValue(name, value);
          }
        : onChange;
      break;
    case 'radio':
      onChangeHandler = formik
        ? (v) => {
            const val = v.currentTarget.value;
            if (val === 'true') {
              return formik.setFieldValue(name, true);
            }
            if (val === 'false') {
              return formik.setFieldValue(name, false);
            }
            if (onChange) {
              return onChange(v);
            }
            return formik.setFieldValue(name, val);
          }
        : onChange;
      break;
    case 'date':
      onChangeHandler = formik
        ? (val: Date | null) => {
            formik.setFieldValue(
              name,
              val ? format(val, config?.dateOutputFormat ?? 'yyyy-MM-dd') : null
            );
          }
        : onChange;
      break;
    case 'unitvalue':
      onChangeHandler = formik
        ? (v: { unit?: string; value?: string }) => {
            if (v.value) {
              formik.setFieldValue(`${name}.value`, v.value);
            }
            if (v.unit) {
              formik.setFieldValue(`${name}.unit`, v.unit);
            }
          }
        : onChange;
      break;
    default:
      break;
  }
  return (
    <InputGroup
      className={containerClassName}
      htmlFor={htmlId}
      name={name}
      description={description}
      label={label}
      touched={touched ?? formik?.touched[name]}
      error={error ?? formik?.errors[name]}
      tooltip={tooltip}
      type={type}
    >
      {!loading ? (
        <FieldBuilder
          id={htmlId}
          name={name}
          type={type}
          label={label}
          placeholder={placeholder}
          value={value ?? formik?.values[name]}
          options={options}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          config={config}
          disabled={disabled}
          cols={cols}
          inline={inline}
          onClear={() => formik?.setFieldValue(name, null)}
          loading={formik?.isSubmitting}
          {...props}
        />
      ) : (
        <div className="bg-slate-200 animate-pulse rounded h-9" />
      )}
    </InputGroup>
  );
}

export default InputFieldGroup;
