import { useMutation } from '@apollo/client';

import { dateFormat } from '@scannable/common';
import { CREATE_API_KEY, PAGINATED_API_KEYS } from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { useTable } from '../../hooks';
import { copyText, resolveMutation } from '../../lib/lib';
import { Modal, useModal } from '../../modals';
import { Input, ModalFooter } from '../../molecules';
import { Table } from '../../tables';

type ManageApiKeysFormProps = {
  organisationId: number;
};

export function ManageApiKeysForm({ organisationId }: ManageApiKeysFormProps) {
  const [createApiKey] = useMutation(CREATE_API_KEY);
  const { showModal, hideModal } = useModal();
  const { reload } = useTable();

  return (
    <Table
      title="API Keys"
      icon="inspect"
      query={PAGINATED_API_KEYS}
      variables={[
        {
          organisationId: {
            equals: Number(organisationId),
          },
        },
      ]}
      tableActions={[
        {
          label: 'Create API Key',
          callback: () =>
            resolveMutation(
              createApiKey({
                variables: {
                  organisationId,
                },
                onCompleted: (data) => {
                  showModal(
                    <Modal
                      title="API Key created"
                      size="sm"
                      overflowHidden={false}
                    >
                      <div className="flex flex-col gap-2 p-5">
                        <p>Your new API key is:</p>
                        <span className="text-sm text-gray-500">
                          This is the only time this key will be shown, so make
                          sure to copy it now.
                        </span>
                        <Input
                          id="apiKey"
                          name="apiKey"
                          type="text"
                          value={data.createApiKey.apiKey}
                        />
                        <div className="flex gap-2">
                          <Button
                            onClick={() => {
                              copyText(data.createApiKey.apiKey);
                            }}
                          >
                            Copy to clipboard
                          </Button>
                        </div>
                      </div>
                      <ModalFooter
                        onClose={() => {
                          reload();
                          hideModal();
                        }}
                      />
                    </Modal>
                  );
                },
              })
            ),
        },
      ]}
      columnSettings={[
        {
          label: 'Created At',
          accessor: 'createdAt',
          cellRender: (row) => {
            return dateFormat(row.createdAt);
          },
        },
        {
          label: 'API Token',
          accessor: 'uuid',
          cellRender: (row) => {
            return row.uuid.replace(/./g, '*');
          },
        },
      ]}
      initialState={{
        useAdvancedPagination: true,
        paginationEnabled: true,
      }}
    />
  );
}

export default ManageApiKeysForm;
