import { useEffect, useRef, useState } from 'react';

import { Button, Icon } from '../../atoms';
import BarcodeModal from '../../modals/BarcodeModal/BarcodeModal';
import { useModal } from '../../modals/ModalContext/ModalContext';

export interface CameraInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onScan?: (value: string) => void;
}

export function CameraInput({
  containerClassName,
  name,
  onChange,
  onScan,
  value,
  ...props
}: CameraInputProps) {
  const cameraRef = useRef<HTMLInputElement>(null);
  const { showModal } = useModal();
  const [search, setSearch] = useState<
    React.InputHTMLAttributes<HTMLInputElement>['value'] | null
  >(null);

  useEffect(() => {
    if (search && value !== search) {
      onScan && onScan(search as string);
    }
  }, [onScan, search, value]);

  useEffect(() => {
    if (value) {
      setSearch(value);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  return (
    <div className="relative mr-2 w-full text-gray-600">
      <input
        className="block w-full rounded-md border-gray-300 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        name={name}
        ref={cameraRef}
        placeholder="Search"
        onChange={(e) => {
          setSearch(e.target.value);
          handleChange(e);
        }}
        value={value}
        {...props}
      />
      <div className="absolute top-1/2 right-1 -translate-y-1/2 transform cursor-pointer">
        <Button
          color="white"
          type="button"
          size="xs"
          onClick={() => {
            showModal(<BarcodeModal setCameraValue={setSearch} />);
          }}
        >
          <div className="flex flex-row justify-center items-center">
            <span className="text-sm">Scan</span>
            <Icon
              name="target"
              className="ml-2 h-2 w-2 text-gray-800"
              height={16}
              width={16}
            />
          </div>
        </Button>
      </div>
    </div>
  );
}

export default CameraInput;
