import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { SubscriptionType } from '@prisma/client';

import { AlertUnit, alertUnits, InspectionFrequency } from '@scannable/common';
import {
  ALERT_TEMPLATE,
  ALERT_TEMPLATES,
  CREATE_ALERT_TEMPLATE,
  CREATE_ALERTS,
  DELETE_ALERT_TEMPLATE,
  UPDATE_ALERT_TEMPLATE,
} from '@scannable/graphql-queries';

import { useAuth } from '../../auth/AuthContext/AuthContext';

export function useAlert() {
  const { subscriptionType } = useAuth();

  const { data: alertTemplates } = useQuery(ALERT_TEMPLATES, {
    skip: !subscriptionType || subscriptionType === SubscriptionType.ACCESS,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [getAlertTemplate] = useLazyQuery(ALERT_TEMPLATE);

  const [addAlertTemplate] = useMutation(CREATE_ALERT_TEMPLATE, {
    refetchQueries: [InspectionFrequency.FindManyAlertTemplates],
  });

  const [updateAlertTemplate] = useMutation(UPDATE_ALERT_TEMPLATE, {
    refetchQueries: [InspectionFrequency.FindManyAlertTemplates],
  });

  const [deleteAlertTemplate] = useMutation(DELETE_ALERT_TEMPLATE);

  const [addAlerts] = useMutation(CREATE_ALERTS);

  function alertUnitFromValue(alertUnitValue: AlertUnit['value']): AlertUnit {
    return alertUnits.find(
      (u) => u.value === alertUnitValue
    ) as typeof alertUnits[number];
  }

  function alertUnitFromSchedule(
    quantity: AlertUnit['schedule']['quantity'] | undefined,
    unit: AlertUnit['schedule']['unit'] | undefined
  ): AlertUnit {
    const alertUnit = alertUnits.find(
      (u) => u.schedule.unit === unit && u.schedule.quantity === quantity
    ) as typeof alertUnits[number];
    return alertUnit;
  }

  return {
    getAlertTemplate,
    addAlertTemplate,
    updateAlertTemplate,
    deleteAlertTemplate,
    addAlerts,
    alertUnits,
    alertUnitFromValue,
    alertUnitFromSchedule,
    alertTemplateOptions: alertTemplates?.organisationAlertTemplates?.map(
      /* eslint-disable-next-line */
      (template: any) => ({
        label: template.name,
        value: template.id,
      })
    ),
  };
}
