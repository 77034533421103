import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { SerialFormatOption, serialFormatParts } from '@scannable/common';
import { RequiredSerialFormatValues } from '@scannable/frontend/common';
import {
  RootState,
  setSerialPurchaseOrderValues,
} from '@scannable/frontend/store';
import {
  SerialisationFormValues,
  SerialPurchaseOrderValues,
} from '@scannable/frontend/types';

import { Button } from '../../atoms';
import { useCustomerReferenceTag, useTranslation } from '../../hooks';
import {
  DescriptionList,
  DescriptionListItem,
  InputFieldGroup,
} from '../../molecules';
import {
  DateSelectionDescriptionListItem,
  SerialFormatDescriptionListItem,
} from '../../organisms';
import { batchValidationSchema } from '../../validation/batch-validation.schema';
import Form from '../Form/Form';

export interface BatchSerialisationFormProps {
  initialSerialFormatId: number;
  availableSerialFormats: SerialFormatOption[];
}

export function BatchSerialisationForm({
  initialSerialFormatId,
  availableSerialFormats,
}: BatchSerialisationFormProps) {
  const { t } = useTranslation();
  const { customerOptions } = useCustomerReferenceTag();
  const dispatch = useDispatch();

  // we have to manage the selected serial format external to formik so we can manage validation
  const [selectedSerialFormatId, setSelectedSerialFormatId] = useState<number>(
    initialSerialFormatId
  );

  const serialiseProductsState = useSelector(
    (state: RootState) => state.serialisation
  );

  const handleBatchSubmit = (values: SerialPurchaseOrderValues) => {
    dispatch(setSerialPurchaseOrderValues({ ...values }));
  };

  const formik = useFormik({
    initialValues: {
      hasCountryOfOrgin: false,
      hasPurchaseOrderNumber: false,
      hasBatch: false,
      hasCustomerReference: false,
      hasSalesOrderNumber: false,
      hasDom: false,
      purchaseOrderNumber: '',
      salesOrderNumber: '',
      customerReference: '',
      batchNumber: '',
      dom: '',
    },
    validationSchema: batchValidationSchema,
    onSubmit: async ({
      purchaseOrderNumber,
      salesOrderNumber,
      batchNumber,
      customerReference,
      dom,
    }: SerialisationFormValues & RequiredSerialFormatValues) => {
      handleBatchSubmit({
        serialFormatId: selectedSerialFormatId,
        purchaseOrderNumber,
        salesOrderNumber,
        batchNumber,
        customerReference,
        dom,
      });
    },
  });

  useEffect(() => {
    if (selectedSerialFormatId) {
      const selectedSerialFormat = availableSerialFormats?.find(
        (f) => f.id === selectedSerialFormatId
      );
      if (selectedSerialFormat) {
        const {
          hasPurchaseOrderNumber,
          hasSalesOrderNumber,
          hasCustomerReference,
          hasBatch,
          hasDom,
        } = serialFormatParts(selectedSerialFormat?.format ?? null);
        formik.setValues({
          ...formik.values,
          hasPurchaseOrderNumber,
          hasSalesOrderNumber,
          hasCustomerReference,
          hasBatch,
          hasDom,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSerialFormats, selectedSerialFormatId]);

  return (
    <Form formik={formik} data-cy="format-references">
      {!serialiseProductsState.serialPurchaseOrder && availableSerialFormats && (
        <DescriptionList title={t('reference_numbers_serial_format')}>
          <div>
            <SerialFormatDescriptionListItem
              availableSerialFormats={availableSerialFormats}
              onChange={(selectedValue) => {
                if (selectedValue) {
                  setSelectedSerialFormatId(selectedValue);
                }
              }}
              value={selectedSerialFormatId ?? initialSerialFormatId}
            />

            <DescriptionListItem title={t('create_serials_purchase_order')}>
              <InputFieldGroup
                placeholder={t('enter_ref_number')}
                name="purchaseOrderNumber"
                type="text"
                formik={formik}
              />
            </DescriptionListItem>
            <DescriptionListItem title={t('sales_order_number')}>
              <InputFieldGroup
                placeholder={t('enter_sales_order_number')}
                name="salesOrderNumber"
                type="text"
                formik={formik}
              />
            </DescriptionListItem>
            <DescriptionListItem title={t('customer_reference')}>
              <InputFieldGroup
                name="customerReference"
                type="createable-select"
                formik={formik}
                options={customerOptions}
              />
            </DescriptionListItem>

            <DescriptionListItem title={t('create_serials_batch_num')}>
              <InputFieldGroup
                placeholder={t('enter_batch_number')}
                name="batchNumber"
                type="text"
                formik={formik}
              />
            </DescriptionListItem>
            <DateSelectionDescriptionListItem
              formik={formik}
              fieldName="dom"
              label="Select a date of manufacture to use as part of serial creation"
            />
          </div>

          <DescriptionListItem>
            <div className="flex justify-end">
              <Button
                label={t('save_reference_serial_format_btn')}
                type="submit"
                disabled={formik.isSubmitting}
              />
            </div>
          </DescriptionListItem>
        </DescriptionList>
      )}
    </Form>
  );
}

export default BatchSerialisationForm;
