import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { removeSkuByIndex, RootState, store } from '@scannable/frontend/store';
import { Search } from '@scannable/frontend/types';

import { Button } from '../../atoms';
import { useTranslation } from '../../hooks';
import DataGrid, { DataGridCell, DataGridRow } from '../DataGrid/DataGrid';

interface AssembliesToCreateTableProps {
  assembly: Search;
}
export function AssembliesToCreateTable({
  assembly,
}: AssembliesToCreateTableProps) {
  const { skus } = useSelector((state: RootState) => state.serialisation);
  const handleRemoveProductVariation = useCallback((index: number) => {
    store.dispatch(removeSkuByIndex(index));
  }, []);
  const { t } = useTranslation();
  return (
    <DataGrid titles={[t('products_name'), 'SKU', '']}>
      {skus.map((assemblyToCreate, index) => (
        <DataGridRow key={index}>
          <DataGridCell
            image={{
              url: assembly.image?.uri || '',
              alt: assembly.info?.name || '',
            }}
            data-cy="assembly-name"
          >
            {assembly.info?.name}
          </DataGridCell>
          <DataGridCell data-cy="assembly-code">
            {assembly.info?.code}
          </DataGridCell>
          <DataGridCell className="flex flex-col p-6 md:flex-row">
            <Button
              color="white"
              label={t('delete')}
              className="mb-4 flex w-auto flex-col text-gray-700 md:ml-2"
              onClick={() => {
                handleRemoveProductVariation(index);
              }}
            />
          </DataGridCell>
        </DataGridRow>
      ))}
    </DataGrid>
  );
}
export default AssembliesToCreateTable;
