import { GroupListItem, SpecificationGroupHeading } from '../../atoms';

export interface ProductSerialProps {
  serialisedProduct: {
    serialNumber: string;
  };
}

export function ProductSerial({ serialisedProduct }: ProductSerialProps) {
  return (
    <div className="bg-white override-width rounded-lg">
      <div className="py-6">
        <div className="max-w-2xl mx-auto pt-10 pb-0 lg:max-w-7xl lg:pt-16 ">
          {/* Options */}
          <div className="mt-4 lg:row-span-3">
            <SpecificationGroupHeading title="serial" />
            <div className="space-y-6 lg:px-8 sm:px-6 px-5">
              <dl>
                <GroupListItem name={serialisedProduct.serialNumber} />
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductSerial;
