import { ChangeEvent } from 'react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';

import { LoadingIcon } from '../../atoms';

export interface UploadProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  loading: boolean;
  uploadHandler: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

export function Upload({
  name,
  uploadHandler,
  className,
  label = 'Upload a file',
  loading,
  accept = 'image/*',
  ...props
}: UploadProps) {
  return (
    <>
      <input
        type="file"
        id={`${name}_uploadref`}
        name={name}
        className="hidden absolute z-[-1] overflow-hidden"
        onChange={uploadHandler}
        multiple={false}
        accept={accept}
        {...props}
      />
      <label
        htmlFor={`${name}_uploadref`}
        className="mt-3 w-full flex
    px-4 py-2 border-transparent shadow-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm cursor-pointer"
      >
        {!loading ? (
          <>
            <ArrowUpTrayIcon className="h-5 w-5 mr-2" />
            <span>{label}</span>
          </>
        ) : (
          <div className="relative h-5 w-24">
            <LoadingIcon size="sm" />
          </div>
        )}
      </label>
    </>
  );
}

export default Upload;
