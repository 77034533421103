import { cn } from '@scannable/common';

export interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  children?: React.ReactNode;
  colour?:
    | 'gray'
    | 'red'
    | 'yellow'
    | 'green'
    | 'blue'
    | 'indigo'
    | 'purple'
    | 'pink';
  size?: 'sm' | 'lg';
}

export function Badge({
  children,
  size = 'sm',
  colour = 'green',
  className,
  ...props
}: BadgeProps) {
  return (
    <span
      className={cn(
        'inline-flex items-center py-0.5 rounded-full font-medium',
        `bg-${colour}-100 text-${colour}-800`,
        size === 'sm' ? 'px-2.5 text-xs' : 'px-3 text-sm',
        className ?? ''
      )}
      {...props}
    >
      {children}
    </span>
  );
}

export default Badge;
