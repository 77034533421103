import { classNames } from '@scannable/common';

import { useTranslation } from '../../hooks';

export interface PaginationProps {
  next: () => void;
  prev: () => void;
  maxPagination: boolean;
}

export function Pagination({ next, prev, maxPagination }: PaginationProps) {
  const { t } = useTranslation();
  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between sm:px-6"
      aria-label="Pagination"
    >
      <div className="flex-1 flex justify-between sm:justify-end">
        <span
          onClick={() => prev()}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
        >
          {t('previous_btn')}
        </span>
        <span
          onClick={() => (!maxPagination ? next() : null)}
          className={classNames(
            !maxPagination ? 'cursor-pointer' : 'cursor-not-allowed',
            'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
          )}
        >
          {t('next_btn')}
        </span>
      </div>
    </nav>
  );
}

export default Pagination;
