import { ProductDataType } from '@prisma/client';

import { dateFormat, lastInspectionFormat } from '@scannable/common';
import {
  ProductScreenData,
  ViewProductComponent,
  ViewProductLastInspection,
} from '@scannable/frontend/common';

import { GroupListItem, ProductPageDataTab } from '../../atoms';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { GroupsList } from '../../molecules/GroupsList/GroupsList';
import { ProductPageComponent } from '../../molecules/ProductPageComponent/ProductPageComponent';
import NfcListItem from '../NfcListItem/NfcListItem';
import { PaginatedHistory } from './PaginatedHistory';

export interface ProductPageDataTabsProps extends ProductScreenData {
  tabNumber: number;
  manufacturer?: string | null;
  code?: string | null;
  ean?: string | null;
  lastInspection?: ViewProductLastInspection;
  serialisedProductId?: number;
  showPrivateData?: boolean;
  productGroup?: string | null;
  hideEmptyData?: boolean;
  openComponentsInModal: boolean;
  openComponentsInPanel?: boolean;
  includePrivateData?: boolean;
  isPublicUrl?: boolean;
  externalId?: string | null;
  onOpenComponent?: (
    item: Pick<
      ViewProductComponent,
      'productVariationId' | 'serialisedProductId'
    >
  ) => void;
}

export function ProductPageDataTabs({
  isPublicUrl,
  nfcTag,
  externalId,
  tabNumber,
  hasNfc,
  serialNumber,
  batchNumber,
  name,
  manufacturer,
  code,
  ean,
  lastInspection,
  assignedUser,
  expiryDate,
  dom,
  dofu,
  ownedByName,
  proofOfPurchase,
  components,
  history,
  serialisedProductId,
  showPrivateData,
  productGroup,
  productData,
  hideEmptyData,
  onOpenComponent,
  openComponentsInModal,
  openComponentsInPanel,
  includePrivateData,
  nextInspectionAt,
  nfcTags,
}: ProductPageDataTabsProps) {
  const { t } = useTranslation();

  return (
    <div>
      {/* <--- Item tab data ---> */}
      <ProductPageDataTab tabIndex={0} selectedTabNumber={tabNumber}>
        <dl className="divide-y divide-gray-100">
          {serialisedProductId && (
            <NfcListItem
              nfcTag={nfcTag}
              hasNfc={Boolean(hasNfc)}
              serialisedProductId={serialisedProductId}
              isPublicUrl={isPublicUrl}
              nfcTags={nfcTags}
            />
          )}
          {serialNumber && (
            <GroupListItem name={t('serial_number')} unit={serialNumber} />
          )}
          {name && <GroupListItem name={t('name')} unit={name} />}
          <GroupListItem name={t('manufacturer')} unit={manufacturer} />
          {code && <GroupListItem name={t('part_number')} unit={code} />}
          {ean && <GroupListItem name="EAN" unit={ean} />}
          {batchNumber && (
            <GroupListItem
              name={t('create_serials_batch_num')}
              unit={batchNumber}
            />
          )}
          {lastInspection && (
            <GroupListItem
              name={t('last_inspection')}
              value={lastInspectionFormat(lastInspection)}
            />
          )}
          {nextInspectionAt && (
            <GroupListItem
              name={t('next_due')}
              value={dateFormat(nextInspectionAt)}
            />
          )}
          {dom && <GroupListItem name={t('dom')} value={dateFormat(dom)} />}
          {dofu && <GroupListItem name={t('dofu')} value={dateFormat(dofu)} />}
          {expiryDate && (
            <GroupListItem
              name={t('expiry_date')}
              value={dateFormat(expiryDate)}
            />
          )}
          {ownedByName && <GroupListItem name="Owned by" value={ownedByName} />}
          {assignedUser && showPrivateData && (
            <GroupListItem name="Assigned to" value={`${assignedUser}`} />
          )}
          {proofOfPurchase && showPrivateData && (
            <GroupListItem
              name={t('purchase_order')}
              value={`${proofOfPurchase}`}
            />
          )}
          {externalId && showPrivateData && (
            <GroupListItem name={t('external_id')} value={externalId} />
          )}
        </dl>
      </ProductPageDataTab>

      {/* <--- Specifications tab data ---> */}
      <ProductPageDataTab tabIndex={1} selectedTabNumber={tabNumber}>
        <dl className="divide-y divide-gray-100">
          <GroupListItem
            name={t('kit_list_table_category')}
            value={productGroup}
          />
          {productData
            ?.filter(
              (item) =>
                ![ProductDataType.IMAGE as string].includes(
                  item.productDataType as string
                )
            )
            .filter(
              // hide empty data except for pdf and certifications. todo when we have time to refactor this, it should be probably filtered in `makeProductScreenData`
              (item) =>
                !(
                  hideEmptyData &&
                  !item.value &&
                  ![
                    ProductDataType.PDF,
                    ProductDataType.CERTIFICATION as string,
                  ].includes(item.productDataType as string)
                )
            )
            .filter(
              (item) =>
                item.value ||
                Number(item.certifications?.length) > 0 ||
                Number(item.pdfs?.length) > 0
            )
            .sort((a, b) => {
              const orderA =
                a.order !== null ? a.order : Number.MAX_SAFE_INTEGER;
              const orderB =
                b.order !== null ? b.order : Number.MAX_SAFE_INTEGER;

              return orderA - orderB;
            })
            .map((item, i) => (
              <GroupListItem key={`${item.id}-${i}`} {...item} />
            ))}
        </dl>
      </ProductPageDataTab>

      {/* <--- Components tab data ---> */}
      {components && Array.isArray(components) && (
        <ProductPageDataTab tabIndex={2} selectedTabNumber={tabNumber}>
          <div className="divide-y divide-gray-100">
            {components.map((comp, i) => (
              <ProductPageComponent
                key={`${comp.productVariationId}_${i}`}
                component={comp}
                onOpenComponent={
                  openComponentsInModal || openComponentsInPanel
                    ? onOpenComponent
                    : undefined
                }
              />
            ))}
          </div>
        </ProductPageDataTab>
      )}

      {/* <--- Group tab ---> */}
      {serialisedProductId && showPrivateData && (
        <ProductPageDataTab tabIndex={3} selectedTabNumber={tabNumber}>
          <GroupsList id={serialisedProductId} />
        </ProductPageDataTab>
      )}
      {/* <--- History tab ---> */}
      {serialisedProductId && (
        <ProductPageDataTab tabIndex={4} selectedTabNumber={tabNumber}>
          <PaginatedHistory
            includePrivateData={includePrivateData}
            itemId={serialisedProductId}
          />
        </ProductPageDataTab>
      )}
    </div>
  );
}

export default ProductPageDataTabs;
