import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { CREATE_PASSWORD_RESET } from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Form from '../Form/Form';

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('A valid email address is required'),
});

export interface ForgotPasswordFormValues {
  email: string;
}

export function ForgotPasswordForm() {
  const [createPasswordReset] = useMutation(CREATE_PASSWORD_RESET);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: async (
      values: ForgotPasswordFormValues,
      actions: FormikHelpers<ForgotPasswordFormValues>
    ) => {
      const result = await resolveMutation(
        createPasswordReset({
          variables: {
            data: {
              email: values.email,
            },
          },
        }),
        {
          successMessage: 'Password reset email sent. Check your inbox!',
        }
      );

      if (result.ok) {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    },
  });
  return (
    <Form formik={formik}>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <InputFieldGroup
            {...{
              type: 'email',
              name: 'email',
              placeholder: 'Email address',
            }}
            formik={formik}
          />
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <Button
            label="Send a password reset email"
            type="submit"
            loading={formik.isSubmitting}
          />
        </div>
      </div>
    </Form>
  );
}

export default ForgotPasswordForm;
