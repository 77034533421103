import { WatchQueryFetchPolicy } from '@apollo/client';

import { dateFormat, getNextDueMessage } from '@scannable/common';
import { NullsOrder, SortOrder } from '@scannable/frontend/common';
import { PAGINATED_INVENTORY_QUERY } from '@scannable/graphql-queries';

import { IconType, ResizedImage } from '../../atoms';
import { useTranslation } from '../../hooks';
import { InspectionStatusBadge } from '../../molecules';
import { LastInspection } from '../../organisms';
import { useSidePanel } from '../../side-panels';
import { InventoryData } from '../../types/table-data.types';
import { TableRowAction } from '../../types/table.types';
import { Table } from '../Table/Table';
import { InspectionProductCategoryFilter } from './InspectionProductCategoryFilter';

export interface InspectionsTableProps {
  title?: string;
  icon?: IconType;
  skip?: boolean;
  variables?: Record<string, unknown>[];
  rowActions?: TableRowAction<InventoryData>[];
  fetchPolicy?: WatchQueryFetchPolicy;
}

export function InspectionsTable({
  title,
  icon,
  skip,
  variables,
  rowActions,
  fetchPolicy = 'cache-and-network',
}: InspectionsTableProps) {
  const { t } = useTranslation();
  const { showItemSidePanel } = useSidePanel();

  return (
    <Table<typeof PAGINATED_INVENTORY_QUERY>
      uniqueTableKey="inspections"
      title={title}
      icon={icon}
      rowActions={rowActions}
      query={PAGINATED_INVENTORY_QUERY}
      columnSettings={[
        {
          label: t('image'),
          accessor: 'id',
          cellRender: (row) => {
            return (
              <div
                className="h-10 w-10 flex-shrink-0 relative cursor-pointer"
                onClick={() => {
                  showItemSidePanel({
                    serialisedProductId: row.id,
                    showOptionsButton: true,
                    showBanner: false,
                  });
                }}
              >
                <ResizedImage
                  src={row.image?.uri || ''}
                  alt={row.tierOne?.name || ''}
                  width={48}
                  height={48}
                  fit="fill"
                  className="object-contain object-center"
                />
              </div>
            );
          },
        },
        {
          label: t('product'),
          accessor: 'tierOne',
          cellRender: (row) => (
            <div
              onClick={() => {
                showItemSidePanel({
                  serialisedProductId: row.id,
                  showOptionsButton: true,
                  showBanner: false,
                });
              }}
              className="cursor-pointer"
            >
              <span className="block text-sm font-medium text-gray-900">
                {row.tierOne?.name ?? ''}
              </span>
              <span className="text-xs text-gray-500">
                Serial #: {row.serialNumber}
              </span>
            </div>
          ),
        },
        {
          label: t('product_group'),
          accessor: 'tierOne',
          cellRender: ({ tierOne }) => (
            <span>{tierOne?.productGroupName ?? ''}</span>
          ),
        },
        {
          label: t('manufacturer'),
          accessor: 'tierOne',
          cellRender: ({ tierOne }) => (
            <span>{tierOne?.manufacturerName ?? ''}</span>
          ),
        },
        {
          label: t('result'),
          accessor: 'lastInspectionAt',
          cellRender: (row) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {row.tierOne ? (
                <InspectionStatusBadge
                  status={row.tierOne.lastInspection?.status}
                  expiryDate={row.tierOne.expiryDate}
                />
              ) : null}
            </>
          ),
        },
        {
          label: t('status'),
          accessor: 'lastInspectionAt',
          cellRender: ({ tierOne }) => (
            <LastInspection
              data={tierOne}
              inspectionCount={tierOne?.inspectionCount || 0}
            />
          ),
        },
        {
          label: 'next due',
          accessor: 'nextInspectionAt',
          canSort: {
            nulls: NullsOrder.last,
            sort: true,
          },
          cellRender: ({ tierOne }) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {tierOne?.nextInspection ? (
                <>
                  <div className="text-sm font-medium text-gray-900">
                    {dateFormat(tierOne.nextInspectionAt)}
                  </div>
                  <div className="text-xs text-gray-500">
                    {getNextDueMessage(
                      new Date(tierOne?.nextInspectionAt ?? '')
                    )}
                  </div>
                </>
              ) : null}
            </>
          ),
        },
      ]}
      fetchPolicy={fetchPolicy}
      initialState={{
        paginationEnabled: true,
        useAdvancedPagination: true,
        pageSize: 25,
        orderBy: {
          lastInspectionAt: {
            sort: SortOrder.desc,
          },
        },
      }}
      filters={[
        {
          filterName: 'inspectionProductGroup',
          render: (props) => <InspectionProductCategoryFilter {...props} />,
        },
      ]}
      searchFields={[
        {
          field: 'serialNumber',
          label: t('serial_number').toLowerCase(),
        },
      ]}
      skip={skip}
      variables={[
        {
          inspections: {
            some: {},
          },
        },
      ]}
    />
  );
}

export default InspectionsTable;
