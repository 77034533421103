import { useCallback } from 'react';

import { h } from '@scannable/frontend/common';

import { useTranslation } from '../../hooks';
import { FilterSidePanel, useSidePanel } from '../../side-panels';
import { TableFilter } from '../../types/table.types';

export function useShowFilters(filters: TableFilter[], tableName: string) {
  const { t } = useTranslation();
  const { showSidePanel } = useSidePanel();
  const showFilters = useCallback(
    () =>
      showSidePanel(
        <FilterSidePanel filters={filters} tableName={tableName} />,
        {
          title: h(t('tables.apply_filters')),
        }
      ),
    [showSidePanel, filters, tableName, t]
  );

  return showFilters;
}
