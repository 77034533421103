import { ProductType } from '@prisma/client';

import { filterByOr } from '@scannable/frontend/common';
import { Search } from '@scannable/frontend/types';

import { Button, ResizedImage } from '../../atoms';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import SearchInput from '../SearchInput/SearchInput';

export interface AddComponentsInputProps {
  existingComponents: Search[];
  removeComponent: (componentId: number) => void;
  addComponent: (component: Search) => void;
  showComposites?: boolean;
}

export function AddComponentsInput({
  existingComponents,
  removeComponent,
  addComponent,
  showComposites = false,
}: AddComponentsInputProps) {
  const { t } = useTranslation();
  const productTypesToShow = [
    ProductType.COMPONENT,
    ProductType.COMPOSITE,
  ].filter((t) => (showComposites ? t : t !== ProductType.COMPOSITE));
  return (
    <>
      <div className="grid grid-cols-2 gap-x-4">
        {existingComponents.map((child, i) => (
          <div
            key={`${child.id}_${i}`}
            className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 mb-4 shadow-sm hover:border-gray-400"
          >
            <div className="flex w-full items-center justify-between space-x-6 ">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">
                    {child.info?.name}
                  </h3>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                  {t('product_code')} {child.info?.code}
                </p>
              </div>
              <div className="h-12 w-12 flex-shrink-0 relative">
                <ResizedImage
                  src={child.image?.uri || ''}
                  alt={child.info?.name || ''}
                  className="rounded-md object-contain object-center"
                  width={48}
                  height={48}
                  fit="fill"
                />
              </div>
            </div>
            <div className="pt-3">
              <Button
                type="button"
                size="xs"
                color="danger"
                onClick={() => {
                  if (child.productVariationId) {
                    removeComponent(child.productVariationId);
                  }
                }}
              >
                {t('remove')}
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div>
        <SearchInput
          name="productVariationId"
          addToCallback={addComponent}
          resetAfterCallback
          filters={[
            {
              key: 'productType',
              value: filterByOr('productType', productTypesToShow),
              custom: true,
            },
          ]}
        />
      </div>
    </>
  );
}

export default AddComponentsInput;
