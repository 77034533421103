import { ProductDataType } from '@prisma/client';

import { formatUnit, getYoutubeId } from '@scannable/common';
import { TranslateFn } from '@scannable/frontend/translations';

import { UseSkuOrItemData } from '../../hooks/useSkuOrItemScreenQuery/useSkuOrItemScreenQuery';
import {
  ItemData,
  ViewProductSpecificationItem,
} from './makeProductScreenData';

// this is a new implementation, designed to be work with more simplier 'new shape data' from the api
export const makeItemSpecificationTabData = ({
  item,
  t,
}: {
  item: UseSkuOrItemData;
  t: TranslateFn;
}): ViewProductSpecificationItem[] => {
  if (!item) {
    return [];
  }
  const baseSpecs = [
    {
      name: t('kit_list_table_category'),
      value: item?.info?.productGroupName ?? '',
    },
  ] as ViewProductSpecificationItem[];

  const data = item?.data?.data as ItemData[];
  const restSpecs = data
    ?.filter(
      (item) =>
        ![ProductDataType.IMAGE as string].includes(
          item.productDataType as string
        )
    )
    .filter((item) => {
      if (item.pdfs && item.pdfs.length > 0) {
        return true;
      }
      if (item.certifications && item.certifications.length > 0) {
        return true;
      }
      return item.value;
    })
    .map((item) => {
      const { value, unit, pdfs, certifications, productDataType, name } = item;

      if (productDataType === ProductDataType.URL) {
        return {
          name,
          webLink: {
            url: value ?? '',
          },
        };
      }

      if (productDataType === ProductDataType.VIDEO_EMBED) {
        const videoUrl = value ?? '';
        const embedId = getYoutubeId(videoUrl);
        return {
          name,
          video: {
            url: embedId
              ? `https://www.youtube.com/embed/${embedId}`
              : videoUrl,
            isYoutube: !!embedId,
          },
        };
      }

      if (pdfs?.length) {
        return {
          name,
          pdfs,
        };
      }

      if (certifications?.length) {
        return {
          name,
          certifications,
        };
      }

      const formattedValue = `${value || ''} ${formatUnit(unit) || ''}`;
      return {
        name,
        value: formattedValue,
      };
    }) as ViewProductSpecificationItem[];
  if (restSpecs && restSpecs.length > 0) {
    return baseSpecs.concat(restSpecs);
  }

  return baseSpecs;
};
