import { ItemAction, proModules, Role } from '@scannable/common';
import { InspectionTypeLabel, SortOrder } from '@scannable/frontend/common';
import { ALL_INSPECTION_FREQUENCIES } from '@scannable/graphql-queries';

import { IconType } from '../../atoms';
import { useModuleGuard, useRoleGuard } from '../../auth';
import { useAlert, useTable, useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import {
  ConfirmationModal,
  InspectionFrequencyModal,
  useModal,
} from '../../modals';
import { TableRowType } from '../../types/table.types';
import { Table } from '../Table/Table';

export interface InspectionFrequenciesTableProps {
  createTitle: string;
  icon: IconType;
  title?: string;
}

export function InspectionFrequenciesTable({
  createTitle,
  icon,
  title,
}: InspectionFrequenciesTableProps) {
  const { showModal, hideModal } = useModal();

  const moduleGuard = useModuleGuard();
  const roleGuard = useRoleGuard();
  const { alertUnitFromSchedule, deleteAlertTemplate } = useAlert();
  const { t } = useTranslation();
  const { reload } = useTable();

  const showEditModal = (id: number) =>
    showModal(
      <InspectionFrequencyModal
        title={t('update_frequency')}
        message="Any update to the frequency will affect all items associated with this schedule."
        alertTemplateId={id}
      />
    );

  return (
    <Table<typeof ALL_INSPECTION_FREQUENCIES>
      title={title}
      icon={icon}
      tableActions={[
        {
          label: createTitle,
          callback: () =>
            showModal(
              <InspectionFrequencyModal title={t('create_frequency')} />
            ),
          display: roleGuard([Role.Admin]) && moduleGuard(proModules),
          color: 'primary',
        },
      ]}
      rowActions={[
        {
          label: t('update_frequency'),
          callback: (row) => showEditModal(row.id),
        },
        {
          label: t('delete_frequency'),
          type: TableRowType.danger,
          key: ItemAction.removeItemFromInventory,
          callback: (row) =>
            showModal(
              <ConfirmationModal
                title="Are you sure you want to delete this schedule?"
                message="The schedule will also be removed from the items it's been assigned to."
                buttonLabel="Remove"
                onConfirm={async () => {
                  await resolveMutation(
                    deleteAlertTemplate({
                      variables: {
                        id: row.id,
                      },
                    }),
                    {
                      failureMessage: 'Error deleting schedule',
                      successMessage: 'Schedule deleted',
                    }
                  );
                  reload();
                  hideModal();
                }}
              />
            ),
        },
      ]}
      query={ALL_INSPECTION_FREQUENCIES}
      columnSettings={[
        {
          label: 'Name',
          accessor: 'name',
          canSort: true,
          cellRender({ id, name }) {
            return (
              <span
                className="cursor-pointer"
                onClick={() => showEditModal(id)}
              >{`${name}`}</span>
            );
          },
        },
        {
          label: 'Inspection Type',
          accessor: 'type',
          canSort: true,
          cellRender({ type }) {
            return <span>{InspectionTypeLabel[type]}</span>;
          },
        },
        {
          label: 'frequency',
          accessor: 'id',
          cellRender({ quantity, unit }) {
            return (
              <span>{`${
                alertUnitFromSchedule(quantity, unit)?.label ?? ''
              }`}</span>
            );
          },
        },
      ]}
      initialState={{
        paginationEnabled: true,
        useAdvancedPagination: true,
        pageSize: 25,
        orderBy: {
          name: SortOrder.asc,
        },
      }}
      searchFields={[
        {
          field: 'name',
          label: t('name').toLowerCase(),
        },
      ]}
    />
  );
}

export default InspectionFrequenciesTable;
