export enum AppEvent {
  NFCRead = 'NFCRead',
  NFCReadNoResultWithURL = 'NFCReadNoResultWithURL', // 'NFC read with no result (pre encoded url)',
  NFCReadNoResultWithoutURL = 'NFCReadNoResultWithoutURL', // 'NFC read with no result (without pre encoded url)',
  QRCodeScan = 'QRCodeScan',
  ScannableQRCodeScan = 'ScannableQRCodeScan',
  BarcodeScan = 'BarcodeScan',
  DataMatrixScan = 'DataMatrixScan',
  Inspect = 'Inspect',
  InspectionStart = 'InspectionStart',
  InspectionEnd = 'InspectionEnd',
  ProductViewed = 'ProductViewed',
  ProductSearch = 'ProductSearch',
  AddToKit = 'AddToKit',
  SaveSerialisedProduct = 'SaveSerialisedProduct',
  Login = 'Login',
  Logout = 'Logout',
  SignUp = 'SignUp',
  AppSignUp = 'AppSignUp',
  AppLogin = 'AppLogin',
  DeleteAccount = 'DeleteAccount',
  ResetPassword = 'ForgotPassword',
  ProductCreated = 'ProductCreated',
  ProductUpdated = 'ProductUpdated',
}
