import { useMemo } from 'react';

import { FormFieldOptionType } from '@scannable/frontend/types';

import {
  AddressInput,
  CameraInput,
  CheckBoxInput,
  CountrySelectInput,
  CreateableSelectInput,
  DataFieldInput,
  DateInput,
  FileUpload,
  Input,
  NfcInput,
  RadioInput,
  ReactSelectInput,
  ReCaptchaInput,
} from '../../molecules';
import { FormFieldConfig } from '../../types/forms.types';

interface FieldProps extends FormFieldConfig {
  id: HTMLInputElement['id'];
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onClear?: () => void;
  loading?: boolean;
  ['data-cy']?: string;
}
export function FieldBuilder({
  id,
  onChange,
  onBlur,
  onClear,
  config,
  value,
  name,
  placeholder,
  type,
  options,
  disabled,
  style,
  label,
  children,
  cols,
  inline,
  loading,
  ...props
}: FieldProps) {
  const isMulti = config?.isMulti;
  const fieldValue = value;

  const reactSelectValue = useMemo(() => {
    if (!fieldValue) {
      return null;
    }
    if (isMulti && Array.isArray(fieldValue) && options) {
      return options.filter((v) => fieldValue.includes(v.value));
    }
    return options?.find((o) => o.value === fieldValue);
  }, [options, isMulti, fieldValue]);

  switch (type) {
    case 'text':
    case 'email':
    case 'number':
    case 'phone':
    case 'hidden':
    case 'password':
      return (
        <Input
          {...props}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={fieldValue}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md nodrag"
        />
      );
    case 'country':
      return (
        <CountrySelectInput
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          defaultOptions={config?.defaultOptions}
          isMulti={isMulti}
          value={fieldValue}
          placeholder={placeholder}
        />
      );
    case 'select':
    case 'react-select':
      return (
        <ReactSelectInput<FormFieldOptionType, boolean>
          id={id}
          name={name}
          onChange={onChange}
          options={options}
          defaultOptions={config?.defaultOptions}
          isMulti={isMulti}
          value={reactSelectValue}
          placeholder={placeholder}
          isClearable={config?.isClearable}
          {...props}
        />
      );
    case 'createable-select':
      return (
        <CreateableSelectInput
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          options={options}
        />
      );
    case 'checkbox':
      return (
        <CheckBoxInput
          type={type}
          name={name}
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          value={fieldValue}
          disabled={disabled}
          style={{ ...style }}
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          checked={Boolean(fieldValue)}
          aria-describedby={`${name}-description`}
        />
      );
    case 'image':
      return (
        <FileUpload
          id={id}
          label={label}
          name={name}
          loading={loading}
          multiple={isMulti}
          previewUrl={config?.previewUrl ?? undefined}
          currentImages={config?.currentImages ?? undefined}
          onFileChange={onChange}
          accept={{
            'image/*': [],
          }}
          maxFiles={config?.maxFiles}
          maxFilesReachedMessage={config?.maxFilesReachedMessage}
        />
      );
    case 'pdf':
      return (
        <FileUpload
          id={id}
          label={label}
          name={name}
          onFileChange={onChange}
          loading={loading}
          multiple={isMulti}
          accept={{
            'application/pdf': [],
          }}
          maxFiles={config?.maxFiles}
          maxFilesReachedMessage={config?.maxFilesReachedMessage}
        />
      );
    case 'textarea':
      return (
        <div className="mt-1">
          <textarea
            name={name}
            id={id}
            rows={3}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
            onChange={onChange}
            onBlur={onBlur}
            value={fieldValue}
          />
        </div>
      );
    case 'nfc':
      return (
        <NfcInput
          name={name}
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          value={fieldValue}
          clearNfc={onClear}
          nfcTags={null}
        />
      );
    case 'camera':
      return (
        <CameraInput
          id={id}
          name={name}
          value={fieldValue}
          placeholder={placeholder}
          onScan={onChange}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
    case 'radio':
      if (options) {
        return (
          <RadioInput
            options={options}
            id={id}
            name={name}
            onChange={onChange}
            inline={inline}
            onBlur={onBlur}
            value={value}
            cols={cols}
          />
        );
      }
      return <div>No options</div>;
    case 'date':
      return (
        <DateInput
          id={id}
          name={name}
          value={value?.toString() ?? ''}
          onChange={
            onChange ?? (() => new Error('DateInput requires onChange'))
          }
          onBlur={onBlur}
          inline={inline}
          showYearDropdown={config?.showYearDropdown}
          showMonthDropdown={config?.showMonthDropdown}
          {...props}
        />
      );
    case 'recaptcha':
      return <ReCaptchaInput name={name} onChange={onChange} {...props} />;
    case 'address':
      return (
        <AddressInput
          id={id}
          name={name}
          options={options}
          onChange={onChange}
          onBlur={onBlur}
          value={fieldValue}
        />
      );
    case 'unitvalue':
      return (
        <DataFieldInput
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          unit={(fieldValue as { unit?: string })?.unit ?? ''}
          value={(fieldValue as { value?: string })?.value ?? ''}
          availableUnits={config?.secondaryOptions ?? []}
        />
      );
    default:
      return <div>Unsupported field</div>;
  }
}

export default FieldBuilder;
