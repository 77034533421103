import { Product } from '@scannable/common';

import { graphql } from '../graphql';

export const UPDATE_PRODUCT = graphql(`
  mutation ${Product.UpdateProduct}($data: UpdateProductInput!) {
    updateProduct(data: $data) {
      id
    }
  }
`);

export const CREATE_PRODUCT = graphql(`
  mutation ${Product.CreateProduct}($data: CreateProductInput!) {
    createProduct(data: $data) {
      id
      }
  }`);

export const MOVE_ALL_PRODUCTS_TO_GROUP = graphql(`
  mutation ${Product.MoveAllProductsToGroup}($data: MoveAllProductsToGroupInput!) {
    moveAllProductsToGroup(data: $data) {
      count
    }
  }
`);

export const DELETE_PRODUCT = graphql(`
  mutation ${Product.DeleteProduct}($data: DeleteProductInput!) {
    deleteProduct(data: $data) {
      id
    }
  }
`);
