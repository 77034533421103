import { ProductType } from '@prisma/client';

import { Badge } from '../../atoms';

export interface ProductTypeBadgeProps
  extends React.HTMLAttributes<HTMLDivElement> {
  type?: ProductType;
}

export function ProductTypeBadge({ type, className }: ProductTypeBadgeProps) {
  if (!type || type === ProductType.COMPONENT) {
    return null;
  }
  const label =
    type === ProductType.ASSEMBLY ? ProductType.ASSEMBLY : 'ROPE LENGTH';
  return (
    <Badge
      className={className}
      colour={type === ProductType.ASSEMBLY ? 'green' : 'yellow'}
    >
      {label}
    </Badge>
  );
}

export default ProductTypeBadge;
