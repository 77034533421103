export interface CompositeComponent {
  id: number;
  productVariationId: number;
  batchNumber?: string;
}

export interface ComponentSerial {
  productVariationId: number;
  serialisedProductId: number;
  serialNumber: string;
}

export enum SerialFormatComponentType {
  prefix = 'prefix',
  suffix = 'suffix',
  dom = 'dom',
  batch = 'batch',
  countryOfOrigin = 'countryOfOrigin',
  customerReference = 'customerReference',
  salesOrderNumber = 'salesOrderNumber',
  purchaseOrderNumber = 'purchaseOrderNumber',
  increments = 'increments',
}

export type SerialFormatItemData = {
  dataKey: string;
  dataValue: string;
};
export type SerialFormatOption = {
  id: number;
  name: string | null;
  format: SerialFormatComponent[];
  itemData?: SerialFormatItemData[];
};

export type SerialFormatComponent = {
  type: SerialFormatComponentType;
  value?: string | null;
  order: number;
};

export type SerialFormatNumericComponent = Omit<
  SerialFormatComponent,
  'value'
> & {
  value?: number | null;
};
