export interface ItemInfoProps {
  productName?: string | null;
  name?: string | null;
  manufacturerName?: string | null;
  code?: string | null;
  serialNumber?: string | null;
}

export function ItemInfo({
  productName,
  name,
  manufacturerName,
  code,
  serialNumber,
}: ItemInfoProps) {
  return (
    <>
      <div className="flex justify-between text-base font-medium text-gray-900">
        <h3>{`${productName || ''}${productName && name ? ' - ' : ''}${
          name ? name : ''
        }`}</h3>
      </div>
      <p className="mt-1 text-sm text-gray-500">
        {manufacturerName ?? ''} {code ?? ''}
      </p>
      <p className="mt-1 text-sm text-gray-500">{serialNumber}</p>
    </>
  );
}

export default ItemInfo;
