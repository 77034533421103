import { AccountModule, Role } from '@scannable/common';

import { IMenuItem } from '../molecules';
import { navigationBasePath } from '../utils/navigation.utils';

export const navigation = [
  {
    name: 'Buy NFC tags',
    langKey: 'menu_buy_nfc_tags',
    href: 'https://hubs.li/Q02svb4X0',
    external: true,
    icon: 'waves',
    current: false,
    primaryAction: true,
  },
  {
    name: 'Dashboard',
    langKey: 'menu_dashboard',
    href: '/admin',
    current: true,
    icon: 'dashboard',
  },
  {
    name: 'Users',
    langKey: 'menu_users',
    href: '/admin/users',
    current: false,
    icon: 'users',
    showForRoles: [Role.SuperAdmin],
    testId: 'users',
    group: 'admin',
  },
  {
    name: 'Team',
    langKey: 'menu_team',
    href: '/admin/team',
    icon: 'team',
    testId: 'team',
    current: false,
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier, Role.User],
    isProFeature: true,
  },
  {
    name: 'Product Search',
    langKey: 'menu_product_search',
    href: '/admin/product-search',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier, Role.User],
    current: false,
    icon: 'productSearch',
  },
  {
    name: 'Settings',
    langKey: 'settings',
    href: '/admin/settings',
    showForRoles: [Role.Admin],
    hideForRoles: [Role.Supplier, Role.User],
    current: false,
    icon: 'settings',
  },
  {
    name: 'Scannable Updates',
    langKey: 'menu_updates',
    href: 'https://scannable.notion.site/Scannable-Release-Log-1007d60de3674c60a1d063f2e34c2c47',
    icon: 'products',
    tootipLangKey: 'tooltip_scannable_updates',
    external: true,
    current: false,
    showForRoles: [
      AccountModule.Pro,
      AccountModule.Enterprise,
      AccountModule.Access,
    ],
  },
  {
    name: 'Knowledge Base',
    langKey: 'menu_knowledge_base',
    href: 'https://hubs.li/Q02ym67D0',
    icon: 'bookOpen',
    tootipLangKey: 'tooltip_knowledge_base',
    external: true,
    current: false,
    showForRoles: [
      AccountModule.Pro,
      AccountModule.Enterprise,
      AccountModule.Access,
    ],
  },
  {
    name: 'Manufacturers',
    langKey: 'menu_manufacturers',
    href: '/admin/manufacturers',
    showForRoles: [Role.SuperAdmin],
    current: false,
    icon: 'producers',
    testId: 'manufacturers',
    group: 'admin',
  },
  {
    name: 'Product Categories',
    langKey: 'menu_product_categories',
    href: '/admin/product-categories',
    showForRoles: [Role.SuperAdmin],
    current: false,
    icon: 'data',
    group: 'admin',
  },
  {
    name: 'Product Data Fields',
    langKey: 'menu_product_data_categories',
    href: '/admin/product-data',
    showForRoles: [Role.SuperAdmin],
    current: false,
    icon: 'productsCategories',
    group: 'admin',
  },
  {
    name: 'Products/SKUS',
    langKey: 'menu_products',
    href: '/admin/products',
    showForRoles: [AccountModule.Enterprise],
    current: false,
    icon: 'products',
    testId: 'products',
    group: 'manufacturer', // grouped by manufacturer
  },
  {
    name: 'Products/SKUS',
    langKey: 'menu_products',
    href: '/admin/products',
    showForRoles: [AccountModule.Access, AccountModule.Pro],
    hideForRoles: [Role.Supplier, Role.User],
    current: false,
    icon: 'products',
    testId: 'products',
    group: 'owner', // this one is for the owner of the products
    isProFeature: true,
  },
  {
    name: 'Inventory',
    langKey: 'menu_inventory',
    href: '/admin/inventory',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'kitList',
    testId: 'inventory',
    group: 'owner',
  },
  {
    name: 'My Inventory',
    langKey: 'menu_my_inventory',
    href: '/admin/my-inventory',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier, Role.User],
    current: false,
    icon: 'myInventory',
    testId: 'my-inventory',
    group: 'owner',
    isProFeature: true,
  },
  {
    name: 'Kit Bags',
    langKey: 'menu_kit_bags',
    href: '/admin/kit-bags',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'kitBag',
    testId: 'kit-bags',
    group: 'groups',
    isProFeature: true,
  },
  {
    name: 'Locations',
    langKey: 'menu_locations',
    href: '/admin/locations',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'location',
    testId: 'locations',
    group: 'groups',
    isProFeature: true,
  },
  {
    name: 'Vehicles',
    langKey: 'menu_vehicles',
    href: '/admin/vehicles',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'vehicles',
    testId: 'vehicles',
    group: 'groups',
    isProFeature: true,
  },
  {
    name: 'Clients',
    langKey: 'menu_clients',
    href: '/admin/clients',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'clients',
    testId: 'clients',
    group: 'groups',
    isProFeature: true,
  },
  {
    name: 'Staff',
    langKey: 'menu_staff',
    href: '/admin/staff',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'staff',
    testId: 'staff',
    group: 'groups',
    isProFeature: true,
  },
  {
    name: 'Equipment Type',
    langKey: 'menu_equipment_type',
    href: '/admin/equipment-type',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'equipmentType',
    testId: 'equipmentType',
    group: 'groups',
    isProFeature: true,
  },
  {
    name: 'Multi-scan',
    langKey: 'multi_scan.title',
    href: '/admin/multi-scan',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'multiScan',
    testId: 'tasks',
    group: 'owner',
    isProFeature: true,
  },
  {
    name: 'Serialisation',
    langKey: 'serialisation.title',
    href: '/admin/serialisation',
    tootipLangKey: 'tooltip_create_serial_menu',
    showForRoles: [AccountModule.Enterprise],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'createSerials',
    testId: 'serialisation',
    group: 'manufacturer',
  },
  {
    name: 'Inspections',
    langKey: 'menu_inspections',
    href: '/admin/serialisation/inspections',
    showForRoles: [AccountModule.Enterprise],
    hideForRoles: [Role.Supplier],
    current: false,
    icon: 'inspections',
    group: 'manufacturer',
  },
  {
    name: 'Checklists',
    langKey: 'menu_checklists',
    href: '/admin/checklists',
    showForRoles: [AccountModule.Enterprise],
    hideForRoles: [Role.Supplier, Role.Member],
    current: false,
    icon: 'inspections',
    testId: 'checklists',
    group: 'manufacturer',
  },
  {
    name: 'Inspections',
    langKey: 'menu_inspections',
    href: '/admin/inspections',
    showForRoles: [
      AccountModule.Access,
      AccountModule.Pro,
      AccountModule.Enterprise,
    ],
    hideForRoles: [Role.Supplier, Role.User],
    current: false,
    icon: 'inspections',
    testId: 'inspections',
    group: 'owner',
    isProFeature: true,
  },
  {
    name: 'Organisations',
    langKey: 'menu_organisations',
    href: '/admin/organisations',
    showForRoles: [Role.SuperAdmin],
    current: false,
    icon: 'organisations',
    group: 'admin',
  },
] as IMenuItem[];

export const navigationToShow = (roles: string[]) =>
  navigation.filter((n) => {
    return (
      (!n.hideForRoles || !n.hideForRoles.some((r) => roles.includes(r))) &&
      (!n.showForRoles || n.showForRoles.some((r) => roles.includes(r))) &&
      n.hidden !== true
    );
  });

/**
 * return path after admin base path
 * @param path
 * @returns
 */
export const baseAdminPath = (path: string) =>
  path.split('/').filter((p) => Boolean(p))[1];

export const canViewCurrentRoute = (roles: string[], basePath: string) => {
  const uri = navigationBasePath(basePath);
  const paths = navigation.filter((n) => n.href === uri);
  if (paths.length === 0) {
    return true;
  }
  let canView = false;
  for (const path of paths) {
    // If hideForRoles includes any of the user's roles, return false
    if (
      path.hideForRoles &&
      path.hideForRoles.some((role) => roles.includes(role))
    ) {
      return false;
    }
    // if showForRoles is not defined, set canView to true
    if (!path.showForRoles) {
      return true;
    }
    // If showForRoles is defined and the user has any of the roles in it, set canView to true
    if (
      path.showForRoles &&
      path.showForRoles.some((role) => roles.includes(role))
    ) {
      canView = true;
    }
  }
  return canView;
};
