import { useQuery } from '@apollo/client';

import {
  EXAMINATION_JOB,
  FIND_ACTIVE_EXAMINATIONS,
  UseQueryOptions,
} from '@scannable/graphql-queries';

export function useFindActiveExaminationsQuery(
  options?: UseQueryOptions<typeof FIND_ACTIVE_EXAMINATIONS>
) {
  return useQuery(FIND_ACTIVE_EXAMINATIONS, options);
}

export function useExaminationJobQuery(
  options?: UseQueryOptions<typeof EXAMINATION_JOB>
) {
  return useQuery(EXAMINATION_JOB, options);
}
