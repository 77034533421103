import { useMutation, useQuery } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { classNames, Metadata, Unit, units } from '@scannable/common';
import {
  CREATE_METADATA,
  GET_METADATA_CATEGORIES,
  UPDATE_METADATA,
} from '@scannable/graphql-queries';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is required'),
  metadataCategoryId: yup
    .number()
    .nullable()
    .required('Please select a category'),
});

export interface DataFieldValues {
  name: string;
  hasUnits: boolean;
  type: string;
  unit: string;
  isPublic: boolean;
  selectedUnits: string[];
  metadataCategoryId: number | null;
}

export interface DataFieldModalProps extends Partial<DataFieldValues> {
  id?: number;
  availableUnits?: Unit[];
}

export function DataFieldModal({
  metadataCategoryId,
  availableUnits,
  name,
  hasUnits,
  isPublic,
  type,
  unit,
  id,
}: DataFieldModalProps) {
  const { hideModal } = useModal();
  const { data } = useQuery(GET_METADATA_CATEGORIES);
  const [updateDataField] = useMutation(UPDATE_METADATA, {
    refetchQueries: [Metadata.PaginatedMetadata],
  });
  const [createDataField] = useMutation(CREATE_METADATA, {
    refetchQueries: [Metadata.PaginatedMetadata],
  });

  const options = data?.metadataCategories
    ? data.metadataCategories.map((mc) => ({
        label: mc.name,
        value: mc.id,
      }))
    : [];
  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      hasUnits: hasUnits ?? false,
      type: type ?? '',
      unit: unit ?? '',
      isPublic: isPublic ?? true,
      selectedUnits: availableUnits ? availableUnits.map((u) => u.value) : [],
      metadataCategoryId: metadataCategoryId ?? null,
    } as DataFieldValues,
    validationSchema,
    onSubmit: async (
      values: DataFieldValues,
      actions: FormikHelpers<DataFieldValues>
    ) => {
      const availableUnits = units.filter((u) =>
        values.selectedUnits.includes(u.value)
      );
      const data = {
        name: values.name,
        hasUnits: values.hasUnits,
        type: values.type,
        unit: values.unit,
        isPublic: values.isPublic,
        availableUnits,
        metadataCategoryId: Number(values.metadataCategoryId),
      };

      const result = await resolveMutation(
        id
          ? updateDataField({
              variables: {
                data: { ...data, id },
              },
            })
          : createDataField({
              variables: {
                data,
              },
            }),
        {
          successMessage: id
            ? 'Data field updated successfully'
            : 'Data field created successfully',
        }
      );

      if (result.ok) {
        hideModal();
        actions.setSubmitting(false);
      }
    },
  });
  return (
    <Modal
      title={`${id ? 'Update' : 'Create'} Data Field`}
      overflowHidden={false}
    >
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: id ? 'Update' : 'Create',
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup type="text" name="name" label="Name" formik={formik} />
        <InputFieldGroup
          type="radio"
          name="isPublic"
          label="Is a Public Facing Data Field"
          formik={formik}
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
        />
        <InputFieldGroup
          formik={formik}
          type="select"
          name="metadataCategoryId"
          label="Data Category"
          options={options}
        />
        <InputFieldGroup
          formik={formik}
          type="radio"
          name="type"
          label="Data Type"
          options={[
            {
              label: 'String',
              value: 'string',
            },
            {
              label: 'Text',
              value: 'text',
            },
            {
              label: 'HTML',
              value: 'html',
            },
            {
              label: 'Numeric',
              value: 'numeric',
            },
            {
              label: 'Date',
              value: 'date',
            },
            {
              label: 'PDF',
              value: 'pdf',
            },
            {
              label: 'Photo',
              value: 'photo',
            },
            {
              label: 'URL',
              value: 'url',
            },
            {
              label: 'Certifications',
              value: 'certifications',
            },
            {
              label: 'Video',
              value: 'video',
            },
            {
              label: 'Printer Icon',
              value: 'icon',
            },
          ]}
        />
        <div
          className={classNames(
            formik.values['type'] !== 'numeric' ? 'hidden' : 'block'
          )}
        >
          <InputFieldGroup
            type="radio"
            name="hasUnits"
            label="Has units?"
            options={[
              {
                label:
                  'Yes, this numeric item has measurement units and can be converted between Metric/Imperial',
                value: true,
              },
              {
                label: 'No, this numeric item is just a number',
                value: false,
              },
            ]}
            formik={formik}
          />
          <div
            className={classNames(
              formik.values['hasUnits'] !== true ? 'hidden' : 'block'
            )}
          >
            <InputFieldGroup
              formik={formik}
              containerClassName="mt-7"
              type="select"
              name="selectedUnits"
              label="Available Units"
              options={units}
              config={{ isMulti: true }}
            />
          </div>
        </div>
      </ModalForm>
    </Modal>
  );
}

export default DataFieldModal;
