import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { classNames } from '@scannable/common';
import { AppUser } from '@scannable/frontend/types';

import { Link, ResizedImage } from '../../atoms';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';

const userIconClassnames =
  'rounded-full flex justify-center items-center font-medium border-gray-300 bg-gray-200 border h-8 w-8 overflow-hidden';

interface IUserNavigationItem {
  name: string;
  href: string;
  testRef?: string;
}

export interface UserMenuProps {
  user: AppUser | null;
  isLoggedIn: boolean;
  signOut: () => void;
  navigationToShow: IUserNavigationItem[];
  roleName?: string;
}

export function UserMenu({
  user,
  isLoggedIn,
  signOut,
  navigationToShow,
  roleName,
}: UserMenuProps) {
  const { t } = useTranslation();

  return (
    <Menu as="div" className="ml-2 relative min-w-max">
      <div>
        <Menu.Button
          className="max-w-xs px-1 h-10 bg-white flex items-center text-sm rounded-full border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          data-cy="user-menu"
        >
          <span className="sr-only">Open user menu</span>

          {user ? (
            <div className={userIconClassnames}>
              {user.avatar ? (
                <ResizedImage
                  className="object-fill object-center rounded-full border border-gray-200"
                  src={user.avatar}
                  alt={user.firstName}
                  height={160}
                  width={160}
                  fit="cover"
                />
              ) : (
                <>
                  {user?.firstName?.charAt(0) || ''}
                  {user?.lastName?.charAt(0) || ''}
                </>
              )}
            </div>
          ) : (
            <div className={userIconClassnames} />
          )}
          <ChevronDownIcon className="h-5 w-5 text-gray-500 ml-1 mr-1" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {isLoggedIn && user && (
            <Link href="/admin/me" passHref>
              <Menu.Item>
                <div className="bg-gray-200 px-3 py-3 rounded-lg m-2">
                  <span className="block text-gray-800 text-sm font-semibold truncate">
                    {user.firstName} {user.lastName}
                  </span>
                  {roleName && (
                    <div className="block text-xs mt-1 font-medium text-gray-500 truncate">
                      {roleName}
                    </div>
                  )}
                </div>
              </Menu.Item>
            </Link>
          )}
          {navigationToShow.map((item) => (
            <Link key={item.name} href={item.href} passHref>
              <Menu.Item data-cy={item.testRef}>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block font-medium px-4 py-2 text-sm text-gray-700 hover:cursor-pointer'
                    )}
                  >
                    {item.name}
                  </span>
                )}
              </Menu.Item>
            </Link>
          ))}
          {isLoggedIn && (
            <Menu.Item>
              <>
                <div className="border-t border-gray-200 mt-1 mx-3" />
                <span
                  onClick={() => signOut()}
                  className={classNames(
                    'block font-medium px-4 py-3 text-sm text-gray-700 cursor-pointer hover:bg-gray-100'
                  )}
                  data-cy="sign-out"
                >
                  {t('sign_out')}
                </span>
              </>
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default UserMenu;
