import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { CompositeComponent, SerialFormatOption } from '@scannable/common';
import { clearSerialisationState, RootState } from '@scannable/frontend/store';
import { CREATE_SERIALISED_PRODUCTS } from '@scannable/graphql-queries';

import { Button, Text } from '../../atoms';
import { useAuth } from '../../auth';
import { resolveMutation } from '../../lib/lib';
import AdminPanel from '../../molecules/AdminPanel/AdminPanel';
import {
  DescriptionList,
  DescriptionListItem,
} from '../../molecules/DescriptionList/DescriptionList';
import LoadingPanel from '../../molecules/LoadingPanel/LoadingPanel';
import { SerialFormatDisplay } from '../../molecules/SerialFormatDisplay/SerialFormatDisplay';
import { errorAlert } from '../../utils';

export interface BatchSerialDescriptionListProps {
  manufacturerSerialFormats: SerialFormatOption[];
}

export function BatchSerialsToCreateDescriptionList({
  manufacturerSerialFormats,
}: BatchSerialDescriptionListProps) {
  const { organisationId, manufacturerId } = useAuth();
  const router = useRouter();
  const [createSerialisedProducts, { loading: isSubmitting }] = useMutation(
    CREATE_SERIALISED_PRODUCTS
  );
  const { serialPurchaseOrder, skus } = useSelector(
    (state: RootState) => state.serialisation
  );
  const dispatch = useDispatch();
  const reset = () => {
    dispatch(clearSerialisationState());
  };

  const submitProductsForSerialisation = async () => {
    if (!serialPurchaseOrder || !serialPurchaseOrder.serialFormatId) {
      errorAlert('Please select a serial format');
      return;
    }
    if (skus.length === 0) {
      errorAlert('No products selected for serialisation');
      return;
    }

    const result = await resolveMutation(
      createSerialisedProducts({
        variables: {
          data: {
            serialFormatId: serialPurchaseOrder.serialFormatId,
            purchaseOrderNumber: serialPurchaseOrder.purchaseOrderNumber,
            salesOrderNumber: serialPurchaseOrder.salesOrderNumber,
            batchNumber: serialPurchaseOrder.batchNumber,
            dom: serialPurchaseOrder.dom,
            customerReference: serialPurchaseOrder.customerReference,
            manufacturerId,
            organisationId,
            serialCreationData: skus.map((stateValue) => ({
              productDom: stateValue.productDom,
              productBatchNumber: stateValue.productBatchNumber,
              serialQuantity: stateValue.serialQuantity,
              productVariationId: stateValue.productVariation.id,
              productType:
                stateValue.productVariation.info?.productType || 'COMPONENT',
              componentBatchNumbers: stateValue.componentBatchNumbers,
            })),
          },
        },
      }),
      {
        successMessage: 'Successfully created serialised products',
      }
    );
    if (result.ok) {
      reset();
      router.push('/admin/serialisation');
    }
  };
  const format =
    serialPurchaseOrder && manufacturerSerialFormats
      ? manufacturerSerialFormats.find(
          (format) => format.id === serialPurchaseOrder.serialFormatId
        )
      : '';

  if (isSubmitting) {
    return (
      <AdminPanel>
        <LoadingPanel />
      </AdminPanel>
    );
  }
  return (
    <DescriptionList title="Serialise product info">
      {serialPurchaseOrder?.purchaseOrderNumber && (
        <DescriptionListItem title="Reference number">
          <Text className="text-center" data-cy="po-number">
            {serialPurchaseOrder?.purchaseOrderNumber || 'None entered'}
          </Text>
        </DescriptionListItem>
      )}
      {serialPurchaseOrder?.salesOrderNumber && (
        <DescriptionListItem title="Sales order number">
          <Text className="text-center" data-cy="so-number">
            {serialPurchaseOrder?.salesOrderNumber || 'None entered'}
          </Text>
        </DescriptionListItem>
      )}
      {serialPurchaseOrder?.batchNumber && (
        <DescriptionListItem title="Batch number">
          <Text className="text-center" data-cy="batch-number">
            {serialPurchaseOrder?.batchNumber || 'None entered'}
          </Text>
        </DescriptionListItem>
      )}
      {serialPurchaseOrder?.customerReference && (
        <DescriptionListItem title="Customer reference">
          <Text className="text-center" data-cy="cust-ref">
            {serialPurchaseOrder?.customerReference || 'None entered'}
          </Text>
        </DescriptionListItem>
      )}
      <DescriptionListItem title="Date of manufacture">
        {serialPurchaseOrder?.dom && (
          <Text
            className="text-center"
            data-cy="dom"
          >{`${serialPurchaseOrder?.dom}`}</Text>
        )}
      </DescriptionListItem>

      <DescriptionListItem title="Serial format">
        {format && <SerialFormatDisplay format={format} />}
      </DescriptionListItem>
      {skus.length > 0 && (
        <DescriptionListItem title="Products selected for serialisation - add as many as you need.">
          {skus.map((p, i) => (
            <div key={`${p.productVariation}_${i}`} className="mb-5">
              <div className="flex flex-col">
                <div>
                  {p.productVariation && (
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <h3>{p.productVariation.info?.name}</h3>
                      <p className="ml-4" data-cy="variation-code">
                        {p.productVariation.info?.code}
                      </p>
                    </div>
                  )}
                  <Text data-cy="serial-quantity">
                    Serial quantity: {p.serialQuantity}
                  </Text>
                  {p.componentBatchNumbers &&
                    p.componentBatchNumbers.filter((c) => c.batchNumber)
                      ?.length > 0 && (
                      <div>
                        <Text>Box batch numbers:</Text>
                        <ul className="text-sm text-gray-500 ml-5">
                          {p.componentBatchNumbers
                            .filter((c) => c.batchNumber)
                            .map((c: CompositeComponent) => (
                              <li
                                key={`${c.productVariationId}_${c.batchNumber}`}
                                className="list-disc"
                              >
                                {c.batchNumber}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}
        </DescriptionListItem>
      )}
      <DescriptionListItem>
        <Button
          type="button"
          label="Clear selections"
          onClick={() => reset()}
          className="float-left"
          color="secondary"
        />
        {skus.length > 0 && (
          <Button
            type="button"
            label="Create serials for products"
            onClick={() => submitProductsForSerialisation()}
            loading={isSubmitting}
            className="float-right"
            data-cy="create-serials"
          />
        )}
      </DescriptionListItem>
    </DescriptionList>
  );
}

export default BatchSerialsToCreateDescriptionList;
