import { ApolloError } from '@apollo/client';
import { CriteriaResult, Organisation } from '@prisma/client';

export function extractMessageFromError(error: ApolloError | Error) {
  if (error?.message) {
    return error.message;
  }
  return 'There was an error';
}

export function daysLeft(trialEnds: string | null) {
  if (!trialEnds) {
    return '';
  }
  const today = new Date();
  const endOfTrial = new Date(trialEnds);
  const diffTime = endOfTrial.getTime() - today.getTime();
  const diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return `${diff} days left on Trial`;
}

export function heading(heading: string | undefined): string {
  const articles = ['a', 'an', 'the'];
  const prepositions = [
    'to',
    'on',
    'for',
    'in',
    'with',
    'by',
    'of',
    'at',
    'from',
    'about',
    'after',
    'before',
  ];
  const conjunctions = ['but', 'and', 'or', 'nor', 'yet', 'so'];

  const acronyms = ['nfc', 'qr', 'pdf', 'api', 'url', 'id', 'uuid'];

  if (!heading) {
    return '';
  }

  const words = heading.toLowerCase().split(' ');
  const convertedWords = words.map((word, index) => {
    if (
      index === 0 ||
      (!articles.includes(word) &&
        !prepositions.includes(word) &&
        !conjunctions.includes(word) &&
        !acronyms.includes(word))
    ) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }

    if (acronyms.includes(word)) {
      return word.toUpperCase();
    }

    return word;
  });

  return convertedWords.join(' ');
}
export const h = heading;

export function tableBtnLabel(label: string) {
  return `+ ${h(label)}`;
}

export function formatSSRDate(date: Date, isClient: boolean) {
  if (isClient) {
    return date.toLocaleDateString();
  }
  return date.toLocaleDateString('en-US');
}

export function getOrganisationLabels(org: Partial<Organisation>) {
  const labels = [];
  if (org.isManufacturer) {
    labels.push('Manufacturer');
  }
  if (org.isReseller) {
    labels.push('Reseller');
  }
  if (org.isEquipmentOwner) {
    labels.push('Equipment Owner');
  }
  if (org.isInspector) {
    labels.push('Inspector');
  }
  if (org.isTrainingCentre) {
    labels.push('Training Centre');
  }
  return labels;
}

export const getCriteriaResultColor = (result: CriteriaResult) => {
  switch (result) {
    case CriteriaResult.PASSED:
      return 'emerald';
    case CriteriaResult.FAILED:
      return 'red';
    case CriteriaResult.NOT_APPLICABLE:
      return 'gray';
    case CriteriaResult.MONITORING:
      return 'yellow';
    case CriteriaResult.REPAIR:
      return 'blue';
    default:
      return '';
  }
};

export function testId(value: string, prefix?: string) {
  return `${prefix ? `${prefix}-` : ''}${value ?? ''}`
    .split(' ')
    .join('-')
    .toLowerCase();
}

/**
 * Interpolates a string with a value (Used for translations
 * @example tInterpolate("# NFCs Added", "3") // 3 NFCs Added
 * @param text The text to interpolate
 * @param value The value to interpolate
 * @returns The interpolated string
 */
export function tInterpolate(text: string, value: string | number) {
  return text.replace('#', value.toString());
}
