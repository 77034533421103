import { useLayoutEffect, useRef, useState } from 'react';

export const useSelectAllCheckboxState = (
  rowIds: number[],
  selectedRows: number[],
  setSelectedRows: (selectedRows: number[]) => void
) => {
  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  useLayoutEffect(() => {
    if (rowIds.length > 0 && checkbox.current) {
      //  check if all current rows are selected
      const checked = rowIds.every((rowId) => selectedRows.includes(rowId));
      const isIndeterminate = selectedRows.length > 0 && !checked;
      setChecked(checked);
      setIndeterminate(isIndeterminate);
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedRows, rowIds]);

  const toggleAll = () => {
    if (indeterminate && rowIds.length > 0) {
      setSelectedRows(Array.from(new Set([...selectedRows, ...rowIds])));
    }
    if (!indeterminate && rowIds) {
      setChecked(!checked);
      // remove all selected rows if all rows are selected
      const selected = selectedRows.filter((rowId) => !rowIds.includes(rowId));

      setSelectedRows(checked ? selected : rowIds);
    }
  };

  return { checkbox, checked, toggleAll };
};
