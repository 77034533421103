import { useManufacturerProductGrouping } from '../../hooks';

const filter = {
  serialisedProductVariations: {
    some: {
      productVariation: {
        is: {
          product: {
            is: {
              productGroupId: {
                in: '{VAL}',
              },
            },
          },
        },
      },
    },
  },
};

export function useProductCategoryFilter() {
  const { options } = useManufacturerProductGrouping();

  return {
    options,
    interpolatedFilter: filter,
  };
}
