import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { usePopperTooltip } from 'react-popper-tooltip';

export interface ToolTipProps {
  msg: string;
}

export function ToolTip({ msg }: ToolTipProps) {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  return (
    <div className="inline-block align-middle ml-1 cursor-pointer">
      <div ref={setTriggerRef}>
        <InformationCircleIcon className="h-5 stroke-slate-500" />
      </div>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'tooltip-container inline-block p-2 w-40 whitespace-normal',
          })}
        >
          <span className="text-sm text-gray-500">{msg}</span>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </div>
  );
}

export default ToolTip;
