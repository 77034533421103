import { LockClosedIcon } from '@heroicons/react/20/solid';
import { SessionType } from '@prisma/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useSignUp } from '@scannable/frontend/common';

import { Alert, Button, Text } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { resolveMutation } from '../../lib/lib';
import { AdminPanel } from '../../molecules/AdminPanel/AdminPanel';
import { CustomForm } from '../../molecules/CustomForm/CustomForm';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import { LoadingPanel } from '../../molecules/LoadingPanel/LoadingPanel';
import { LoggedInMessage } from '../../molecules/LoggedInMessage/LoggedInMessage';
import { setLastEmail } from '../../utils';

export const signupValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is a required field'),
  lastName: Yup.string().required('Last name is a required field'),
  email: Yup.string().email().required('A valid email address is required'),
  password: Yup.string()
    .min(8)
    .required('A password of at least 8 characters is required'),
  confirmPassword: Yup.string().test(
    'passwords-match',
    'Passwords must match',
    function (value) {
      return this.parent.password === value;
    }
  ),
  recaptcha: Yup.string()
    .nullable()
    .required("Please confirm you're not a bot"),
});

export interface SignUpFormmValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  receiveUpdates: boolean;
  recaptcha: string;
}

export interface SignUpFormProps {
  token?: string;
  email?: string;
}

export function SignUpForm({ token, email }: SignUpFormProps) {
  const { signIn, loading, isLoggedIn, userId, signOut, redirecting } =
    useAuth();

  const { signup, inviteEmail, loadingInvite } = useSignUp(token);
  const formik = useFormik({
    initialValues: {
      email: email || '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      receiveUpdates: false,
      recaptcha: '',
    },
    validationSchema: signupValidationSchema,
    onSubmit: async (values: SignUpFormmValues) => {
      const { confirmPassword, ...returnValues } = values;
      const result = await resolveMutation(
        signup({
          variables: {
            data: {
              ...returnValues,
              sessionType: SessionType.WEB,
              token,
            },
          },
        }),
        {
          successMessage: 'Welcome to Scannable!',
        }
      );

      if (result.ok) {
        signIn(result.data.signup.accessToken);
        setLastEmail(values.email);
      }
    },
  });
  if (loading || redirecting) {
    return (
      <AdminPanel>
        <LoadingPanel />
      </AdminPanel>
    );
  }
  if (isLoggedIn || userId) {
    return (
      <AdminPanel>
        <LoggedInMessage signOut={signOut} />
      </AdminPanel>
    );
  }

  if (!loadingInvite && token && !inviteEmail) {
    return (
      <Alert
        type="warning"
        message="Unable to find this invite. Please check the link is correct and try again or reach out to us."
      />
    );
  }

  return (
    <CustomForm className="mt-8 space-y-6" formik={formik}>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-4 bg-white py-6 px-4 sm:p-6">
          <InputFieldGroup
            type="text"
            name="firstName"
            label="First name"
            placeholder="First name"
            formik={formik}
          />
          <InputFieldGroup
            type="text"
            name="lastName"
            label="Last name"
            placeholder="Last name"
            formik={formik}
          />
          <InputFieldGroup
            type="email"
            name="email"
            label="Email address"
            placeholder="Email address"
            readOnly={!!inviteEmail}
            formik={formik}
          />
          <InputFieldGroup
            type="password"
            name="password"
            label="Password"
            placeholder="Password"
            formik={formik}
          />
          <InputFieldGroup
            type="password"
            name="confirmPassword"
            label="Confirm password"
            placeholder="Confirm password"
            formik={formik}
          />
          <InputFieldGroup type="recaptcha" name="recaptcha" formik={formik} />
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id="ReceiveUpdates"
                aria-describedby="Receive-Updates-Description"
                name="receiveUpdates"
                type="checkbox"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.receiveUpdates ? '1' : '0'}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="ReceiveUpdates"
                className="cursor-pointer font-medium text-gray-700"
                id="Receive-Updates-Description"
              >
                Yes, I want:
                <ul className="list-disc">
                  <li>To get emails with discounts and offers</li>
                  <li>
                    To be notified when an item in my inventory has been
                    recalled
                  </li>
                  <li>
                    To be kept up to date with the latest Scannable feature
                    releases
                  </li>
                  <li>
                    To know all the tips and tricks to help me get the most out
                    of Scannable
                  </li>
                </ul>
              </label>
              <Text className="text-gray-700 font-medium -ml-3 mt-2">
                We know spam isn&rsquo;t cool, so we&rsquo;re only going to send
                you the important stuff - once a week, tops.
              </Text>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 text-left sm:px-6">
          <Text>
            Scannable uses the information you provide to contact you with
            updates about our app, products and services, and relevant content.
            You may change your preferences or unsubscribe from these
            communications at any time. Your information will not be shared.
          </Text>
        </div>
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
          <Button
            type="submit"
            className="group relative flex w-full justify-center py-2 px-4"
            loading={formik.isSubmitting}
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Agree &amp; Continue
          </Button>
        </div>
      </div>
    </CustomForm>
  );
}

export default SignUpForm;
