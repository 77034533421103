import { useQuery } from '@apollo/client';

import { INVENTORY_MANUFACTURERS } from '@scannable/graphql-queries';

const filter = {
  OR: [
    {
      manufacturerId: {
        in: '{VAL}',
      },
    },
    {
      productVariation: {
        is: {
          manufacturer: {
            is: {
              id: {
                in: '{VAL}',
              },
            },
          },
        },
      },
    },
  ],
};

export function useManufacturerFilter() {
  const { data } = useQuery(INVENTORY_MANUFACTURERS);

  return {
    interpolatedFilter: filter,
    options: data?.getInventoryManufacturers
      ? data.getInventoryManufacturers
          .map((manufacturer) => ({
            label: manufacturer.name,
            value: manufacturer.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [],
  };
}
