import React from 'react';
import DatePicker from 'react-datepicker';

import { ISO_8601_DATE_FORMAT } from '@scannable/common';

export interface DateInputProps
  extends React.ComponentProps<typeof DatePicker> {
  id: string;
  name: HTMLInputElement['name'];
  inline?: boolean;
}

export function DateInput({
  id,
  name,
  onChange,
  inline,
  value,
  ...props
}: DateInputProps) {
  return (
    <DatePicker
      id={id}
      name={name}
      {...props}
      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      selected={(value && new Date(value)) || null}
      onChange={onChange}
      inline={inline}
      onKeyDown={(e) => e.preventDefault()}
      dateFormat={ISO_8601_DATE_FORMAT} // display of date to user. We use ISO format for consistency
      todayButton={
        <button
          type="button"
          className="text-sm text-gray-700"
          onClick={(e) => onChange(new Date(), e)}
        >
          Today
        </button>
      }
      wrapperClassName="w-full"
    />
  );
}

export default DateInput;
