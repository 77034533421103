import { PublishedStatus } from '@prisma/client';
import * as yup from 'yup';

import { ChecklistAppliesTo } from '@scannable/common';
import { WrappedForm } from '@scannable/frontend/common';

export type ChecklistCriteria = {
  id?: string;
  title: string;
  description: string;
};

export type EditChecklistSidePanelProps = {
  id: string;
  isManufacturer?: boolean;
};

export type ChecklistValues = {
  name: string;
  checklistCriteria: ChecklistCriteria[];
  productVariationId: number | null;
  default: boolean;
  published: PublishedStatus;
  productGroupId: number | null;
  appliesTo: ChecklistAppliesTo;
};

export type ChecklistSidePanelProps = Partial<ChecklistValues> &
  Partial<EditChecklistSidePanelProps> &
  Partial<WrappedForm>;

export const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  checklistCriteria: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .max(255, 'Title must be less than 255 characters')
        .required('Title is required'),
    })
  ),
});
