import { useState } from 'react';
import { GroupType } from '@prisma/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { AssignToGroupActionType } from '@scannable/common';
import { useAssignToGroup } from '@scannable/frontend/common';
import { GroupTypeMappingSingle } from '@scannable/frontend/translations';
import { FormFieldOptionType } from '@scannable/frontend/types';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

interface AssignEquipmentToGroupValues {
  groupId?: number | null;
}

export interface AssignToGroupModalProps {
  title: string;
  groupType: GroupType;
  actionType: AssignToGroupActionType;
  serialisedProductId?: number;
  serialisedProductIds?: number[];
  groupId?: number;
}
const validationSchema = yup.object().shape({
  groupId: yup.number().nullable().required('Please select a group'),
});

export function AssignToGroupModal({
  title,
  actionType,
  serialisedProductIds,
  serialisedProductId,
}: AssignToGroupModalProps) {
  const { hideModal } = useModal();
  const { t } = useTranslation();
  const [selectedGroupType, setSelectedGroupType] = useState<
    GroupType | undefined
  >(undefined);
  const { options, action } = useAssignToGroup(selectedGroupType, actionType);

  const formik = useFormik({
    initialValues: {
      groupId: undefined,
    } as AssignEquipmentToGroupValues,
    validationSchema,
    onSubmit: async (
      values: AssignEquipmentToGroupValues,
      actions: FormikHelpers<AssignEquipmentToGroupValues>
    ) => {
      await resolveMutation(
        action({
          serialisedProductIds,
          serialisedProductId,
          groupId: values.groupId,
        }),
        {
          failureMessage: 'Error assigning to group',
          successMessage: 'Successfully assigned to group',
        }
      );
      actions.setSubmitting(false);
      hideModal();
    },
  });

  const handleGroupTypeSelect = (selectedType: GroupType) => {
    formik.setValues({ groupId: null });
    setSelectedGroupType(selectedType);
  };

  return (
    <Modal title={title} size="sm" overflowHidden={false}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: t('assign'),
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup
          id="groupType"
          name="groupType"
          label="Select a group type"
          type="select"
          options={[
            { label: t('clients'), value: GroupType.CLIENT },
            { label: t('equipment_type'), value: GroupType.EQUIPMENT_TYPE },
            { label: t('kit_bags'), value: GroupType.KIT_BAG },
            { label: t('locations'), value: GroupType.LOCATION },
            { label: t('staff'), value: GroupType.STAFF },
            { label: t('vehicles'), value: GroupType.VEHICLE },
          ]}
          onChange={(selectedType: FormFieldOptionType) =>
            handleGroupTypeSelect(selectedType.value as GroupType)
          }
          value={selectedGroupType}
        />
        {selectedGroupType && options && (
          <InputFieldGroup
            id="GroupId"
            name="groupId"
            type="select"
            isClearable={true}
            formik={formik}
            label={`Select ${t(GroupTypeMappingSingle[selectedGroupType])}`}
            options={options}
          />
        )}
      </ModalForm>
    </Modal>
  );
}

export default AssignToGroupModal;
