import { ReactNode } from 'react';
import { FormikProps } from 'formik';

import { Alert, Button, ButtonProps } from '../../atoms';

type FormProps<V> = {
  formik: FormikProps<V>;
  children?: ReactNode;
  warning?: string;
  buttons?: (ButtonProps & { 'data-cy'?: string })[];
  dismissable?: boolean;
  renderAction?: () => ReactNode;
} & React.HTMLAttributes<HTMLFormElement>;

export function Form<V>({
  formik,
  children,
  warning,
  buttons,
  renderAction,
  dismissable = true,
  ...props
}: FormProps<V>) {
  return (
    <div className="flex flex-1 flex-col h-full">
      <form {...props} onSubmit={formik.handleSubmit} noValidate>
        {children}
        {buttons && (
          <div className="py-3 text-right">
            {buttons?.map((buttonProps, index) => (
              <Button
                key={index}
                className="disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400 disabled:bg-gray-200"
                disabled={formik.isSubmitting || !formik.isValid}
                loading={formik.isSubmitting}
                {...buttonProps}
              />
            ))}
          </div>
        )}
      </form>
      {warning && (
        <div className="pt-4 text-sm">
          <Alert type="warning" message={warning} />
        </div>
      )}
    </div>
  );
}

export default Form;
