import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Organisation } from '@scannable/common';
import { CREATE_ORGANISATION } from '@scannable/graphql-queries';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import { Modal } from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export const createOrganisationValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
});
export function CreateOrganisation() {
  const router = useRouter();
  const { hideModal } = useModal();
  const [createOrganisation] = useMutation(CREATE_ORGANISATION, {
    refetchQueries: [Organisation.AllOrganisations],
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      manufacturerId: null,
      verified: false,
      manufacturerName: '',
      isManufacturer: false,
      isReseller: false,
      isEquipmentOwner: false,
      isInspector: false,
      isTrainingCentre: false,
    },
    validationSchema: createOrganisationValidationSchema,
    onSubmit: async (values, actions) => {
      const result = await resolveMutation(
        createOrganisation({
          variables: {
            data: {
              name: values.name,
              manufacturerId: values.manufacturerId || null,
              verified: values.verified,
              manufacturerName: values.manufacturerName,
              isManufacturer: values.isManufacturer,
              isReseller: values.isReseller,
              isEquipmentOwner: values.isEquipmentOwner,
              isInspector: values.isInspector,
              isTrainingCentre: values.isTrainingCentre,
            },
          },
        }),
        { successMessage: 'Organisation created' }
      );
      if (result.ok) {
        router.push(
          `/admin/organisations/${result.data.createOrganisation.id}`
        );
        hideModal();
      }
      actions.setSubmitting(false);
    },
  });

  return (
    <Modal title="Create Organisation">
      <ModalForm
        dismissable={true}
        formik={formik}
        buttons={[
          {
            label: 'Create',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
          },
        ]}
      >
        <>
          <InputFieldGroup
            {...{
              type: 'text',
              name: 'name',
              label: 'Organisation Name',
              placeholder: 'Enter Organisation Name',
            }}
            formik={formik}
          />
          <InputFieldGroup
            {...{
              type: 'text',
              name: 'manufacturerName',
              label:
                'Add an optional name for the Manufacturer. If no name is set, the organisation name will be used',
              placeholder: 'Enter Manufacturer Name',
            }}
            formik={formik}
          />
          <InputFieldGroup
            type="checkbox"
            name="verified"
            label="Is this a verified manufacturer?"
            formik={formik}
          />
          <label className="block text-sm font-medium text-gray-700">
            Customer Type
          </label>
          <InputFieldGroup
            type="checkbox"
            name="isManufacturer"
            label="Manufacturer"
            formik={formik}
          />
          <InputFieldGroup
            type="checkbox"
            name="isReseller"
            label="Reseller"
            formik={formik}
          />
          <InputFieldGroup
            type="checkbox"
            name="isEquipmentOwner"
            label="Equipment Owner"
            formik={formik}
          />
          <InputFieldGroup
            type="checkbox"
            name="isInspector"
            label="Inspector"
            formik={formik}
          />
          <InputFieldGroup
            type="checkbox"
            name="isTrainingCentre"
            label="Training Centre"
            formik={formik}
          />

          <p className="text-sm text-gray-500">
            Organisations are created with PRO subscription by default.
          </p>
        </>
      </ModalForm>
    </Modal>
  );
}

export default CreateOrganisation;
