import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { ProductVariation } from '@scannable/common';
import { useProductSkus } from '@scannable/frontend/common';
import { CREATE_PRODUCT_VARIATION } from '@scannable/graphql-queries';

import { SidePanelForm } from '../../forms';
import { useManufacturerProducts } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';

interface ProductVariationCreateValues {
  code: string;
  name: string;
  ean: string;
  cloneId: number | null;
  productId: number | null;
}

const addVariationValidationSchema = yup.object().shape({
  code: yup.string().required('Please enter code for new variation'),
});

type CreateProductVariationSidePanelProps = {
  manufacturerId?: number;
  productId?: number;
  cloneId?: number;
};
export function CreateProductVariationSidePanel({
  manufacturerId,
  productId,
  cloneId,
}: CreateProductVariationSidePanelProps) {
  const [createProductVariation] = useMutation(CREATE_PRODUCT_VARIATION, {
    refetchQueries: [ProductVariation.PaginatedProductVariationsByProduct],
  });
  const { options } = useManufacturerProducts({
    manufacturerId,
  });

  const formik = useFormik<ProductVariationCreateValues>({
    initialValues: {
      code: '',
      name: '',
      ean: '',
      cloneId: cloneId ?? null,
      productId: productId ?? null,
    },
    enableReinitialize: true,
    validationSchema: addVariationValidationSchema,
    onSubmit: async (values, actions) => {
      if (!values.productId) {
        actions.setSubmitting(false);
        return;
      }
      await resolveMutation(
        createProductVariation({
          variables: {
            data: {
              productId: values.productId,
              name: values.name,
              code: values.code,
              ean: values.ean,
              cloneId: values.cloneId,
            },
          },
        }),
        { successMessage: 'Product Variation created successfully' }
      );
      actions.resetForm();
      actions.setSubmitting(false);
    },
  });
  const { options: productVariationOptions } = useProductSkus(
    formik.values.productId
  );

  return (
    <SidePanelForm
      formik={formik}
      buttons={[
        {
          type: 'submit',
          loading: formik.isSubmitting,
          color: 'primary',
          label: 'Create SKU',
        },
      ]}
    >
      <InputFieldGroup
        id="productId"
        name="productId"
        type="select"
        formik={formik}
        label="Product"
        options={options}
      />
      {formik.values.productId && (
        <InputFieldGroup
          id="cloneId"
          name="cloneId"
          type="select"
          formik={formik}
          label="Copy from existing SKU"
          options={productVariationOptions}
        />
      )}
      <InputFieldGroup
        id="code"
        name="code"
        data-cy="code"
        type="text"
        formik={formik}
        label="Part Number"
      />
      <InputFieldGroup
        id="code"
        name="name"
        data-cy="name"
        type="text"
        formik={formik}
        label="SKU Name"
      />
      <InputFieldGroup
        id="ean"
        name="ean"
        data-cy="ean"
        type="text"
        formik={formik}
        label="EAN/UPC/Barcode"
      />
    </SidePanelForm>
  );
}

export default CreateProductVariationSidePanel;
