import { ItemHistory } from '@scannable/common';

import { graphql } from '../graphql';

export const PAGINATED_ITEM_HISTORY = graphql(`
  query ${ItemHistory.PaginatedItemHistory}(
    $serialisedProductId: Int!
    $orderBy: [SerialisedProductEventOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedItemHistory(
      serialisedProductId: $serialisedProductId
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        title
        date
        formattedDate
        type
        actions {
          type
          label
          link
        }
        event
        user
      }
      totalCount
    }
  }
`);
