import { GENERIC_ERROR_MESSAGE } from '@scannable/common';
import { WrappedForm } from '@scannable/frontend/common';

import { LoadingIcon, Text } from '../../atoms';
import { SidePanelLayout } from '../../layouts';

type WithSidePanelLoadingProps = WrappedForm;

export function withSidePanelLoading<T extends WithSidePanelLoadingProps>(
  Component: React.FC<T>
) {
  return (props: T) => {
    if (props.loading) {
      return (
        <SidePanelLayout>
          <LoadingIcon size="sm" />
        </SidePanelLayout>
      );
    }
    if (props.error) {
      return (
        <SidePanelLayout>
          <Text>{GENERIC_ERROR_MESSAGE}</Text>
        </SidePanelLayout>
      );
    }
    return <Component {...props} />;
  };
}
