import { useBoolean } from '@scannable/frontend/common';

import Link from '../../atoms/Link/Link';
import { SerialPurchaseOrderData } from '../../types/table-data.types';
import { TableColumn, TableRowAction } from '../../types/table.types';
import TableCell from '../TableCell/TableCell';
import TableRow from '../TableRow/TableRow';
import TableRowActions from '../TableRowActions/TableRowActions';

interface RowRenderProps {
  row: SerialPurchaseOrderData;
  rowNum: number;
  columns: TableColumn<SerialPurchaseOrderData>[];
  rowActions: TableRowAction<SerialPurchaseOrderData>[] | null;
}

export function ItemRow({ row, rowNum, columns, rowActions }: RowRenderProps) {
  const [show, setShow] = useBoolean(false);

  return (
    <>
      <TableRow data-cy="table-row">
        {columns.map((cell, cellNum) => {
          return (
            <TableCell
              key={`${rowNum}-${cellNum}`}
              testId={String(cell.accessor)}
              className="px-4"
            >
              <span className="cursor-pointer" onClick={setShow.toggle}>
                {cell.cellRender
                  ? cell.cellRender(row, rowNum)
                  : (row[cell.accessor] || '').toString()}
              </span>
            </TableCell>
          );
        })}
        {rowActions &&
        rowActions.filter((action) => !action.filter || action.filter(row))
          .length > 0 ? (
          <TableRowActions
            rowActions={rowActions.filter(
              (action) => !action.filter || action.filter(row)
            )}
            item={row}
          />
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      {show && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {row.serialisedProductVariations &&
            row.serialisedProductVariations.map((r) => (
              <TableRow key={r.id}>
                <TableCell key={r.id} colSpan={rowActions ? 8 : 7}>
                  <div className="flex items-center">
                    <div className="ml-4">
                      <Link
                        textLink
                        href={`/admin/products?tab=skus&search=${r.productVariation.code}&table=skus`}
                      >
                        {r.productVariation.name || r.productVariation.code}
                      </Link>
                      <div className="text-gray-500">
                        Serial quantity: {r.serialQuantity}
                      </div>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </>
      )}
    </>
  );
}

export default ItemRow;
