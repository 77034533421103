import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';

import { Notification } from '@scannable/common';
import {
  useGetNotificationCountQuery,
  useMarkAllAsReadMutation,
  usePaginatedNotifications,
} from '@scannable/frontend/common';
import { NOTIFICATIONS_SUBSCRIPTION } from '@scannable/graphql-queries';

import { Button } from '../../atoms/Button/Button';
import useInfiniteScroll from '../../hooks/useInfiniteScroll/useInfiniteScroll';
import useTranslation from '../../hooks/useTranslation/useTranslation';
import { SidePanelLayout } from '../../layouts';
import LoadingBlock from '../../organisms/LoadingBlock/LoadingBlock';
import { useSidePanel } from '../SidePanelContext/SidePanelContext';
import { NotificationListItem } from './NotificationListItem';

export function NotificationsSidePanel() {
  const { loading, data, loadMore, hasMore, refetch } =
    usePaginatedNotifications();
  const { refetch: refetchCount } = useGetNotificationCountQuery();
  const { hideSidePanel } = useSidePanel();
  const { t } = useTranslation();
  const loader = useInfiniteScroll({ loadMore, hasMore });
  const [markAllAsRead] = useMarkAllAsReadMutation({
    refetchQueries: [Notification.GetNotificationCount],
  });

  useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    onData() {
      refetch();
      refetchCount();
    },
  });

  useEffect(() => {
    if (data) {
      markAllAsRead();
      refetch();
    }
  }, [markAllAsRead, data, refetch]);

  if (loading) {
    return <LoadingBlock />;
  }

  return (
    <SidePanelLayout
      actions={() => (
        <Button className="w-full" onClick={hideSidePanel}>
          {t('done_btn')}
        </Button>
      )}
    >
      {data?.paginatedNotifications?.nodes?.map((notification) => (
        <NotificationListItem
          key={notification.id}
          notification={notification}
        />
      ))}
      <div ref={loader} className="h-10"></div>
    </SidePanelLayout>
  );
}
