import { ItemProductData } from '@scannable/common';

import { graphql } from '../graphql';

export const FIND_ITEM_DATA_FIELD = graphql(`
    query ${ItemProductData.ItemDataField}($data: FindOneItemData!) {
      itemDataField(data: $data) {
        id
        value
      }
    }
  `);
