import { cn } from '@scannable/common';

export interface AdminPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  topBorder?: 'lime';
  childrenClassName?: string;
}

export function AdminPanel({
  children,
  className,
  childrenClassName,
  topBorder,
}: AdminPanelProps) {
  const topBorderClass =
    topBorder === 'lime' ? 'border-scannable-lime border-t-8' : '';
  return (
    <div className={cn('shadow sm:rounded-md ', className || '')}>
      <div
        className={cn(
          'bg-white sm:rounded-md py-6 px-4 space-y-6 sm:p-6 h-full',
          topBorderClass,
          childrenClassName || ''
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default AdminPanel;
