import {
  UseSkuScreenData,
  useSkuScreenQuery,
} from '../product-variation/queries';
import {
  UseItemScreenData,
  useItemScreenQuery,
} from '../serialised-product/queries';

export type UseSkuOrItemData = UseItemScreenData | UseSkuScreenData;
interface UseProductQueryProps {
  productVariationId?: number | null;
  serialisedProductId?: number | null;
  skip?: boolean;
}

const resolveData = (
  skuData: ReturnType<typeof useSkuScreenQuery>['data'],
  itemData: ReturnType<typeof useItemScreenQuery>['data']
) => {
  if (skuData) {
    return skuData.productVariation;
  }
  if (itemData) {
    return itemData.serialisedProduct;
  }
  return null;
};

export function useSkuOrItemScreenQuery({
  productVariationId,
  serialisedProductId,
  skip = false,
}: UseProductQueryProps) {
  if (serialisedProductId && productVariationId) {
    productVariationId = undefined;
  }
  if (!serialisedProductId && !productVariationId && !skip) {
    throw new Error(
      'Must provide either productVariationId or serialisedProductId'
    );
  }

  const {
    data: skuData,
    error: skuError,
    loading: skuLoading,
  } = useSkuScreenQuery({
    productVariationId,
  });

  const {
    data: itemData,
    error: itemError,
    loading: itemLoading,
  } = useItemScreenQuery({
    serialisedProductId,
  });
  const data = resolveData(skuData, itemData);
  const loading = data ? false : serialisedProductId ? itemLoading : skuLoading;
  const error = serialisedProductId ? itemError : skuError;

  return {
    data,
    error,
    loading,
  };
}
