import { FormikProps } from 'formik';

import CustomForm from '../CustomForm/CustomForm';

export interface MetadataFieldGroupProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  id: string;
  name: string;
  children: React.ReactNode;
}

export function MetadataFieldGroup({
  title,
  formik,
  id,
  name,
  children,
}: MetadataFieldGroupProps) {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500">
      <div className="flex">
        <div className="mr-4 w-28">
          <p className="text-sm font-medium text-gray-900">{title}</p>
        </div>
        <CustomForm className="sm:flex sm:items-center" formik={formik}>
          <div className="sm:flex sm:items-center">
            <div className="w-full sm:max-w-xs focus:outline-none">
              <label htmlFor={id} className="sr-only">
                {name}
              </label>

              {children}

              {formik.touched['value'] && formik.errors['value'] && (
                <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
                  {`${formik.errors['value']}`}
                </p>
              )}
            </div>
            <button
              type="submit"
              className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
          </div>
        </CustomForm>
      </div>
    </div>
  );
}

export default MetadataFieldGroup;
