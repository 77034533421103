import { useCallback, useState } from 'react';
import { ApolloError, QueryResult } from '@apollo/client';

import {
  AppEvent,
  ProductRecord,
  SearchFilter,
  SearchType,
} from '@scannable/common';
import {
  GlobalItemSearchResult,
  InventorySearchResult,
  Search,
  SearchEventType,
  SkuSearchResult,
} from '@scannable/frontend/types';

import {
  useLazyGlobalItemSearch,
  useLazyInventorySearch,
  useLazySkuSearch,
} from '../search/searchQueries';

type LastSearchTerm = {
  type: SearchType;
  term: string;
};

type SearchQueryResult = Promise<
  QueryResult<{
    globalItemSearch?: GlobalItemSearchResult[];
    skuSearch?: SkuSearchResult[];
    inventorySearch?: InventorySearchResult[];
  }>
>;

export type ProductSearchResult =
  | InventorySearchResult
  | SkuSearchResult
  | GlobalItemSearchResult;

export interface UseProductSearch {
  data?:
    | InventorySearchResult[]
    | SkuSearchResult[]
    | GlobalItemSearchResult[]
    | null;
  loading: boolean;
  error: unknown;
  search: (
    searchTerm: string,
    type: SearchType,
    filters?: SearchFilter<ProductRecord>[],
    eventType?: AppEvent
  ) => SearchQueryResult;
  lastSearchTerm?: LastSearchTerm;
}

export function useProductSearch({
  onSearchCallback,
  resetSearch,
}: {
  onSearchCallback?: (result: Search) => void;
  resetSearch?: () => void;
}): UseProductSearch {
  const [searchedType, setSearchedType] = useState<SearchType | null>(null);
  const [lastSearchTerm, setLastSearchTerm] = useState<LastSearchTerm | null>(
    null
  );
  const [
    inventorySearch,
    {
      loading: inventorySearchLoading,
      data: inventorySearchData,
      error: inventorySearchError,
    },
  ] = useLazyInventorySearch({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (
        onSearchCallback &&
        data.inventorySearch &&
        data.inventorySearch.length > 0
      ) {
        const [item] = data.inventorySearch;
        item.serialisedProductId &&
          onSearchCallback({
            ...item,
            id: item.serialisedProductId,
          });
      }
      if (onSearchCallback && resetSearch) {
        resetSearch();
      }
    },
  });
  const [
    globalSearch,
    {
      loading: globalSearchLoading,
      data: globalSearchData,
      error: globalSearchError,
    },
  ] = useLazyGlobalItemSearch({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (
        onSearchCallback &&
        data.globalItemSearch &&
        data.globalItemSearch.length > 0
      ) {
        const [item] = data.globalItemSearch;

        item.serialisedProductId &&
          onSearchCallback({ ...item, id: item.serialisedProductId }); // how to handle more than one item????
        resetSearch && resetSearch();
      }
    },
  });
  const [
    skuSearch,
    { loading: skuSearchLoading, data: skuSearchData, error: skuSearchError },
  ] = useLazySkuSearch({
    fetchPolicy: 'network-only',
  });

  const search = useCallback(
    async (
      st: string,
      type: SearchType,
      filters?: SearchFilter<ProductRecord>[],
      eventType?: SearchEventType
    ) => {
      setLastSearchTerm({
        type,
        term: st,
      });
      const variables = {
        data: {
          term: st,
          filters,
          type: eventType,
        },
      };
      setSearchedType(type);
      if (type === 'SkusOnly') {
        return skuSearch({
          variables,
        });
      }
      if (type === 'InventoryItemsOnly') {
        return inventorySearch({
          variables,
        });
      }
      if (type === 'GlobalItemsOnly') {
        return globalSearch({
          variables,
        });
      }
      throw new Error('Invalid search type');
    },
    [globalSearch, inventorySearch, skuSearch]
  );

  const dataMap = {
    SkusOnly: skuSearchData?.skuSearch,
    InventoryItemsOnly: inventorySearchData?.inventorySearch,
    GlobalItemsOnly: globalSearchData?.globalItemSearch,
  };

  const errorMap: Record<SearchType, ApolloError | undefined> = {
    SkusOnly: skuSearchError,
    InventoryItemsOnly: inventorySearchError,
    GlobalItemsOnly: globalSearchError,
  };

  const loadingMap: Record<SearchType, boolean> = {
    SkusOnly: skuSearchLoading,
    InventoryItemsOnly: inventorySearchLoading,
    GlobalItemsOnly: globalSearchLoading,
  };

  return {
    loading: searchedType ? loadingMap[searchedType] : false,
    error: searchedType ? errorMap[searchedType] : null,
    data: searchedType ? dataMap[searchedType] : undefined,
    // todo - fix this
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    search,
    lastSearchTerm: lastSearchTerm ? lastSearchTerm : undefined,
  };
}
