import { useQuery } from '@apollo/client';

import { SortOrder } from '@scannable/frontend/common';
import { GET_ALL_MY_PRODUCTS } from '@scannable/graphql-queries';

export type UseManufacturerProducts = {
  options: { label: string; value: number }[];
};

export type UseManufacturerProductsProps = {
  manufacturerId?: number;
};

export function useManufacturerProducts({
  manufacturerId,
}: UseManufacturerProductsProps): UseManufacturerProducts {
  const { data } = useQuery(GET_ALL_MY_PRODUCTS, {
    variables: {
      orderBy: [{ name: SortOrder.asc }],
      where: manufacturerId
        ? {
            manufacturerId: {
              equals: manufacturerId,
            },
          }
        : {},
    },
  });

  const options = (data?.products ?? [])
    .map((pg) => ({
      label: pg.name,
      value: pg.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    options: options ?? [],
  };
}

export default useManufacturerProducts;
