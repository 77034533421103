import { GraphQLRequest } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import * as Sentry from '@scannable/frontend/sentry';

import { ApolloConfig } from '../types/client.types';
import {
  addPendingRequest,
  checkTokenExpiry,
  clearPendingRequests,
  getNewToken,
  isRefreshing,
  resolvePendingRequests,
  setIsRefreshing,
} from '../utils/utils';

export const jwtExpiryLink = ({
  endpoint,
  getOrganisationId,
  getRefreshToken,
  getToken,
  setToken,
  setRefreshToken,
  onRefreshTokenError,
}: ApolloConfig) =>
  setContext(async (operation, { headers }) => {
    const token = await getToken();
    const isTokenExpired = checkTokenExpiry(token, 15000);

    const handleError = (operation: GraphQLRequest) => {
      setIsRefreshing(false);
      clearPendingRequests();
      onRefreshTokenError();
    };
    if (isTokenExpired) {
      if (!isRefreshing) {
        setIsRefreshing(true);
        try {
          const { data, errors } = await getNewToken(
            endpoint,
            getOrganisationId,
            getRefreshToken
          );
          if (!errors && data?.refreshToken) {
            const {
              refreshToken: { accessToken, refreshToken },
            } = data;
            await setToken(accessToken);
            if (setRefreshToken) {
              await setRefreshToken(refreshToken);
            }
            setIsRefreshing(false);
            resolvePendingRequests();
            return {
              headers: {
                ...headers,
                authorization: `Bearer ${accessToken}`,
              },
            };
          }
          Sentry.addBreadcrumb({
            category: 'auth',
            message: 'Error refreshing token',
            level: 'error',
            data: { errors },
          });
          throw new Error('Error refreshing token');
        } catch (error) {
          Sentry.captureException(error);
          handleError(operation);
        }
      } else {
        return new Promise((resolve) => {
          addPendingRequest(async () => {
            const newToken = await getToken();
            resolve({
              headers: {
                ...headers,
                authorization: `Bearer ${newToken}`,
              },
            });
          });
        });
      }
    }
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });
