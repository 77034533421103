import {
  AUTH_RESET_URL,
  KIT_ID_URL,
  NFC_URL,
  SIGN_IN_ROUTE,
} from './constants';
import { cleanseSearchString, removeNfcIdFromString } from './formatting';

export const stringIsAValidUrl = (s: string) => {
  try {
    new URL(s);
    return true;
  } catch (err) {
    return false;
  }
};

export function fileNameFromUrl(s3Url: string) {
  try {
    const url = new URL(s3Url);

    // Decode the pathname
    const decodedPathname = decodeURIComponent(url.pathname);

    // Split the decoded pathname to get the segments
    const paths = decodedPathname.split('/');

    return paths[paths.length - 1];
  } catch (_) {
    return false;
  }
}

export const isSerialisedUrl = (url: string) => {
  const codes = ['01', '21'];

  const filtered = url.split('/').filter((elem) => codes.includes(elem));
  if (filtered.length < 2) {
    return false;
  }
  return true;
};

export const getYoutubeId = (url: string): string | false => {
  const videoIdMatch = url.match(
    /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be|youtube-nocookie\.com)\/(?:watch\?v=)?([^\s&]+)/
  );
  return videoIdMatch ? videoIdMatch[1] : false;
};

export const getYoutubeIdFromEmbedUrl = (url: string): string | null => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  }

  return null;
};

export const areEqual = (a: (string | number)[], b: (string | number)[]) => {
  if (a.length === b.length) {
    return a.every((e) => {
      if (b.includes(e)) {
        return true;
      }
      return false;
    });
  }
  return false;
};

export const isBrowser = typeof window !== 'undefined';

/**
 * converts serial url to kit.id to search unique url
 * @param url
 * @returns
 */
export const extractSerialFromUrl = (url: string) => {
  const segments = url.split('/');
  const codeIndex = segments.findIndex((elem) => elem === '01');
  const serialIndex = segments.findIndex((elem) => elem === '21');
  const uri = segments.slice(codeIndex).join('/');

  if (codeIndex === -1 || serialIndex === -1) {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname.slice(1); // todo tidy this up
  }

  uri.endsWith('/') ? uri.slice(0, -1) : uri;
  return `${KIT_ID_URL}${uri.endsWith('/') ? uri.slice(0, -1) : uri}`;
};

export const isAliasTag = (url: string) => {
  return (
    url.includes('nfc.scannable.io') ||
    url.includes('https://www.scannable.io/tags')
  );
};

export const isScannableUrl = (url: string) => {
  return url.includes(KIT_ID_URL) || url.includes(NFC_URL);
};

export function extractUniqueIdFromSearchTerm(rawSearch: string) {
  // first cleanse the url
  const searchTerm = cleanseSearchString(rawSearch);

  if (isAliasTag(searchTerm)) {
    return extractAliasFromNfcUrl(searchTerm);
  }
  if (stringIsAValidUrl(searchTerm)) {
    return extractSerialFromUrl(searchTerm);
  }

  return searchTerm;
}

export const extractAliasFromNfcUrl = (url: string) => {
  //if just an alias without url prefix return it
  if (url.indexOf('/') === -1 && url.length > 0) {
    return removeNfcIdFromString(url);
  }

  // if it's got no protocol and nfc.scannable.io return the alias
  if (url.startsWith(new URL(NFC_URL).hostname)) {
    return removeNfcIdFromString(url.split('/').splice(1).join('/'));
  }

  if (url.startsWith('https://www.scannable.io/tags')) {
    return removeNfcIdFromString(url.split('/').pop() ?? '');
  }
  const uniqueIdMatch = url.match(/nfc\.scannable\.io\/(0x[0-9A-F]+)/i);
  if (
    uniqueIdMatch &&
    uniqueIdMatch.length > 0 &&
    isAliasTag(uniqueIdMatch[0])
  ) {
    return removeNfcIdFromString(uniqueIdMatch[0].split('/').pop() ?? '');
  }

  try {
    const u = new URL(url);
    if (!u.pathname) {
      return '';
    }
    return removeNfcIdFromString(
      u.pathname.split('/').filter(Boolean)[0] ?? ''
    );
  } catch (e) {
    return '';
  }
};

export const redirectToSignIn = (path: string | null = null) => {
  return `${SIGN_IN_ROUTE}${
    path ? `?redirect=${encodeURIComponent(path)}` : ''
  }`;
};

export const constructRedirectURI = (
  params: Record<string, string | null> = {}
) => {
  const queryString = Object.entries(params)
    .filter(([, v]) => Boolean(v))
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key as string)}=${encodeURIComponent(
          value as string
        )}`
    )
    .join('&');
  return `${SIGN_IN_ROUTE}${queryString ? `?${queryString}` : ''}`;
};

export const redirectHandler = (path?: string | null) => {
  return `${AUTH_RESET_URL}${
    path ? `?redirect=${encodeURIComponent(path)}` : ''
  }`;
};

export function calculatePercentage(used?: number, max?: number | null) {
  return used && max ? Number(((used / max) * 100).toFixed(2)) : 0;
}
