import { Invites } from '@scannable/common';

import { graphql } from '../graphql';

export const GET_INVITE = graphql(`query ${Invites.GetInvite}($token: String!) {
    invite(token: $token) {
        email
    }
    }`);

export const GET_INVITES = graphql(`query ${Invites.GetInvites} {
    invitesByUser {
      id
      inviteId
      name
      createdAt
      organisationId
    }
  }`);
