import jwt_decode from 'jwt-decode';

import { JwtAuthPayload } from '../types/jwt-payload.types';

export const decodeJwt = (token: string) => {
  return jwt_decode<JwtAuthPayload>(token);
};

export const initialAuthState = {
  user: null,
  token: null,
  userId: 0,
  loading: true,
  redirecting: false,
  roles: [],
  modules: [],
  organisations: [],
  manufacturerId: 0,
  organisationId: 0,
  organisationUuid: null,
  isPersonalAccount: false,
  organisationName: null,
  subscriptionStatus: null,
  subscriptionType: null,
  trialEnds: null,
  activated: false,
  integrations: [],
};
