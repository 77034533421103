import Head from 'next/head';

interface PageHeadOgProps {
  pageTitle?: string;
  title?: string;
  description?: string;
  url?: string;
  image?: string;
}
export function PageHeadOg({
  pageTitle = 'Scannable | Easily scan, track, and manage your safety equipment',
  title,
  description,
  url,
  image = '/static/scannable-og-image.png',
}: PageHeadOgProps) {
  const ogTitle = title || pageTitle;
  const ogDescription =
    description ||
    'Scannable lets you track the lifecycle of your gear, and gives you access to manufacturer data and compliance information at the tap of a phone.';
  const ogUrl = url || 'https://app.scannable.io/';
  const ogImage = image || '/static/scannable-og-image.png';
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:image" content={ogImage} />
    </Head>
  );
}
