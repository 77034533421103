import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { HardwareType, Printing } from '@scannable/common';
import { CREATE_HARDWARE } from '@scannable/graphql-queries';

import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import Modal from '../../modals/Modal/Modal';
import { useModal } from '../../modals/ModalContext/ModalContext';
import { InputFieldGroup } from '../../molecules';
import ModalForm from '../ModalForm/ModalForm';

type CreateHardwarePrinterValues = {
  hardwareId: string;
  name: string;
  type: HardwareType;
};

export function CreateHardwarePrinterForm() {
  const [createHardware] = useMutation(CREATE_HARDWARE, {
    refetchQueries: [Printing.GetHardwareByType],
  });
  const { hideModal } = useModal();
  const { t } = useTranslation();
  const validationSchema = {
    hardwareId: yup.string().required('Printer ID is required'),
    name: yup.string().required('Name is required'),
  };
  const formik = useFormik({
    initialValues: {
      hardwareId: '',
      name: '',
      type: HardwareType.PRINTER,
    },
    validationSchema: yup.object(validationSchema),
    onSubmit: async (
      values: CreateHardwarePrinterValues,
      { setSubmitting }
    ) => {
      const result = await resolveMutation(
        createHardware({
          variables: {
            data: {
              hardwareId: values.hardwareId,
              name: values.name,
              type: HardwareType.PRINTER,
            },
          },
        }),
        {
          successMessage: 'Printer added',
        }
      );
      if (result.ok) {
        setSubmitting(false);
        hideModal();
      }
    },
  });
  return (
    <Modal title="Add Printer">
      <ModalForm
        formik={formik}
        buttons={[
          {
            type: 'submit',
            label: t('save'),
            disabled: !formik.isValid,
          },
        ]}
      >
        <InputFieldGroup formik={formik} name="name" label="Name" type="text" />
        <InputFieldGroup
          formik={formik}
          name="hardwareId"
          label="Printer ID"
          type="text"
        />
      </ModalForm>
    </Modal>
  );
}
export default CreateHardwarePrinterForm;
