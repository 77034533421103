import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import { classNames } from '@scannable/common';

import { useTranslation } from '../../hooks';

const getRange = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(undefined)
    .map((v, i) => i + start);
};

const pagination = (
  currentPage: number,
  pageCount: number
): Array<number | '...'> => {
  let delta: number;
  if (pageCount <= 7) {
    delta = 7;
  } else {
    delta = currentPage > 4 && currentPage < pageCount - 3 ? 2 : 4;
  }

  const range = {
    start: Math.round(currentPage - delta / 2),
    end: Math.round(currentPage + delta / 2),
  };

  if (range.start - 1 === 1 || range.end + 1 === pageCount) {
    range.start += 1;
    range.end += 1;
  }

  let pages =
    currentPage > delta
      ? getRange(
          Math.min(range.start, pageCount - delta),
          Math.min(range.end, pageCount)
        )
      : getRange(1, Math.min(pageCount, delta + 1));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const withDots = (value: number, pair: any[]) =>
    pages.length + 1 !== pageCount ? pair : [value];

  if (pages[0] !== 1) {
    pages = withDots(1, [1]).concat(pages);
  }

  if (pages[pages.length - 1] < pageCount) {
    pages = pages.concat(withDots(pageCount, ['...', pageCount]));
  }

  return pages;
};

export interface PaginationProps {
  next: () => void;
  prev: () => void;
  goToPage: (p: number) => void;
  offset: number;
  pageCount: number;
  defaultPageSize: number;
  totalCount: number;
}

export function Pagination({
  next,
  prev,
  goToPage,
  totalCount,
  offset,
  pageCount,
  defaultPageSize,
}: PaginationProps) {
  const { t } = useTranslation();

  const pageStart = offset + 1;
  const pageEnd = pageStart + pageCount - 1;
  const hasMorePagesToGo = pageEnd < totalCount;

  const nextMouseClasses =
    pageEnd < totalCount ? 'cursor-pointer' : 'cursor-not-allowed';

  const prevMouseClasses = offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed';

  const nextPage = hasMorePagesToGo ? () => next() : undefined;

  const numberOfPages = Math.ceil(totalCount / defaultPageSize);
  const currentPage = Math.ceil(offset / defaultPageSize) + 1;

  return (
    <div className="flex items-center justify-between bg-white px-4 py-2 sm:px-6 sm:rounded-b-lg">
      <div className="flex flex-1 justify-between sm:hidden">
        <span
          onClick={() => prev()}
          className={classNames(
            prevMouseClasses,
            'relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
          )}
        >
          {t('previous_btn')}
        </span>
        <span
          onClick={nextPage}
          className={classNames(
            nextMouseClasses,
            'relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
          )}
        >
          {t('next_btn')}
        </span>
      </div>
      <div className="hidden w-full sm:flex sm:items-center justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{pageStart}</span> to{' '}
            <span className="font-medium">{pageEnd}</span> of{' '}
            <span className="font-medium">{totalCount}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <span
              onClick={() => prev()}
              className={classNames(
                prevMouseClasses,
                'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
              )}
            >
              <span className="sr-only">{t('previous_btn')}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            {pagination(currentPage, numberOfPages).map((pageNumber, i) => {
              if (pageNumber === 0) {
                return (
                  <span
                    key={i}
                    className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
                  >
                    ...
                  </span>
                );
              }

              const current = pageNumber === currentPage;
              const isSpacer = pageNumber === '...';
              return (
                <span
                  key={i}
                  onClick={
                    !current && !isSpacer
                      ? () => goToPage(pageNumber)
                      : undefined
                  }
                  data-cy={`page-${pageNumber}`}
                  aria-current={current ? 'page' : undefined}
                  className={classNames(
                    current
                      ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                      : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50',
                    'relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20',
                    !isSpacer ? 'cursor-pointer' : ''
                  )}
                >
                  {pageNumber}
                </span>
              );
            })}

            <span
              onClick={nextPage}
              className={classNames(
                nextMouseClasses,
                'relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
              )}
            >
              <span className="sr-only">{t('next_btn')}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
