import { cn } from '@scannable/common';

import { Icon, IconType } from '../../atoms';
import { TableRowAction } from '../../types/table.types';
import ActionsButton from '../ActionsButton/ActionsButton';

interface AdminPanelHeaderProps<T> {
  title?: string;
  renderTitle?: () => React.ReactNode;
  icon: IconType;
  children?: React.ReactNode;
  renderButtons?: () => React.ReactNode;
  actions?: (TableRowAction<T> & { item: T })[];
}
export function AdminPanelHeader<T>({
  title,
  renderTitle,
  icon,
  children,
  actions,
  renderButtons,
}: AdminPanelHeaderProps<T>) {
  return (
    <div className={cn('shadow sm:rounded-md mb-4')}>
      <div className="bg-white sm:rounded-md h-full">
        <div
          className={cn(
            'flex justify-between items-center pb-4 pt-4 px-4',
            children ? 'border-b border-gray-200' : ''
          )}
        >
          <div className="flex">
            <div className="flex justify-center items-center bg-gray-200 rounded-lg h-10 w-10 ">
              <Icon name={icon} className="h-6 w-6" />
            </div>
            <div className="ml-4 flex items-center">
              {title && (
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  {title}
                </h1>
              )}
              {renderTitle && renderTitle()}
            </div>
          </div>
          {renderButtons && (
            <div className="flex ml-auto">{renderButtons()}</div>
          )}
          {actions && (
            <div className="flex items-center ml-4">
              <ActionsButton actions={actions} />
            </div>
          )}
        </div>
        {children && <div className="px-4 md:pb-0 pb-4">{children}</div>}
      </div>
    </div>
  );
}

export default AdminPanelHeader;
