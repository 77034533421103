import { UPGRADE_ROUTE } from '@scannable/common';

import { Button, Icon, Link } from '../../atoms';

export function UpgradePlanButton() {
  return (
    <Link href={UPGRADE_ROUTE}>
      <Button type="button" className="mr-3 w-full shadow" color="lime">
        <Icon
          name="waves"
          className="mr-2 animate-pulse text-purple-500"
          width={15}
          height={15}
        />
        Upgrade to Pro Today
      </Button>
    </Link>
  );
}

export default UpgradePlanButton;
