import { useEffect, useRef, useState } from 'react';

import { tableBtnLabel } from '@scannable/frontend/common';

import { Icon, Link } from '../../atoms';
import { useOnClickOutside, useTranslation } from '../../hooks';

export interface NfcInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: HTMLInputElement['name'];
  clearNfc?: () => void;
  onNfcScanComplete?: (nfc: string) => void;
  onRemove?: () => void;
  nfcTags: string[] | null | undefined;
}
interface NfcReaderProps extends NfcInputProps {
  isScanning: boolean;
  setIsScanning: (isScanning: boolean) => void;
  value?: string;
}
function NfcReader({
  id,
  name,
  className,
  onChange,
  onBlur,
  clearNfc,
  value,
  isScanning,
  setIsScanning,
  onNfcScanComplete,
  onRemove,
  nfcTags,
  ...props
}: NfcReaderProps) {
  const { t, tInterpolate } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // listens for typing from the nfc reader and disables scanning after no input 100ms
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setIsScanning(false);
      if (onNfcScanComplete) {
        onNfcScanComplete(event.target.value);
      }
    }, 100);
  };

  useEffect(() => {
    if (isScanning && inputRef.current) {
      inputRef.current.focus();
    }
    if (!isScanning && inputRef.current) {
      inputRef.current.blur();
    }
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [isScanning]);

  if (isScanning) {
    return (
      <div className="flex items-center">
        <input
          id={id}
          ref={inputRef}
          name={name}
          onChange={handleInputChange}
          onBlur={onBlur}
          value={value}
          className="sr-only"
          {...props}
        />
        <Link
          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
          onClick={() => setIsScanning(false)}
        >
          Ready for NFC reader...
        </Link>
      </div>
    );
  }

  const defaultNFCAddedText = t('nfc.one_nfc_added');

  return (
    <div>
      {value && (
        <div className="flex justify-between items-center mb-2.5">
          <div className="flex items-center">
            <Icon name="hasNfc" className="mr-2" width={20} height={20} />
            {nfcTags
              ? nfcTags?.length > 1
                ? tInterpolate('nfc.multiple_nfcs_added', nfcTags.length)
                : defaultNFCAddedText
              : defaultNFCAddedText}
          </div>
          {onRemove && value && (
            <Link
              className="text-gray-600 hover:text-red-600 font-medium text-xs cursor-pointer"
              onClick={onRemove}
            >
              {t('remove')}
            </Link>
          )}
        </div>
      )}
      <div className="flex justify-between items-center">
        <Link
          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
          onClick={() => setIsScanning(true)}
        >
          {tableBtnLabel(t('nfc.add_tag'))}
        </Link>
      </div>
    </div>
  );
}

export function NfcInput({
  id,
  name,
  className,
  onChange,
  onBlur,
  clearNfc,
  value,
  onNfcScanComplete,
  onRemove,
  ...props
}: NfcInputProps) {
  const [isScanning, setIsScanning] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  useOnClickOutside<HTMLInputElement>(inputRef, () => setIsScanning(false));

  return (
    <div ref={inputRef}>
      <NfcReader
        id={id}
        name={name}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        clearNfc={clearNfc}
        value={value?.toString()}
        isScanning={isScanning}
        setIsScanning={setIsScanning}
        onNfcScanComplete={onNfcScanComplete}
        onRemove={onRemove}
        {...props}
      />
    </div>
  );
}

export default NfcInput;
