import { SerialisedProduct } from '@scannable/common';

import { graphql } from '../graphql';

export const SERIALISED_PRODUCT_DATA_FOR_UPDATE =
  graphql(`query ${SerialisedProduct.GetSerialisedProductForUpdateQuery} ($id: Int!) {
  serialisedProduct(where: { id: { equals: $id } }) {
    id
    dom
    dofu
    name
    info {
      name
      serialNumber
      batchNumber
      productType
      expiryDate
      proofOfPurchase
      nfcTag
      externalId
    }
    data {
      images {
        id
        name
        uri
        dataType
      }
    }
  }
}`);

export const SERIALISED_PRODUCT_UNIQUE_URL =
  graphql(`query ${SerialisedProduct.SerialisedProductForUniqueUrl} ($where: SerialisedProductWhereInput!) {
    serialisedProduct(where: $where) {
      id
      userGenerated
      uid
      uuid
      manufacturerId
      expiryDate
      serialNumber
      dom
      dofu
      status
      parentId
      productVariationId
      info {
        name
        batchNumber
        productName
        code
        ean
        assignedUser
        ownedByName
        ownedById
        aliases
        equipmentId
        expiryDate
        nextInspectionAt
        productGroupName
        lastInspection {
          status
          date
        }
        manufacturerName
        manufacturerLogo
        proofOfPurchase
        assignedUserId
        nfcTags
        productGroupId
        nfcTag
        externalId
      }
      data {
        data {
          id
          name
          value
          unit
          order
          hasUnits
          isNumeric
          productDataType
          pdfs {
            id
            name
            url
          }
          images {
            id
            name
            uri
          }
          certifications {
            id
            name
            displayName
          }
        }
        pdfs {
          id
          name
          url
        }
        images {
          id
          name
          uri
        }
        certifications {
          id
          name
          displayName
        }
      }
      components {
        id
        uid
        serialNumber
        info {
          name
          code
          ean
          batchNumber
          productGroupName
          aliases
          lastInspection {
            status
          }
          manufacturerName
          nfcTags
        }
        image {
          id
          uri
        }
      }
      parent {
        id
        code
        name
        serialNumber
        ean
      }
    }
  }
`);
