import { useMutation } from '@apollo/client';
import { ProductType, PublishedStatus } from '@prisma/client';
import { useFormik } from 'formik';

import { ProductVariation } from '@scannable/common';
import {
  productTypeOptions,
  publishedStatusOptions,
} from '@scannable/frontend/common';
import { UPDATE_PRODUCT_VARIATION } from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { useManufacturerProducts, useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup, SidePanelButtonContainer } from '../../molecules';
import Form from '../Form/Form';

export type ProductVariantFormProps = {
  id: number;
} & UpdateProductVariationValues;

export type UpdateProductVariationValues = {
  ean: string;
  name: string;
  status: PublishedStatus;
  type: ProductType;
  productId: number;
  manufacturerId?: number;
};

export function ProductVariantForm({
  id,
  ean,
  name,
  status,
  type,
  productId,
  manufacturerId,
}: ProductVariantFormProps) {
  const { t } = useTranslation();
  const { options } = useManufacturerProducts({
    manufacturerId,
  });

  const [updateProductVariation] = useMutation(UPDATE_PRODUCT_VARIATION, {
    refetchQueries: [ProductVariation.PaginatedProductVariationsByProduct],
  });

  const formik = useFormik<UpdateProductVariationValues>({
    initialValues: {
      name: name,
      ean: ean,
      status: status,
      type: type,
      productId: productId,
    },
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      await resolveMutation(
        updateProductVariation({
          variables: {
            data: {
              id,
              name: values.name,
              ean: values.ean,
              status: values.status,
              type: values.type,
              productId: values.productId,
            },
          },
        }),
        {
          successMessage: 'Product variation updated',
        }
      );
      actions.setSubmitting(false);
    },
  });
  return (
    <Form className="h-full" formik={formik}>
      <div className="space-y-4 px-5 h-full mt-4">
        <InputFieldGroup
          id="code"
          name="name"
          type="text"
          formik={formik}
          label="Name"
        />
        <InputFieldGroup
          id="ean"
          name="ean"
          type="text"
          formik={formik}
          label="EAN / UPC / Barcode"
        />
        <InputFieldGroup
          id="productId"
          name="productId"
          type="select"
          formik={formik}
          label="Product"
          tooltip="If you think this SKU is incorrectly associated with a product, please update here."
          options={options}
        />
        <InputFieldGroup
          id="status"
          name="status"
          type="select"
          formik={formik}
          label="Status"
          config={{
            defaultSelect: false,
          }}
          options={publishedStatusOptions}
        />
        {[ProductType.ASSEMBLY, ProductType.COMPOSITE as string].includes(
          type
        ) && (
          <InputFieldGroup
            id="type"
            name="type"
            type="select"
            formik={formik}
            label="Type"
            config={{
              defaultSelect: false,
            }}
            tooltip="If you think this SKU is incorrectly set as an assembly or cut length of rope, please update here."
            options={productTypeOptions}
          />
        )}
      </div>
      <SidePanelButtonContainer>
        <Button className="w-full" data-cy="update-product-variation">
          {t('update')}
        </Button>
      </SidePanelButtonContainer>
    </Form>
  );
}

export default ProductVariantForm;
