import { Certification } from '@scannable/common';

import { graphql } from '../graphql';

export const UPDATE_CERTIFICATION = graphql(`
  mutation ${Certification.UpdateCertification}($data: UpdateCertificationInput!) {
    updateCertification(data: $data) {
      id
    }
  }
`);
export const CREATE_CERTIFICATION = graphql(`
mutation ${Certification.CreateCertification}($data: CreateCertificationInput!) {
  createCertification(data: $data) {
    id
  }
}
`);
