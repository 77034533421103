import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Teams } from '@scannable/common';
import { useCreateTeamMemberMutation } from '@scannable/frontend/common';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { useAssignToUser } from '../../hooks/useAssignToUser/useAssignToUser';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export interface CreateTeamMemberModalProps {
  title: string;
  teamId: string;
}
interface CreateTeamMemberValues {
  userId: number | null;
}

const validationSchema = yup.object().shape({
  userId: yup.number().nullable().required('Please select a user'),
});

export function CreateTeamMemberModal({
  title,
  teamId,
}: CreateTeamMemberModalProps) {
  const { hideModal } = useModal();

  const { options } = useAssignToUser('singleItem');

  const [createTeamMember] = useCreateTeamMemberMutation({
    refetchQueries: [
      Teams.GetTeamMembers,
      Teams.GetTeamInvetory,
      Teams.GetTeam,
    ],
  });

  const formik = useFormik<CreateTeamMemberValues>({
    initialValues: {
      userId: null,
    },
    validationSchema,
    onSubmit: async (
      values: CreateTeamMemberValues,
      actions: FormikHelpers<CreateTeamMemberValues>
    ) => {
      if (!values.userId) {
        return;
      }
      const result = await resolveMutation(
        createTeamMember({
          variables: {
            data: {
              teamId,
              userId: values.userId,
            },
          },
        }),
        {
          successMessage: 'Added to team successfully',
        }
      );
      actions.setSubmitting(false);
      if (result.ok) {
        hideModal();
      }
    },
  });
  return (
    <Modal title={title} overflowHidden={false}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: 'Add',
            color: 'primary',
            'data-cy': 'save-team',
          },
        ]}
      >
        <InputFieldGroup
          data-cy="user"
          id="User"
          name="userId"
          type="react-select"
          formik={formik}
          label="User"
          options={options}
        />
      </ModalForm>
    </Modal>
  );
}

export default CreateTeamMemberModal;
