import QRCode from 'react-qr-code';

import { itemUidUrl } from '@scannable/common';

import Badge from '../../atoms/Badge/Badge';
import ResizedImage from '../../atoms/ResizedImage/ResizedImage';
import { Modal, useModal } from '../../modals';

interface ProductVariationSerialProps {
  name?: string | null;
  serialNumber: string | null;
  uid: string | null;
  uri?: string | null;
}

export function ProductVariationSerial({
  name,
  serialNumber,
  uri,
  uid,
}: ProductVariationSerialProps) {
  const { showModal } = useModal();
  return (
    <div className="m-3 inline-block cursor-pointer">
      <Badge
        onClick={() =>
          showModal(
            <Modal title={name ?? ''} size="sm">
              <div className="flex p-6">
                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                  {uri ? (
                    <ResizedImage
                      className="h-full w-full object-cover object-center"
                      src={uri || ''}
                      alt={name || ''}
                      height={48}
                      width={48}
                      fit="fill"
                    />
                  ) : null}
                </div>
                <div className="ml-4 flex flex-1 flex-col">
                  <div>
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <h3>{name}</h3>
                    </div>
                    <p className="mt-1 text-sm text-gray-500">{serialNumber}</p>
                  </div>
                  <div className="flex flex-1 items-end justify-between text-sm">
                    {uid && (
                      <a
                        href={itemUidUrl(uid)}
                        target="_blank"
                        className="text-gray-500"
                        rel="noreferrer"
                      >
                        {itemUidUrl(uid)}
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="py-6 flex justify-center">
                {uid && <QRCode value={itemUidUrl(uid)} />}
              </div>
            </Modal>
          )
        }
      >
        {serialNumber || 'No serial number'}
      </Badge>
    </div>
  );
}
