export interface LoadingPlaceholderProps {
  height: string;
  width: string;
  color?: string;
}

export function LoadingPlaceholder({
  height,
  width,
  color,
}: LoadingPlaceholderProps) {
  const className = `h-${height} bg-${
    color || 'gray-300'
  } w-${width} rounded-md align-middle inline-block animate-pulse`;

  return <div className={className}></div>;
}

export default LoadingPlaceholder;
