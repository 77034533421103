import * as yup from 'yup';

export const createSerialisedRopesValidationSchema = yup.object().shape({
  dom: yup.string().nullable().required('A date is required'),
  serialFormatId: yup
    .number()
    .positive('A serial format is required')
    .required('A serial format is required'),
});

export default createSerialisedRopesValidationSchema;
