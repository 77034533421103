import { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { UseSkuOrItemData } from '@scannable/frontend/common';

import { Image } from '../../atoms';
import { PageHeadOg } from '../../organisms/PageHeadOg/PageHeadOg';
import ProductDisplay from '../../organisms/ProductDisplay/ProductDisplay';

export interface ProductPageDataTabProps {
  tabIndex: number;
  selectedTabNumber: number;
  title: string;
  children: React.ReactNode;
}

export interface ProductPageTabProps {
  name: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
}

export interface ProductPageProps {
  product: UseSkuOrItemData;
  tab: number;
}

export function ProductPage({ product, tab }: ProductPageProps) {
  const router = useRouter();
  const [tabNumber, setTabNumber] = useState(tab);

  useEffect(() => {
    setTabNumber(tab);
  }, [tab]);

  const changeTab = (tabNumber: number) => {
    setTabNumber(tabNumber);
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          tab: tabNumber,
        },
      },
      undefined,
      { shallow: true }
    );
  };

  return (
    <>
      <Head>
        <PageHeadOg title={`Scannable | ${product?.info?.name ?? 'Product'}`} />
      </Head>
      <div className="container-sm min-h-screen">
        <ProductDisplay
          product={product}
          tabNumber={tabNumber}
          changeTab={changeTab}
          isPublicUrl
          showAddToInventory
          showOptionsButton={false}
        />
        <div className="flex items-start justify-center pb-2">
          <a
            href="https://apps.apple.com/nz/app/scannable-safety-data/id1622332012"
            target="_blank"
            rel="noopener noreferrer"
            className="flex mt-3 w-48 justify-center"
          >
            <Image
              src="/static/ios-app-store.svg"
              alt="Scannable on the App Store"
              className="object-contain object-center"
              width={150}
              height={40}
            />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=com.scannable.app"
            target="_blank"
            rel="noopener noreferrer"
            className="flex w-48 justify-center"
          >
            <Image
              src="/static/google-play-badge.png"
              alt="Scannable on the Google Play Store"
              className="object-contain object-center"
              width={190}
              height={60}
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default ProductPage;
