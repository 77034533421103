import { useCallback } from 'react';
import {
  InspectionSource,
  InspectionStatus,
  InspectionType,
} from '@prisma/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { Search } from '@scannable/frontend/types';

export const MULTI_SCAN_FEATURE_KEY = 'multiScan';

export interface MultiScanState {
  items: Search[];
}

export const initialMultiScanState: MultiScanState = {
  items: [],
};

type Inspection = {
  id: number;
  passed: boolean;
  source: InspectionSource;
  status: InspectionStatus;
  type: InspectionType;
  isCompetentInspector: boolean;
};
export interface InspectionsById {
  [id: number]: Inspection;
}

export const multiScanSlice = createSlice({
  name: MULTI_SCAN_FEATURE_KEY,
  initialState: initialMultiScanState,
  reducers: {
    add: (state, action: PayloadAction<Search>) => {
      const isAdded = state.items.some(
        (i) => i.serialisedProductId === action.payload.serialisedProductId
      );
      if (isAdded) {
        state.items = state.items.map((item) => {
          if (item.serialisedProductId === action.payload.serialisedProductId) {
            return action.payload;
          }
          return item;
        });
      } else {
        state.items = [...state.items, action.payload];
      }
    },
    update: (state, action: PayloadAction<Search>) => {
      state.items = state.items.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    remove: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter(
        (item) => item.serialisedProductId !== action.payload
      );
    },
    clear: (state) => {
      state.items = [];
    },
    onInspectionsCompleted: (state, action: PayloadAction<InspectionsById>) => {
      state.items = state.items.map((item) => {
        if (action.payload[item.id] && item.info) {
          item.info.lastInspection = action.payload[item.id];
        }
        return item;
      });
    },
  },
});

export const multiScanReducer = multiScanSlice.reducer;

export const multiScanActions = multiScanSlice.actions;

export const getMultiScanState = (rootState: {
  [MULTI_SCAN_FEATURE_KEY]: MultiScanState;
}): MultiScanState => rootState[MULTI_SCAN_FEATURE_KEY];

export const useGetMultiScanState = () => {
  return useSelector(getMultiScanState);
};

export const useGetMultiScanItems = () => {
  const { items } = useSelector(getMultiScanState);
  return items;
};

export const useClearMultiScanItems = () => {
  const dispatch = useDispatch();
  const clearItems = useCallback(() => {
    dispatch(multiScanActions.clear());
  }, [dispatch]);
  return clearItems;
};
