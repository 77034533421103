import React from 'react';
import Link from 'next/link';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { classNames } from '@scannable/common';

import ResizedImage from '../ResizedImage/ResizedImage';
import Text from '../Text/Text';

export interface AssemblyImageListItemProps {
  imageSrc?: string;
  itemName?: string;
  productSerialUrl?: string;
  batchNumber?: string;
  showComponentLinks?: boolean;
  onClick?: () => void;
}

export function ConditionalLink({
  link,
  children,
  useLink = true,
}: {
  useLink?: boolean;
  link: string | undefined;
  children: React.ReactNode;
}) {
  if (link && useLink) {
    return (
      <Link href={link} passHref className="focus:outline-none">
        {children}
      </Link>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
export function AssemblyImageListItem({
  imageSrc,
  itemName,
  productSerialUrl,
  batchNumber,
  onClick,
  showComponentLinks = true,
}: AssemblyImageListItemProps) {
  return (
    <div
      className={classNames(
        'relative bg-white flex items-center lg:px-8 sm:px-6 px-4',
        batchNumber ? 'py-7' : 'py-6',
        onClick ? 'cursor-pointer' : ''
      )}
      style={{ wordBreak: 'break-word' }}
      onClick={onClick}
    >
      <div className="flex-1 min-w-0">
        <ConditionalLink link={productSerialUrl} useLink={showComponentLinks}>
          <span className="absolute inset-0" aria-hidden="true" />
          <div className="flex items-center">
            <div className="rounded-lg mr-5 w-16 h-16 relative">
              <ResizedImage
                src={imageSrc || ''}
                width={65}
                height={65}
                fit="fill"
                alt={itemName || 'Component image'}
                className="object-cover object-center"
              />
            </div>

            <div className="flex-1 min-h-[7rem] border-b">
              <div className="flex items-center">
                <Text className="text-sm font-semibold !text-black">
                  {itemName}
                </Text>

                {showComponentLinks && productSerialUrl && (
                  <ChevronRightIcon
                    className="h-12 w-6 text-brand-tertiary ml-auto"
                    aria-hidden="true"
                  />
                )}
              </div>
              {batchNumber && (
                <Text className="!m-0 pb-5">Batch number: {batchNumber}</Text>
              )}
            </div>
          </div>
        </ConditionalLink>
      </div>
    </div>
  );
}

export default AssemblyImageListItem;
