import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { RootState } from '@scannable/frontend/store';
import { SerialPurchaseOrderValues } from '@scannable/frontend/types';
import { CREATE_SERIALISED_ROPES } from '@scannable/graphql-queries';

import { Button, Text, Title } from '../../atoms';
import { useCustomerReferenceTag, useSerialFormat } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { AdminPanel, InputFieldGroup, LoadingPanel } from '../../molecules';
import Form from '../Form/Form';

const validationSchema = yup.object().shape({
  dom: yup.string().nullable().required('A date is required'),
  serialFormatId: yup
    .number()
    .positive('A serial format is required')
    .required('A serial format is required'),
});

export interface CreateSerialisedRopeFormProps {
  restartRopeCutting: () => void;
}

export function CreateSerialisedRopeForm({
  restartRopeCutting,
}: CreateSerialisedRopeFormProps) {
  const router = useRouter();
  const { customerOptions } = useCustomerReferenceTag();

  const { serialFormats, initialSerialFormatId } = useSerialFormat();
  const [createSerialisedProductFromProcess, { loading }] = useMutation(
    CREATE_SERIALISED_ROPES
  );
  const { cutRopes } = useSelector((state: RootState) => state.serialisation);

  const formik = useFormik({
    initialValues: {
      purchaseOrderNumber: '',
      salesOrderNumber: '',
      customerReference: '',
      batchNumber: '',
      dom: new Date().toDateString(),
      serialFormatId: initialSerialFormatId,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: SerialPurchaseOrderValues) => {
      const serialCreationData = (cutRopes || []).map((cutRope) => {
        const { productVariation, ...rest } = cutRope;
        return rest;
      });

      const result = await resolveMutation(
        createSerialisedProductFromProcess({
          variables: {
            data: {
              ...values,
              dom: values.dom ?? '',
              serialFormatId: values.serialFormatId as number,
              serialCreationData,
              process: 'CutRopeSplice',
            },
          },
        }),
        {
          successMessage: 'Serials created successfully',
        }
      );
      if (result.ok) {
        router.push('/admin/serialisation');
      }
    },
  });

  if (loading) {
    return (
      <AdminPanel className="mt-4">
        <LoadingPanel />
      </AdminPanel>
    );
  }
  return (
    <Form formik={formik} data-cy="serialisation-details">
      <AdminPanel className="mt-10">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <Title size="h3">Serialisation Details</Title>
            <Text>
              Add information to make it easier to find your serialised ropes.
            </Text>
          </div>
          <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <InputFieldGroup
                    name="customerReference"
                    type="createable-select"
                    label="Customer Reference"
                    formik={formik}
                    options={customerOptions}
                  />

                  <InputFieldGroup
                    id="DateOfManufacture"
                    name="dom"
                    type="date"
                    formik={formik}
                    label="Manufacturer Date"
                  />
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <InputFieldGroup
                    id="PurchaseOrder"
                    name="purchaseOrderNumber"
                    type="text"
                    formik={formik}
                    label="Purchase Order"
                  />
                  <InputFieldGroup
                    id="SalesOrder"
                    name="salesOrderNumber"
                    type="text"
                    formik={formik}
                    label="Sales Order"
                  />
                  <InputFieldGroup
                    id="Batch"
                    name="batchNumber"
                    type="text"
                    formik={formik}
                    label="Batch Number"
                  />
                </div>
                <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <InputFieldGroup
                    name="serialFormatId"
                    type="select"
                    label="Serial Format"
                    formik={formik}
                    options={serialFormats}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminPanel>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="button"
            color="white"
            onClick={() => {
              restartRopeCutting();
            }}
          >
            Cancel
          </Button>
          <Button type="submit" loading={formik.isSubmitting} className="ml-3">
            Create Serials
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default CreateSerialisedRopeForm;
