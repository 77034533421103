import { ReactEventHandler, useEffect, useState } from 'react';
import NextImage, { ImageProps as NextImageProps } from 'next/image';

import { MISSING_IMAGE } from '@scannable/common';

export type ImageProps = NextImageProps;

export function Image({ alt, src, fetchPriority, ...props }: ImageProps) {
  const [error, setError] = useState(null);
  const imageSrc = src || MISSING_IMAGE;
  const fallbackImage = MISSING_IMAGE;
  useEffect(() => {
    setError(null);
  }, [src]);

  return (
    <NextImage
      alt={alt}
      onError={setError as unknown as ReactEventHandler<unknown>}
      src={error ? fallbackImage : imageSrc}
      {...props}
    />
  );
}

export default Image;
