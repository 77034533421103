import { useCallback } from 'react';
import { useRouter } from 'next/router';

export function useUpdateQueryParams() {
  const router = useRouter();

  const clearParam = useCallback(
    (filterName: string, onChangeRoute?: () => void) => {
      const updatedQuery = { ...router.query };
      delete updatedQuery[filterName];

      router
        .push({
          query: {
            ...updatedQuery,
          },
        })
        .then(() => {
          if (onChangeRoute) {
            onChangeRoute();
          }
        });
    },
    [router]
  );

  return {
    clearParam,
  };
}
