import { FormikProps, FormikValues } from 'formik';

import { FormFieldOptionType } from '@scannable/frontend/types';

export interface SelectInputProps<V>
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: HTMLSelectElement['name'];
  options?: FormFieldOptionType[];
  formik: FormikProps<V>;
  valueType: 'string' | 'number';
  defaultValue?: HTMLSelectElement['value'];
  defaultSelect?: boolean;
}

export function SelectInput<V extends FormikValues>({
  name,
  formik,
  className,
  options,
  defaultValue,
  valueType = 'number',
  defaultSelect = true,
  ...props
}: SelectInputProps<V>) {
  return (
    <select
      name={name}
      onChange={(e) => {
        // cast the value
        let value: string | number = e.target.value;
        value = valueType === 'number' ? Number(value) : value;
        return formik.setFieldValue(name, value);
      }}
      onBlur={formik.handleBlur}
      value={formik.values[name] || ''}
      className={
        className
          ? className
          : 'mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
      }
      defaultValue={defaultValue}
      {...props}
    >
      {defaultSelect && <option value="" label="Select an option" />}
      {options &&
        options.map((opt) => (
          <option key={`${opt.label}_${opt.value}`} value={`${opt.value}`}>
            {opt.label}
          </option>
        ))}
    </select>
  );
}

export default SelectInput;
