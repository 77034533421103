import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import { IMenuItem, MenuItem } from '../../molecules';

export interface MenuGroupTitleProps {
  title: string;
  group: string;
  menuItems: IMenuItem[];
  isOpen: boolean;
  toggleIsOpen: (key: string) => void;
  isOnTrialOrAccess: boolean;
}

export function MenuGroupTitle({
  title,
  group,
  menuItems,
  isOpen,
  toggleIsOpen,
  isOnTrialOrAccess,
}: MenuGroupTitleProps) {
  return (
    <>
      <dt
        className="cursor-pointer"
        onClick={() => toggleIsOpen(group)}
        data-cy={`menu-group-${group}`}
      >
        <div className="text-xs font-semibold leading-6 text-gray-400 flex justify-between">
          {title}
          {isOpen ? (
            <ChevronUpIcon className="h-5 w-5" aria-hidden="false" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          )}
        </div>
      </dt>
      <Transition
        as={Fragment}
        show={isOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 mx-h-full"
        leaveTo="opacity-0 mx-h-0"
      >
        <dd>
          <div className="">
            <ul className="-mx-2 mt-2 space-y-1">
              {menuItems.map((item) => (
                <MenuItem
                  {...item}
                  isProFeature={item.isProFeature && isOnTrialOrAccess}
                  key={item.name}
                />
              ))}
            </ul>
          </div>
        </dd>
      </Transition>
    </>
  );
}

export default MenuGroupTitle;
