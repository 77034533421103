import { useMutation } from '@apollo/client';

import {
  CREATE_TEAM,
  CREATE_TEAM_MEMBER,
  DELETE_TEAM,
  REMOVE_TEAM_MEMBER,
  UPDATE_TEAM,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export const useUpdateTeamMutation = (
  options?: UseMutationOptions<typeof UPDATE_TEAM>
) => {
  return useMutation(UPDATE_TEAM, options);
};

export const useRemoveTeamMemberMutation = (
  options?: UseMutationOptions<typeof REMOVE_TEAM_MEMBER>
) => {
  return useMutation(REMOVE_TEAM_MEMBER, options);
};

export const useDeleteTeamMutation = (
  options?: UseMutationOptions<typeof DELETE_TEAM>
) => {
  return useMutation(DELETE_TEAM, options);
};

export const useCreateTeamMutation = (
  options?: UseMutationOptions<typeof CREATE_TEAM>
) => {
  return useMutation(CREATE_TEAM, options);
};

export const useCreateTeamMemberMutation = (
  options?: UseMutationOptions<typeof CREATE_TEAM_MEMBER>
) => {
  return useMutation(CREATE_TEAM_MEMBER, options);
};
