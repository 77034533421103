import { useState } from 'react';
import Link from 'next/link';
import { useLazyQuery } from '@apollo/client';
import { DocumentTextIcon, FolderMinusIcon } from '@heroicons/react/24/outline';
import {
  CalendarIcon,
  CheckCircleIcon,
  DevicePhoneMobileIcon,
  ExclamationCircleIcon,
  FolderPlusIcon,
  MinusCircleIcon,
  MinusIcon,
  PlusIcon,
  UserMinusIcon,
  UserPlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { CriteriaResult, InspectionStatus } from '@prisma/client';

import {
  dateFormat,
  fileUrl,
  getChecklistResultStatus,
  GraphQLItemEvent,
  Image,
  InspectionDetails,
  InspectionFile,
} from '@scannable/common';
import { ViewProductHistoryItem } from '@scannable/frontend/common';
import { GET_INSPECTION } from '@scannable/graphql-queries';

import Button from '../../atoms/Button/Button';
import ResizedImage from '../../atoms/ResizedImage/ResizedImage';
import useTranslation from '../../hooks/useTranslation/useTranslation';
import { useModal } from '../../modals/ModalContext/ModalContext';
import SimpleModal from '../../modals/SimpleModal/SimpleModal';
import InspectionStatusBadge from '../../molecules/InspectionStatusBadge/InspectionStatusBadge';

export interface HistoryItemProps {
  item: ViewProductHistoryItem;
  includePrivateData?: boolean;
}

type InspectionEvent = { status: string };

function InspectionStatusIcon({ event }: { event?: InspectionEvent }) {
  if (!event) {
    return (
      <div className="p-3 h-11 w-11 justify-center items-center text-gray-500 ">
        <ExclamationCircleIcon />
      </div>
    );
  }
  if (event.status === InspectionStatus.PASSED) {
    return (
      <div className="p-3 h-11 w-11 justify-center items-center text-emerald-500">
        <CheckCircleIcon />
      </div>
    );
  }
  if (event.status === InspectionStatus.FAILED) {
    return (
      <div className="p-3 h-11 w-11 justify-center items-center text-red-500">
        <XCircleIcon />
      </div>
    );
  }

  if (event.status === InspectionStatus.QUARANTINED) {
    return (
      <div className="p-3 h-11 w-11 justify-center items-center text-blue-500">
        <MinusCircleIcon />
      </div>
    );
  }

  if (event.status === InspectionStatus.MONITORING) {
    return (
      <div className="p-3 h-11 w-11 justify-center items-center text-yellow-500">
        <ExclamationCircleIcon />
      </div>
    );
  }

  return (
    <div className="p-3 h-11 w-11 justify-center items-center text-red-900 ">
      <MinusCircleIcon />
    </div>
  );
}

interface HistoryItemRowProps {
  title: string;
  subtitle?: string;
  formattedDate: string;
  icon: JSX.Element;
  inspectionId?: number;
}

function HistoryItemRow({
  title,
  subtitle,
  formattedDate,
  icon,
  inspectionId,
}: HistoryItemRowProps) {
  const [findInspection] = useLazyQuery(GET_INSPECTION);
  const [inspectionDetails, setInspectionDetails] =
    useState<InspectionDetails | null>(null);
  const [showInspectionDetails, setShowInspectionDetails] = useState(false);
  const { showModal } = useModal();
  const { t } = useTranslation();
  return (
    <div className="font-medium border-b border-gray-100">
      <div className="py-2 flex w-full justify-between items-center">
        <div className="flex items-center gap-6">
          <div className="h-[50px] w-[24px]">{icon}</div>
          <div>
            <div className="text-sm text-gray-900">{title}</div>
            {subtitle && (
              <div className="text-xs text-gray-600">{subtitle}</div>
            )}
          </div>
        </div>
        <div className="ml-1.5 shrink-0 bg-gray-100 text-xs rounded-lg py-2 px-2 text-gray-500">
          {formattedDate}
        </div>
      </div>
      {inspectionId && (
        <div className="flex justify-end">
          <Button
            className="text-xs underline mt-0 pt-0"
            color="link"
            size="sm"
            onClick={async () => {
              if (!showInspectionDetails && !inspectionDetails) {
                const { data: inspectionData } = await findInspection({
                  variables: { id: inspectionId },
                });
                setInspectionDetails(
                  inspectionData?.inspection as InspectionDetails
                );
                setShowInspectionDetails(true);
                return;
              }
              setShowInspectionDetails(!showInspectionDetails);
            }}
          >
            {showInspectionDetails ? 'Hide' : 'Show'}
          </Button>
        </div>
      )}

      {inspectionDetails && showInspectionDetails && (
        <div className="text-sm text-gray-900 mb-4 ml-12">
          {/* Checklist Result */}
          {inspectionDetails?.checklistResult?.criteriaResults && (
            <div className="text-xs text-gray-600">
              <div className="text-xs text-gray-600 font-semibold mb-2">
                {inspectionDetails.checklistResult?.checklist?.name}:
              </div>
              <div>
                {inspectionDetails.checklistResult?.criteriaResults.map(
                  (criteriaResult: {
                    checklistCriteria: { title: string };
                    result: CriteriaResult;
                  }) => (
                    <div
                      key={criteriaResult.checklistCriteria?.title}
                      className="flex flex-row justify-between items-center pb-4"
                    >
                      <div className="text-xs text-gray-600">
                        {criteriaResult.checklistCriteria?.title}
                      </div>
                      <div>
                        {criteriaResult.result === CriteriaResult.PASSED && (
                          <div className="text-gray-500 flex items-center justify-end">
                            <div className="text-gray-500">Passed</div>
                            <CheckCircleIcon className="h-4 w-4 text-green-500 inline ml-1" />
                          </div>
                        )}
                        {criteriaResult.result === CriteriaResult.FAILED && (
                          <div className="text-gray-500 flex items-center content-end justify-end">
                            <span className="text-gray-500">Failed</span>
                            <XCircleIcon className="h-4 w-4 text-red-500 inline ml-1" />
                          </div>
                        )}
                        {criteriaResult.result ===
                          CriteriaResult.MONITORING && (
                          <div className="text-gray-500 flex items-center justify-end">
                            <span className="text-gray-500">Monitor</span>
                            <ExclamationCircleIcon className="h-4 w-4 text-yellow-500 inline ml-1" />
                          </div>
                        )}
                        {criteriaResult.result === CriteriaResult.REPAIR && (
                          <div className="text-gray-500 flex items-center justify-end">
                            <span className="text-gray-500">Repair</span>
                            <MinusCircleIcon className="h-4 w-4 text-blue-500 inline ml-1" />
                          </div>
                        )}
                        {criteriaResult.result ===
                          CriteriaResult.NOT_APPLICABLE && (
                          <div className="text-gray-500 flex items-center  content-end justify-end">
                            <span className="text-gray-500">NA</span>
                            <MinusCircleIcon className="h-4 w-4 text-gray-500 inline ml-1" />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>

              <div className="flex flex-row justify-between items-center pb-2 pt-4 font-medium text-md border-t">
                <span className="text-xs text-gray-600 font-semibold">
                  {t('result')}:
                </span>
                <span className="text-xs text-gray-600 font-semibold">
                  <InspectionStatusBadge
                    status={getChecklistResultStatus(
                      inspectionDetails.checklistResult?.criteriaResults
                    )}
                  />
                </span>
              </div>
            </div>
          )}

          {/* date */}
          <div className="text-xs text-gray-600 mb-4">
            Date: {dateFormat(inspectionDetails.date)}
          </div>

          {/* notes */}
          {inspectionDetails.notes && (
            <div className="bg-gray-100 p-2 rounded-lg min-h-14">
              <span className="text-xs text-gray-700 bg-gray-200 rounded-lg p-1 px-2 w-12 text-center mb-2">
                Note
              </span>
              <span className="text-xs p-2 pt-0 text-gray-600">
                {inspectionDetails.notes}
              </span>
            </div>
          )}

          {/* image thumbnails */}
          {inspectionDetails.images && (
            <div className="mt-4">
              <div className="text-xs text-gray-600 mb-4">{`Images Attached [${inspectionDetails.images.length}]`}</div>
              <div className="grid grid-cols-4 gap-3 max-w-96">
                {inspectionDetails.images.map((img: Image, index: number) => (
                  <div
                    key={index}
                    className="w-[95px] h-[95px] overflow-hidden flex items-center justify-center relative p-2"
                  >
                    <ResizedImage
                      src={img.s3Key ?? ''}
                      alt="Inspection image"
                      width={100}
                      height={100}
                      className="object-cover object-center cursor-pointer"
                      fit="fill"
                      onClick={() => {
                        showModal(
                          <SimpleModal>
                            <div className="mt-8">
                              <ResizedImage
                                src={img.s3Key ?? ''}
                                alt="Inspection image"
                                width={500}
                                height={500}
                                fit="fill"
                              />
                            </div>
                          </SimpleModal>
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* pdfs */}
          {inspectionDetails.pdfs && (
            <div className="text-gray-500 text-sm mt-4">
              <div className="text-xs text-gray-600 mb-4">{`PDFs Attached [${inspectionDetails.pdfs.length}]`}</div>
              {inspectionDetails.pdfs.map((pdf: InspectionFile) => (
                <div key={pdf.internalId} className="py-2">
                  <Link
                    href={fileUrl(pdf.s3Key)}
                    target="_blank"
                    className="bg-gray-100 text-grey-800 px-3 py-1 text-xs mb-1 rounded-full"
                  >
                    <DocumentTextIcon className="h-4 w-4 inline mr-1" />
                    <span className="inline">
                      {pdf.sourceName ?? pdf.value}
                    </span>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function HistoryItem({ item, includePrivateData }: HistoryItemProps) {
  if (item.type === GraphQLItemEvent.inspected) {
    const subtitle = item.user
      ? `${item.user} ${item.event.isCompetentInspector ? '(CP)' : ''}`
      : '';
    return (
      <HistoryItemRow
        title={item.title}
        subtitle={includePrivateData ? subtitle : ''}
        formattedDate={item.formattedDate}
        inspectionId={item.event.inspectionId}
        icon={
          <InspectionStatusIcon
            event={
              item?.event
                ? (item?.event as unknown as InspectionEvent)
                : undefined
            }
          />
        }
      />
    );
  }
  if (!includePrivateData) {
    return null;
  }
  return (
    <HistoryItemRow
      title={item.title}
      subtitle={item.user ? `By ${item.user}` : ''}
      formattedDate={item.formattedDate}
      icon={
        <div className="p-3 h-11 w-11 justify-center items-center text-gray-500 ">
          {item.type === GraphQLItemEvent.scanned && <DevicePhoneMobileIcon />}
          {item.type === GraphQLItemEvent.domAdded && <CalendarIcon />}
          {item.type === GraphQLItemEvent.addedToInventory && <PlusIcon />}
          {item.type === GraphQLItemEvent.removedFromInventory && <MinusIcon />}
          {item.type === GraphQLItemEvent.assignedToUser && <UserPlusIcon />}
          {item.type === GraphQLItemEvent.unassignedFromUser && (
            <UserMinusIcon />
          )}
          {item.type === GraphQLItemEvent.addedToGroup && <FolderPlusIcon />}
          {item.type === GraphQLItemEvent.removedFromGroup && (
            <FolderMinusIcon />
          )}
        </div>
      }
    />
  );
}

export default HistoryItem;
