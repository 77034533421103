import { ManufacturerName } from './common';

interface Variant {
  pattern: RegExp;
  serialFormat: string;
  validate: (serialNumber: string) => Date | null;
}

interface Manufacturer {
  variants: Variant[];
}

export const manufacturerSerialPatterns: Record<
  ManufacturerName,
  Manufacturer
> = {
  'Aspiring Safety': {
    variants: [
      {
        pattern: /^[0-9]{4}$/,
        serialFormat: 'Year',
        validate: (serialNumber: string) => {
          // Extract the year and return it
          const year = parseInt(serialNumber, 10);
          const date = new Date(year, 0, 1);
          return date;
        },
      },
    ],
  },
  'At Height UK': {
    variants: [
      {
        pattern: /^([0-9]{2})([0-9]{2})([0-9]{5})$/,
        serialFormat: 'YYXXZZZZZ',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          // Since the exact day and month aren't encoded in the serial number,
          // we'll return a date with the given year and 10/31 as the date
          return new Date(year, 9, 31); // 9 is for October since months are 0-indexed in JavaScript
        },
      },
    ],
  },
  Beal: {
    variants: [
      {
        pattern: /^[A-Z]{2} [0-9]{4} ([0-9]{2})([0-9]{2})$/,
        serialFormat: 'XXNNNNMMYY',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(9, 11), 10);
          const year = parseInt(serialNumber.slice(11, 13), 10) + 2000;
          return new Date(year, month - 1); // -1 because months are 0-indexed in JavaScript
        },
      },
      {
        pattern: /^[0-9]{4} ([0-9]{2})([0-9]{2})$/,
        serialFormat: 'NNNNMMYY',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(5, 7), 10);
          const year = parseInt(serialNumber.slice(7, 9), 10) + 2000;
          return new Date(year, month - 1);
        },
      },
      {
        pattern: /^[A-Z0-9]{11}([0-9]{2})$/,
        serialFormat: 'XXXXXXXXXXXYY',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(11, 13), 10) + 2000;
          // As we don't have month information, we'll use January
          return new Date(year, 0);
        },
      },
      {
        pattern: /^[A-Z0-9]{8}([0-9]{2})$/,
        serialFormat: 'XXXXXXYY',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(7, 9), 10) + 2000;
          // As we don't have month information, we'll use January
          return new Date(year, 0);
        },
      },
    ],
  },
  'Black Diamond': {
    variants: [
      {
        pattern: /^([0-9]{2})[A-Za-z0-9]+$/,
        serialFormat: 'YYXXXX...',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          return new Date(year, 0);
        },
      },
      {
        pattern: /^([0-9]{2})-([0-9]{4}) ([0-9]{4})$/,
        serialFormat: 'MM-YYYY NNNN',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10);
          const year = parseInt(serialNumber.slice(3, 7), 10);
          return new Date(year, month - 1);
        },
      },
      {
        pattern: /^([0-9]{2})-([0-9]{4}) ([0-9]{2})-([0-9]{2})$/,
        serialFormat: 'MM-YYYY NN-NN',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10);
          const year = parseInt(serialNumber.slice(3, 7), 10);
          return new Date(year, month - 1);
        },
      },
      {
        pattern: /^([0-9]{2})-([0-9]{4}) ([0-9]{5})$/,
        serialFormat: 'MM-YYYY NNNNN',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10);
          const year = parseInt(serialNumber.slice(3, 7), 10);
          return new Date(year, month - 1);
        },
      },
      {
        pattern: /^([0-9]{2})-([0-9]{4})$/,
        serialFormat: 'MM-YYYY',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10);
          const year = parseInt(serialNumber.slice(3, 7), 10);
          return new Date(year, month - 1);
        },
      },
      {
        pattern: /^([0-9]{2})[A-Z]{3}[0-9]{2}[A-Z0-9]$/,
        serialFormat: 'YYXXXXX',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          return new Date(year, 0);
        },
      },
    ],
  },
  Camp: {
    variants: [
      {
        pattern: /^([0-9]{8})$/,
        serialFormat: 'YYYYMMDD',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 4), 10);
          const month = parseInt(serialNumber.slice(4, 6), 10) - 1;
          const day = parseInt(serialNumber.slice(6, 8), 10);
          return new Date(year, month, day);
        },
      },
      {
        pattern: /^([0-9]{2}) ([0-9]{2})$/,
        serialFormat: 'MM YY',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(3, 5), 10) + 2000;
          const month = parseInt(serialNumber.slice(0, 2), 10) - 1;
          return new Date(year, month);
        },
      },
    ],
  },
  Courant: {
    variants: [
      {
        pattern: /^ITN A([0-9]{3})([A-Z]) [0-9]{3}$/,
        serialFormat: 'ITN ABBBY XXX',
        validate: (serialNumber: string) => {
          // 11 digits   A121(batch) J (Month) 082(individual)
          // ITN A121J 082
          const batch = serialNumber.slice(4, 8);
          const month = serialNumber.slice(8, 9).charCodeAt(0) - 65;
          const year = parseInt(batch.slice(0, 1), 10) + 2010;

          return new Date(year, month, 31); // Assuming the last day of the month
        },
      },
      {
        pattern: /^ITN [0-9A-Z]{4}[A-Z] [0-9]{3} ([0-9]{4})\/([0-9]{2})$/,
        serialFormat: 'ITN XXXXX XXX YYYY/MM',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(-7, -3), 10);
          const month = parseInt(serialNumber.slice(-2), 10) - 1; // Months are 0-indexed
          return new Date(year, month);
        },
      },
      {
        pattern: /^ITN ([0-9]{5})([A-Z]) [0-9]{3}$/,
        serialFormat: 'ITN NNNNYY XXX',
        validate: (serialNumber: string) => {
          const yearChar = serialNumber.slice(9, 10);
          const year = yearChar.charCodeAt(0) - 75 + 2015;
          return new Date(year, 0);
        },
      },
      {
        pattern: /^ITN [0-9]{4}[A-Z] [0-9]{3} {2}([0-9]{4})\/([0-9]{2})$/,
        serialFormat: 'ITN YYYYX XXX YYYY/MM',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(17, 21), 10);
          const month = parseInt(serialNumber.slice(22, 24), 10) - 1;
          return new Date(year, month);
        },
      },
    ],
  },
  'CT Climbing': {
    variants: [
      {
        pattern: /^([0-9]{2})-([0-9]{4})$/,
        serialFormat: 'MM-YYYY',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10);
          const year = parseInt(serialNumber.slice(3, 7), 10);
          return new Date(year, month - 1);
        },
      },
      {
        pattern: /^([0-9]{2})-([0-9]{4}) ([0-9]{4})$/,
        serialFormat: 'MM-YYYY NNNN',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10);
          const year = parseInt(serialNumber.slice(3, 7), 10);
          return new Date(year, month - 1);
        },
      },
      {
        pattern: /^([0-9]{5})$/,
        serialFormat: 'NNNNN',
        validate: () => {
          // This ID# does not have any reference to DOM. Wait for more info
          return null;
        },
      },
    ],
  },
  Eyolf: {
    variants: [
      {
        pattern: /^(\d{2})(\d{3})([A-Z]\d{4})$/,
        serialFormat: 'YYDDD[Letter][Numbers]',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const day = parseInt(serialNumber.slice(2, 5), 10);
          const date = new Date(year, 0); // JS Date months are 0-indexed
          date.setDate(day);
          return date;
        },
      },
    ],
  },
  'Fixe Climbing': {
    variants: [
      {
        pattern: /^A([0-9]{2})([0-9]{2})\{1\}[0-9]{3}$/,
        serialFormat: 'AMMYY NNN',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(1, 3), 10);
          const year = parseInt(serialNumber.slice(3, 5), 10) + 2000;
          return new Date(year, month - 1);
        },
      },
      {
        pattern: /^([0-9]{6})([0-9]{6})$/,
        serialFormat: 'NNNNNNNNNNNN',
        validate: () => {
          // Incomplete information, cannot determine DOM
          return null;
        },
      },
      {
        pattern: /^([0-9]{4})\{1\}([0-9]{11})$/,
        serialFormat: 'YYYY NNNNNNNNNNN',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 4), 10);
          return new Date(year, 0);
        },
      },
    ],
  },
  'Freeworker GmbH': {
    variants: [
      {
        pattern:
          /^([0-9]{4})-([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{4})([0-9]{2})$/,
        serialFormat: 'YYYY-ProductIdentifier-MMDD-RNN-ID',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 4), 10);
          const month = parseInt(serialNumber.slice(-9, -7), 10);
          const day = parseInt(serialNumber.slice(-7, -5), 10);
          const date = new Date(year, month - 1, day);
          return date;
        },
      },
    ],
  },
  Grivel: {
    variants: [
      {
        pattern: /^([0-9]{4})-([0-9]{2})$/,
        serialFormat: 'YYYY-MM',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 4), 10);
          const month = parseInt(serialNumber.slice(5, 7), 10) - 1;
          return new Date(year, month);
        },
      },
    ],
  },

  Petzl: {
    variants: [
      {
        pattern: /^([0-9]{2})\s*([0-9]{3})\s*([0-9]*)$/,
        serialFormat: 'YYDDD[Numbers]',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const day = parseInt(serialNumber.slice(2, 5), 10);
          const date = new Date(year, 0, 1);
          date.setDate(date.getDate() + (day - 1));

          return date;
        },
      },
      {
        pattern: /^([0-9]{2})\s*([A-Z])\s*([0-9\s]+)$/,
        serialFormat: 'YY[A-Z][Numbers]',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
          const month = letters.indexOf(serialNumber.slice(2, 3));
          const date = new Date(year, month, 1);
          date.setDate(date.getDate());

          return date;
        },
      },
      {
        pattern: /^([0-9]{2})([0-9]{3})([0-9]+)([A-Z][0-9]+)\.([A-Z0-9]+)$/,
        serialFormat: 'YYDDD[Numbers][A-Z][Numbers].[A-Z0-9]',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const day = parseInt(serialNumber.slice(2, 5), 10);
          const date = new Date(year, 0, 1);
          date.setDate(date.getDate() + (day - 1));

          return date;
        },
      },
      {
        pattern: /^([0-9]{2})([0-9]{3})([A-Z]{2})([0-9]{4})$/,
        serialFormat: 'YYDDDXXNNNN',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const day = parseInt(serialNumber.slice(2, 5), 10);
          const date = new Date(year, 0, 1);
          date.setDate(date.getDate() + (day - 1));
          return date;
        },
      },
      {
        pattern: /^([0-9]{2})([0-9]{3})([A-Z])$/,
        serialFormat: 'YYDDDX',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const day = parseInt(serialNumber.slice(2, 5), 10);
          const date = new Date(year, 0, 1);
          date.setDate(date.getDate() + (day - 1));
          return date;
        },
      },
      {
        pattern: /^([0-9]{2})([0-9]{2})$/,
        serialFormat: 'MMYY',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10) - 1; // Months are 0-indexed
          const year = parseInt(serialNumber.slice(2, 4), 10) + 2000;
          return new Date(year, month, 1); // Assuming the first day of the month
        },
      },
    ],
  },
  ISC: {
    variants: [
      {
        pattern: /^([0-9]{2})([0-9]{5})([0-9]{4})$/,
        serialFormat: 'YYBBBBBXXXX',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          return new Date(year, 11, 31); // Assuming the last day of December
        },
      },
      {
        pattern: /^(\d{2})\/\d+\/\w+$/,
        serialFormat: 'YY/BBBBB/XXXXX',
        validate: (serialNumber: string) => {
          const yearPart = serialNumber.slice(0, 2);
          const year = parseInt(yearPart, 10) + 2000;
          return new Date(year, 0, 1);
        },
      },
      {
        pattern: /^\d{2}\d{3}\d{7}$/,
        serialFormat: 'YYBBBBBBXXXXX',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000; // assuming 2000s// Date in JS is 0-indexed for months
          return new Date(year, 0, 1);
        },
      },
    ],
  },
  'Singing Rock': {
    variants: [
      {
        pattern: /.*(\d{2})(\d{2})$/,
        serialFormat: '[Anything][Month][Year]',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(-2), 10) + 2000;
          const month = parseInt(serialNumber.slice(-4, -2), 10) - 1;
          const date = new Date(year, month, 1);
          date.setDate(date.getDate());
          return date;
        },
      },
    ],
  },
  DMM: {
    variants: [
      {
        pattern: /^(\d{2})(\d{3})\d{4}[A-Z]$/,
        serialFormat: 'YYDDD[Serial Number][Machine Identifier]',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const dayOfYear = parseInt(serialNumber.slice(2, 5), 10);
          const date = new Date(year, 0);
          date.setDate(dayOfYear);
          return date;
        },
      },
    ],
  },
  Lyon: {
    variants: [
      {
        pattern: /^[0-9]{5}(\s)?\w+$/,
        serialFormat: 'YYDDD XXXXX or YYDDDXXXXX',
        validate: (serialNumber: string) => {
          let year = parseInt(serialNumber.slice(0, 2));
          const day = parseInt(serialNumber.slice(2, 5));

          year = year >= 0 && year <= 79 ? year + 2000 : year + 1900;

          const date = new Date(year, 0);
          date.setDate(day);

          return date;
        },
      },
    ],
  },
  'Rock Exotica': {
    variants: [
      {
        pattern: /^(\d{2})(\d{3})([A-Z]\d{4})$/,
        serialFormat: 'YYDDD[Letter][Numbers]',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const day = parseInt(serialNumber.slice(2, 5), 10);
          const date = new Date(year, 0); // JS Date months are 0-indexed
          date.setDate(day);
          return date;
        },
      },
    ],
  },

  Skylotec: {
    variants: [
      {
        pattern:
          /^(\/\/)?([A-Za-z0-9-]+)\+\+([A-Za-z0-9-]+)\+\+(\d{2}\/\d{4})\+\+(\d+)$/,
        serialFormat: '//PartNumber++Serial++MM/YYYY++[Numbers]',
        validate: (serialNumber) => {
          const parts = serialNumber.split('++');
          const dateString = parts[2]; // the date is in format MM/YYYY
          const dateParts = dateString.split('/');
          const month = parseInt(dateParts[0], 10) - 1; // JS months are 0-indexed
          const year = parseInt(dateParts[1], 10);
          const date = new Date(year, month, 1);
          return date;
        },
      },
    ],
  },
  Taz: {
    variants: [
      {
        pattern: /^([A-Z])(\d{2})(\d{2})(\d{2})(\d{2})(\d+)$/,
        serialFormat: 'XSSYYYYMMDD[Numbers]',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(3, 5), 10) + 2000;
          const month = parseInt(serialNumber.slice(5, 7), 10) - 1; // JS Date months are 0-indexed
          const day = parseInt(serialNumber.slice(7, 9), 10);
          const date = new Date(year, month, day);
          return date;
        },
      },
    ],
  },
  Teufelberger: {
    variants: [
      {
        pattern: /^(\d{2})\/(\d{2})-(\d+)$/,
        serialFormat: 'YY/MM-SerialNumber',
        validate: (serialNumber) => {
          const parts = serialNumber.split('/');
          const year = parseInt(parts[0], 10) + 2000;
          const month = parseInt(parts[1].slice(0, 2), 10) - 1; // JS Date months are 0-indexed
          const date = new Date(year, month, 1);
          return date;
        },
      },
      {
        pattern: /^([0-9]{2})([0-9]{2})([0-9]{2})-([0-9]{7})\s[A-Z]{2}$/,
        serialFormat: 'YYMMDD-XXXXXXX',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const month = parseInt(serialNumber.slice(2, 4), 10) - 1; // Months are 0-indexed in JavaScript
          const day = parseInt(serialNumber.slice(4, 6), 10);
          return new Date(year, month, day);
        },
      },
      {
        pattern: /^([0-9]{2})([0-9]{2})([0-9]{4})\s([0-9]{8})$/,
        serialFormat: 'YYMMXXXX XXXXXXXX',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const month = parseInt(serialNumber.slice(2, 4), 10) - 1;
          return new Date(year, month, 1);
        },
      },
      {
        pattern: /^([0-9]{2})-([0-9]{5})$/,
        serialFormat: 'YY-XXXXX',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          return new Date(year, 0, 1);
        },
      },
    ],
  },
  Edelrid: {
    variants: [
      {
        pattern:
          /^21([\x21-\x22\x25-\x2F\x30-\x39\x3A-\x3F\x41-\x5A\x5F\x61-\x7A]{0,20})$/,

        serialFormat: 'GS1',
        validate: (serialNumber: string) => {
          const match = serialNumber.match(
            /^21([\x21-\x22\x25-\x2F\x30-\x39\x3A-\x3F\x41-\x5A\x5F\x61-\x7A]{0,20})$/
          );

          if (!match) {
            throw new Error('Invalid serial number');
          }

          return new Date();
        },
      },
      {
        pattern: /^1(\d{2})(\d{2})(\d{4})([A-Za-z\d])(\d{6})$/,
        serialFormat:
          '1[Edelrid][Day][Month][Year][Producer][Individual Serial]',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(5, 9), 10);
          const month = parseInt(serialNumber.slice(3, 5), 10) - 1; // JS Date months are 0-indexed
          const day = parseInt(serialNumber.slice(1, 3), 10);
          const date = new Date(year, month, day);
          return date;
        },
      },
      {
        pattern: /^(00E) (\d{4}) (\d{4}) (\d{3})$/,
        serialFormat: '00E[Factory] [Year] [Batch] [Individual]',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(4, 8), 10);

          const dateValue = Date.UTC(year, 0, 1); // Get the UTC value for January 1st of the given year
          return new Date(dateValue);
        },
      },
      {
        pattern: /^(\d{5}) (\d{4}) (\d{4}) (\d{2}) (\d{2})$/,
        serialFormat: '[Batch] [Individual ID] [Year] [Month/Day] [Day/Month]',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(10, 16), 10);
          const month = parseInt(serialNumber.slice(17, 19), 10) - 1; // JS Date months are 0-indexed
          const day = parseInt(serialNumber.slice(20, 22), 10);
          const date = new Date(Date.UTC(year, month, day));
          return date;
        },
      },
    ],
  },
  Harken: {
    variants: [
      {
        pattern: /^(\d{4})(\d{3})(-\d+)?$/,
        serialFormat: 'YYYYDDD(-XXXX)?',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(0, 4), 10);
          const dayOfYear = parseInt(serialNumber.slice(4, 7), 10);
          const date = new Date(year, 0);
          date.setDate(dayOfYear);
          return date;
        },
      },
    ],
  },
  Heightec: {
    variants: [
      {
        pattern: /^D[0-9]{2} ([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{3})$/,
        serialFormat: 'D01 DDMMYY XXX',
        validate: (serialNumber: string) => {
          const day = parseInt(serialNumber.slice(4, 6), 10);
          const month = parseInt(serialNumber.slice(6, 8), 10) - 1; // Months are 0-indexed
          const year = parseInt(serialNumber.slice(8, 10), 10) + 2000;
          return new Date(year, month, day);
        },
      },
    ],
  },
  Husqvarna: {
    variants: [
      {
        pattern: /^([0-9]{2})([0-9]{2})$/,
        serialFormat: 'MMYY',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10) - 1;
          const year = parseInt(serialNumber.slice(2, 4), 10) + 2000;
          return new Date(year, month);
        },
      },
    ],
  },
  ART: {
    variants: [
      {
        pattern: /^(\d{2})\.(\d{2}).*$/,
        serialFormat: 'YY.MM[Anything]',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(3, 5), 10) + 2000;
          const month = parseInt(serialNumber.slice(0, 2), 10) - 1;
          const date = new Date(year, month, 1);
          return date;
        },
      },
    ],
  },
  'Kong Italy': {
    variants: [
      {
        pattern: /^(\d{6})\s(\d{2})(\d{2})\s(\d{4})$/,
        serialFormat: 'LLLLLL MMYY XXXX',
        validate: (serialNumber) => {
          const year = parseInt(serialNumber.slice(9, 11), 10) + 2000;
          const month = parseInt(serialNumber.slice(7, 9), 10) - 1; // JS Date months are 0-indexed
          const date = new Date(year, month);
          return date;
        },
      },

      {
        pattern: /^([0-9]{6})([0-9]{2})[0-9]{4}$/,
        serialFormat: 'LLLLLL YY XXXX',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(6, 8), 10) + 2000;
          return new Date(year, 11, 31); // December 31st of the given year
        },
      },
    ],
  },
  Marlow: {
    variants: [
      {
        pattern: /^([0-9]{2})([A-Z])[0-9]{4}$/,
        serialFormat: 'YYM####',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const month = serialNumber.slice(2, 3).charCodeAt(0) - 65;
          return new Date(year, month);
        },
      },
      {
        pattern: /^([0-9]{2})([A-Z])[0-9]{5}$/,
        serialFormat: 'YYM#####',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const month = serialNumber.slice(2, 3).charCodeAt(0) - 65;
          return new Date(year, month);
        },
      },
      {
        pattern: /^([0-9]{2})([A-Z])[0-9]{4}-[0-9]{3}$/,
        serialFormat: 'YYM####-XXX',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          const month = serialNumber.slice(2, 3).charCodeAt(0) - 65;
          return new Date(year, month);
        },
      },
      {
        pattern: /^([0-9]{2})\/([0-9]{4})[0-9]{4}-[0-9]{3}$/,
        serialFormat: 'MM/YYYY####-XXX',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10) - 1;
          const year = parseInt(serialNumber.slice(3, 7), 10);
          return new Date(year, month);
        },
      },
    ],
  },
  'Pfanner Protos': {
    variants: [
      {
        pattern: /^([0-9]{2})([0-9]{4})([A-Za-z0-9#]{4})-([0-9]{3})$/,
        serialFormat: 'MMYYYY####-XXX',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10) - 1; // Months are 0-indexed
          const year = parseInt(serialNumber.slice(2, 6), 10);
          return new Date(year, month, 1); // Assuming the first day of the month
        },
      },
    ],
  },
  PMI: {
    variants: [
      {
        pattern: /^([0-9]{2})([0-9]{2})[0-9]{4}$/,
        serialFormat: 'MMYY####',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(0, 2), 10) - 1;
          const year = parseInt(serialNumber.slice(2, 4), 10) + 2000;
          return new Date(year, month);
        },
      },
    ],
  },
  'Rock Empire': {
    variants: [
      // March 2021 onwards
      {
        pattern: /^([0-9]{2})[A-Z0-9]{6}[A-Z][0-9]{4}$/,
        serialFormat: 'YY######M####',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(0, 2), 10) + 2000;
          return new Date(year, 0);
        },
      },
      // PRE March 2021 Slings/Lanyards
      {
        pattern: /^[0-9]{4}\/[0-9]{4}$/,
        serialFormat: '####/####',
        validate: () => {
          // Optional date not included, cannot determine DOM
          return null;
        },
      },
      // PRE March 2021 Harnesses
      {
        pattern: /^[0-9]{2}([0-9]{2})([0-9]{2})\/[0-9]{3}$/,
        serialFormat: 'XXWWYY/XXX',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(4, 6), 10) + 2000;
          const week = parseInt(serialNumber.slice(2, 4), 10);
          const date = new Date(year, 0, 1 + (week - 1) * 7);
          return date;
        },
      },
      // PRE March 2021 Hardwear
      {
        pattern: /^[A-Z0-9]{5}\/([0-9]{2}) [0-9]{3}$/,
        serialFormat: 'XXXXX/YY ###',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(6, 8), 10) + 2000;
          return new Date(year, 11, 31); // December 31st of the given year
        },
      },
      // Some hard goods
      {
        pattern: /^[0-9]{3}\/([0-9]{2})$/,
        serialFormat: '###/YY',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(4, 6), 10) + 2000;
          return new Date(year, 0);
        },
      },
      // Some hard goods with unique ID
      {
        pattern: /^[A-Z0-9]{5}\/([0-9]{2}) [0-9]{4}$/,
        serialFormat: 'XXXXX/YY ####',
        validate: (serialNumber: string) => {
          const year = parseInt(serialNumber.slice(6, 8), 10) + 2000;
          return new Date(year, 0);
        },
      },
    ],
  },
  Timbersaws: {
    variants: [
      {
        pattern: /^[0-9]{4}-([0-9]{2})([0-9]{2})([0-9]{4})$/,
        serialFormat: '####-DDMMYYYY',
        validate: (serialNumber: string) => {
          const day = parseInt(serialNumber.slice(5, 7), 10);
          const month = parseInt(serialNumber.slice(7, 9), 10) - 1;
          const year = parseInt(serialNumber.slice(9, 13), 10);
          return new Date(year, month, day);
        },
      },
    ],
  },
  Yates: {
    variants: [
      // Old format
      {
        pattern: /^[0-9]{5}-([0-9]{2})([0-9]{2})[A-Z]$/,
        serialFormat: '#####-MMYYX',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(6, 8), 10) - 1;
          const year = parseInt(serialNumber.slice(8, 10), 10) + 2000;
          return new Date(year, month);
        },
      },
      // More recent format
      {
        pattern: /^[0-9]{5}-([0-9]{2})([0-9]{2})[A-Z]-[0-9]{3}$/,
        serialFormat: '#####-MMYYX-###',
        validate: (serialNumber: string) => {
          const month = parseInt(serialNumber.slice(6, 8), 10) - 1;
          const year = parseInt(serialNumber.slice(8, 10), 10) + 2000;
          return new Date(year, month);
        },
      },
    ],
  },
  Zero: {
    variants: [
      {
        pattern: /^[0-9]{6}$/,
        serialFormat: '######',
        validate: () => {
          // Incomplete information, cannot determine DOM
          return null;
        },
      },
    ],
  },
};

function validateSerialNumberWithVariant(
  serialNumber: string,
  variant: Variant,
  name: string
) {
  const { pattern, validate, serialFormat } = variant;
  if (pattern.test(serialNumber)) {
    try {
      const date = validate(serialNumber);
      return {
        name,
        date,
        serialFormat,
      };
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(
          `Error when validating serial number with format ${serialFormat}: ${error.message}`
        );
      }
    }
  }
  return null;
}

export function findSerialNumberByManufacturer(
  manufacturer: ManufacturerName | string,
  serialNumber: string
) {
  const manufacturerPatterns =
    manufacturerSerialPatterns[manufacturer as ManufacturerName];

  if (!manufacturerPatterns) {
    throw new Error(`No patterns found for manufacturer: ${manufacturer}`);
  }

  const { variants } = manufacturerPatterns;

  for (const variant of variants) {
    const result = validateSerialNumberWithVariant(
      serialNumber,
      variant,
      manufacturer
    );
    if (result) {
      return result;
    }
  }

  // join all the serial formats for the error message
  const serialFormats = variants
    .map((variant) => variant.serialFormat)
    .join(', ');

  throw new Error(
    `Could not find a match for this serial number. Serial formats: ${serialFormats}`
  );
}

export function findSerialNumber(
  serialNumber: string,
  returnPossibleMatches = false
) {
  const possibleMatches = [];
  for (const manufacturer in manufacturerSerialPatterns) {
    const { variants } =
      manufacturerSerialPatterns[manufacturer as ManufacturerName];

    for (const variant of variants) {
      const result = validateSerialNumberWithVariant(
        serialNumber,
        variant,
        manufacturer
      );
      if (result && !returnPossibleMatches) {
        return result;
      }
      if (result && returnPossibleMatches) {
        possibleMatches.push(result);
      }
    }
  }
  return returnPossibleMatches ? possibleMatches : null;
}
