export interface SpecificationGroupHeadingProps {
  title: string;
}

export function SpecificationGroupHeading(
  props: SpecificationGroupHeadingProps
) {
  const { title } = props;
  return (
    <div className="bg-brand-primary">
      <div className="lg:px-8 sm:px-6 px-6 py-3">
        <h2 className="font-semibold text-sm">{title}</h2>
      </div>
    </div>
  );
}

export default SpecificationGroupHeading;
