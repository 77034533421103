import { Dispatch, SetStateAction, useCallback } from 'react';
import { useRouter } from 'next/router';

export const useUrlParameters = (
  setCurrentPage: Dispatch<SetStateAction<number>>
) => {
  const router = useRouter();

  const updateUrlParams = useCallback(
    (newParams: Record<string, unknown>) => {
      const updatedQuery = { ...router.query, ...newParams } as Record<
        string,
        string
      >;
      router.push(
        { pathname: router.pathname, query: updatedQuery },
        undefined,
        { shallow: true }
      );
    },
    [router]
  );

  const initializeFromUrl = useCallback(() => {
    const pageFromUrl = router.query.page
      ? parseInt(router.query.page as string)
      : 1;
    setCurrentPage(pageFromUrl);

    if (!router.query.page) {
      updateUrlParams({ page: 1 });
    }
  }, [router.query.page, updateUrlParams, setCurrentPage]);

  return { initializeFromUrl, updateUrlParams };
};
