import { useRouter } from 'next/router';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

import { cn, redirectToSignIn } from '@scannable/common';

import { Image, Link } from '../../atoms';
import UserDropdown from '../../organisms/UserDropdown/UserDropdown';

export interface UniqueUrlBannerProps {
  isLoggedIn: boolean;
  showLogin?: boolean;
  isPublicUrl?: boolean;
}

export function UniqueUrlBanner({
  isLoggedIn,
  showLogin = true,
  isPublicUrl = true,
}: UniqueUrlBannerProps) {
  const router = useRouter();
  return (
    <div
      className={cn(
        'bg-white px-4 mb-4 rounded-lg',
        isPublicUrl ? 'shadow-sm' : ''
      )}
    >
      <div className="relative w-full flex items-center py-3">
        <div className="cursor-pointer flex items-center">
          <Link href="/">
            <Image
              src="/static/scannable-dashboard-logo.svg"
              alt="Scannable"
              className="object-contain object-center"
              width={150}
              height={40}
              priority
            />
          </Link>
        </div>
        <div className="absolute flex right-0 text-sm items-center z-20">
          {showLogin && !isLoggedIn && (
            <Link
              href={redirectToSignIn(router.asPath)}
              className="flex font-semibold"
            >
              Login <ArrowRightIcon className="text-brand-grey w-5 pl-1" />
            </Link>
          )}
          {showLogin && isLoggedIn && <UserDropdown />}
        </div>
      </div>
    </div>
  );
}

export default UniqueUrlBanner;
