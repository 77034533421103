import { cn } from '@scannable/common';

interface InputTextProps {
  type: 'error' | 'warning' | 'info';
  message: string;
  name?: string;
}
export function InputText({ message, name, type }: InputTextProps) {
  let color = '';
  let id = '';

  switch (type) {
    case 'error':
      color = 'text-red-600';
      id = `${name}-error`;
      break;
    case 'warning':
      color = 'text-yellow-600';
      id = `${name}-warning`;
      break;
    case 'info':
      color = 'text-gray-600';
      id = `${name}-info`;
      break;
    default:
      color = 'text-gray-600';
      id = `${name}-info`;
      break;
  }
  return (
    <p className={cn('mt-2 text-sm', color)} id={id}>
      {message}
    </p>
  );
}
