import { GENERIC_ERROR_MESSAGE } from '@scannable/common';
import { WrappedForm } from '@scannable/frontend/common';

import { LoadingIcon, Text } from '../../atoms';

type WithFormLoadingProps = WrappedForm;

export function withFormLoading<T extends WithFormLoadingProps>(
  Component: React.FC<T>
) {
  return (props: T) => {
    if (props.loading) {
      return (
        <div>
          <LoadingIcon size="sm" />
        </div>
      );
    }
    if (props.error) {
      return (
        <div>
          <Text>{GENERIC_ERROR_MESSAGE}</Text>
        </div>
      );
    }
    return <Component {...props} />;
  };
}
