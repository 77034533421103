import { ServerError, useMutation, useQuery } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';

import {
  DataType,
  Inventory,
  ItemHistory,
  SerialisedProduct,
} from '@scannable/common';
import {
  h,
  useUpdateSerialisedProductMutation,
} from '@scannable/frontend/common';
import {
  DELETE_SERIALISED_PRODUCT_IMAGE,
  SERIALISED_PRODUCT_DATA_FOR_UPDATE,
} from '@scannable/graphql-queries';

import { useSidePanel } from '..';
import { Alert } from '../../atoms';
import { SidePanelForm } from '../../forms';
import { useTranslation, useValidateSerial } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup, ItemPreview } from '../../molecules';
import { withSidePanelLoading } from '../withSidePanelLoading/withSidePanelLoading';
import {
  EditItemSidePanelProps,
  EditItemValues,
  SidePanelProps,
} from './EditItemSidePanel.types';

function ItemSidePanel({
  id,
  name,
  serialNumber,
  batchNumber,
  dom,
  dofu,
  expiryDate,
  proofOfPurchase,
  nfc,
  externalId,
  productType,
  imagesData,
  ...props
}: SidePanelProps) {
  const { showItemSidePanel, hideSidePanel } = useSidePanel();
  const { t } = useTranslation();
  const [updateItem] = useUpdateSerialisedProductMutation({
    refetchQueries: [
      SerialisedProduct.SerialisedProductForUniqueUrl,
      Inventory.PaginatedWebInventory,
      ItemHistory.PaginatedItemHistory,
    ],
  });
  const [deleteSerialisedProductImage] = useMutation(
    DELETE_SERIALISED_PRODUCT_IMAGE,
    {
      refetchQueries: [
        SerialisedProduct.GetSerialisedProductForUpdateQuery,
        SerialisedProduct.SerialisedProductForUniqueUrl,
        Inventory.PaginatedWebInventory,
        ItemHistory.PaginatedItemHistory,
      ],
    }
  );

  const {
    confirmEmptySerial,
    setIsEmptySerial,
    setIsDuplicateSerial,
    getMessageAndAction,
  } = useValidateSerial();

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      serialNumber: serialNumber ?? '',
      batchNumber: batchNumber ?? '',
      dom: dom ?? null,
      dofu: dofu ?? null,
      expiryDate: expiryDate ?? null,
      proofOfPurchase: proofOfPurchase ?? '',
      nfc: nfc ?? '',
      externalId: externalId ?? '',
      images: null,
    } as EditItemValues,
    onSubmit: async (
      values: EditItemValues,
      actions: FormikHelpers<EditItemValues>
    ) => {
      if (
        !confirmEmptySerial &&
        !values.serialNumber &&
        productType !== 'ASSEMBLY'
      ) {
        setIsEmptySerial(true);
        return;
      }
      const result = await resolveMutation(
        updateItem({
          variables: {
            data: {
              nfc: values.nfc,
              externalId: values.externalId,
              id,
              name: values.name,
              serialNumber: values.serialNumber,
              batchNumber: values.batchNumber,
              dom: values.dom,
              dofu: values.dofu,
              expiryDate: values.expiryDate,
              proofOfPurchase: values.proofOfPurchase,
              confirmDuplicateSerial:
                values.serialNumber === serialNumber
                  ? true
                  : values.confirmDuplicateSerial,
              images: values.images,
            },
          },
        }),
        {
          successMessage: 'Updated item successfully',
        }
      );
      actions.setSubmitting(false);
      if (result && !result.ok) {
        const isDuplicateSerial =
          result?.error?.message === 'Serial number already exists' ||
          (result?.error?.networkError as ServerError)?.statusCode === 409;

        if (isDuplicateSerial) {
          setIsDuplicateSerial(true);
        }
      }
      if (result.ok) {
        showItemSidePanel({
          serialisedProductId: id,
          showBanner: false,
          showOptionsButton: true,
          onBack: () => {
            hideSidePanel();
          },
        });
      }
    },
  });
  const { message, primaryButtonLabel, primaryButtonAction } =
    getMessageAndAction(
      () => {
        formik.setFieldValue('confirmDuplicateSerial', true);
      },
      () => formik.submitForm()
    );

  return (
    <SidePanelForm
      formik={formik}
      buttons={[
        {
          label: primaryButtonLabel,
          type: 'submit',
          loading: formik.isSubmitting,
          onClick: primaryButtonAction,
          color: 'primary',
          'data-cy': 'save-item',
        },
      ]}
    >
      <ItemPreview {...props} uri={imagesData?.[0]?.uri} />
      <InputFieldGroup
        data-cy="name"
        id="name"
        name="name"
        type="text"
        formik={formik}
        label={h(t('name'))}
        placeholder="--"
      />
      <InputFieldGroup
        id="images"
        name="images"
        type="image"
        label="Images"
        formik={formik}
        config={{
          isMulti: true,
          currentImages:
            imagesData
              ?.filter((image) => image.dataType === DataType.SERIAL)
              .map((image) => ({
                image: {
                  id: image.id,
                  uri: image.uri,
                },
                removeFile: () => {
                  resolveMutation(
                    deleteSerialisedProductImage({
                      variables: {
                        data: {
                          serialisedProductId: id,
                          imageId: image.id,
                        },
                      },
                    }),
                    {
                      successMessage: 'Image deleted successfully',
                    }
                  );
                },
              })) ?? undefined,
        }}
      />
      <InputFieldGroup
        data-cy="nfc"
        id="NFC"
        name="nfc"
        type="nfc"
        formik={formik}
        label="NFC"
      />
      <InputFieldGroup
        data-cy="serial-number"
        id="serialNumber"
        name="serialNumber"
        type="camera"
        formik={formik}
        label={h(t('serial_number'))}
        placeholder="--"
      />

      <InputFieldGroup
        data-cy="batch-number"
        id="batchNumber"
        name="batchNumber"
        type="camera"
        formik={formik}
        label={h(t('batch_number'))}
        placeholder="--"
      />

      <InputFieldGroup
        data-cy="dom"
        id="dom"
        name="dom"
        type="date"
        config={{
          showMonthDropdown: true,
          showYearDropdown: true,
        }}
        formik={formik}
        label={h(t('date_of_manufacture'))}
        placeholder={h(t('date_of_manufacture'))}
        wrapperClassName="w-full"
      />
      <InputFieldGroup
        data-cy="dofu"
        id="dofu"
        name="dofu"
        type="date"
        config={{
          showMonthDropdown: true,
          showYearDropdown: true,
        }}
        formik={formik}
        label={h(t('date_of_first_use'))}
        placeholder={h(t('date_of_first_use'))}
        wrapperClassName="w-full"
      />
      <InputFieldGroup
        data-cy="expiry-date"
        id="expiryDate"
        name="expiryDate"
        config={{
          showMonthDropdown: true,
          showYearDropdown: true,
        }}
        type="date"
        formik={formik}
        label={h(t('expiry_date'))}
        placeholder={t('expiry_date')}
        wrapperClassName="w-full"
      />
      <InputFieldGroup
        data-cy="external-id"
        id="externalId"
        name="externalId"
        type="camera"
        formik={formik}
        label={h(t('external_id'))}
        placeholder="--"
      />
      <InputFieldGroup
        data-cy="proof-of-purchase"
        id="proofOfPurchase"
        name="proofOfPurchase"
        type="text"
        formik={formik}
        label={h(t('purchase_order'))}
        placeholder="--"
      />

      {message && (
        <div className="pt-4 text-sm">
          <Alert type="warning" message={message} />
        </div>
      )}
    </SidePanelForm>
  );
}

const WrappedItemSidePanel = withSidePanelLoading(ItemSidePanel);

export function EditItemSidePanel({ id, ...props }: EditItemSidePanelProps) {
  const { data, loading, error } = useQuery(
    SERIALISED_PRODUCT_DATA_FOR_UPDATE,
    {
      variables: {
        id,
      },
      skip: !id,
    }
  );
  return (
    <WrappedItemSidePanel
      id={id}
      name={data?.serialisedProduct?.name ?? ''}
      serialNumber={data?.serialisedProduct?.info?.serialNumber ?? ''}
      batchNumber={data?.serialisedProduct?.info?.batchNumber ?? ''}
      dom={data?.serialisedProduct?.dom ?? null}
      dofu={data?.serialisedProduct?.dofu ?? null}
      expiryDate={data?.serialisedProduct?.info?.expiryDate ?? null}
      proofOfPurchase={data?.serialisedProduct?.info?.proofOfPurchase ?? ''}
      nfc={data?.serialisedProduct?.info?.nfcTag ?? ''}
      externalId={data?.serialisedProduct?.info?.externalId ?? ''}
      productType={data?.serialisedProduct?.info?.productType ?? ''}
      imagesData={data?.serialisedProduct?.data?.images ?? []}
      loading={loading}
      error={error}
      {...props}
    />
  );
}
export default EditItemSidePanel;
