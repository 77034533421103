import { System } from '@scannable/common';

import { graphql } from '../graphql';

export const APP_SYSTEM_STATUS = graphql(`
  query ${System.AppSystemStatus} {
    systemStatus {
      minAppVersionSupported
    }
  }
`);
