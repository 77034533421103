import {
  SerialFormatComponent,
  SerialFormatComponentType,
  SerialFormatNumericComponent,
} from '../types/serialisation.types';

export const DEFAULT_PREFIX_ORDER = 0;
export const DEFAULT_DOM_ORDER = 1;
export const DEFAULT_BATCH_ORDER = 2;
export const DEFAULT_CUSTOMER_REFERENCE_ORDER = 3;
export const DEFAULT_SALES_ORDER_NUMBER_ORDER = 4;
export const DEFAULT_PURCHASE_ORDER_NUMBER_ORDER = 5;
export const DEFAULT_INCREMENTS = 4;
export const DEFAULT_INCREMENTS_ORDER = 6;
export const DEFAULT_COUNTRY_OF_ORGIN_ORDER = 7;
export const DEFAULT_SUFFIX_ORDER = 8;

export function serialFormatPartByType(
  type: SerialFormatComponentType,
  components: SerialFormatComponent[] | null
) {
  const component = (components || []).find((c) => c.type === type);
  return component ?? null;
}

export function serialFormatParts(components: SerialFormatComponent[] | null): {
  hasPrefix: boolean;
  prefix: SerialFormatComponent | null;
  hasSuffix: boolean;
  suffix: SerialFormatComponent | null;
  hasDom: boolean;
  dom: SerialFormatComponent | null;
  hasBatch: boolean;
  batch: SerialFormatComponent | null;
  hasCountryOfOrgin: boolean;
  countryOfOrigin: SerialFormatComponent | null;
  hasCustomerReference: boolean;
  hasPurchaseOrderNumber: boolean;
  purchaseOrderNumber: SerialFormatComponent | null;
  customerReference: SerialFormatComponent | null;
  hasSalesOrderNumber: boolean;
  salesOrderNumber: SerialFormatComponent | null;
  hasIncrements: boolean;
  increments: SerialFormatNumericComponent | null;
} {
  const prefix = serialFormatPartByType(
    SerialFormatComponentType.prefix,
    components
  );
  const suffix = serialFormatPartByType(
    SerialFormatComponentType.suffix,
    components
  );
  const dom = serialFormatPartByType(SerialFormatComponentType.dom, components);
  const batch = serialFormatPartByType(
    SerialFormatComponentType.batch,
    components
  );
  const customerReference = serialFormatPartByType(
    SerialFormatComponentType.customerReference,
    components
  );
  const countryOfOrigin = serialFormatPartByType(
    SerialFormatComponentType.countryOfOrigin,
    components
  );
  const salesOrderNumber = serialFormatPartByType(
    SerialFormatComponentType.salesOrderNumber,
    components
  );
  const purchaseOrderNumber = serialFormatPartByType(
    SerialFormatComponentType.purchaseOrderNumber,
    components
  );
  const increments = serialFormatPartByType(
    SerialFormatComponentType.increments,
    components
  );
  return {
    hasPrefix: Boolean(prefix),
    prefix,
    hasSuffix: Boolean(suffix),
    suffix,
    hasDom: Boolean(dom),
    dom,
    hasBatch: Boolean(batch),
    batch,
    hasCustomerReference: Boolean(customerReference),
    customerReference,
    hasCountryOfOrgin: Boolean(countryOfOrigin),
    countryOfOrigin,
    hasSalesOrderNumber: Boolean(salesOrderNumber),
    salesOrderNumber,
    hasPurchaseOrderNumber: Boolean(purchaseOrderNumber),
    purchaseOrderNumber,
    hasIncrements: Boolean(increments),
    increments: {
      type: increments?.type || SerialFormatComponentType.increments,
      order: increments?.order ?? DEFAULT_INCREMENTS_ORDER,
      value: increments?.value ? Number(increments.value) : DEFAULT_INCREMENTS,
    },
  };
}
