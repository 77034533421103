import { ProductVariation } from '@scannable/common';

import { graphql } from '../graphql';

export const ORIGINAL_ROPE = graphql(
  `
    query AddRopeVariantQuery($manufacturerId: Int!, $ropeId: Int!) {
      productVariations(
        where: {
          AND: [
            { manufacturerId: { equals: $manufacturerId } }
            {
              children: {
                some: { childProductVariationId: { equals: $ropeId } }
              }
            }
          ]
        }
      ) {
        id
        name
        code
      }
    }
  `
);
export const MANUFACTURER_SPLICES = graphql(
  `query ${ProductVariation.AddSpliceQuery}($manufacturerId: Int!) {
      productVariations(
        where: {
          AND: [
            { manufacturerId: { equals: $manufacturerId } }
            {
              product: {
                is: {
                  productGroup: { is: { name: { equals: "Splice/Termination" } } }
                }
              }
            }
          ]
        }
      ) {
        id
        name
        code
      }
    }
  `
);
export const PAGINATED_PRODUCT_VARIATIONS_BY_PRODUCT = graphql(`
  query ${ProductVariation.PaginatedProductVariationsByProduct} (
    $where: ProductVariationWhereInput
    $orderBy: [ProductVariationOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedProductVariations(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        code
        ean
        status
        type
        productId
        product {
          id
          name
          productGroup {
            id
            name
          }
        }
        image {
          id
          smallUrl
          key
          uri
        }
      }
      totalCount
    }
  }
`);

export const PAGINATED_PRODUCT_VARIATIONS_QUERY = graphql(`
  query ${ProductVariation.PaginatedProductVariationsQuery} (
    $where: ProductVariationWhereInput
    $orderBy: [ProductVariationOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedProductVariations(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        code
        type
        productId
        manufacturerId
        manufacturer {
          name
        }
        product {
          name
          productGroup {
            id
            name
          }
        }
        image {
          id
          smallUrl
          key
          uri
        }
        data {
          data {
            id
            key
            name
            value
            isImportant
            valueForDisplay
          }
        }
      }
      totalCount
    }
  }
`);

export const PRODUCT_VARIATIONS_BY_MANUFACTURER = graphql(`
  query ${ProductVariation.ProductVariationsByManufacturer} (
    $where: ProductVariationWhereInput
    $orderBy: [ProductVariationOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    productVariations(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      name
      code
      ean
      status
      type
      productId
      product {
        id
        name
        productGroup {
          id
          name
        }
      }
      image {
        id
        smallUrl
        key
        uri
      }
    }
  }
`);

export const PRODUCT_VARIATION_ADMIN = graphql(`
      query ${ProductVariation.ProductVariationAdmin}($id: Int!) {
        productVariation :productVariationAdmin(id: $id ) {
          id
          name
          type
          product {
            id
            name
          }
        }
      }`);

export const PRODUCT_VARIATION_MANUFACTURERS_ADMIN = graphql(`
      query ${ProductVariation.ProductVariationManufacturersAdmin}($id: Int!) {
        productVariation :getProductVariation(id: $id) {
          id
          name
          type
          product {
            id
            name
          }
          manufacturer {
            id
            name
          }
        }
      }`);

export const PRODUCT_VARIATION_SERIALS = graphql(`
  query ${ProductVariation.ProductVariationSerialisedProducts}($id: Int!) {
    productVariation: getProductVariation(id: $id) {
      id
      serialisedProducts {
        id
        uid
        uuid
        serialNumber
        uniqueLocalUrl
      }
    }
  }
`);

export const MOVE_PRODUCT_VARIATION = graphql(`
mutation ${ProductVariation.MoveProductVariation}($data: MoveProductVariationInput!) {
  moveProductVariation(data: $data) {
    id
    productId
  }
}
`);

export const SKU_SCREEN_DATA =
  graphql(`query ${ProductVariation.SKUScreenData}($id: Int!) {
  productVariation: getProductVariation(id: $id) {
    id
    manufacturerId
    ean
    code
    name
    status
    type
    info {
      id
      name
      code
      ean
      manufacturerLogo
      manufacturerName
      productGroupName
    }
    image {
      id
      uri
    }
    data {
      data {
        id
        dataId
        dataType
        metadataId
        key
        name
        value
        unit
        order
        hasUnits
        availableUnits
        isNumeric
        productDataType
        pdfs {
          id
          name
          url
        }
        images {
          id
          name
          uri
        }
        certifications {
          id
          name
          displayName
        }
      }
      pdfs {
        id
        name
        url
      }
      images {
        id
        name
        uri
      }
      certifications {
        id
        name
      }
    }
    components {
      id
      info {
        name
        code
        ean
        productName
        manufacturerLogo
        manufacturerName
        productGroupName
      }
      image {
        id
        uri
      }
    }
  }
}`);

export const FIND_MANY_PRODUCT_VARIATIONS =
  graphql(`query ${ProductVariation.ProductVariationsQuery}($where: ProductVariationWhereInput) {
productVariations(where: $where) {
  id
  code
  name
  }
}`);
