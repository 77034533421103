import { SerialFormat } from '@scannable/common';

import { graphql } from '../graphql';

export const FIND_ONE_SERIAL_FORMAT = graphql(`
  query ${SerialFormat.FindOneSerialFormat}($id: Int!) {
    serialFormat(id: $id) {
      id
      name
      format
    }
  }
`);

export const GET_MANUFACTURERS_SERIAL_FORMATS =
  graphql(`query ${SerialFormat.GetManufacturersSerialFormats} {
  manufacturerSerialFormats {
    id
    name
    format
  }
}`);

export const USER_SERIAL_FORMATS =
  graphql(`query ${SerialFormat.GetUserSerialFormats} {
  serialFormats {
    id
    name
    format
  }
}`);
