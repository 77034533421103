import { Link } from '../../atoms';

/* eslint-disable-next-line */
export interface TermsAndPrivacyFooterProps {}

export function TermsAndPrivacyFooter(props: TermsAndPrivacyFooterProps) {
  return (
    <div className="flex justify-center ">
      <div className="max-w-md text-center text-sm">
        By using Scannable, you agree to our{' '}
        <Link href="https://www.scannable.io/terms-conditions" external>
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href="https://www.scannable.io/privacy-policy" external>
          Privacy Policy
        </Link>
        .
      </div>
    </div>
  );
}

export default TermsAndPrivacyFooter;
