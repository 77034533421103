import { ExaminationStatus } from '@prisma/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Inspection } from '@scannable/common';
import {
  ExaminationStatusLabel,
  useUpdateExaminationMutation,
} from '@scannable/frontend/common';
import { FormFieldOptionType } from '@scannable/frontend/types';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export interface EditExaminationModalProps extends EditExaminationValues {
  id: string;
  customerAddresses: FormFieldOptionType[];
  issuedByAddresses: FormFieldOptionType[];
}
type EditExaminationValues = {
  dateOfIssue: string;
  customerAddressId: null | string;
  issueByAddressId: null | string;
  declaration: string;
  status: ExaminationStatus;
};
const validationSchema = yup.object().shape({
  dateOfIssue: yup.string().required('Please enter a date'),
});

export function EditExaminationModal({
  id,
  dateOfIssue,
  declaration,
  customerAddresses,
  issuedByAddresses,
  customerAddressId,
  issueByAddressId,
  status,
}: EditExaminationModalProps) {
  const { hideModal } = useModal();
  const [updateExamination] = useUpdateExaminationMutation({
    refetchQueries: [Inspection.ExaminationJobQuery],
  });

  const formik = useFormik({
    initialValues: {
      dateOfIssue: dateOfIssue ?? '',
      customerAddressId: customerAddressId ?? null,
      issueByAddressId: issueByAddressId ?? null,
      declaration: declaration ?? '',
      status: status ?? ExaminationStatus.ACTIVE,
    } as EditExaminationValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (
      values: EditExaminationValues,
      actions: FormikHelpers<EditExaminationValues>
    ) => {
      const result = await resolveMutation(
        updateExamination({
          variables: {
            data: {
              id: id,
              dateOfIssue: values.dateOfIssue,
              declaration: values.declaration,
              customerAddressId: values.customerAddressId,
              issueByAddressId: values.issueByAddressId,
              status: values.status,
            },
          },
        }),
        {
          failureMessage: 'Failed to update Examination',
          successMessage: 'Updated Examination successfully',
        }
      );
      if (result.ok) {
        actions.setSubmitting(false);
        hideModal();
      }
    },
  });
  return (
    <Modal title="Edit Examination">
      <ModalForm
        className="max-h-[calc(100vh-200px)] overflow-y-auto"
        formik={formik}
        buttons={[
          {
            label: 'Update',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
            'data-cy': 'save-group',
          },
        ]}
      >
        <InputFieldGroup
          name="status"
          type="select"
          formik={formik}
          label="Examination Status"
          tooltip='The status of the examination. Mark as "Complete" if the examination is finished. This will hide the examination from the inspection process.'
          options={[
            {
              label: ExaminationStatusLabel[ExaminationStatus.COMPLETE],
              value: ExaminationStatus.COMPLETE,
            },
            {
              label: ExaminationStatusLabel[ExaminationStatus.ACTIVE],
              value: ExaminationStatus.ACTIVE,
            },
          ]}
        />
        <InputFieldGroup
          name="dateOfIssue"
          type="date"
          formik={formik}
          inline
          label="Date of Issue"
        />
        <InputFieldGroup
          name="declaration"
          tooltip="The declaration of the examination. This pulls through from your Scannable Profile when the examination is created."
          type="textarea"
          formik={formik}
          label="Enter the Declaration"
        />
        <InputFieldGroup
          name="customerAddressId"
          tooltip="The address of the customer who is having the examination"
          type="address"
          formik={formik}
          label="Customer Address"
          options={customerAddresses}
        />
        <InputFieldGroup
          name="issueByAddressId"
          tooltip="The address of the person who is issuing the examination. If you don't see your address here, add your address to your inspector account."
          type="address"
          formik={formik}
          label="Issued Address"
          options={issuedByAddresses}
        />
      </ModalForm>
    </Modal>
  );
}

export default EditExaminationModal;
