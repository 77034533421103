import { useEffect, useState } from 'react';

import ScanditScanner from '../../organisms/ScanditScanner/ScanditScanner';
import { useModal } from '../ModalContext/ModalContext';
import SimpleModal from '../SimpleModal/SimpleModal';

export interface BarcodeModalProps {
  setCameraValue: (value: string) => void;
}

type BarcodeScan = { barcodes: { data: string }[] };

export function BarcodeModal({ setCameraValue }: BarcodeModalProps) {
  const { hideModal } = useModal();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <SimpleModal size="lg" title="Scan QR/Barcode">
      {visible && (
        <ScanditScanner
          visible={visible}
          onScan={(v: BarcodeScan) => {
            setCameraValue(`${v.barcodes[0].data}`);
            hideModal();
          }}
          setVisible={setVisible}
        />
      )}
    </SimpleModal>
  );
}

export default BarcodeModal;
