import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { SerialFormatOption } from '@scannable/common';
import { FormFieldOptionType } from '@scannable/frontend/types';
import {
  GET_MANUFACTURERS_SERIAL_FORMATS,
  USER_SERIAL_FORMATS,
} from '@scannable/graphql-queries';

export interface UseSerialFormat {
  serialFormatId: number | null;
  initialSerialFormatId: number | null;
  setSerialFormatId: (id: number | null) => void;
  loading: boolean;
  manufacturerSerialFormats: SerialFormatOption[] | null;
  userSerialFormats: SerialFormatOption[] | null;
  serialFormats: FormFieldOptionType[];
  hasSerialFormats: boolean;
}

export function useSerialFormat(): UseSerialFormat {
  const [serialFormatId, setSerialFormatId] = useState<number | null>(null);
  const { data, loading } = useQuery(GET_MANUFACTURERS_SERIAL_FORMATS);
  const { data: userData, loading: userLoading } =
    useQuery(USER_SERIAL_FORMATS);

  const manufacturerFormats = data?.manufacturerSerialFormats ?? null;
  const userSerialFormats = userData?.serialFormats ?? null;

  const serialFormatsList = userSerialFormats
    ? userSerialFormats.map((s) => ({
        label: s.name ?? '',
        value: s.id,
      }))
    : [];

  return {
    hasSerialFormats: Boolean(
      manufacturerFormats && manufacturerFormats.length > 0
    ),
    loading: Boolean(loading || userLoading),
    initialSerialFormatId:
      userSerialFormats && userSerialFormats.length > 0
        ? userSerialFormats[0].id
        : null,
    manufacturerSerialFormats: manufacturerFormats,
    userSerialFormats,
    serialFormats: serialFormatsList,
    setSerialFormatId,
    serialFormatId,
  };
}

export default useSerialFormat;
