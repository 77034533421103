import { useQuery } from '@apollo/client';

import { INVENTORY_PRODUCT_GROUPS } from '@scannable/graphql-queries';

const filter = {
  OR: [
    {
      productGroupId: {
        in: '{VAL}',
      },
    },
    {
      productVariation: {
        is: {
          product: {
            is: {
              productGroup: {
                is: {
                  id: {
                    in: '{VAL}',
                  },
                },
              },
            },
          },
        },
      },
    },
  ],
};

export function useProductGroupFilter() {
  const { data } = useQuery(INVENTORY_PRODUCT_GROUPS);
  const options = data?.getInventoryProductGroups
    ? data.getInventoryProductGroups
        .map((group) => ({
          label: group.name,
          value: group.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];
  return {
    options,
    interpolatedFilter: filter,
  };
}
