import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

import { ProductCategories } from '@scannable/common';
import { ProductCategoryData } from '@scannable/frontend/common';
import {
  REMOVE_PRODUCT_GROUP_METADATA,
  UPDATE_PRODUCT_GROUP_METADATA_ORDER,
} from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { ConfirmationModal, useModal } from '../../modals';

function SortableItem({
  productCategoryData,
  productGroupId,
}: {
  productCategoryData: ProductCategoryData;
  productGroupId: number;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: `${productCategoryData.id}` });
  const { showModal, hideModal } = useModal();
  const [removeMetadataFromProductGroup] = useMutation(
    REMOVE_PRODUCT_GROUP_METADATA,
    {
      refetchQueries: [ProductCategories.ProductGroupMetadata],
    }
  );
  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { t } = useTranslation();

  return (
    <div style={itemStyle} className="text-sm table-row" ref={setNodeRef}>
      <div
        className="table-cell w-1/12 align-middle p-1"
        {...attributes}
        {...listeners}
      >
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </div>
      <div className="table-cell w-8/12 text-wrap align-middle p-1">
        {productCategoryData.metadata?.name || ''}
      </div>
      <div className="table-cell text-center w-3/12 align-middle p-1">
        <Button
          size="xs"
          type="button"
          color="danger"
          onClick={() =>
            showModal(
              <ConfirmationModal
                title={`Are sure you want to remove ${productCategoryData.metadata?.name} data field?`}
                buttonLabel="Remove"
                onConfirm={async () => {
                  await resolveMutation(
                    removeMetadataFromProductGroup({
                      variables: {
                        data: {
                          id: productGroupId,
                          productGroupMetadataId: productCategoryData.id,
                        },
                      },
                      onCompleted: () => {
                        hideModal();
                      },
                    }),
                    {
                      model: 'Removed data field',
                    }
                  );
                }}
              />
            )
          }
        >
          {t('remove')}
        </Button>
      </div>
    </div>
  );
}

export interface SortableMetadataFormProps {
  orderedMetadata: ProductCategoryData[];
  productGroupId: number;
}

export function SortableMetadataForm({
  orderedMetadata,
  productGroupId,
}: SortableMetadataFormProps) {
  const [items, setItems] = useState<ProductCategoryData[]>([]);
  useEffect(() => {
    setItems(orderedMetadata);
  }, [orderedMetadata]);
  const [updateProductGroupMetadataOrder] = useMutation(
    UPDATE_PRODUCT_GROUP_METADATA_ORDER,
    {
      refetchQueries: [ProductCategories.ProductGroupMetadata],
    }
  );

  const onSort = useCallback(
    async (fromIndex: number, toIndex: number) => {
      const newItems = Array.from(items);

      const movingItem = newItems[fromIndex];
      newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movingItem);

      setItems(newItems);
      let order = 0;

      const toUpdate = newItems.map((ni) => ({
        id: ni.id,
        order: ++order,
      }));
      await resolveMutation(
        updateProductGroupMetadataOrder({
          variables: {
            data: {
              id: productGroupId,
              productGroupMetadataOrder: toUpdate,
            },
          },
        }),
        { successMessage: 'Order updated' }
      );
    },
    [items, productGroupId, updateProductGroupMetadataOrder]
  );

  const finishSort = useCallback(
    (e: DragEndEvent) => {
      const fromIndex = e.active.data.current?.sortable.index;
      const toIndex = e.over?.data.current?.sortable.index;

      if (fromIndex === undefined || toIndex === undefined) {
        return;
      }

      onSort(fromIndex, toIndex);
    },
    [onSort]
  );

  return (
    <DndContext onDragEnd={finishSort}>
      <div className="table w-full">
        <div className="table-row-group space-y-3">
          <SortableContext items={items.map((i) => ({ id: `${i.id}` }))}>
            {items.map((productCategoryData) => (
              <SortableItem
                key={`${productCategoryData.id}`}
                productCategoryData={productCategoryData}
                productGroupId={productGroupId}
              />
            ))}
          </SortableContext>
        </div>
      </div>
    </DndContext>
  );
}

export default SortableMetadataForm;
