import { dateFormat, lastInspectionFormat } from '@scannable/common';
import { TranslateFn } from '@scannable/frontend/translations';

import { UseSkuOrItemData } from '../../hooks/useSkuOrItemScreenQuery/useSkuOrItemScreenQuery';
import { h } from '../../utils/formatting.utils';
import {
  isItem,
  ViewProductInfoItem,
  ViewProductLastInspection,
} from './types';

type MakeProductInfoItemsArgs = {
  product: UseSkuOrItemData | null;
  t: TranslateFn;
  name?: string | null;
  lastInspection?: ViewProductLastInspection;
  showPrivateData: boolean;
  nextInspectionAt?: string | null;
  expiryDate?: string | null;
  dom?: string | null;
  dofu?: string | null;
  proofOfPurchase?: string | null;
};

export const makeProductInfoItems = ({
  product,
  t,
  name,
  lastInspection,
  showPrivateData,
  nextInspectionAt,
  expiryDate,
  dom,
  dofu,
  proofOfPurchase,
}: MakeProductInfoItemsArgs): ViewProductInfoItem[] => {
  if (!product) {
    return [];
  }

  const items = [];

  const isUniqueItem = isItem(product);

  if (isUniqueItem && product?.serialNumber) {
    items.push({
      name: t('serial_number'),
      value: product?.serialNumber,
    });
  }

  if (name) {
    items.push({
      name: t('name'),
      value: name,
    });
  }

  items.push({
    name: t('manufacturer'),
    value: product?.info?.manufacturerName ?? '',
  });

  if (product?.info?.code) {
    items.push({
      name: h(t('part_number')),
      value: product?.info?.code,
    });
  }

  if (product?.info?.ean) {
    items.push({
      name: 'EAN',
      value: product?.info?.ean,
    });
  }

  if (isUniqueItem && product?.info?.batchNumber) {
    items.push({
      name: t('create_serials_batch_num'),
      value: product?.info?.batchNumber,
    });
  }

  if (dom) {
    items.push({
      name: t('dom'),
      value: dateFormat(dom),
    });
  }
  if (dofu) {
    items.push({
      name: t('dofu'),
      value: dateFormat(dofu),
    });
  }

  if (lastInspection) {
    items.push({
      name: t('last_inspection'),
      value: lastInspectionFormat(lastInspection),
    });
  }

  if (nextInspectionAt) {
    items.push({
      name: t('next_inspection'),
      value: dateFormat(nextInspectionAt),
    });
  }

  if (expiryDate) {
    items.push({
      name: t('expiry_date'),
      value: dateFormat(expiryDate),
    });
  }

  if (isUniqueItem && product?.info?.ownedByName) {
    items.push({
      name: 'Owned by',
      value: product?.info?.ownedByName,
    });
  }

  const assignedUser = isUniqueItem ? product?.info?.assignedUser : undefined;

  if (assignedUser && showPrivateData) {
    items.push({
      name: 'Assigned to',
      value: assignedUser,
    });
  }

  if (isUniqueItem && product?.info?.externalId && showPrivateData) {
    items.push({
      name: t('external_id'),
      value: product?.info?.externalId,
    });
  }

  if (proofOfPurchase) {
    items.push({
      name: t('purchase_order'),
      value: proofOfPurchase,
    });
  }

  return items;
};
