import { useFormik } from 'formik';
import * as yup from 'yup';

import { useBoolean } from '@scannable/frontend/common';

import { Text } from '../../atoms';
import { ModalForm } from '../../forms';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export interface ConfirmationModalProps {
  title: string;
  message?: string;
  shouldConfirm?: boolean;
  onConfirm: () => Promise<void>;
  buttonLabel?: string;
  type?: 'danger' | 'info' | 'lime';
  hideOnConfirm?: boolean;
  onDismiss?: () => void;
}

type ConfirmationValues = {
  confirmation: string;
};

export function ConfirmationModal({
  title,
  message,
  onConfirm,
  shouldConfirm = false,
  type = 'danger',
  buttonLabel = 'Delete',
  hideOnConfirm = false,
  onDismiss,
}: ConfirmationModalProps) {
  const { hideModal } = useModal();
  // we don't have access to loading state from the underlying action so this is a little hack to show the loading state on the button
  const [loading, setLoading] = useBoolean();

  const formik = useFormik<ConfirmationValues>({
    initialValues: {
      confirmation: '',
    },
    validationSchema: shouldConfirm
      ? yup.object().shape({
          confirmation: yup.string().required('Please confirm'),
        })
      : null,
    onSubmit: async () => {
      setLoading.on();
      await onConfirm();
      if (hideOnConfirm) {
        hideModal();
      }
      setLoading.off();
    },
  });

  return (
    <Modal title={title} size="sm" onDismiss={onDismiss}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: buttonLabel,
            color: type,
            'data-cy': 'confirm-delete',
            disabled:
              loading ||
              (shouldConfirm && formik.values.confirmation !== 'CONFIRM'),
            loading,
          },
        ]}
      >
        <div className="">{message && <Text>{message}</Text>}</div>
        {shouldConfirm && (
          <InputFieldGroup
            type="text"
            name="confirmation"
            placeholder="Please type CONFIRM to confirm"
            formik={formik}
          />
        )}
      </ModalForm>
    </Modal>
  );
}

export default ConfirmationModal;
