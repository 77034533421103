import { useMutation, useQuery } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Product } from '@scannable/common';
import { useGetProductDataQuery } from '@scannable/frontend/common';
import {
  ALL_ACTIVE_PRODUCT_CATEGORIES,
  UPDATE_PRODUCT,
} from '@scannable/graphql-queries';

import { Button } from '../../atoms';
import { Form, ProductDataForm } from '../../forms';
import { useTranslation } from '../../hooks';
import { SidePanelLayout } from '../../layouts';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup, SidePanelButtonContainer } from '../../molecules';
import { Tab, TabProvider, TabsContainer } from '../../organisms';

type EditProductSidePanelProps = {
  id: number;
};
type ProductUpdateValues = {
  name: string;
  productGroupId: number;
};

export const validationSchema = yup.object().shape({
  code: yup.string().required('Please enter code for new variation'),
  cloneId: yup.number().nullable().typeError('Please enter variation to clone'),
});

const productValidationSchema = yup.object().shape({
  name: yup.string().required('A product name is required'),
});

export function EditProductSidePanel({
  id,
  name,
  productGroupId,
}: EditProductSidePanelProps & ProductUpdateValues) {
  const { t } = useTranslation();

  const { data: productGroupsData } = useQuery(ALL_ACTIVE_PRODUCT_CATEGORIES);
  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: [Product.PaginatedProductsQuery],
  });
  const { data: productData, loading } = useGetProductDataQuery({
    variables: {
      id: id,
    },
  });
  const dataFields = productData?.product?.data?.data
    ? [...productData.product.data.data]
    : [];

  const formik = useFormik<ProductUpdateValues>({
    initialValues: {
      name: name ?? '',
      productGroupId: productGroupId ?? 0,
    },
    enableReinitialize: true,
    validationSchema: productValidationSchema,
    onSubmit: async (
      values: ProductUpdateValues,
      actions: FormikHelpers<ProductUpdateValues>
    ) => {
      await resolveMutation(
        updateProduct({
          variables: {
            data: {
              id,
              name: values.name,
              productGroupId: Number(values.productGroupId),
            },
          },
        }),
        {
          successMessage: 'Product updated',
        }
      );
      actions.setSubmitting(false);
    },
  });

  return (
    <SidePanelLayout isTabbedLayout>
      <TabProvider defaultTab="product">
        <TabsContainer isInSidePanel />
        <Tab name="product">
          <Form className="h-full" formik={formik}>
            <div className="space-y-4 px-5 h-full mt-4">
              <InputFieldGroup
                type="text"
                name="name"
                label="Product name"
                formik={formik}
              />
              <InputFieldGroup
                type="select"
                name="productGroupId"
                label="Product Group"
                tooltip="To change the product group, select the appropriate group from the dropdown and update the product."
                options={productGroupsData?.productGroups.map((pg) => ({
                  value: pg.id,
                  label: pg.name,
                }))}
                formik={formik}
              />
            </div>
            <SidePanelButtonContainer>
              <Button
                type="submit"
                className="w-full"
                disabled={!formik.isValid || formik.isSubmitting}
                loading={formik.isSubmitting}
                data-cy="update-product"
              >
                {t('update')}
              </Button>
            </SidePanelButtonContainer>
          </Form>
        </Tab>
        <Tab name="data" label="Product Data">
          <ProductDataForm
            productId={id}
            dataFields={dataFields}
            loading={loading}
          />
        </Tab>
      </TabProvider>
    </SidePanelLayout>
  );
}

export default EditProductSidePanel;
