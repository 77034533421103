import { AllHTMLAttributes } from 'react';

import { cn } from '@scannable/common';

export interface TableCellProps
  extends AllHTMLAttributes<HTMLTableCellElement> {
  testId?: string;
}

export function TableCell({
  children,
  className,
  testId,
  ...rest
}: TableCellProps) {
  return (
    <td
      className={cn('whitespace-nowrap py-4', className || '')}
      {...rest}
      data-cy={testId}
    >
      {children}
    </td>
  );
}

export default TableCell;
