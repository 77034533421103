import { useMemo } from 'react';
import Link from 'next/link';

import { useGetGroupsBySerialisedProductIdQuery } from '@scannable/frontend/common';
import {
  GroupTypeIconMapping,
  GroupTypeMapping,
  GroupTypePathMapping,
} from '@scannable/frontend/translations';
import { inventoryGroups, InventoryGroupType } from '@scannable/frontend/types';

import Badge from '../../atoms/Badge/Badge';
import Icon, { IconType } from '../../atoms/Icon/Icon';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { useTranslation } from '../../hooks';
import { useSidePanel } from '../../side-panels';

interface GroupsListProps {
  id: number;
}

export function GroupsList({ id }: GroupsListProps) {
  const { hideSidePanel } = useSidePanel();
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { data: groups } = useGetGroupsBySerialisedProductIdQuery(
    id,
    isLoggedIn
  );
  type GroupByType = {
    name: string;
    type: InventoryGroupType;
    path: string;
    groups: {
      id: number;
      name: string;
    }[];
  };
  const groupsByType: GroupByType[] = useMemo(() => {
    const getGroupsForType = (type: InventoryGroupType) => {
      return groups?.groupsBySerialisedProductId?.filter(
        (t) => t.type === type
      );
    };

    const allGroups: GroupByType[] = inventoryGroups.map((type) => ({
      type,
      name: t(GroupTypeMapping[type]),
      path: GroupTypePathMapping[type],
      groups: getGroupsForType(type) || [],
    }));

    return allGroups;
  }, [groups?.groupsBySerialisedProductId, t]);

  return (
    <>
      {groupsByType.map((groupType) => (
        <div key={groupType.type} className="border-b py-2 mb-2">
          <div className="flex flex-row items-center justify-between pb-2">
            <Link
              href={`/admin/${groupType.path}`}
              onClick={() => {
                hideSidePanel();
              }}
              className="text-gray-500 text-sm font-medium"
            >
              {groupType.name}
            </Link>
            <div className="bg-gray-200 rounded-lg h-6 w-8 flex items-center justify-center text-xs text-gray-500">
              {groupType.groups.length}
            </div>
          </div>
          <div className="flex flex-wrap">
            {groupType.groups.map((group, i) => (
              <Link
                href={`/admin/${groupType.path}/${group.id.toString()}`}
                key={i}
                onClick={() => {
                  hideSidePanel();
                }}
              >
                <Badge
                  size="lg"
                  colour="gray"
                  className="whitespace-nowrap h-8 mr-2 mb-2"
                  key={i}
                >
                  <Icon
                    name={GroupTypeIconMapping[groupType.type] as IconType}
                    className="mr-2"
                    width={16}
                    height={16}
                  />
                  {group.name}
                </Badge>
              </Link>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default GroupsList;
