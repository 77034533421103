import { useCallback } from 'react';
import { ItemStatus } from '@prisma/client';

import { cn, dateFormat, Role } from '@scannable/common';
import {
  isItem,
  makeProductScreenData,
  UseSkuOrItemData,
  ViewProductComponent,
} from '@scannable/frontend/common';
import { Search } from '@scannable/frontend/types';

import { GroupListItem, ProductPageDataTab } from '../../atoms';
import { useAuth, useRoleGuard } from '../../auth/AuthContext/AuthContext';
import { useTable } from '../../hooks/useTable/useTable';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { useModal } from '../../modals/ModalContext/ModalContext';
import { SimpleModal } from '../../modals/SimpleModal/SimpleModal';
import { TopNotice } from '../../molecules/TopNotice/TopNotice';
import { useSidePanel } from '../../side-panels';
import { ProductDisplayOptions } from '../../types/product-display.types';
import AddToKitButton from '../AddToKitButton/AddToKitButton';
import ItemOptionsMenu from '../ItemOptionsMenu/ItemOptionsMenu';
import LoadProductWrapper from '../LoadProductWrapper/LoadProductWrapper';
import { ProductPageDataTabs } from '../ProductPageDataTabs/ProductPageDataTabs';
import { ProductPageTabs } from '../ProductPageTabs/ProductPageTabs';
import { UniqueUrlBanner } from '../UniqueUrlBanner/UniqueUrlBanner';
import { UniqueUrlCarousel } from '../UniqueUrlCarousel/UniqueUrlCarousel';

export interface ProductDisplayProps {
  product: UseSkuOrItemData;
  tabNumber: number;
  changeTab?: (tabNumber: number) => void;
  hideEmptyData?: boolean;
  showHistoryTab?: boolean;
  openComponentsInModal?: boolean;
  openComponentsInPanel?: boolean;
  showBanner?: boolean;
  refetch?: () => void;
  includePrivateData?: boolean;
}

export function ProductDisplay({
  product,
  tabNumber,
  changeTab,
  hideEmptyData = true,
  isPublicUrl = false,
  showAddToInventory = false,
  showOptionsButton = false,
  openComponentsInModal = false,
  openComponentsInPanel = false,
  showBanner = true,
}: ProductDisplayProps & ProductDisplayOptions) {
  const { t } = useTranslation();
  const { isLoggedIn, organisationId } = useAuth();
  const { showModal } = useModal();
  const { showItemSidePanel } = useSidePanel();
  const { selectedRows } = useTable();
  const roleGuard = useRoleGuard();
  const handleShowComponent = useCallback(
    ({
      serialisedProductId,
      productVariationId,
    }: Pick<
      ViewProductComponent,
      'productVariationId' | 'serialisedProductId'
    >) => {
      if (!serialisedProductId && !productVariationId) {
        throw new Error(
          'Either serialisedProductId or productVariationId must be provided'
        );
      }
      if (openComponentsInPanel) {
        if (serialisedProductId) {
          showItemSidePanel({
            serialisedProductId,
            showOptionsButton,
            showAddToInventory,
            showBanner: false,
          });
        } else if (productVariationId) {
          showItemSidePanel({
            productVariationId,
            showOptionsButton,
            showAddToInventory,
            showBanner: false,
          });
        }
      } else {
        showModal(
          <SimpleModal size="lg">
            <LoadProductWrapper
              key={`serialisedProductId${serialisedProductId}productVariationId${productVariationId}`}
              productVariationId={productVariationId}
              serialisedProductId={serialisedProductId}
              showAddToInventory={showAddToInventory}
              showOptionsButton={showOptionsButton}
            />
          </SimpleModal>
        );
      }
    },
    [
      openComponentsInPanel,
      showAddToInventory,
      showItemSidePanel,
      showModal,
      showOptionsButton,
    ]
  );

  const {
    hasComponents,
    components,
    hasNfc,
    productData,
    showPrivateData,
    lastInspection,
    nextInspectionAt,
    name,
    serialNumber,
    batchNumber,
    history,
    expiryDate,
    dom,
    dofu,
    isExpired,
    parsedStatus,
    proofOfPurchase,
    status,
    assignedUser,
    ownedByName,
    nfcTag,
    itemInInventory,
    externalId,
    nfcTags,
  } = makeProductScreenData({ data: product, organisationId, t });

  const isUniqueItem = isItem(product);

  const shouldShowOptionsButton =
    isUniqueItem && showOptionsButton && itemInInventory;
  const shouldShowAddToInventory =
    showAddToInventory &&
    !shouldShowOptionsButton &&
    roleGuard([Role.Member, Role.Admin]);
  const hasNoNotice = status === ItemStatus.NONE;
  return (
    <div
      className={cn(
        'max-w-lg mx-auto overflow-y-auto rounded-b-lx w-full sm:w-96',
        isPublicUrl ? 'mt-4' : ''
      )}
    >
      {/* Banner */}
      {showBanner && (
        <UniqueUrlBanner
          isLoggedIn={isLoggedIn}
          showLogin={isPublicUrl}
          isPublicUrl={isPublicUrl}
        />
      )}

      {/* Product incl image and name */}
      <div
        className={cn(
          'text-brand-grey rounded-lg flex flex-col bg-white relative',
          selectedRows.length > 0
            ? 'h-[calc(100vh_-_210px)]'
            : 'h-[calc(100vh_-_9rem)]',
          isPublicUrl ? 'shadow-sm h-full' : ''
        )}
      >
        {/* Top Notice */}
        {!isExpired && status === ItemStatus.SAFETY_NOTICE && (
          <TopNotice
            title="Immediate Action Required"
            summary="Inspection Notice. Stop use of this item - see required action"
            status="failure"
          />
        )}
        {expiryDate && isExpired && (
          <TopNotice
            title="Item Expired"
            summary={`Expired: ${dateFormat(expiryDate)}`}
            status="failure"
            isSidePanel={openComponentsInPanel}
          />
        )}
        {hasNoNotice && !isExpired && lastInspection && parsedStatus && (
          <TopNotice
            title={parsedStatus.title}
            summary={
              nextInspectionAt
                ? `Next Due: ${dateFormat(nextInspectionAt)}`
                : `Inspected: ${dateFormat(lastInspection?.date)}`
            }
            status={parsedStatus.status}
            isSidePanel={true}
          />
        )}

        <div
          className={cn('pt-2 flex-grow pb-0', !isPublicUrl ? ' pb-16' : '')}
        >
          <UniqueUrlCarousel images={product?.data?.images} name={name} />

          <div className="mb-4 p-2 text-lg font-semibold text-gray-900 text-center">
            {name}
            {!product && 'No Product Found'}
          </div>

          {/* Tab titles and icons */}
          <div id="product-tabs" />
          {product && (
            <ProductPageTabs
              hasComponents={hasComponents ?? false}
              tabNumber={tabNumber}
              changeTab={
                changeTab
                  ? changeTab
                  : () => {
                      return;
                    }
              }
              showHistoryTab={isUniqueItem}
              numberOfComponents={components?.length}
              showGroupsTab={isLoggedIn && showPrivateData}
            />
          )}

          {!product && (
            <ProductPageDataTab tabIndex={0} selectedTabNumber={0}>
              <GroupListItem name="Description" unit={t('empty_product')} />
            </ProductPageDataTab>
          )}
          {product && (
            <ProductPageDataTabs
              status={status}
              isPublicUrl={isPublicUrl}
              nfcTag={nfcTag}
              tabNumber={tabNumber}
              hasNfc={hasNfc}
              serialNumber={serialNumber}
              batchNumber={batchNumber}
              name={name}
              manufacturer={product?.info?.manufacturerName}
              code={product?.info?.code}
              ean={product?.info?.ean}
              lastInspection={lastInspection}
              expiryDate={expiryDate}
              dom={dom}
              dofu={dofu}
              assignedUser={assignedUser}
              ownedByName={ownedByName}
              proofOfPurchase={proofOfPurchase}
              components={components}
              history={history}
              serialisedProductId={isUniqueItem ? product?.id : undefined}
              showPrivateData={showPrivateData}
              productGroup={product?.info?.productGroupName}
              productData={productData}
              hideEmptyData={hideEmptyData}
              onOpenComponent={handleShowComponent}
              openComponentsInModal={openComponentsInModal}
              openComponentsInPanel={openComponentsInPanel}
              includePrivateData={showPrivateData && isLoggedIn}
              externalId={externalId}
              nextInspectionAt={nextInspectionAt}
              nfcTags={nfcTags}
            />
          )}
        </div>

        {/* Options menu */}
        <div
          className={cn(
            'bg-white border-t border-gray-200 left-0 w-full',
            !isPublicUrl ? 'sticky bottom-0' : ''
          )}
        >
          {shouldShowOptionsButton && <ItemOptionsMenu item={product} />}

          {shouldShowAddToInventory && product?.id && (
            <AddToKitButton
              serialisedProductId={isUniqueItem ? product.id : undefined}
              productVariation={
                {
                  id: product.id,
                  components: product.components ?? null,
                  info: {
                    name: product.info?.name ?? '',
                    code: product.info?.code ?? '',
                    manufacturerName: product.info?.manufacturerName ?? '',
                    isPart: false,
                  },
                  productVariationId: product.id,
                } as unknown as Search
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductDisplay;
