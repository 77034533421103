import { useQuery } from '@apollo/client';

import {
  GET_INVITES,
  ResultOf,
  UseQueryOptions,
} from '@scannable/graphql-queries';

export type GetInvitesData = ResultOf<typeof GET_INVITES>['invitesByUser'];

export function useGetInvitesQuery(
  options?: UseQueryOptions<typeof GET_INVITES>
) {
  return useQuery(GET_INVITES, options);
}
