import { FetchResult } from '@apollo/client';
import { print } from 'graphql';

import { Auth } from '@scannable/common';
import * as Sentry from '@scannable/frontend/sentry';
import {
  NATIVE_REFERESH_TOKENS,
  ResultOf,
  WEB_REFRESH_TOKENS,
} from '@scannable/graphql-queries';

export const refreshJwtToken = async (
  endpoint: string,
  refreshToken?: string | null,
  organisationId?: number | null,
  retries = 2,
  delay = 3000
): Promise<FetchResult<ResultOf<typeof WEB_REFRESH_TOKENS>>> => {
  try {
    const payload =
      refreshToken && organisationId
        ? {
            operationName: Auth.NativeTokenRefresh,
            query: print(NATIVE_REFERESH_TOKENS),
            variables: {
              refreshToken,
              organisationId,
            },
          }
        : {
            operationName: Auth.WebTokenRefresh,
            query: print(WEB_REFRESH_TOKENS),
          };
    // console.log('Payload: ', payload);
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    if (retries > 0) {
      // console.log(`Fetch failed, retrying in ${delay / 1000} seconds...`);
      await new Promise((resolve) => setTimeout(resolve, delay));
      return refreshJwtToken(
        endpoint,
        refreshToken,
        organisationId,
        retries - 1,
        delay
      );
    }
    Sentry.captureException(error);
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'Error refreshing token',
      level: 'info',
    });
    throw new Error(`Failed to fetch after ${retries} retries.`);
  }
};
