import { cn } from '@scannable/common';

import Icon, { IconType } from '../Icon/Icon';

interface ProductPageTabProps {
  name: string;
  isActive: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
  component: IconType;
}

export function ProductPageTab({
  name,
  isActive,
  onClick,
  children,
  component,
}: ProductPageTabProps) {
  return (
    <div
      className={cn(
        isActive ? 'text-gray-800' : '',
        'flex flex-col w-20 fill-slate-400 hover:fill-gray-800 justify-center items-center cursor-pointer py-4 text-center hover:text-gray-800'
      )}
      onClick={onClick}
    >
      {children}
      <Icon
        name={component}
        className={cn(
          isActive ? 'text-gray-800' : '',
          'shrink w-8 h-8 pb-2  block m-auto text-center'
        )}
      />
      <div>{name}</div>
    </div>
  );
}

export default ProductPageTab;
