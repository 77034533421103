import { HTMLAttributes } from 'react';

import { classNames } from '@scannable/common';

export function Text({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p
      className={classNames('mt-1 text-sm text-gray-500', className)}
      {...props}
    >
      {children}
    </p>
  );
}

export default Text;
