import { useMutation } from '@apollo/client';

import {
  ADD_ITEM_TO_GROUP,
  ADD_ITEMS_TO_GROUP,
  ASSIGN_ITEM_TO_GROUPS,
  DELETE_GROUP,
  REMOVE_ITEM_FROM_GROUP,
  UPDATE_GROUP,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useUpdateGroupMutation(
  options?: UseMutationOptions<typeof UPDATE_GROUP>
) {
  return useMutation(UPDATE_GROUP, options);
}

export function useDeleteGroupMutation(
  options?: UseMutationOptions<typeof DELETE_GROUP>
) {
  return useMutation(DELETE_GROUP, options);
}

export function useAddItemToGroupMuation(
  options?: UseMutationOptions<typeof ADD_ITEM_TO_GROUP>
) {
  return useMutation(ADD_ITEM_TO_GROUP, options);
}

export function useRemoveItemFromGroupMutation(
  options?: UseMutationOptions<typeof REMOVE_ITEM_FROM_GROUP>
) {
  return useMutation(REMOVE_ITEM_FROM_GROUP, options);
}

export function useAssignItemToGroupsMutation(
  options?: UseMutationOptions<typeof ASSIGN_ITEM_TO_GROUPS>
) {
  return useMutation(ASSIGN_ITEM_TO_GROUPS, options);
}

export function useAddItemsToGroupMutation(
  options?: UseMutationOptions<typeof ADD_ITEMS_TO_GROUP>
) {
  return useMutation(ADD_ITEMS_TO_GROUP, options);
}
