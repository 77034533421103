import { useMutation } from '@apollo/client';

import {
  UPDATE_ACCOUNT_MANUFACTURER,
  UPDATE_MANUFACTURER,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useUpdateManufacturerMutation(
  options?: UseMutationOptions<typeof UPDATE_MANUFACTURER>
) {
  return useMutation(UPDATE_MANUFACTURER, options);
}

export function useUpdateAccountManufacturerMutation(
  options?: UseMutationOptions<typeof UPDATE_ACCOUNT_MANUFACTURER>
) {
  return useMutation(UPDATE_ACCOUNT_MANUFACTURER, options);
}
