import { ApolloError } from '@apollo/client';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const TABLES_FEATURE_KEY = 'tables';

export type FilterValue = string | number | boolean;
export type FilterOption = {
  label: string | JSX.Element;
  value: FilterValue;
};

export type Filter = { [key: string]: unknown };
export type TableFilterRecord = {
  filterName: string;
  computedFilter?: Filter | null;
  interpolatedFilter?: Filter | null;
  values: FilterOption | FilterOption[] | null;
};
export interface TablesState {
  reload: number;
  loading: boolean;
  error: string | null;
  tableFilters: Record<string, TableFilterRecord[]>;
  selectedRows: number[];
}

export const initialTablesState: TablesState = {
  reload: 0,
  error: null,
  tableFilters: {},
  selectedRows: [],
  loading: false,
};

function interpolateFilter(
  returnObject: Filter,
  values?: FilterValue[] | FilterValue
) {
  if (!values) {
    return null;
  }

  return JSON.parse(JSON.stringify(returnObject), (key, val) =>
    val === '{VAL}' ? values : val
  );
}

export const tablesSlice = createSlice({
  name: TABLES_FEATURE_KEY,
  initialState: initialTablesState,
  reducers: {
    setHasError: (state, action: PayloadAction<Error | ApolloError>) => {
      if (action.payload?.message) {
        state.error = action.payload.message;
      }
    },
    setSelectedRows: (state, action: PayloadAction<number[]>) => {
      state.selectedRows = action.payload;
    },
    initTableFilters: (
      state,
      action: PayloadAction<{
        table: string;
        filters: TableFilterRecord[];
      }>
    ) => {
      state.tableFilters[action.payload.table] = action.payload.filters;
    },
    addTableFilter: (
      state,
      action: PayloadAction<{
        table: string;
        filter: TableFilterRecord;
      }>
    ) => {
      const { table, filter } = action.payload;
      const existingFilters: TableFilterRecord[] =
        state.tableFilters[table] || [];
      const existingFilterIndex = existingFilters.findIndex(
        (f) => f.filterName === filter.filterName
      );
      const newFilter = {
        ...filter,
        computedFilter: filter.interpolatedFilter
          ? interpolateFilter(
              filter.interpolatedFilter,
              Array.isArray(filter.values)
                ? filter.values.map((v) => v.value)
                : filter.values?.value
            )
          : filter.computedFilter,
      };

      if (existingFilterIndex !== -1) {
        // Replace existing filter
        existingFilters[existingFilterIndex] = newFilter;
      } else {
        // Add new filter
        existingFilters.push(newFilter);
      }

      state.tableFilters[table] = existingFilters;
    },
    removeTableFilter: (
      state,
      action: PayloadAction<{
        table: string;
        filterName: string;
        value?: FilterValue;
      }>
    ) => {
      const { table, filterName, value } = action.payload;
      const existingFilters: TableFilterRecord[] =
        state.tableFilters[table] || [];
      const existingFilterIndex = existingFilters.findIndex(
        (f) => f.filterName === filterName
      );
      if (existingFilterIndex !== -1) {
        const filter = existingFilters[existingFilterIndex];
        if (Array.isArray(filter.values)) {
          const valueIndex = filter.values.findIndex((v) => v.value === value);
          // Remove value from filter
          if (valueIndex !== -1) {
            filter.values.splice(valueIndex, 1);
            filter.computedFilter = filter.interpolatedFilter
              ? interpolateFilter(
                  filter.interpolatedFilter,
                  filter.values.map((v) => v.value)
                )
              : filter.computedFilter;
          }
          // Remove filter if no values left
          if (filter.values.length === 0) {
            existingFilters.splice(existingFilterIndex, 1);
          }
        } else {
          existingFilters.splice(existingFilterIndex, 1);
        }
      }
      state.tableFilters[table] = existingFilters;
    },
    resetTableFilters: (state) => {
      state.tableFilters = {};
    },
    clearError: (state) => {
      state.error = null;
    },
    reload: (state) => {
      state.reload = state.reload + 1;
    },
  },
});

export const tablesReducer = tablesSlice.reducer;

export const tablesActions = tablesSlice.actions;

export const {
  setHasError,
  clearError,
  reload,
  addTableFilter,
  removeTableFilter,
  resetTableFilters,
  setSelectedRows,
} = tablesSlice.actions;

export const getTablesState = (rootState: {
  [TABLES_FEATURE_KEY]: TablesState;
}): TablesState => rootState[TABLES_FEATURE_KEY];

export const selectHasErrors = createSelector(
  getTablesState,
  (state: TablesState) => state.error
);

export const selectReloading = createSelector(
  getTablesState,
  (state: TablesState) => state.reload
);

export const selectTableFilters = createSelector(
  getTablesState,
  (state: TablesState) => state.tableFilters
);

export const selectSelectedRows = createSelector(
  getTablesState,
  (state: TablesState) => state.selectedRows
);
