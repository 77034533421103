import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import {
  CompositeComponent,
  ROPE_GROUPS,
  SearchFilterOperator,
} from '@scannable/common';
import { addSku, RootState } from '@scannable/frontend/store';
import { Search } from '@scannable/frontend/types';
import { TEST_SERIALISED_CONFIG } from '@scannable/graphql-queries';

import { Button } from '../../atoms/Button/Button';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { resolveFormSubmit } from '../../lib/lib';
import {
  DescriptionList,
  DescriptionListItem,
  InputFieldGroup,
} from '../../molecules';
import { CameraInput } from '../../organisms';
import DateSelectionDescriptionListItem from '../../organisms/DateSelectionDescriptionListItem/DateSelectionDescriptionListItem';
import SearchInput from '../../organisms/SearchInput/SearchInput';
import { errorAlert, successAlert } from '../../utils';
import Form from '../Form/Form';

type AddSkuValues = {
  productDom: null | string;
  productVariation: null | Search;
  productVariationId: null | number;
  productBatchNumber: string;
  componentBatchNumbers: CompositeComponent[] | null;
  serialQuantity: number;
};
/* eslint-disable-next-line */
export interface AddSkuForSerialisationFormProps {}

export function AddSkuForSerialisationForm(
  props: AddSkuForSerialisationFormProps
) {
  const { manufacturerId, organisationId } = useAuth();
  const dispatch = useDispatch();
  const serialiseProductsState = useSelector(
    (state: RootState) => state.serialisation
  );
  const [testSerialisationConfiguration, { loading: checkingSerialsOK }] =
    useMutation(TEST_SERIALISED_CONFIG);
  const handleProductVariationSubmit = (values: AddSkuValues) => {
    if (
      values.productVariation?.info?.productType &&
      values.productVariation &&
      values.productVariationId
    ) {
      dispatch(
        addSku({
          productDom: values.productDom,
          productBatchNumber: values.productBatchNumber,
          serialQuantity: values.serialQuantity,
          componentBatchNumbers: values.componentBatchNumbers,
          productVariation: values.productVariation,
          productVariationId: values.productVariationId,
          productType: values.productVariation?.info?.productType,
          componentSerials: null,
        })
      );
    }
  };

  const setProductVariation = (productVariation: Search) => {
    formik.setFieldValue('productVariation', productVariation);
    // we need to set the state for component batch numbers here to get around formik state updating
    if (productVariation.components) {
      formik.setFieldValue(
        'componentBatchNumbers',
        productVariation.components.map((child) => ({
          id: child.id,
          productVariationId: child.productVariationId,
          batchNumber: '',
        }))
      );
    }
  };
  const initialValues: AddSkuValues = {
    productDom: null,
    productVariation: null,
    productVariationId: null,
    productBatchNumber: '',
    componentBatchNumbers: [],
    serialQuantity: 0,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      serialQuantity: yup
        .number()
        .moreThan(0, 'The serial quantity must be greater than 1')
        .required('Quantity increments is required'),
    }),
    onSubmit: async (
      values: AddSkuValues,
      actions: FormikHelpers<AddSkuValues>
    ) => {
      if (!serialiseProductsState?.serialPurchaseOrder?.serialFormatId) {
        errorAlert('Please select a serial format');
        actions.setSubmitting(false);
        return;
      }
      if (!values.productVariation) {
        errorAlert('Please select a product');
        actions.setSubmitting(false);
        return;
      }
      const [, error] = await resolveFormSubmit(
        testSerialisationConfiguration({
          variables: {
            data: {
              serialFormatId:
                serialiseProductsState.serialPurchaseOrder.serialFormatId,
              purchaseOrderNumber:
                serialiseProductsState.serialPurchaseOrder.purchaseOrderNumber,
              salesOrderNumber:
                serialiseProductsState.serialPurchaseOrder.salesOrderNumber,
              customerReference:
                serialiseProductsState.serialPurchaseOrder.customerReference,
              batchNumber:
                serialiseProductsState.serialPurchaseOrder.batchNumber,
              dom: serialiseProductsState.serialPurchaseOrder.dom,
              serialQuantity: Number(values.serialQuantity),
              manufacturerId,
              productVariationId: values.productVariation.id,
              componentBatchNumbers: values.componentBatchNumbers,
              organisationId,
            },
          },
        })
      );
      if (error || !values.productVariation.productVariationId) {
        errorAlert(error.message || 'Unable to create serials');
        actions.setSubmitting(false);
        return;
      }
      handleProductVariationSubmit({
        ...values,
        productBatchNumber:
          values.componentBatchNumbers?.find((b) => b.batchNumber)
            ?.batchNumber ?? '',
        productVariationId: values.productVariation.productVariationId,
        serialQuantity: Number(values.serialQuantity),
        productVariation: values.productVariation,
        componentBatchNumbers: values.componentBatchNumbers,
      });
      successAlert('Serial configuration is ok');
      actions.resetForm();
      actions.setSubmitting(false);
    },
  });

  return (
    <Form formik={formik} data-cy="add-products">
      {/* checks to see if the serial format has a date and if date selectd or no date */}
      {serialiseProductsState.serialPurchaseOrder &&
        !formik.values.productVariation && (
          <DescriptionList
            title="Add products"
            subTitle="Search for products by part number. NOTE: This tool won't work with Assembly product types."
          >
            <DescriptionListItem>
              <SearchInput
                name="productVariations"
                addToCallback={setProductVariation}
                filters={[
                  {
                    key: 'manufacturerId',
                    value: manufacturerId,
                    operator: SearchFilterOperator.And,
                  },
                  {
                    key: 'productType',
                    value: 'productType:"COMPONENT" OR productType:"COMPOSITE"',
                    operator: SearchFilterOperator.And,
                    custom: true,
                  },
                ]}
              />
            </DescriptionListItem>
          </DescriptionList>
        )}
      <div>
        {formik.values.productVariation?.info && (
          <DescriptionList title="Add a product">
            <DescriptionListItem title="Product description">
              <div className="ml-4 flex flex-1 flex-col mr-5">
                <div>
                  <div className="flex justify-between text-base font-medium text-gray-900">
                    <h3>{formik.values.productVariation.info.name}</h3>
                    <p className="ml-4">
                      {formik.values.productVariation.info.code}
                    </p>
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    {formik.values.productVariation.info.code}
                  </p>
                </div>
              </div>
            </DescriptionListItem>
            {formik.values.productVariation.info.productType ===
              'COMPOSITE' && (
              <>
                <DescriptionListItem title="This is a cut rope product. Please enter a box batch number">
                  {(formik.values.productVariation.components ?? [])
                    .filter(
                      (c) =>
                        c.productGroupName &&
                        ROPE_GROUPS.includes(c.productGroupName)
                    )
                    .map((child, i) => (
                      <div className="my-5" key={`${child.id}_${i}`}>
                        <CameraInput
                          label="Box batch number"
                          name="componentBatchNumbers"
                          valueField="batchNumber"
                          identifyingKey="id"
                          identifyingValue={child.id}
                          id={`BatchNumber_${child.id}`}
                          placeholder="Enter a batch number"
                          type="text"
                          formik={formik}
                          value={
                            formik.values[`componentBatchNumbers`] &&
                            formik.values[`componentBatchNumbers`][i]
                              ? formik.values[`componentBatchNumbers`].find(
                                  (obj) => obj.id === Number(child.id)
                                )?.batchNumber || ''
                              : ''
                          }
                        />
                      </div>
                    ))}
                </DescriptionListItem>
                <DateSelectionDescriptionListItem
                  formik={formik}
                  fieldName="productDom"
                  label="Date of manufacture"
                />
              </>
            )}
            <DescriptionListItem title="Serial quantity">
              <InputFieldGroup
                id="SerialQuantity"
                label="Enter quantity of serials needed"
                placeholder="Enter number of serials to create"
                name="serialQuantity"
                type="text"
                formik={formik}
                containerClassName="mt-3"
              />
            </DescriptionListItem>
            <DescriptionListItem>
              <div className="mt-9 flex justify-end">
                <Button
                  label="Add Product"
                  type="submit"
                  loading={checkingSerialsOK}
                />
              </div>
            </DescriptionListItem>
          </DescriptionList>
        )}
      </div>
    </Form>
  );
}

export default AddSkuForSerialisationForm;
