import toast from 'react-hot-toast';

import { useSendActivationEmailMutation } from '@scannable/frontend/common';

import { Alert, Link } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';

export function ActivationStatus() {
  const { isLoggedIn, activated } = useAuth();
  const [sendActivationEmail] = useSendActivationEmailMutation({
    onCompleted: () => {
      toast.success('Activation email sent');
    },
  });

  if (isLoggedIn && activated) {
    return null;
  }

  return (
    <Alert
      message={
        <>
          Please confirm your email address. Click here to{' '}
          <Link
            onClick={() => sendActivationEmail()}
            className="text-blue-700 underline cursor-pointer"
          >
            resend confirmation email
          </Link>
          .
        </>
      }
      type="warning"
    />
  );
}

export default ActivationStatus;
