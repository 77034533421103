import { useCallback, useEffect, useState } from 'react';
import { GroupType } from '@prisma/client';
import { FormikHelpers, useFormik } from 'formik';

import {
  Groups,
  Inventory,
  ItemHistory,
  Role,
  SerialisedProduct,
} from '@scannable/common';
import {
  useAssignItemToGroupsMutation,
  useAssignItemToUserMutation,
  useGetAccountGroupNamesQuery,
  useGetGroupsBySerialisedProductIdQuery,
  useGetOrganisationUsersQuery,
  useUnAssignItemMutation,
} from '@scannable/frontend/common';
import {
  GroupTypeIconMapping,
  GroupTypeMapping,
} from '@scannable/frontend/translations';

import { Button } from '../../atoms';
import { useCurrentUser } from '../../auth/AuthContext/AuthContext';
import { SidePanelForm } from '../../forms';
import { Guard } from '../../guards/Guard';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { ConfirmationModal, useModal } from '../../modals';
import {
  AssignToMeButton,
  InputFieldGroup,
  ItemPreview,
  ItemPreviewProps,
} from '../../molecules';
import { useSidePanel } from '../../side-panels';
import { errorAlert } from '../../utils';

type AssignToValue = {
  [GroupType.KIT_BAG]: number[] | null;
  [GroupType.LOCATION]: number[] | null;
  [GroupType.VEHICLE]: number[] | null;
  [GroupType.CLIENT]: number[] | null;
  [GroupType.STAFF]: number[] | null;
  [GroupType.EQUIPMENT_TYPE]: number[] | null;
  teamMemberId: number | null;
};
interface AssignGroupsAndUsersFormProps extends ItemPreviewProps {
  serialisedProductId: number;
  kitBagGroups: number[];
  locationGroups: number[];
  vehicleGroups: number[];
  clientGroups: number[];
  staffGroups: number[];
  equipmentTypeGroups: number[];
  teamMemberId: number | null;
}
function AssignGroupsAndUsersForm({
  serialisedProductId,
  kitBagGroups,
  locationGroups,
  vehicleGroups,
  clientGroups,
  staffGroups,
  equipmentTypeGroups,
  teamMemberId,
  ...props
}: AssignGroupsAndUsersFormProps) {
  const { showItemSidePanel, hideSidePanel } = useSidePanel();
  const user = useCurrentUser();
  const { showModal, hideModal } = useModal();
  const { data: groups } = useGetAccountGroupNamesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [unAssignItem] = useUnAssignItemMutation({
    refetchQueries: [
      SerialisedProduct.SerialisedProductForUniqueUrl,
      Inventory.PaginatedWebInventory,
      ItemHistory.PaginatedItemHistory,
    ],
  });
  const [assignItemToUser] = useAssignItemToUserMutation({
    refetchQueries: [
      SerialisedProduct.SerialisedProductForUniqueUrl,
      Inventory.PaginatedWebInventory,
      ItemHistory.PaginatedItemHistory,
    ],
  });
  const [assignItemToGroups] = useAssignItemToGroupsMutation({
    refetchQueries: [
      Groups.GetGroupsBySerialisedProductId,
      SerialisedProduct.SerialisedProductForUniqueUrl,
    ],
  });
  const { t } = useTranslation();
  const { data: organisationUsers } = useGetOrganisationUsersQuery();
  const options = organisationUsers?.organisationUsers
    ? organisationUsers.organisationUsers
        .map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.userId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];
  const onChange = useCallback(() => {
    showItemSidePanel({
      serialisedProductId,
      showOptionsButton: true,
      showBanner: false,
      onBack: () => {
        hideSidePanel();
      },
    });
  }, [hideSidePanel, serialisedProductId, showItemSidePanel]);
  const formik = useFormik({
    initialValues: {
      [GroupType.KIT_BAG]: kitBagGroups,
      [GroupType.LOCATION]: locationGroups,
      [GroupType.VEHICLE]: vehicleGroups,
      [GroupType.CLIENT]: clientGroups,
      [GroupType.STAFF]: staffGroups,
      [GroupType.EQUIPMENT_TYPE]: equipmentTypeGroups,
      teamMemberId,
    } as AssignToValue,
    onSubmit: async (
      values: AssignToValue,
      actions: FormikHelpers<AssignToValue>
    ) => {
      if (values.teamMemberId !== teamMemberId) {
        if (values.teamMemberId) {
          const result = await assignItemToUser({
            variables: {
              serialisedProductId,
              userId: values.teamMemberId,
            },
          });
          result.errors && errorAlert('An error occured');
        }
        if (!values.teamMemberId) {
          const result = await unAssignItem({
            variables: {
              serialisedProductId,
            },
          });
          result.errors && errorAlert('An error occured');
        }
      }

      const groupIds: number[] = [];
      Object.keys(values)
        .filter(Boolean)
        .filter((k) => k !== 'teamMemberId')
        .forEach((key) => {
          const val = values[key as keyof Omit<AssignToValue, 'teamMemberId'>];
          if (val) {
            val.map((value: number) => groupIds.push(value));
          }
        });
      await resolveMutation(
        assignItemToGroups({
          variables: {
            data: {
              itemId: serialisedProductId,
              groupIds,
            },
          },
        }),
        {
          successMessage: 'Updated Item Successfully',
          failureMessage: 'Failed to Assign Item',
        }
      );
      onChange();
      actions.setSubmitting(false);
    },
  });

  const [groupTypes, setGroupTypes] = useState<
    {
      type: GroupType;
      groups: { label: string; value: number; icon: string }[];
    }[]
  >([]);

  useEffect(() => {
    if (groups?.groups) {
      const types = groups.groups.reduce((acc, group) => {
        acc[group.type] = [
          ...(acc[group.type] || []),
          {
            label: group.name,
            value: group.id,
            icon: GroupTypeIconMapping[group.type],
          },
        ];
        return acc;
      }, {} as Record<string, { label: string; value: number; icon: string }[]>);
      setGroupTypes(
        Object.keys(types).map((type) => ({
          type: type as GroupType,
          groups: types[type],
        }))
      );
    }
  }, [groups]);

  const getGroupsForType = (type: GroupType) => {
    return groupTypes.find((t) => t.type === type)?.groups;
  };
  return (
    <SidePanelForm
      formik={formik}
      buttons={[
        {
          label: t('save'),
          type: 'submit',
          onClick: () => {
            formik.submitForm();
          },
          color: 'primary',
        },
      ]}
    >
      <ItemPreview {...props} />
      <div>
        <AssignToMeButton
          currentUserId={user?.id ?? null}
          teamMemberId={teamMemberId}
          serialisedProductId={serialisedProductId}
          onChange={onChange}
        />
        <div className="mt-6">
          <InputFieldGroup
            name="teamMemberId"
            type="select"
            label="Assign to"
            formik={formik}
            options={options}
            config={{ isClearable: true }}
          />
        </div>
      </div>
      <hr className="border-gray-200 mb-5" />
      <div className="mt-6">
        <div className="mt-6">
          <div className="mt-6">
            <InputFieldGroup
              name={GroupType.KIT_BAG}
              type="react-select"
              label={t(GroupTypeMapping[GroupType.KIT_BAG])}
              formik={formik}
              options={getGroupsForType(GroupType.KIT_BAG)}
              config={{ isMulti: true }}
            />
          </div>
          <div className="mt-6">
            <InputFieldGroup
              name={GroupType.LOCATION}
              type="react-select"
              label={t(GroupTypeMapping[GroupType.LOCATION])}
              formik={formik}
              options={getGroupsForType(GroupType.LOCATION)}
              config={{ isMulti: true }}
            />
          </div>
          <div className="mt-6">
            <InputFieldGroup
              name={GroupType.VEHICLE}
              type="react-select"
              label={t(GroupTypeMapping[GroupType.VEHICLE])}
              formik={formik}
              options={getGroupsForType(GroupType.VEHICLE)}
              config={{ isMulti: true }}
            />
          </div>
          <div className="mt-6">
            <InputFieldGroup
              name={GroupType.CLIENT}
              type="react-select"
              label={t(GroupTypeMapping[GroupType.CLIENT])}
              formik={formik}
              options={getGroupsForType(GroupType.CLIENT)}
              config={{ isMulti: true }}
            />
          </div>
          <div className="mt-6">
            <InputFieldGroup
              name={GroupType.STAFF}
              type="react-select"
              label={t(GroupTypeMapping[GroupType.STAFF])}
              formik={formik}
              options={getGroupsForType(GroupType.STAFF)}
              config={{ isMulti: true }}
            />
          </div>
          <div className="mt-6">
            <InputFieldGroup
              name={GroupType.EQUIPMENT_TYPE}
              type="react-select"
              label={t(GroupTypeMapping[GroupType.EQUIPMENT_TYPE])}
              formik={formik}
              options={getGroupsForType(GroupType.EQUIPMENT_TYPE)}
              config={{ isMulti: true }}
            />
          </div>
          <Guard roles={[Role.Admin]}>
            <div className="mt-6">
              <Button
                label={t('remove_all_groups')}
                color="white"
                type="button"
                className="border-red-500 text-red-500"
                onClick={() =>
                  showModal(
                    <ConfirmationModal
                      title={t('remove_all_groups')}
                      message={t('are_you_sure_you_want_to_remove_all_groups')}
                      onConfirm={() => {
                        hideModal();
                        formik.setValues({
                          [GroupType.KIT_BAG]: [],
                          [GroupType.LOCATION]: [],
                          [GroupType.VEHICLE]: [],
                          [GroupType.CLIENT]: [],
                          [GroupType.STAFF]: [],
                          [GroupType.EQUIPMENT_TYPE]: [],
                          teamMemberId,
                        });
                        return formik.submitForm();
                      }}
                      buttonLabel={t('remove')}
                    />
                  )
                }
              />
            </div>
          </Guard>
        </div>
      </div>
    </SidePanelForm>
  );
}

export interface AssignGroupsSidePanelProps extends ItemPreviewProps {
  id: number;
  assignedUserId?: number | null;
}

export function AssignGroupsSidePanel({
  id,
  assignedUserId,
  ...props
}: AssignGroupsSidePanelProps) {
  const { data: itemGroups } = useGetGroupsBySerialisedProductIdQuery(id);

  const groupIds = (group: GroupType) => {
    return (
      itemGroups?.groupsBySerialisedProductId
        ?.filter((g) => g.type === group)
        .map((g) => g.id) ?? []
    );
  };
  return (
    <AssignGroupsAndUsersForm
      serialisedProductId={id}
      kitBagGroups={groupIds(GroupType.KIT_BAG)}
      locationGroups={groupIds(GroupType.LOCATION)}
      vehicleGroups={groupIds(GroupType.VEHICLE)}
      clientGroups={groupIds(GroupType.CLIENT)}
      staffGroups={groupIds(GroupType.STAFF)}
      equipmentTypeGroups={groupIds(GroupType.EQUIPMENT_TYPE)}
      teamMemberId={assignedUserId ?? null}
      {...props}
    />
  );
}

export default AssignGroupsSidePanel;
