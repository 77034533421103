import { HTMLInputTypeAttribute } from 'react';
import { FormikErrors, FormikTouched } from 'formik';

import { InputText, ToolTip } from '../../atoms';

export interface InputGroupProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  description?: string;
  tooltip?: string;
  touched?: boolean | FormikTouched<unknown> | FormikTouched<unknown>[] | null;
  error?:
    | string
    | FormikErrors<unknown>
    | string[]
    | FormikErrors<unknown>[]
    | null;
  htmlFor?: string;
}

interface InputLabelProps {
  type?: HTMLInputTypeAttribute;
  children?: React.ReactNode;
  name?: string;
  label?: string;
  htmlFor?: string;
  tooltip?: string;
}
function InputLabel({
  type,
  children,
  name,
  label,
  htmlFor,
  tooltip,
}: InputLabelProps) {
  if (type === 'checkbox') {
    return (
      <div className="space-x-2">
        {children}
        {label && (
          <label
            htmlFor={htmlFor || name}
            className="inline-block text-sm text-gray-700 cursor-pointer"
          >
            {label}
          </label>
        )}
      </div>
    );
  }

  return (
    <>
      {label && (
        <label
          htmlFor={htmlFor || name}
          className="inline-block text-sm font-medium text-gray-700 pb-1"
        >
          {label}
        </label>
      )}
      {tooltip && (
        <label className="inline-block align-middle ml-1">
          <ToolTip msg={tooltip} />
        </label>
      )}
      <div className="mt-1">{children}</div>
    </>
  );
}

export function InputGroup({
  className,
  name,
  label,
  children,
  description,
  touched,
  error,
  tooltip,
  type,
  htmlFor,
}: InputGroupProps) {
  return (
    <div className={className}>
      <InputLabel
        type={type}
        name={name}
        label={label}
        htmlFor={htmlFor}
        tooltip={tooltip}
      >
        {children}
      </InputLabel>

      {description && (
        <InputText name={name} type="info" message={description} />
      )}
      {touched && error && (
        <InputText name={name} type="error" message={error.toString()} />
      )}
    </div>
  );
}

export default InputGroup;
