import { RadioGroup } from '@headlessui/react';

import { classNames } from '@scannable/common';
import { FormFieldOptionType } from '@scannable/frontend/types';

export interface RadioGroupTableProps<T> {
  name: string;
  label: string;
  options: FormFieldOptionType[];
  value?: T;
  onChange?: (value: T) => void;
  error?: string;
  touched?: boolean;
}

export function RadioGroupTable<T>({
  name,
  label,
  options,
  value,
  onChange,
  error,
  touched,
}: RadioGroupTableProps<T>) {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
      <div className="relative bg-white rounded-md -space-y-px">
        {options.map((option, optionIdx) => (
          <RadioGroup.Option
            key={`${option.value}`}
            value={option.value}
            className={({ checked }) =>
              classNames(
                optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                optionIdx === options.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked
                  ? 'bg-indigo-50 border-indigo-200 z-10'
                  : 'border-gray-200',
                'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <div className="flex items-center text-sm">
                <span
                  className={classNames(
                    checked
                      ? 'bg-indigo-600 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                    'h-4 w-4 rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <RadioGroup.Label
                  as={typeof option.label === 'string' ? 'span' : 'div'}
                  className={classNames(
                    checked ? 'text-indigo-900' : 'text-gray-900',
                    'ml-3 font-medium',
                    typeof option.label !== 'string' ? 'w-full' : ''
                  )}
                >
                  {option.label}
                </RadioGroup.Label>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
      {error && touched && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </RadioGroup>
  );
}

export default RadioGroupTable;
