import { Metadata } from '@scannable/common';

import { graphql } from '../graphql';

export const CREATE_METADATA_CATEGORY = graphql(`
  mutation ${Metadata.CreateMetadataCategory} ($data: CreateMetadataCategoryInput!) {
    createMetadataCategory(data: $data) {
      id
    }
  }
`);

export const UPDATE_METADATA_CATEGORY = graphql(`
  mutation ${Metadata.UpdateMetadataCategory} ($data: UpdateMetadataCategoryInput!) {
    updateMetadataCategory(data: $data) {
      id
    }
  }
`);

export const UPDATE_METADATA = graphql(`
  mutation ${Metadata.UpdateMetadata} ($data: UpdateMetadataInput!) {
    updateMetadata(data: $data) {
      id
    }
  }
`);

export const CREATE_METADATA = graphql(`
  mutation ${Metadata.CreateMetadata} ($data: CreateMetadataInput!) {
    createMetadata(data: $data) {
      id
    }
  }
`);
