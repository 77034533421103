import { SerialPurchaseOrder } from '@scannable/common';

import { graphql } from '../graphql';

export const DELETE_SERIAL_PURCHASE_ORDER = graphql(
  `mutation ${SerialPurchaseOrder.DeleteSerialPurchaseOrder}($id: String!) {
      deleteSerialPurchaseOrder(id: $id) {
        id
      }
    }`
);
