import { Checklist, Inspection } from '@scannable/common';

import { graphql } from '../graphql';

export const CREATE_INSPECTION =
  graphql(`mutation ${Inspection.CreateInspection}($data: CreateProductInspectionInput!) {
    createInspection(data: $data) {
      id
  }
}`);

export const CREATE_MANY_INSPECTIONS =
  graphql(`mutation ${Inspection.CreateInspections}($data: [CreateProductInspectionInput!]!) {
  createInspections(data: $data)
}`);

export const CREATE_EXAMINATION =
  graphql(`mutation ${Inspection.CreateExaminationMutation}($data: CreateExaminationInput!) {
    createExamination(data: $data) {
      id
    }
  }`);

export const UPDATE_EXAMINATION =
  graphql(`mutation ${Inspection.UpdateExaminationMutation}($data: UpdateExaminationInput!) {
    updateExamination(data: $data) {
      id
    }
  }`);

export const CREATE_CHECKLIST =
  graphql(`mutation ${Checklist.CreateChecklistMutation}($data: CreateChecklistInput!) {
    createChecklist(data: $data) {
      id
    }
  }`);

export const UPDATE_CHECKLIST = graphql(`
  mutation ${Checklist.UpdateChecklistMutation}($data: UpdateChecklistInput!) {
    updateChecklist(data: $data) {
      id
    }
  }`);

export const DELETE_CHECKLIST = graphql(`
  mutation ${Checklist.DeleteChecklistMutation}($id: ID!) {
    deleteChecklist(id: $id)
  }`);

export const COPY_CHECKLIST = graphql(`
  mutation ${Checklist.CopyChecklistMutation}($id: ID!) {
    copyChecklist(id: $id) {
      id
    }
  }`);
