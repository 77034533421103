import { cn } from '@scannable/common';

import { Link } from '../../atoms';

export interface ModalFooterProps {
  dismissable?: boolean;
  onClose: () => void;
  renderAction?: () => React.ReactNode;
}

export function ModalFooter({
  onClose,
  renderAction,
  dismissable = true,
}: ModalFooterProps) {
  return (
    <div className="border-t bg-white sticky bottom-0 p-4 pb-1 mt-4">
      <div
        className={cn(
          'flex flex-row',
          dismissable ? 'justify-between' : 'justify-end'
        )}
      >
        {dismissable && (
          <Link
            onClick={() => onClose()}
            className="cursor-pointer text-sm text-gray-500 hover:text-gray-900 py-2"
          >
            Close
          </Link>
        )}
        {renderAction && renderAction()}
        {/* {buttons && buttons.length > 0 && (
            <div className="flex space-x-3">
              {buttons.map((buttonProps, index) => (
                <Button
                  key={index}
                  className="disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400 disabled:bg-gray-200"
                  disabled={formik.isSubmitting || !formik.isValid}
                  loading={formik.isSubmitting}
                  {...buttonProps}
                />
              ))}
            </div>
          )} */}
      </div>
    </div>
  );
}

export default ModalFooter;
