import { useRouter } from 'next/router';
import { SubscriptionStatus, SubscriptionType } from '@prisma/client';
import { FormikHelpers, useFormik } from 'formik';

import { Integration, Organisation } from '@scannable/common';
import {
  organisationSubscriptionStatusOptions,
  organisationTypeOptions,
  useUpdateOrganisationMutation,
} from '@scannable/frontend/common';

import { Button } from '../../atoms';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { resolveMutation } from '../../lib/lib';
import { FormAdminPanel } from '../../molecules/FormAdminPanel/FormAdminPanel';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import { Form } from '../Form/Form';

type OrganisationUpdateValues = {
  name: string;
  subscriptionType: SubscriptionType;
  subscriptionStatus: SubscriptionStatus;
  isManufacturer: boolean;
  isReseller: boolean;
  isEquipmentOwner: boolean;
  isInspector: boolean;
  isTrainingCentre: boolean;
  manufacturerName: string;
  verified: boolean;
  trialEnds: string;
  inventoryLimit: number;
  integrations: Integration[];
};

export function EditOrganisationForm({
  name,
  subscriptionType,
  subscriptionStatus,
  isManufacturer,
  isReseller,
  isEquipmentOwner,
  isInspector,
  isTrainingCentre,
  manufacturerName,
  verified,
  trialEnds,
  inventoryLimit,
  integrations,
}: OrganisationUpdateValues) {
  const router = useRouter();
  const { t } = useTranslation();
  const { organisationId: orgId } = router.query;
  const organisationId = Number(orgId);
  const [update] = useUpdateOrganisationMutation();

  const formik = useFormik({
    initialValues: {
      name: name || '',
      subscriptionType: subscriptionType || '',
      subscriptionStatus: subscriptionStatus || '',
      isManufacturer: isManufacturer || false,
      isReseller: isReseller || false,
      isEquipmentOwner: isEquipmentOwner || false,
      isInspector: isInspector || false,
      isTrainingCentre: isTrainingCentre || false,
      manufacturerName: manufacturerName || '',
      verified: verified || false,
      trialEnds: trialEnds ?? null,
      inventoryLimit: inventoryLimit || 0,
      integrations: integrations || [],
    },
    onSubmit: async (
      values: OrganisationUpdateValues,
      actions: FormikHelpers<OrganisationUpdateValues>
    ) => {
      await resolveMutation(
        update({
          variables: {
            data: {
              id: organisationId,
              name: values.name,
              subscriptionType: values.subscriptionType,
              subscriptionStatus: values.subscriptionStatus,
              isManufacturer: values.isManufacturer,
              isReseller: values.isReseller,
              isEquipmentOwner: values.isEquipmentOwner,
              isInspector: values.isInspector,
              isTrainingCentre: values.isTrainingCentre,
              manufacturerName: values.manufacturerName,
              verified: values.verified,
              trialEnds: values.trialEnds,
              inventoryLimit: values.inventoryLimit,
              integrations: values.integrations,
            },
          },
          refetchQueries: [Organisation.GetOrganisationDetailQuery],
        })
      );
      actions.setSubmitting(false);
    },
  });

  return (
    <Form formik={formik}>
      <FormAdminPanel
        renderFormFields={() => (
          <>
            <InputFieldGroup
              type="text"
              name="name"
              label={t('name')}
              formik={formik}
            />
            <InputFieldGroup
              type="select"
              name="subscriptionType"
              label={`${t('subscription')} ${t('type')}`}
              formik={formik}
              options={organisationTypeOptions}
            />
            <InputFieldGroup
              type="select"
              name="subscriptionStatus"
              label={`${t('subscription')} ${t('status')}`}
              formik={formik}
              options={organisationSubscriptionStatusOptions}
            />

            <InputFieldGroup
              type="number"
              name="inventoryLimit"
              label="Inventory Limit"
              formik={formik}
            />
            {subscriptionStatus === SubscriptionStatus.TRIAL && (
              <InputFieldGroup
                type="date"
                name="trialEnds"
                label="Trial Ends"
                formik={formik}
              />
            )}

            {manufacturerName && (
              <>
                <InputFieldGroup
                  type="text"
                  name="manufacturerName"
                  label="Manufacturer Name"
                  formik={formik}
                />

                <InputFieldGroup
                  type="checkbox"
                  name="verified"
                  label="Is this a verified manufacturer?"
                  formik={formik}
                />
                <InputFieldGroup
                  type="react-select"
                  name="integrations"
                  label="Integrations"
                  formik={formik}
                  options={[
                    { label: 'Label Live', value: Integration.LabelLive },
                  ]}
                  config={{
                    isMulti: true,
                  }}
                />
              </>
            )}
            <label className="block text-sm font-medium text-gray-700">
              Customer Type
            </label>
            <InputFieldGroup
              type="checkbox"
              name="isManufacturer"
              label="Manufacturer"
              formik={formik}
            />
            <InputFieldGroup
              type="checkbox"
              name="isReseller"
              label="Reseller"
              formik={formik}
            />
            <InputFieldGroup
              type="checkbox"
              name="isEquipmentOwner"
              label="Equipment Owner"
              formik={formik}
            />
            <InputFieldGroup
              type="checkbox"
              name="isInspector"
              label="Inspector"
              formik={formik}
            />
            <InputFieldGroup
              type="checkbox"
              name="isTrainingCentre"
              label="Training Centre"
              formik={formik}
            />
          </>
        )}
        renderFormAction={() => (
          <Button
            label={t('update')}
            type="submit"
            loading={formik.isSubmitting}
          />
        )}
      />
    </Form>
  );
}

export default EditOrganisationForm;
