import {
  CriteriaResult,
  AlertUnit as DBAlertUnit,
  GroupType,
  InspectionStatus,
} from '@prisma/client';

import { AppEvent } from '../types/app-event.types';

export interface FormResult {
  ok: boolean;
  message: string;
  messages: string[];
}

// export type ProductType = keyof typeof productType;

export type AlertUnit = {
  label: string;
  value: string;
  schedule: {
    unit: DBAlertUnit;
    quantity: number;
  };
};

export const alertUnits: AlertUnit[] = [
  {
    label: 'Weekly',
    value: 'weekly',
    schedule: {
      unit: 'weeks',
      quantity: 1,
    },
  },
  {
    label: 'Monthly',
    value: 'monthly',
    schedule: {
      unit: 'months',
      quantity: 1,
    },
  },
  {
    label: '3 Monthly',
    value: '3monthly',
    schedule: {
      unit: 'months',
      quantity: 3,
    },
  },
  {
    label: '6 Monthly',
    value: '6monthly',
    schedule: {
      unit: 'months',
      quantity: 6,
    },
  },
  {
    label: 'Yearly',
    value: 'yearly',
    schedule: {
      unit: 'years',
      quantity: 1,
    },
  },
];

export enum ManufacturerName {
  'Aspiring Safety' = 'Aspiring Safety',
  'At Height UK' = 'At Height UK',
  ART = 'ART',
  'Beal' = 'Beal',
  'Black Diamond' = 'Black Diamond',
  Camp = 'Camp',
  'CT Climbing' = 'CT Climbing',
  Courant = 'Courant',
  DMM = 'DMM',
  Eyolf = 'Eyolf',
  'Fixe Climbing' = 'Fixe Climbing',
  'Freeworker GmbH' = 'Freeworker GmbH',
  Grivel = 'Grivel',
  Harken = 'Harken',
  Heightec = 'Heightec',
  Husqvarna = 'Husqvarna',
  ISC = 'ISC',
  'Kong Italy' = 'Kong Italy',
  Marlow = 'Marlow',
  'Pfanner Protos' = 'Pfanner Protos',
  'PMI' = 'PMI',
  Petzl = 'Petzl',
  'Singing Rock' = 'Singing Rock',
  Lyon = 'Lyon',
  'Rock Empire' = 'Rock Empire',
  'Rock Exotica' = 'Rock Exotica',
  Skylotec = 'Skylotec',
  Taz = 'Taz',
  Teufelberger = 'Teufelberger',
  Edelrid = 'Edelrid',
  Timbersaws = 'Timbersaws',
  'Yates' = 'Yates',
  'Zero' = 'Zero',
}

export const companySizes = [
  '1-5',
  '5-25',
  '25-50',
  '50-100',
  '100-500',
  '500-1000',
  '1000+',
];

export const allowedImageTypes = 'PNG, JPG, GIF up to 10MB';
export const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;

export const allowedPdfTypes = 'PDF up to 10MB';
export const pdfMimeType = /application\/(pdf)/i;

export function isManufacturerName(
  value?: string | null
): value is ManufacturerName {
  return Object.values(ManufacturerName).includes(value as ManufacturerName);
}

export const manufacturersWithDOMNames = Object.values(ManufacturerName);

export function getGroupPath(type: GroupType, uid: string) {
  let path = '';
  switch (type) {
    case GroupType.KIT_BAG:
      path = 'kit-bag';
      break;
    case GroupType.LOCATION:
      path = 'location';
      break;
    case GroupType.CLIENT:
      path = 'client';
      break;
    case GroupType.EQUIPMENT_TYPE:
      path = 'equipment-type';
      break;
    case GroupType.STAFF:
      path = 'staff';
      break;
    case GroupType.VEHICLE:
      path = 'vehicle';
      break;
    default:
      break;
  }
  return `/${path}/${uid}`;
}

export function getChecklistResultStatus(
  criteriaResults: { result: CriteriaResult | undefined }[]
) {
  //if any criteria is failed, then the inspection is failed
  if (criteriaResults.some((c) => c.result === CriteriaResult.FAILED)) {
    return InspectionStatus.FAILED;
  }
  //if any criteria is repair the inspection result is quarantined
  if (criteriaResults.some((c) => c.result === CriteriaResult.REPAIR)) {
    return InspectionStatus.QUARANTINED;
  }

  //if any criteria is monitoring the inspection result is monitoring
  if (criteriaResults.some((c) => c.result === CriteriaResult.MONITORING)) {
    return InspectionStatus.MONITORING;
  }

  //if all criteria are passed, then the inspection is passed
  if (criteriaResults.every((c) => c.result === CriteriaResult.PASSED)) {
    return InspectionStatus.PASSED;
  }
  //if any criteria is not applicable ignore it and check the rest
  if (criteriaResults.some((c) => c.result === CriteriaResult.NOT_APPLICABLE)) {
    return getChecklistResultStatus(
      criteriaResults.filter((c) => c.result !== CriteriaResult.NOT_APPLICABLE)
    );
  }
}

export function determineIsFailResult(
  checklistResult: {
    checklistId: string;
    criteriaResults:
      | {
          checklistCriteriaId: string;
          result: CriteriaResult;
        }[]
      | undefined;
  } | null,
  status?: InspectionStatus
) {
  if (checklistResult && checklistResult.criteriaResults) {
    const result = getChecklistResultStatus(checklistResult.criteriaResults);
    return result === 'FAILED';
  }

  if (status === 'FAILED') {
    return true;
  }
  return false;
}

export function is1dOr2dScan(type: AppEvent): boolean {
  const scanTypes: AppEvent[] = [
    AppEvent.NFCRead,
    AppEvent.QRCodeScan,
    AppEvent.ScannableQRCodeScan,
    AppEvent.BarcodeScan,
    AppEvent.DataMatrixScan,
  ];

  return scanTypes.includes(type);
}
