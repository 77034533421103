import { Manufacturer } from '@scannable/common';

import { graphql } from '../graphql';

export const GET_MANUFACTURER = graphql(`
  query ${Manufacturer.GetManufacturer} ($id: Int!) {
    manufacturer(id: $id) {
      id
      name
      website
      verified
      logo
    }
  }
`);

export const GET_MANUFACTURER_OPTIONS = graphql(`
  query ${Manufacturer.GetManufacturerOptions} {
    manufacturers(where: { verified: { equals: true } }) {
      id
      name
    }
  }
`);

export const ALL_MANUFACTURERS = graphql(`
  query ${Manufacturer.AllManufacturers}(
    $where: ManufacturerWhereInput
    $orderBy: [ManufacturerOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedManufacturers(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        uuid
        hidden
        logo
        metric
        name
        website
        verified
      }
      totalCount
    }
  }
`);

export const PAGINATED_MANUFACTURERS_QUERY = graphql(`
  query ${Manufacturer.PaginatedManufacturersQuery} (
    $where: ManufacturerWhereInput
    $orderBy: [ManufacturerOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedManufacturers(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        products {
          id
        }
      }
      totalCount
    }
  }
`);

export const INVENTORY_MANUFACTURERS = graphql(`
  query ${Manufacturer.InventoryManufacturers} {
    getInventoryManufacturers {
      name
      id
    }
  }
`);

export const PAGINATED_SERIAL_FORMATS = graphql(`
  query ${Manufacturer.PaginatedSerialFormats}(
    $where: SerialFormatWhereInput
    $orderBy: [SerialFormatOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedSerialFormats(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        createdAt
        updatedAt
        format
      }
      totalCount
    }
  }
`);

export const MANUFACTURER_SETTINGS =
  graphql(`query ${Manufacturer.ManufacturerSettingsQuery}($id: Int!) {
  manufacturer(id: $id) {
    id
    name
    website
    logo
  }
}`);
