import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { HardwareType } from '@scannable/common';
import { h } from '@scannable/frontend/common';
import {
  GET_ALL_LABEL_TEMPLATES,
  GET_FIRST_SERIAL_PRINT_DATA,
  GET_HARDWARE_BY_TYPE,
} from '@scannable/graphql-queries';

import { useAuth } from '../../auth/AuthContext/AuthContext';
import { ModalForm } from '../../forms';
import { useTranslation } from '../../hooks';
import InputFieldGroup from '../../molecules/InputFieldGroup/InputFieldGroup';
import { errorAlert, successAlert } from '../../utils/alerts.utils';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

interface PrintWithLabelLiveValues {
  labelTemplate?: string;
  copies?: number;
  printer?: string;
}
type PrintWithLabelLiveModalProps = {
  serialPurchaseOrderId: string;
};
export function PrintWithLabelLiveModal({
  serialPurchaseOrderId,
}: PrintWithLabelLiveModalProps) {
  const { organisationId } = useAuth();
  const { t } = useTranslation();
  const { hideModal } = useModal();
  const { data } = useQuery(GET_FIRST_SERIAL_PRINT_DATA, {
    variables: {
      serialPurchaseOrderId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: printers } = useQuery(GET_HARDWARE_BY_TYPE, {
    variables: {
      data: {
        type: HardwareType.PRINTER,
      },
    },
  });

  const { data: labelTemplates } = useQuery(GET_ALL_LABEL_TEMPLATES, {
    variables: { organisationId: organisationId ?? 0 },
    skip: !organisationId,
  });

  const formatTemplateKey = (input: string) => {
    const alphanumericOnly = input.replace(/[^a-zA-Z0-9 ]/g, '');
    const withoutSpaces = alphanumericOnly.replace(/\s/g, '');
    const uppercased = withoutSpaces.toUpperCase();
    return uppercased;
  };
  const validationSchema = yup.object().shape({
    labelTemplate: yup.string().required('Label Template is required'),
    copies: yup.number().required('Copies is required'),
  });

  const formik = useFormik({
    initialValues: {
      labelTemplate: '',
      copies: 1,
      printer: 'Preview',
    },
    validationSchema,
    onSubmit: async (values: PrintWithLabelLiveValues) => {
      const templateVariables = labelTemplates?.labelTemplates?.find(
        (template) => template.id === values.labelTemplate
      )?.variables;
      const dataobj = data?.getFirstSerialPrintData?.data;
      const filteredData: { [key: string]: string } = {};

      // filter the data to only include the variables that are in the template
      for (const key in dataobj) {
        const formattedTemplateKey = formatTemplateKey(key);
        const templateVariable = templateVariables?.find(
          (variable) => formatTemplateKey(variable) === formattedTemplateKey
        );
        if (templateVariable) {
          filteredData[templateVariable] = dataobj[key];
        }
      }

      // for any variables that have a value of '', replace them with a space
      for (const key in filteredData) {
        if (filteredData[key] === '') {
          filteredData[key] = ' ';
        }
      }

      // add any variables that are in the template variables that are not in the data
      for (const variable of templateVariables ?? []) {
        if (!filteredData[variable]) {
          filteredData[variable] = ' ';
        }
      }

      const stringVariables = JSON.stringify(filteredData);
      const escapedVariables = encodeURIComponent(stringVariables);
      try {
        const response = await fetch(
          `http://localhost:11180/api/v1/print?design=${values.labelTemplate}&variables=${escapedVariables}&printer=${values.printer}&copies=${values.copies}&ignoreWarnings=true`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
          }
        );

        if (response?.body) {
          const reader = response.body.getReader();
          const { value } = await reader.read();
          const decoded = new TextDecoder('utf-8').decode(value);
          const parsedResponse = JSON.parse(decoded);
          const error = parsedResponse?.error;
          if (error) {
            errorAlert(`Label Live - ${error}`);
          }
          const success = parsedResponse?.success;
          if (success) {
            successAlert(`Label Live - ${success}`);
          }
        } else {
          successAlert('Printed successfully');
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Sentry.captureException(error);
        if (error?.message === 'Failed to fetch') {
          errorAlert(t('printing.fail_to_connect_to_printer'));
        }
        errorAlert(JSON.stringify(error.message));
      }
      hideModal();
    },
  });

  return (
    <Modal title={h(t('printing.print_with_label_live'))} size="lg">
      <ModalForm
        className="h-96 overflow-y-auto"
        formik={formik}
        buttons={[
          {
            label: t('printing.print_label'),
            type: 'submit',
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup
          name="labelTemplate"
          type="react-select"
          label="Label Template"
          placeholder="Select Label Template"
          options={labelTemplates?.labelTemplates?.map((template) => ({
            label: template.id,
            value: template.id,
          }))}
          formik={formik}
        />
        <InputFieldGroup
          name="copies"
          type="number"
          label="Copies"
          placeholder="Copies"
          formik={formik}
        />
        <InputFieldGroup
          name="printer"
          type="react-select"
          label="Printer"
          placeholder={t('serialisation.select_printer')}
          options={[
            ...(printers?.hardwareByType ?? []).map((printer) => ({
              label: printer.name,
              value: printer.hardwareId,
            })),
            { label: 'Preview', value: 'Preview' },
            { label: 'Default', value: 'Default' },
          ]}
          formik={formik}
        />
      </ModalForm>
    </Modal>
  );
}
