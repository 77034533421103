import { ProductPageTab } from '../../atoms';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';

export interface ProductPageTabsProps {
  hasComponents: boolean;
  showHistoryTab: boolean;
  changeTab: (tabNumber: number) => void;
  tabNumber: number;
  numberOfComponents?: number;
  showGroupsTab?: boolean;
}

export function ProductPageTabs({
  hasComponents,
  showHistoryTab,
  changeTab,
  tabNumber,
  showGroupsTab = false,
  numberOfComponents = 0,
}: ProductPageTabsProps) {
  const { t } = useTranslation();
  return (
    <div className="px-2 flex flex-row text-xs columns-4 justify-evenly border-b text-gray-400 flex-shrink">
      {/* Item Data */}
      <ProductPageTab
        name="Info"
        onClick={() => changeTab(0)}
        isActive={tabNumber === 0}
        component="info"
      />

      {/* Specifications */}
      <ProductPageTab
        name={t('specs')}
        onClick={() => changeTab(1)}
        isActive={tabNumber === 1}
        component="specs"
      />

      {/* Components */}
      {hasComponents && (
        <ProductPageTab
          name={t('parts')}
          onClick={() => changeTab(2)}
          isActive={tabNumber === 2}
          component="components"
        />
      )}

      {/* Groups */}
      {showGroupsTab && (
        <ProductPageTab
          name={t('groups')}
          onClick={() => changeTab(3)}
          isActive={tabNumber === 3}
          component="group"
        />
      )}

      {/* History */}
      {showHistoryTab && (
        <ProductPageTab
          name="History"
          onClick={() => changeTab(4)}
          isActive={tabNumber === 4}
          component="history"
        />
      )}
    </div>
  );
}

export default ProductPageTabs;
