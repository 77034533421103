export const units = [
  {
    label: 'Years',
    value: 'years',
  },
  {
    label: 'Months',
    value: 'months',
  },
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Meters (m)',
    value: 'm',
  },
  {
    label: 'Centimeters (cm)',
    value: 'cm',
  },
  {
    label: 'Millimeters (mm)',
    value: 'mm',
  },
  {
    label: 'Inches (in)',
    value: 'in',
  },
  {
    label: 'Feet (ft)',
    value: 'ft',
  },
  {
    label: 'Pounds of force (lbf)',
    value: 'lbf',
  },
  {
    label: 'Pounds per 100ft (lbs/100ft)',
    value: 'lbs/100ft',
  },
  {
    label: 'Kilograms (kg)',
    value: 'kg',
  },
  {
    label: 'Grams (g)',
    value: 'g',
  },
  {
    label: 'Grams per meter (g/m)',
    value: 'g/m',
  },
  {
    label: 'Pounds (lb)',
    value: 'lb',
  },
  {
    label: 'Ounces (oz)',
    value: 'oz',
  },
  {
    label: 'Tonnes (t)',
    value: 't',
  },
  {
    label: 'Kilonewtons (kN)',
    value: 'kN',
  },
  {
    label: 'Percentage (%)',
    value: '%',
  },
  {
    label: 'Kg per 100m (kg/100m)',
    value: 'kg/100m',
  },
  {
    label: 'Voltage (V)',
    value: 'V',
  },
  {
    label: 'Horsepower (hp)',
    value: 'hp',
  },
  {
    label: 'Brake Horsepower (bhp)',
    value: 'bhp',
  },
  {
    label: 'Cubic meters per hour (m3/h)',
    value: 'm3/h',
  },
  {
    label: 'Newton (N)',
    value: 'N',
  },
  {
    label: 'Cubic centimeters (cm3)',
    value: 'cm3',
  },

  {
    label: 'Cubic centimeters (cc)',
    value: 'cc',
  },
  {
    label: 'Kilowatt (kW)',
    value: 'kW',
  },
  {
    label: 'Liters (L)',
    value: 'L',
  },
  {
    label: 'Celcius (°C)',
    value: '°C',
  },
  {
    label: 'Fahrenheit (°F)',
    value: '°F',
  },
];
