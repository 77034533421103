import { SerialisedProduct } from '@scannable/common';

import { graphql } from '../graphql';

export const CREATE_SERIALISED_PRODUCTS = graphql(`
  mutation ${SerialisedProduct.CreateSerialisedProducts}($data: CreateSerialisedProductsInput!) {
    createSerialisedProducts(data: $data) {
      id
      status
      count
    }
  }
`);

export const CREATE_SERIALISED_ROPES = graphql(`
  mutation ${SerialisedProduct.CreateSerialisedSplicedRopes}(
    $data: CreateSerialisedSplicedRopesInput!
  ) {
    createSerialisedProductFromProcess(data: $data) {
      id
    }
  }
`);

export const CREATE_SERIALISED_PRODUCT = graphql(`
  mutation ${SerialisedProduct.CreateSerialisedProduct}($data: CreateSerialisedProductInput!) {
    createSerialisedProduct(data: $data) {
      id
    }
  }
`);

export const TEST_SERIALISED_CONFIG = graphql(`
  mutation ${SerialisedProduct.TestSerialisationConfiguration} ($data: TestSerialisedProductsInput!) {
    testSerialisationConfiguration(data: $data) {
      id
      status
      count
    }
  }
`);

export const UPDATE_SERIALISED_PRODUCT = graphql(`
  mutation ${SerialisedProduct.UpdateSerialisedProduct}($data: UpdateSerialisedProductInput!) {
    updateSerialisedProduct(data: $data) {
      id
    }
  }
`);

export const CREATE_SERIALISED_PRODUCTS_IMAGES = graphql(`
  mutation ${SerialisedProduct.CreateSerialisedProductImages}($data: CreateSerialisedProductImagesInput!) {
    createSerialisedProductImages(data: $data) {
      id
    }
  }`);

export const GENERATE_SERIAL_PURCHASE_ORDER_CSV = graphql(`
  mutation ${SerialisedProduct.GenerateSerialPurchaseOrderCsv}($data: SerialPurchaseOrderGenerateInput!) {
    generateSerialPurchaseOrderCsv(data: $data) {
      id
    }
  }`);

export const CAPTURE_SERIALS = graphql(
  `mutation ${SerialisedProduct.CaptureSerials}($data: CaptureSerialsProcessInput!) {
    captureSerials(data: $data)
  }`
);
