import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';

import { redirectHandler } from '@scannable/common';
import { DELETE_ME } from '@scannable/graphql-queries';

import { Button, Text } from '../../atoms';
import { resolveMutation } from '../../lib/lib';
import { ConfirmationModal, useModal } from '../../modals';
import { FormAdminPanel } from '../../molecules';
import { removeUserId, removeUserToken } from '../../utils';

export function DeleteMeForm() {
  const router = useRouter();
  const [removeUser] = useMutation(DELETE_ME);
  const { showModal } = useModal();

  return (
    <FormAdminPanel
      renderFormFields={() => (
        <div>
          <Text>
            Deleting your account will remove all data associated with your
            account. The following will be removed:
          </Text>
          <ul className="text-sm text-gray-500 ml-5 list-disc my-3">
            <li>
              You will be removed from any organisations you are a member of
            </li>
            <li>All inventory assigned to you</li>
            <li>
              All of your data assocated with events such as inspections or
              scans
            </li>
          </ul>
        </div>
      )}
      renderFormAction={() => (
        <Button
          className="mr-4"
          color="danger"
          onClick={() =>
            showModal(
              <ConfirmationModal
                title="Are you sure you want to delete your account?"
                message="To confirm this action please type CONFIRM."
                shouldConfirm
                onConfirm={async () => {
                  await resolveMutation(removeUser(), {
                    successMessage: 'Account deleted',
                    failureMessage: 'Failed to delete account',
                  });
                  removeUserId();
                  removeUserToken();
                  router.push(redirectHandler());
                }}
                buttonLabel="Delete Account"
              />
            )
          }
          type="button"
        >
          Delete Account
        </Button>
      )}
    />
  );
}

export default DeleteMeForm;
