import * as Sentry from '@sentry/nextjs';

import { FormResult } from '@scannable/common';
import { MutationResult } from '@scannable/frontend/types';

import { errorAlert, successAlert } from '../utils';

type ModelActions =
  | 'CREATE'
  | 'READ'
  | 'UPDATE'
  | 'DELETE'
  | 'ACCEPT'
  | 'REMOVE'
  | 'CANCEL';

type FormActionMessage = {
  action?: ModelActions;
  model?: string;
  successMessage?: string;
  failureMessage?: string;
};

export function copyText(text: string) {
  navigator.clipboard.writeText(text);
  successAlert('Copied');
}

export function handleToast(result: FormResult) {
  const message = result.messages.shift() || result.message;
  if (result.ok) {
    successAlert(message);
  } else {
    errorAlert(message);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function resolveFormSubmit(promise: Promise<any>) {
  try {
    const result = await promise;
    return [result, null];
  } catch (error) {
    Sentry.captureException(error);
    return [null, error];
  }
}

function getMessage(
  type: 'successMessage' | 'failureMessage',
  message: FormActionMessage,
  fallbackMessage: string
): string {
  if (type in message) {
    return message[type] as string;
  }
  if (!message.action) {
    return fallbackMessage;
  }
  let suffix: string;
  switch (message.action) {
    case 'ACCEPT':
      suffix = 'ed';
      break;
    case 'CANCEL':
      suffix = 'led';
      break;
    default:
      suffix = 'd';
  }
  if (type === 'successMessage') {
    return `Successfully ${message.action.toLowerCase()}${suffix}${
      message.model ? ` ${message.model}` : ''
    }`;
  }
  if (fallbackMessage) {
    return fallbackMessage;
  }
  return `There was an error trying to ${message.action.toLowerCase()}${
    message.model ? ` ${message.model}` : ''
  }`;
}

export async function resolveMutation(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promise: Promise<any>,
  message?: FormActionMessage,
  alertMessage = true
): Promise<MutationResult> {
  const [result, error] = await resolveFormSubmit(promise);

  let formResult = {} as MutationResult;
  const successMessage = getMessage(
    'successMessage',
    message ?? {},
    'Successfully saved'
  );
  const errorMessage = getMessage(
    'failureMessage',
    message ?? {},
    error?.message ?? 'There was an error'
  );
  if (result) {
    formResult = {
      ok: true,
      message: successMessage,
      messages: successMessage ? [successMessage] : [],
      data: result.data,
      error,
    };
  } else {
    formResult = {
      ok: false,
      message: errorMessage,
      messages: errorMessage ? [errorMessage] : [],
      data: null,
      error,
    };
  }
  if (alertMessage) {
    handleToast(formResult);
  }
  return formResult;
}
