import { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Accept, useDropzone } from 'react-dropzone';

import { cn, PDF_PLACEHOLDER } from '@scannable/common';

import { Image, ResizedImage } from '../../atoms';
import useTranslation from '../../hooks/useTranslation/useTranslation';

export interface FileUploadProps {
  id?: string;
  name: string;
  loading?: boolean;
  label?: string;
  multiple?: boolean;
  accept: Accept;
  previewUrl?: string;
  currentImages?: {
    image: {
      id?: number;
      uri?: string | null;
    };
    removeFile: () => void;
  }[];
  onFileChange?: (files: FileAndPreview | FileAndPreview[]) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  maxFiles?: number;
  maxFilesReachedMessage?: string;
}
type FileAndPreview = File & { preview: string };

export function FileUpload({
  id,
  name,
  multiple = false,
  accept = {
    'image/*': [],
  },
  loading,
  previewUrl,
  onFileChange,
  currentImages,
  onBlur,
  maxFiles,
  maxFilesReachedMessage,
}: FileUploadProps) {
  const [files, setFiles] = useState<FileAndPreview[]>([]);
  const [mode = 'preview', setMode] = useState<'upload' | 'preview'>();
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const { t } = useTranslation();
  const hasReachedLimit =
    typeof maxFiles === 'number' ? files.length >= maxFiles : false;
  const addBtnDisabledReason = hasReachedLimit
    ? maxFilesReachedMessage ?? t('uploads.max_uploads_reached')
    : undefined;

  useEffect(() => {
    if (loading) {
      setMode('preview');
      setFiles([]);
    }
  }, [loading]);

  useEffect(() => {
    if (previewUrl) {
      setMode('preview');
      return;
    }
    setMode('upload');
  }, [previewUrl]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    accept,
    disabled: Boolean(addBtnDisabledReason),
    onDrop: (acceptedFiles) => {
      if (multiple) {
        setFiles((prevFiles) =>
          prevFiles.concat(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          )
        );
        return;
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDragEnter: () => {
      setIsDraggingOver(true);
    },
    onDragLeave: () => {
      setIsDraggingOver(false);
    },
  });
  useEffect(() => {
    if (files && files.length > 0) {
      onFileChange && onFileChange(multiple ? files : files[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRemove = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const showAddButton =
    multiple ||
    (!multiple && files.length === 0 && currentImages?.length !== 1);

  return (
    <div className="col-span-full">
      <div className="mt-2 flex items-center gap-x-3">
        {mode === 'preview' && previewUrl && (
          <ResizedImage
            className="object-cover rounded-md"
            height={85}
            width={85}
            src={previewUrl}
            alt="preview"
          />
        )}
      </div>
      {(mode === 'upload' || !previewUrl) && (
        <div className="grid grid-cols-4 gap-x-4 max-w-80">
          {currentImages &&
            currentImages.map((image, index) => (
              <div
                key={index}
                className="w-[85px] h-[85px] overflow-hidden flex items-center justify-center relative p-2"
              >
                <div className="absolute top-0 right-0 bg-red-600 h-4 w-4 rounded-full flex justify-center items-center cursor-pointer">
                  <XMarkIcon
                    className="h-3 w-3 text-white"
                    onClick={() => {
                      image.removeFile();
                    }}
                  />
                </div>
                <ResizedImage
                  src={image.image.uri || ''}
                  className="object-cover object-center rounded-md"
                  alt="Image preview"
                  height={85}
                  width={85}
                  fit="fill"
                />
              </div>
            ))}
          {files.map((file, index) => (
            <div
              key={file.name}
              className="w-[85px] h-[85px] overflow-hidden flex items-center justify-center relative p-2"
            >
              <div className="absolute top-0 right-0 bg-red-600 h-4 w-4 rounded-full flex justify-center items-center">
                <XMarkIcon
                  className="h-3 w-3 text-white cursor-pointer"
                  onClick={() => onRemove(index)}
                />
              </div>
              <Image
                width={85}
                height={85}
                className="object-cover object-center rounded-md"
                src={
                  file.type !== 'application/pdf'
                    ? file.preview
                    : PDF_PLACEHOLDER
                }
                alt="preview"
              />
            </div>
          ))}
          {showAddButton && (
            <label htmlFor={`${name}_uploadref`}>
              <div {...getRootProps()}>
                <input id={id} {...getInputProps()} onBlur={onBlur} />
                <div className="w-[70px] h-[70px] overflow-hidden flex items-center justify-center relative mt-2 ml-2">
                  <div
                    className={cn(
                      'w-full h-full flex items-center justify-center rounded-md bg-gray-100 ',
                      isDraggingOver ? 'border-gray-200 border' : '',
                      addBtnDisabledReason
                        ? 'cursor-not-allowed opacity-60'
                        : 'cursor-pointer hover:border-gray-200 hover:border'
                    )}
                  >
                    <PlusIcon
                      className="h-8 w-8 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </label>
          )}
        </div>
      )}
      {addBtnDisabledReason && (
        <p className="text-xs mt-1 leading-5 text-gray-500">
          {addBtnDisabledReason}
        </p>
      )}
    </div>
  );
}

export default FileUpload;
