import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

export function usePagination(tableKey = 'default', pageSize = 25) {
  const [currentPage, setCurrentPage] = useState<{
    [key: string]: number;
  }>({
    [tableKey]: 1,
  });

  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);

  const currentPageNumber = currentPage[tableKey];

  useEffect(() => {
    const newOffset = (currentPageNumber - 1) * pageSize;
    setOffset(newOffset);
  }, [currentPageNumber, pageSize]);

  const router = useRouter();
  const changePage = useCallback(
    (newPage: number) => {
      setCurrentPage({ [tableKey]: newPage });
    },
    [tableKey]
  );

  useEffect(() => {
    if (router.isReady) {
      const pageFromUrl = router.query.page;
      if (Array.isArray(pageFromUrl)) {
        return;
      }
      if (!pageFromUrl) {
        return;
      }
      const parsedPage = parseInt(pageFromUrl, 10);
      if (
        parsedPage &&
        (!router.query.table || router.query.table === tableKey)
      ) {
        changePage(parsedPage);
      }
    }
  }, [
    changePage,
    router.isReady,
    router.query.page,
    router.query.table,
    tableKey,
  ]);

  const updateUrlParams = useCallback(
    (page: number) => {
      const currentQuery = router.query;
      const newUrlParams: { page: number; table?: string } = { page };
      if (tableKey !== 'default') {
        newUrlParams['table'] = tableKey;
      }
      const updatedQuery = { ...currentQuery, ...newUrlParams };
      router.push(
        { pathname: router.pathname, query: updatedQuery },
        undefined,
        { shallow: true }
      );
    },
    [router, tableKey]
  );

  const next = useCallback(() => {
    setCurrentPage((prevPage) => {
      const current = prevPage[tableKey];
      const newPage = current + 1;
      updateUrlParams(newPage);
      return { [tableKey]: newPage };
    });
  }, [tableKey, updateUrlParams]);

  const prev = useCallback(() => {
    setCurrentPage((prevPage) => {
      const current = prevPage[tableKey];
      const newPage = Math.max(1, current - 1);
      updateUrlParams(newPage);
      return { [tableKey]: newPage };
    });
  }, [tableKey, updateUrlParams]);

  const goToPage = useCallback(
    (page: number) => {
      changePage(page);
      updateUrlParams(page);
    },
    [changePage, updateUrlParams]
  );

  const maxPage = useMemo(() => {
    return Math.ceil(totalCount / pageSize);
  }, [totalCount, pageSize]);

  useEffect(() => {
    if (currentPageNumber > maxPage && maxPage > 0) {
      changePage(maxPage);
    }
  }, [currentPageNumber, maxPage, changePage]);

  return {
    currentPage: currentPage[tableKey],
    next,
    prev,
    goToPage,
    offset,
    totalCount,
    setTotalCount,
    maxPage,
  };
}
