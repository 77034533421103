import { useCallback } from 'react';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

export const COMMAND_PALETTE_FEATURE_KEY = 'commandPalette';

export type CommandPaletteTab = 'search' | 'nfc' | 'camera-scan';

export interface CommandPaletteState {
  isOpen: false;
  activeTab: CommandPaletteTab;
  activateNFCOnMount: boolean;
}

export const initialCommandPaletteState = {
  isOpen: false,
  activeTab: 'nfc' as CommandPaletteTab,
  activateNFCOnMount: false,
};

export const commandPaletteSlice = createSlice({
  name: COMMAND_PALETTE_FEATURE_KEY,
  initialState: initialCommandPaletteState,
  reducers: {
    openSearch: (state) => {
      state.activeTab = 'search';
      state.isOpen = true;
    },
    openCamera: (state) => {
      state.activeTab = 'camera-scan';
      state.isOpen = true;
    },
    openFromLongpress: (state) => {
      state.activateNFCOnMount = true;
      state.isOpen = true;
    },
    toggleOpen: (
      state,
      action: PayloadAction<{ shouldActivateNFCOnMount: boolean }>
    ) => {
      state.isOpen = !state.isOpen;
      if (state.isOpen) {
        state.activateNFCOnMount = action.payload.shouldActivateNFCOnMount;
        state.activeTab = 'nfc';
      } else {
        state.activateNFCOnMount = false;
      }
    },
    dismiss: (state) => {
      state.isOpen = false;
      state.activeTab = 'nfc';
    },
    setTab: (state, action: PayloadAction<CommandPaletteTab>) => {
      state.activeTab = action.payload;
    },
  },
});

export const commandPaletteReducer = commandPaletteSlice.reducer;

export const commandPaletteActions = commandPaletteSlice.actions;

export const getCommandPaletteState = (rootState: {
  [COMMAND_PALETTE_FEATURE_KEY]: CommandPaletteState;
}): CommandPaletteState => rootState[COMMAND_PALETTE_FEATURE_KEY];

export const isCommandPaletteOpen = createSelector(
  getCommandPaletteState,
  (state: CommandPaletteState) => state.isOpen
);

export const useOpenCommandPaletteSearch = () => {
  const dispatch = useDispatch();
  const handleOpen = useCallback(() => {
    dispatch(commandPaletteActions.openSearch());
  }, [dispatch]);
  return handleOpen;
};

export function useCloseCommandPalette() {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(commandPaletteActions.dismiss());
  }, [dispatch]);

  return handleClose;
}
