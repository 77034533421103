import { AllHTMLAttributes } from 'react';

export function TableHeadRow({
  children,
  className,
  ...rest
}: AllHTMLAttributes<HTMLTableRowElement>) {
  return <tr {...rest}>{children}</tr>;
}

export default TableHeadRow;
