import { useRouter } from 'next/router';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { backendUrl } from '@scannable/frontend/common';

import { Button } from '../../atoms';
import { copyText } from '../../lib/lib';
import { FormAdminPanel } from '../../molecules/FormAdminPanel/FormAdminPanel';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import { Form } from '../Form/Form';

type CreateSubscriptionLinkValues = {
  currency: string;
  interval: string;
  email: string;
  quantity: number;
};

export function CreateSubscriptionLinkForm() {
  const { organisationId } = useRouter().query;
  const formik = useFormik({
    initialValues: {
      currency: '',
      interval: '',
      email: '',
      quantity: 0,
    },
    validationSchema: Yup.object().shape({
      currency: Yup.string().required('Please enter a currency'),
      interval: Yup.string().required('Please enter an interval'),
      email: Yup.string().email().required('A valid email address is required'),
      quantity: Yup.number().required('Please enter a quantity'),
    }),
    onSubmit: async (
      values: CreateSubscriptionLinkValues,
      actions: FormikHelpers<CreateSubscriptionLinkValues>
    ) => {
      const url = backendUrl(
        `subscriptions/checkout?organisationId=${organisationId}&email=${encodeURIComponent(
          values.email
        )}&quantity=${values.quantity}&interval=${values.interval}&currency=${
          values.currency
        }`
      );
      copyText(url);
      actions.setSubmitting(false);
    },
  });

  return (
    <Form formik={formik}>
      <FormAdminPanel
        renderFormFields={() => (
          <>
            <InputFieldGroup
              type="number"
              name="quantity"
              label="Quantity of Items"
              formik={formik}
            />
            <InputFieldGroup
              type="text"
              name="email"
              label="Billing Email"
              formik={formik}
            />
            <InputFieldGroup
              type="select"
              name="currency"
              label="Subscription Currency"
              formik={formik}
              options={[
                { label: 'USD', value: 'usd' },
                {
                  label: 'EUR',
                  value: 'eur',
                },
                {
                  label: 'GBP',
                  value: 'gbp',
                },
                {
                  label: 'NZD',
                  value: 'nzd',
                },
              ]}
            />
            <InputFieldGroup
              type="select"
              name="interval"
              label="Subscription Interval"
              formik={formik}
              options={[
                {
                  label: 'Monthly',
                  value: 'month',
                },
                {
                  label: 'Yearly',
                  value: 'year',
                },
              ]}
            />
          </>
        )}
        renderFormAction={() => (
          <Button
            label="Create Subscription Link"
            type="submit"
            loading={formik.isSubmitting}
          />
        )}
      />
    </Form>
  );
}

export default CreateSubscriptionLinkForm;
