import { Fragment } from 'react';
import { Float } from '@headlessui-float/react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';

import { cn } from '@scannable/common';

import { TableRowAction } from '../../types/table.types';

export interface ActionsButtonProps<T> {
  actions: (TableRowAction<T> & { item: T })[];
  className?: string;
}

export function ActionsButton<T>({
  actions,
  className,
}: ActionsButtonProps<T>) {
  const filteredActions = actions.filter((action) => action.display !== false);
  if (filteredActions.length === 0) {
    return null;
  }
  return (
    <Menu as="div" className="bg-white">
      <Float placement="left-start" portal flip offset={4}>
        <div className="flex justify-end">
          <Menu.Button
            data-cy="action-button"
            className={cn(
              className ? className : '',
              'flex items-center border border-gray-300 rounded text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            )}
          >
            <EllipsisHorizontalIcon className="h-5 w-5 text-gray-500" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredActions.map((action) => (
              <Menu.Item key={action.label}>
                <span
                  data-cy="action-button-item"
                  className={cn(
                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                    action.type === 'danger' ? 'text-red-600' : ''
                  )}
                  onClick={() => action.callback(action.item || ({} as T))}
                >
                  {action.label}
                </span>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Float>
    </Menu>
  );
}

export default ActionsButton;
