import { useQuery } from '@apollo/client';

import {
  IS_ITEM_IN_INVENTORY,
  UseQueryOptions,
} from '@scannable/graphql-queries';

export function useIsItemInInventoryQuery(
  options?: UseQueryOptions<typeof IS_ITEM_IN_INVENTORY> // QueryHookOptions<{ isItemInInventory: boolean }>
) {
  return useQuery(IS_ITEM_IN_INVENTORY, options);
}
