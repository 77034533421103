import { InspectionStatus } from '@prisma/client';

import { UseItemScreenData } from '../../hooks/serialised-product/queries';
import { UseSkuOrItemData } from '../../hooks/useSkuOrItemScreenQuery/useSkuOrItemScreenQuery';

export interface ViewProductInfoItem {
  name: string;
  value: string;
}

export interface ViewProductLastInspection {
  status: InspectionStatus;
  date: string;
}

export function isItem(
  data: UseSkuOrItemData | null | undefined
): data is UseItemScreenData {
  return Boolean(data && (data as UseItemScreenData).uid !== undefined);
}
