import { InspectionStatus } from '@prisma/client';

import { TranslateFn } from '@scannable/frontend/translations';

import { UseSkuOrItemData } from '../../hooks/useSkuOrItemScreenQuery/useSkuOrItemScreenQuery';
import { makeItemSpecificationTabData } from './makeItemSpecificationTabData';
import { makeProductInfoItems } from './makeProductInfoItems';
import { isItem, ViewProductLastInspection } from './types';

export type ParsedStatus = {
  title: string;
  status: 'success' | 'failure' | 'quarantine' | 'monitoring';
};

function inspectionStatus(status?: InspectionStatus): ParsedStatus | null {
  if (status === InspectionStatus.PASSED) {
    return {
      title: 'Passed Inspection',
      status: 'success',
    };
  }
  if (status === InspectionStatus.FAILED) {
    return {
      title: 'Failed Inspection',
      status: 'failure',
    };
  }
  if (status === InspectionStatus.QUARANTINED) {
    return {
      title: 'Quarantined',
      status: 'quarantine',
    };
  }
  if (status === InspectionStatus.MONITORING) {
    return {
      title: 'Monitoring',
      status: 'monitoring',
    };
  }
  return null;
}

export const makeItemScreenData = ({
  item,
  organisationId,
  t,
}: {
  item?: UseSkuOrItemData;
  organisationId: number | null;
  t: TranslateFn;
}) => {
  if (!item) {
    return {};
  }

  const name = item?.info?.name;

  if (!isItem(item)) {
    const infoItems = makeProductInfoItems({
      product: item,
      t,
      name,
      showPrivateData: false,
    });

    const specificationItems = makeItemSpecificationTabData({
      item,
      t,
    });

    return { infoItems, specificationItems };
  }

  const hasComponents = Boolean(item.components?.length);

  const aliases = item?.info?.nfcTags ?? [];

  const showPrivateData = Boolean(
    organisationId && item.info?.ownedById === organisationId
  );

  const lastInspection = item?.info
    ?.lastInspection as ViewProductLastInspection;

  const nextInspectionAt = item?.info?.nextInspectionAt;

  const specificationItems = makeItemSpecificationTabData({
    item,
    t,
  });

  const expiryDate = item?.info?.expiryDate;
  const dom = item?.dom;
  const dofu = item?.dofu;
  const isExpired = Boolean(
    expiryDate ? new Date(expiryDate) < new Date() : false
  );
  const status = item.status;
  const proofOfPurchase = item?.info?.proofOfPurchase;

  const parsedStatus = inspectionStatus(item?.info?.lastInspection?.status);

  const assignedUser = item?.info?.assignedUser;
  const ownedByName = item?.info?.ownedByName;
  const serialNumber = item?.serialNumber;
  const batchNumber = item?.info?.batchNumber;
  const nfcTag = item?.info?.nfcTag;
  const nfcTags = item?.info?.nfcTags;
  const externalId = item?.info?.externalId;

  const infoItems = makeProductInfoItems({
    product: item,
    t,
    name,
    lastInspection,
    showPrivateData,
    nextInspectionAt,
    expiryDate,
    dom,
    dofu,
    proofOfPurchase,
  });

  const itemInInventory = item?.info?.ownedById === organisationId;
  return {
    name,
    serialNumber,
    batchNumber,
    hasComponents,
    hasNfc: Boolean(aliases.length),
    nfcTag,
    nfcTags,
    aliases,
    showPrivateData,
    lastInspection,
    specificationItems,
    infoItems,
    assignedUser,
    ownedByName,
    expiryDate,
    dom,
    dofu,
    isExpired,
    nextInspectionAt,
    parsedStatus,
    proofOfPurchase,
    itemInInventory,
    status,
    externalId,
  };
};
