import { useDispatch, useSelector } from 'react-redux';

import {
  selectMaintenanceMode,
  turnOffMaintenanceMode as turnOffMaintenanceModeAction,
  turnOnMaintenanceMode as turnOnMaintenanceModeAction,
} from '@scannable/frontend/store';

export function useApplicationStatus() {
  const dispatch = useDispatch();

  const turnOnMaintenanceMode = () => dispatch(turnOnMaintenanceModeAction());
  const turnOffMaintenanceMode = () => dispatch(turnOffMaintenanceModeAction());
  const isInMaintenanceMode = useSelector(selectMaintenanceMode);

  return {
    turnOffMaintenanceMode,
    turnOnMaintenanceMode,
    isInMaintenanceMode,
  };
}
