import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { GroupType } from '@prisma/client';

import {
  AssignToGroupAction,
  AssignToTeamMemberAction,
  Inventory,
  Role,
  Tasks,
} from '@scannable/common';
import { ActionType, heading } from '@scannable/frontend/common';
import { GroupTypePathMapping } from '@scannable/frontend/translations';
import { REMOVE_INVENTORY_ITEMS } from '@scannable/graphql-queries';

import { useIsAccess, useRoleGuard } from '../../auth';
import { useTable, useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import {
  AssignEquipmentToUserModal,
  ConfirmationModal,
  EditItemInspectionFrequencyModal,
  RemoveFromGroupModal,
  SimpleModal,
  useModal,
} from '../../modals';
import { AssignToGroupModal } from '../../modals/AssignToGroupModal/AssignToGroupModal';
import { ReactSelectInput } from '../../molecules';
import {
  AssignSkuSidePanel,
  CreateInspectionSidePanel,
  useSidePanel,
} from '../../side-panels';
import { TableRowType } from '../../types/table.types';

function useIsOnGroupPage() {
  const router = useRouter();
  const { asPath, query } = router;
  const { groupId: queryGroupId } = query;

  const groupPaths = [
    'kit-bags',
    'locations',
    'vehicles',
    'clients',
    'staff',
    'equipment-type',
  ];
  const groupTypePath = groupPaths.find((path) => asPath.includes(path));
  const groupType = Object.keys(GroupTypePathMapping).find(
    (key) =>
      GroupTypePathMapping[key as keyof typeof GroupTypePathMapping] ===
      groupTypePath
  ) as GroupType;

  const groupId = queryGroupId?.toString() ?? null;

  return {
    groupType,
    groupId,
  };
}

export interface BulkActionSelectorProps {
  spIds: number[];
  isMultiScan: boolean;
}

export function BulkActionSelector(props: BulkActionSelectorProps) {
  const { showModal, hideModal } = useModal();
  const isAccess = useIsAccess();
  const roleGuard = useRoleGuard();
  const { showSidePanel } = useSidePanel();
  const { t } = useTranslation();
  const { spIds } = props;
  const [removeItemsFromInventory] = useMutation(REMOVE_INVENTORY_ITEMS);
  const { clearSelectedRows } = useTable();
  const { groupId, groupType } = useIsOnGroupPage();

  return (
    <ReactSelectInput
      name="task"
      data-cy="bulk-action-select"
      options={[
        {
          label: t('inspect'),
          value: Tasks.inspect,
          show: true,
        },
        {
          label: t('actions.assign_to_sku'),
          value: Tasks.assignToSku,
          show: !isAccess && roleGuard([Role.SuperAdmin]),
        },
        {
          label: t('actions.assign_to_a_team_member'),
          value: Tasks.assignToTeamMember,
          show: !isAccess && roleGuard([Role.Admin, Role.Member]),
        },
        {
          label: t('actions.assign_to_group'),
          value: Tasks.assignToGroup,
          show: !isAccess && roleGuard([Role.Admin, Role.Member]),
        },
        {
          label: t('actions.set_inspection_frequency'),
          value: Tasks.addAlert,
          show: !isAccess && roleGuard([Role.Admin, Role.Member]),
        },
        {
          label: t('actions.bulk_remove_from_inventory'),
          value: Tasks.bulkRemoveFromInventory,
          show: !isAccess && roleGuard([Role.Admin, Role.Member]),
          type: TableRowType.danger,
        },
        {
          label: t('actions.bulk_remove_from_group'),
          value: Tasks.bulkRemoveFromGroup,
          show: !isAccess && roleGuard([Role.Admin, Role.Member]),
          type: TableRowType.danger,
        },
      ].filter((o) => o.show)}
      isDisabled={spIds.length === 0}
      onChange={(v) => {
        const task = v?.value;
        switch (task) {
          case Tasks.inspect:
            showSidePanel(
              <CreateInspectionSidePanel
                ids={spIds}
                actionType={ActionType.ListOfItems}
                isMultiScanInspection={props.isMultiScan}
              />
            );
            break;
          case Tasks.assignToTeamMember:
            showModal(
              <AssignEquipmentToUserModal
                serialisedProductIds={spIds}
                type={AssignToTeamMemberAction.listOfItems}
              />
            );
            break;
          case Tasks.assignToGroup:
            showModal(
              <AssignToGroupModal
                title={heading(t('actions.assign_to_group'))}
                serialisedProductIds={spIds}
                actionType={AssignToGroupAction.listOfItems}
                groupType={GroupType.KIT_BAG}
              />
            );
            break;
          case Tasks.addAlert:
            showModal(
              <EditItemInspectionFrequencyModal
                title={heading(t('actions.set_inspection_frequency'))}
                serialisedProductIds={spIds}
              />
            );
            break;
          case Tasks.bulkRemoveFromInventory:
            showModal(
              <ConfirmationModal
                title="Are you sure you want to remove these items from inventory?"
                message={`You are about to remove ${
                  spIds.length === 1 ? '1 item' : `${spIds.length} items`
                }  from inventory. This action cannot be undone.`}
                buttonLabel="Remove"
                onConfirm={async () => {
                  const res = await resolveMutation(
                    removeItemsFromInventory({
                      variables: {
                        itemIds: spIds,
                      },
                      refetchQueries: [Inventory.PaginatedWebInventory],
                    }),
                    {
                      successMessage: 'Items removed from inventory',
                      failureMessage: 'Failed to remove items from inventory',
                    }
                  );
                  if (res.ok) {
                    clearSelectedRows();
                    hideModal();
                  }
                }}
              />
            );
            break;
          case Tasks.bulkRemoveFromGroup:
            showModal(
              <RemoveFromGroupModal
                groupId={groupId ?? null}
                groupType={groupType}
                itemIds={spIds}
              />
            );

            break;
          case Tasks.assignToSku:
            showSidePanel(<AssignSkuSidePanel itemIds={spIds} />, {
              title: 'Assign Items to a new SKU',
            });
            break;
          default:
            showModal(<SimpleModal>No Task</SimpleModal>);
            break;
        }
      }}
      menuPlacement="top"
    />
  );
}

export default BulkActionSelector;
