import { FormikHelpers, useFormik } from 'formik';

import {
  NotificationPreference,
  NotificationPreferenceStatus,
  NotificationType,
} from '@scannable/common';
import {
  GetOrgNotificationPreferencesData,
  useUpdateOrgNotificationPreferencesMutation,
} from '@scannable/frontend/common';

import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import { Form } from '../Form/Form';

type OrgNotificationPreferencesUpdateProps = {
  preferences: GetOrgNotificationPreferencesData;
};

type OrgNotificationPreferencesUpdateValues = {
  inspectionDueId?: string;
  inspectionDueManagers: boolean;
  inspectionDueMembers: boolean;
  inspectionDueAssignedUsers: boolean;
  expiryDueId?: string;
  expiryDueManagers: boolean;
  expiryDueMembers: boolean;
  expiryDueAssignedUsers: boolean;
};

const getStatus = (value: boolean) => {
  return value
    ? NotificationPreferenceStatus.ENABLED
    : NotificationPreferenceStatus.DISABLED;
};

const isEnabled = (status: keyof typeof NotificationPreferenceStatus) => {
  return status === NotificationPreferenceStatus.ENABLED;
};

export function EditOrganisationNotificationPreferencesForm(
  props: OrgNotificationPreferencesUpdateProps
) {
  const { t } = useTranslation();
  const [update] = useUpdateOrgNotificationPreferencesMutation({
    refetchQueries: [NotificationPreference.GetOrgNotificationPreferences],
  });

  const inspectionDue = props.preferences.find(
    (p) => p.type === NotificationType.INSPECTION_DUE
  );
  const expiryDue = props.preferences.find(
    (p) => p.type === NotificationType.EXPIRY_DUE
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...(inspectionDue?.id && { inspectionDueId: inspectionDue?.id }),
      inspectionDueManagers: inspectionDue?.managers
        ? isEnabled(inspectionDue.managers)
        : true,
      inspectionDueMembers: inspectionDue?.members
        ? isEnabled(inspectionDue.members)
        : false,
      inspectionDueAssignedUsers: inspectionDue?.assignedUsers
        ? isEnabled(inspectionDue.assignedUsers)
        : true,
      ...(expiryDue?.id && { expiryDueId: expiryDue?.id }),
      expiryDueManagers: expiryDue?.managers
        ? isEnabled(expiryDue?.managers)
        : true,
      expiryDueMembers: expiryDue?.members
        ? isEnabled(expiryDue.members)
        : false,
      expiryDueAssignedUsers: expiryDue?.assignedUsers
        ? isEnabled(expiryDue.assignedUsers)
        : true,
    },
    onSubmit: async (
      values: OrgNotificationPreferencesUpdateValues,
      actions: FormikHelpers<OrgNotificationPreferencesUpdateValues>
    ) => {
      await resolveMutation(
        update({
          variables: {
            data: {
              preferences: [
                {
                  id: values.inspectionDueId,
                  type: NotificationType.INSPECTION_DUE,
                  managers: getStatus(values.inspectionDueManagers),
                  members: getStatus(values.inspectionDueMembers),
                  assignedUsers: getStatus(values.inspectionDueAssignedUsers),
                },
                {
                  id: values.expiryDueId,
                  type: NotificationType.EXPIRY_DUE,
                  managers: getStatus(values.expiryDueManagers),
                  members: getStatus(values.expiryDueMembers),
                  assignedUsers: getStatus(values.expiryDueAssignedUsers),
                },
              ],
            },
          },
        })
      );
      actions.setSubmitting(false);
    },
  });

  return (
    <Form formik={formik} buttons={[{ label: t('update'), type: 'submit' }]}>
      <div className="grid grid-cols-4 gap-4 border-b border-gray-200 py-2">
        <div className="col-span-1 text-xs text-gray-500">
          {t('notifications.notification')}
        </div>
        <div className="col-span-1 text-center text-xs text-gray-500">
          {t('managers')}
        </div>
        <div className="col-span-1 text-center text-xs text-gray-500">
          {t('members')}
        </div>
        <div className="col-span-1 text-center text-xs text-gray-500">
          {t('assigned_users')}
        </div>
      </div>
      <>
        <div className="grid grid-cols-4 gap-4 border-b border-gray-200 py-4 items-center">
          <div className="col-span-1 text-left">
            <div className="text-sm text-gray-900 font-bold mb-1">
              {t('notifications.inspection_due_next_30_days')}
            </div>
            <div className="text-xs text-gray-500">
              {t('notifications.sent_weekly')}
            </div>
          </div>
          <div className="col-span-1 text-center">
            <InputFieldGroup
              type="checkbox"
              name="inspectionDueManagers"
              formik={formik}
            />
          </div>
          <div className="col-span-1 text-center">
            <InputFieldGroup
              type="checkbox"
              name="inspectionDueMembers"
              formik={formik}
            />
          </div>
          <div className="col-span-1 text-center">
            <InputFieldGroup
              type="checkbox"
              name="inspectionDueAssignedUsers"
              formik={formik}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 border-b border-gray-200 py-4 items-center">
          <div className="col-span-1 text-left">
            <div className="text-sm text-gray-900 font-bold mb-1">
              {t('notifications.expiry_due_next_30_days')}
            </div>
            <div className="text-xs text-gray-500">
              {t('notifications.sent_weekly')}
            </div>
          </div>

          <div className="col-span-1 text-center">
            <InputFieldGroup
              type="checkbox"
              name="expiryDueManagers"
              formik={formik}
            />
          </div>
          <div className="col-span-1 text-center">
            <InputFieldGroup
              type="checkbox"
              name="expiryDueMembers"
              formik={formik}
            />
          </div>
          <div className="col-span-1 text-center">
            <InputFieldGroup
              type="checkbox"
              name="expiryDueAssignedUsers"
              formik={formik}
            />
          </div>
        </div>
      </>
    </Form>
  );
}

export default EditOrganisationNotificationPreferencesForm;
