import ReCAPTCHA from 'react-google-recaptcha';

export interface ReCaptchaInputProps {
  name: HTMLInputElement['name'];
  onChange?: (value: string | null) => void;
}

export function ReCaptchaInput({ name, onChange }: ReCaptchaInputProps) {
  const sitekey =
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ||
    '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

  return <ReCAPTCHA sitekey={sitekey} onChange={onChange} />;
}

export default ReCaptchaInput;
