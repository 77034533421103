import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { useChangeMyPasswordMutation } from '@scannable/frontend/common';

import { Button } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { resolveMutation } from '../../lib/lib';
import { FormAdminPanel } from '../../molecules/FormAdminPanel/FormAdminPanel';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import { Form } from '../Form/Form';

export const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Your old password is required'),
  newPassword: Yup.string()
    .min(8)
    .required('A password of at least 8 characters is required'),
  confirmPassword: Yup.string().test(
    'passwords-match',
    'Passwords must match',
    function (value) {
      return this.parent.newPassword === value;
    }
  ),
});

export interface ChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export function ChangePasswordForm() {
  const [changePassword] = useChangeMyPasswordMutation();
  const { signOut } = useAuth();

  const changePasswordForm = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: async (
      values: ChangePasswordFormValues,
      actions: FormikHelpers<ChangePasswordFormValues>
    ) => {
      const result = await resolveMutation(
        changePassword({
          variables: {
            data: {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
            },
          },
        }),
        {
          successMessage: 'Password successfully changed, please log in!',
        }
      );
      if (result.ok) {
        actions.setSubmitting(false);
        signOut();
      }
    },
  });

  return (
    <Form formik={changePasswordForm}>
      <FormAdminPanel
        renderFormFields={() => (
          <>
            <InputFieldGroup
              type="password"
              name="oldPassword"
              label="Old Password"
              formik={changePasswordForm}
              placeholder="Enter your old password"
            />
            <InputFieldGroup
              type="password"
              name="newPassword"
              label="New Password"
              formik={changePasswordForm}
              placeholder="Enter your new password"
            />
            <InputFieldGroup
              type="password"
              name="confirmPassword"
              label="Confirm New Password"
              formik={changePasswordForm}
              placeholder="Confirm your new password"
            />
          </>
        )}
        renderFormAction={() => (
          <Button
            className="mr-4"
            disabled={
              !changePasswordForm.isValid || changePasswordForm.isSubmitting
            }
            loading={changePasswordForm.isSubmitting}
            type="submit"
          >
            Change Password
          </Button>
        )}
      />
    </Form>
  );
}
