import { Certification } from '@scannable/common';

import { graphql } from '../graphql';

export const FIND_ALL_CERTIFICATIONS = graphql(`
  query ${Certification.FindAllCertifications} {
    certifications {
      id
      name
      eso
      legislation
      displayName
    }
  }
`);

export const ALL_CERTIFICATIONS = graphql(`
  query ${Certification.PaginatedCertifications} (
    $where: CertificationWhereInput
    $orderBy: [CertificationOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    paginatedCertifications(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      nodes {
        id
        name
        eso
        legislation
        displayName
      }
      totalCount
    }
  }
`);

export const GET_CERTIFICATION = graphql(`
  query ${Certification.GetCertification}($id: Int!) {
    certification(id: $id) {
      id
      name
      eso
      legislation
    }
  }
`);
