import { useMutation } from '@apollo/client';

import {
  CREATE_SERIALISED_PRODUCTS_IMAGES,
  GENERATE_SERIAL_PURCHASE_ORDER_CSV,
  UPDATE_SERIALISED_PRODUCT,
  UseMutationOptions,
} from '@scannable/graphql-queries';

export function useCreateSerialisedProductImagesMutation(
  options?: UseMutationOptions<typeof CREATE_SERIALISED_PRODUCTS_IMAGES>
) {
  return useMutation(CREATE_SERIALISED_PRODUCTS_IMAGES, options);
}

export function useGenerateSerialPurchaseOrderCsvMutation(
  options?: UseMutationOptions<typeof GENERATE_SERIAL_PURCHASE_ORDER_CSV>
) {
  return useMutation(GENERATE_SERIAL_PURCHASE_ORDER_CSV, options);
}

export function useUpdateSerialisedProductMutation(
  options?: UseMutationOptions<typeof UPDATE_SERIALISED_PRODUCT>
) {
  return useMutation(UPDATE_SERIALISED_PRODUCT, options);
}
