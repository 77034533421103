export function getOrganisationId(type: string | null = null) {
  return window.localStorage.getItem('organisationId');
}

export function getManufacturerId(type: string | null = null) {
  return window.localStorage.getItem('manufacturerId');
}

export function setUserToken(token: string | number | null) {
  return window.localStorage.setItem('token', `${token}`);
}
export function getUserToken() {
  return window.localStorage.getItem('token') || '';
}

export function setUserId(userId: string | number | null) {
  return window.localStorage.setItem('userId', `${userId}`);
}
export function getUserId() {
  return window.localStorage.getItem('userId') || '';
}

export function removeUserId() {
  return window.localStorage.removeItem('userId');
}

export function setOrganisationId(organisationId: string | number | null) {
  organisationId = organisationId ? `${organisationId}` : '';
  return window.localStorage.setItem('organisationId', organisationId);
}
export function removeOrganisationId() {
  return window.localStorage.removeItem('organisationId');
}

export function setManufacturerId(manufacturerId: string | number | null) {
  manufacturerId = manufacturerId ? `${manufacturerId}` : '';
  return window.localStorage.setItem('manufacturerId', manufacturerId);
}

export function removeUserToken() {
  return window.localStorage.removeItem('token');
}

export function getLastEmail() {
  return window.localStorage.getItem('loginEmail') || '';
}

export function setLastEmail(email: string) {
  return window.localStorage.setItem('loginEmail', email);
}
