import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { AssignToTeamMemberActionType } from '@scannable/common';

import { ModalForm } from '../../forms/ModalForm/ModalForm';
import { useAssignToUser, useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

interface AssignEquipmentToUserValues {
  userId?: number;
}

export interface AssignEquipmentToUserModalProps {
  serialisedProductId?: number;
  serialisedProductIds?: number[];
  groupId?: number;
  type: AssignToTeamMemberActionType;
}
const validationSchema = yup.object().shape({
  userId: yup.number().required('Please select a team member'),
});

export function AssignEquipmentToUserModal({
  serialisedProductId,
  serialisedProductIds,
  groupId,
  type,
}: AssignEquipmentToUserModalProps) {
  const { hideModal } = useModal();
  const { options, action } = useAssignToUser(type);

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      userId: undefined,
    } as AssignEquipmentToUserValues,
    validationSchema,
    onSubmit: async (
      values: AssignEquipmentToUserValues,
      actions: FormikHelpers<AssignEquipmentToUserValues>
    ) => {
      const result = await resolveMutation(
        action({
          groupId,
          serialisedProductId,
          serialisedProductIds,
          userId: values.userId,
        }),
        {
          model: 'user equipment',
          action: 'CREATE',
          successMessage: 'Action successfully completed',
        }
      );
      actions.setSubmitting(false);
      if (result.ok) {
        hideModal();
      }
    },
  });

  return (
    <Modal title="Assign to team member" size="sm" overflowHidden={false}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: t('assign'),
            type: 'submit',
            color: 'primary',
            'data-cy': 'assign-button',
          },
        ]}
      >
        {options && (
          <InputFieldGroup
            id="userId"
            name="userId"
            type="select"
            formik={formik}
            label="Select a team member"
            options={options}
            data-cy="team-member-select"
          />
        )}
      </ModalForm>
    </Modal>
  );
}

export default AssignEquipmentToUserModal;
