import { useMutation } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';

import { ProductCategories } from '@scannable/common';
import { ADD_PRODUCT_GROUP_METADATA } from '@scannable/graphql-queries';

import ModalForm from '../../forms/ModalForm/ModalForm';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

export interface AssignMetadataModalProps {
  productCategoryId: number;
  metadataId: number;
  metadataName: string;
}
export interface AddMetadataFormValues {
  dataTable: boolean;
}
export function AssignMetadataModal({
  metadataId,
  metadataName,
  productCategoryId,
}: AssignMetadataModalProps) {
  const [addMetadataToProductGroup] = useMutation(ADD_PRODUCT_GROUP_METADATA, {
    refetchQueries: [ProductCategories.ProductGroupMetadata],
  });
  const { hideModal } = useModal();
  const formik = useFormik({
    initialValues: {
      dataTable: true,
    },
    onSubmit: async (
      values: AddMetadataFormValues,
      actions: FormikHelpers<AddMetadataFormValues>
    ) => {
      const result = await resolveMutation(
        addMetadataToProductGroup({
          variables: {
            data: {
              id: productCategoryId,
              dataTable: values.dataTable,
              metadataId,
            },
          },
        }),
        {
          successMessage: 'Product group metadata added',
        }
      );
      if (result.ok) {
        actions.setSubmitting(false);
        hideModal();
      }
    },
  });
  return (
    <Modal title={`Add: ${metadataName}`} size="sm" overflowHidden={false}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: 'Confirm',
            type: 'submit',
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup
          id="DataTable"
          type="checkbox"
          name="dataTable"
          label="Metadata will display on the data table"
          formik={formik}
        />
      </ModalForm>
    </Modal>
  );
}

export default AssignMetadataModal;
