import { useEffect } from 'react';
import { InspectionType } from '@prisma/client';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { inspectionTypeOptions } from '@scannable/frontend/common';

import { Alert } from '../../atoms';
import ModalForm from '../../forms/ModalForm/ModalForm';
import { useAlert } from '../../hooks/useAlert/useAlert';
import { useTable } from '../../hooks/useTable/useTable';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules/InputFieldGroup/InputFieldGroup';
import Modal from '../Modal/Modal';
import { useModal } from '../ModalContext/ModalContext';

type InspectionFrequencyValues = {
  alertTemplateId?: number;
  name?: string;
  alertUnitValue?: string;
  quantity?: number;
  due?: Date;
  type: InspectionType;
};

export interface InspectionFrequencyModalProps {
  title: string;
  alertTemplateId?: number;
  message?: string;
}
const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter a name'),
  alertUnitValue: yup.string().required('Please select a unit'),
});

export function InspectionFrequencyModal({
  title,
  message,
  alertTemplateId,
}: InspectionFrequencyModalProps) {
  const { hideModal } = useModal();
  const {
    getAlertTemplate,
    addAlertTemplate,
    updateAlertTemplate,
    alertUnitFromValue,
    alertUnitFromSchedule,
    alertUnits,
  } = useAlert();
  const { reload } = useTable();
  const formik = useFormik({
    initialValues: {
      alertTemplateId: alertTemplateId,
      name: '',
      alertUnitValue: '',
      quantity: 0,
      due: undefined,
      type: 'GENERAL',
    } as InspectionFrequencyValues,
    validationSchema,
    onSubmit: async (
      values: InspectionFrequencyValues,
      actions: FormikHelpers<InspectionFrequencyValues>
    ) => {
      if (!alertTemplateId) {
        const { schedule } = alertUnitFromValue(
          values?.alertUnitValue as string
        );
        await resolveMutation(
          addAlertTemplate({
            variables: {
              data: {
                name: values?.name ?? '',
                unit: schedule.unit,
                quantity: schedule.quantity,
                type: values?.type,
              },
            },
          }),
          {
            failureMessage: 'Error creating alert template',
            successMessage: 'Successfully created',
          }
        );
      }
      if (alertTemplateId) {
        const { schedule } = alertUnitFromValue(
          values?.alertUnitValue as string
        );
        await resolveMutation(
          updateAlertTemplate({
            variables: {
              data: {
                id: alertTemplateId,
                name: values?.name,
                unit: schedule.unit,
                quantity: schedule.quantity,
                type: values?.type,
              },
            },
          }),
          {
            failureMessage: 'Error updating alert template',
            successMessage: 'Successfully updated',
          }
        );
      }
      reload();
      actions.setSubmitting(false);
      hideModal();
    },
  });

  useEffect(() => {
    const retrieveAlertTemplate = async () => {
      const { data } = await getAlertTemplate({
        variables: {
          id: alertTemplateId ?? 0,
        },
      });
      const alertTemplate = data?.alertTemplate;
      const { value } = alertUnitFromSchedule(
        alertTemplate?.quantity,
        alertTemplate?.unit
      );
      formik.setFieldValue('name', data?.alertTemplate?.name);
      formik.setFieldValue('alertUnitValue', value);
    };
    if (alertTemplateId) {
      retrieveAlertTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertTemplateId]);

  return (
    <Modal title={title} size="sm" overflowHidden={false}>
      <ModalForm
        formik={formik}
        buttons={[
          {
            label: alertTemplateId ? 'Update' : 'Create',
            type: 'submit',
            onClick: () => {
              formik.submitForm();
            },
            color: 'primary',
          },
        ]}
      >
        <InputFieldGroup
          id="name"
          name="name"
          type="text"
          formik={formik}
          label="Name"
          data-cy="alert-label"
        />
        <InputFieldGroup
          name="type"
          type="select"
          formik={formik}
          label="Inspection Type"
          tooltip="Select the type of inspection that will be performed from this schedule"
          options={inspectionTypeOptions}
        />
        <InputFieldGroup
          id="unit-select"
          name="alertUnitValue"
          type="select"
          data-cy="alert-frequency"
          formik={formik}
          label="Frequency"
          options={alertUnits.map((unit) => ({
            label: unit.label,
            value: unit.value,
          }))}
        />
        {message && (
          <div className="pt-4 text-sm">
            <Alert type="warning" message={message} />
          </div>
        )}
      </ModalForm>
    </Modal>
  );
}

export default InspectionFrequencyModal;
