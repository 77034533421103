import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { GroupType } from '@prisma/client';

import {
  GET_ALL_GROUPS,
  GET_GROUP,
  GET_GROUPS_BY_SERIALISED_PRODUCT_ID,
  GET_GROUPS_BY_TYPE,
  UseLazyQueryOptions,
} from '@scannable/graphql-queries';

export function useGetGroupsByTypeLazyQuery(
  options?: UseLazyQueryOptions<typeof GET_GROUPS_BY_TYPE>
) {
  return useLazyQuery(GET_GROUPS_BY_TYPE, options);
}

export function useGetAccountGroupNamesByTypeQuery(type: GroupType) {
  return useQuery(GET_GROUPS_BY_TYPE, {
    variables: { type },
    skip: !type,
    fetchPolicy: 'cache-and-network',
  });
}

export function useGetAccountGroupNamesQuery(options?: QueryHookOptions) {
  return useQuery(GET_ALL_GROUPS, options);
}

export function useGetGroupsBySerialisedProductIdQuery(
  serialisedProductId: number,
  isLoggedIn?: boolean
) {
  return useQuery(GET_GROUPS_BY_SERIALISED_PRODUCT_ID, {
    variables: { serialisedProductId },
    skip: !serialisedProductId || isLoggedIn === false,
    fetchPolicy: 'cache-and-network',
  });
}

export function useGetGroupQuery(id: number) {
  return useQuery(GET_GROUP, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });
}
