import { SIGN_IN_ROUTE } from '@scannable/common';

import { useAuth, useRoleName } from '../../auth/AuthContext/AuthContext';
import { UserMenu } from '../../organisms';

interface IUserNavigationItem {
  name: string;
  href: string;
  testRef?: string;
}
const userNavigationItems: IUserNavigationItem[] = [
  { name: 'Profile', href: '/admin/me', testRef: 'profile' },
  { name: 'Dashboard', href: '/admin', testRef: 'dashboard' },
  { name: 'Invites', href: '/admin/invitations', testRef: 'invites' },
];
const unLoggedInNavigation = [{ name: 'Sign in', href: SIGN_IN_ROUTE }];

export function UserDropdown() {
  const { signOut, isLoggedIn, loading, user } = useAuth();
  const roleName = useRoleName();

  let navigationToShow = [] as IUserNavigationItem[];
  if (isLoggedIn && !loading) {
    navigationToShow = userNavigationItems;
  }
  if (!isLoggedIn && !loading) {
    navigationToShow = unLoggedInNavigation;
  }
  return (
    <UserMenu
      navigationToShow={navigationToShow}
      isLoggedIn={isLoggedIn}
      user={user}
      signOut={signOut}
      roleName={roleName}
    />
  );
}

export default UserDropdown;
