import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { ApolloClient, ApolloProvider, useReactiveVar } from '@apollo/client';
import { SubscriptionType } from '@prisma/client';
import * as Sentry from '@sentry/nextjs';
import { useDispatch } from 'react-redux';

import {
  AccountModule,
  AccountModuleType,
  ACTIVATION_ROUTE,
  ADMIN_HOME_ROUTE,
  CHANGE_ORGANISATION,
  constructRedirectURI,
  DASHBOARD_HOME_ROUTE,
  decodeJwt,
  FORBIDDEN_ROUTE,
  initialAuthState,
  Integration,
  INVITE_ROUTE,
  isRouteProtected,
  NETWORK_CONNECTION_ERROR_MESSAGE,
  PLEASE_TRY_AGAIN,
  proModules,
  redirectHandler,
  Role,
  RoleType,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  SIMPLE_ERROR_MESSAGE,
  SUCCESS_ROUTE,
} from '@scannable/common';
import {
  accessTokenUpdatedVar,
  initApolloClient,
} from '@scannable/frontend/apollo';
import {
  useApplicationStatus,
  userRoleOptions,
} from '@scannable/frontend/common';
import { resetTableFilters } from '@scannable/frontend/store';
import { UserAuthState } from '@scannable/frontend/types';
import {
  GET_ORGANISATION,
  IMPERSONATE_ORGANISATION,
  LOG_OUT,
  ME_QUERY,
  REFRESH_TOKENS_WITH_ORGANISATION,
} from '@scannable/graphql-queries';

import { canViewCurrentRoute } from '../../lib/navigation';
import {
  errorAlert,
  getManufacturerId,
  getOrganisationId,
  getUserId,
  getUserToken,
  removeOrganisationId,
  removeUserId,
  removeUserToken,
  setManufacturerId,
  setOrganisationId,
  setUserId,
  setUserToken,
  successAlert,
} from '../../utils';
import { AuthContext, config, resolve } from './lib';

export interface AuthProviderProps {
  children?: React.ReactNode;
  client?: ApolloClient<unknown> | undefined;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export function useIsPro() {
  const { subscriptionType } = useAuth();
  return subscriptionType === AccountModule.Pro;
}

export function useIsAccess(): boolean {
  const { subscriptionType } = useAuth();
  return subscriptionType === SubscriptionType.ACCESS;
}
export function useIsAdmin(): boolean {
  const { roles } = useAuth();
  return roles.includes(Role.Admin);
}

export function useIsEnterprise(): boolean {
  const { subscriptionType } = useAuth();
  return subscriptionType === SubscriptionType.ENTERPRISE;
}

export function useIsCompetentPerson(): boolean {
  const { roles, modules } = useAuth();
  return (
    roles.includes(Role.CompetentPerson) &&
    proModules.some((module) => modules.includes(module))
  );
}
export function useCurrentOrganisation() {
  const { organisationId, organisationName, organisationUuid } = useAuth();
  return {
    id: organisationId,
    name: organisationName,
    uuid: organisationUuid,
  };
}

export function useRoleGuard() {
  const { guard } = useAuth();
  return guard;
}
export function useCurrentUser() {
  const { user } = useAuth();
  return user;
}

export function useModuleGuard() {
  const { modules } = useAuth();

  const moduleGuard = (userModules: AccountModuleType[]) =>
    !modules ||
    modules.length === 0 ||
    modules.some((m) => {
      return userModules.includes(m);
    });

  return moduleGuard;
}

export function useIntegrationGuard() {
  const { integrations } = useAuth();
  return (integration: Integration) => {
    if (!integrations) {
      return false;
    }
    return integrations.includes(integration);
  };
}

export function useIsSuperAdmin() {
  const { roles } = useAuth();
  return roles.includes(Role.SuperAdmin);
}

export const useRoleName = () => {
  const { roles } = useAuth();
  return userRoleOptions.find((r) => roles.includes(r.value as RoleType))
    ?.label;
};

export function ApolloAuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { client, ...auth } = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </AuthContext.Provider>
  );
}
const initialState = {};

function useProvideAuth() {
  const { turnOnMaintenanceMode } = useApplicationStatus();
  const [authState, setAuthState] = useState<UserAuthState>(initialAuthState);
  const client = useMemo(
    () =>
      initApolloClient(initialState, {
        ...config,
        onNetworkError: (e) => {
          if ('statusCode' in e && Number(e.statusCode) === 503) {
            turnOnMaintenanceMode();
          } else {
            errorAlert(NETWORK_CONNECTION_ERROR_MESSAGE);
          }
        },
        setToken: async (token) => setUserToken(token),
        getToken: async () => getUserToken(),
        getUserId: async () => getUserId(),
        onRefreshTokenError: () => {
          if (!document.hidden) {
            errorAlert('Your session has expired. Please sign in again.');
            resetAuthState(true);
            window.location.reload();
          }
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [redirectPath, setRedirectPath] = useState<string | null>(null);
  const accessTokenUpdated = useReactiveVar(accessTokenUpdatedVar);
  const dispatch = useDispatch();

  const router = useRouter();

  const logoutUser = useCallback(async () => {
    const result = await resolve(
      client.mutate({
        mutation: LOG_OUT,
      })
    );
    return result;
  }, [client]);

  const impersonateOrganisation = useCallback(
    async (organisationId: number) => {
      const result = await resolve(
        client.mutate({
          mutation: IMPERSONATE_ORGANISATION,
          variables: {
            data: {
              organisationId,
            },
          },
        })
      );
      return result;
    },
    [client]
  );

  const redirectUserToSignIn = useCallback(
    (clearHttpOnly = false) => {
      if (![SIGN_IN_ROUTE].includes(router.pathname)) {
        let pathToRedirect = redirectPath;
        if (
          [SUCCESS_ROUTE, INVITE_ROUTE, ACTIVATION_ROUTE].includes(
            router.pathname
          )
        ) {
          pathToRedirect = router.asPath;
        }
        if (clearHttpOnly) {
          router.push(redirectHandler(pathToRedirect));
        } else {
          router.push(constructRedirectURI({ redirect: pathToRedirect }));
        }
      }
    },
    [redirectPath, router]
  );

  const setLoading = (loading: boolean) => {
    setAuthState((prevState: UserAuthState) => ({
      ...prevState,
      loading,
    }));
  };
  const setRedirecting = (redirecting: boolean) => {
    setAuthState((prevState: UserAuthState) => ({
      ...prevState,
      redirecting,
    }));
  };

  const resetAuthState = useCallback((redirectToSignIn = false) => {
    removeUserId();
    removeUserToken();
    setAuthState({
      ...initialAuthState,
      redirecting: redirectToSignIn,
    });
  }, []);

  const fetchUserData = useCallback(async () => {
    const [result] = await resolve(
      client.query({
        query: ME_QUERY,
        fetchPolicy: 'network-only',
      }),
      PLEASE_TRY_AGAIN,
      false
    );
    return result?.data?.me ?? null;
  }, [client]);

  const fetchOrgData = useCallback(async () => {
    const [result] = await resolve(
      client.query({
        query: GET_ORGANISATION,
        fetchPolicy: 'network-only',
      }),
      PLEASE_TRY_AGAIN,
      false
    );
    return result?.data?.getUserOrganisation ?? null;
  }, [client]);

  const loadAuth = useCallback(
    async (token: string, redirecting = false) => {
      if (token) {
        const {
          userId,
          roles,
          modules,
          organisations,
          manufacturerId,
          organisationId,
          isPersonalAccount,
          activated,
          integrations,
        } = decodeJwt(token);
        setUserId(`${userId}`);
        setUserToken(token);
        setOrganisationId(organisationId);
        setManufacturerId(manufacturerId);
        const organisation = await fetchOrgData();
        const user = await fetchUserData();
        const canView = canViewCurrentRoute(roles, router.pathname);

        if (user && organisation) {
          setAuthState({
            ...authState,
            user,
            isPersonalAccount,
            userId: Number(userId),
            roles,
            modules,
            organisations,
            manufacturerId,
            organisationId,
            organisationUuid: organisation?.uuid ?? null,
            organisationName: organisation?.name ?? null,
            trialEnds: organisation?.trialEnds ?? null,
            subscriptionStatus: organisation?.subscriptionStatus ?? null,
            subscriptionType: organisation?.subscriptionType ?? null,
            integrations,
            token,
            redirecting, // set redirecting to handle any ui depening on displaying loading
            loading: !canView,
            activated,
          });
          Sentry.setUser({
            id: userId,
            organisationId,
            email: user.email,
          });
          if (canView) {
            Sentry.addBreadcrumb({
              category: 'navigation',
              message: `User can view route: ${router.pathname}`,
              level: 'info',
            });
          }
          if (!canView) {
            Sentry.addBreadcrumb({
              category: 'navigation',
              message: `User can't view route: ${router.pathname}`,
              level: 'info',
            });
            // set loading to true after route change
            router.push(FORBIDDEN_ROUTE).then(() => setLoading(false));
          }
        } else {
          resetAuthState();
        }
      }
    },
    [authState, fetchOrgData, fetchUserData, resetAuthState, router]
  );

  const refreshAuthState = useCallback(
    async (token: string) => {
      setLoading(true);
      await loadAuth(token);

      if (client) {
        await client.clearStore();
      }
      dispatch(resetTableFilters());
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [client, dispatch, loadAuth]
  );

  const retrieveLocalState = async () => {
    const organisationId = getOrganisationId();
    const manufacturerId = getManufacturerId();
    return {
      organisationId: organisationId ? Number(organisationId) : null,
      manufacturerId: manufacturerId ? Number(manufacturerId) : null,
    };
  };

  const reloadUser = async () => {
    const user = await fetchUserData();
    setAuthState({
      ...authState,
      user,
    });
  };

  const signIn = useCallback(
    async (token: string, redirecting = true) => {
      // reset any redux state here for a new session.. right now just tables state
      dispatch(resetTableFilters());
      await loadAuth(token, redirecting);
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'User signed in',
        level: 'info',
      });
    },
    [dispatch, loadAuth]
  );

  const signOut = useCallback(async () => {
    try {
      const isSuper = authState.roles.includes(Role.SuperAdmin);
      await logoutUser();
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'User signed out',
        level: 'info',
      });
      Sentry.setUser(null);
      // then we unset local storage otherwise the auth will fail
      resetAuthState();
      if (isSuper) {
        removeOrganisationId();
      }
      // clear local session storage
      sessionStorage.clear();
      if (client) {
        await client.clearStore();
      }
      // no redirect to sign in
      if (isRouteProtected(router.asPath)) {
        redirectUserToSignIn();
      }
    } catch (e) {
      Sentry.captureException(e);
      resetAuthState();
    }
  }, [
    authState.roles,
    logoutUser,
    resetAuthState,
    client,
    router.asPath,
    redirectUserToSignIn,
  ]);

  const refreshTokens = useCallback(
    async (organisationId: number) => {
      const [result] = await resolve(
        client.mutate({
          mutation: REFRESH_TOKENS_WITH_ORGANISATION,
          variables: { organisationId },
        }),
        SIMPLE_ERROR_MESSAGE
      );
      if (result?.data?.refreshTokenWithCookie) {
        setOrganisationId(organisationId);
        setUserToken(result.data.refreshTokenWithCookie.accessToken);
        return result.data.refreshTokenWithCookie;
      }
      return { accessToken: null };
    },
    [client]
  );

  const changeOrganisation = async (organisationId: number) => {
    const { accessToken } = await refreshTokens(organisationId);
    if (!accessToken) {
      Sentry.captureMessage('No access tokens from org change');
      errorAlert(SIMPLE_ERROR_MESSAGE);
      return;
    }
    await refreshAuthState(accessToken);
    successAlert(CHANGE_ORGANISATION);
  };

  const impersonate = async (organisationId: number) => {
    const [result] = await impersonateOrganisation(organisationId);
    if (result?.errors || !result?.data?.impersonateOrganisation?.accessToken) {
      errorAlert(SIMPLE_ERROR_MESSAGE);
      return;
    }
    await refreshAuthState(result.data.impersonateOrganisation.accessToken);
    successAlert(CHANGE_ORGANISATION);
  };

  const guard = (roles: RoleType[] | undefined) => {
    const userRoles = authState.roles;
    return !roles ||
      roles.length === 0 ||
      userRoles.some((r) => {
        return roles.includes(r as RoleType);
      })
      ? true
      : false;
  };

  const refreshAccount = useCallback(
    async (organisationId?: number): Promise<boolean> => {
      const orgIdToRefresh =
        organisationId || localStorage.getItem('organisationId');

      if (!orgIdToRefresh) {
        errorAlert(SIMPLE_ERROR_MESSAGE);
        return false;
      }

      const { accessToken } = await refreshTokens(Number(orgIdToRefresh));

      if (accessToken) {
        await refreshAuthState(accessToken);
        return true;
      }
      errorAlert(SIMPLE_ERROR_MESSAGE);
      return false;
    },
    [refreshTokens, refreshAuthState]
  );

  useEffect(() => {
    // if we have a new token, update local state including roles and available organisations
    if (accessTokenUpdated) {
      const token = localStorage.getItem('token') as string;
      const { roles, modules, organisations, organisationId } =
        decodeJwt(token);
      if (
        token &&
        organisationId &&
        authState.organisationId === organisationId &&
        authState.loading === false
      ) {
        setAuthState({
          ...authState,
          roles,
          modules,
          organisations,
          token,
        });
        accessTokenUpdatedVar(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenUpdated]);

  const localStorageChangeHandler = useCallback(
    async (event: StorageEventInit) => {
      const logOutEvent = event.key === 'token' && event.newValue === null;
      const tokenChange = event.key === 'token' && event.newValue !== null;
      const orgChange =
        event.key === 'organisationId' && event.newValue !== null;
      // sign out of all tabs if user signs out, send them to home screen
      if (logOutEvent) {
        router.reload();
      }
      // refresh all tabs if user logs in
      if (tokenChange) {
        router.reload();
      }
      if (orgChange) {
        router.reload();
      }
    },
    [router]
  );

  // listens for log out or new log in events and handles accordingly
  useEffect(() => {
    window.addEventListener('storage', localStorageChangeHandler);
    return () => {
      window.removeEventListener('storage', localStorageChangeHandler);
    };
  }, [localStorageChangeHandler]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !authState.token) {
      loadAuth(token, false);
    }
    // if the user is not logged in and the route is protected, redirect to sign in
    // - catches non-logged in tabs that are refreshed
    // - this also catches users with an expired refresh token
    if (!token && isRouteProtected(router.asPath)) {
      redirectUserToSignIn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handles loading state which controls loading screen
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token && authState.token && authState.redirecting) {
      // redirect anyone on these screens to /admin
      if ([SIGN_IN_ROUTE, DASHBOARD_HOME_ROUTE].includes(router.pathname)) {
        const { redirect } = router.query;
        router.push((redirect as string) || ADMIN_HOME_ROUTE);
      } else if ([SIGN_UP_ROUTE].includes(router.pathname)) {
        const query = router.query;
        const isInvite = query?.i;
        router.push({
          pathname: ADMIN_HOME_ROUTE,
          query: isInvite ? { welcome: '1' } : { trial: '1' },
        });
      } else {
        // redireting state needs to be set back to normal if no redirecting to do
        setRedirecting(false);
      }
    }

    // if no user in localstorage and we aren't trying to access a protected route, then it's ok to set loading false
    if (authState.loading && !isRouteProtected(router.asPath) && !token) {
      setLoading(false);
    }
  }, [
    authState.loading,
    authState.redirecting,
    authState.roles,
    authState.token,
    redirectPath,
    router,
  ]);

  const handleRouteChange = useCallback(
    (route: string) => {
      const token = localStorage.getItem('token');
      if (redirectPath) {
        setRedirectPath(null);
      }
      if (authState.redirecting) {
        setRedirecting(false);
      }

      if (token) {
        const { roles } = decodeJwt(token);
        // handle if user can't see this route
        if (!canViewCurrentRoute(roles, route)) {
          router.push(FORBIDDEN_ROUTE);
        }
      }
    },
    [redirectPath, authState.redirecting, router]
  );

  // on a route change, checks if user can view
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [handleRouteChange, router.events]);

  const isAManager =
    authState.roles.includes(Role.SuperAdmin) ||
    authState.roles.includes(Role.Admin);

  const isOnTrial =
    authState.roles.includes(AccountModule.Pro) &&
    authState.subscriptionStatus === 'TRIAL';

  const isOnTrialOrAccess =
    isOnTrial || authState.subscriptionType === AccountModule.Access;

  return {
    ...authState,
    client,
    isLoggedIn: !!authState.userId && !!authState.user,
    isAManager,
    signIn,
    signOut,
    impersonate,
    reloadUser,
    retrieveLocalState,
    changeOrganisation,
    refreshAccount,
    guard,
    isOnTrial,
    isOnTrialOrAccess,
  };
}
