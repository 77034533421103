export interface DescriptionListItemProps {
  title?: string;
  item?: string | React.ReactNode;
  children?: React.ReactNode;
}

export function DescriptionListItem({
  title,
  item,
  children,
}: DescriptionListItemProps) {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6">
      {title && <dt className="text-sm font-medium text-gray-500">{title}</dt>}
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {item || children}
      </dd>
    </div>
  );
}

export interface DescriptionListProps {
  title: string;
  subTitle?: string;
  description?: React.ReactNode;
  items?: DescriptionListItemProps[];
  children?: React.ReactNode;
}

export function DescriptionList({
  title,
  subTitle,
  description,
  items,
  children,
}: DescriptionListProps) {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{subTitle}</p>
        {description}
      </div>
      {items && (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            {items.map((item) => (
              <DescriptionListItem key={item.title} {...item} />
            ))}
          </dl>
        </div>
      )}
      {children && (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">{children}</dl>
        </div>
      )}
    </div>
  );
}

export default DescriptionList;
