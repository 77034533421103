import { useQuery } from '@apollo/client';

import {
  CURRENT_ADDRESS,
  GET_ORGANISATION_USERS,
  MY_ADDRESSES,
  UseQueryOptions,
} from '@scannable/graphql-queries';

import { GraphQLResult } from '../../types/graphql.types';

export type AddressGraphQLType = GraphQLResult<typeof CURRENT_ADDRESS>;

export function useCurrentAddressQuery(
  options?: UseQueryOptions<typeof CURRENT_ADDRESS>
) {
  return useQuery(CURRENT_ADDRESS, options);
}

export function useMyAddressesQuery(
  options?: UseQueryOptions<typeof MY_ADDRESSES>
) {
  return useQuery(MY_ADDRESSES, options);
}

export function useGetOrganisationUsersQuery(
  options?: UseQueryOptions<typeof GET_ORGANISATION_USERS>
) {
  return useQuery(GET_ORGANISATION_USERS, options);
}
