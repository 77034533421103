import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { AccountModule } from '@scannable/common';

import { LoadingPlaceholder } from '../../atoms';
import { useAuth } from '../../auth/AuthContext/AuthContext';
import { useModal } from '../../modals/ModalContext/ModalContext';
import OrganisationSelectorModal from '../../modals/OrganisationSelectorModal/OrganisationSelectorModal';

export function OrganisationSelector() {
  const { user, organisationName, isPersonalAccount, subscriptionType } =
    useAuth();
  const { showModal } = useModal();

  let accountDisplay = organisationName;
  if (isPersonalAccount) {
    switch (subscriptionType) {
      case AccountModule.Pro:
        accountDisplay = 'Pro Account';
        break;
      case AccountModule.Access:
        accountDisplay = 'Access Account';
        break;
      default:
        break;
    }
  }

  return (
    <button
      className="block w-full text-left"
      disabled={!user || !organisationName}
      onClick={() => showModal(<OrganisationSelectorModal dismissable />)}
    >
      <div className="flex cursor-pointer justify-between">
        <img
          src="/static/scannable-icon.svg"
          alt="Scannable"
          className="object-contain object-left"
          width={50}
          height={50}
        />
        <div className="flex w-full flex-col justify-start pl-4 pt-1">
          <span className="text-sm font-medium text-gray-900">
            {user ? (
              <>
                {user?.firstName} {user?.lastName}
              </>
            ) : (
              <LoadingPlaceholder height="4" width="12" color="gray-200" />
            )}
          </span>
          <span
            className="text-[12px] font-medium text-gray-500"
            data-cy="org-name"
          >
            {accountDisplay ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>{accountDisplay}</>
            ) : (
              <LoadingPlaceholder height="4" width="20" color="gray-300" />
            )}
          </span>
        </div>
        <div className="flex justify-center items-center">
          <div className="border-l border-gray-200 pl-3 h-7 flex justify-center items-center">
            <ChevronDownIcon className="w-5 h-5 text-white bg-gray-400 rounded" />
          </div>
        </div>
      </div>
    </button>
  );
}

export default OrganisationSelector;
