import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { ProductDataType } from '@prisma/client';

import {
  classNames,
  fileExtensionLowerCase,
  formatUnit,
} from '@scannable/common';
import { ItemData } from '@scannable/frontend/common';

import Icon from '../Icon/Icon';
import VideoEmbed from '../VideoEmbed/VideoEmbed';

export interface GroupListItemProps {
  isNfc?: boolean;
  className?: string;
  renderValue?: () => JSX.Element;
}

export function ValueDisplay({
  pdfs,
  value,
  unit,
  productDataType,
  certifications,
  isNfc,
}: Partial<ItemData> & { isNfc?: boolean }) {
  if (pdfs && pdfs.length > 0) {
    return (
      <>
        {pdfs.map((item) => (
          <a
            key={`${item.id}`}
            href={`${item.url}`}
            className="px-2 mr-2 mb-2 inline-block rounded-full text-gray-900 text-xs bg-blue-100"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center">
              <span className="p-2">
                {item.name ? fileExtensionLowerCase(item.name, 'PDF') : 'PDF'}
              </span>
              <span>
                <DocumentTextIcon className="h-8 w-4 text-brand-secondary ml-auto" />
              </span>
            </div>
          </a>
        ))}
      </>
    );
  }
  if (certifications && certifications.length > 0) {
    return (
      <>
        {certifications.map((item) => (
          <span key={`${item.id}`} className="pr-2">
            {item.name}
          </span>
        ))}
      </>
    );
  }
  if (productDataType === ProductDataType.URL) {
    return (
      <a
        href={`${value}`}
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    );
  }
  if (productDataType === ProductDataType.VIDEO_EMBED && value) {
    return <VideoEmbed videoUrl={value} />;
  }
  if (isNfc && value) {
    return (
      <div className="flex">
        <Icon name="hasNfc" className="mr-2" width={20} height={20} />
        <div className="flex-col pr-4">{value}</div>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{`${value || ''} ${formatUnit(unit) || ''}`}</>;
}
export function GroupListItem({
  name,
  className,
  value,
  unit,
  pdfs,
  certifications,
  productDataType,
  renderValue,
}: GroupListItemProps & Partial<ItemData>) {
  return (
    <div className="grid grid-cols-6 gap py-3 last:border-0 flex-shrink flex-wrap relative">
      <dt className="col-span-2 font-medium text-brand-grey md:text-sm text-xs pr-3">
        {name}
      </dt>
      <dd
        className={classNames(
          'col-span-4 text-gray-900 font-medium md:text-sm text-xs',
          className || ''
        )}
        style={{ wordBreak: 'break-word' }} // safari fix
      >
        {renderValue ? (
          renderValue()
        ) : (
          <ValueDisplay
            pdfs={pdfs}
            value={value}
            unit={unit}
            productDataType={productDataType}
            certifications={certifications}
          />
        )}
      </dd>
    </div>
  );
}

export default GroupListItem;
