import { useGetOrganisationUsersQuery } from '../../hooks/organisations/queries';

const filter = {
  usersEquipment: {
    some: {
      userId: {
        in: '{VAL}',
      },
    },
  },
};

export function useTeamMemberFilter() {
  const { data } = useGetOrganisationUsersQuery();
  const options = data?.organisationUsers
    ? data.organisationUsers
        .map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.userId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  return {
    interpolatedFilter: filter,
    options,
  };
}
