import { ReactEventHandler, useEffect, useState } from 'react';
import NextImage, { ImageProps as NextImageProps } from 'next/image';

import { MISSING_IMAGE, resizedImage } from '@scannable/common';

export interface ResizedImageProps {
  width?: number;
  height?: number;
  fit?: 'fill' | 'cover';
}

export function ResizedImage({
  alt,
  src,
  width,
  height,
  fit,
  ...props
}: ResizedImageProps & NextImageProps) {
  const [error, setError] = useState(null);
  let imageUrl = src || MISSING_IMAGE;
  if (src) {
    imageUrl = resizedImage(`${src}`, { width, height, fit });
  }
  const fallbackImage = MISSING_IMAGE;
  useEffect(() => {
    setError(null);
  }, [src]);

  // because we may not have a width and height, Next Image needs both so we need to set the fill prop
  const widthProps = width && height ? { width, height } : { fill: true };
  return (
    <NextImage
      alt={alt}
      onError={setError as unknown as ReactEventHandler<unknown>}
      src={error ? fallbackImage : imageUrl}
      {...widthProps}
      {...props}
    />
  );
}

export default ResizedImage;
