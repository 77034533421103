import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { Organisation } from '@scannable/common';
import { useUpdateAddressMutation } from '@scannable/frontend/common';

import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Form from '../Form/Form';

interface AddressUpdateValues {
  line1: string;
  line2: string;
  city: string;
  postCode: string;
  country: string;
  state: string;
}
interface AddressFormProps extends Partial<AddressUpdateValues> {
  id: string | null;
}

const validationSchema = Yup.object().shape({
  line1: Yup.string().required('Please add address line 1'),
});

export function AddressForm({
  id,
  line1,
  line2,
  city,
  postCode,
  country,
  state,
}: AddressFormProps) {
  const [updateAddress] = useUpdateAddressMutation({
    refetchQueries: [Organisation.CurrentAddressQuery],
  });

  const formik = useFormik({
    initialValues: {
      line1: line1 ?? '',
      line2: line2 ?? '',
      city: city ?? '',
      postCode: postCode ?? '',
      country: country ?? '',
      state: state ?? '',
    },
    validationSchema,
    onSubmit: async (
      values: AddressUpdateValues,
      actions: FormikHelpers<AddressUpdateValues>
    ) => {
      await resolveMutation(
        updateAddress({
          variables: {
            data: {
              id: id ?? null,
              ...values,
            },
          },
        }),
        { successMessage: 'Address updated successfully' }
      );
      actions.setSubmitting(false);
    },
  });

  return (
    <Form
      formik={formik}
      buttons={[
        {
          type: 'submit',
          label: 'Update Address',
        },
      ]}
    >
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-2">
        <InputFieldGroup
          label="Address Line 1"
          type="text"
          name="line1"
          placeholder="Address line 1"
          formik={formik}
        />
        <InputFieldGroup
          label="Address Line 2"
          type="text"
          name="line2"
          placeholder="Address Line 2"
          formik={formik}
        />
        <InputFieldGroup
          label="City"
          type="text"
          name="city"
          placeholder="City"
          formik={formik}
        />
        <InputFieldGroup
          label="Post Code"
          type="text"
          name="postCode"
          placeholder="Post code"
          formik={formik}
        />
        <InputFieldGroup
          label="State"
          type="text"
          name="state"
          placeholder="State"
          formik={formik}
        />
        <InputFieldGroup
          label="Country"
          type="country"
          name="country"
          placeholder="Country"
          formik={formik}
        />
      </div>
    </Form>
  );
}
