export enum ItemAction {
  removeItemFromInventory = 'removeItemFromInventory',
  assignToUser = 'assignToUser',
  addToKitBag = 'addToKitBag',
  addToLocation = 'addToLocation',
  addToVehicle = 'addToVehicle',
  addToClient = 'addToClient',
  addToStaff = 'addToStaff',
  addToEquipmentType = 'addToEquipmentType',
  inspect = 'inspect',
  addUpdateGroups = 'addUpdateGroups',
}
