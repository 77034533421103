import UpgradePanel from '../UpgradePanel/UpgradePanel';

export interface UpgradeGuardProps {
  title?: string;
  shouldHide: boolean;
  children: React.ReactNode;
}

export function UpgradeGuard({
  title,
  shouldHide = false,
  children,
}: UpgradeGuardProps) {
  if (shouldHide) {
    return (
      <>
        {title && (
          <div className="mb-6 mt-10">
            <h2 className="text-2xl font-semibold text-gray-900">{title}</h2>
          </div>
        )}
        <UpgradePanel />
      </>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default UpgradeGuard;
