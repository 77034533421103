import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { Organisation } from '@scannable/common';
import { useUpdateAccountMutation } from '@scannable/frontend/common';

import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { InputFieldGroup } from '../../molecules';
import Form from '../Form/Form';

interface AccountNameValues {
  name: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a name'),
});

export function AccountNameForm({ name }: AccountNameValues) {
  const { t } = useTranslation();
  const [updateOrganisation] = useUpdateAccountMutation({
    refetchQueries: [Organisation.OrganisationSubscriptionQuery],
  });
  const formik = useFormik({
    initialValues: {
      name,
    },
    validationSchema,
    onSubmit: async (
      values: AccountNameValues,
      actions: FormikHelpers<AccountNameValues>
    ) => {
      await resolveMutation(
        updateOrganisation({
          variables: {
            data: {
              ...values,
            },
          },
        }),
        { successMessage: 'Account updated' }
      );
      actions.setSubmitting(false);
    },
  });

  return (
    <Form
      formik={formik}
      buttons={[
        {
          type: 'submit',
          label: t('edit_organisations_button'),
        },
      ]}
    >
      <InputFieldGroup
        label={t('edit_organisation_name')}
        type="text"
        name="name"
        placeholder={t('edit_organisation_name')}
        formik={formik}
      />
    </Form>
  );
}
