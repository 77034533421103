import { Organisation, Printing } from '@scannable/common';

import { graphql } from '../graphql';

export const UPDATE_ADDRESS = graphql(`
  mutation ${Organisation.UpdateAddress}($data: UpsertOneAddressInput!) {
    updateAddress(data: $data) {
      id
    }
  }`);

export const UPDATE_ACCOUNT = graphql(`
  mutation ${Organisation.UpdateAccount}($data: UpdateAccountInput!) {
    updateAccount(data: $data) {
      id
    }
  }`);

export const CREATE_ORGANISATION = graphql(`
mutation ${Organisation.CreateOrganisation} ($data: CreateOrganisationInput!) {
  createOrganisation(data: $data) {
    id
  }
}
`);

export const UPDATE_ORGANISATION = graphql(
  `mutation ${Organisation.UpdateOrganisation}($data: UpdateOrganisationInput!) {
    updateOrganisation(data: $data) {
      id
    }
  }`
);

export const PRINT_ALL = graphql(
  `mutation ${Printing.PrintAll}($data: PrintingInput!) {
    printAll(data: $data) {
      id
      status
    }
  }`
);

export const PRINT_FIRST = graphql(
  `mutation ${Printing.PrintFirst}($data: PrintingInput!) {
    printFirst(data: $data) {
      id
      status
    }
  }`
);

export const PRINT_ONE_SERIAL = graphql(`
  mutation ${Printing.PrintOneSerial}($data: PrintOneSerialInput!) {
    printOneSerial(data: $data) {
      id
      status
    }
  }`);

export const ASSIGN_PRINTERS = graphql(
  `mutation ${Printing.AssignPrinters}($data: AssignPrintersInput!) {
    assignPrintersToTeamMember(data: $data)
  }`
);

export const UPDATE_LABEL_TEMPLATES = graphql(
  `mutation ${Printing.UpdateLabelTemplates}($data: UpdateLabelTemplatesInput!) {
    updateLabelTemplates(data: $data) {
      id
    }
  }`
);

export const UPDATE_SUBSCRIPTION =
  graphql(`mutation ${Organisation.UpdateAccountSubscription}($data: UpdateOrganisationSubscriptionInput!) {
  updateSubscription(data: $data)
}`);

export const CANCEL_SUBSCRIPTION =
  graphql(`mutation ${Organisation.CancelSubscription} {
  cancelSubscription
}`);
