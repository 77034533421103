import { useQuery } from '@apollo/client';

import { calculatePercentage } from '@scannable/common';
import { daysLeft, heading } from '@scannable/frontend/common';
import { EQUIPMENT_OWNER_WEB_STATS } from '@scannable/graphql-queries';

import { Link } from '../../atoms';
import { useAuth } from '../../auth';
import { useTranslation } from '../../hooks';
import { DashboardStat, UpgradePlanButton } from '../../molecules';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProStatisticsProps {}

export function ProStatistics(props: ProStatisticsProps) {
  const { isOnTrial, trialEnds } = useAuth();

  const { t } = useTranslation();
  const { data, loading } = useQuery(EQUIPMENT_OWNER_WEB_STATS, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <div className="mb-5 rounded-lg shadow-md py-2 bg-brand-secondary mt-2">
      {isOnTrial && (
        <>
          <div className="flex justify-between p-5">
            <div>
              <div>
                <span className="text-white">Scannable Pro</span>
                {'  '}
                <span className="text-scannable-lime">
                  {daysLeft(trialEnds)}
                </span>
              </div>
              <Link
                href="https://www.scannable.io/pro"
                external
                className="text-gray-400 hover:text-gray-600 underline underline-offset-2 text-sm hover:no-underline "
              >
                Learn more about Scannable Pro here &rarr;
              </Link>
            </div>
            <div>
              <UpgradePlanButton />
            </div>
          </div>

          <hr className="border-gray-600 mx-5 mb-5" />
        </>
      )}
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 divide-x divide-gray-600">
        <DashboardStat
          name={t('inventory')}
          isLoading={loading}
          value={`${data?.getEquipmentOwnerStats?.inventoryCount} Items`}
          icon="kitList"
          ctaHref="/admin/inventory"
          ctaText="View Inventory"
        />
        <DashboardStat
          name={t('plan')}
          isLoading={loading}
          value={`${heading(data?.getEquipmentOwnerStats?.subscriptionType)} ${
            data?.getEquipmentOwnerStats?.inventoryLimit
              ? ` - ${data?.getEquipmentOwnerStats?.inventoryLimit} Item Limit`
              : ``
          }`}
          percent={calculatePercentage(
            data?.getEquipmentOwnerStats?.inventoryCount,
            data?.getEquipmentOwnerStats?.inventoryLimit
          )}
          icon="inspections"
          ctaHref=""
          ctaText=""
        />
        <DashboardStat
          name={t('team.title')}
          isLoading={loading}
          value={`${data?.getEquipmentOwnerStats?.teamCount} ${
            data?.getEquipmentOwnerStats?.teamCount === 1 ? 'Member' : 'Members'
          }`}
          icon="team"
          ctaHref="/admin/team"
          ctaText="View Team"
        />
      </div>
      <hr className="border-gray-600 mx-5 mb-5" />
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 divide-x divide-gray-600">
        <DashboardStat
          name={t('dashboard.items_inspected')}
          isLoading={loading}
          value={`${data?.getEquipmentOwnerStats?.itemsInspected} %`}
          icon="inspections"
          ctaHref="/admin/inspections"
          ctaText="View Inspections"
        />
        <DashboardStat
          name={t('dashboard.items_overdue')}
          isLoading={loading}
          value={data?.getEquipmentOwnerStats?.overdueInspectionCount}
          icon="notifications"
          ctaHref="/admin/inventory?inventoryNextInspectionResult=overdue"
          ctaText="View Overdue Items"
          testId="pro-statistics-overdue-items"
        />
        <DashboardStat
          name={t('dashboard.items_due_30')}
          isLoading={loading}
          value={data?.getEquipmentOwnerStats?.inspectionDue30DaysCount}
          icon="notifications"
        />
        <DashboardStat
          name={t('dashboard.items_due_60')}
          isLoading={loading}
          value={data?.getEquipmentOwnerStats?.inspectionDue60DaysCount}
          icon="notifications"
        />
      </div>
    </div>
  );
}

export default ProStatistics;
