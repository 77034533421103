import { isString } from 'class-validator';
import { format } from 'date-fns';

import { ISO_8601_DATE_FORMAT } from './constants';

/**
 * Checkout: https://www.youtube.com/shorts/jZUHZDXmQ_A
 * @param date
 * @param locale
 * @param options
 * @returns
 */
export function dateFormat(
  date: Date | string | null | undefined,
  options?: Intl.DateTimeFormatOptions,
  locale?: string
) {
  let d = date;
  const option = options ?? {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  if (!d) {
    return '';
  }
  if (isString(d)) {
    d = new Date(d);
  }
  const f = new Intl.DateTimeFormat(locale ?? 'en-US', option);

  return f.format(d);
}

export const formatDateForAPI = (
  value: Date,
  dateOutputFormat?: 'yyyy-MM-dd' | 'iso'
): string => {
  const outputFormat = dateOutputFormat || ISO_8601_DATE_FORMAT;
  if (outputFormat === 'iso') {
    return value.toISOString();
  }

  return format(value, outputFormat);
};

export const currentDate = dateFormat(new Date());
